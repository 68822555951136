import React from 'react'
import { useTranslation } from 'react-i18next'
import { ImageBackground } from 'react-native'

import { BecomeMemberBackground } from '@charlycares/assets'
import { Box, Text, Button } from 'native-base'
import { useMembershipIAP } from './common'
import { useRouter } from '@charlycares/shared'

export default function WelcomeBackMember() {
  // useMembershipIAP()

  const { t } = useTranslation()
  const { navigate } = useRouter()
  const reactivate = () => {
    navigate('ReactivateMembership', '/reactivate-membership')
  }

  return (
    <ImageBackground source={BecomeMemberBackground as any} style={{ height: '100%' }}>
      <Box position="absolute" backgroundColor="bg.dark.alpha.50" bottom={0} padding="20px" width="100%">
        <Text fontSize={21} width="100%" color="white" textAlign="center">
          {t('welcomeBack.title')}
        </Text>

        <Text fontSize={16} marginTop="15px" marginBottom="15px" color="white" width="100%" textAlign="center">
          {t('welcomeBack.description')}
        </Text>

        <Button backgroundColor="secondary.400" marginBottom="20px" onPress={reactivate}>
          {t('welcomeBack.button')}
        </Button>
      </Box>
    </ImageBackground>
  )
}
