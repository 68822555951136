import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { Text, Divider } from '../../elements';
import { TextButton } from '../Buttons';
import colors from '../../styles/colors';

const View = styled.View`
  padding: 20px;
  height: 200px;
`;

const NoBookings = ({ navigateToBookScreen }) => {
  const [t] = useTranslation();

  return (
    <>
      <Divider />

      <View>
        <Text fontFamily="Martel" fontSize="18px">
          {t('bookingsScreenParentNoBookings')}
        </Text>

        <Text margins="10px 0px 0px 0px" fontSize="15px">
          {t('bookingsScreenParentNoBookingsDesc')}
        </Text>

        <TextButton
          margins="25px 0px 10px 0px"
          borderRightWidth="1px"
          borderLeftWidth="1px"
          borderRadius="20px"
          borderColor={colors.primary}
          text={t('bookingsScreenParentNoBookingsBtn')}
          fontSize="16px"
          textColor={colors.primary}
          onPress={navigateToBookScreen}
        />
      </View>
    </>
  );
};

NoBookings.propTypes = {
  navigateToBookScreen: PropTypes.func.isRequired
};

export default NoBookings;
