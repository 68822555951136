import React from 'react'
import { Box, Divider, Factory, Skeleton, Stack, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import { Card, CharlyIcon, ScreenLayout } from '@charlycares/ui'
import { useGetBookingRequestTypesQuery } from '@charlycares/data-access'
import { isWeb, useRouter } from '@charlycares/shared'
import { TouchableOpacity } from 'react-native'
import { getInfoFromBookingServiceType } from '../../common'

const Touchable = Factory(TouchableOpacity)

export default function BookingRequestTypesListScreen({ navigation }: any) {
  const { navigate, goBack } = useRouter()
  const { t } = useTranslation()

  const { data: bookingRequestTypes, isLoading } = useGetBookingRequestTypesQuery()

  return (
    <ScreenLayout
      supportButton
      title={t('findAngels')}
      edges={['right', 'left']}
      headerLeft={
        <CharlyIcon
          name="icn-nav-left"
          color="primary.400"
          size={30}
          onPress={() => {
            if (isWeb) {
              goBack()
            } else {
              navigation.dismiss()
            }
          }}
        />
      }
    >
      <Card pb={bookingRequestTypes && bookingRequestTypes.length > 1 ? '150px' : '30px'}>
        <Text fontSize="20px" fontWeight="600" mb="32px">
          {t('bookingRequestTypesList.title')}
        </Text>
        {isLoading && (
          <Stack space="5px">
            {Array.from({ length: 4 }).map((_, index) => (
              <Skeleton key={index} h="90px" w="100%" rounded="md" startColor="gray.100" />
            ))}
          </Stack>
        )}
        {!isLoading &&
          bookingRequestTypes?.map((bookingRequestType, index) => (
            <Stack space="5px" key={index}>
              <Touchable
                onPress={() => navigate('CreateBooking', '/book-create', { bookingRequestType })}
                overflow="hidden"
              >
                <Box px="5px" py="5px" flexDir="row" alignItems="center">
                  <CharlyIcon
                    size={30}
                    color={`${getInfoFromBookingServiceType(bookingRequestType.service_type).icon_color}.400`}
                    name={getInfoFromBookingServiceType(bookingRequestType.service_type).icon}
                  />
                  <Box ml="15px" flex={1}>
                    <Text fontSize="16px" fontWeight="600">
                      {t(`booking_service_type.${bookingRequestType.service_type}.title`)}
                    </Text>
                    <Text fontSize="12px" color="gray.800">
                      {t(`booking_service_type.${bookingRequestType.service_type}.description`)}
                    </Text>
                  </Box>
                  <CharlyIcon size={24} color="gray.500" name="icn-caret-right" />
                </Box>
              </Touchable>
              {bookingRequestTypes.length - 1 !== index && <Divider my="10px" />}
            </Stack>
          ))}
      </Card>
    </ScreenLayout>
  )
}

BookingRequestTypesListScreen.navigationOptions = () => ({
  headerShown: false
})
