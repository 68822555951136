/**
 * @deprecated This need's to be moved to types, because data-access shouldn't be imported into other libs and is not good place to add shared Type
 */
export enum Currency {
  EUR = 'EUR'
}

/**
 * @deprecated This need's to be moved to types, because data-access shouldn't be imported into other libs and is not good place to add shared Type
 */
export interface Money {
  amount: number
  currency: Currency | 'EUR'
}
