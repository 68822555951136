import React, { useEffect } from 'react'
import { ActivityIndicator } from 'react-native'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getNavigationRouteAndParamsOnLogin, changeMomentLocale, colors, Boarding } from '@charlycares/legacy/web'
import { bindActionCreators } from 'redux'
import { useGetUserQuery, userApi } from '@charlycares/data-access'

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  align-items: center;
  justify-content: center;
`

const Loading = ({ history, location, oldUser, loading, setLoading, loginError, getUser }) => {
  const { i18n } = useTranslation()
  const { data: user } = useGetUserQuery()

  useEffect(() => {
    if (loginError) {
      history.push('/login')
    }
  }, [loginError, history])

  useEffect(() => {
    if (oldUser && user) {
      i18n.changeLanguage(oldUser.locale)
      changeMomentLocale(oldUser.locale === 'nl_NL' ? 'nl' : 'en')

      const { pathname, params } = getNavigationRouteAndParamsOnLogin(oldUser, location.state?.pathname)
      history.push({ pathname, state: params })
    }
  }, [oldUser, user, loading, history, location.state?.pathname, setLoading, getUser, i18n])

  return (
    <SafeAreaView>
      {loading ? <ActivityIndicator size={50} color={colors.secondary} /> : <Boarding history={history} />}
    </SafeAreaView>
  )
}

Loading.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  user: PropTypes.shape({
    role: PropTypes.string,
    locale: PropTypes.string,
    has_membership: PropTypes.bool,
    has_pending_bookings: PropTypes.bool,
    membership: PropTypes.shape({
      active_payments: PropTypes.bool,
      current_state: PropTypes.string
    })
  }),
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  getUserData: PropTypes.func.isRequired
}

Loading.defaultProps = {
  user: null
}
function mapStateToProps(state) {
  return {
    oldUser: state.User.user,
    loginError: state.User.loginError
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUser: userApi.endpoints.getUser.initiate
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Loading)
