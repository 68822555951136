import React from 'react'
import { useTranslation } from 'react-i18next'
import { BookingStates, makeCall, useRouter } from '@charlycares/shared'
import { ScreenLayout, Card, CharlyIcon } from '@charlycares/ui'
import { Heading, Box, Button, Text, HStack, Skeleton } from 'native-base'
import { useGetBookingDetailsQuery } from '@charlycares/data-access'

export default function AngelBookingCancelledScreen() {
  const { getParam, navigate } = useRouter()
  const { t } = useTranslation()

  const bookingId = getParam('bookingId') as string
  const bookingStatus = getParam('bookingStatus', BookingStates.CANCELED) as BookingStates

  const { isLoading, data: booking } = useGetBookingDetailsQuery({ obscured_id: bookingId }, { skip: !bookingId })

  return (
    <ScreenLayout
      progress={`${100}%`}
      hideBackButton
      title={t('bookingCancelled')}
      subtitle={bookingId}
      edges={['right', 'left']}
      headerRight={
        <Button onPress={() => navigate('BookingsAngelScreen')} variant="text">
          {t('done')}
        </Button>
      }
    >
      <Box w="100%" bg="white" p="20px" borderColor="gray.200" borderWidth="1px">
        <Heading color="gray.900" fontSize="18px" mt="10px" mb="15px">
          {t('bookingsIsCancelledTitle')}
        </Heading>
        <Text>
          <Text color="gray.800" fontSize="15px">
            {t('bookingsIsCancelledDescription')}
          </Text>
          <Text color="gray.800" fontSize="15px" onPress={() => makeCall('+310202102323')}>
            {' +31 (0) 20 210 23 23'}
          </Text>
        </Text>

        <HStack mt="30px" mb="20px">
          <Text color="gray.800" fontSize="15px">
            {t('bookingsIsCancelledReachOutFamily')}
          </Text>
        </HStack>
      </Box>
      {isLoading &&
        Array.from({ length: 7 }).map((_, index) => (
          <HStack alignItems="center" mt="10px" py="5px" px="20px" key={index}>
            <Skeleton h="4" w="100%" startColor="gray.400" />
          </HStack>
        ))}
      {booking && bookingStatus === BookingStates.PENDING && (
        <Card>
          <Box bgColor="secondary.alpha.15" p="15px" borderRadius="5px">
            <HStack alignItems="center" justifyContent="space-between">
              <Text color="gray.900" fontSize="16px" fontWeight="600">
                {t('shareBookingTitle')}
              </Text>
              <CharlyIcon name="icn-send" color="primary.400" size={34} />
            </HStack>
            <Text mt="15px" color="gray.800" fontSize="15px">
              {t('shareBookingText')}
            </Text>
          </Box>

          <HStack mt="30px" mb="80px">
            <Button
              width="100%"
              color="primary"
              onPress={() => navigate('AngelShareBooking', '/share-booking', { bookingId: booking.obscured_id })}
            >
              {t('shareBookingBtnText')}
            </Button>
          </HStack>
        </Card>
      )}
    </ScreenLayout>
  )
}

AngelBookingCancelledScreen.navigationOptions = () => ({
  headerShown: false
})
