import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AlertButton } from 'react-native'

export interface AlertSlice {
  show: boolean
  title?: string
  message?: string
  buttons?: AlertButton[]
}

const initialState: AlertSlice = {
  show: false
}

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    showAlert: (state, action: PayloadAction<{ title?: string; message?: string; buttons?: AlertButton[] }>) => ({
      show: true,
      title: action.payload.title,
      message: action.payload.message,
      buttons: action.payload.buttons
    }),
    closeAlert: () => initialState
  }
})

// Action creators are generated for each case reducer function
export const { showAlert, closeAlert } = alertSlice.actions

export const alertReducer = alertSlice.reducer
