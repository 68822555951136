import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { Pressable } from 'react-native';
import { isMobile, isTablet } from 'react-device-detect';

import CharlyIcon from '../../Icons/Icon';
import { ContainerView, Text, Heading } from '../../../elements';
import { getAge } from '../../../utils/functions';
import theme from '../../../styles/theme';

import { TextTextView, TextIconView } from '../../Texts';
import { TextButton, IconButton, TextButtonText } from '../../Buttons';
import ProfileImage from './ProfileImage';
import RatesDistance from './RatesDistance';
import Characteristics from './Characteristics';
import Skills from './Skills';
import Rating from '../Rating';
import AngelRatings from './AngelRatings';
import Availability from './Availability';
import { Modal } from '../../Modals';
import WebVideo from '../../Video';

const ScrollView = styled.ScrollView.attrs({
  contentContainerStyle: {
    width: '100%',
    alignItems: 'center',
    paddingTop: theme.spacer.xl,
    paddingRight: theme.spacer.lg,
    paddingBottom: theme.spacer.xl,
    paddingLeft: theme.spacer.lg,
  },
})``;
const AngelProfileImageArrowsView = styled.View`
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;
const RatingButtonView = styled.View`
  flex-direction: row;
  align-items: center;
`;
const View = styled.View`
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: space-between;
  ${({ paddings }) => paddings && { padding: paddings }};
  margin-top: 20px;
  width: 100%;
  ${({ borderBottom }) =>
    borderBottom && {
      'border-bottom-width': '1px',
      'border-color': theme.dividerLineColor,
    }};
`;

const Container = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
`;

const VideoWrapper = styled(Pressable)`
  width: ${isMobile ? (isTablet ? '50%' : '80%') : '20%'};
`;

const getSelectBtnText = (t, isSelected, disabled) => {
  if (disabled) {
    return t('maxSelectedAngels');
  }

  if (isSelected) {
    return t('selected');
  }

  return t('select');
};

const AngelDetails = ({
  scrollRef,
  showLeftArrow,
  showRightArrow,
  angel,
  ratings,
  onToggleLikePress,
  onVideoPress,
  showSelectButton,
  isSelected,
  disabled,
  availability,
  calendarDisabled,
  onToggleSelectPress,
  onCharacteristicsInfoPress,
  onSkillsInfoPress,
  onReviewsPress,
  onReadMorePress,
  numberOfCharacters,
  onCalendarPress,
  onLeftArrowPress,
  onRightArrowPress,
}) => {
  const [t] = useTranslation();
  const {
    id,
    is_liked,
    image,
    video,
    standBy,
    fixed,
    first_name,
    birthdate,
    written_review_count,
    average_rating,
    short_bio,
    normal_rate,
    extra_rate,
    fixed_rate,
    distance,
    response_time,
    education,
    field_of_study,
    first_aid,
    driving_license,
    tutor,
    min_age_children,
    babysit_expertise,
    liability_insurance,
    language,
    type,
  } = angel;
  const { dutch, english, french, german, italian, spanish } = language;
  const languagesArray = [
    dutch ? t('dutchLanguage') : null,
    english ? t('englishLanguage') : null,
    german ? t('germanLanguage') : null,
    french ? t('frenchLanguage') : null,
    spanish ? t('spanishLanguage') : null,
    italian ? t('italianLanguage') : null,
  ];
  const showMore = short_bio.length > numberOfCharacters;
  const modalRef = React.useRef(null);

  const setIsVisible = (isVisible) => {
    modalRef.current?.setIsVisible(isVisible);
  };

  return (
    <ScrollView ref={scrollRef}>
      <AngelProfileImageArrowsView>
        {/* <IconButton
          iconSize="40px"
          iconColor={showLeftArrow ? theme.colors.grey07 : theme.colors.white}
          iconName="icn-nav-left"
          onPress={onLeftArrowPress}
        /> */}

        <ProfileImage
          margins="0px 16px 12px 16px"
          isLiked={is_liked > 0}
          image={image}
          video={video}
          standBy={standBy}
          fixed={fixed}
          onToggleLikePress={() => onToggleLikePress(id, is_liked)}
          onVideoPress={() =>
            onVideoPress ? onVideoPress(video) : setIsVisible(true)
          }
        />

        {/* <IconButton
          iconSize="40px"
          iconColor={showRightArrow ? theme.colors.grey07 : theme.colors.white}
          iconName="icn-nav-right"
          onPress={showRightArrow ? onRightArrowPress : () => {}}
        /> */}
      </AngelProfileImageArrowsView>

      {showSelectButton && (
        <TextButton
          disabled={disabled}
          flexDirection="row"
          paddings="0px 10px 0px 10px"
          margins="0 0 8px 0"
          backgroundColor={
            isSelected || disabled ? theme.colors.grey08 : theme.colors.primary
          }
          text={getSelectBtnText(t, isSelected, disabled)}
          textColor={
            isSelected || disabled ? theme.colors.grey01 : theme.colors.white
          }
          textMargins={`0px ${isSelected ? 16 : 10}px 0px ${
            isSelected ? 16 : 16
          }px`}
          width="240px"
          onPress={() => onToggleSelectPress(id, is_liked > 0)}
        >
          {!isSelected && (
            <CharlyIcon
              size={24}
              color={theme.colors.white}
              name={'icn-nav-add'}
            />
          )}
        </TextButton>
      )}

      <Heading
        xl
        ariaLevel={2}
        margins="0 0 4px 0"
        fontFamily={theme.fontFamilyAlt}
        text={`${first_name} (${getAge(birthdate)})`}
      />

      {written_review_count > 0 && (
        <RatingButtonView>
          <Rating
            margins="0px 8px 4px"
            iconSize="16px"
            rating={average_rating}
          />

          <TextButton
            paddings="0px"
            justifyContent="center"
            height="auto"
            text={`${written_review_count} ${t('reviews')}`}
            textColor={theme.textLinkColor}
            onPress={onReviewsPress}
          />
        </RatingButtonView>
      )}

      {short_bio !== '' && (
        <TextButtonText
          disabled={!showMore}
          height="auto"
          firstText={
            showMore
              ? `${short_bio.substring(0, numberOfCharacters)}...`
              : short_bio
          }
          firstTextWidth="100%"
          firstTextAlign="left"
          secondText={showMore ? `${t('readMore')}` : ''}
          onPress={onReadMorePress}
        />
      )}

      <RatesDistance
        justifyContent="center"
        paddings="20px 0px 20px 0px"
        margins="20px 0px 0px 0px"
        width="100%"
        iconMargins="0px 10px"
        iconSize="32px"
        fontSize={theme.fontSize.sm}
        normalRate={normal_rate}
        extraRate={extra_rate}
        fixedRate={fixed_rate}
        distance={distance}
        borderTopWidth="1px"
        borderBottomWidth="1px"
      />

      <Characteristics
        responseTime={response_time}
        languages={languagesArray.filter((lan) => lan)}
        education={education}
        fieldOfStudy={field_of_study}
        onInfoPress={onCharacteristicsInfoPress}
      />

      <Skills
        firstAid={!!first_aid}
        drivingLicense={!!driving_license}
        tutor={!!tutor}
        babyExperience={min_age_children < 6}
        pro={!!babysit_expertise}
        liabilityInsurance={!!liability_insurance}
        onInfoPress={onSkillsInfoPress}
      />

      {!!availability.length && type === 'fixed' && (
        <View flexDirection="column">
          <ContainerView
            justifyContent="space-between"
            flexDirection="row"
            flex="1 1 auto"
          >
            <Heading
              ariaLevel={3}
              text={t('angelProfileAvailability')}
              margins="0"
            />
            <TextIconView
              flexDirection="row-reverse"
              paddings="5px"
              height="22px"
              backgroundColor={theme.labelFixedSitterBg}
              text={t('bookScreensPermanentBabysitter')}
              fontSize={theme.labelFontSize}
              fontColor={theme.labelFixedSitterColor}
              name="icn-repeat"
              iconSize={theme.labelIconSize}
              iconColor={theme.labelFixedSitterColor}
              borderRadius="3px"
            />
          </ContainerView>
          <Text lighterColor xs>
            {t('angelProfileAvailabilityDescription')}
          </Text>
          <Availability
            paddings="10px 0px 20px 0px"
            borderBottom
            availability={availability}
          />
        </View>
      )}

      {!calendarDisabled && (
        <View paddings="0px 0px 20px 0px" borderBottom>
          <TextTextView
            flexDirection="column"
            alignItems="flex-start"
            paddings="0px"
            firstText={t('angelAgenda')}
            firstTextFontFamily={theme.fontFamilyAlt}
            firstTextFontSize="16px"
            firstTextFontWeight="600"
            secondText={t('angelAgendaDetails')}
            secondTextFontSize="12px"
            secondTextColor={theme.colors.grey04}
          />

          <IconButton
            iconName="icn-calendar"
            iconSize="32px"
            iconColor={theme.colors.secondary}
            onPress={onCalendarPress}
          />
        </View>
      )}

      {!!ratings.length && (
        <AngelRatings firstName={first_name} ratings={ratings} />
      )}

      <Modal ref={modalRef}>
        <Container>
          <VideoWrapper>
            <WebVideo src={video} />
          </VideoWrapper>
        </Container>
      </Modal>
    </ScrollView>
  );
};

AngelDetails.propTypes = {
  scrollRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  showLeftArrow: PropTypes.bool,
  showRightArrow: PropTypes.bool,
  angel: PropTypes.shape({
    id: PropTypes.number,
    is_liked: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    image: PropTypes.string,
    video: PropTypes.string,
    standBy: PropTypes.bool,
    fixed: PropTypes.bool,
    first_name: PropTypes.string,
    birthdate: PropTypes.string,
    written_review_count: PropTypes.number,
    average_rating: PropTypes.number,
    short_bio: PropTypes.string,
    normal_rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    extra_rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fixed_rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    distance: PropTypes.number,
    response_time: PropTypes.number,
    education: PropTypes.string,
    field_of_study: PropTypes.string,
    first_aid: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    driving_license: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    tutor: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    min_age_children: PropTypes.number,
    babysit_expertise: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    liability_insurance: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
    ]),
    language: PropTypes.shape({
      dutch: PropTypes.bool,
      english: PropTypes.bool,
      french: PropTypes.bool,
      german: PropTypes.bool,
      italian: PropTypes.bool,
      spanish: PropTypes.bool,
    }),
    type: PropTypes.string,
  }).isRequired,
  ratings: PropTypes.arrayOf(PropTypes.shape({})),
  onToggleLikePress: PropTypes.func,
  onVideoPress: PropTypes.func.isRequired,
  showSelectButton: PropTypes.bool,
  isSelected: PropTypes.bool,
  availability: PropTypes.arrayOf(PropTypes.shape),
  disabled: PropTypes.bool,
  calendarDisabled: PropTypes.bool,
  onToggleSelectPress: PropTypes.func,
  onCharacteristicsInfoPress: PropTypes.func.isRequired,
  onSkillsInfoPress: PropTypes.func.isRequired,
  onReviewsPress: PropTypes.func.isRequired,
  onReadMorePress: PropTypes.func.isRequired,
  numberOfCharacters: PropTypes.number.isRequired,
  onCalendarPress: PropTypes.func,
  onLeftArrowPress: PropTypes.func,
  onRightArrowPress: PropTypes.func,
};

AngelDetails.defaultProps = {
  scrollRef: () => {},
  showLeftArrow: false,
  showRightArrow: false,
  showSelectButton: false,
  isSelected: false,
  disabled: false,
  calendarDisabled: true,
  availability: [],
  ratings: [],
  onToggleLikePress: () => {},
  onToggleSelectPress: () => {},
  onCalendarPress: () => {},
  onLeftArrowPress: () => {},
  onRightArrowPress: () => {},
};

export default AngelDetails;
