import React, { useRef, useState } from 'react'
import { Box, Button, Divider, HStack, IBoxProps, Text, useTheme } from 'native-base'
import { useTranslation } from 'react-i18next'
import moment, { Moment } from 'moment'
import {
  SearchBookingsDates,
  formatDate,
  getBookingPickerDatesRange,
  getDuration,
  isWeb,
  useRouter,
  formatInterval
} from '@charlycares/shared'
import { useAlert } from '@charlycares/features'
import { CharlyIcon, DateTimePicker, Card } from '@charlycares/ui'

type Props = IBoxProps & {
  startDate?: Moment | Date | string
  endDate?: Moment | Date | string
  repeatDates?: number
  readonly?: boolean
  onChange?: (bookingDates: SearchBookingsDates) => void
  showRepeat?: boolean
  bookingIndex?: number
  hideDuration?: boolean
}

const BookingDatePicker = ({
  startDate,
  endDate,
  readonly,
  repeatDates,
  showRepeat,
  onChange,
  bookingIndex,
  hideDuration,
  ...boxProps
}: Props) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { navigate } = useRouter()
  const alert = useAlert()

  const startPickerRef = useRef<any>()
  const endPickerRef = useRef<any>()

  const { maxEndDate, maxStartDate, minEndDate, minStartDate } = getBookingPickerDatesRange(startDate)

  const [selectedStartDate, setSelectedStartDate] = useState(moment(startDate) || minStartDate)
  const [selectedEndDate, setSelectedEndDate] = useState(moment(endDate) || minEndDate)

  const duration = getDuration(selectedStartDate, selectedEndDate)

  const onStartDateChange = (date: string) => {
    const newStartDate = moment(date)
    setSelectedStartDate(newStartDate)

    const isMoreThenSixWeeks = getDuration(moment(), newStartDate).asWeeks() > 6
    if (isMoreThenSixWeeks) {
      alert.show(t('moreThenSixWeeksBookingTitle'), t('moreThenSixWeeksBookingDescription'), [{ text: t('ok') }])
    }

    const newEndDate = newStartDate.clone().add(2, 'hours')
    setSelectedEndDate(newEndDate)

    onChange?.({
      startDate: newStartDate.format('YYYY-MM-DD HH:mm:ss'),
      endDate: newEndDate.format('YYYY-MM-DD HH:mm:ss'),
      repeatDates: []
    })
  }

  const onEndDateChange = (date: string) => {
    const newDate = moment(date)
    setSelectedEndDate(newDate)

    onChange?.({
      startDate: selectedStartDate.format('YYYY-MM-DD HH:mm:ss'),
      endDate: newDate.format('YYYY-MM-DD HH:mm:ss'),
      repeatDates: []
    })
  }

  return (
    <Card mt="0" px="20px" py="0" {...boxProps}>
      <Box w="100%" flexDir="row" justifyContent="space-between" alignItems="center" py="12px">
        <Text fontSize="16px">{t('start')}</Text>

        {!isWeb ? (
          <Button
            _text={{
              color: readonly ? 'gray.800' : 'secondary.500'
            }}
            disabled={readonly}
            variant="text"
            onPress={() => startPickerRef.current?.open(selectedStartDate)}
            rightIcon={readonly ? undefined : <CharlyIcon color={'gray.500'} name="icn-nav-right" />}
          >
            {formatDate(selectedStartDate, 'dddd, D MMM HH:mm')}
          </Button>
        ) : (
          <HStack space="5px">
            <DateTimePicker
              disabled={readonly}
              timeIntervals={15}
              minDate={moment(minStartDate).toDate()}
              maxDate={moment(maxStartDate).toDate()}
              selected={moment(selectedStartDate).toDate()}
              labelValue={moment(selectedStartDate).format('dddd, D MMM HH:mm')}
              onChange={onStartDateChange}
              popperPlacement="bottom-end"
              _button={{
                rightIcon: !readonly ? <CharlyIcon color={colors.gray[500]} name="icn-nav-right" /> : undefined
              }}
            />
          </HStack>
        )}
      </Box>

      <Divider borderBottomWidth={1} borderColor="gray.400" />

      <Box w="100%" flexDir="row" justifyContent="space-between" alignItems="center" py="12px">
        <Text fontSize="16px">{t('end')}</Text>
        {!isWeb ? (
          <Button
            disabled={readonly}
            _text={{
              color: readonly ? 'gray.800' : 'secondary.500'
            }}
            variant="text"
            onPress={() => endPickerRef.current?.open(selectedEndDate)}
            rightIcon={readonly ? undefined : <CharlyIcon color={colors.gray[500]} name="icn-nav-right" />}
          >
            {formatDate(selectedEndDate, 'dddd, D MMM HH:mm')}
          </Button>
        ) : (
          <HStack space={'5px'}>
            <DateTimePicker
              disabled={readonly}
              timeIntervals={15}
              minDate={moment(minEndDate).toDate()}
              maxDate={moment(maxEndDate).toDate()}
              selected={moment(selectedEndDate).toDate()}
              labelValue={moment(selectedEndDate).format('dddd, D MMM HH:mm')}
              onChange={onEndDateChange}
              popperPlacement="bottom-end"
              _button={{
                rightIcon: !readonly ? <CharlyIcon color={colors.gray[500]} name="icn-nav-right" /> : undefined
              }}
            />
          </HStack>
        )}
      </Box>

      {!hideDuration && (
        <>
          <Divider borderBottomWidth={1} borderColor="gray.400" />

          <Box w="100%" flexDir="row" justifyContent="space-between" alignItems="center" py="12px">
            <Text fontSize="16px">{t('duration')}</Text>
            <Text fontSize="16px" color="gray.800">
              {formatInterval(duration.toISOString(), true, true)}
            </Text>
          </Box>
        </>
      )}

      {Boolean(repeatDates || showRepeat) && (
        <>
          <Divider borderBottomWidth={1} borderColor="gray.400" />
          <Box w="100%" flexDir="row" justifyContent="space-between" alignItems="center" py="12px">
            <Text fontSize="16px">{readonly ? t('repeats') : t('repeatDates')}</Text>
            <Button
              disabled={readonly}
              _text={{
                color: readonly ? 'gray.800' : 'secondary.500'
              }}
              variant="text"
              onPress={() => navigate('BookRepeatDates', '/book-repeat-dates', { bookingIndex })}
              rightIcon={!readonly ? <CharlyIcon color={colors.gray[500]} name="icn-nav-right" /> : undefined}
            >
              {repeatDates ? `${repeatDates}x` : t('no')}
            </Button>
          </Box>
        </>
      )}

      {!isWeb && (
        <DateTimePicker
          ref={startPickerRef}
          type="datetime"
          dateFormat="ddd, D MMM"
          minDate={minStartDate}
          maxDate={maxStartDate}
          onConfirmPress={onStartDateChange}
        />
      )}

      {!isWeb && (
        <DateTimePicker
          ref={endPickerRef}
          type="datetime"
          dateFormat="ddd, D MMM"
          minDate={minEndDate}
          maxDate={maxEndDate}
          onConfirmPress={onEndDateChange}
        />
      )}
    </Card>
  )
}

export default BookingDatePicker
