import AsyncStorage from '@react-native-async-storage/async-storage'
import { StorageKeys, isWeb, OldUser, IBookingDetails } from '@charlycares/shared'
import { api } from '../api'
import { userApi } from '../user'
import { ApiTags, ResponseError } from '../types'
import { BroadcastToken, LoginBody, LoginResponse, MercureToken, SignUpBody } from './auth.dto'
import { PaginationData } from '../paginate'
import { ChatMessage } from '../chat'

export const authApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: ({ mutation, query }) => ({
    login: mutation<LoginResponse, LoginBody>({
      query: body => ({
        url: '/v2/authenticate/login',
        method: 'POST',
        body
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled
          await AsyncStorage.setItem(StorageKeys.AUTH_TOKEN, data.data.token)

          if (isWeb) {
            dispatch(userApi.endpoints.getUserProfile.initiate())
          }
        } catch (error: any) {
          // WEB ONLY: This is needed currently in order to display error message in the legacy login screen
          if (isWeb) {
            dispatch({
              type: 'login_error',
              payload: (error as ResponseError)?.status >= 500 ? 'server error' : 'invalid credentials'
            })
          }
        }
      }
    }),
    refreshToken: mutation<LoginResponse, void>({
      query: () => ({
        url: '/v2/authenticate/refresh',
        method: 'GET'
      })
    }),
    angelSignUp: mutation<{ token: string; data: OldUser }, SignUpBody>({
      query: body => ({
        url: 'v2/angels/signup',
        method: 'POST',
        body
      }),
      onQueryStarted(arg, { queryFulfilled, dispatch }) {
        queryFulfilled
          .then(({ data }) => {
            AsyncStorage.setItem(StorageKeys.AUTH_TOKEN, data.token)

            dispatch(api.util.invalidateTags([ApiTags.USER, ApiTags.ANGEL_TODOS, ApiTags.ONBOARDING_SCREENS]))
          })
          .catch(e => console.log(e))
      }
    }),
    familySignUp: mutation<{ token: string; data: OldUser }, SignUpBody>({
      query: body => ({
        url: 'v2/families/signup',
        method: 'POST',
        body
      }),
      onQueryStarted(arg, { queryFulfilled, dispatch }) {
        queryFulfilled
          .then(({ data }) => {
            AsyncStorage.setItem(StorageKeys.AUTH_TOKEN, data.token)

            dispatch(api.util.invalidateTags([ApiTags.USER, ApiTags.ANGEL_TODOS, ApiTags.ONBOARDING_SCREENS]))
          })
          .catch(e => console.log(e))
      }
    }),
    mercureToken: query<MercureToken, undefined>({
      query: () => ({
        url: `v2/authenticate/mercure-token`,
        method: 'POST'
      }),
      providesTags: [ApiTags.MERCURE_TOKEN],
      transformResponse: ({ data }): MercureToken => data
    }),
    broadcastToken: mutation<BroadcastToken, { channel_name: string; socket_id: string }>({
      query: body => ({
        url: `v2/authenticate/broadcast-token`,
        method: 'POST',
        body
      }),
      transformResponse: ({ data }): BroadcastToken => data
    }),
    forgotPassword: mutation<void, { email: string }>({
      query: body => ({
        url: 'v2/forgot-password',
        method: 'POST',
        body
      })
    }),
    checkResetPasswordCode: mutation<void, { code: string; email: string }>({
      query: body => ({
        url: 'v2/check-reset-password-code',
        method: 'POST',
        body
      })
    }),
    resetPassword: mutation<void, { email: string; code: string; password: string; password_confirmation: string }>({
      query: body => ({
        url: 'v2/reset-password',
        method: 'POST',
        body
      })
    })
  })
})

export const {
  useLoginMutation,
  useRefreshTokenMutation,
  useAngelSignUpMutation,
  useFamilySignUpMutation,
  useMercureTokenQuery,
  useBroadcastTokenMutation,
  useForgotPasswordMutation,
  useCheckResetPasswordCodeMutation,
  useResetPasswordMutation
} = authApi
