import React from 'react';
import PropTypes from 'prop-types';
import { Player, ControlBar } from 'video-react';

const WebVideo = ({ src, ...props }) => (
  <Player {...props} playsInline src={src}>
    <ControlBar />
  </Player>
);

WebVideo.propTypes = {
  src: PropTypes.string.isRequired,
  disableControls: PropTypes.bool
};

WebVideo.defaultProps = {
  disableControls: true
};

export default WebVideo;
