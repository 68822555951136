import React, { useMemo, useState } from 'react'
import { Box, Button, Divider, Flex, HStack, Spacer, Text, useTheme } from 'native-base'
import { useTranslation } from 'react-i18next'
import moment from 'moment/moment'
import { useGetActiveTimerQuery } from '@charlycares/data-access'
import { BookingCancellationReasonType, getDurationString, isWeb, useRouter, UserRoles } from '@charlycares/shared'
import { PlatformCircleSnail } from '@charlycares/ui'
import { RunningBookingOverlay } from '../overlay'

export default function ActiveBookingTimer({ role }: { role: UserRoles }) {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { navigate, didFocus } = useRouter()
  const [showStopTimer, setShowStopTimer] = useState(false)
  const { data: activeTimer, refetch, isLoading, isUninitialized } = useGetActiveTimerQuery()
  const duration = useMemo(() => {
    if (activeTimer === undefined) {
      return ''
    }

    return getDurationString(activeTimer.starts_at, moment().format('YYYY-MM-DD HH:mm:ss'))
  }, [activeTimer])

  return (
    <Box mt="10px" w="100%" bg="white" borderColor="gray.200" borderWidth="1px" px="15px" py="15px">
      <Flex w="100%" direction="row" rounded="md" bgColor="secondary.alpha.15" px="10px" py="15px">
        <Flex direction="column">
          <Text color="gray.700" fontSize="16px" fontFamily="OpenSans-Light">
            {t('activeBookingTimer.timer.title')}
          </Text>
          <Text mt="6px" fontSize="18px" fontFamily="OpenSans-SemiBold" fontWeight={600}>
            {duration}
          </Text>
        </Flex>
        <Spacer />
        {role === UserRoles.FAMILY && (
          <>
            <Button
              bgColor="alert.danger.400"
              width={54}
              height={54}
              alignSelf="flex-end"
              p={0}
              mr={2}
              onPress={() => setShowStopTimer(true)}
              style={{
                position: 'relative'
              }}
            >
              <Text
                style={{ position: 'relative', top: '72%', left: '28%' }}
                color="white"
                fontWeight={600}
                fontSize="16px"
              >
                Stop
              </Text>
              {!isWeb && (
                <PlatformCircleSnail
                  style={{ position: 'relative', top: '-24%', left: '0%' }}
                  size={79}
                  borderWidth={0}
                  color={colors.secondary['400']}
                  thickness={5}
                />
              )}
            </Button>
            {activeTimer && showStopTimer && <RunningBookingOverlay onClose={() => setShowStopTimer(false)} />}
          </>
        )}
      </Flex>

      {role === UserRoles.FAMILY && (
        <>
          <Box mt="15px" mb="30px">
            <Text fontSize="14px" fontWeight="600">
              {t('activeBookingTimer.timer.explanationTitle')}
            </Text>
            <Text mt="5px" fontSize="14px">
              {t('activeBookingTimer.timer.explanationDescription')}
            </Text>
          </Box>

          <Divider />
          <HStack mt="15px" alignItems="center">
            <Text fontSize="14px">{t('activeBookingTimer.timer.noShow')}</Text>
            <Button
              ml="auto"
              size="xs"
              colorScheme="secondary"
              variant="outline"
              onPress={() => {
                navigate('CancelBooking', '/cancel-booking', {
                  bookingId: activeTimer?.booking.id,
                  cancellationReason: BookingCancellationReasonType.ANGEL_NO_SHOW
                })
              }}
            >
              {t('cancel')}
            </Button>
          </HStack>
        </>
      )}
    </Box>
  )
}
