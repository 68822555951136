const spacer = {
  xxs: '2px',
  xs: '4px',
  sm: '8px',
  md: '12px',
  lg: '16px',
  xl: '24px',
  xxl: '32px',
  xxxl: '64px'
};

const fontSize = {
  xxs: '11px',
  xs: '12px',
  sm: '14px',
  md: '15px',
  lg: '16px',
  xl: '18px',
  xxl: '22px'
};

const borderRadius = {
  xs: '1px',
  sm: '2px',
  md: '4px',
  lg: '8px'
};

export { spacer, fontSize, borderRadius };
