import React, { useState, useEffect, useRef } from 'react';
import { ActivityIndicator } from 'react-native';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { connect } from 'react-redux';

import { toggleLikeAngel, getAngelDetails, getAngelRatings } from '../../stores/actions/userAction';
import { getAvailability } from '../../stores/actions/angelPreferencesAvailabilityAction';
import { IconButton, AngelDetails, Header } from '../../components';
import { makeAPhoneCall } from '../../utils/functions';
import colors from '../../styles/colors';

const SafeAreaView = styled.SafeAreaView`
  flex: 1 1 auto;
  min-height: 100%;
`;
const HeaderRightView = styled.View`
  flex-direction: row;
`;

const AngelProfile = ({
  location,
  history,
  navigation,
  angelDetails,
  availability,
  getAngelDetailsData,
  getAngelRatingsData,
  getAvailabilityData,
  toggleLikeAngelPress
}) => {
  const { angelId, favorites } = location.state || {};
  const [numberOfCharacters, setNumberOfCharacters] = useState(120);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (angelDetails && angelDetails.id === angelId) {
      return;
    }
    getAngelDetailsData(angelId);
    getAngelRatingsData(angelId);
    getAvailabilityData(angelId);
  }, []);

  const onToggleLikeAngel = (angel_id, liked) => {
    toggleLikeAngelPress(angel_id, !liked);
  };

  if (!angelDetails || angelId !== angelDetails.id) {
    return <ActivityIndicator />;
  }
  const {
    angel_id,
    is_liked,
    video,
    ratings,
    allowed_chat,
    last_booked,
    phone,
    conversation_id
  } = angelDetails;

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          left={
            <IconButton
              iconSize="32px"
              iconName="icn-nav-left"
              iconColor={colors.secondary}
              onPress={() => history.goBack()}
            />
          }
          right={
            <HeaderRightView>
              <IconButton
                disabled={!allowed_chat || !conversation_id}
                margins="0px 10px 0px 0px"
                iconName="icn-chat"
                iconSize="32px"
                iconColor={allowed_chat ? colors.secondary : colors.grey03}
                onPress={() => {
                  history.push({
                    pathname: '/chat',
                    state: { conversationId: conversation_id }
                  });
                }}
              />

              <IconButton
                disabled={!last_booked || !phone}
                margins="0px 10px 0px 0px"
                iconName="icn-telephone"
                iconSize="32px"
                iconColor={last_booked && phone ? colors.secondary : colors.grey03}
                onPress={() => makeAPhoneCall(phone)}
              />

              {favorites && (
                <IconButton
                  margins="0px 10px 0px 0px"
                  iconName="icn-calendar-add"
                  iconSize="32px"
                  iconColor={colors.secondary}
                  onPress={() => {
                    // TODO
                  }}
                />
              )}
            </HeaderRightView>
          }
        />
      )}
      <AngelDetails
        scrollRef={scrollRef}
        calendarDisabled // TODO enable on mobile
        angel={angelDetails}
        ratings={ratings}
        availability={availability}
        onToggleLikePress={() => onToggleLikeAngel(angel_id, is_liked)}
        video={video}
        onCharacteristicsInfoPress={() => history.push('/book-screening-info')}
        onSkillsInfoPress={() => history.push('/book-explanation-features')}
        onCalendarPress={() => {
          // TODO
          // navigation.navigate('FamilyCalendar', { angelId: angel_id, angelName: first_name })
        }}
        onReviewsPress={() => scrollRef.current.scrollToEnd()}
        onReadMorePress={() => setNumberOfCharacters(numberOfCharacters + 1000)}
        numberOfCharacters={numberOfCharacters}
      />
    </SafeAreaView>
  );
};

AngelProfile.propTypes = {
  location: PropTypes.shape({ state: PropTypes.shape({}) }),
  history: PropTypes.shape({ push: PropTypes.func, goBack: PropTypes.func }),
  navigation: PropTypes.shape({
    navigate: PropTypes.func
  }),
  angelDetails: PropTypes.shape({
    id: PropTypes.number,
    angel_id: PropTypes.number,
    first_name: PropTypes.string,
    is_liked: PropTypes.bool,
    video: PropTypes.string,
    ratings: PropTypes.arrayOf(PropTypes.shape({})),
    allowed_chat: PropTypes.bool,
    last_booked: PropTypes.string,
    phone: PropTypes.string,
    conversation_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.string
  }),
  availability: PropTypes.arrayOf(PropTypes.shape({})),
  toggleLikeAngelPress: PropTypes.func.isRequired,
  getAngelDetailsData: PropTypes.func.isRequired,
  getAngelRatingsData: PropTypes.func.isRequired,
  getAvailabilityData: PropTypes.func.isRequired
};

AngelProfile.defaultProps = {
  location: {},
  history: null,
  navigation: null,
  angelDetails: null,
  availability: []
};

const mapStateToProps = ({ User, AngelPreferences }) => ({
  angelDetails: User.angelDetails,
  familyId: User.user.id,
  availability: AngelPreferences.availability
});

const mapDispatchToProps = {
  toggleLikeAngelPress: toggleLikeAngel,
  getAngelDetailsData: getAngelDetails,
  getAngelRatingsData: getAngelRatings,
  getAvailabilityData: getAvailability
};

export default connect(mapStateToProps, mapDispatchToProps)(AngelProfile);
