import React from 'react';
import { View } from 'react-native';

import propTypes from './Icon.props';
import iconMap from './icon-map';
import colors from '../../styles/colors';

const EmptyIcon = () => <View />;

const Icon = ({ name, size, color, ...rest }) => {
  const IconRender = iconMap[name] || EmptyIcon;
  return (
    <IconRender color={color} style={{ width: size, height: size }} {...rest} />
  );
};

Icon.propTypes = propTypes;

Icon.defaultProps = {
  size: 20,
  color: colors.black,
};

export default Icon;
