import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Switch } from '../../elements'
import { TextView } from '../Texts'
import colors from '../../styles/colors'

const AddToJobFinder = ({ value, onValueChange }) => {
  const [t] = useTranslation()

  return (
    <TextView
      flexDirection="column-reverse"
      alignItems="flex-start"
      paddings="20px 20px 10px 20px"
      text={t('bookScreensAddToJobFinderDescription')}
      fontSize="12px"
      textColor={colors.defaultBtnTextColor}
    >
      <TextView
        justifyContent="space-between"
        margins="0px 0px 10px 0px"
        width="100%"
        text={t('bookScreensAddToJobFinder')}
        fontFamily="Martel"
        fontSize="16px"
        fontWeight="600"
      >
        <Switch value={value} onValueChange={onValueChange} />
      </TextView>
    </TextView>
  )
}

AddToJobFinder.propTypes = {
  value: PropTypes.bool.isRequired,
  onValueChange: PropTypes.func.isRequired
}

export default AddToJobFinder
