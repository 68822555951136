import React from 'react'
import { Keyboard, KeyboardAvoidingView, Platform, TouchableOpacity } from 'react-native'
import { HStack, Text, Factory, IBoxProps } from 'native-base'
import { useKeyboard } from '@charlycares/features'
import { useTranslation } from 'react-i18next'

export default function DismissKeyboardBar(boxProps: IBoxProps) {
  const { t } = useTranslation()
  const { isKeyboardVisible } = useKeyboard()

  const Touchable = Factory(TouchableOpacity) as any

  return (
    <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'position' : 'height'} keyboardVerticalOffset={0}>
      {isKeyboardVisible && (
        <HStack justifyContent="flex-end" alignItems="center" px="5px" py="5px" bg="gray.100">
          <Touchable onPress={() => Keyboard.dismiss()} variant="text">
            <Text fontSize="16px" fontWeight="600" color="secondary.300">
              {t('done')}
            </Text>
          </Touchable>
        </HStack>
      )}
    </KeyboardAvoidingView>
  )
}
