import React from 'react';
import { Linking } from 'react-native';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';

import { Heading, Text } from '../../elements';
import { TextView, TextButton, IconButton, Header } from '../../components';
import colors from '../../styles/colors';

const SafeAreaView = styled.SafeAreaView`
  flex: 1 1 auto;
  justify-content: space-between;
`;

const View = styled.View`
  padding: 0 16px;
`;

const OutsideService = ({ history, navigation }) => {
  const [t] = useTranslation();

  if (navigation) {
    navigation.setOptions({
      headerLeft: (
        <IconButton
          margins="0px 0px 0px 10px"
          iconName="close"
          iconSize={32}
          iconColor={colors.secondary}
          onPress={navigation.goBack}
        />
      )
    });
  }

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          left={
            <IconButton
              margins="0px 0px 0px 10px"
              iconName="close"
              iconSize="32px"
              iconColor={colors.secondary}
              onPress={history.goBack}
            />
          }
        />
      )}

      <View>
        <Heading xl text={t('signUpAngelScreensOutsideOfService')} />
        <Text>{t('signUpAngelScreensOutsideOfServiceDescription')}</Text>
      </View>
      <TextView
        flexDirection="column-reverse"
        margins="0px 20px 0px 20px"
        height="auto"
        text={t('signUpAngelScreensWeWillRedirectYou')}
        fontSize="12px"
        textColor={colors.grey04}>
        <TextButton
          margins="0px 0px 10px 0px"
          width="100%"
          backgroundColor={colors.primary}
          text={t('signUpAngelScreensShowMeWhere')}
          onPress={() => {
            Linking.openURL('https://www.charlycares.com/en/babysitter-neighborhood');
          }}
        />
      </TextView>
    </SafeAreaView>
  );
};

OutsideService.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func
  }),
  navigation: PropTypes.shape({
    goBack: PropTypes.func,
    setOptions: PropTypes.func
  })
};

OutsideService.defaultProps = {
  history: null,
  navigation: null
};

export default OutsideService;
