import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import AsyncStorage from '@react-native-async-storage/async-storage'
import browserLangDetector from 'i18next-browser-languagedetector'
import nativeLangDetector from 'i18next-react-native-language-detector'
import { Platform } from 'react-native'

import { StorageKeys, isWeb, isDev } from '../const'
import { axiosBase, initLegacyStrings } from '../utils'

import en_GB from './en'
import nl_NL from './nl'

export const strings = initLegacyStrings(en_GB as any, nl_NL as any)

const customLanguageDetector: any = {
  init: Function.prototype,
  type: 'languageDetector',
  async: true,
  detect: async (callback: (lang: string | null) => void) => {
    const storedLang = await AsyncStorage.getItem(StorageKeys.LANG_CODE)
    __DEV__ && console.log('[detected language]', storedLang)
    callback(storedLang)
  },
  cacheUserLanguage: async (lang: string) => {
    await AsyncStorage.setItem(StorageKeys.LANG_CODE, lang)
  }
}

i18n
  .use(initReactI18next)
  .use(isWeb ? browserLangDetector : nativeLangDetector)
  .use(customLanguageDetector)
  .init({
    debug: isDev,
    fallbackLng: 'nl_NL',
    ns: ['translation'],
    defaultNS: 'translation',
    resources: {
      en_GB,
      nl_NL
    },
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true
    }
  })

type LoadTranslationsResponse = void | {
  en: { [key: string]: string }
  nl: { [key: string]: string }
}

export const loadTranslations = async (): Promise<LoadTranslationsResponse | undefined> => {
  const promises = [axiosBase.get('translations/nl_NL'), axiosBase.get('translations/en_GB')]
  const [nlTranslations, enTranslations] = await Promise.all(promises)

  if (nlTranslations.data && enTranslations.data) {
    const enTranslationValues: { [key: string]: string } = {}
    const nlTranslationValues: { [key: string]: string } = {}

    enTranslations.data.forEach(({ key, value }: { key: string; value: string }) => {
      enTranslationValues[key] = value
    })

    nlTranslations.data.forEach(({ key, value }: { key: string; value: string }) => {
      nlTranslationValues[key] = value
    })

    i18n.addResourceBundle('en_GB', 'translation', enTranslationValues, false, true)
    i18n.addResourceBundle('nl_NL', 'translation', nlTranslationValues, false, true)
    // legacy strings
    if (Platform.OS !== 'web') {
      strings?.setContent({
        en: enTranslationValues,
        nl: nlTranslationValues
      })
    }

    return {
      en: enTranslationValues,
      nl: nlTranslationValues
    }
  }
}

export default i18n
