import AsyncStorage from '@react-native-async-storage/async-storage'
import axios from 'axios'
import { Linking, Platform } from 'react-native'

import axiosInstance from '../../utils/axios'
import {
  SERVER_ERROR,
  SET_USER,
  LOGOUT,
  LOGIN_ERROR,
  SIGNUP_ERROR,
  PASSWORD_RESET_COMPLETED,
  PASSWORD_RESET_REQUESTED,
  REGISTRATION_VALIDATED,
  SET_VALIDATION_ERRORS,
  UPDATING_PROFILE,
  UPDATE_PROFILE_ERROR,
  UPLOADING_PROFILE_IMAGE,
  UPDATE_PROFILE_IMAGE,
  TOGGLE_LIKE_ANGEL,
  SET_MEMBERSHIP,
  APPLY_COUPON,
  UPDATING_MEMBERSHIP,
  UPDATE_MEMBERSHIP,
  CANCEL_MEMBERSHIP,
  GET_MEMBERSHIP_REASONS,
  SET_ONBOARDING_PROGRESS,
  UPDATING_REFERENCE,
  SAVE_REFERENCE,
  VIDEO_UPLOADED,
  UPDATE_PROFILE_VIDEO,
  SET_LOCALE,
  SET_INDICATORS,
  CLEAR_VALIDATION_ERRORS,
  SET_VALIDATION_ERROR,
  DELETE_APPLY_COUPON_RESPONSE,
  GET_ANGEL_DETAILS,
  GET_ANGEL_RATINGS,
  SET_ANNOUNCEMENTS_READ,
  GET_ANGEL_PUBLIC,
  ADD_ANGEL_REFERENCE_SUCCESS,
  SET_HAVE_A_LOOK_AROUND
} from '../types'
import { MEMBERSHIP_TYPES } from '@charlycares/shared'

const logError = (error, dispatch) => {
  if (error.response && error.response.data) {
    dispatch({ type: SERVER_ERROR, payload: error.response.data.message })
  } else {
    console.log({ error })
  }
}

export const login = (email, password) => dispatch => {
  dispatch({ type: LOGIN_ERROR, payload: null })
  axiosInstance
    .post('auth/sign_in', { email, password })
    .then(({ data }) => {
      if (data) {
        AsyncStorage.setItem('token', data.data.token)
        dispatch({
          type: SET_USER,
          payload: data.data
        })
      }
    })
    .catch(error => {
      dispatch({
        type: LOGIN_ERROR,
        payload: 'get profile error'
      })
    })
}

export const logout = () => async dispatch => {
  await AsyncStorage.removeItem('token')
  dispatch({ type: LOGOUT })
}

export const resetPassword = email => dispatch => {
  dispatch({ type: PASSWORD_RESET_COMPLETED })
  axiosInstance
    .post('auth/reset_sms', email)
    .then(response => {
      if (response.data) {
        dispatch({
          type: PASSWORD_RESET_REQUESTED,
          payload: response.data
        })
      }
    })
    .catch(error => {
      if (error.response && error.response.data) {
        dispatch({
          type: PASSWORD_RESET_REQUESTED,
          payload: error.response.data
        })
      } else {
        logError(error, dispatch)
      }
    })
}

export const requestPasswordReset = data => dispatch => {
  dispatch({ type: PASSWORD_RESET_COMPLETED })
  axiosInstance
    .post('/password/validate_email', data)
    .then(response => {
      if (response.data) {
        dispatch({
          type: PASSWORD_RESET_REQUESTED,
          payload: { requestSent: true }
        })
      }
    })
    .catch(error => {
      if (error.response && error.response.data) {
        dispatch({
          type: PASSWORD_RESET_REQUESTED,
          payload: { requestSent: false, message: error.response.data.message }
        })
      } else {
        logError(error, dispatch)
      }
    })
}

export const submitPasswordReset = (verificationCode, password) => async dispatch => {
  try {
    const response = await axiosInstance.post('password/reset', {
      token: verificationCode,
      password,
      password_confirmation: password
    })

    dispatch({ type: PASSWORD_RESET_COMPLETED, payload: { success: true, payload: response } })
  } catch (error) {
    dispatch({
      type: PASSWORD_RESET_COMPLETED,
      payload: error.response.data
    })
  }
}

export const getIndicators = () => dispatch => {
  axiosInstance
    .get('indicators')
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: SET_INDICATORS,
          payload: data.data
        })
      }
    })
    .catch(error => logError(error, dispatch))
}

export const getUser = () => dispatch => {
  axiosInstance
    .get('profile')
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: SET_USER,
          payload: data.data
        })

        dispatch(getIndicators())
      }
    })
    .catch(error => {
      dispatch({
        type: LOGIN_ERROR,
        payload: 'get profile error'
      })
    })
}

export const setValidationErrors = errors => dispatch => {
  dispatch({
    type: SET_VALIDATION_ERRORS,
    payload: errors
  })
}

export const validateRegistration = registrationData => dispatch => {
  setValidationErrors()
  axiosInstance
    .post('validate', registrationData)
    .then(({ data }) => {
      if (data) {
        dispatch({ type: REGISTRATION_VALIDATED })
      }
    })
    .catch(error => {
      if (error.response && error.response.data) {
        const validationErrors = error.response.data.message

        if (!validationErrors.outside_area) {
          Object.keys(validationErrors).forEach(key => {
            const [firstError] = validationErrors[key]
            validationErrors[key] = firstError
          })
        }

        dispatch({
          type: SET_VALIDATION_ERRORS,
          payload: validationErrors
        })
      } else {
        logError(error, dispatch)
      }
    })
}

export const signup = (role, user) => dispatch => {
  dispatch({ type: SIGNUP_ERROR, payload: null })

  axiosInstance
    .post(`auth/${role}_sign_up`, user)
    .then(async ({ data }) => {
      if (data) {
        await AsyncStorage.setItem('token', data.data.token)
        dispatch(getUser())
      }
    })
    .catch(async error => {
      if (error.response && error.response.data) {
        const validationErrors = error.response.data.message

        if (validationErrors === 'invalid_postcode') {
          await AsyncStorage.setItem('token', error.response.data.data.token)
          dispatch(getUser())
          return
        }

        dispatch({
          type: SIGNUP_ERROR,
          payload: validationErrors
        })
      }
    })
}

export const toggleLikeAngel = (angelId, like) => dispatch => {
  axiosInstance
    .post('angel/like', { angel_id: angelId, like })
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: TOGGLE_LIKE_ANGEL,
          payload: like
        })
      }
    })
    .catch(error => logError(error, dispatch))
}

export const resetUpdateProfile = () => dispatch => {
  dispatch({
    type: UPDATING_PROFILE,
    payload: { updatingProfile: false, profileUpdated: false }
  })
}

export const updateProfile = profile => (dispatch, state) => {
  const {
    User: { user }
  } = state()

  dispatch({ type: UPDATING_PROFILE })
  axiosInstance
    .put(`profile/${user.role}`, { ...profile })
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: SET_USER,
          payload: data.data
        })
      }
    })
    .catch(error => {
      if (error.response && error.response.data) {
        dispatch({
          type: UPDATE_PROFILE_ERROR,
          payload: error.response.data.message
        })
      } else {
        logError(error, dispatch)
      }
    })
}

export const updateProfileImage = file => dispatch => {
  dispatch({ type: UPLOADING_PROFILE_IMAGE })
  axiosInstance
    .post('family/photo', file)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: UPDATE_PROFILE_IMAGE,
          payload: data.data.image
        })
      }
    })
    .catch(error => {
      if (error.response && error.response.data) {
        dispatch({
          type: UPDATE_PROFILE_ERROR,
          payload: error.response.data.message
        })
      } else {
        logError(error, dispatch)
      }
    })
}

export const reactivateMembership = () => dispatch => {
  dispatch({ type: UPDATING_PROFILE })
  axiosInstance
    .post('membership/reactivate')
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: SET_MEMBERSHIP,
          payload: data.data
        })
      }
    })
    .catch(error => {
      if (error.response && error.data) {
        dispatch({
          type: UPDATE_PROFILE_ERROR,
          payload: error.response.data.message
        })
      }
    })
}

export const applyCoupon = coupon => dispatch => {
  axiosInstance
    .post('membership/coupon', { coupon })
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: APPLY_COUPON,
          payload: data.data
        })
      }
    })
    .catch(error => logError(error, dispatch))
}

export const deleteApplyCouponResponse = () => dispatch => dispatch({ type: DELETE_APPLY_COUPON_RESPONSE })

export const updateMembership = (membership, terms) => dispatch => {
  dispatch({ type: UPDATING_MEMBERSHIP, payload: true })
  axiosInstance
    .put('membership', { membership, terms })
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: UPDATE_MEMBERSHIP,
          payload: data.data
        })
      }
    })
    .catch(error => {
      dispatch({ type: UPDATING_MEMBERSHIP, payload: false })
      logError(error, dispatch)
    })
}

export const cancelMembership = (reasons, add_reason) => dispatch => {
  dispatch({ type: UPDATING_MEMBERSHIP })
  axiosInstance
    .post('membership/cancel', { reasons, add_reason })
    .then(data => {
      if (data) {
        dispatch({
          type: CANCEL_MEMBERSHIP,
          payload: data.data
        })
      }
    })
    .catch(error => logError(error, dispatch))
}

export const getMembershipReasons = () => dispatch => {
  axiosInstance
    .get('membership/reasons')
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_MEMBERSHIP_REASONS,
          payload: data
        })
      }
    })
    .catch(error => logError(error, dispatch))
}

export const setOnboardingProgress = progress => dispatch => {
  dispatch({
    type: SET_ONBOARDING_PROGRESS,
    payload: progress
  })
}

export const saveReference = reference => dispatch => {
  dispatch({ type: UPDATING_REFERENCE })
  axiosInstance
    .post('reference', reference)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: SAVE_REFERENCE,
          payload: data.data
        })
      }
    })
    .catch(error => logError(error, dispatch))
}

export const updateProfileVideo = (url, video) => dispatch => {
  dispatch({ type: VIDEO_UPLOADED, payload: false })
  const fileParts = video.name.split('.')
  const fileType = fileParts[1]
  axios
    .put(url, video, {
      headers: {
        'Content-Type': fileType
      },
      transformRequest: [
        (data, headers) => {
          delete headers.common.Authorization
          return data
        }
      ]
    })
    .then(res => {
      console.log({ res })
      dispatch({ type: UPDATING_PROFILE })
      axiosInstance
        .post('angel/videoUploaded')
        .then(({ data }) => {
          console.log({ data })
          if (data) {
            dispatch({
              type: UPDATE_PROFILE_VIDEO,
              payload: data.data
            })
          }
        })
        .catch(error => {
          console.log({ error })
          if (error.response && error.response.data) {
            dispatch({
              type: UPDATE_PROFILE_ERROR,
              payload: error.response.data.message
            })
          } else {
            logError(error, dispatch)
          }
        })
    })
    .catch(error => logError(error, dispatch))
}

export const setLocale = locale => dispatch => {
  axiosInstance
    .put('user/locale', { locale })
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: SET_LOCALE,
          payload: data.data.locale
        })
      }
    })
    .catch(error => logError(error, dispatch))
}

export const clearValidationErrors = field => dispatch => {
  dispatch({
    type: CLEAR_VALIDATION_ERRORS,
    payload: field
  })
}

export const setValidationError = error => dispatch => {
  dispatch({
    type: SET_VALIDATION_ERROR,
    payload: error
  })
}

export const getAngelDetails = angelId => dispatch => {
  axiosInstance
    .post(`angel/profile/${angelId}`)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_ANGEL_DETAILS,
          payload: data.data
        })
      }
    })
    .catch(error => logError(error, dispatch))
}

export const getAngelRatings = angelId => dispatch => {
  axiosInstance
    .get(`angel/ratings/${angelId}`)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_ANGEL_RATINGS,
          payload: data.data
        })
      }
    })
    .catch(error => logError(error, dispatch))
}

export const setAnnouncementRead = () => dispatch => {
  axiosInstance
    .get('announcement')
    .then(() => {
      dispatch({ type: SET_ANNOUNCEMENTS_READ })
    })
    .catch(error => logError(error, dispatch))
}

export const getAngelPublic = angelId => dispatch => {
  axiosInstance
    .get(`angel/get_data/${angelId}`)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_ANGEL_PUBLIC,
          payload: data
        })
      }
    })
    .catch(error => logError(error, dispatch))
}

export const addAngelReference = data => dispatch => {
  axiosInstance
    .post(`angel_reference`, data)
    .then(() => {
      dispatch({
        type: ADD_ANGEL_REFERENCE_SUCCESS
      })
    })
    .catch(error => logError(error, dispatch))
}

export const setHaveALookAround = haveALookAround => dispatch => {
  dispatch({
    type: SET_HAVE_A_LOOK_AROUND,
    payload: haveALookAround
  })
}

export const subscribeMembership = payment_method => dispatch => {
  dispatch({ type: UPDATING_MEMBERSHIP, payload: payment_method })
  axiosInstance
    .post('v2/memberships/subscription', {
      payment_method,
      membership_type: MEMBERSHIP_TYPES.FLEXIBLE_TRIAL,
      channel: Platform.OS
    })
    .then(({ data }) => {
      window.open(data.data.redirect_url, '_self')
    })
    .catch(err => logError(err, dispatch))
}
