import styled from 'styled-components/native'
import theme from '../styles/theme'

const Text = styled.Text`
  ${({ flex }) => flex && { flex }};
  ${({ alignSelf }) => alignSelf && { 'align-self': alignSelf }};
  margin: ${({ margins }) => margins || '0px'};
  padding: ${({ paddings }) => paddings || '0px'};
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  color: ${({ color }) =>
    color ||
    (props => {
      if (props.whiteColor) return theme.colors.white
      if (props.primaryColor) return theme.colors.primary
      if (props.lightColor) return theme.textColorLight
      if (props.lighterColor) return theme.textColorLighter
      if (props.lightestColor) return theme.textColorLightest
      return theme.textColorBase
    })};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  opacity: ${({ opacity }) => opacity || 1};
  font-family: ${props => theme.fontFamilyBase};
  font-size: ${({ fontSize }) =>
    fontSize ||
    (props => {
      if (props.lg) return theme.fontSize.lg
      if (props.sm) return theme.fontSize.sm
      if (props.xs) return theme.fontSize.xs
      if (props.xxs) return theme.fontSize.xxs
      return theme.fontSize.md
    })};
  font-weight: ${({ fontWeight }) =>
    fontWeight ||
    (props => {
      if (props.bold) return theme.textFontWeightBold
      return theme.textFontWeight
    })};
  text-align: ${({ textAlign }) => textAlign || 'auto'};
  ${({ lineHeight }) => lineHeight && { 'line-height': lineHeight }};
  ${({ transform }) => transform && { 'text-transform': transform }};
`

export default Text
