import React from 'react'
import { Box, HStack, IBoxProps, Button } from 'native-base'
import { useTranslation } from 'react-i18next'
import { useSetLocale } from '../../hooks'

type Props = IBoxProps

export default function LanguageSwitch({ ...boxProps }: Props) {
  const { i18n } = useTranslation()
  const { setLocale } = useSetLocale()

  return (
    <Box {...boxProps}>
      <HStack space="10px" alignItems="center">
        <Button
          onPress={() => setLocale('nl_NL')}
          variant="text"
          _text={{
            color: i18n.language === 'nl_NL' ? 'primary.400' : 'gray.600',
            fontWeight: i18n.language === 'nl_NL' ? 600 : 400,
            fontSize: '16px',
            textTransform: 'uppercase'
          }}
        >
          {'nl'}
        </Button>
        <Box h="80%" w="1px" bg="gray.600" />
        <Button
          onPress={() => setLocale('en_GB')}
          variant="text"
          _text={{
            color: i18n.language === 'en_GB' ? 'primary.400' : 'gray.600',
            fontWeight: i18n.language === 'en_GB' ? 600 : 400,
            fontSize: '16px',
            textTransform: 'uppercase'
          }}
        >
          {'en'}
        </Button>
      </HStack>
    </Box>
  )
}
