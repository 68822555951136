import { GiftedChat } from 'react-native-gifted-chat';
import styled from 'styled-components/native';
import theme from '../../styles/theme';

const StyledGiftedChat = styled(GiftedChat).attrs({
  textInputStyle: {
    width: '100%',
    color: theme.colors.primaryText,
    fontSize: '18px',
    lineHeight: 24
  },
  messagesContainerStyle: {
    backgroundColor: theme.chatBackgroundColor
  }
})``;

export default StyledGiftedChat;
