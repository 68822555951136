import AddIcon from './AddIcon'
import AppStoreIcon from './AppStoreIcon'
import AvatarIcon from './AvatarIcon'
import BtnCheckIcon from './BtnCheckIcon'
import BtnCloseSmIcon from './BtnCloseSmIcon'
import BtnCloseIcon from './BtnCloseIcon'
import BtnMinusIcon from './BtnMinusIcon'
import BtnPlayIcon from './BtnPlayIcon'
import BtnPlusIcon from './BtnPlusIcon'
import CatIcon from './CatIcon'
import CheckBtnIcon from './CheckBtnIcon'
import CloseBtnIcon from './CloseBtnIcon'
import CloseIcon from './CloseIcon'
import CreditCardIcon from './CreditCardIcon'
import DogIcon from './DogIcon'
import ElderlyCareIcon from './ElderlyCareIcon'
import EyeOpenIcon from './EyeOpenIcon'
import EyeShutIcon from './EyeShutIcon'
import FaceFrownIcon from './FaceFrownIcon'
import FaceNeutralIcon from './FaceNeutralIcon'
import FavoriteInactiveIcon from './FavoriteInactiveIcon'
import FavoritesIcon from './FavoritesIcon'
import FixedCalendarIcon from './FixedCalendarIcon'
import FlatIcon from './FlatIcon'
import GooglePlayIcon from './GooglePlayIcon'
import HeartIcon from './HeartIcon'
import IcnAlertOffIcon from './IcnAlertOffIcon'
import IcnAlertIcon from './IcnAlertIcon'
import IcnBabyBottleIcon from './IcnBabyBottleIcon'
import IcnBackpackIcon from './IcnBackpackIcon'
import IcnBlockIcon from './IcnBlockIcon'
import IcnBookingsIcon from './IcnBookingsIcon'
import IcnCalculateIcon from './IcnCalculateIcon'
import IcnCalendarAcceptedIcon from './IcnCalendarAcceptedIcon'
import IcnCalendarAddIcon from './IcnCalendarAddIcon'
import IcnCalendarCanceledIcon from './IcnCalendarCanceledIcon'
import IcnCalendarSyncIcon from './IcnCalendarSyncIcon'
import IcnCalendarIcon from './IcnCalendarIcon'
import IcnCancelIcon from './IcnCancelIcon'
import IcnCarIcon from './IcnCarIcon'
import IcnCaretDownIcon from './IcnCaretDownIcon'
import IcnCaretLeftIcon from './IcnCaretLeftIcon'
import IcnCaretRightIcon from './IcnCaretRightIcon'
import IcnCaretUpIcon from './IcnCaretUpIcon'
import IcnCatIcon from './IcnCatIcon'
import IcnChatIcon from './IcnChatIcon'
import IcnCheckIcon from './IcnCheckIcon'
import IcnConversationIcon from './IcnConversationIcon'
import IcnCookingIcon from './IcnCookingIcon'
import IcnCreditcardIcon from './IcnCreditcardIcon'
import IcnCyclingIcon from './IcnCyclingIcon'
import IcnDayIcon from './IcnDayIcon'
import IcnDiningIcon from './IcnDiningIcon'
import IcnDishesIcon from './IcnDishesIcon'
import IcnDogIcon from './IcnDogIcon'
import IcnDrumsIcon from './IcnDrumsIcon'
import IcnEditIcon from './IcnEditIcon'
import IcnEuroIcon from './IcnEuroIcon'
import IcnEyeOpenIcon from './IcnEyeOpenIcon'
import IcnFacebookIcon from './IcnFacebookIcon'
import IcnFamilyIcon from './IcnFamilyIcon'
import IcnFavoriteFillIcon from './IcnFavoriteFillIcon'
import IcnFavoriteInactiveIcon from './IcnFavoriteInactiveIcon'
import IcnFavoritesIcon from './IcnFavoritesIcon'
import IcnFilterIcon from './IcnFilterIcon'
import IcnFirstAidIcon from './IcnFirstAidIcon'
import IcnFluteIcon from './IcnFluteIcon'
import IcnGiftIcon from './IcnGiftIcon'
import IcnGuitarIcon from './IcnGuitarIcon'
import IcnHouseIcon from './IcnHouseIcon'
import IcnImageIcon from './IcnImageIcon'
import IcnInsuranceIcon from './IcnInsuranceIcon'
import IcnIroningIcon from './IcnIroningIcon'
import IcnKidsIcon from './IcnKidsIcon'
import IcnLanguageDeIcon from './IcnLanguageDeIcon'
import IcnLanguageEnIcon from './IcnLanguageEnIcon'
import IcnLanguageFrIcon from './IcnLanguageFrIcon'
import IcnLanguageItIcon from './IcnLanguageItIcon'
import IcnLanguageNlIcon from './IcnLanguageNlIcon'
import IcnLanguageSpIcon from './IcnLanguageSpIcon'
import IcnLastMinuteIcon from './IcnLastMinuteIcon'
import IcnLaundryIcon from './IcnLaundryIcon'
import IcnLocationIcon from './IcnLocationIcon'
import IcnMathIcon from './IcnMathIcon'
import IcnMicrophoneIcon from './IcnMicrophoneIcon'
import IcnMobilePhoneIcon from './IcnMobilePhoneIcon'
import IcnMorningIcon from './IcnMorningIcon'
import IcnNavAddIcon from './IcnNavAddIcon'
import IcnNavDownIcon from './IcnNavDownIcon'
import IcnNavEndIcon from './IcnNavEndIcon'
import IcnNavLeftIcon from './IcnNavLeftIcon'
import IcnNavRightIcon from './IcnNavRightIcon'
import IcnNavStartIcon from './IcnNavStartIcon'
import IcnNavUpIcon from './IcnNavUpIcon'
import IcnNightIcon from './IcnNightIcon'
import IcnPaymentsIcon from './IcnPaymentsIcon'
import IcnPeopleIcon from './IcnPeopleIcon'
import IcnPhotoIcon from './IcnPhotoIcon'
import IcnPianoIcon from './IcnPianoIcon'
import IcnPostIcon from './IcnPostIcon'
import IcnProfileIcon from './IcnProfileIcon'
import IcnReadingIcon from './IcnReadingIcon'
import IcnRepeatIcon from './IcnRepeatIcon'
import IcnSaxophoneIcon from './IcnSaxophoneIcon'
import IcnSearchIcon from './IcnSearchIcon'
import IcnSendIcon from './IcnSendIcon'
import IcnSettingsIcon from './IcnSettingsIcon'
import IcnShareIcon from './IcnShareIcon'
import IcnSingingIcon from './IcnSingingIcon'
import IcnSmallDeclinedIcon from './IcnSmallDeclinedIcon'
import IcnSmallInfoIcon from './IcnSmallInfoIcon'
import IcnSmallUnknownIcon from './IcnSmallUnknownIcon'
import IcnTelephoneFillIcon from './IcnTelephoneFillIcon'
import IcnTelephoneIcon from './IcnTelephoneIcon'
import IcnTimeIcon from './IcnTimeIcon'
import IcnTrashIcon from './IcnTrashIcon'
import IcnVacuumIcon from './IcnVacuumIcon'
import IcnValidatedIcon from './IcnValidatedIcon'
import IcnViolinIcon from './IcnViolinIcon'
import IcnWalkingTheDogIcon from './IcnWalkingTheDogIcon'
import IdealIcon from './IdealIcon'
import IdentityVerifiedIcon from './IdentityVerifiedIcon'
import LogoIcnIcon from './LogoIcnIcon'
import LogoIcon from './LogoIcon'
import MeetAndGreetIcon from './MeetAndGreetIcon'
import MeetIcon from './MeetIcon'
import MinusIcon from './MinusIcon'
import NavCloseIcon from './NavCloseIcon'
import NotAvailableCalendarIcon from './NotAvailableCalendarIcon'
import PaymentCardIcon from './PaymentCardIcon'
import PhotoBtnIcon from './PhotoBtnIcon'
import PhotoLibBtnIcon from './PhotoLibBtnIcon'
import ReadingIcon from './ReadingIcon'
import SchoolIcon from './SchoolIcon'
import SearchIcon from './SearchIcon'
import SettingsIcon from './SettingsIcon'
import SortIcon from './SortIcon'
import StandbyIcon from './StandbyIcon'
import StarIcon from './StarIcon'
import TimeIcon from './TimeIcon'
import TutoringIcon from './TutoringIcon'
import UnverifiedAngelIcon from './UnverifiedAngelIcon'
import VacationIcon from './VacationIcon'
import ValidatedIcon from './ValidatedIcon'
import VaryingCalendarIcon from './VaryingCalendarIcon'
import VerifiedAngelIcon from './VerifiedAngelIcon'
import VerifiedFalseIcon from './VerifiedFalseIcon'
import VerifiedIcon from './VerifiedIcon'
import VideoPlayIcon from './VideoPlayIcon'
import WalletIcon from './WalletIcon'
import WarningIcon from './WarningIcon'

const map = {
"add": AddIcon, 
"app-store": AppStoreIcon, 
"avatar": AvatarIcon, 
"btn-check": BtnCheckIcon, 
"btn-close-sm": BtnCloseSmIcon, 
"btn-close": BtnCloseIcon, 
"btn-minus": BtnMinusIcon, 
"btn-play": BtnPlayIcon, 
"btn-plus": BtnPlusIcon, 
"cat": CatIcon, 
"check-btn": CheckBtnIcon, 
"close-btn": CloseBtnIcon, 
"close": CloseIcon, 
"credit-card": CreditCardIcon, 
"dog": DogIcon, 
"elderly-care": ElderlyCareIcon, 
"eye-open": EyeOpenIcon, 
"eye-shut": EyeShutIcon, 
"face-frown": FaceFrownIcon, 
"face-neutral": FaceNeutralIcon, 
"favorite-inactive": FavoriteInactiveIcon, 
"favorites": FavoritesIcon, 
"fixed-calendar": FixedCalendarIcon, 
"flat": FlatIcon, 
"google-play": GooglePlayIcon, 
"heart": HeartIcon, 
"icn-alert-off": IcnAlertOffIcon, 
"icn-alert": IcnAlertIcon, 
"icn-baby-bottle": IcnBabyBottleIcon, 
"icn-backpack": IcnBackpackIcon, 
"icn-block": IcnBlockIcon, 
"icn-bookings": IcnBookingsIcon, 
"icn-calculate": IcnCalculateIcon, 
"icn-calendar-accepted": IcnCalendarAcceptedIcon, 
"icn-calendar-add": IcnCalendarAddIcon, 
"icn-calendar-canceled": IcnCalendarCanceledIcon, 
"icn-calendar-sync": IcnCalendarSyncIcon, 
"icn-calendar": IcnCalendarIcon, 
"icn-cancel": IcnCancelIcon, 
"icn-car": IcnCarIcon, 
"icn-caret-down": IcnCaretDownIcon, 
"icn-caret-left": IcnCaretLeftIcon, 
"icn-caret-right": IcnCaretRightIcon, 
"icn-caret-up": IcnCaretUpIcon, 
"icn-cat": IcnCatIcon, 
"icn-chat": IcnChatIcon, 
"icn-check": IcnCheckIcon, 
"icn-conversation": IcnConversationIcon, 
"icn-cooking": IcnCookingIcon, 
"icn-creditcard": IcnCreditcardIcon, 
"icn-cycling": IcnCyclingIcon, 
"icn-day": IcnDayIcon, 
"icn-dining": IcnDiningIcon, 
"icn-dishes": IcnDishesIcon, 
"icn-dog": IcnDogIcon, 
"icn-drums": IcnDrumsIcon, 
"icn-edit": IcnEditIcon, 
"icn-euro": IcnEuroIcon, 
"icn-eye-open": IcnEyeOpenIcon, 
"icn-facebook": IcnFacebookIcon, 
"icn-family": IcnFamilyIcon, 
"icn-favorite-fill": IcnFavoriteFillIcon, 
"icn-favorite-inactive": IcnFavoriteInactiveIcon, 
"icn-favorites": IcnFavoritesIcon, 
"icn-filter": IcnFilterIcon, 
"icn-first-aid": IcnFirstAidIcon, 
"icn-flute": IcnFluteIcon, 
"icn-gift": IcnGiftIcon, 
"icn-guitar": IcnGuitarIcon, 
"icn-house": IcnHouseIcon, 
"icn-image": IcnImageIcon, 
"icn-insurance": IcnInsuranceIcon, 
"icn-ironing": IcnIroningIcon, 
"icn-kids": IcnKidsIcon, 
"icn-language-de": IcnLanguageDeIcon, 
"icn-language-en": IcnLanguageEnIcon, 
"icn-language-fr": IcnLanguageFrIcon, 
"icn-language-it": IcnLanguageItIcon, 
"icn-language-nl": IcnLanguageNlIcon, 
"icn-language-sp": IcnLanguageSpIcon, 
"icn-last-minute": IcnLastMinuteIcon, 
"icn-laundry": IcnLaundryIcon, 
"icn-location": IcnLocationIcon, 
"icn-math": IcnMathIcon, 
"icn-microphone": IcnMicrophoneIcon, 
"icn-mobile-phone": IcnMobilePhoneIcon, 
"icn-morning": IcnMorningIcon, 
"icn-nav-add": IcnNavAddIcon, 
"icn-nav-down": IcnNavDownIcon, 
"icn-nav-end": IcnNavEndIcon, 
"icn-nav-left": IcnNavLeftIcon, 
"icn-nav-right": IcnNavRightIcon, 
"icn-nav-start": IcnNavStartIcon, 
"icn-nav-up": IcnNavUpIcon, 
"icn-night": IcnNightIcon, 
"icn-payments": IcnPaymentsIcon, 
"icn-people": IcnPeopleIcon, 
"icn-photo": IcnPhotoIcon, 
"icn-piano": IcnPianoIcon, 
"icn-post": IcnPostIcon, 
"icn-profile": IcnProfileIcon, 
"icn-reading": IcnReadingIcon, 
"icn-repeat": IcnRepeatIcon, 
"icn-saxophone": IcnSaxophoneIcon, 
"icn-search": IcnSearchIcon, 
"icn-send": IcnSendIcon, 
"icn-settings": IcnSettingsIcon, 
"icn-share": IcnShareIcon, 
"icn-singing": IcnSingingIcon, 
"icn-small-declined": IcnSmallDeclinedIcon, 
"icn-small-info": IcnSmallInfoIcon, 
"icn-small-unknown": IcnSmallUnknownIcon, 
"icn-telephone-fill": IcnTelephoneFillIcon, 
"icn-telephone": IcnTelephoneIcon, 
"icn-time": IcnTimeIcon, 
"icn-trash": IcnTrashIcon, 
"icn-vacuum": IcnVacuumIcon, 
"icn-validated": IcnValidatedIcon, 
"icn-violin": IcnViolinIcon, 
"icn-walking-the-dog": IcnWalkingTheDogIcon, 
"ideal": IdealIcon, 
"identity-verified": IdentityVerifiedIcon, 
"logo-icn": LogoIcnIcon, 
"logo": LogoIcon, 
"meet-and-greet": MeetAndGreetIcon, 
"meet": MeetIcon, 
"minus": MinusIcon, 
"nav-close": NavCloseIcon, 
"not-available-calendar": NotAvailableCalendarIcon, 
"payment-card": PaymentCardIcon, 
"photo-btn": PhotoBtnIcon, 
"photo-lib-btn": PhotoLibBtnIcon, 
"reading": ReadingIcon, 
"school": SchoolIcon, 
"search": SearchIcon, 
"settings": SettingsIcon, 
"sort": SortIcon, 
"standby": StandbyIcon, 
"star": StarIcon, 
"time": TimeIcon, 
"tutoring": TutoringIcon, 
"unverified-angel": UnverifiedAngelIcon, 
"vacation": VacationIcon, 
"validated": ValidatedIcon, 
"varying-calendar": VaryingCalendarIcon, 
"verified-angel": VerifiedAngelIcon, 
"verified-false": VerifiedFalseIcon, 
"verified": VerifiedIcon, 
"video-play": VideoPlayIcon, 
"wallet": WalletIcon, 
"warning": WarningIcon, 
};

export default map