import {
  GET_ANGEL_DASHBOARD,
  GET_FAMILY_DASHBOARD,
  GET_ANGEL_LATEST_REVIEWS,
  GET_AVAILABLE_ANGELS,
  FETCHING_AVAILABLE_ANGElS,
  GET_STANDBY_ANGELS,
  FETCHING_STANDBY_ANGElS,
  TOGGLE_LIKE_DASHBOARD_ANGELS
} from '../types';

const initialState = {
  angel: null,
  reviews: [],
  availableAngels: null,
  standbyAngels: null,
  fetchingStandbyAngels: false,
  fetchingAvailableAngels: false
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_ANGEL_DASHBOARD:
      return {
        ...state,
        angel: payload
      };
    case GET_FAMILY_DASHBOARD:
      return {
        ...state,
        family: payload
      };
    case GET_ANGEL_LATEST_REVIEWS:
      return {
        ...state,
        reviews: payload
      };
    case GET_AVAILABLE_ANGELS:
      return {
        ...state,
        fetchingAvailableAngels: false,
        availableAngels: payload
      };
    case FETCHING_AVAILABLE_ANGElS:
      return {
        ...state,
        fetchingAvailableAngels: true
      };
    case GET_STANDBY_ANGELS:
      return {
        ...state,
        fetchingStandbyAngels: false,
        standbyAngels: payload
      };
    case FETCHING_STANDBY_ANGElS:
      return {
        ...state,
        fetchingStandbyAngels: true
      };
    case TOGGLE_LIKE_DASHBOARD_ANGELS:
      return {
        ...state,
        availableAngels: state.availableAngels && {
          ...state.availableAngels,
          data: state.availableAngels.data.map((angel) => {
            if (angel.id === payload.angelId) {
              return { ...angel, is_liked: payload.like };
            }
            return angel;
          })
        },
        standbyAngels: state.standbyAngels && {
          ...state.standbyAngels,
          data: state.standbyAngels.data.map((angel) => {
            if (angel.id === payload.angelId) {
              return { ...angel, is_liked: payload.like };
            }
            return angel;
          })
        }
      };
    default:
      return state;
  }
}
