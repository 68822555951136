import { AngelBookingStatus, BookingServiceType, BookingStates, IBookingDetails, UserRoles } from '@charlycares/shared'
import { useGetUserQuery } from '@charlycares/data-access'
import { getArchivedInvitations, getPendingOffers, isAngelBookingArchived, isBookingArchived } from './booking.utils'

const useBookingState = (booking: IBookingDetails): { message: string; color?: string; action?: string } => {
  const { data: user } = useGetUserQuery()

  const getFamilyStates = () => {
    const hasOffer = getPendingOffers(booking).length > 0
    const activeInvitations = booking.invitations.length - getArchivedInvitations(booking).length

    if (booking.current_state === BookingStates.ACCEPTED) {
      return {
        message: 'bookingState_accepted',
        color: 'alert.success.400'
      }
    } else if (isBookingArchived(booking)) {
      return {
        message: `bookingState_${booking.current_state}`,
        color: 'gray.500'
      }
    } else {
      if (booking.current_state === BookingStates.PENDING_PAYMENT) {
        return {
          message: 'bookingState_paymentIssue',
          color: 'alert.danger.400',
          action: 'bookingAction_solveIssue'
        }
      } else if (booking.current_state === BookingStates.PENDING_EDIT) {
        return {
          message: 'bookingState_pendingEdit',
          color: 'alert.warning.400'
        }
      } else if (hasOffer) {
        return {
          message: booking.on_job_board ? 'bookingState_onJobboard' : 'bookingState_notOnJobboard',
          color: 'alert.warning.400',
          action: 'bookingAction_viewOffer'
        }
      } else if (activeInvitations > 0) {
        return {
          message: 'bookingState_awaitingResponse',
          color: 'alert.warning.400',
          action:
            booking.maximum_amount_of_angels && booking.maximum_amount_of_angels > 0 && booking.invitations_allowed
              ? 'bookingAction_addAngel'
              : undefined
        }
      } else {
        return {
          message: booking.on_job_board ? 'bookingState_onJobboard' : 'bookingState_notOnJobboard',
          color: 'alert.warning.400',
          action: booking.invitations_allowed ? 'bookingAction_addAngel' : undefined
        }
      }
    }
  }

  const getAngelStates = () => {
    if (booking.angel_status === AngelBookingStatus.ACCEPTED) {
      return {
        message: 'bookingState_accepted',
        color: 'alert.success.400'
      }
    } else if (isAngelBookingArchived(booking)) {
      return {
        message: `bookingState_${booking.angel_status}`,
        color: 'gray.500'
      }
    } else {
      let action
      switch (booking.angel_status) {
        case AngelBookingStatus.PENDING_EDIT:
          action = 'bookingAction_viewChanges'
          break
        case AngelBookingStatus.PENDING_INVITATION:
          action = 'bookingAction_viewInvitation'
          break
      }

      return {
        message: `bookingState_${booking.angel_status}`,
        color: 'alert.warning.400',
        action
      }
    }
  }

  return user?.role === UserRoles.FAMILY ? getFamilyStates() : getAngelStates()
}

export default useBookingState
