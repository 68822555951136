import {
  GET_JOBS,
  FETCHING_JOBS,
  ADD_TO_JOBLIST,
  ADDING_TO_JOBLIST,
  ADD_TO_JOBLIST_ERROR
} from '../types';

const initialState = {
  fetching: false,
  jobs: [],
  addedToJoblist: false,
  addToJoblistError: null
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_JOBS:
      return {
        ...state,
        jobs: payload,
        serverError: false,
        fetching: false
      };
    case FETCHING_JOBS:
      return {
        ...state,
        fetching: true,
        addToJoblistError: null
      };
    case ADD_TO_JOBLIST:
      return {
        ...state,
        addedToJoblist: true,
        addToJoblistError: null
      };
    case ADDING_TO_JOBLIST:
      return {
        ...state,
        addedToJoblist: false,
        addToJoblistError: null
      };
    case ADD_TO_JOBLIST_ERROR:
      return {
        ...state,
        addToJoblistError: payload
      };
    default:
      return state;
  }
}
