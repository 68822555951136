import {
  FETCHING_FAVORITES,
  GET_FAVORITES,
  GET_NEXT_FAVORITES,
  GET_SHARE_ANGEL_DETAILS,
  GET_SHARE_ANGEL_RATINGS,
  GET_FAMILY_DETAILS,
  FAVORITES_TOGGLE_LIKE
} from '../types';

const initialState = {
  fetching: false,
  favorites: null
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCHING_FAVORITES:
      return {
        ...state,
        fetching: true
      };
    case GET_FAVORITES:
      return {
        ...state,
        fetching: false,
        favorites: payload
      };
    case GET_NEXT_FAVORITES:
      return {
        ...state,
        favorites: {
          ...payload.favorites,
          data: [...state.favorites.data, ...payload.data]
        },
        fetching: false
      };
    case GET_SHARE_ANGEL_DETAILS:
      return {
        ...state,
        angelDetails: payload
      };
    case GET_SHARE_ANGEL_RATINGS:
      return {
        ...state,
        angelDetails: { ...state.angelDetails, ratings: payload }
      };
    case GET_FAMILY_DETAILS:
      return {
        ...state,
        familyDetails: payload
      };
    case FAVORITES_TOGGLE_LIKE:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          data: state.favorites.data.filter(({ angel_id }) => angel_id !== payload.angelId)
        }
      };
    default:
      return state;
  }
}
