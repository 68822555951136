import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, TextInput, ScreenLayout } from '@charlycares/ui'
import { Text, Button, Heading } from 'native-base'

import { useRouter } from '@charlycares/shared'
import { useAlert } from '../../hooks'
import { useCheckResetPasswordCodeMutation } from '@charlycares/data-access'

export default function CheckResetPasswordCode() {
  const { t } = useTranslation()
  const { navigate, getParam } = useRouter()
  const alert = useAlert()

  const resetEmail = getParam('email') as string

  const [code, setToken] = useState('')
  const [email, setEmail] = useState(resetEmail)

  const [checkResetPasswordCode, checkResetPasswordCodeState] = useCheckResetPasswordCodeMutation()

  const onSubmit = () => {
    if (code && email) {
      checkResetPasswordCode({ code, email })
    }
  }

  useEffect(() => {
    if (checkResetPasswordCodeState.isSuccess) {
      alert.show(
        t('checkResetPasswordCodeScreen.alertSuccessTitle'),
        t('checkResetPasswordCodeScreen.alertSuccessMessage'),
        [
          {
            text: t('ok'),
            onPress: () => navigate('ResetPassword', '/reset-password', { code, email })
          }
        ]
      )
    }
  }, [checkResetPasswordCodeState.isSuccess])

  useEffect(() => {
    if (checkResetPasswordCodeState.isError) {
      alert.show(
        t('checkResetPasswordCodeScreen.alertErrorTitle'),
        (checkResetPasswordCodeState.error as any)?.data?.message
      )
    }
  }, [checkResetPasswordCodeState.isError])

  return (
    <ScreenLayout title={t('checkResetPasswordCodeScreen.header')} disableScroll>
      <Card h="100%">
        <Heading mb="20px" fontSize="18px" fontWeight={600}>
          {t('checkResetPasswordCodeScreen.title')}
        </Heading>
        <Text fontSize="15px" mb="20px">
          {t('checkResetPasswordCodeScreen.subtitle')}
        </Text>

        <TextInput
          mt="10px"
          mb="20px"
          autoCapitalize="none"
          label={t('checkResetPasswordCodeScreen.emailLabel')}
          value={email}
          error={(checkResetPasswordCodeState.error as any)?.data?.errors?.email}
          isDisabled={!!resetEmail}
          onChangeText={value => setEmail(value)}
          placeholder={t('checkResetPasswordCodeScreen.emailPlaceholder')}
          returnKeyType="done"
        />

        <TextInput
          autoCapitalize="none"
          label={t('checkResetPasswordCodeScreen.codeLabel')}
          value={code}
          error={(checkResetPasswordCodeState.error as any)?.data?.errors?.code}
          isFocused={!!resetEmail}
          onChangeText={value => setToken(value)}
          placeholder={t('checkResetPasswordCodeScreen.codePlaceholder')}
          onSubmitEditing={() => onSubmit()}
          returnKeyType="done"
        />
        <Button
          onPress={() => onSubmit()}
          isLoading={checkResetPasswordCodeState.isLoading}
          isDisabled={!code}
          mt="20px"
          height="50px"
        >
          {t('checkResetPasswordCodeScreen.submitButton')}
        </Button>
      </Card>
    </ScreenLayout>
  )
}

CheckResetPasswordCode.navigationOptions = () => ({
  headerShown: false
})
