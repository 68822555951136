import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../../../elements';
import { TextView } from '../../Texts';
import colors from '../../../styles/colors';

const SuspendedCard = () => {
  const [t] = useTranslation();

  return (
    <TextView
      flexDirection="column"
      alignItems="flex-start"
      paddings="25px 20px 25px 20px"
      backgroundColor={colors.secondaryLight}
      text={t('angelSuspendedBannerTitle')}
      fontFamily="Martel"
      fontSize="18px"
      fontWeight="600">
      <Text margins="10px 0px 20px 0px" fontFamily="Open Sans" fontWeight="300">
        {t('angelSuspendedBannerDescription')}
      </Text>
    </TextView>
  );
};

export default SuspendedCard;
