import React, { useEffect } from 'react'
import { Box, Heading, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import { LandingLayout } from '@charlycares/ui'
import { isWeb, MEMBERSHIP_TYPES, useRouter } from '@charlycares/shared'
import { useSubscribeMembershipMutation } from '@charlycares/data-access'
import { Linking, Platform } from 'react-native'
import { MembershipPaymentMethods } from './common'

export default function LinkBankAccountScreen() {
  const { getParam } = useRouter()
  const { t } = useTranslation()

  const membershipType = getParam('membershipType') ?? MEMBERSHIP_TYPES['FLEXIBLE_TRIAL']

  const [subscribeMembership, subscribeResult] = useSubscribeMembershipMutation()

  const setupBankAccount = (methodId: string, issuer?: string) => {
    subscribeMembership({
      payment_method: methodId,
      channel: Platform.OS,
      membership_type: membershipType,
    })
  }

  useEffect(() => {
    if (subscribeResult.data) {
      if (isWeb) {
        window.open(subscribeResult.data?.data.redirect_url, '_self')
      } else {
        Linking.openURL(subscribeResult.data?.data.redirect_url)
      }
    }
  }, [subscribeResult.data])

  return (
    <LandingLayout
      withGoBack
      withLogo
      BottomComponent={
        <Box w="100%" p="20px" mt="auto">
          <Text fontSize="12px" fontWeight="400" color="white">
            {t('linkBankAccount.disclaimer')}
          </Text>
        </Box>
      }
    >
      <Box w="100%" px="20px" mt="50px">
        <Heading color="primary.300" fontSize="22px" fontWeight="600">
          {t('linkBankAccount.heading')}
        </Heading>

        <Text fontSize="16px" color="white">
          {t('linkBankAccount.description')}
        </Text>
      </Box>

      <Text px="20px" fontSize="16px" color="white" fontWeight="600" mt="60px">
        {t('linkBankAccount.choosePaymentMethod')}
      </Text>
      <MembershipPaymentMethods
        isLoading={subscribeResult.isLoading}
        onPress={(methodId) => setupBankAccount(methodId)}
        membershipType={membershipType}
      />
    </LandingLayout>
  )
}

LinkBankAccountScreen.navigationOptions = () => ({
  headerShown: false
})
