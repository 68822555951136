import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { updateRating, resetUpdateRating } from '../../../stores/actions/ratingsAction'
import { Divider } from '../../../elements'
import {
  TextView,
  TextTextView,
  TextIconView,
  TextButton,
  IconButton,
  AngelAvatarCard,
  Rating,
  Header,
  Alert
} from '../../../components'
import { getFormatedDate, getAge, makeAPhoneCall } from '../../../utils/functions'
import theme from '../../../styles/theme'

import infoIcon from '../../../../assets/icons/icn-small-info.png'
import leftIcon from '../../../../assets/icons/icn-nav-left.png'

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  justify-content: space-between;
`
const ScrollView = styled.ScrollView`
  flex: 1;
`
const Input = styled.TextInput`
  margin: ${({ margins }) => margins};
  width: 100%;
  min-height: 70px;
  max-height: ${({ maxHeight }) => maxHeight};
  border-color: ${theme.colors.secondary};
  border-bottom-width: ${({ showUnderline }) => (showUnderline ? '1px' : 0)};
  font-size: 16px;
`
const ButtonView = styled.View`
  padding: 15px 20px 15px 20px;
  border-top-width: 1px;
  border-color: ${theme.colors.grey08};
`

const ReviewDetails = ({
  location,
  history,
  route,
  navigation,
  ratingUpdated,
  updateRatingData,
  resetUpdateRatingData
}) => {
  const { review } = route.params || location.state || {}

  const { angel, rating, booking_id, start_date } = review
  const { id, first_name, image, birthdate, phone, conversation_id, angel_id, obscured_id } = angel
  const [comments, setComments] = useState(review.comments)
  const [t] = useTranslation()
  const alertRef = useRef(null)
  const buttonDisabled = ratingUpdated || comments === '' || comments === review.comments

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <TextTextView
          flexDirection="column"
          alignSelf="center"
          firstText={getFormatedDate(start_date, 'dddd')}
          firstTextFontWeight="600"
          firstTextFontFamily="Martel"
          firstTextFontSize="16px"
          firstTextColor={theme.colors.primaryText}
          firstTextAlign="center"
          firstTextxFontFamily="Martel"
          secondText={getFormatedDate(start_date, 'LL')}
          secondTextFontSize="13px"
          secondTextColor={theme.colors.grey03}
        />
      )
    })
  }

  useEffect(() => {
    if (ratingUpdated) {
      if (alertRef) {
        alertRef.current.setIsVisible(true)
      }
    }
  }, [ratingUpdated])

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          left={
            <IconButton
              iconSize="32px"
              iconColor={theme.colors.secondary}
              iconSource={leftIcon}
              onPress={history.goBack}
            />
          }
          title={getFormatedDate(start_date, 'dddd')}
          subtitle={getFormatedDate(start_date, 'LL')}
        />
      )}
      <ScrollView>
        <Divider />
        <AngelAvatarCard
          paddings="15px 5px 15px 20px"
          angelImage={image}
          angelName={first_name}
          angelAge={getAge(birthdate)}
          messageDisabled={!conversation_id}
          callDisabled={!phone}
          bookingDisabled={!booking_id}
          onMessagePress={() => {
            if (navigation) {
              navigation.navigate('ChatScreen', { conversationId: conversation_id })
            } else {
              history.push({ pathname: '/chat-inbox', state: { conversationId: conversation_id } })
            }
          }}
          onCallPress={() => makeAPhoneCall(phone)}
          onBookingPress={() => {
            if (navigation) {
              navigation.navigate('FamilyCalendar', { angelId: angel_id, angelName: first_name })
            } else {
              history.push({
                pathname: '/calendar',
                state: { angelId: angel_id, angelName: first_name }
              })
            }
          }}
          onPress={() => {
            history.push({ pathname: '/profile-angel', state: { angelId: id } })
          }}
        />
        <Divider />
        <TextView
          flexDirection="column"
          alignItems="flex-start"
          paddings="20px"
          text={t('rating')}
          fontFamily="Martel"
          fontSize="16px"
          fontWeight="600"
        >
          <Rating rating={rating} iconSize="27px" activeTintColor={theme.ratingStarActiveMuted} />
        </TextView>
        <TextView
          flexDirection="column"
          alignItems="flex-start"
          paddings="0px 20px 0px 20px"
          text={t('review')}
          fontFamily="Martel"
          fontSize="16px"
          fontWeight="600"
        >
          <TextIconView
            flexDirection="row-reverse"
            alignItems="flex-start"
            justifyContent="flex-end"
            margins="10px 0px 0px 0px"
            backgroundColor="transparent"
            text={t('profileScreensHelpParentsWriteReview')}
            fontSize="12px"
            textWidth="70%"
            textAlign="left"
            textColor={theme.colors.secondary}
            numberOfLines={2}
            icon={infoIcon}
            iconMargins="0px 5px 0px 0px"
            iconColor={theme.colors.secondary}
          />

          <Input
            margins="15px 0px 0px 0px"
            maxHeight="150px"
            showUnderline={review.comments === ''}
            editable={review.comments === ''}
            autoCapitalize="none"
            autoCorrect={false}
            multiline
            returnKeyType="done"
            blurOnSubmit
            textAlignVertical="top"
            placeholder={t('profileScreensWriteReviewPlaceholder')}
            value={comments}
            onChangeText={setComments}
          />
        </TextView>
      </ScrollView>

      {review.comments === '' && (
        <ButtonView>
          <TextButton
            text={t('done')}
            textColor={buttonDisabled ? theme.colors.grey03 : theme.colors.white}
            backgroundColor={buttonDisabled ? theme.colors.defaultBtnBackgroundColor : theme.colors.primary}
            onPress={() => updateRatingData(booking_id, comments)}
          />
        </ButtonView>
      )}

      {!navigation && (
        <Alert
          ref={alertRef}
          title={t('profileScreensReviewUpdated')}
          showCancelButton={false}
          confirmButtonText={t('ok')}
          onConfirmPress={() => {
            resetUpdateRatingData()
            if (navigation) {
              navigation.goBack()
            } else {
              history.goBack()
            }
          }}
        />
      )}
    </SafeAreaView>
  )
}

ReviewDetails.propTypes = {
  location: PropTypes.shape({ state: PropTypes.shape({ review: PropTypes.shape({}) }) }),
  history: PropTypes.shape({ goBack: PropTypes.func, push: PropTypes.func }),
  route: PropTypes.shape({ params: PropTypes.shape({ review: PropTypes.shape({}) }) }),
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    goBack: PropTypes.func,
    getParam: PropTypes.func,
    setOptions: PropTypes.func
  }),
  ratingUpdated: PropTypes.bool,
  updateRatingData: PropTypes.func.isRequired,
  resetUpdateRatingData: PropTypes.func.isRequired
}

ReviewDetails.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null,
  ratingUpdated: false
}

const mapStateToProps = ({ Ratings }) => ({
  ratingUpdated: Ratings.ratingUpdated
})

const mapDispatchToProps = {
  updateRatingData: updateRating,
  resetUpdateRatingData: resetUpdateRating
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewDetails)
