import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { TypingAnimation } from 'react-native-typing-animation';
import { useTranslation } from 'react-i18next';
import { Text, Icon, Button, Circle } from '../../elements';
import { TextView } from '../Texts';
import { AngelAvatar } from '../Profile';
import { getFormatedDate } from '../../utils/functions';
import theme from '../../styles/theme';

import rightArrowIcon from '../../../assets/icons/icn-nav-right.png';
import imageIcon from '../../../assets/icons/icn-image.png';

const View = styled.View`
  ${({ flex }) => flex && { flex }};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  ${({ paddings }) => paddings && { padding: paddings }};
  ${({ height }) => height && { height }};
`;

const Contact = ({
  borderBottomWidth,
  isActive,
  isTyping,
  name,
  image,
  lastMessage,
  createdAt,
  onPress,
  unreadMessages
}) => {
  const [t] = useTranslation();

  return (
    <Button
      flexDirection="row"
      height="90px"
      borderTopWidth={0}
      borderBottomWidth={borderBottomWidth}
      borderColor={theme.dividerLineColor}
      borderRadius={0}
      backgroundColor={theme.colors.white}
      activeOpacity={1}
      onPress={onPress}>
      <AngelAvatar margins="15px 10px 15px 15px" size="60px" source={image}>
        <Circle
          position="absolute"
          bottom={0}
          right={0}
          size="16px"
          borderColor={theme.colors.white}
          backgroundColor={isActive ? theme.colors.green : theme.colors.grey05}
        />
      </AngelAvatar>

      <View flex={1} flexDirection="column" paddings="10px 0px 10px 0px" height="100%">
        <TextView
          paddings="0px"
          justifyContent="space-between"
          text={name}
          textWidth="70%"
          numberOfLines={1}
          fontFamily="Martel"
          secondText={getFormatedDate(createdAt, 'DD-MM-YY')}
          secondTextFontSize="12px"
          secondTextColor={theme.colors.grey02}>
          <TextView
            flexDirection="row-reverse"
            text={getFormatedDate(
              (lastMessage && lastMessage.createdAt) || createdAt,
              lastMessage && !lastMessage.viewedAt && !lastMessage.isMine ? 'HH:mm' : 'DD-MM-YY'
            )}
            fontSize="12px"
            textColor={
              lastMessage && !lastMessage.viewedAt && !lastMessage.isMine
                ? theme.colors.notify
                : theme.colors.grey02
            }
            numberOfLines={2}>
            {!!unreadMessages && !lastMessage.isMine && (
              <TextView
                margins="0px 5px 0px 0px"
                justifyContent="center"
                width="18px"
                height="18px"
                backgroundColor={theme.colors.notify}
                borderRadius="18px"
                text={unreadMessages.toString()}
                fontSize="12px"
                textColor={theme.colors.white}
              />
            )}
          </TextView>
        </TextView>

        {!isTyping && (
          <>
            {lastMessage ? (
              <View>
                <Text
                  fontSize="13px"
                  color={lastMessage.viewedAt ? theme.colors.primary : theme.colors.grey02}
                  numberOfLines={2}>
                  {`✓${lastMessage.viewedAt ? '✓' : ''}`}
                  {!lastMessage.image && (
                    <Text fontSize="13px" color={theme.colors.grey02}>
                      {` ${lastMessage.text}`}
                    </Text>
                  )}
                </Text>

                {lastMessage.image && (
                  <Icon top={-5} size="25px" source={imageIcon} color={theme.colors.grey02} />
                )}
              </View>
            ) : (
              <Text fontSize="13px" color={theme.colors.grey02}>
                {`${t('chatScreensAddedOn')} ${getFormatedDate(createdAt, 'LL')}`}
              </Text>
            )}
          </>
        )}

        {isTyping && (
          <TypingAnimation
            style={{ flex: 1, width: '20px', height: 'auto' }}
            dotColor={theme.colors.primaryText}
            dotMargin={5}
            dotSpeed={0.1}
            dotRadius={2.5}
            dotX={7.5}
            dotY={0}
          />
        )}
      </View>

      <Icon
        margins="0px 5px 0px 5px"
        size="23px"
        color={theme.colors.grey07}
        source={rightArrowIcon}
      />
    </Button>
  );
};

Contact.propTypes = {
  borderBottomWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isActive: PropTypes.bool.isRequired,
  isTyping: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.node,
  lastMessage: PropTypes.shape({
    text: PropTypes.string,
    image: PropTypes.string,
    viewedAt: PropTypes.string,
    isMine: PropTypes.bool,
    createdAt: PropTypes.string
  }),
  createdAt: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  unreadMessages: PropTypes.number
};

Contact.defaultProps = {
  borderBottomWidth: '1px',
  image: null,
  lastMessage: null,
  unreadMessages: null
};

export default Contact;
