import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ContainerView, Heading, Text } from '../../elements';
import { TextButton } from '../Buttons';
import theme from '../../styles/theme';

const NoMembershipCard = ({ onPress }) => {
  const [t] = useTranslation();

  return (
    <ContainerView
      flexDirection="column"
      alignItems="flex-start"
      paddings="25px 20px 25px 20px"
      backgroundColor={theme.colors.primaryAlpha80}>
      <Heading xl ariaLevel={4} text={t('profileScreensNoMembership')} />

      <Text margins="10px 0px 20px 0px">{t('profileScreensNoMembershipDescription')}</Text>

      <TextButton
        width="100%"
        backgroundColor={theme.colors.primary}
        text={t('profileScreensStartTrialMonth')}
        onPress={onPress}
      />
    </ContainerView>
  );
};

NoMembershipCard.propTypes = {
  onPress: PropTypes.func.isRequired
};

export default NoMembershipCard;
