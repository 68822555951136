import { Linking } from 'react-native'
import { isWeb } from '@charlycares/shared'

import { LinkEmployeeBody, GetBusinessDataResponse } from './business.dto'
import { api } from '../api'
import { ResponseData } from '../types'

export const businessApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: ({ mutation, query }) => ({
    startBusinessMembership: mutation<ResponseData<any>, LinkEmployeeBody>({
      query: body => ({
        url: 'v2/business/employee/start',
        method: 'POST',
        body
      }),
      onQueryStarted(arg, { queryFulfilled }) {
        queryFulfilled
          .then(({ data }) => {
            if (isWeb) {
              window.open(data.data?.redirect_url, '_self')
            } else {
              Linking.openURL(data.data?.redirect_url)
            }
          })
          .catch(err => console.log(err))
      }
    }),
    connectBusinessMembership: mutation<ResponseData<void>, LinkEmployeeBody>({
      query: body => ({
        url: 'v2/business/employee/connect',
        method: 'POST',
        body
      })
    }),
    validateBusinessData: mutation<ResponseData<GetBusinessDataResponse>, { code: string }>({
      query: body => ({
        url: 'v2/business/employee',
        method: 'POST',
        body
      })
    }),
    getBusinessData: query<ResponseData<GetBusinessDataResponse>, { code: string }>({
      query: body => ({
        url: 'v2/business/employee',
        method: 'POST',
        body
      })
    }),
    getFamilyEmployerData: query<ResponseData<void>, { code: string }>({
      query: ({ code }) => ({
        url: `v2/angels/family/${code}`,
        method: 'GET'
      })
    })
  })
})

export const {
  useStartBusinessMembershipMutation,
  useConnectBusinessMembershipMutation,
  useGetBusinessDataQuery,
  useValidateBusinessDataMutation,
  useGetFamilyEmployerDataQuery
} = businessApi
