import React, { useState } from 'react'
import { Box, Button, Flex, Heading, Skeleton, Stack, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import { CharlyIcon, LandingLayout } from '@charlycares/ui'
import { ServiceGroupTypeResource, useGetServiceGroupTypesQuery } from '@charlycares/data-access'
import { useRouter } from '@charlycares/shared'

export default function ServiceGroupTypesScreen({ navigation }: any) {
  const { navigate, getParam } = useRouter()
  const { t } = useTranslation()

  const { data: serviceGroupTypes, isLoading } = useGetServiceGroupTypesQuery()

  const [selectedServiceGroupTypes, setSelectedServiceGroupTypes] = useState<ServiceGroupTypeResource[]>([])

  const isMultiSelectable = false

  const toggleSelectedServiceGroupTypes = (serviceGroupType: ServiceGroupTypeResource) => {
    const isSelected = selectedServiceGroupTypes.find(s => s.name === serviceGroupType.name)
    if (isSelected) {
      setSelectedServiceGroupTypes(selectedServiceGroupTypes.filter(s => s.name !== serviceGroupType.name))
    } else {
      setSelectedServiceGroupTypes(
        isMultiSelectable ? [...selectedServiceGroupTypes, serviceGroupType] : [serviceGroupType]
      )
    }
  }

  return (
    <LandingLayout
      withGoBack
      BottomComponent={
        <Box w="100%" p="20px">
          <Button
            isDisabled={selectedServiceGroupTypes.length === 0 || isLoading}
            _disabled={{
              backgroundColor: 'gray.500',
              opacity: 1
            }}
            onPress={() =>
              navigate('SignUpScreen', `/signup/family`, {
                serviceGroupTypes: selectedServiceGroupTypes?.map(s => s.name),
                referralCode: getParam('referralCode', null)
              })
            }
            backgroundColor="white"
          >
            <Text
              color={selectedServiceGroupTypes.length === 0 || isLoading ? 'gray.700' : 'primary.900'}
              fontSize="16px"
            >
              {selectedServiceGroupTypes.length === 0 || isLoading
                ? t('serviceGroupTypes.select')
                : t('serviceGroupTypes.signUp')}
            </Text>
          </Button>
        </Box>
      }
    >
      <Box mt="30px" mb="20px" w="100%" p="20px">
        <Heading color="white" fontSize="22px" fontWeight="600">
          {t('serviceGroupTypes.heading')}
        </Heading>
        <Text color="white" fontSize="18px" mt="30px" fontWeight="600">
          {t('serviceGroupTypes.subHeading')}
        </Text>
      </Box>
      <Box p="20px" flex={1}>
        {isLoading && (
          <Stack space="15px" mt="15px">
            {Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} h="45px" w="100%" rounded="md" startColor="gray.100" />
            ))}
          </Stack>
        )}
        {!isLoading &&
          serviceGroupTypes?.map((serviceGroupType, index) => {
            const isSelected = selectedServiceGroupTypes?.find(s => s.name === serviceGroupType.name)

            return (
              <Stack space="15px" key={index}>
                <Flex
                  bgColor="white"
                  p="10px"
                  mt="15px"
                  direction="row"
                  justify="space-between"
                  borderRadius="5px"
                  align="center"
                >
                  <Text fontSize="15px">{t(`serviceGroupTypes.${serviceGroupType.name}`)}</Text>
                  <CharlyIcon
                    name={isSelected ? 'btn-check' : 'btn-plus'}
                    color={isSelected ? 'success.400' : 'gray.400'}
                    size={22}
                    onPress={() => toggleSelectedServiceGroupTypes(serviceGroupType)}
                  />
                </Flex>
              </Stack>
            )
          })}
      </Box>
    </LandingLayout>
  )
}

ServiceGroupTypesScreen.navigationOptions = () => ({
  headerShown: false
})
