import React, { useEffect } from 'react'
import { CharlyIcon, LandingLayout } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { formatMoney, useRouter } from '@charlycares/shared'
import { Box, Button, HStack, Spacer, Text, VStack } from 'native-base'
import { useGetPaymentSummaryMutation } from '@charlycares/data-access'

function BookingFinishedScreen() {
  const { t } = useTranslation()
  const { navigate, getParam } = useRouter()
  const paymentMethod = getParam('paymentMethod') as string
  const bookingId = getParam('bookingId') as string
  const [getPaymentSummaryData, { isLoading, data: bookingPaymentSummary }] = useGetPaymentSummaryMutation()

  useEffect(() => {
    if (!isLoading) {
      getPaymentSummaryData({ booking_id: bookingId })
    }
  }, [getPaymentSummaryData, bookingId])

  const usps = [
    {
      text: t(`bookingFinishedScreen.${paymentMethod}.usp1`, {
        payoutAmount: formatMoney(bookingPaymentSummary?.rate.angel_payout)
      }),
      bold: true
    },
    {
      text: t(`bookingFinishedScreen.${paymentMethod}.usp2`),
      bold: false
    },
    {
      text: t(`bookingFinishedScreen.${paymentMethod}.usp3`),
      bold: false
    }
  ]

  const noInsurance = bookingPaymentSummary ? bookingPaymentSummary.rate.insurance_fee.amount === 0 : false

  return (
    <LandingLayout
      withLogo
      BottomComponent={
        <Box w="100%" px="20px" mt="auto">
          <Button
            onPress={() => navigate('BookingsScreen', '/bookings')}
            bgColor="white"
            _text={{ color: 'secondary.400', fontSize: '16px', fontWeight: 600 }}
          >
            {t('bookingFinishedScreen.finishButton')}
          </Button>
        </Box>
      }
    >
      <VStack px="20px" mt="50px" width="100%">
        {usps.map(({ text, bold }) => (
          <HStack mb="20px">
            <CharlyIcon name="icn-check" color="primary.300" size="28" />
            <Text color="white" fontWeight={bold ? '600' : '400'} fontSize="18px" ml="10px" maxW="90%">
              {text}
            </Text>
          </HStack>
        ))}
      </VStack>

      {!noInsurance && (
        <Box px="20px" mt="50px" width="100%">
          <Box bgColor="secondary.alpha.30" width="100%" rounded="md" p="10px">
            <HStack mb="20px">
              <Text color="white" fontSize="18px" fontWeight="600" mb="10px">
                {t('bookingFinishedScreen.angelInsurance.title')}
              </Text>
              <Spacer />
              <CharlyIcon name="icn-insurance" color="white" size="32" />
            </HStack>
            <Text color="white">{t('bookingFinishedScreen.angelInsurance.description')}</Text>
          </Box>
        </Box>
      )}
    </LandingLayout>
  )
}

BookingFinishedScreen.navigationOptions = {
  headerShown: false,
  gestureEnabled: false,
  gesturesEnabled: false,
  swipeEnabled: false
}

export default BookingFinishedScreen
