import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const PaymentCardIcon = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      fill="#1434CB"
      d="M12.116 1.507L7.94 11.494H5.213l-2.056-7.97c-.125-.49-.234-.67-.613-.877C1.924 2.31.9 1.994 0 1.797l.061-.29H4.45c.56 0 1.062.373 1.189 1.019l1.086 5.778 2.683-6.797h2.71zm10.68 6.727c.011-2.636-3.637-2.781-3.612-3.959.008-.358.348-.74 1.094-.836.369-.049 1.387-.086 2.542.446l.453-2.117a6.917 6.917 0 00-2.412-.443c-2.55 0-4.343 1.357-4.358 3.301-.016 1.438 1.28 2.24 2.257 2.718 1.005.49 1.342.803 1.339 1.241-.007.67-.802.966-1.544.977-1.296.02-2.049-.351-2.648-.63l-.467 2.188c.602.277 1.714.518 2.867.53 2.71 0 4.481-1.34 4.49-3.416zm6.73 3.26h2.386L29.83 1.507h-2.201c-.495 0-.913.29-1.098.733l-3.869 9.254h2.708l.537-1.492h3.308l.312 1.492zM26.65 7.956l1.357-3.75.781 3.75H26.65zM15.8 1.507l-2.132 9.987H11.09l2.133-9.987h2.578z"
    />
    <Path fill="#FF5A00" d="M21.052 14.924h-8.115v14.58h8.115v-14.58z" />
    <Path
      fill="#EB001B"
      d="M13.477 22.214a9.3 9.3 0 013.53-7.29 9.223 9.223 0 00-5.72-1.984 9.268 9.268 0 00-9.274 9.274 9.268 9.268 0 009.273 9.273 9.223 9.223 0 005.72-1.983 9.255 9.255 0 01-3.53-7.29z"
    />
    <Path
      fill="#F79E1B"
      d="M32 22.214a9.268 9.268 0 01-9.274 9.273 9.223 9.223 0 01-5.72-1.983 9.223 9.223 0 003.53-7.29 9.3 9.3 0 00-3.53-7.29 9.208 9.208 0 015.717-1.984c5.129 0 9.276 4.175 9.276 9.274z"
    />
  </Svg>
)

export default PaymentCardIcon
