import React from 'react'
import { Box, Text } from 'native-base'
import { useTranslation } from 'react-i18next'

import { BookingServiceType, ServiceGroupType } from '@charlycares/shared'
import { Card, CharlyIcon } from '@charlycares/ui'
import { getInfoFromBookingServiceType } from '@charlycares/features'

type Props = {
  bookingServiceType?: BookingServiceType,
  serviceGroupType?: ServiceGroupType
}

export default function BookingServiceTypeInfo({ bookingServiceType, serviceGroupType }: Props) {
  const { t } = useTranslation()
  const type = bookingServiceType ?? serviceGroupType

  const { icon: iconName, banner_color: bannerColor } = getInfoFromBookingServiceType(type)

  return (
    <Card bgColor={`${bannerColor}.100`} p="8px">
      <Box
        py="8px"
        px="8px"
        flexDir="row"
        borderLeftColor={`${bannerColor}.300`}
        borderLeftWidth="4px"
        alignItems="center"
      >
        <CharlyIcon size={32} color="gray.800" name={iconName} />
        <Box ml="10px" flex={1}>
          <Text fontSize="16px" fontWeight="600">
            {t(`family_wants.${type}.title`)}
          </Text>
          <Text fontSize="12px" fontWeight="400" color="gray.800">
            {t(`family_wants.${type}.description`)}
          </Text>
        </Box>
      </Box>
    </Card>
  )
}
