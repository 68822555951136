import React from 'react'
import { Card, CreditCardIcon, Layout, Title } from '@charlycares/ui'
import { Button, CheckIcon, HStack, Icon, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import { useRouter } from '@charlycares/shared'

export default function AppleIapActivated() {
  const { t } = useTranslation()
  const { navigate } = useRouter()
  const { getParam } = useRouter()
  const membershipType = getParam('membershipType')

  const setupBankAccount = async () => {
    navigate('SetupBankAccount', '/setup-bank-account', { membershipType: membershipType })
  }

  return (
    <Layout title={t('appleIapActivated.title')} hideBack>
      <Card>
        <HStack space={2} marginBottom="10px">
          <CheckIcon size="5" mt="0.5" color="secondary.400" />

          <Title>{t('appleIapActivated.startedTitle')}</Title>
        </HStack>

        <Text fontSize="md" color="gray.900" paddingRight="40px">
          {t('appleIapActivated.startedDescription')}
        </Text>
      </Card>
      <Card>
        <HStack space={2} marginBottom="10px">
          <Icon as={CreditCardIcon} mt="0.5" color="secondary.400" />

          <Title>{t('appleIapActivated.connectTitle')}</Title>
        </HStack>

        <Text fontSize="sm" color="gray.900" marginBottom="20px" pt={2}>
          {t('appleIapActivated.connectDescription')}
        </Text>

        <Button backgroundColor="secondary.400" marginBottom="20px" width="100%" onPress={() => setupBankAccount()}>
          {t('appleIapActivated.connectButton')}
        </Button>
      </Card>
    </Layout>
  )
}
