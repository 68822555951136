import React from 'react'
import { Box, Flex, IBoxProps, Stack, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import { CharlyIcon, iconsMap } from '@charlycares/ui'
import { GetAvailableMembershipsResponse } from '@charlycares/data-access'
import { MembershipsName } from '@charlycares/shared'
import moment from 'moment'

type Props = IBoxProps & {
  availableMemberships?: GetAvailableMembershipsResponse
}
export default function FeaturesWeOffer({ availableMemberships, ...boxProps }: Props) {
  const { t } = useTranslation()

  const membershipName = availableMemberships
    ? (Object.keys(availableMemberships)[0] as MembershipsName)
    : ('full_service' as MembershipsName)
  const days = moment.duration(availableMemberships?.[membershipName].trial?.interval).days()

  const usps = (): { text: string; icon: keyof typeof iconsMap; color: string }[] => {
    if (membershipName === 'pet_service') {
      return [
        {
          text: t('featuresWeOfferPetService.usp1'),
          icon: 'identity-verified',
          color: 'primary.300'
        },
        {
          text: t('featuresWeOfferPetService.usp2'),
          icon: 'icn-reading',
          color: 'primary.300'
        },
        {
          text: t('featuresWeOfferPetService.usp3'),
          icon: 'icn-conversation',
          color: 'primary.300'
        },
        {
          text: t('featuresWeOfferPetService.usp4'),
          icon: 'wallet',
          color: 'primary.300'
        },
        {
          text: t('featuresWeOfferPetService.usp5'),
          icon: 'icn-insurance',
          color: 'primary.300'
        }
      ]
    }

    return [
      {
        text: t('featuresWeOfferFullService.usp1'),
        icon: 'identity-verified',
        color: 'primary.300'
      },
      {
        text: t('featuresWeOfferFullService.usp2'),
        icon: 'icn-reading',
        color: 'primary.300'
      },
      {
        text: t('featuresWeOfferFullService.usp3'),
        icon: 'icn-conversation',
        color: 'primary.300'
      },
      {
        text: t('featuresWeOfferFullService.usp4'),
        icon: 'wallet',
        color: 'primary.300'
      },
      {
        text: t('featuresWeOfferFullService.usp5'),
        icon: 'icn-insurance',
        color: 'primary.300'
      }
    ]
  }

  return (
    <Box p="20px" flex={1} {...boxProps}>
      {usps().map((item, index) => (
        <Stack key={index}>
          <Flex mt="20px" direction="row" borderRadius="5px">
            <CharlyIcon mr="10px" name={item.icon} size={28} color={item.color} />
            <Text fontSize="18px" fontWeight="600" color="white" flex={1}>
              {item.text}
            </Text>
          </Flex>
        </Stack>
      ))}
      <Flex mt="20px" direction="row" borderRadius="5px" align="center">
        <Text fontSize="18px" fontWeight="600" color="white">
          {t('featuresWeOffer.more')}
        </Text>
      </Flex>
    </Box>
  )
}
