import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components/native';

const Container = styled.View`
  padding: 10px;
`;

const Loader = () => (
  <Container>
    {[...Array(6)].map((_, i) => (
      <ContentLoader
        height={100}
        width={400}
        speed={2}
        color="#f3f3f3"
        key={i.toString()}
      >
        <rect x="303" y="45.05" rx="0" ry="0" width="14" height="1" />

        <rect x="20" y="29.05" rx="0" ry="0" width="150.2" height="14.96" />

        <rect
          x="20"
          y="56.22"
          rx="0"
          ry="0"
          width="115.66"
          height="8.879999999999999"
        />

        <rect
          x="20"
          y="76.22"
          rx="0"
          ry="0"
          width="120.66"
          height="8.879999999999999"
        />

        <rect x="252" y="60.05" rx="0" ry="0" width="93" height="10" />

        <rect x="282" y="75.05" rx="0" ry="0" width="61" height="10" />
      </ContentLoader>
    ))}
  </Container>
);

export default Loader;
