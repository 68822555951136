import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter, UserRoles, BookingStates } from '@charlycares/shared'
import { ScreenLayout, Checkbox } from '@charlycares/ui'
import { BookingCancellationReason, useGetBookingDetailsQuery, useGetUserQuery } from '@charlycares/data-access'
import { Heading, Text, Box, Stack, Flex, Divider } from 'native-base'
import BookingsMetric from './common/BookingsMetric'
import { useHandleCancelBooking } from './common'

export default function BookingCancellationReasonsScreen() {
  const { getParam, navigate } = useRouter()
  const { t } = useTranslation()

  const bookingId = getParam('bookingId') as string
  const cancellationReasons = getParam('cancellationReasons') as BookingCancellationReason[]

  const [selectedReason, setSelectedReason] = useState<BookingCancellationReason>()

  const { data: user } = useGetUserQuery()
  const { data: booking } = useGetBookingDetailsQuery({ obscured_id: bookingId }, { skip: !bookingId })
  const { onCancelBooking, cancelBookingResponse } = useHandleCancelBooking({ booking: booking, navigation: navigate })

  const onNextStep = () => {
    if (selectedReason && booking) {
      booking?.current_state === BookingStates.ACCEPTED
        ? navigate('CancelBooking', '/cancel-booking', {
            bookingId: bookingId,
            cancellationReason: selectedReason.reason_type
          })
        : onCancelBooking(booking.obscured_id, selectedReason.reason_type, '')
    }
  }

  if (!booking) {
    return <ScreenLayout isLoading title={t('bookingsScreenCancelBooking')} />
  }

  return (
    <ScreenLayout
      progress={booking?.current_state === BookingStates.ACCEPTED ? `${33}%` : `${50}%`}
      supportButton
      title={t('bookingsScreenCancelBooking')}
      subtitle={bookingId}
      edges={['right', 'left']}
      _bottomContainer={{
        backgroundColor: 'white',
        paddingBottom: '30px'
      }}
      _buttonProps={{
        onPress: onNextStep,
        isDisabled: !selectedReason,
        isLoading: cancelBookingResponse.isLoading,
        children: booking?.current_state === BookingStates.ACCEPTED ? t('nextStep') : t('cancelBooking'),
        w: '50%',
        ml: 'auto'
      }}
    >
      {user?.role === UserRoles.ANGEL && <BookingsMetric booking={booking} />}
      <Box w="100%" bg="white" p="20px" borderColor="gray.200" borderWidth="1px">
        <Heading fontSize="18px" color="gray.900" mt="10px" mb="15px">
          {t('reasonForCancellation')}
        </Heading>

        <Text fontSize="15px" color="gray.700">
          {booking?.current_state === BookingStates.ACCEPTED
            ? t('reasonForCancellationInfo', { userRole: user?.role === UserRoles.FAMILY ? 'Angel' : 'family' })
            : t('reasonForCancellationPendingBookingInfo')}
        </Text>

        <Box w="100%" mt={user?.role === UserRoles.FAMILY ? '50px' : '10px'} mb="20px">
          {cancellationReasons?.map((cancellationReason, index) => {
            const isSelected = selectedReason?.reason_type === cancellationReason.reason_type

            return (
              <Stack space="10px" key={index}>
                <Flex mt="10px" direction="row" justify="space-between" align="center">
                  <Text fontSize="15px">{t(`cancellation_reasons.${cancellationReason.reason_type}`)}</Text>
                  <Checkbox
                    onChange={() => setSelectedReason(isSelected ? undefined : cancellationReason)}
                    isChecked={isSelected}
                    isDisabled={cancelBookingResponse.isLoading}
                  />
                </Flex>
                {cancellationReasons.length - 1 !== index && <Divider my="10px" />}
              </Stack>
            )
          })}
        </Box>
      </Box>
    </ScreenLayout>
  )
}

BookingCancellationReasonsScreen.navigationOptions = () => ({
  headerShown: false
})
