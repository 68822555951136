import React from 'react'
import PropTypes from 'prop-types'
import { FamilyBookingCancel } from '@charlycares/legacy/web'
import { ProtectedRoute } from '../components'

import {
  AngelBookingDetailsScreen,
  BookingsListScreen,
  FamilyBookingDetailsScreen,
  EditBookingScreen,
  ViewOfferScreen,
  OfferAcceptedScreen,
  BookingEstimatedCostSummaryScreen,
  BookingCancellationReasonsScreen,
  CancelBookingScreen,
  BookingCancelledScreen,
  AngelBookingCancelledScreen
} from '@charlycares/features'

const BookingsRoutes = user => {
  const { role } = user || {}

  return [
    <ProtectedRoute key="bookings" path="/bookings" user={user} component={BookingsListScreen} />,
    <ProtectedRoute
      key="booking"
      path="/booking"
      user={user}
      component={props =>
        role === 'angel' ? <AngelBookingDetailsScreen {...props} /> : <FamilyBookingDetailsScreen {...props} />
      }
    />,
    <ProtectedRoute
      key="booking-edit"
      path="/booking-edit"
      user={user}
      component={props =>
        role === 'angel' ? <AngelBookingDetailsScreen {...props} /> : <EditBookingScreen {...props} />
      }
    />,
    <ProtectedRoute key="booking-cancel" path="/booking-cancel" user={user} component={FamilyBookingCancel} />,
    <ProtectedRoute key="booking-offer" path="/booking-offer" user={user} component={ViewOfferScreen} />,
    <ProtectedRoute key="offer-accepted" path="/offer-accepted" user={user} component={OfferAcceptedScreen} />,
    <ProtectedRoute
      key="booking-cost-summary"
      path="/booking-cost-summary"
      user={user}
      component={BookingEstimatedCostSummaryScreen}
    />,
    <ProtectedRoute
      key="booking-cancellation-reasons"
      path="/booking-cancellation-reasons"
      user={user}
      component={BookingCancellationReasonsScreen}
    />,
    <ProtectedRoute key="cancel-booking" path="/cancel-booking" user={user} component={CancelBookingScreen} />,
    <ProtectedRoute key="booking-cancelled" path="/booking-cancelled" user={user} component={BookingCancelledScreen} />,
    <ProtectedRoute
      key="angel-booking-cancelled"
      path="/angel-booking-cancelled"
      user={user}
      component={AngelBookingCancelledScreen}
    />
  ]
}

BookingsRoutes.propTypes = {
  user: PropTypes.shape({ role: PropTypes.string })
}

BookingsRoutes.defaultProps = {
  user: null
}

export default BookingsRoutes
