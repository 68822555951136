import { ChildResponse, GetNearestAngelsResponse } from './family.dto'
import { api } from '../api'
import { AngelSummery, FamilySummery, PaginatedResults } from '@charlycares/shared'
import { ApiTags, ResponseData } from '../types'
import { Moment } from 'moment'
import { angelsApi } from '../angels'

export const familyApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: ({ query, mutation }) => ({
    getNearestAngels: query<ResponseData<GetNearestAngelsResponse[]>, void>({
      query: () => ({
        url: 'v2/matching/nearest-angels',
        method: 'GET'
      })
    }),
    getFamilyDetails: query<FamilySummery, { familyObscuredId: string }>({
      query: ({ familyObscuredId }) => ({
        url: `v2/families/details/${familyObscuredId}`,
        method: 'GET'
      }),
      transformResponse: ({ data }): FamilySummery => data
    }),
    getChildren: query<ChildResponse[], void>({
      query: () => ({
        url: 'v2/families/children',
        method: 'GET'
      }),
      transformResponse: ({ data }): ChildResponse[] => data,
      providesTags: [ApiTags.CHILDREN]
    }),
    createChild: mutation<ChildResponse, { date_of_birth: Moment }>({
      query: body => ({
        url: 'v2/families/children',
        method: 'POST',
        body
      }),
      transformResponse: ({ data }): ChildResponse => data,
      async onQueryStarted({ date_of_birth }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          familyApi.util.updateQueryData('getChildren', undefined, (draft: ChildResponse[]) => {
            draft.push({ id: 0, date_of_birth: date_of_birth.format('YYYY-MM-DD') })
          })
        )

        queryFulfilled.catch(patchResult.undo)
      },
      invalidatesTags: [ApiTags.CHILDREN, ApiTags.ONBOARDING_SCREENS]
    }),
    deleteChild: mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `v2/families/children/${id}`,
        method: 'DELETE'
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          familyApi.util.updateQueryData('getChildren', undefined, (draft: ChildResponse[]) => {
            const objWithIdIndex = draft.findIndex(obj => obj.id === id)
            draft.splice(objWithIdIndex, 1)
          })
        )

        queryFulfilled.catch(patchResult.undo)
      },
      invalidatesTags: [ApiTags.ONBOARDING_SCREENS]
    }),
    toggleFavoriteAngel: mutation<void, { angel_id: number; angelObscuredId: string; like: boolean }>({
      query: body => ({
        url: `v1/angel/like`,
        method: 'POST',
        body
      }),
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          angelsApi.util.updateQueryData('getAngelDetails', { angelObscuredId: patch.angelObscuredId }, draft => {
            Object.assign(draft, { angel: { ...draft, is_favourite: patch.like, is_liked: patch.like } })
          })
        )

        queryFulfilled.catch(patchResult.undo)
      },
      invalidatesTags: (result, error, arg) => [{ type: ApiTags.ANGEL_DETAILS, id: arg.angelObscuredId }]
    }),
    getFavoriteAngels: query<PaginatedResults<AngelSummery>, { page?: number }>({
      query: ({ page }) => ({
        url: `v2/families/favorite-angels?page=${page}`,
        method: 'GET'
      })
    }),
    sendMessageToFavoriteAngels: mutation<void, { selected_angels: { angel_user_id: string }[]; message: string }>({
      query: body => ({
        url: `v2/families/favorite-angels-chat`,
        method: 'POST',
        body
      })
    })
  })
})

export const {
  useGetNearestAngelsQuery,
  useGetChildrenQuery,
  useCreateChildMutation,
  useDeleteChildMutation,
  useGetFamilyDetailsQuery,
  useToggleFavoriteAngelMutation,
  useGetFavoriteAngelsQuery,
  useSendMessageToFavoriteAngelsMutation
} = familyApi
