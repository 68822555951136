import axiosInstance from '../../utils/axios'
import { SERVER_ERROR, GET_REFERRAL_LANDING } from '../types'

const logError = (error, dispatch) => {
  if (error.response && error.response.status === 500) {
    dispatch({ type: SERVER_ERROR })
  } else {
    console.log({ error })
  }
}

export const getReferralLanding = (reference, target, locale) => dispatch => {
  axiosInstance
    .get(`/current_campaign/${reference}/${target}/${locale}`)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_REFERRAL_LANDING,
          payload: data.data
        })
      }
    })
    .catch(error => logError(error, dispatch))
}
