import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { updateProfile, updateProfileVideo } from '../../../stores/actions/userAction'
import { Text, Switch, Divider } from '../../../elements'
import {
  TextView,
  TextIconView,
  LabeledInput,
  IconButton,
  TextButton,
  VideoEdit,
  HourlyRatesEdit,
  ProfileLanguagesEdit,
  OverlayMessage,
  Header,
  Alert
} from '../../../components'
import { isValidIBAN } from '../../../utils/functions'
import colors from '../../../styles/colors'

import dayIcon from '../../../../assets/icons/icn-day.png'
import nightIcon from '../../../../assets/icons/icn-night.png'
import repeatIcon from '../../../../assets/icons/icn-repeat.png'
import standbyIcon from '../../../../assets/icons/icn-standby.png'
import firstAidIcon from '../../../../assets/icons/icn-first-aid.png'
import infoIcon from '../../../../assets/icons/icn-small-info.png'
import educationIcon from '../../../../assets/icons/icn-reading.png'

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
`
const ScrollView = styled.ScrollView``
const View = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px 15px 0px;
`
const Border = styled.View`
  width: 100%;
  height: 1px;
  background-color: ${colors.grey08};
`

const EditPersonalSettings = ({
  history,
  navigation,
  user,
  profileUpdateError,
  updatingProfile,
  profileUpdated,
  videoUploaded,
  serverError,
  updateProfileVideoData,
  updateProfileData
}) => {
  const { account_number, angel } = user
  const { dutch, english, french, german, italian, spanish } = user.languages

  const { normal_rate, extra_rate, fixed_rate, special_rate, first_aid, driving_license } = angel
  const [video, setVideo] = useState(angel.video || null)
  const [normalRate, setNormalRate] = useState(normal_rate)
  const [extraRate, setExtraRate] = useState(extra_rate)
  const [fixedRate, setFixedRate] = useState(fixed_rate)
  const [specialRate, setSpecialRate] = useState(special_rate)
  const [firstAid, setFirstAid] = useState(first_aid)
  const [drivingLicence, setDrivingLicence] = useState(driving_license)
  const [tutor, setTutor] = useState(angel.tutor)
  const [rateWasTouched, setRateWasTouched] = useState(false)
  const [languages, setLanguages] = useState({ dutch, english, french, german, italian, spanish })

  const [accountNumber, setAccountNumber] = useState(account_number)
  const [inputFocused, setInputFocused] = useState(null)
  const [uploadingVideo, setUploadingVideo] = useState(false)
  const [alertData, setAlertData] = useState(null)
  const [t] = useTranslation()
  const overlayRef = useRef(null)
  const alertRef = useRef(null)

  const updateSettings = () => {
    const dataToUpdate = {
      account_number: accountNumber,
      normal_rate: normalRate,
      extra_rate: extraRate,
      fixed_rate: fixedRate,
      special_rate: specialRate,
      first_aid: firstAid,
      driving_license: drivingLicence,
      tutor,
      languages
    }
    updateProfileData(dataToUpdate)
  }

  const onUpdateConfirm = () => {
    const { video_upload_url } = user

    if (video !== angel.video) {
      setUploadingVideo(true)
      if (overlayRef && overlayRef.current) {
        overlayRef.current.setIsVisible(true)
      }
      updateProfileVideoData(video_upload_url, video)
    } else {
      updateSettings()
    }
  }

  const onUpdateSettingsPress = () => {
    const { rates_info } = user
    const { average_extra_rate, average_fixed_rate, average_normal_rate } = rates_info

    if (isValidIBAN(accountNumber) === false) {
      setAlertData({
        title: accountNumber,
        subtitle: t('profileScreensAngelPersonalSettingsInvalidIban'),
        showCancelButton: false,
        onConfirmPress: () => setAlertData(null)
      })

      return
    }

    if (
      rateWasTouched &&
      (normal_rate > average_normal_rate ||
        extra_rate > average_extra_rate ||
        fixed_rate > average_fixed_rate)
    ) {
      setAlertData({
        title: t('profileScreensHigherThanAverageRate'),
        subtitle: t('profileScreensHigherThanAverageRateDesc'),
        cancelButtonText: t('profileScreensHigherThanAverageRateBtn'),
        confirmButtonText: t('continue'),
        onCancelPress: () => setAlertData(null),
        onConfirmPress: () => {
          setAlertData(null)
          onUpdateConfirm()
        }
      })
    } else {
      onUpdateConfirm()
    }
  }

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('edit')}
        </Text>
      ),
      headerRight: (
        <TextButton
          text={t('save')}
          textColor={colors.secondary}
          onPress={() => onUpdateSettingsPress()}
        />
      ),
      headerLeft: (
        <TextButton
          textColor={colors.secondary}
          text={t('cancel')}
          onPress={() => navigation.pop()}
        />
      )
    })
  }

  useEffect(() => {
    if (alertData) {
      alertRef.current.setIsVisible(true)
    }
  }, [alertData])

  useEffect(() => {
    if (!updatingProfile) {
      if (profileUpdateError) {
        setAlertData({
          title: profileUpdateError,
          showCancelButton: false,
          onConfirmPress: () => setAlertData(null)
        })
      }
      if (profileUpdated) {
        setAlertData({
          title: t('profileScreensEditSuccessful'),
          showCancelButton: false,
          onConfirmPress: () => history.goBack()
        })
      }
    }
  }, [user, updatingProfile, profileUpdated])

  useEffect(() => {
    if (serverError) {
      setAlertData({
        title: t('serverErrorMessage'),
        showCancelButton: false,
        onConfirmPress: () => setAlertData(null)
      })
    }
  }, [serverError])

  useEffect(() => {
    if (uploadingVideo && videoUploaded) {
      setUploadingVideo(false)
      if (overlayRef && overlayRef.current) {
        overlayRef.current.setIsVisible(false)
      }
      updateSettings()
    }
  }, [videoUploaded])

  const onInfoPress = () => {
    setAlertData({
      title: t('profileScreensFirstAidTitle'),
      subtitle: t('profileScreensFirstAidDesc'),
      showCancelButton: false,
      onConfirmPress: () => setAlertData(null)
    })
  }

  const onIncreaseDecreasePress = (type, increase) => {
    const amount = increase ? 0.25 : -0.25
    switch (type) {
      case 'normal_rate':
        setNormalRate(normalRate + amount)
        break
      case 'extra_rate':
        setExtraRate(extraRate + amount)
        break
      case 'fixed_rate':
        setFixedRate(fixedRate + amount)
        break
      case 'special_rate':
        setSpecialRate(specialRate + amount)
        break
      default:
        break
    }
    setRateWasTouched(true)
  }

  const onEditVideoPress = event => {
    const file = event.target.files[0]
    if (file) {
      setVideo(file)
    }
  }

  const { rates_info } = user
  const { average_extra_rate, average_fixed_rate, average_normal_rate, average_special_rate } =
    rates_info
  const isAccountNumberValid = isValidIBAN(account_number)

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          left={
            <TextButton textColor={colors.secondary} text={t('cancel')} onPress={history.goBack} />
          }
          title={t('edit')}
          right={
            <TextButton
              text={t('save')}
              textColor={colors.secondary}
              onPress={() => onUpdateSettingsPress()}
            />
          }
        />
      )}

      <ScrollView>
        <Divider />

        <VideoEdit
          source={typeof video === 'object' ? URL.createObjectURL(video) : video}
          paused={false}
          onEditPress={onEditVideoPress}
          onStartExampleVideoPress={() => {
            if (navigation) {
              navigation.navigate('VideoPlayer', {
                source: 'https://s3.eu-central-1.amazonaws.com/charlycares-videos/Mijn+film.mp4'
              })
            }
          }}
        />

        <HourlyRatesEdit
          hourlyRates={[
            {
              type: 'normal_rate',
              desc: t('dayRate'),
              icon: dayIcon,
              rate: normalRate,
              competitiveRate: average_normal_rate,
              minimumRate: average_normal_rate - 1.75,
              maximumRate: average_normal_rate + 1.75
            },
            {
              type: 'extra_rate',
              desc: t('nightRate'),
              icon: nightIcon,
              rate: extraRate,
              competitiveRate: average_extra_rate,
              minimumRate: average_extra_rate - 1.75,
              maximumRate: average_extra_rate + 1.75
            },
            {
              type: 'fixed_rate',
              desc: t('fixedRate'),
              icon: repeatIcon,
              rate: fixedRate,
              competitiveRate: average_fixed_rate,
              minimumRate: average_fixed_rate - 1.75,
              maximumRate: average_fixed_rate + 1.75
            },
            {
              type: 'special_rate',
              desc: t('specialRate'),
              icon: standbyIcon,
              rate: specialRate,
              competitiveRate: average_special_rate,
              minimumRate: average_special_rate - 1.75,
              maximumRate: average_special_rate + 20
            }
          ]}
          onDecreasePress={type => onIncreaseDecreasePress(type, false)}
          onIncreasePress={type => onIncreaseDecreasePress(type, true)}
        />

        <TextView
          paddings="20px"
          flexDirection="column"
          alignItems="flex-start"
          borderTopWidth="1px"
          borderBottomWidth="1px"
          text={t('skillsAndExperiences')}
          fontSize="12px"
          fontWeight="300"
          textColor={colors.grey02}>
          <IconButton
            iconSource={infoIcon}
            iconColor={colors.secondary}
            onPress={onInfoPress}
            alignSelf="flex-end"
            margins="-25px 0px 0px 0px"
          />
          <View>
            <TextIconView
              flexDirection="row-reverse"
              backgroundColor="transparent"
              icon={firstAidIcon}
              iconMargins="0px 10px 0px 0px"
              iconSize="32px"
              iconColor={colors.primaryText}
              text={t('bookScreensFirstAid')}
              fontWeight="300"
            />

            <Switch
              value={firstAid}
              onValueChange={value => {
                setFirstAid(value)
                setInputFocused(false)
              }}
              trackColor={{ true: colors.primary, false: colors.backgroundColor }}
            />
          </View>

          <View>
            <TextIconView
              flexDirection="row-reverse"
              backgroundColor="transparent"
              name="icn-car"
              iconMargins="0px 10px 0px 0px"
              iconSize="32px"
              iconColor={colors.primaryText}
              text={t('bookScreensDrivingLicence')}
              fontWeight="300"
            />

            <Switch
              value={drivingLicence}
              onValueChange={value => {
                setDrivingLicence(value)
                setInputFocused(false)
              }}
              trackColor={{ true: colors.primary, false: colors.backgroundColor }}
            />
          </View>

          <View>
            <TextIconView
              flexDirection="row-reverse"
              backgroundColor="transparent"
              icon={educationIcon}
              iconMargins="0px 10px 0px 0px"
              iconSize="32px"
              iconColor={colors.primaryText}
              text={t('bookScreensEducation')}
              fontWeight="300"
            />

            <Switch
              value={tutor}
              onValueChange={value => {
                setTutor(value)
                setInputFocused(false)
              }}
              trackColor={{ true: colors.primary, false: colors.backgroundColor }}
            />
          </View>
        </TextView>

        <ProfileLanguagesEdit
          languages={languages}
          valid={!!Object.values(languages).filter(lan => lan).length}
          onToggleLanguage={language => {
            const languagesCopy = { ...languages }
            languagesCopy[`${language}`] = !languagesCopy[`${language}`]

            setLanguages(languagesCopy)
          }}
        />

        <Border />

        <LabeledInput
          paddings="0px 5px 0px 5px"
          margins="20px"
          backgroundColor={colors.white}
          inputBackgroundColor={colors.backgroundColor}
          borderLeftWidth={isAccountNumberValid ? 0 : '5px'}
          borderColor={colors.secondary}
          inputBorderColor={inputFocused ? colors.secondary : colors.primary}
          labelText={t('bankAccount')}
          returnKeyType="done"
          value={accountNumber}
          inputFlex={1}
          inputPaddings="0px 0px 0px 5px"
          onChangeText={setAccountNumber}
          onFocus={() => setInputFocused(true)}
          onSubmitEditing={() => setInputFocused(false)}
        />
      </ScrollView>

      {uploadingVideo && (
        <OverlayMessage ref={overlayRef} message={t('profileScreensVideoUploadingMessage')} />
      )}

      {alertData && !navigation && <Alert ref={alertRef} {...alertData} />}
    </SafeAreaView>
  )
}

EditPersonalSettings.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func
  }),
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    setOptions: PropTypes.func,
    goBack: PropTypes.func,
    pop: PropTypes.func
  }),
  user: PropTypes.shape({
    video_upload_url: PropTypes.string,
    account_number: PropTypes.string,
    languages: PropTypes.shape({
      dutch: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      english: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      french: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      german: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      italian: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      spanish: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
    }),
    angel: PropTypes.shape({
      video: PropTypes.string,
      normal_rate: PropTypes.number,
      extra_rate: PropTypes.number,
      fixed_rate: PropTypes.number,
      special_rate: PropTypes.number,
      first_aid: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      driving_license: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      tutor: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
    }),
    editErrorMessage: PropTypes.string,
    rates_info: PropTypes.shape({
      average_extra_rate: PropTypes.number,
      average_fixed_rate: PropTypes.number,
      average_normal_rate: PropTypes.number,
      average_special_rate: PropTypes.number
    })
  }).isRequired,
  profileUpdateError: PropTypes.string,
  updatingProfile: PropTypes.bool,
  profileUpdated: PropTypes.bool,
  videoUploaded: PropTypes.bool,
  serverError: PropTypes.bool,
  updateProfileData: PropTypes.func.isRequired,
  updateProfileVideoData: PropTypes.func.isRequired
}

EditPersonalSettings.defaultProps = {
  history: null,
  navigation: null,
  profileUpdateError: null,
  updatingProfile: false,
  profileUpdated: false,
  videoUploaded: false,
  serverError: false
}

const mapStateToProps = ({ User }) => ({
  user: User.user,
  profileUpdateError: User.profileUpdateError,
  updatingProfile: User.updatingProfile,
  profileUpdated: User.profileUpdated,
  videoUploaded: User.videoUploaded,
  serverError: User.serverError
})

const mapDispatchToProps = {
  updateProfileData: updateProfile,
  updateProfileVideoData: updateProfileVideo
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPersonalSettings)
