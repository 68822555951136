import AsyncStorage from '@react-native-async-storage/async-storage'
import jwt_decode from 'jwt-decode'

import { StorageKeys } from '../const'
import { getBaseUrl } from '@charlycares/shared'

export const getUserToken = async (): Promise<string | null> => {
  try {
    let token = await AsyncStorage.getItem(StorageKeys.AUTH_TOKEN)
    const baseUrl = await getBaseUrl()

    if (token && isTokenExpired(token)) {
      const res = await fetch(`${baseUrl}v2/authenticate/refresh`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (!res.ok) {
        throw new Error('Failed to refresh token')
      }

      const data = await res.json()

      token = data.data?.token as string
      await AsyncStorage.setItem(StorageKeys.AUTH_TOKEN, token)
    }

    return token
  } catch (error) {
    return null
  }
}

export const isAuthenticated = async (): Promise<boolean> => {
  try {
    const token = await AsyncStorage.getItem(StorageKeys.AUTH_TOKEN)

    return token !== null
  } catch (error) {
    return false
  }
}

export function isTokenExpired(token: string) {
  try {
    const decoded: any = jwt_decode(token)

    return decoded.exp < Date.now() / 1000
  } catch (error) {
    return false
  }
}

export function isImpersonated(token: string) {
  try {
    const decoded: any = jwt_decode(token)

    return decoded.impersonating
  } catch (error) {
    return false
  }
}
