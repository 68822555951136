import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { signup, toggleLikeAngel } from '../../../stores/actions/userAction'
import { Text } from '../../../elements'
import { TextButton, KidsEdit, LanguagesEdit, Header } from '../../../components'
import { getFormatedDate } from '../../../utils/functions'
import colors from '../../../styles/colors'

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  justify-content: space-between;
  overflow: hidden;
`
const View = styled.View``

const allLanguages = t => [
  { value: 'dutch', label: t('dutchLanguage') },
  { value: 'english', label: t('englishLanguage') },
  { value: 'french', label: t('frenchLanguage') },
  { value: 'german', label: t('germanLanguage') },
  { value: 'italian', label: t('italianLanguage') },
  { value: 'spanish', label: t('spanishLanguage') }
]

const Step2 = ({
  location,
  history,
  route,
  navigation,
  fetching,
  user,
  onToggleLikeAngel,
  signUp
}) => {
  const { shareAngelId, step1Data } = route.params || location.state || {}
  const [kids, setKids] = useState([])
  const [languages, setLanguages] = useState([])
  const [t, i18n] = useTranslation()
  const languagesPickerRef = useRef(null)
  const languagesOptions = allLanguages(t)
  const disabled = !kids.length || !languages.length

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize={18} fontWeight="600">
          {t('signUpParentScreensMatchingOurAngels')}
        </Text>
      )
    })
  }

  useEffect(() => {
    if (user) {
      if (shareAngelId) {
        onToggleLikeAngel(shareAngelId, true)
      }
      if (navigation) {
        navigation.navigate('OnboardingParentSignUpStack')
      } else {
        history.push('/signup-family-bio')
      }
    }
  }, [user])

  const onSignUpPress = () => {
    const userData = { ...step1Data }
    const selectedLanguages = {}
    languagesOptions.forEach(({ value }) => {
      selectedLanguages[value] = !!languages.find(language => language.value === value)
    })
    userData.kids = kids.map(kidBirthdate => getFormatedDate(kidBirthdate, 'YYYY-MM-DD'))
    userData.languages = selectedLanguages
    userData.locale = i18n.language
    userData.confirmation_link = 'something random'
    signUp('family', userData)
  }

  return (
    <SafeAreaView>
      <View>
        <Header title={t('signUpParentScreensMatchingOurAngels')} />

        <KidsEdit
          title={t('signUpParentScreensKidsBirthdates')}
          description={t('signUpParentScreensKidsBirthdatesDescription')}
          kids={kids}
          onAddPress={birthdate => {
            if (kids.indexOf(birthdate) === -1) {
              setKids([...kids, birthdate])
            }
          }}
          onDeletePress={birthdate => setKids(kids.filter(kid => kid !== birthdate))}
        />

        <LanguagesEdit
          description={t('signUpParentScreensChooseLanguagesDescription')}
          languages={languages}
          languagesOptions={languagesOptions}
          setLanguages={setLanguages}
          onPress={() => languagesPickerRef && languagesPickerRef.current.setIsVisible(true)}
        />
      </View>

      <TextButton
        disabled={disabled || fetching}
        loading={fetching}
        margins="20px"
        backgroundColor={disabled ? colors.grey07 : colors.primary}
        text={t('next')}
        onPress={onSignUpPress}
      />
    </SafeAreaView>
  )
}

Step2.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({})
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  route: PropTypes.shape({
    params: PropTypes.shape({})
  }),
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    setOptions: PropTypes.func
  }),
  user: PropTypes.shape({}),
  fetching: PropTypes.bool,
  signUp: PropTypes.func.isRequired,
  onToggleLikeAngel: PropTypes.func.isRequired
}

Step2.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null,
  user: null,
  fetching: false
}

const mapStateToProps = ({ User }) => ({
  user: User.user,
  fetching: User.fetching
})

const mapDispatchToProps = {
  signUp: signup,
  onToggleLikeAngel: toggleLikeAngel
}

export default connect(mapStateToProps, mapDispatchToProps)(Step2)
