import React, { useState, useEffect } from 'react';
import { FlatList } from 'react-native';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getJobs } from '../../stores/actions/jobFinderActions';
import { getIndicators } from '../../stores/actions/userAction';
import { Text } from '../../elements';
import {
  TextButton,
  ButtonSwitch,
  SuspendedCard,
  JobCard,
  AngelJobsLoader,
  Header
} from '../../components';
import colors from '../../styles/colors';

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
`;

const Overview = ({ history, navigation, jobs, fetching, user, getJobsData }) => {
  const [currentTab, setCurrentTab] = useState('regular');
  const [t] = useTranslation();

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('jobFinder')}
        </Text>
      )
    });
  }

  const refreshJobs = () => {
    getJobsData(currentTab);
  };

  useEffect(() => {
    refreshJobs();
  }, []);

  const onCardPress = (invitationDetails) => {
    if (navigation) {
      navigation.navigate('JobFinderDetails', {
        bookingId: invitationDetails.id,
        invitationDetails
      });
    } else {
      history.push({
        pathname: '/job-finder-details',
        state: { bookingId: invitationDetails.id, invitationDetails }
      });
    }
  };

  const switchTab = (newTab) => {
    setCurrentTab(newTab);
    getJobsData(newTab);
  };

  const { angel } = user;
  if (angel.current_state === 'suspended') {
    return <SuspendedCard />;
  }
  return (
    <SafeAreaView>
      {!navigation && <Header title={t('jobFinder')} />}
      <ButtonSwitch>
        <TextButton
          height="auto"
          width="50%"
          borderRadius="5px"
          text={t('jobFinderRegular')}
          fontSize="16px"
          fontWeight="600"
          textColor={currentTab === 'regular' ? colors.white : colors.primary}
          backgroundColor={currentTab === 'regular' ? colors.primary : colors.white}
          onPress={() => switchTab('regular')}
        />

        <TextButton
          height="auto"
          width="50%"
          borderRadius="5px"
          text={t('jobFinderFixed')}
          fontSize="16px"
          fontWeight="600"
          textColor={currentTab === 'fixed' ? colors.white : colors.primary}
          backgroundColor={currentTab === 'fixed' ? colors.primary : colors.white}
          onPress={() => switchTab('fixed')}
        />
      </ButtonSwitch>

      {!fetching ? (
        <>
          {jobs && jobs.length === 0 ? (
            <Text margins="20px" textAlign="center">
              {t('jobFinderNoJobs')}
            </Text>
          ) : (
            <FlatList
              data={jobs}
              renderItem={({ item }) => (
                <JobCard
                  key={item.id}
                  family={`Fam. ${item.family_data.last_name}`}
                  currentState="pending"
                  repeatQty={item.repeat_qty}
                  bookingDates={item.booking_dates}
                  bookings={item.bookings}
                  onCardPress={() => onCardPress(item)}
                />
              )}
              keyExtractor={({ id }) => id.toString()}
            />
          )}
        </>
      ) : (
        <AngelJobsLoader />
      )}
    </SafeAreaView>
  );
};

Overview.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }),
  navigation: PropTypes.shape({ setOptions: PropTypes.func, navigate: PropTypes.func }),
  jobs: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({ angel: PropTypes.shape({ current_state: PropTypes.string }) }).isRequired,
  fetching: PropTypes.bool,
  getJobsData: PropTypes.func.isRequired
};

Overview.defaultProps = {
  history: null,
  navigation: null,
  jobs: [],
  fetching: true
};

const mapStateToProps = ({ JobFinder, User }) => ({
  jobs: JobFinder.jobs,
  user: User.user,
  fetching: JobFinder.fetching
});

const mapDispatchToProps = {
  getJobsData: getJobs,
  getIndicatorsData: getIndicators
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
