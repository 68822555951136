import React from 'react'
import { Box, HStack, Text, IBoxProps, Spacer, Spinner } from 'native-base'
import { CharlyIcon } from '../icons'
import { useTranslation } from 'react-i18next'

interface Props extends IBoxProps {
  loading?: boolean
}

const SepaDirectDebit = ({ loading, ...boxProps }: Props) => {
  const { t } = useTranslation()

  return (
    <Box rounded="md" bgColor="white" width="100%" px="15px" py="12px" {...boxProps} shadow="3">
      {!loading && (
        <>
          <Text fontSize="16px" color="text.dark.400" fontWeight="600">
            {t('sepaDirectDebit.title')}
          </Text>
          <HStack>
            <Text>{t('sepaDirectDebit.message')}</Text>
            <Spacer />
            <CharlyIcon name="icn-caret-right" width="22px" />
          </HStack>
        </>
      )}
      {loading && <Spinner size={45} />}
    </Box>
  )
}

export default SepaDirectDebit
