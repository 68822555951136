import React from 'react'
import { Box, Button, Heading, Image, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import { LandingLayout } from '@charlycares/ui'
import { isWeb, PreBoardingStep1, useRouter } from '@charlycares/shared'
import { ServicesWeOffer } from '../../common'

export default function PreBoardingFamilyStep1Screen() {
  const { navigate, getParam } = useRouter()
  const { t } = useTranslation()

  return (
    <LandingLayout
      withGoBack
      BottomComponent={
        <Box w="100%" p="20px">
          <Button
            onPress={() =>
              navigate('PreBoardingFamilyStep2Screen', `/family-preboarding-step-2`, {
                referralCode: getParam('referralCode', null)
              })
            }
            bgColor="white"
            _text={{ color: 'secondary.400', fontSize: '16px', fontWeight: 600 }}
          >
            {t('preBoardingFamilyStep1Screen.next')}
          </Button>
          <Button
            onPress={() => navigate('Login', `/login`)}
            variant="text"
            mt="10px"
            _text={{ color: 'white', fontSize: '16px' }}
          >
            {t('login')}
          </Button>
        </Box>
      }
    >
      <Box w="100%" mt="20px">
        <Image
          source={PreBoardingStep1 as any}
          alt="PreBoardingFamilyStep1Screen"
          maxHeight="180px"
          height={isWeb ? '180px' : '110px'}
          resizeMode="contain"
          width="100%"
        />
      </Box>
      <Box w="100%" px="20px" mt="20px">
        <Heading color="white" fontSize="22px" fontWeight="600">
          {t('preBoardingFamilyStep1Screen.heading')}
        </Heading>
        <Text color="white" fontSize="18px" mt="20px" fontWeight="400">
          {t('preBoardingFamilyStep1Screen.subHeading')}
        </Text>
      </Box>

      <ServicesWeOffer />
    </LandingLayout>
  )
}

PreBoardingFamilyStep1Screen.navigationOptions = () => ({
  headerShown: false
})
