import { BookingStates, IBookingDetails, AngelSummery, IOffer, Money } from '@charlycares/shared'
import { ApiTags } from '../types'
import { api } from '../api'
import {
  AddAngelToBookingBody,
  AngelCreateBookingBody,
  BookingCancellationRate,
  BookingCancellationRateQuery,
  BookingCancellationReason,
  BookingPaymentSummary,
  BookingPaymentSummaryQuery,
  BookingQuery,
  BookingRequestType,
  BookingTimer,
  BookingWalletTransaction,
  BookingWalletTransactionQuery,
  CalculatePotentialEarningsBody,
  CancelBookingQuery,
  CancelledBookingWalletTransaction,
  CreateBookingBody,
  GetBookingResponse,
  GetJobsArgs,
  GetJobsResponse,
  MembershipWalletTransaction,
  PayBooking,
  PayBookingQuery,
  RecreateBookingBody,
  SearchAngelsBody,
  SendOfferBody,
  ServiceGroupTypeResource,
  WalletTransactionQuery
} from './booking.dto'

export const bookingApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: ({ mutation, query }) => ({
    getBookings: query<GetBookingResponse, { status?: BookingStates; page: number }>({
      query: ({ page, ...body }) => ({
        url: `v2/bookings/list?page=${page}`,
        method: 'POST',
        body
      }),
      providesTags: [ApiTags.BOOKINGS]
    }),
    getBookingCancellationReasons: query<BookingCancellationReason[], BookingQuery>({
      query: ({ booking_id }) => ({
        url: `v2/bookings/${booking_id}/cancellation-reasons`,
        method: 'GET'
      }),
      transformResponse: ({ data }): BookingCancellationReason[] => data
    }),
    createBooking: mutation<void, CreateBookingBody>({
      query: body => ({
        url: 'v2/bookings',
        method: 'POST',
        body
      }),
      invalidatesTags: [ApiTags.BOOKINGS]
    }),
    getJobs: query<GetJobsResponse, GetJobsArgs>({
      query: ({ page, ...body }) => ({
        url: `v2/bookings/jobs?page=${page}`,
        method: 'POST',
        body
      }),
      providesTags: [ApiTags.JOBS]
    }),
    getBookingDetails: query<IBookingDetails, { obscured_id: string; isOffer?: boolean }>({
      query: ({ obscured_id, isOffer }) => ({
        url: `v2/bookings/${obscured_id}${isOffer ? `?isOffer=${isOffer}` : ''}`,
        method: 'GET'
      }),
      providesTags: result => {
        return [{ type: ApiTags.BOOKING, id: result?.obscured_id }]
      },
      transformResponse: ({ data }): IBookingDetails => data
    }),
    getActiveTimer: query<BookingTimer, void>({
      query: () => ({
        url: 'v2/bookings/active-timer',
        method: 'GET'
      }),
      providesTags: [ApiTags.BOOKING_TIMER],
      transformResponse: ({ data }): BookingTimer => data
    }),
    getPaymentSummary: mutation<BookingPaymentSummary, BookingPaymentSummaryQuery>({
      query: ({ booking_id, ...body }) => {
        return {
          url: `v2/bookings/${booking_id}/payment-summary`,
          method: 'POST',
          body
        }
      },
      transformResponse: ({ data }): BookingPaymentSummary => data
    }),
    payBooking: mutation<PayBooking | BookingPaymentSummary, PayBookingQuery>({
      query: ({ booking_id, ...body }) => {
        return {
          url: `v2/bookings/${booking_id}/pay`,
          method: 'POST',
          body
        }
      },
      invalidatesTags: [ApiTags.BOOKING_TIMER],
      transformResponse: ({ data }): PayBooking | BookingPaymentSummary => data
    }),
    getBookingTransactions: query<BookingWalletTransaction, BookingWalletTransactionQuery>({
      query: ({ booking_id, transaction_uuid }) => {
        return {
          url: `v2/bookings/${booking_id}/transactions/${transaction_uuid}`,
          method: 'GET'
        }
      },
      transformResponse: ({ data }): BookingWalletTransaction => data
    }),
    stopTimer: mutation<BookingPaymentSummary, BookingPaymentSummaryQuery>({
      query: ({ booking_id, ...body }) => {
        return {
          url: `v2/bookings/${booking_id}/stop-timer`,
          method: 'POST',
          body
        }
      },
      transformResponse: ({ data }): BookingPaymentSummary => data
    }),
    sendOffer: mutation<void, SendOfferBody & { booking_id: string }>({
      query: ({ booking_id, ...body }) => ({
        url: `v2/bookings/${booking_id}/offers`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result, error, arg) => [
        { type: ApiTags.BOOKING, id: arg.booking_id },
        ApiTags.BOOKINGS,
        ApiTags.JOBS
      ]
    }),
    acceptOffer: mutation<IOffer, { parentBookingObscuredId: string; offerObscuredId: string }>({
      query: ({ parentBookingObscuredId, offerObscuredId }) => ({
        url: `v2/bookings/${parentBookingObscuredId}/offers/${offerObscuredId}/accept`,
        method: 'POST'
      }),
      invalidatesTags: (result, error, arg) => [
        { type: ApiTags.BOOKING, id: arg.parentBookingObscuredId },
        ApiTags.BOOKINGS
      ],
      transformResponse: ({ data }): IOffer => data
    }),
    declineOffer: mutation<void, { parentBookingObscuredId: string; offerObscuredId: string }>({
      query: ({ parentBookingObscuredId, offerObscuredId }) => ({
        url: `v2/bookings/${parentBookingObscuredId}/offers/${offerObscuredId}/decline`,
        method: 'POST'
      }),
      invalidatesTags: (result, error, arg) => [
        { type: ApiTags.BOOKING, id: arg.parentBookingObscuredId },
        ApiTags.BOOKINGS
      ]
    }),
    acceptInvitation: mutation<void, { bookingObscuredId: string; invitationObscuredId: string }>({
      query: ({ bookingObscuredId, invitationObscuredId, ...body }) => ({
        url: `v2/bookings/${bookingObscuredId}/invitations/${invitationObscuredId}/accept`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result, error, arg) => [{ type: ApiTags.BOOKING, id: arg.bookingObscuredId }, ApiTags.BOOKINGS]
    }),
    declineInvitation: mutation<void, { bookingObscuredId: string; invitationObscuredId: string }>({
      query: ({ bookingObscuredId, invitationObscuredId, ...body }) => ({
        url: `v2/bookings/${bookingObscuredId}/invitations/${invitationObscuredId}/decline`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result, error, arg) => [{ type: ApiTags.BOOKING, id: arg.bookingObscuredId }, ApiTags.BOOKINGS]
    }),
    addAngelToBooking: mutation<void, AddAngelToBookingBody & { bookingObscuredId: string }>({
      query: ({ bookingObscuredId, ...body }) => ({
        url: `v2/bookings/${bookingObscuredId}/add-angel`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result, error, arg) => [{ type: ApiTags.BOOKING, id: arg.bookingObscuredId }, ApiTags.BOOKINGS]
    }),
    searchAngels: query<AngelSummery[], SearchAngelsBody>({
      query: ({ bookingObscuredId, ...body }) => ({
        url: `v2/matching/angels${bookingObscuredId ? `/${bookingObscuredId}` : ''}`,
        method: 'POST',
        body
      }),
      transformResponse: ({ data }): AngelSummery[] => data,
      keepUnusedDataFor: 0
    }),
    angelCreateBooking: mutation<void, AngelCreateBookingBody>({
      query: body => ({
        url: `v2/bookings/angel`,
        method: 'POST',
        body
      }),
      invalidatesTags: [ApiTags.BOOKINGS]
    }),
    getBookingCancellationRate: query<BookingCancellationRate, BookingCancellationRateQuery>({
      query: ({ booking_obscured_id, ...body }) => {
        return {
          url: `v2/bookings/${booking_obscured_id}/cancellation-rate`,
          method: 'POST',
          body
        }
      },
      transformResponse: ({ data }): BookingCancellationRate => data
    }),
    cancelBooking: mutation<IBookingDetails, CancelBookingQuery>({
      query: ({ booking_obscured_id, ...body }) => {
        return {
          url: `v2/bookings/${booking_obscured_id}/cancel`,
          method: 'POST',
          body
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiTags.BOOKING, id: arg.booking_obscured_id },
        ApiTags.BOOKINGS,
        ApiTags.BOOKING_TIMER
      ],
      transformResponse: ({ data }): IBookingDetails => data
    }),
    recreateBooking: mutation<void, RecreateBookingBody>({
      query: body => ({
        url: `v2/bookings/recreate`,
        method: 'POST',
        body
      }),
      invalidatesTags: [ApiTags.BOOKINGS]
    }),
    calculatePotentialEarnings: mutation<Money, CalculatePotentialEarningsBody>({
      query: ({ booking_obscured_id, ...body }) => {
        return {
          url: `v2/bookings/${booking_obscured_id}/potential-earning`,
          method: 'POST',
          body
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: ApiTags.BOOKING, id: arg.booking_obscured_id },
        ApiTags.BOOKINGS
      ],
      transformResponse: ({ data }): Money => data
    }),
    getBookingRequestTypes: query<BookingRequestType[], void>({
      query: () => {
        return {
          url: `v2/bookings/request-types`,
          method: 'GET'
        }
      },
      transformResponse: ({ data }): BookingRequestType[] => data
    }),
    getServiceGroupTypes: query<ServiceGroupTypeResource[], void>({
      query: () => {
        return {
          url: `v2/service-group-types`,
          method: 'GET'
        }
      },
      transformResponse: ({ data }): ServiceGroupTypeResource[] => data
    }),
    getCancelledBookingTransaction: query<CancelledBookingWalletTransaction, BookingWalletTransactionQuery>({
      query: ({ booking_id, transaction_uuid }) => {
        return {
          url: `v2/bookings/cancelled-booking/${booking_id}/transactions/${transaction_uuid}`,
          method: 'GET'
        }
      },
      transformResponse: ({ data }): CancelledBookingWalletTransaction => data
    }),
    getMembershipTransaction: query<MembershipWalletTransaction, WalletTransactionQuery>({
      query: ({ transaction_uuid }) => {
        return {
          url: `v2/memberships/transactions/${transaction_uuid}`,
          method: 'GET'
        }
      },
      transformResponse: ({ data }): MembershipWalletTransaction => data
    })
  })
})

export const {
  useGetJobsQuery,
  useCreateBookingMutation,
  useGetActiveTimerQuery,
  useGetBookingCancellationReasonsQuery,
  useGetPaymentSummaryMutation,
  useGetBookingTransactionsQuery,
  useStopTimerMutation,
  useGetBookingDetailsQuery,
  useSendOfferMutation,
  useGetBookingsQuery,
  useAcceptOfferMutation,
  useDeclineOfferMutation,
  useAcceptInvitationMutation,
  useDeclineInvitationMutation,
  useAddAngelToBookingMutation,
  useSearchAngelsQuery,
  useAngelCreateBookingMutation,
  useGetBookingCancellationRateQuery,
  useCancelBookingMutation,
  useRecreateBookingMutation,
  useCalculatePotentialEarningsMutation,
  useGetBookingRequestTypesQuery,
  useGetServiceGroupTypesQuery,
  usePayBookingMutation,
  useGetCancelledBookingTransactionQuery,
  useGetMembershipTransactionQuery
} = bookingApi
