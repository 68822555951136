import {
  GET_BOOKINGS,
  FETCHING_BOOKINGS,
  GET_NEXT_BOOKINGS,
  SET_BOOKING_DETAILS,
  ACCEPT_BOOKING,
  BOOKING_ACCEPTED_ERROR,
  UPDATING_BOOKING_DETAILS,
  CHECK_AVAILABILITY,
  CHECK_AVAILABILITY_ERROR,
  GET_ACTIVE_TIMINGS,
  GET_UPDATED_TIMING
} from '../types';

const initialState = {
  fetching: false,
  bookings: null,
  active_bookings: null,
  bookingDetails: null,
  updatingDetails: false,
  acceptedError: null
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_BOOKINGS:
      return {
        ...payload,
        bookingDetails: state.bookingDetails,
        fetching: false
      };
    case FETCHING_BOOKINGS:
      return {
        ...state,
        fetching: true
      };
    case GET_NEXT_BOOKINGS:
      return {
        ...payload,
        bookings: { ...payload.bookings, data: [...state.bookings.data, ...payload.bookings.data] },
        bookingDetails: state.bookingDetails,
        fetching: false
      };
    case SET_BOOKING_DETAILS:
      return {
        ...state,
        bookingDetails: payload,
        updatingDetails: false,
        fetching: false
      };
    case UPDATING_BOOKING_DETAILS:
      return {
        ...state,
        updatingDetails: true
      };
    case ACCEPT_BOOKING:
      return {
        ...state,
        active_bookings: [...payload, ...state.active_bookings]
      };
    case BOOKING_ACCEPTED_ERROR:
      return {
        ...state,
        acceptedError: payload
      };
    case CHECK_AVAILABILITY:
      return {
        ...state,
        bookingDetails: {
          ...state.bookingDetails,
          available_angels: payload.available_angels,
          unavailable_angels: payload.unavailable_angels,
          availabilityErrorMessage: null
        },
        fetching: false
      };
    case CHECK_AVAILABILITY_ERROR:
      return {
        ...state,
        bookingDetails: {
          ...(state.bookingDetails || {}),
          availabilityErrorMessage: payload
        }
      };
    case GET_ACTIVE_TIMINGS:
      return {
        ...state,
        activeBooking: payload,
        fetching: false
      };
    case GET_UPDATED_TIMING:
      console.log(payload.rates);
      return {
        ...state,
        activeBooking: { ...state.activeBooking, rates: payload.rates }
      };
    default:
      return state;
  }
}
