import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const EyeShutIcon = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 20 12" fill="none" {...props}>
    <Path
      d="M17.626.646a.65.65 0 00-.947.89l.403.438c.28.31.584.657.91 1.04l.15.177-.203.225C15.286 6.287 12.58 7.7 9.81 7.7l-.295-.006c-2.65-.097-5.14-1.502-7.484-4.26l-.203-.243.149-.19c.295-.379.57-.72.823-1.024l.365-.429a.65.65 0 10-.982-.853c-.363.419-.772.919-1.228 1.501l-.47.61a.65.65 0 00.005.796 19.33 19.33 0 002.098 2.293l-.95 1.645-.042.087a.65.65 0 001.168.563l.833-1.443c.653.5 1.319.919 1.999 1.253l-.482 1.797-.018.094a.65.65 0 001.274.242l.437-1.63a9.287 9.287 0 002.544.485V11l.007.096A.65.65 0 0010.65 11V9l-.003-.036a9.774 9.774 0 002.517-.571l.467 1.741.032.091a.65.65 0 001.224-.427l-.518-1.932c.64-.325 1.272-.72 1.898-1.182l.87 1.507.054.08a.65.65 0 001.072-.73l-.97-1.681a21.617 21.617 0 002.2-2.235.65.65 0 00.006-.84A48.341 48.341 0 0017.626.646z"
      fill="currentColor"
    />
  </Svg>
)

export default EyeShutIcon
