import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ActivityIndicator, Linking } from 'react-native';
import { FullLogo, Text } from '../../../elements';
import { TextButton, PaymentCard, TextTextView } from '../../../components';
import colors from '../../../styles/colors';
import { getOutstandingPayments } from '../../../stores/actions/paymentsAction';
import { roundMonetaryValue } from '../../../utils/functions';

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  justify-content: center;
  margin: 20px;
`;

const View = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px 20px 20px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: ${colors.grey07};
`;

const Landing = ({
  getOutstandingPaymentsData,
  outstandingPayments,
  outstandingAmount,
  paymentLink,
  navigation
}) => {
  const [t] = useTranslation();
  const params = useParams();

  useEffect(() => {
    getOutstandingPaymentsData(params.reference);
  }, []);

  if (!outstandingPayments) {
    return <ActivityIndicator />;
  }

  return (
    <SafeAreaView>
      <FullLogo margins="50px auto 30px auto" width="211px" height="96px" />
      <Text fontSize="18px" textAlign="center" margins="0px 0px 15px 0px">
        {t('outstandingPaymentsTitle')}
      </Text>
      {outstandingPayments.map((payment) => {
        return (
          <PaymentCard
            createdAt={payment.created_at}
            totalAmount={payment.total_amount}
            currentState={payment.current_state}
            reason={payment.reason}
          />
        );
      })}

      <View>
        <TextTextView
          flexDirection="column"
          justifyContent="flex-start"
          paddings="0px"
          firstText={t('paymentsTotalOutstandingAmount')}
          firstTextFontSize="12px"
          secondText={`€ ${roundMonetaryValue(outstandingAmount)}`}
          secondTextMargins="5px 0px 0px 0px"
          secondTextFontSize="30px"
          secondTextColor={colors.alert}
        />

        <TextButton
          padding="5px 5px 5px 5px"
          margins="15px 0px 0px 0px"
          backgroundColor={colors.primary}
          text={t('paymentsScreenOutstandingPayNow')}
          fontSize="16px"
          fontWeight="600"
          onPress={() => {
            if (navigation) {
              navigation.navigate('WebView', { uri: paymentLink });
            } else {
              window.open(paymentLink, '_self');
            }
          }}
        />
      </View>
      <Text fontSize="15px" margins="20px 0px 20px 0px">
        {t('outstandingPaymentsHelp')}
      </Text>
      <Text fontSize="15px" margins="0px 0px 15px 0px">
        {t('outstandingPaymentsContact')}
      </Text>
    </SafeAreaView>
  );
};

Landing.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func, replace: PropTypes.func }),
  navigation: PropTypes.shape({ navigate: PropTypes.func }),
  getOutstandingPaymentsData: PropTypes.func.isRequired,
  outstandingPayments: PropTypes.arrayOf(PropTypes.shape({})),
  outstandingAmount: PropTypes.number,
  paymentLink: PropTypes.string
};

Landing.defaultProps = {
  history: null,
  navigation: null,
  outstandingPayments: null,
  outstandingAmount: null,
  paymentLink: null
};

const mapStateToProps = ({ Payments }) => ({
  outstandingPayments: Payments.outstandingPayments,
  outstandingAmount: Payments.outstandingAmount,
  paymentLink: Payments.paymentLink
});

const mapDispatchToProps = {
  getOutstandingPaymentsData: getOutstandingPayments
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
