import React, { useEffect, useState } from 'react'
import { Card, ScreenLayout, TextInput } from '@charlycares/ui'
import { useGetUserQuery, useLinkFamilyMutation } from '@charlycares/data-access'
import { useTranslation } from 'react-i18next'
import { Heading, Text } from 'native-base'
import { ServiceGroupType, useRouter } from '@charlycares/shared'
import { useAlert } from '@charlycares/features'
import { getAngelOnboardingProgress } from '../../../../../legacy/mobileapp/src/utils'
import AsyncStorage from '@react-native-async-storage/async-storage'

export default function ConnectAngelToFamilyScreen() {
  const { navigate, getParam } = useRouter()
  const { t } = useTranslation()
  const alert = useAlert()

  const { data: user } = useGetUserQuery()
  const [connect, { isLoading, error }] = useLinkFamilyMutation()

  const [personalCode, setPersonalCode] = useState('')

  const fromProfileMenu = getParam('fromProfileMenu', false)

  const serviceType = ServiceGroupType.CHILD_CARE

  useEffect(() => {
    if (user && !fromProfileMenu) {
      const step = getAngelOnboardingProgress('ConnectAngelToFamilyScreen')
      AsyncStorage.setItem(`onboarding_${user.id}`, JSON.stringify(step))
    }
  }, [user])

  const onSubmit = async () => {
    try {
      if (personalCode.trim()) {
        await connect({ family_code: personalCode.trim() }).unwrap()
        alert.show(t('connectAngelToFamily.successTitle'), t('connectAngelToFamily.successDescription'), [
          {
            text: t('ok'),
            onPress: () => navigate(fromProfileMenu ? 'ProfileScreen' : 'Dashboard')
          }
        ])
        return
      }
      navigate(fromProfileMenu ? 'ProfileScreen' : 'SignUpAngelReferences')
    } catch (error) {
      alert.show(t('error'), (error as any)?.data?.message || t('connectCodeError'))
    }
  }

  return (
    <ScreenLayout
      title={t('profileScreensAngelConnectWithFamilyHeaderTitle')}
      progress={!fromProfileMenu ? `${60}%` : undefined}
      hideBackButton={!fromProfileMenu}
      _buttonProps={{
        onPress: onSubmit,
        isLoading: isLoading,
        isDisabled: !personalCode.trim() && fromProfileMenu,
        children: t('next')
      }}
    >
      <Card>
        <Heading mb="20px" fontSize="18px" fontWeight={600}>
          {t(`connectAngelToFamily.title.${serviceType}`)}
        </Heading>
        <Text fontSize="15px" mb="20px">
          {t(`connectAngelToFamily.subtitle.${serviceType}`)}
        </Text>

        <TextInput
          mt="10px"
          label={t('signUpAngelConnectWithFamilyLabel')}
          autoCapitalize="characters"
          value={personalCode}
          error={(error as any)?.data?.message}
          onChangeText={text => setPersonalCode(text)}
          placeholder={t(`connectAngelToFamily.placeholder.${serviceType}`)}
          returnKeyType="done"
          onSubmitEditing={() => onSubmit()}
        />
      </Card>
    </ScreenLayout>
  )
}

ConnectAngelToFamilyScreen.navigationOptions = () => ({
  headerShown: false
})
