import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { Text } from '../../elements';
import { TextView } from '../Texts';
import BabysitterCard from './BabysitterCard';
import { DashboardLoader } from '../Loaders';
import colors from '../../styles/colors';

const FlatList = styled.FlatList`
  margin: 20px 0px 0px 0px;
  height: 260px;
  width: 100%;
`;

const LastMinuteBabysitters = ({
  standbyAngels,
  fetchingStandbyAngels,
  onAngelPress,
  onAngelVideoPress,
  onAngelToggleLikePress
}) => {
  const [t] = useTranslation();
  const { data = [] } = standbyAngels || {};

  return (
    <TextView
      flexDirection="column"
      alignItems="flex-start"
      margins="0px 20px 0px 20px"
      paddings="20px 0px 20px 0px"
      borderTopWidth="1px"
      text={t('dashboardScreensLastMinuteSittersTitle')}
      fontSize="22px"
      fontWeight="600">
      <Text lg color={colors.defaultBtnTextColor}>
        {t('dashboardScreensLastMinuteSittersDescription')}
      </Text>
      {fetchingStandbyAngels ? (
        <DashboardLoader />
      ) : (
        <FlatList
          horizontal
          showsHorizontalScrollIndicator={false}
          data={data}
          renderItem={({ item }) => (
            <BabysitterCard
              babysitter={item}
              lastMinute
              onAngelPress={onAngelPress}
              onAngelVideoPress={onAngelVideoPress}
              onToggleLikePress={onAngelToggleLikePress}
            />
          )}
          keyExtractor={({ id }) => String(id)}
        />
      )}
    </TextView>
  );
};

LastMinuteBabysitters.propTypes = {
  standbyAngels: PropTypes.shape({ data: PropTypes.arrayOf(PropTypes.shape({})) }),
  fetchingStandbyAngels: PropTypes.bool,
  onAngelPress: PropTypes.func.isRequired,
  onAngelVideoPress: PropTypes.func.isRequired,
  onAngelToggleLikePress: PropTypes.func.isRequired
};

LastMinuteBabysitters.defaultProps = {
  standbyAngels: [],
  fetchingStandbyAngels: false
};

export default LastMinuteBabysitters;
