import React, { useState, useEffect } from 'react'
import { FlatList } from 'react-native'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getIndicators } from '../../stores/actions/userAction'
import { getFavorites, getNextFavorites, toggleLikeAngelFavorite } from '../../stores/actions/favoritesAction'
import { ContainerView, Heading, Text } from '../../elements'
import { AvailableAngelCard, Header } from '../../components'
import theme from '../../styles/theme'

const getSortOptions = t => [
  { value: 'last_booked', label: t('favoritesScreensLastBooked') },
  { value: 'first_name', label: t('favoritesScreensFirstName') },
  { value: 'last_name', label: t('favoritesScreensLastName') },
  { value: 'distance', label: t('favoritesScreensDistance') }
]

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
`

const Overview = ({
  history,
  navigation,
  favorites,
  fetching,
  getFavoritesData,
  getNextFavoritesData,
  toggleLikeAngelData
}) => {
  const [t] = useTranslation()
  const sortOptions = getSortOptions(t)
  const [page, setPage] = useState(1)
  const [sortOption, setSortOption] = useState(sortOptions[0])
  const { current_page, next_page_url, data = [] } = favorites || {}

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('favoritesScreensFavorites')}
        </Text>
      )
    })
  }
  const refreshFavorites = () => {
    setPage(1)
    getFavoritesData(sortOption.value)
  }

  useEffect(() => {
    refreshFavorites()
  }, [])

  const onToggleLikeAngel = (angelId, liked) => {
    toggleLikeAngelData(angelId, !liked)
  }

  return (
    <SafeAreaView>
      {!navigation && <Header title={t('favoritesScreensFavorites')} />}
      <ContainerView paddings={theme.layoutMainContentPaddingHorizontal} margins="16px 0">
        <Heading lg ariaLevel={2} text={t('favoritesScreensShareFavorites')} />
        <Text>{t('favoritesScreensShareFavoritesDescription')}</Text>
      </ContainerView>
      {data && data.length ? (
        <FlatList
          data={data}
          renderItem={({ item }) => {
            const { profile } = item
            const { obscured_id } = profile

            return (
              <AvailableAngelCard
                favorites
                angel={profile}
                chatEnabled={false}
                onVideoPress={video => {
                  if (navigation) {
                    navigation.navigate('VideoPlayer', { source: video })
                  } else {
                    // TODO
                  }
                }}
                onToggleFavoritePress={onToggleLikeAngel}
                onSharePress={() => {}}
                onBookPress={() => {
                  history.push({
                    pathname: '/book-direct',
                    state: {
                      angelId: obscured_id
                    }
                  })
                }}
                onCardPress={() => {
                  history.push({
                    pathname: '/profile-angel',
                    state: { angelId: obscured_id }
                  })
                }}
              />
            )
          }}
          onEndReachedThreshold={0.5}
          onEndReached={() => {
            if (current_page === page && next_page_url) {
              setPage(page + 1)
              getNextFavoritesData(next_page_url)
            }
          }}
          refreshing={fetching}
          onRefresh={refreshFavorites}
          keyExtractor={({ id }) => String(id)}
        />
      ) : (
        <>
          {sortOption === sortOptions[0] && !fetching && (
            <Text alignSelf="center" margins="20px 0px 0px 0px" width="60%" fontWeight="300" textAlign="center">
              {t('favoritesScreensNoFavorites')}
            </Text>
          )}
        </>
      )}
    </SafeAreaView>
  )
}

Overview.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }),
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    setOptions: PropTypes.func
  }),
  familyId: PropTypes.number.isRequired,
  favorites: PropTypes.shape({}),
  fetching: PropTypes.bool,
  getFavoritesData: PropTypes.func.isRequired,
  getNextFavoritesData: PropTypes.func.isRequired,
  toggleLikeAngelData: PropTypes.func.isRequired
}

Overview.defaultProps = {
  history: null,
  navigation: null,
  favorites: null,
  fetching: false
}

const mapStateToProps = ({ User, Favorites }) => ({
  familyId: User.user.family.id,
  favorites: Favorites.favorites,
  fetching: Favorites.fetching
})

const mapDispatchToProps = {
  getIndicatorsData: getIndicators,
  getFavoritesData: getFavorites,
  getNextFavoritesData: getNextFavorites,
  toggleLikeAngelData: toggleLikeAngelFavorite
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview)
