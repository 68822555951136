import { Alert, Platform } from 'react-native';

const alertPolyfill = (title, description, options) => {
  const result = window.confirm([title, description].filter(Boolean).join('\n'));

  if (result) {
    const confirmOption = options.length > 1 ? options[1] : options[0];
    if (confirmOption && confirmOption.onPress) {
      confirmOption.onPress();
    }
  } else {
    const cancelOption = options[0];
    if (cancelOption && cancelOption.onPress) {
      cancelOption.onPress();
    }
  }
};

const CommonAlert = Platform.OS === 'web' ? alertPolyfill : Alert.alert;

export default CommonAlert;
