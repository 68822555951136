import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Card, TextInput, Center, Icon, ScreenLayout } from '@charlycares/ui'
import { Keyboard, TextInput as TextInputType } from 'react-native'
import { useTheme, Text, Alert, HStack, PresenceTransition, Button } from 'native-base'
import { Platform } from 'react-native'
import {
  ResponseError,
  useAppSelector,
  useGetUserProfileMutation,
  useGetUserQuery,
  useLoginMutation,
  userApi,
  useSavePushTokenMutation
} from '@charlycares/data-access'
import {
  getDevicePushToken,
  getUserNavigationRoute,
  getUserToken,
  isImpersonated,
  useRouter
} from '@charlycares/shared'
import { useDispatch } from 'react-redux'

export default function Login() {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { navigate } = useRouter()
  const dispatch = useDispatch()

  /** State */
  const [showPassword, setShowPassword] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState<string>()
  const passwordRef = useRef<TextInputType>()

  /** Redux */
  const userLegacy = useAppSelector(state => state.User.user)
  const [login, loginState] = useLoginMutation()
  const [getUserProfile, getUserProfileState] = useGetUserProfileMutation()
  const [savePushToken] = useSavePushTokenMutation()
  const {
    data: user,
    isLoading: isUserLoading,
    error: getUserError,
    isError: isGetUserError
  } = useGetUserQuery(undefined, {
    skip: !loginState.isSuccess
  })

  const showErrorAlert = Boolean(errorMessage && (loginState.isError || isGetUserError || getUserProfileState.isError))
  const isLoading = loginState.isLoading || isUserLoading || getUserProfileState.isLoading

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('LoginScreen.validation.email')).required(t('LoginScreen.validation.email')),
    password: Yup.string().required(t('LoginScreen.validation.password'))
  })

  const { handleChange, handleSubmit, errors, values } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: values => {
      Keyboard.dismiss()
      login(values)
    },
    validationSchema,
    validateOnChange: false
  })

  const setupNotifications = useCallback(async () => {
    const userToken = await getUserToken()

    if (userToken && !isImpersonated(userToken)) {
      const pushToken = await getDevicePushToken()

      if (pushToken) {
        savePushToken({ token: pushToken, channel: Platform.OS })
      }
    }
  }, [savePushToken])

  /** Fetch user profile */
  useEffect(() => {
    if (loginState.isSuccess) {
      getUserProfile()
      dispatch(userApi.endpoints.getUser.initiate())
    }
  }, [loginState.isSuccess, getUserProfile, dispatch])

  /** Setup user */
  useEffect(() => {
    if (userLegacy?.id && user) {
      setupNotifications()
      const { screen, params } = getUserNavigationRoute(userLegacy)
      navigate(screen, undefined, params)
    }
  }, [navigate, userLegacy, user, setupNotifications])

  /** Handle errors */
  useEffect(() => {
    if (loginState.error) {
      const serverError = loginState.error as ResponseError

      if (serverError.status >= 500) {
        setErrorMessage(t('LoginScreen.validation.serverError'))
      } else if (serverError.status >= 400) {
        setErrorMessage(t('LoginScreen.validation.invalidCredentials'))
      } else {
        setErrorMessage(serverError.message || t('error'))
      }
    } else if (getUserProfileState?.error || getUserError) {
      const profileError = (getUserProfileState?.error || getUserError) as ResponseError

      if (profileError.status >= 500) {
        setErrorMessage(t('LoginScreen.validation.serverError'))
      } else {
        setErrorMessage(t('LoginScreen.validation.profileError'))
      }
    }
  }, [loginState.error, getUserProfileState.error, getUserError, t])

  return (
    <ScreenLayout disableScroll title={t('login')}>
      <Card mt="10px" h="100%">
        <Text mb="20px" fontSize="15px" fontWeight={300} color="text.dark.400">
          {t('LoginScreen.description')}
        </Text>

        <TextInput
          keyboardType="email-address"
          autoCapitalize="none"
          value={values.email}
          label={t('LoginScreen.email')}
          onChangeText={handleChange('email')}
          error={errors.email}
          placeholder={t('LoginScreen.emailPlaceholder')}
          onSubmitEditing={() => passwordRef.current?.focus()}
          returnKeyType="next"
        />
        <TextInput
          ref={passwordRef}
          autoCapitalize="none"
          value={values.password}
          secureTextEntry={!showPassword}
          label={t('LoginScreen.password')}
          onChangeText={handleChange('password')}
          error={errors.password}
          letterSpacing={showPassword && values.password.length ? '2px' : '0px'}
          placeholder={t('LoginScreen.passwordPlaceholder')}
          onSubmitEditing={() => handleSubmit()}
          returnKeyType="done"
          InputRightElement={
            <Icon
              onPress={() => setShowPassword(!showPassword)}
              name={showPassword ? 'eye-open' : 'eye-shut'}
              size={25}
              color={colors.primary['400']}
            />
          }
        />

        {showErrorAlert && (
          <PresenceTransition
            visible={showErrorAlert}
            style={{ width: '100%' }}
            initial={{
              opacity: 0
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 500
              }
            }}
          >
            <Alert bg="alert.danger.alpha.30" mt="10px" w="100%" status="error">
              <HStack alignItems="center">
                <Alert.Icon mr="5px" />
                <Text textAlign="center" fontWeight={500} shadow={5} color="white">
                  {errorMessage}
                </Text>
              </HStack>
            </Alert>
          </PresenceTransition>
        )}

        <Button isLoading={isLoading} w="100%" mt={'20px'} onPress={() => handleSubmit()}>
          {t('LoginScreen.loginButton')}
        </Button>

        <Center mt={'30px'}>
          <Button
            onPress={() => {
              setErrorMessage(undefined)
              navigate('ForgotPassword', '/forgot-password')
            }}
            variant="text"
          >
            {t('LoginScreen.forgotPassword')}
          </Button>
        </Center>
      </Card>
    </ScreenLayout>
  )
}

Login.navigationOptions = () => ({
  headerShown: false
})
