import React from 'react'
import { Box, HStack, Spacer, Text } from 'native-base'
import { SvgUri } from '@charlycares/ui'
import { MoneyWalletTransaction, TimeWalletTransaction } from '@charlycares/data-access'
import { formatInterval, formatMoney } from '@charlycares/shared'
import { useTranslation } from 'react-i18next'

export default ({ walletTransaction }: { walletTransaction: MoneyWalletTransaction | TimeWalletTransaction }) => {
  const { t } = useTranslation()

  if (typeof walletTransaction.current_saldo === 'object' && walletTransaction.current_saldo?.amount <= 0) {
    return null
  }

  return (
    <Box width="full">
      <HStack>
        <Text fontSize="22px" fontWeight="600">
          {t('walletTransaction.title')}
        </Text>
        <Spacer />
        <Box height="35px" maxWidth="100px" width="100%">
          {walletTransaction.wallet_image && <SvgUri uri={walletTransaction.wallet_image} size="100%" />}
        </Box>
      </HStack>
      <HStack marginTop="15px">
        <Box textAlign="center">
          <Text color="gray.900" paddingBottom="6px" fontSize="12px" lineHeight="17px" textAlign="center">
            {t('walletTransaction.currentSaldo')}
          </Text>
          <Box bgColor="secondary.alpha.15" padding="10px" textAlign="center">
            <Text color="gray.800" fontWeight="600" fontSize="15px" lineHeight="25px" textAlign="center">
              {typeof walletTransaction.current_saldo === 'string'
                ? formatInterval(walletTransaction.current_saldo, false, true)
                : formatMoney(walletTransaction.current_saldo)}
            </Text>
          </Box>
        </Box>
        <Spacer>
          <Text color="gray.900" paddingBottom="6px" fontSize="12px" lineHeight="17px" textAlign="center">
            &nbsp;
          </Text>
          <Box bgColor="secondary.alpha.15" padding="10px" textAlign="center">
            <Text color="gray.800" fontWeight="600" fontSize="15px" lineHeight="25px" textAlign="center">
              &nbsp;
            </Text>
          </Box>
        </Spacer>
        <Box>
          <Text color="gray.900" paddingBottom="6px" fontSize="12px" lineHeight="17px" textAlign="center">
            {t('walletTransaction.usedSaldo')}
          </Text>
          <Box bgColor="secondary.alpha.15" padding="10px" textAlign="center">
            <Text color="gray.800" fontWeight="600" fontSize="15px" lineHeight="25px" textAlign="center">
              {typeof walletTransaction.used_saldo === 'string'
                ? formatInterval(walletTransaction.used_saldo, false, true)
                : formatMoney(walletTransaction.used_saldo)}
            </Text>
          </Box>
        </Box>
        <Spacer>
          <Text color="gray.900" paddingBottom="6px" fontSize="12px" lineHeight="17px" textAlign="center">
            &nbsp;
          </Text>
          <Box bgColor="secondary.alpha.15" padding="10px" textAlign="center">
            <Text color="gray.800" fontWeight="600" fontSize="15px" lineHeight="25px" textAlign="center">
              &nbsp;
            </Text>
          </Box>
        </Spacer>
        <Box>
          <Text color="gray.900" paddingBottom="6px" fontSize="12px" lineHeight="17px" textAlign="center">
            {t('walletTransaction.newSaldo')}
          </Text>
          <Box bgColor="secondary.alpha.15" padding="10px">
            <Text color="gray.800" fontWeight="600" fontSize="15px" lineHeight="25px">
              {typeof walletTransaction.new_saldo === 'string'
                ? formatInterval(walletTransaction.new_saldo, false, true)
                : formatMoney(walletTransaction.new_saldo)}
            </Text>
          </Box>
        </Box>
      </HStack>
    </Box>
  )
}
