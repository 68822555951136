import React from 'react'
import { TouchableOpacity, TouchableOpacityProps } from 'react-native'
import { Box, Center, Factory, Heading, StyledProps, Text, useTheme } from 'native-base'
import { CharlyIcon } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { useRouter } from '@charlycares/shared'
import { PlatformProps } from 'native-base/lib/typescript/components/types'

type Props = React.ForwardRefExoticComponent<TouchableOpacityProps & StyledProps & PlatformProps<StyledProps>>

const Touchable = Factory(TouchableOpacity)

const UpdateCalendarWidget = ({ ...boxProps }: Props) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { navigate } = useRouter()

  return (
    <Touchable
      p="10px"
      borderRadius="4px"
      bg="gray.100"
      flex={1}
      onPress={() => navigate('CalendarScreen')}
      {...boxProps}
    >
      <Box flexDir="row" alignItems="center">
        <Heading flex={1} fontWeight={600} fontSize="15px">
          {t('calendar')}
        </Heading>
        <CharlyIcon size={30} color={colors.gray[500]} name="icn-caret-right" />
      </Box>

      <Center flex={1}>
        <CharlyIcon size={75} color={colors.gray[500]} name="vacation" />
      </Center>

      <Text fontSize="14px" textAlign="center">
        {t('blockCalendarNote')}
      </Text>
    </Touchable>
  )
}

export default UpdateCalendarWidget
