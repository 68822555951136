import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { Text, Icon, ContainerView } from '../../../elements';
import { TextView } from '../../Texts';
import theme from '../../../styles/theme';

import checkIcon from '../../../../assets/icons/icn-check.png';

const View = styled.View`
  ${({ flex }) => flex};
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  ${({ margins }) => margins && { margin: margins }};
  ${({ paddings }) => paddings && { padding: paddings }};
  ${({ size }) => size && { width: size, height: size }};
  ${({ width }) => width};
  opacity: ${({ opacity }) => opacity || 1};
  ${({ borderBottom }) =>
    borderBottom && { 'border-bottom-width': '1px', 'border-color': theme.dividerLineColor }};
  ${({ backgroundColor }) => backgroundColor && { 'background-color': backgroundColor }};
`;
const Days = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const Availability = ({ margins, paddings, borderBottom, availability }) => {
  const [t] = useTranslation();

  return (
    <View
      flexDirection="row"
      justifyContent="flex-start"
      margins={margins}
      paddings={paddings}
      width="100%"
      borderBottom={borderBottom}>
      <ContainerView
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="space-around"
        paddings="28px 12px 0px 0px"
        height="100%">
        <Text height="24px">{t('morning')}</Text>
        <Text height="24px">{t('afternoon')}</Text>
        <Text height="24px">{t('evening')}</Text>
      </ContainerView>
      <Days>
        {availability.map(({ id, morning, afternoon, evening }, index) => (
          <View key={String(id)}>
            <TextView
              alignItems="center"
              justifyContent="center"
              paddings="2px 3px"
              margins="2px"
              width="24px"
              height="24px"
              opacity={morning || afternoon || evening ? 1 : 0.3}
              backgroundColor={theme.colors.grey06}
              text={t(`calendarScreensMonth${index}`)}
              fontSize={theme.fontSize.md}
            />
            <View
              margins="2px"
              size="24px"
              opacity={morning ? 1 : 0.3}
              backgroundColor={theme.colors.grey06}>
              {!!morning && (
                <Icon source={checkIcon} color={theme.colors.primaryText} size="16px" />
              )}
            </View>
            <View
              margins="2px"
              size="24px"
              opacity={afternoon ? 1 : 0.3}
              backgroundColor={theme.colors.grey06}>
              {!!afternoon && (
                <Icon source={checkIcon} color={theme.colors.primaryText} size="16px" />
              )}
            </View>
            <View
              margins="2px"
              size="24px"
              opacity={evening ? 1 : 0.3}
              backgroundColor={theme.colors.grey06}>
              {!!evening && (
                <Icon source={checkIcon} color={theme.colors.primaryText} size="16px" />
              )}
            </View>
          </View>
        ))}
      </Days>
    </View>
  );
};

Availability.propTypes = {
  margins: PropTypes.string,
  paddings: PropTypes.string,
  borderBottom: PropTypes.bool,
  availability: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

Availability.defaultProps = {
  margins: '0',
  paddings: null,
  borderBottom: false
};

export default Availability;
