import React from 'react';
import { Time } from 'react-native-gifted-chat';
import styled from 'styled-components/native';
import colors from '../../styles/colors';

const View = styled.View`
  flex: 1;
`;
const StyledTime = styled(Time).attrs({
  containerStyle: {
    backgroundColor: colors.green,
    alignItems: 'flex-end'
  },
  timeTextStyle: {
    color: colors.green
  }
})``;

const CustomTime = (props) => (
  <View>
    <StyledTime {...props} />
  </View>
);

export default CustomTime;
