import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { ContainerView, Heading, Text, Circle, Icon } from '../../elements';
import { TextView, TextIconView } from '../Texts';
import { AngelAvatar } from '../Profile';
import { getFormatedDate } from '../../utils/functions';
import theme from '../../styles/theme';

import warningIcon from '../../../assets/icons/icn-small-error.png';

const TouchableOpacity = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: ${theme.spacer.lg};
  width: 100%;
  min-height: 90px;
  border-bottom-width: 1px;
  border-color: ${theme.dividerLineColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const View = styled.View`
  flex: 1 1 auto;
  justify-content: center;
  height: 100%;
`;

const DateView = styled.View`
  flex: 1 0 auto;
  justify-content: center;
  height: 100%;
`;

const TextViewTextView = styled.View`
  flex-direction: row;
  align-items: center;
`;

const AvatarContainer = styled.View`
  flex-direction: row;
  align-items: center;
  margin-right: ${theme.spacer.sm};
`;

const getBorderColor = (state, invitationsDenied) => {
  if (invitationsDenied && state === 'pending') {
    return theme.colors.error;
  }
  switch (state) {
    case 'pending':
      return theme.statusPendingColor;
    case 'pending_edit':
      return theme.statusPendingColor;
    case 'declined':
      return theme.statusDeclinedColor;
    case 'accepted':
      return theme.statusAcceptedColor;
    case 'canceled':
      return theme.statusCanceledColor;
    default:
      return theme.statusErrorColor;
  }
};

const getBackgroundColor = (state, hasOffer, invitationsDenied) => {
  if (hasOffer) {
    return theme.colors.white;
  }

  if (invitationsDenied) {
    return theme.colors.error;
  }
  switch (state) {
    case 'pending':
      return theme.colors.white;
    case 'pending_edit':
      return theme.colors.white;
    case 'declined':
      return theme.colors.error;
    case 'accepted':
      return theme.colors.green;
    case 'canceled':
      return theme.colors.error;
    default:
      return theme.colors.error;
  }
};

const getDescription = (t, state, invitationsDenied) => {
  if (invitationsDenied && state === 'pending') {
    return t('bookingsScreenDeniedAddAngels');
  }
  switch (state) {
    case 'declined':
      return t('bookingsScreenDenied');
    case 'accepted':
      return t('bookingsScreenAcceptedBookings');
    case 'ended':
      return t('bookingsScreenEndedBookings');
    case 'canceled':
      return t('bookingsScreenCanceledBookings');
    case 'pending':
      return t('bookingsScreenPendingBookings');
    case 'pending_edit':
      return t('bookingsScreenPendingBookings');
    case 'pending_payment':
      return t('bookingsScreenPendingPaymentsBookings');
    default:
      return state;
  }
};
const BookingsCard = ({
  startDate,
  endDate,
  repeatQty,
  hasOffer,
  currentState,
  onCardPress,
  profileImage,
  invitationsDenied
}) => {
  const [t] = useTranslation();

  return (
    <TouchableOpacity
      backgroundColor={!hasOffer ? 'transparent' : theme.colors.yellowAlpha20}
      onPress={onCardPress}>
      {!!profileImage && (
        <AvatarContainer>
          <AngelAvatar source={profileImage} />
        </AvatarContainer>
      )}
      <View>
        <TextViewTextView>
          {repeatQty > 1 && (
            <TextView
              justifyContent="center"
              alignItems="center"
              margins="0px 4px 0px 0px"
              height="16px"
              width="24px"
              borderRadius="8px"
              backgroundColor={theme.colors.grey05}
              text={`${repeatQty}x`}
              color={theme.colors.primaryText}
              fontSize="12px"
            />
          )}
          <Heading ariaLevel={4} text={getFormatedDate(startDate, 'dddd')} />
        </TextViewTextView>
        <Text sm lightColor>
          {getFormatedDate(startDate, 'LL')}
        </Text>
      </View>
      <DateView>
        <Text margins="0 0 4px 0" textAlign="right">
          {`${getFormatedDate(startDate, 'HH:mm')} - ${getFormatedDate(endDate, 'HH:mm')}`}
        </Text>
        {!hasOffer ? (
          <ContainerView justifyContent="flex-end" flexDirection="row" alignItems="center">
            <Text xs lightColor textAlign="right">
              {getDescription(t, currentState, invitationsDenied)}
            </Text>
            {['declined', 'pending', 'pending_edit'].includes(currentState) && (
              <Circle
                margins="0 0 0 4px"
                borderColor={getBorderColor(currentState, invitationsDenied)}
                backgroundColor={getBackgroundColor(currentState, invitationsDenied)}
              />
            )}
            {currentState === 'pending_payment' && (
              <Icon
                margins="0 0 0 4px"
                source={warningIcon}
                size="16px"
                color={theme.colors.danger}
              />
            )}
          </ContainerView>
        ) : (
          <TextIconView
            margins="5px 0px 0px 0px"
            paddings="0px 5px 0px 10px"
            alignSelf="flex-end"
            borderRadius="10px"
            fontWeight="bold"
            backgroundColor={theme.colors.green}
            text={t('bookingsScreenViewOfferNow')}
            fontSize="13px"
            textColor={theme.colors.white}
            name="time"
          />
        )}
      </DateView>
    </TouchableOpacity>
  );
};

BookingsCard.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  repeatQty: PropTypes.number,
  hasOffer: PropTypes.bool,
  currentState: PropTypes.string.isRequired,
  onCardPress: PropTypes.func.isRequired,
  profileImage: PropTypes.string,
  invitationsDenied: PropTypes.bool
};

BookingsCard.defaultProps = {
  repeatQty: 0,
  hasOffer: false,
  invitationsDenied: false
};

BookingsCard.defaultProps = {
  profileImage: null
};
export default BookingsCard;
