import axiosInstance from '../../utils/axios'
import {
  FETCHING_BOOK,
  SEARCH_AVAILABLE_ANGELS,
  SEARCH_AVAILABLE_ANGELS_ERROR,
  GET_BOOK_ANGEL_RATINGS,
  TOGGLE_LIKE_BOOK_ANGEL,
  SEND_BOOK_REQUEST,
  SEND_BOOK_REQUEST_ERROR,
  ADD_ANGELS,
  SERVER_ERROR,
  SET_BOOKING_DETAILS
} from '../types'

const logError = (error, dispatch) => {
  if (error.response && error.response.status === 500) {
    dispatch({ type: SERVER_ERROR })
  } else {
    console.log({ error })
  }
}

export const searchAvailableAngels = dates => dispatch => {
  dispatch({ type: FETCHING_BOOK })
  axiosInstance
    .post('match', { dates })
    .then(response => {
      if (response.data) {
        dispatch({
          type: SEARCH_AVAILABLE_ANGELS,
          payload: response.data.data
        })
      }
    })
    .catch(error => {
      if (error.response && error.response.data) {
        dispatch({
          type: SEARCH_AVAILABLE_ANGELS_ERROR,
          payload: error.response.data.message
        })
      } else {
        logError(error, dispatch)
      }
    })
}

export const toggleLikeBookAngel = (angelId, like) => dispatch => {
  axiosInstance
    .post('angel/like', { angel_id: angelId, like })
    .then(response => {
      if (response.data) {
        dispatch({
          type: TOGGLE_LIKE_BOOK_ANGEL,
          payload: { angelId, like }
        })
      }
    })
    .catch(error => logError(error, dispatch))
}

export const getAngelRatingsBook = angelId => dispatch => {
  axiosInstance
    .get(`angel/ratings/${angelId}`)
    .then(response => {
      if (response.data) {
        dispatch({
          type: GET_BOOK_ANGEL_RATINGS,
          payload: response.data.data
        })
      }
    })
    .catch(error => logError(error, dispatch))
}

export const sendBookRequest =
  (ids, approvalRequired, dates, message, kids = [], showJobFinder, maximumRate, singleBooking, role = 'angel') =>
  dispatch => {
    const dataToSend = {
      dates,
      message,
      single_booking: singleBooking,
      show_jobfinder: showJobFinder,
      kids,
      max_rate: maximumRate
    }

    if (role === 'angel') {
      //   dataToSend.angel_ids = ids.map(obj => obj.id)
      dataToSend.requested_angels = ids
      dataToSend.approvalRequired = approvalRequired
    } else {
      const [obj] = ids
      dataToSend.family_id = obj.id
    }
    dispatch({ type: FETCHING_BOOK })
    axiosInstance
      .post('bookings', dataToSend)
      .then(response => {
        if (response.data) {
          dispatch({
            type: SEND_BOOK_REQUEST,
            payload: response.data.data
          })
        }
      })
      .catch(error => {
        if (error.response && error.response.data) {
          dispatch({
            type: SEND_BOOK_REQUEST_ERROR,
            payload: error.response.data.message
          })
        } else {
          logError(error, dispatch)
        }
      })
  }

export const addAngels = bookingId => dispatch => {
  axiosInstance
    .post('add_angels', { booking_id: bookingId })
    .then(response => {
      if (response.data) {
        dispatch({
          type: ADD_ANGELS,
          payload: response.data.data
        })
      }
    })
    .catch(error => logError(error, dispatch))
}

export const storeAddAngels = (selectedAngels, bookingId, requested_angels) => dispatch => {
  axiosInstance
    .post('bookings/add_angels', {
      booking_id: bookingId,
      angel_ids: selectedAngels,
      requested_angels
    })
    .then(response => {
      if (response.data) {
        dispatch({
          type: SET_BOOKING_DETAILS,
          payload: response.data.data
        })
      }
    })
    .catch(error => logError(error, dispatch))
}
