import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getIndicators, setLocale } from '../../../stores/actions/userAction'
import { getRatings } from '../../../stores/actions/ratingsAction'
import { Text, Icon, Divider } from '../../../elements'
import { TextView, TextButton, Header, ProfileHeader } from '../../../components'
import {
  roundMonetaryValue,
  getMembershipPlanName,
  getNumberOfProfileMissingFields,
  changeMomentLocale
} from '../../../utils/functions'
import colors from '../../../styles/colors'

import arrowRight from '../../../../assets/icons/icn-nav-right.png'
import { Linking } from 'react-native'

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
`
const ScrollView = styled.ScrollView`
  flex: 1;
`

const Profile = ({ history, navigation, user, unratedCount, setLocaleData, getRatingsData, getIndicatorsData }) => {
  const [t, i18n] = useTranslation()
  const { image, first_name, last_name, role, locale, family, languages, credit, membership, has_company_wallet } = user
  const membershipNavigate = navigation ? 'ProfileMembership' : '/profile-membership'
  const membershipDetailsNavigate = navigation ? 'ProfileMembershipDetails' : '/profile-membership-details'

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('profile')}
        </Text>
      ),
      headerRight: (
        <TextButton
          text={t('profileScreensNeedHelp')}
          textColor={colors.secondary}
          fontWeight="normal"
          onPress={() => Linking.openURL('https://support.charlycares.com')}
        />
      )
    })
  }

  useEffect(() => {
    getRatingsData()
    let focusListener = () => {}
    if (navigation) {
      focusListener = navigation.addListener('didFocus', () => {
        getIndicatorsData()
      })
    }
    return focusListener
  }, [])

  const getNumberOfSettingsMissingFields = () => {
    const { kids } = family
    const { dutch, english, french, german, italian, spanish } = languages
    const allLanguages = [dutch, english, french, german, italian, spanish]
    const fields = [kids.length, allLanguages.filter(language => language).length]
    return fields.filter(field => !field).length
  }

  const getItemsToRender = () => {
    let membershipDescription
    let current_state = ''

    if (membership) {
      current_state = membership.current_state
      membershipDescription = getMembershipPlanName(t, current_state)
    }

    const items = [
      {
        title: t('profileScreensProfileAccount'),
        description: t('profileScreensIncomplete'),
        numberOfMissingFields: getNumberOfProfileMissingFields(user),
        navigateTo: navigation ? 'ProfileAccount' : '/profile-account',
        missingFieldsNavigateTo: navigation ? 'ProfileEdit' : '/profile-edit',
        navigateParams: { shouldGoBack: false }
      },
      {
        title: t('profileScreensFamilySettings'),
        description: t('profileScreensIncomplete'),
        numberOfMissingFields: getNumberOfSettingsMissingFields(),
        navigateTo: navigation ? 'ProfileFamilySettings' : '/profile-settings',
        missingFieldsNavigateTo: navigation ? 'ProfileFamilySettings' : '/profile-settings-edit'
      },
      {
        title: t('profileScreensRatingAndReviews'),
        description: t('profileScreensUnwritenReviews'),
        numberOfMissingFields: unratedCount,
        navigateTo: '/profile-reviews',
        missingFieldsNavigateTo: '/profile-reviews'
      },
      {
        title: t('profileScreensPromotions'),
        description: `€ ${roundMonetaryValue(credit)}`,
        navigateTo: navigation ? 'Promotions' : '/profile-promotions',
        fontSizeDescription: '17px'
      },
      {
        title: t('profileScreensInviteYourOwnAngel'),
        fontSizeDescription: '17px',
        navigateTo: '/profile-invite-your-own-angel',
        navigateParams: { shouldGoBack: true }
      },
      {
        title: t('paymentsScreenPayments'),
        navigateTo: navigation ? 'PaymentListScreen' : '/profile-payments',
        fontSizeDescription: '17px'
      },
      {
        title: t('profileScreensMembership'),
        description: membershipDescription,
        fontSizeDescription: '17px',
        navigateTo: membership
          ? current_state === 'canceled'
            ? membershipDetailsNavigate
            : membershipNavigate
          : '/become-member'
      },
      {
        title: t('profileScreensBusinessAccount'),
        description: t(has_company_wallet ? 'profileBusinessAccountActive' : 'profileBusinessAccountNotActive'),
        fontSizeDescription: '17px',
        navigateTo: has_company_wallet ? '/profile-business-account-connected' : '/profile-business-account',
        navigateParams: { shouldGoBack: true }
      },
      {
        title: t('profileScreensTermsAndConditions'),
        navigateTo: navigation ? 'GeneralConditions' : '/profile-general-conditions'
      }
    ]

    return items
  }

  const items = getItemsToRender()

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          title={t('profile')}
          right={
            <TextButton
              text={t('profileScreensNeedHelp')}
              textColor={colors.secondary}
              fontWeight="normal"
              onPress={() => history.push('/support')}
            />
          }
        />
      )}

      <ScrollView>
        <Divider />

        <ProfileHeader
          image={image}
          firstName={first_name}
          lastName={last_name}
          isAngel={role === 'angel'}
          showPlaceholder={!image}
          onLanguagePress={language => {
            setLocaleData(language)
            i18n.changeLanguage(language)
            changeMomentLocale(language === 'nl_NL' ? 'nl' : 'en')
          }}
          selectedLanguage={locale}
        />

        {!image && (
          <TextView
            margins="0px 20px 20px 20px"
            paddings="10px"
            borderLeftWidth="3px"
            borderColor={colors.secondary}
            backgroundColor={colors.grey08}
            text={t('profileScreensFamilyAddPhotoAndDescription')}
            fontWeight="300"
          />
        )}

        {items.map(
          ({
            title,
            description,
            numberOfMissingFields,
            navigateParams,
            navigateTo,
            missingFieldsNavigateTo,
            fontSizeDescription
          }) => (
            <TextButton
              key={title}
              flexDirection="row"
              fontSize="17px"
              justifyContent="space-between"
              paddings="15px 0px 15px 0px"
              margins="0px 0px 0px 20px"
              height="auto"
              fontWeight={100}
              borderWidth={0}
              borderBottomWidth="1px"
              borderTopWidth={0}
              borderRadius="0px"
              borderColor={colors.grey08}
              text={title}
              textColor={colors.primaryText}
              onPress={() => {
                history.push(numberOfMissingFields > 0 ? missingFieldsNavigateTo : navigateTo, navigateParams)
              }}
            >
              <TextView
                text={numberOfMissingFields === 0 || !description ? '' : description}
                fontSize={fontSizeDescription || '14px'}
                textColor={numberOfMissingFields > 0 ? colors.notify : colors.grey03}
              >
                {numberOfMissingFields > 0 && (
                  <TextView
                    justifyContent="center"
                    margins="0px 0px 0px 5px"
                    width="18px"
                    height="18px"
                    borderRadius="18px"
                    backgroundColor={colors.notify}
                    text={numberOfMissingFields.toString()}
                    fontSize="11px"
                    fontWeight="600"
                    textColor={colors.white}
                  />
                )}

                <Icon source={arrowRight} size="32px" color={colors.defaultBtnBackgroundColor} />
              </TextView>
            </TextButton>
          )
        )}
      </ScrollView>
    </SafeAreaView>
  )
}

Profile.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    addListener: PropTypes.func,
    setOptions: PropTypes.func
  }),
  user: PropTypes.shape({
    image: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    role: PropTypes.string,
    locale: PropTypes.string,
    family: PropTypes.shape({
      kids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]))
    }),
    languages: PropTypes.shape({
      dutch: PropTypes.bool,
      english: PropTypes.bool,
      french: PropTypes.bool,
      german: PropTypes.bool,
      italian: PropTypes.bool,
      spanish: PropTypes.bool
    }),
    credit: PropTypes.string,
    membership: PropTypes.shape({ current_state: PropTypes.string })
  }).isRequired,
  unratedCount: PropTypes.number,
  getRatingsData: PropTypes.func.isRequired,
  getIndicatorsData: PropTypes.func.isRequired,
  setLocaleData: PropTypes.func.isRequired
}

Profile.defaultProps = {
  history: null,
  navigation: null,
  unratedCount: 0
}

const mapStateToProps = ({ User, Ratings }) => ({
  user: User.user,
  unratedCount: Ratings.unrated_count
})

const mapDispatchToProps = {
  getRatingsData: getRatings,
  getIndicatorsData: getIndicators,
  setLocaleData: setLocale
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
