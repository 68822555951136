import React from 'react'
import { Card, CharlyIcon, Map } from '@charlycares/ui'
import { Box, Flex, Heading, IBoxProps, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import { getDistance } from '@charlycares/shared'

type Props = IBoxProps & {
  street_name: string
  street_number?: string
  city: string
  distance: number
  lat: number
  lon: number
}

const AddressDetails = ({ street_name,street_number, city, distance, lat, lon, ...boxProps }: Props) => {
  const { t } = useTranslation()

  return (
    <Card {...boxProps}>
      <Heading textTransform="capitalize" fontSize="22px" fontWeight={600} flex={1}>
        {t('address')}
      </Heading>

      <Box mt="10px" flexDir="row" alignItems="center">
        <CharlyIcon color="gray.900" name="icn-location" size={35} />

        <Box ml="15px" flex={1}>
          <Text fontSize="14px" fontWeight={600}>
            {street_name} {street_number}
          </Text>
          <Flex direction="row" justify="space-between" align="center">
            <Text fontSize="12px">{city}</Text>
            <Text fontSize="12px">{getDistance(distance)}</Text>
          </Flex>
        </Box>
      </Box>

      {!!lat && !!lon && <Map lat={lat} lon={lon} />}
    </Card>
  )
}

export default AddressDetails
