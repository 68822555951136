import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import colors from '../styles/colors';

const StyledInput = styled.TextInput`
  ${({ flex }) => flex && { flex }};
  margin: ${({ margins }) => margins};
  padding: ${({ paddings }) => paddings};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  ${({ minHeight }) => minHeight && { 'min-height': minHeight }};
  ${({ maxHeight }) => maxHeight && { 'max-height': maxHeight }};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  font-family: 'Open Sans';
  font-size: ${({ inputFontSize }) => inputFontSize};
  font-style: ${({ inputFontStyle }) => inputFontStyle};
  color: ${({ inputColor }) => inputColor};
  border-color: ${({ borderColor }) => borderColor};
  border-bottom-width: ${({ showUnderline }) => (showUnderline ? '1px' : 0)};
`;

const Input = ({
  inputRef,
  inputFlex,
  inputWidth,
  inputHeight,
  value,
  onChangeText,
  showUnderline,
  inputBorderColor,
  inputBackgroundColor,
  maxInputHeight,
  minInputHeight,
  inputMargins,
  inputPaddings,
  inputFontSize,
  inputFontStyle,
  inputColor,
  blurOnSubmit,
  returnKeyType,
  placeholderTextColor,
  ...inputProps
}) => (
  <StyledInput
    ref={inputRef}
    flex={inputFlex}
    width={inputWidth}
    height={inputHeight}
    margins={inputMargins}
    paddings={inputPaddings}
    maxHeight={maxInputHeight}
    minHeight={minInputHeight}
    showUnderline={showUnderline}
    borderColor={inputBorderColor}
    inputFontStyle={inputFontStyle}
    inputFontSize={inputFontSize}
    inputColor={inputColor}
    placeholderTextColor={placeholderTextColor}
    backgroundColor={inputBackgroundColor}
    value={value}
    onChangeText={onChangeText}
    autoCapitalize="none"
    autoCorrect={false}
    blurOnSubmit={blurOnSubmit}
    returnKeyType={returnKeyType}
    {...inputProps}
  />
);

Input.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  inputFlex: PropTypes.number,
  inputWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputBorderColor: PropTypes.string,
  inputBackgroundColor: PropTypes.string,
  inputFontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputFontStyle: PropTypes.string,
  inputColor: PropTypes.string,
  placeholderTextColor: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChangeText: PropTypes.func.isRequired,
  showUnderline: PropTypes.bool,
  maxInputHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minInputHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputMargins: PropTypes.string,
  inputPaddings: PropTypes.string,
  blurOnSubmit: PropTypes.bool,
  returnKeyType: PropTypes.string
};

Input.defaultProps = {
  inputRef: null,
  inputFlex: null,
  inputWidth: '100%',
  inputHeight: 'auto',
  showUnderline: true,
  inputBorderColor: colors.grey08,
  inputBackgroundColor: null,
  inputFontSize: '16px',
  inputFontStyle: 'normal',
  inputColor: colors.primaryText,
  placeholderTextColor: colors.grey04,
  maxInputHeight: null,
  minInputHeight: '44px',
  inputMargins: '0px',
  inputPaddings: '0px',
  blurOnSubmit: false,
  returnKeyType: 'next'
};

export default Input;
