import React from 'react'
import { isMobile } from 'react-device-detect'
import { Linking, ActivityIndicator } from 'react-native'
import styled from 'styled-components/native'

import { Text, colors } from '@charlycares/legacy/web'
import { LogoNameAndIconImage } from '@charlycares/assets'
import { Box, Image } from 'native-base'

const Center = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`
export default function OpenUrl({ match, role, history }) {
  const [loading, setLoading] = React.useState(true)

  const checkAndRedirect = async () => {
    setLoading(true)

    const webUrl = decodeURIComponent(match.params.fallback)
    const mobileUrl = `charlyapp://${match.params.url}`

    if (isMobile) {
      try {
        await Linking.openURL(match.params.url)
        window.location = mobileUrl
      } catch (error) {
        if (role === 'angel') {
          window.location = 'https://www.charlycares.com/download-app'
        } else {
          history.push(`/${decodeURIComponent(match.params.fallback)}`)
          history.push(`/${webUrl}`)
        }
      }
    } else {
      history.push(`/${decodeURIComponent(match.params.fallback)}`)
      history.push(`/${webUrl}`)
    }

    setLoading(false)
  }

  React.useEffect(() => {
    checkAndRedirect()
  }, [])
  return (
    <Center>
      {loading ? (
        <ActivityIndicator size="large" color={colors.primary} />
      ) : isMobile ? (
        <Center>
          <Box width="100%" flexDir="row" alignItems="center">
            <Image source={LogoNameAndIconImage} alt="Charly Cares" width="200px" height="200px" resizeMode="contain" />
          </Box>
        </Center>
      ) : (
        <Text bold fontSize={40}>
          404 Not Found
        </Text>
      )}
    </Center>
  )
}
