import * as React from 'react'
import Svg, { SvgProps, Rect, Path } from 'react-native-svg'

const VaryingCalendarIcon = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 52 56" fill="none" {...props}>
    <Rect
      x={0.812}
      y={5.681}
      width={50.319}
      height={49.507}
      rx={5.681}
      fill="#fff"
      stroke="#0B5394"
      strokeWidth={1.623}
    />
    <Path
      d="M6.493 5.681h38.956a5.681 5.681 0 015.681 5.681v2.435H.812v-2.435a5.681 5.681 0 015.68-5.68z"
      fill="#0B5394"
      stroke="#0B5394"
      strokeWidth={1.623}
    />
    <Rect
      x={10.551}
      y={0.812}
      width={5.681}
      height={10.551}
      rx={1.623}
      fill="#fff"
      stroke="#0B5394"
      strokeWidth={1.623}
    />
    <Rect
      x={35.71}
      y={0.812}
      width={5.681}
      height={10.551}
      rx={1.623}
      fill="#fff"
      stroke="#0B5394"
      strokeWidth={1.623}
    />
    <Rect x={6.493} y={18.667} width={6.493} height={6.493} rx={0.812} fill="#F2F2F2" />
    <Rect x={6.493} y={26.783} width={6.493} height={6.493} rx={0.812} fill="#F2F2F2" />
    <Rect
      x={6.898}
      y={35.304}
      width={5.681}
      height={5.681}
      rx={0.406}
      fill="#062D51"
      stroke="#F2F2F2"
      strokeWidth={0.812}
    />
    <Rect x={6.493} y={43.015} width={6.493} height={6.493} rx={0.812} fill="#F2F2F2" />
    <Rect x={14.609} y={18.667} width={6.493} height={6.493} rx={0.812} fill="#F2F2F2" />
    <Rect x={14.609} y={26.783} width={6.493} height={6.493} rx={0.812} fill="#F2F2F2" />
    <Rect x={14.609} y={34.899} width={6.493} height={6.493} rx={0.812} fill="#F2F2F2" />
    <Rect
      x={15.014}
      y={43.42}
      width={5.681}
      height={5.681}
      rx={0.406}
      fill="#062D51"
      stroke="#F2F2F2"
      strokeWidth={0.812}
    />
    <Rect
      x={23.13}
      y={19.073}
      width={5.681}
      height={5.681}
      rx={0.406}
      fill="#062D51"
      stroke="#F2F2F2"
      strokeWidth={0.812}
    />
    <Rect x={22.725} y={26.783} width={6.493} height={6.493} rx={0.812} fill="#F2F2F2" />
    <Rect x={22.725} y={34.899} width={6.493} height={6.493} rx={0.812} fill="#F2F2F2" />
    <Rect x={22.725} y={43.015} width={6.493} height={6.493} rx={0.812} fill="#F2F2F2" />
    <Rect x={30.841} y={18.667} width={6.493} height={6.493} rx={0.812} fill="#F2F2F2" />
    <Rect x={30.841} y={26.783} width={6.493} height={6.493} rx={0.812} fill="#F2F2F2" />
    <Rect x={30.841} y={34.899} width={6.493} height={6.493} rx={0.812} fill="#F2F2F2" />
    <Rect
      x={31.246}
      y={43.42}
      width={5.681}
      height={5.681}
      rx={0.406}
      fill="#062D51"
      stroke="#F2F2F2"
      strokeWidth={0.812}
    />
    <Rect x={38.956} y={18.667} width={6.493} height={6.493} rx={0.812} fill="#F2F2F2" />
    <Rect
      x={39.362}
      y={27.189}
      width={5.681}
      height={5.681}
      rx={0.406}
      fill="#062D51"
      stroke="#F2F2F2"
      strokeWidth={0.812}
    />
    <Rect x={38.956} y={34.899} width={6.493} height={6.493} rx={0.812} fill="#F2F2F2" />
    <Rect x={38.956} y={43.015} width={6.493} height={6.493} rx={0.812} fill="#F2F2F2" />
  </Svg>
)

export default VaryingCalendarIcon
