import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { Text } from '../../../elements';
import { TextView, TextIconView } from '../../Texts';
import { IconButton } from '../../Buttons';
import { roundMonetaryValue, getRateDescription } from '../../../utils/functions';
import colors from '../../../styles/colors';

const View = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const HourlyRateEdit = ({
  disabled,
  competitiveRate,
  minimumRate,
  maximumRate,
  desc,
  icon,
  rate,
  onDecreasePress,
  onIncreasePress
}) => {
  const [t] = useTranslation();

  return (
    <View>
      <TextView
        flexDirection="column-reverse"
        alignItems="flex-start"
        text={`${t('profileScreensCompetitiveRate')} € ${roundMonetaryValue(competitiveRate)}`}
        fontSize="12px"
        textColor={colors.grey04}>
        <TextIconView
          flexDirection="row-reverse"
          margins="10px 0px 0px 0px"
          backgroundColor="transparent"
          icon={icon}
          iconMargins="0px 10px 0px 0px"
          iconSize="32px"
          iconColor={colors.primaryText}
          text={desc}
        />
      </TextView>

      <TextView
        flexDirection="column-reverse"
        alignItems="center"
        paddings="0px 10px 0px 10px"
        width="50%"
        text={getRateDescription(t, rate, competitiveRate)}
        fontSize="12px"
        textColor={colors.grey04}>
        <View>
          <IconButton
            disabled={disabled || rate <= minimumRate}
            iconName="btn-minus"
            iconSize="32px"
            iconColor={disabled || rate <= minimumRate ? colors.primaryAlpha80 : colors.primary}
            onPress={onDecreasePress}
          />

          <Text fontSize="18px">{`€ ${roundMonetaryValue(rate)}`}</Text>

          <IconButton
            disabled={disabled || rate >= maximumRate}
            iconName="btn-plus"
            iconSize="32px"
            iconColor={disabled || rate >= maximumRate ? colors.primaryAlpha80 : colors.primary}
            onPress={onIncreasePress}
          />
        </View>
      </TextView>
    </View>
  );
};

HourlyRateEdit.propTypes = {
  disabled: PropTypes.bool.isRequired,
  minimumRate: PropTypes.number,
  maximumRate: PropTypes.number,
  competitiveRate: PropTypes.number,
  desc: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  rate: PropTypes.number.isRequired,
  onDecreasePress: PropTypes.func,
  onIncreasePress: PropTypes.func
};

HourlyRateEdit.defaultProps = {
  minimumRate: 0,
  maximumRate: 10,
  competitiveRate: null,
  onDecreasePress: () => {},
  onIncreasePress: () => {}
};

export default HourlyRateEdit;
