import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter, isWeb, BookingCancelledImage, makeCall, BookingStates } from '@charlycares/shared'
import { ScreenLayout } from '@charlycares/ui'
import { Heading, Box, Button, Text, Image, HStack, Spacer } from 'native-base'
import { useGetBookingDetailsQuery } from '@charlycares/data-access'

export default function BookingCancelledScreen() {
  const { getParam, navigate } = useRouter()
  const { t } = useTranslation()

  const bookingId = getParam('bookingId') as string
  const bookingStatus = getParam('bookingStatus', BookingStates.CANCELED) as BookingStates

  const { isLoading, data: booking } = useGetBookingDetailsQuery({ obscured_id: bookingId }, { skip: !bookingId })

  return (
    <ScreenLayout
      isLoading={isLoading}
      progress={`${100}%`}
      hideBackButton
      title={t('bookingCancelled')}
      subtitle={bookingId}
      edges={['right', 'left']}
      headerRight={
        <Button onPress={() => navigate('BookingsScreen', `/bookings`)} variant="text">
          {t('done')}
        </Button>
      }
    >
      <Box w="100%" bg="white" p="20px" borderColor="gray.200" borderWidth="1px">
        {booking && bookingStatus === BookingStates.PENDING ? (
          <>
            <Heading color="gray.900" fontSize="18px" mt="10px" mb="15px">
              {t('bookingsIsCancelledAndRecreatedTitle')}
            </Heading>
            <Text color="gray.800" fontSize="15px">
              {t('bookingsIsCancelledAndRecreatedSubTitle')}
            </Text>

            <Box rounded="md" bgColor="secondary.alpha.15" p="20px" mt="30px">
              <Text color="gray.800" fontSize="15px">
                {t('bookingsIsCancelledAndRecreatedDescription')}
              </Text>
              <Button
                onPress={() => navigate('FamilyEditBooking', `/booking-edit`, { bookingId: booking?.obscured_id })}
                mt="30px"
                color="primary"
                fontSize="15px"
              >
                {t('viewRecreatedBooking')}
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Heading color="gray.900" fontSize="18px" mt="10px" mb="15px">
              {t('bookingsIsCancelledTitle')}
            </Heading>
            <Text>
              <Text color="gray.800" fontSize="15px">
                {t('bookingsIsCancelledDescription')}
              </Text>
              <Text color="gray.800" fontSize="15px" onPress={() => makeCall('+310202102323')}>
                {' +31 (0) 20 210 23 23'}
              </Text>
            </Text>
          </>
        )}
        <HStack mt="30px">
          <Text color="gray.800" fontSize="15px">
            Happy to help!
          </Text>
          <Spacer />
          <Image
            source={BookingCancelledImage as any}
            alt="Booking is cancelled"
            maxHeight="200px"
            height={isWeb ? '200px' : undefined}
            position="static"
            resizeMode="contain"
          />
        </HStack>
      </Box>
    </ScreenLayout>
  )
}

BookingCancelledScreen.navigationOptions = () => ({
  headerShown: false
})
