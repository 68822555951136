import React from 'react';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextIconView } from '../../Texts';
import { IconButton } from '../../Buttons';
import AngelAvatar from '../AngelAvatar';
import colors from '../../../styles/colors';

import favoriteIcon from '../../../../assets/icons/icn-favorite.png';
import favoriteInactiveIcon from '../../../../assets/icons/icn-favorite-inactive.png';
import playIcon from '../../../../assets/icons/icn-play.png';
import repeatIcon from '../../../../assets/icons/icn-repeat.png';
import standbyIcon from '../../../../assets/icons/icn-standby.png';

const View = styled.View`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  margin: ${({ margins }) => margins};
`;

const AngelProfileImage = ({
  margins,
  size,
  isLiked,
  image,
  video,
  standBy,
  fixed,
  onToggleLikePress,
  onVideoPress
}) => {
  const [t] = useTranslation();

  return (
    <View margins={margins} size={size}>
      <AngelAvatar size={size} source={image} />

      <IconButton
        height="auto"
        paddings="5px"
        position="absolute"
        top={-10}
        left={-20}
        borderWidth={0}
        borderTopWidth={0}
        borderBottomWidth={0}
        iconSource={isLiked ? favoriteIcon : favoriteInactiveIcon}
        iconColor={isLiked ? colors.secondary : colors.grey07}
        iconWidth="42px"
        iconHeight="42px"
        onPress={onToggleLikePress}
      />

      {standBy && (
        <TextIconView
          position="absolute"
          right={80}
          bottom={5}
          flexDirection="row-reverse"
          alignItems="flex-start"
          justifyContent="flex-start"
          backgroundColor={colors.secondary}
          text={t('standBy')}
          textMargins="0px 5px 0px 0px"
          textColor={colors.white}
          fontSize="13px"
          icon={standbyIcon}
          iconMargins="0px 5px 0px 5px"
          iconColor={colors.white}
        />
      )}

      {fixed && (
        <TextIconView
          position="absolute"
          left={80}
          bottom={5}
          flexDirection="row-reverse"
          alignItems="flex-start"
          justifyContent="flex-start"
          backgroundColor={colors.grey08}
          text={t('bookScreensPermanentBabysitter')}
          textMargins="0px 5px 0px 0px"
          fontSize="13px"
          icon={repeatIcon}
          iconMargins="0px 5px 0px 5px"
          iconColor={colors.primaryText}
        />
      )}

      {video && (
        <IconButton
          height="auto"
          position="absolute"
          right={-45 / 2}
          bottom={150 / 2 - 45 / 2}
          borderWidth={0}
          borderTopWidth={0}
          borderBottomWidth={0}
          iconSource={playIcon}
          iconWidth="45px"
          iconHeight="45px"
          useIconTintColor={false}
          onPress={onVideoPress}
        />
      )}
    </View>
  );
};

AngelProfileImage.propTypes = {
  margins: PropTypes.string,
  size: PropTypes.string,
  isLiked: PropTypes.bool.isRequired,
  image: PropTypes.string,
  video: PropTypes.string,
  fixed: PropTypes.bool,
  standBy: PropTypes.bool,
  onToggleLikePress: PropTypes.func.isRequired,
  onVideoPress: PropTypes.func
};

AngelProfileImage.defaultProps = {
  margins: '0px',
  size: '150px',
  image: null,
  video: null,
  fixed: false,
  standBy: false,
  onVideoPress: () => {}
};

export default AngelProfileImage;
