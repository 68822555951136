import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { TextView, TextTextView, TextIconView } from '../Texts';
import { getFormatedDate } from '../../utils/functions';
import colors from '../../styles/colors';

import checkIcon from '../../../assets/icons/icn-check.png';

const View = styled.View``;

const RequestSingleDetails = ({ startDate, endDate }) => {
  const [t] = useTranslation();

  return (
    <View>
      <TextView
        paddings="15px 15px 5px 15px"
        borderTopWidth="1px"
        borderBottomWidth="1px"
        backgroundColor={colors.backgroundColor}
        text={t('request')}
        textColor={colors.grey02}
        fontSize="14px"
      />

      <TextTextView
        margins="0px 20px 0px 20px"
        paddings="20px 0px 20px 0px"
        borderBottomWidth="1px"
        firstText={getFormatedDate(startDate, 'dddd')}
        firstTextFontSize="20px"
        firstTextFontWeight="600"
        firstTextFontFamily="Martel"
        secondText={`${getFormatedDate(startDate, 'HH:mm')} - ${getFormatedDate(
          endDate,
          'HH:mm'
        )} `}
        secondTextFontSize="16px"
        secondTextFontWeight="600"
        secondTextFontFamily="Martel"
      />

      <TextIconView
        paddings="10px 20px 10px 20px"
        margins="0px 0px 20px 0px"
        justifyContent="space-between"
        backgroundColor={colors.white}
        text={getFormatedDate(startDate, 'D MMMM')}
        icon={checkIcon}
        iconSize="32px"
        iconColor={colors.secondary}
      />
    </View>
  );
};

RequestSingleDetails.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired
};

export default RequestSingleDetails;
