import { useAppSelector, useGetUserQuery } from '@charlycares/data-access'
import React, { useCallback, useEffect } from 'react'
import { Linking } from 'react-native'
import { isAuthenticated, useRouter } from '@charlycares/shared'

const getQueryParams = (query: string | null) => {
  let params = {}
  if (query) {
    const vars = query.split('&')
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=')
      params = { ...params, [pair[0]]: pair[1] }
    }
  }
  return params
}

export default function useHandleLinks() {
  const { navigate } = useRouter()

  const getInitialLinkRoute = useCallback(async () => {
    const url = await Linking.getInitialURL()
    // const dynamicLink = await firebase.dynamicLinks().getInitialLink()

    if (url) {
      __DEV__ && console.log('OpenUrl:', url)
      const route = url.replace(/.*?:\/\//g, '')
      const [screen, query] = route.split('/')[0].split('?')
      const params = getQueryParams(query)
      return { screen, params }
    }
    // else if (dynamicLink) {
    //   __DEV__ && console.log('OpenUrl:', dynamicLink.url)
    //   const [, query] = dynamicLink.url.replace(/.*?:\/\//g, '').split('?')
    //   const params: any = getQueryParams(query)
    //   if (params.screen) {
    //     return { screen: params.screen, params }
    //   }
    // }

    return null
  }, [])

  const handleDeepLink = (url: string) => {
    __DEV__ && console.log('handleDeepLink', url)
    if (!isAuthenticated()) return

    const route = url.replace(/.*?:\/\//g, '')
    const [screen, query] = route.split('/')[0].split('?')
    const params = getQueryParams(query)

    navigate(screen, '/', params)
  }

  const handleDynamicLink = ({ url }: { url: string }) => {
    __DEV__ && console.log('handleDynamicLink', url)
    if (!url || !isAuthenticated()) return

    const [route, query] = url.replace(/.*?:\/\//g, '').split('?')
    const webUrl = route.split('/').slice(1, route.split('/').length).join('/')
    const params: any = getQueryParams(query)
    if (params.screen) {
      navigate(params.screen, webUrl, params)
    }
  }

  useEffect(() => {
    const sub = Linking.addEventListener('url', e => handleDeepLink(e.url))
    // const unsubscribe = firebase.dynamicLinks().onLink(handleDynamicLink)

    return () => {
      sub.remove()
      //   unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { getInitialLinkRoute }
}
