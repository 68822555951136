import React from 'react';
import PropTypes from 'prop-types';

import CharlyIcon from '../Icons/Icon';
import { Icon } from '../../elements';
import TextView from './TextView';
import theme from '../../styles/theme';

const TextIconView = ({
  name,
  icon,
  iconMargins,
  iconSize,
  iconWidth,
  iconHeight,
  iconColor,
  justifyContent,
  backgroundColor,
  textAlign,
  useTintColor,
  resizeMode,
  ...restTextViewProps
}) => (
  <TextView
    justifyContent={justifyContent}
    backgroundColor={backgroundColor}
    textAlign={textAlign}
    {...restTextViewProps}>
    {name ? (
      <CharlyIcon name={name} size={iconSize} color={iconColor} style={{ margin: iconMargins }} />
    ) : (
      <Icon
        source={icon}
        margins={iconMargins}
        width={iconSize || iconWidth}
        height={iconSize || iconHeight}
        color={iconColor}
        useTintColor={useTintColor}
        resizeMode={resizeMode}
      />
    )}
  </TextView>
);

TextIconView.propTypes = {
  justifyContent: PropTypes.string,
  backgroundColor: PropTypes.string,
  text: PropTypes.string.isRequired,
  textAlign: PropTypes.string,
  icon: PropTypes.node.isRequired,
  iconMargins: PropTypes.string,
  iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconColor: PropTypes.string,
  useTintColor: PropTypes.bool,
  resizeMode: PropTypes.string,
  name: PropTypes.string
};

TextIconView.defaultProps = {
  justifyContent: 'center',
  backgroundColor: theme.colors.green,
  textAlign: 'right',
  iconMargins: '0px',
  iconSize: '20px',
  iconWidth: null,
  iconHeight: null,
  iconColor: theme.colors.white,
  useTintColor: true,
  resizeMode: 'stretch',
  name: ''
};

export default TextIconView;
