import React from 'react'
import { formatInterval, formatMoney, TransactionType, Wallet } from '@charlycares/shared'
import { Box, Flex, HStack, Spacer, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import { CharlyIcon, SvgUri } from '@charlycares/ui'
import moment from 'moment'

interface Props {
  wallets: Wallet[] | undefined
}

export default function Wallets({ wallets }: Props) {
  const { t } = useTranslation()

  return (
    <Box>
      <HStack px="10px">
        <Box minWidth="100px">
          <Text
            color="gray.900"
            paddingBottom="6px"
            fontSize="22px"
            lineHeight="25px"
            textAlign="center"
            fontWeight={600}
          >
            Wallet
          </Text>
        </Box>
        <Spacer>
          <Text color="gray.900" paddingBottom="6px" fontSize="12px" lineHeight="25px" textAlign="center">
            Valid until
          </Text>
        </Spacer>
        <Box minWidth="100px">
          <Text color="gray.900" paddingBottom="6px" fontSize="12px" lineHeight="25px" textAlign="right">
            Balance
          </Text>
        </Box>
      </HStack>
      {wallets
        ?.filter(wallet => wallet.active)
        .map(wallet => (
          <HStack key={wallet.uuid} bgColor="gray.100" pt="10px" pb="6px" px="10px" mb="10px">
            <Box minWidth="100px">
              {!wallet.company && <CharlyIcon name="wallet" size="24px" pt="2px" />}
              {wallet.company && wallet.company.logo_url?.includes('.svg') && (
                <SvgUri uri={wallet.company.logo_url} width={80} height={36} />
              )}
            </Box>
            <Spacer>
              <Text color="gray.900" paddingBottom="6px" fontSize="18px" textAlign="center" fontWeight="600">
                {wallet.valid_until && moment(wallet.valid_until).format('D-M-YYYY')}
              </Text>
            </Spacer>
            <Box minWidth="100px">
              <Text color="gray.900" paddingBottom="6px" fontSize="18px" textAlign="right" fontWeight="600">
                {wallet.transaction_type === TransactionType.MINUTES
                  ? formatInterval(wallet.time, false, true)
                  : formatMoney(wallet.saldo)}
              </Text>
            </Box>
          </HStack>
        ))}
    </Box>
  )
}
