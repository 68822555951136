import React from 'react'
import { Card, Divider, Heading, Icon, Layout, Row, Text } from '@charlycares/ui'
import { Button } from 'native-base'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components/native'
import { useRouter } from '@charlycares/shared'

export default function BusinessAccountConnectionFailed() {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { navigate } = useRouter()

  return (
    <Layout title={t('failed')}>
      <Card>
        <Row mb="20px">
          <Icon name="warning" color={colors.danger} />
          <Text ml="15px" color={colors.danger}>
            Er is een probleem opgetreden met het koppelen van je betaalrekening.
          </Text>
        </Row>

        <Divider />

        <Text mt="20px">Als je niet weet wat er mis is gegaan, neem dan contact op met ons of je bank.</Text>

        <Heading size={16} mt="20px">
          Charly Cares
        </Heading>

        <Text mt="6px">{'Telefoonnummer: 020 - 210 23 23\nWhatsApp: 020 - 210 23 23'}</Text>

        <Text mt="20px">Probeer het opnieuw als je weet wat er verkeerd is gegaan.</Text>

        <Button w="100%" mt="20px" onPress={() => navigate('ProfileScreen', '/profile')}>
          Opnieuw koppelen
        </Button>
      </Card>
    </Layout>
  )
}

BusinessAccountConnectionFailed.navigationOptions = () => ({
  headerShown: false
})
