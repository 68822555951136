import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { logout, setOnboardingProgress } from '../../../stores/actions/userAction'
import { Text } from '../../../elements'
import { TextIconView, TextTextView, TextButton, Progress, Header } from '../../../components'
import { getFormatedDate } from '../../../utils/functions'
import colors from '../../../styles/colors'

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  justify-content: space-between;
`
const View = styled.View``

const Step5 = ({ location, history, route, navigation, user, logOut, setProgress }) => {
  const { progress = 55, isOnboardingDone, appointment } = route.params || location.state || {}
  const { onboardingProgress, angel_appointment } = (user && user.angel) || {}
  const currentProgress = onboardingProgress || progress
  const { agenda_timeslot } = angel_appointment || appointment || {}
  const { agenda, start_date, end_date } = agenda_timeslot || {}
  const { city, address } = agenda || {}
  const [t] = useTranslation()
  // const isOnboardingDone = onboardingProgress === 100;

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <TextTextView
          flexDirection="column"
          firstText={t('signUpAngelScreensYourProfile')}
          firstTextFontFamily="Martel"
          firstTextFontWeight="600"
          secondText={`${isOnboardingDone ? 100 : currentProgress}% ${t('complete')}`}
          secondTextFontSize={13}
          secondTextColor={colors.defaultBtnTextColor}
        />
      ),
      headerRight: (
        <TextButton
          text={t('logOut')}
          textColor={colors.secondary}
          onPress={() => {
            if (navigation) {
              navigation.navigate('BoardingScreenFinal')
            }
            logOut()
          }}
        />
      )
    })
  }

  const saveProgress = () => {
    const signUpProgress = {
      screen: 'SignUpAngelStep5',
      data: { appointment },
      progress
    }
    AsyncStorage.setItem('signUpProgress', JSON.stringify(signUpProgress))
    setProgress(progress)
  }

  // TODO finish redirects
  const getLastProgressScreen = lastProgress => {
    switch (lastProgress) {
      case 70:
        return {
          screen: 'SignUpAngelStep7',
          pathname: '/signup-angel-bio',
          progress: lastProgress
        }
      case 75:
        return {
          screen: 'SignUpAngelStep8',
          pathname: '/signup-angel-preferences',
          progress: lastProgress
        }
      case 86:
        return {
          screen: 'SignUpAngelStep9',
          progress: lastProgress
        }
      case 93:
        return {
          screen: 'SignUpAngelStep10',
          progress: lastProgress
        }
      case 99:
        return {
          screen: 'SignUpAngelStep11',
          progress: lastProgress
        }
      default:
        return {
          screen: 'SignUpAngelStep6',
          pathname: '/signup-angel-references',
          progress: lastProgress === 100 ? 100 : 60
        }
    }
  }

  useEffect(() => {
    saveProgress()
  }, [])

  return (
    <SafeAreaView>
      <View>
        {!navigation && (
          <Header
            title={t('signUpAngelScreensYourProfile')}
            subtitle={`${isOnboardingDone ? 100 : currentProgress}% ${t('complete')}`}
            right={
              <TextButton
                text={t('logOut')}
                textColor={colors.secondary}
                onPress={() => {
                  history.push('/')
                  logOut()
                }}
              />
            }
          />
        )}
        <Progress progress={onboardingProgress || 55} color={isOnboardingDone ? colors.primary : colors.secondary} />

        <TextIconView
          flexDirection="row-reverse"
          justifyContent="flex-end"
          margins="15px 20px 10px 20px"
          width="100%"
          backgroundColor="transparent"
          name="favorites"
          iconSize="50px"
          iconColor={colors.secondary}
          textMargins="0px 0px 0px 10px"
          text={isOnboardingDone ? t('signUpAngelScreensYouAreReadyToFly') : t('signUpAngelScreensYouAreAlmostAnAngel')}
          fontFamily="Martel"
          fontSize="18px"
          fontWeight="600"
          textAlign="left"
        />

        <Text margins="0px 20px 20px 20px" fontWeight="300">
          {isOnboardingDone
            ? t('signUpAngelScreensProfileCompleted')
            : t('signUpAngelScreensCompleteYourProfileDescription')}
        </Text>

        <TextButton
          margins="0px 20px 20px 20px"
          backgroundColor={colors.primary}
          text={
            isOnboardingDone ? t('signUpAngelScreensMakeChangesToProfile') : t('signUpAngelScreensCompleteYourProfile')
          }
          onPress={() => {
            const screenProgress = getLastProgressScreen(onboardingProgress)
            if (navigation) {
              navigation.navigate(screenProgress.screen, { progress: screenProgress.progress })
            } else {
              history.push({
                pathname: screenProgress.pathname,
                state: { progress: screenProgress.progress }
              })
            }
          }}
        />
      </View>

      <View>
        <TextIconView
          flexDirection="row-reverse"
          justifyContent="flex-end"
          margins="20px 20px 10px 20px"
          backgroundColor="transparent"
          text={t('signUpAngelScreensYourAppointment')}
          fontFamily="Martel"
          fontSize="16px"
          fontWeight="600"
          name="icn-calendar"
          iconSize="32px"
          iconColor={colors.primaryText}
        />

        {agenda && (
          <>
            <Text margins="0px 20px 10px 20px" fontWeight="300">
              {t('signUpAngelScreensLocation', { 0: city })}
            </Text>

            <Text margins="0px 20px 10px 20px" fontWeight="300">
              {address}
            </Text>

            <TextButton
              flexDirection="row"
              justifyContent="space-between"
              paddings="10px 0px 10px 0px"
              margins="0px 20px 0px 20px"
              height="auto"
              borderRadius={0}
              borderTopWidth={0}
              borderColor={colors.grey08}
              text={getFormatedDate(start_date, 'dddd, MMM DD')}
              fontSize="16px"
              fontWeight="normal"
              textColor={colors.primaryText}
              onPress={() => {
                if (navigation) {
                  navigation.navigate('SignUpAngelMakeAppointment')
                } else {
                  history.push({ pathname: '/signup-angel-appointment', state: { edit: true } })
                }
              }}
            >
              <Text fontSize="16px">
                {`${getFormatedDate(start_date, 'HH:mm')} ${t('to')} ${getFormatedDate(end_date, 'HH:mm')}`}
              </Text>

              <TextIconView
                backgroundColor="transparent"
                text={t('edit')}
                fontSize="16px"
                textColor={colors.secondary}
                name="icn-nav-right"
                iconColor={colors.grey08}
              />
            </TextButton>
          </>
        )}

        <TextButton
          flexDirection="row"
          justifyContent="space-between"
          paddings="10px 0px 10px 0px"
          margins="0px 20px 0px 20px"
          height="auto"
          borderRadius={0}
          borderTopWidth={0}
          borderColor={colors.grey08}
          textFlex={1}
          text={t('signUpAngelScreensDoYouWantToCancelAppointment')}
          fontSize="16px"
          fontWeight="normal"
          textColor={colors.primaryText}
          textAlign="left"
          onPress={() => {
            if (navigation) {
              navigation.navigate('CancelAppointment')
            } else {
              history.push('/signup-angel-appointment-cancel')
            }
          }}
        >
          <TextIconView
            margins="0px 0px 0px 10px"
            backgroundColor="transparent"
            text={t('cancel')}
            fontSize="16px"
            textColor={colors.secondary}
            name="icn-nav-right"
            iconColor={colors.grey08}
          />
        </TextButton>

        <Text margins="10px 20px 0px 20px" fontSize="12px" fontWeight="300">
          {t('signUpAngelScreensChangeAppointment')}
        </Text>
      </View>
    </SafeAreaView>
  )
}

Step5.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      progress: PropTypes.number,
      isOnboardingDone: PropTypes.bool,
      appointment: PropTypes.shape({})
    })
  }),
  history: PropTypes.shape({ push: PropTypes.func }),
  route: PropTypes.shape({
    params: PropTypes.shape({
      progress: PropTypes.number,
      isOnboardingDone: PropTypes.bool,
      appointment: PropTypes.shape({})
    })
  }),
  navigation: PropTypes.shape({ navigate: PropTypes.func, setOptions: PropTypes.func }),
  logOut: PropTypes.func.isRequired,
  user: PropTypes.shape({ angel: PropTypes.shape({}) }),
  setProgress: PropTypes.func.isRequired
}

Step5.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null,
  user: null
}

const mapStateToProps = ({ User }) => ({
  user: User.user
})

const mapDispatchToProps = {
  logOut: logout,
  setProgress: setOnboardingProgress
}

export default connect(mapStateToProps, mapDispatchToProps)(Step5)
