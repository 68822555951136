import axiosInstance from '../../utils/axios';
import {
  FETCHING_MESSAGES,
  GET_MESSAGES,
  GET_NEXT_MESSAGES,
  NEW_MESSAGE,
  ADDING_MESSAGE,
  UPDATE_MESSAGE,
  SET_MESSAGES_READ,
  UPDATE_CONVERSATION_LAST_MESSAGE,
  GET_CONVERSATIONS,
  GET_ACTIVE_TIMINGS,
  SERVER_ERROR,
  GET_NOTIFICATIONS,
  SET_NOTIFICATIONS_READ
} from '../types';

const logError = (error, dispatch) => {
  if (error.response && error.response.status === 500) {
    dispatch({ type: SERVER_ERROR });
  } else {
    console.log({ error });
  }
};

export const getConversations = () => (dispatch) => {
  axiosInstance
    .get('conversations')
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_CONVERSATIONS,
          payload: data
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};

export const getMessages = (userId, page) => (dispatch) => {
  dispatch({ type: FETCHING_MESSAGES });
  axiosInstance
    .get(`messages/${userId}/10?page=${page}`)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: page > 1 ? GET_NEXT_MESSAGES : GET_MESSAGES,
          payload: { data: data.data.messages.data }
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};

export const clearMessageAdded = () => (dispatch) => {
  dispatch({ type: ADDING_MESSAGE });
};

export const addMessage = (message, receiver_id) => (dispatch) => {
  dispatch({ type: ADDING_MESSAGE });
  const { image, text, ...restMessage } = message;
  const messageToSend = {
    ...restMessage,
    receiver_id: message.receiver_id || receiver_id,
    message: message.text || '',
    image_base64: image
  };
  axiosInstance
    .post('message', messageToSend)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: NEW_MESSAGE,
          payload: data.data.message
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};

export const addRecivedMessage = (message) => (dispatch) => {
  dispatch({
    type: NEW_MESSAGE,
    payload: { receivedMessage: message }
  });
};

export const updateMessage = (id, updateData) => (dispatch) => {
  axiosInstance
    .put(`message/${id}`, updateData)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: UPDATE_MESSAGE,
          payload: data.data
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};

export const updateConversationLastMessage = (conversationId, message) => (dispatch) => {
  dispatch({
    type: UPDATE_CONVERSATION_LAST_MESSAGE,
    payload: { conversationId, message }
  });
};

export const messagesRead = (lastMessageId, viewedAt, makeACall) => (dispatch) => {
  if (makeACall) {
    axiosInstance
      .put('messages/read', { message_id: lastMessageId })
      .then(({ data }) => {
        if (data) {
          dispatch({
            type: SET_MESSAGES_READ,
            payload: viewedAt
          });
        }
      })
      .catch((error) => logError(error, dispatch));
  } else {
    dispatch({
      type: SET_MESSAGES_READ,
      payload: viewedAt
    });
  }
};

export const getActiveTimings = () => (dispatch) => {
  axiosInstance.get('active_timings/time').then(({ data }) => {
    if (data) {
      dispatch({
        type: GET_ACTIVE_TIMINGS,
        payload: data.data
      });
    }
  });
  // .catch((error) => logError(error, dispatch));
};

export const getNotifications = () => (dispatch) => {
  axiosInstance
    .get('notifications')
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_NOTIFICATIONS,
          payload: data.data
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};

export const setNotificationsRead = () => (dispatch) => {
  axiosInstance
    .post('notifications/read')
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: SET_NOTIFICATIONS_READ,
          payload: data.data
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};
