import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Text } from '../../elements';
import { TextView, TextIconView } from '../Texts';
import { TextButton } from '../Buttons';
import { PickerWeb } from '../Pickers';
import colors from '../../styles/colors';
import { isWeb } from '../../utils/functions';

import arrowRightIcon from '../../../assets/icons/icn-nav-right.png';

const SignUpLanguagesEdit = ({
  description,
  languages,
  languagesOptions,
  setLanguages,
  onPress
}) => {
  const [t] = useTranslation();

  return (
    <TextView
      flexDirection="column"
      alignItems="flex-start"
      margins="0px 20px 0px 20px"
      text={t('signUpParentScreensChooseLanguages')}
      fontFamily="Martel"
      fontSize="18px"
      fontWeight="600">
      <Text margins="10px 0px 20px 0px" fontWeight="300">
        {description}
      </Text>

      {!isWeb ? (
        <TextButton
          flexDirection="row"
          justifyContent="space-between"
          paddings="0px"
          width="100%"
          text={t('signUpAngelScreensNative')}
          fontWeight="normal"
          textColor={colors.primaryText}
          onPress={onPress}>
          <TextIconView
            backgroundColor="transparent"
            text={
              languages.map(({ label }) => label).join(', ') ||
              t('signUpScreensMakeAChoiceLanguage')
            }
            fontSize="16px"
            textColor={colors.secondary}
            icon={arrowRightIcon}
            iconColor={colors.defaultBtnTextColor}
          />
        </TextButton>
      ) : (
        <TextView
          flexDirection="row"
          justifyContent="space-between"
          paddings="0px"
          width="100%"
          text={t('signUpAngelScreensNative')}
          fontWeight="normal"
          textColor={colors.primaryText}>
          <PickerWeb
            isMulti
            width="300px"
            options={languagesOptions}
            value={languages}
            onValueChange={setLanguages}
          />
        </TextView>
      )}
    </TextView>
  );
};

SignUpLanguagesEdit.propTypes = {
  description: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ).isRequired,
  languagesOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ).isRequired,
  setLanguages: PropTypes.func.isRequired,
  onPress: PropTypes.func.isRequired
};

export default SignUpLanguagesEdit;
