import { AngelBabysittingExperience } from '../types'

export const MIN_AGE_REQUIRED = 15
export const ANGEL_RATE_MAX_AMOUNT = 99
export const ANGEL_RATE_MIN_AMOUNT = 4
export const ANGEL_DAILY_PET_CARE_RATE_MIN_AMOUNT = 5
export const ANGEL_DAILY_PET_CARE_RATE_MAX_AMOUNT = 500

export const babysittingExperience = [
  {
    label: 'BabysittingExperienceNewBorn',
    value: AngelBabysittingExperience.NewBorn,
    key: 'experienceOpt1'
  },
  {
    label: 'BabysittingExperienceBaby',
    value: AngelBabysittingExperience.Baby,
    key: 'experienceOpt2'
  },
  {
    label: 'BabysittingExperienceToddler',
    value: AngelBabysittingExperience.Toddler,
    key: 'experienceOpt3'
  },
  {
    label: 'BabysittingExperienceSmallChild',
    value: AngelBabysittingExperience.SmallChild,
    key: 'experienceOpt4'
  },
  {
    label: 'BabysittingExperienceChild',
    value: AngelBabysittingExperience.Child,
    key: 'experienceOpt5'
  }
]
