import React, { useState } from 'react'
import { Card, CharlyIcon, ScreenLayout } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { formatMoney } from '@charlycares/shared'
import {
  RedeemableResource,
  ReferralResource,
  usePromotionsQuery,
  useRedeemPromotionCodeMutation
} from '@charlycares/data-access'
import { Box, Button, Heading, HStack, Input, Skeleton, Spacer, Text, VStack } from 'native-base'
import { InviteReferrals } from './common'
import moment from 'moment'

export default function PromotionsScreen() {
  const { t } = useTranslation()
  const { data: promotions, isSuccess: promotionsLoaded } = usePromotionsQuery()
  const [redeemCode, redeemCodeResult] = useRedeemPromotionCodeMutation()
  const [code, setCode] = useState('')
  const redeemCodeError = redeemCodeResult.error as any

  const renderRedeemableAmount = (redeemable: RedeemableResource) => {
    if (redeemable.used_at) {
      return t('promotionsScreen.redeemables.used')
    }

    if (moment(redeemable.expires_at).isBefore(moment())) {
      return t('promotionsScreen.redeemables.expired')
    }

    if (redeemable.amount?.currency) {
      return formatMoney(redeemable.amount)
    }

    return `${redeemable.amount} %`
  }

  return (
    <ScreenLayout title={t('promotionsScreen.title')}>
      <Card>
        <Heading fontSize={18}>{t('promotionsScreen.promotion.heading')}</Heading>
        <Text mt="5px" fontSize={15}>
          {t('promotionsScreen.promotion.subHeading')}
        </Text>
        <HStack mb="10px">
          <Input
            width="100%"
            mt="18px"
            minHeight="20px"
            variant="filled"
            rounded="full"
            borderBottomColor="white"
            value={code}
            placeholder={t('promotionsScreen.promotion.inputPlaceholder')}
            onChangeText={setCode}
            returnKeyType="done"
            onSubmitEditing={() => redeemCode({ code }) as any}
          />
          <Button
            position="absolute"
            right={0}
            mt={17}
            variant="unstyled"
            fontSize={16}
            disabled={code.length === 0}
            onPress={() => redeemCode({ code })}
            isLoading={redeemCodeResult.isLoading}
            _loading={{
              paddingTop: '12px'
            }}
            _text={{ color: code.length > 0 ? 'primary.400' : 'gray.700' }}
          >
            {t('promotionsScreen.promotion.inputButton')}
          </Button>
        </HStack>
        {redeemCodeResult?.status === 'fulfilled' && (
          <HStack bgColor="secondary.alpha.15">
            <Box width="3px" bgColor="secondary.400"></Box>
            <Text ml="10px" fontSize="12px">
              {t('promotionsScreen.promotion.correctCode', {
                amount: formatMoney(redeemCodeResult.data.amount)
              })}
            </Text>
          </HStack>
        )}
        {redeemCodeResult?.status === 'rejected' && (
          <Text color="red.500">{t(`promotionsScreen.promotion.${redeemCodeError.data.message}`)}</Text>
        )}
      </Card>

      <Card>
        <InviteReferrals />
      </Card>
      <Card>
        <Heading fontSize={18}>{t('promotionsScreen.referrers.heading')}</Heading>

        {promotions?.redeemables?.length !== 0 && (
          <VStack pb="10px" mt="10px">
            <HStack>
              <Text minWidth="40%">&nbsp;</Text>
              <Text mr="15px" minWidth="70px" textAlign="center" color="gray.900">
                {t('promotionsScreen.redeemables.valid_until')}
              </Text>
            </HStack>
            {promotions?.redeemables
              .filter(redeemable => redeemable.type === 'promotion')
              .map(redeemable => (
                <HStack bgColor="secondary.alpha.15" height="25px" pt="2px" mt="2px">
                  <Text minWidth="40%">{redeemable.code}</Text>
                  <Text>{moment(redeemable.expires_at).format('DD-MM-YYYY')}</Text>
                  <Spacer />
                  <Text mr="10px" fontWeight={600} color="gray.900">
                    {redeemable ? renderRedeemableAmount(redeemable) : null}
                  </Text>
                </HStack>
              ))}
          </VStack>
        )}

        <VStack mt="20px">
          <HStack pb="10px">
            <Spacer />
            <HStack>
              <Text mr="15px" minWidth="70px" textAlign="center" fontWeight={600} color="gray.900">
                {t('promotionsScreen.referrers.booked')}
              </Text>
              <Text minWidth="50px" textAlign="center" fontWeight={600} color="gray.900">
                {t('promotionsScreen.referrers.credit')}
              </Text>
            </HStack>
          </HStack>
          {!promotionsLoaded && <Skeleton width="100%" height="20px" />}

          {promotionsLoaded && promotions?.referrals?.length === 0 && (
            <HStack>
              <Text>{t('promotionsScreen.referrers.noNewFriendsActive')}</Text>
              <Spacer />
              <HStack>
                <Text mr="15px" minWidth="70px" textAlign="center" fontSize="14px" color="gray.500">
                  {t('promotionsScreen.referrers.notBooked')}
                </Text>

                <Text minWidth="50px" textAlign="center" fontSize="14px" color="gray.500">
                  {t('promotionsScreen.referrers.noCredit')}
                </Text>
              </HStack>
            </HStack>
          )}

          {promotionsLoaded &&
            promotions?.referrals?.map((referral: ReferralResource) => (
              <HStack pb="16px">
                <Text>
                  {t(`promotionsScreen.referrers.${referral.to.role}.prefix`)}{' '}
                  {referral.to.first_name ?? referral.to.last_name ?? ''}
                </Text>
                <Spacer />
                <HStack>
                  {referral.status === 'pending' ? (
                    <Text mr="15px" minWidth="70px" textAlign="center" fontSize="14px" color="gray.500">
                      {t('promotionsScreen.referrers.notBooked')}
                    </Text>
                  ) : (
                    <Box minWidth="70px">
                      <CharlyIcon ml="8px" name="icn-check" color="secondary.400" />
                    </Box>
                  )}

                  <Text
                    minWidth="50px"
                    textAlign="center"
                    fontSize="14px"
                    color={referral.status === 'pending' ? 'gray.500' : 'gray.900'}
                  >
                    {referral.redeemable
                      ? formatMoney(referral.redeemable.amount)
                      : t('promotionsScreen.referrers.noCredit')}
                  </Text>
                </HStack>
              </HStack>
            ))}
        </VStack>

        <Text fontSize={15} mt="20px" color="gray.900">
          {t('promotionsScreen.referrers.footer')}
        </Text>
      </Card>
    </ScreenLayout>
  )
}

PromotionsScreen.navigationOptions = () => ({
  headerShown: false
})
