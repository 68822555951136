import React from 'react'
import { Card, CharlyIcon, ScreenLayout } from '@charlycares/ui'
import { Avatar, Box, Button, Center, Divider, Heading, HStack, Spinner, Text, VStack } from 'native-base'
import { formatDate, formatMoney, getDistance, isWeb, makeCall, UserLanguages, useRouter } from '@charlycares/shared'
import { useTranslation } from 'react-i18next'
import {
  toggleSelectedAngels,
  useAppSelector,
  useGetAngelDetailsQuery,
  useGetUserRatingsQuery,
  useToggleFavoriteAngelMutation
} from '@charlycares/data-access'
import { useDispatch } from 'react-redux'

import { AvailabilityTable } from '../../profile'
import SelectedAngelsContainer from '../family/common/SelectedAngelsContainer'
import RatingsOverview from '../../reviews/common/RatingsOverview'

const AngelDetailsScreen = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { getParam, navigate } = useRouter()

  const angelId: string = getParam('angelId')
  const isSelectable: boolean = getParam('selectable')

  const selectedAngels = useAppSelector(state => state.booking.search.selectedAngels)
  const [toggleFavorite, { isLoading: favoriteLoading }] = useToggleFavoriteAngelMutation()
  const { data: angel } = useGetAngelDetailsQuery(
    { angelObscuredId: angelId },
    {
      skip: !angelId
    }
  )
  const { data: ratings } = useGetUserRatingsQuery({ user_id: angel?.angel_user_id }, { skip: !angel?.angel_user_id })

  if (!angel) {
    return <ScreenLayout isLoading />
  }

  const isFavorite = angel.is_favourite !== undefined ? angel.is_favourite : angel.is_liked
  const isSelected = !!selectedAngels.find(a => a.obscured_id === angel.obscured_id)
  const angelSkills = [
    {
      icon: <CharlyIcon name="icn-first-aid" color="black" size={35} />,
      prop: angel.first_aid
    },
    {
      icon: <CharlyIcon name="icn-baby-bottle" color="black" size={35} />,
      prop: angel.babysit_expertise
    },
    {
      icon: <CharlyIcon name="icn-car" color="black" size={35} />,
      prop: angel.driving_license
    },
    {
      icon: <CharlyIcon name="icn-insurance" color="black" size={35} />,
      prop: angel.liability_insurance
    }
  ].filter(skill => skill.prop)

  return (
    <ScreenLayout
      headerRight={
        <HStack space={isWeb ? '15px' : '10px'} alignItems="center">
          <CharlyIcon
            onPress={() => navigate('ChatScreen', '/chat', { conversationId: angel?.conversation_id })}
            disabled={!angel.conversation_id}
            name="icn-chat"
            size={30}
            color={angel.conversation_id ? 'primary.400' : 'gray.400'}
          />

          <CharlyIcon
            disabled={!angel.phone}
            onPress={() => angel.phone && makeCall(angel.phone)}
            name="icn-telephone-fill"
            size={30}
            color={angel.phone ? 'primary.400' : 'gray.400'}
          />

          <CharlyIcon
            onPress={() => navigate('BookDirect', '/book-direct', { angelId: angel.obscured_id })}
            name="icn-calendar"
            size={30}
            color="primary.400"
          />
        </HStack>
      }
      BottomComponent={
        <Box px="20px" borderTopWidth={1} borderColor="gray.400">
          <SelectedAngelsContainer />
        </Box>
      }
    >
      <Card>
        <Center>
          <Box>
            <Avatar
              borderWidth={1}
              borderColor="gray.200"
              source={{ uri: angel.image }}
              size="150px"
              alignSelf="center"
            />
            {favoriteLoading ? (
              <Center size={42} position={'absolute'} left={'-15px'} top={'-15px'}>
                <Spinner />
              </Center>
            ) : (
              <CharlyIcon
                hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
                onPress={() =>
                  toggleFavorite({ angel_id: angel.id, like: !isFavorite, angelObscuredId: angel.obscured_id })
                }
                name={isFavorite ? 'heart' : 'favorite-inactive'}
                color={isFavorite ? 'primary.400' : 'gray.500'}
                size={42}
                position={'absolute'}
                left={'-15px'}
                top={'-15px'}
              />
            )}
          </Box>

          {isSelectable && (
            <Button
              _pressed={{
                opacity: 0.5
              }}
              _hover={{
                bg: 'secondary.alpha.20'
              }}
              variant={isSelected ? 'outline' : 'solid'}
              onPress={() => dispatch(toggleSelectedAngels(angel))}
              isDisabled={favoriteLoading}
              my="10px"
              w="166px"
            >
              {isSelected ? t('deselect') : t('select')}
            </Button>
          )}

          <Heading mt="10px" fontSize="lg" alignSelf="center">{`${angel.first_name} (${angel.age})`}</Heading>
          {Boolean(ratings?.average_rating) && (
            <HStack space="10px" mt="10px" alignItems="center">
              <Heading fontSize="18px">{ratings?.average_rating?.toFixed(2)}</Heading>
              <CharlyIcon name="star" size={20} color={'gray.800'} />
              <Text fontSize="14px" color="primary.400">{`${ratings?.ratings_count || 0} ${t(
                ratings?.ratings_count === 1 ? 'review' : 'reviews'
              )}`}</Text>
            </HStack>
          )}
        </Center>

        {!!angel.short_bio && (
          <Text fontSize="15px" mt={'15px'}>
            {angel.short_bio}
          </Text>
        )}

        <Divider my="15px" />

        <HStack space="40px" justifyContent="center" alignItems="center">
          <Center>
            <CharlyIcon name="icn-day" size={35} color="gray.900" />
            <Text>{formatMoney(angel.day_rate_per_hour)}</Text>
          </Center>
          <Center>
            <CharlyIcon name="icn-night" size={35} color="gray.900" />
            <Text>{formatMoney(angel.night_rate_per_hour)}</Text>
          </Center>
          <Center>
            <CharlyIcon name="icn-location" size={35} color="gray.900" />
            <Text>{getDistance(angel.distance)}</Text>
          </Center>
        </HStack>

        <Divider my="15px" />

        <VStack space="15px">
          <Box flexDir="row" alignItems="center">
            <Text w="120px">{`${t('responseTime')}:`}</Text>
            <Text>{t('responseTimeDesc', { minutes: angel.response_time })}</Text>
          </Box>
          <Box flexDir="row" alignItems="center">
            <Text w="120px">{`${t('languages')}:`}</Text>
            <Text flex={1} textTransform="capitalize">
              {Object.keys(angel.languages)
                .filter(key => angel.languages[key as keyof UserLanguages])
                .join(', ')}
            </Text>
          </Box>
          <Box flexDir="row" alignItems="center">
            <Text w="120px" textTransform="capitalize">{`${t('education')}:`}</Text>
            <Text>{angel.education}</Text>
          </Box>
          <Box flexDir="row" alignItems="center">
            <Text w="120px" textTransform="capitalize">{`${t('fieldOfStudy')}:`}</Text>
            <Text>{angel.field_of_study}</Text>
          </Box>
        </VStack>

        <Divider my="15px" />

        {!!angelSkills.length && (
          <>
            <Box>
              <Heading fontSize="md">{t('skillsAndExperience')}</Heading>
              <HStack mt="15px" space="30px">
                {angelSkills.map((skill, i) => (
                  <Box key={i}>{skill.icon}</Box>
                ))}
              </HStack>
            </Box>

            <Divider my="15px" />
          </>
        )}

        {!!angel.availabilities.length && (
          <>
            <AvailabilityTable availabilities={angel.availabilities} />
            <Divider my="15px" />
          </>
        )}

        <Box>
          {ratings && (
            <RatingsOverview
              _title={{ fontSize: 'md' }}
              ratings={ratings.category_ratings}
              averageRating={ratings.average_rating}
              reviewsCount={ratings.ratings_count}
              title={t('familiesAboutAngel', { angel: angel.first_name })}
            />
          )}
        </Box>

        <Divider my="15px" />

        <VStack w="100%" space="20px">
          {ratings?.user_rating_history.map(item => (
            <Box key={item.booking_id}>
              <Box flexDir="row" alignItems="center" justifyContent="space-between">
                <Text fontSize={15}>{`${item.profile.first_name} - ${formatDate(
                  item.start_date,
                  'DD MMMM YYYY'
                )}`}</Text>
                {!!item?.average_rating && (
                  <HStack space="5px" mt="5px" alignItems="center">
                    <Heading fontSize={'15px'}>{item?.average_rating?.toFixed(2)}</Heading>
                    <CharlyIcon name="star" size={15} color={'gray.800'} />
                  </HStack>
                )}
              </Box>

              {!!item.comment && (
                <Text mt="5px" fontSize={14}>
                  {item.comment}
                </Text>
              )}
            </Box>
          ))}

          {!!ratings?.user_rating_history.length && (
            <Button
              onPress={() =>
                navigate('ViewReviews', '/booking-view-reviews', {
                  user_id: angel.angel_user_id
                })
              }
              w="120px"
              size="sm"
              alignSelf="center"
              variant="outline"
            >
              {t('moreReviews')}
            </Button>
          )}
        </VStack>
      </Card>
    </ScreenLayout>
  )
}

export default AngelDetailsScreen

AngelDetailsScreen.navigationOptions = {
  headerShown: false
}
