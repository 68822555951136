import React from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { isWeb, useRouter } from '@charlycares/shared'

import { Box, Center, Flex, IBoxProps, Input, StatusBar, Text } from 'native-base'
import { CharlyIcon } from '../icons'
import { setBookingSearchName } from '@charlycares/data-access'

type HeaderProps = IBoxProps & {
  title?: string
  subtitle?: string
  hideBack?: boolean
  headerRight?: () => React.ReactNode
  headerLeft?: () => React.ReactNode
  textColor?: string
  customHeader?: React.ReactNode
}

export default function Header({
  title,
  subtitle,
  hideBack,
  headerRight,
  headerLeft,
  textColor,
  customHeader,
  ...boxProps
}: HeaderProps) {
  const { goBack } = useRouter()
  const insets = useSafeAreaInsets()

  return (
    <Box
      pt={`${insets.top + 10}px`}
      pb="10px"
      w="100%"
      bg={boxProps.bg ?? 'white'}
      borderWidth={boxProps.borderWidth ?? '1px'}
      zIndex={10}
      borderColor="gray.200"
      borderTopWidth={isWeb ? '1px' : 0}
      {...boxProps}
    >
      <Flex minH="30px" direction="row" alignItems="center" justify="space-between" px="15px">
        {!hideBack && !headerLeft && (
          <CharlyIcon name="icn-nav-left" color={textColor ?? 'primary.400'} size={30} onPress={goBack} />
        )}

        {!customHeader && <Box w="auto">{headerLeft?.()}</Box>}

        {customHeader ? (
          customHeader
        ) : (
          <Center zIndex={-1} position="absolute" top="0" bottom="0" left="0" right="0">
            <Text fontSize={subtitle ? '16px' : '18px'} fontWeight="600" color={textColor ?? 'black'}>
              {title}
            </Text>
            {!!subtitle && (
              <Text fontSize={'13px'} color="gray.700">
                {subtitle}
              </Text>
            )}
          </Center>
        )}

        {!customHeader && <Box w="auto">{headerRight?.()}</Box>}
      </Flex>
    </Box>
  )
}
