import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { TextView } from '../../Texts';
import { IconButton } from '../../Buttons';
import theme from '../../../styles/theme';
import {
  IcnBabyBottle,
  IcnCar,
  IcnValidated,
  IcnReading,
  IcnFirstAid,
  IcnInsurance
} from '../../Icons';

import infoIcon from '../../../../assets/icons/icn-small-info.png';

const Container = styled.View`
  width: 100%;
  padding: ${({ paddings }) => paddings};
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: ${theme.colors.grey08};
`;

const IconsContainer = styled.View`
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Skills = ({
  paddings,
  firstAid,
  pro,
  babyExperience,
  drivingLicense,
  tutor,
  liabilityInsurance,
  showInfo,
  onInfoPress
}) => {
  const [t] = useTranslation();

  return (
    <Container paddings={paddings}>
      <TextView
        justifyContent="space-between"
        text={showInfo ? t('angelProfileSkillsAndExperiences') : t('skillsAndExperiences')}
        fontFamily={showInfo ? 'Martel' : 'Open Sans'}
        fontSize={showInfo ? '16px' : '12px'}
        fontWeight={showInfo ? theme.textFontWeightBold : theme.textFontWeight}
        textColor={showInfo ? theme.textColorBase : theme.colors.grey02}>
        {showInfo && (
          <IconButton
            paddings="5px"
            iconSource={infoIcon}
            iconColor={theme.colors.secondary}
            onPress={onInfoPress}
          />
        )}
      </TextView>

      <IconsContainer>
        {!!tutor && <IcnReading size="32px" style={{ marginRight: 16 }} />}

        {pro && <IcnValidated size="32px" style={{ marginRight: 16 }} />}

        {firstAid && <IcnFirstAid size="32px" style={{ marginRight: 16 }} />}

        {babyExperience && <IcnBabyBottle size="32px" style={{ marginRight: 16 }} />}

        {drivingLicense && <IcnCar size="32px" style={{ marginRight: 16 }} />}

        {liabilityInsurance && <IcnInsurance size="32px" style={{ marginRight: 16 }} />}
      </IconsContainer>
    </Container>
  );
};

Skills.propTypes = {
  paddings: PropTypes.string,
  firstAid: PropTypes.bool.isRequired,
  pro: PropTypes.bool.isRequired,
  babyExperience: PropTypes.bool.isRequired,
  drivingLicense: PropTypes.bool.isRequired,
  tutor: PropTypes.bool.isRequired,
  liabilityInsurance: PropTypes.bool.isRequired,
  showInfo: PropTypes.bool,
  onInfoPress: PropTypes.func
};

Skills.defaultProps = {
  paddings: '10px 0px 10px 0px',
  showInfo: true,
  onInfoPress: () => {}
};

export default Skills;
