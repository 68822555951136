import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import theme from '../../styles/theme';
import { Text, Heading, ContainerView } from '../../elements';
import { TextButton } from '../Buttons';

const RegularBabysitter = ({ isAngel, locale, onReadMorePress }) => {
  const [t] = useTranslation();

  return (
    <ContainerView
      flexDirection="column"
      alignItems="flex-start"
      paddings="32px 0"
      margins="0 20px"
      borderBottomWidth="1px"
    >
      <Heading
        xxl
        ariaLevel={3}
        text={
          isAngel
            ? t('dashboardScreensBabysitRegularlyTitle')
            : t('dashboardScreensRegularBabysitterTitle')
        }
      />
      <Text margins="0 0 8px 0" lg lighterColor>
        {isAngel
          ? t('dashboardScreensBabysitRegularlySubtitle')
          : t('dashboardScreensRegularBabysitterSubtitle')}
      </Text>

      <Text margins="0 0 8px 0">
        {isAngel
          ? t('dashboardScreensBabysitRegularlyDescription')
          : t('dashboardScreensRegularBabysitterDescription')}
      </Text>

      <TextButton
        margins="0 0 0 auto"
        paddings="0px 8px"
        borderTopWidth={0}
        borderBottomWidth={0}
        text={t('readMore')}
        textColor={theme.colors.notify}
        onPress={() =>
          onReadMorePress(
            `https://www.charlycares.com/${
              locale
                ? `${locale}babysitting-fixed-job`
                : 'oppassen-vaste-bijbaan'
            }`
          )
        }
      />
    </ContainerView>
  );
};

RegularBabysitter.propTypes = {
  isAngel: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  onReadMorePress: PropTypes.func.isRequired,
};

RegularBabysitter.defaultProps = {
  isAngel: true,
};

export default RegularBabysitter;
