import {
  GET_REFERRAL_SETTINGS,
  GET_REFERRALS,
  GET_REFERRAL_ALL,
  GET_REFERRAL_LANDING
} from '../types';

const initialState = {
  referralSettings: {}
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_REFERRAL_SETTINGS:
      return {
        referralSettings: payload
      };
    case GET_REFERRALS:
      return {
        ...state,
        referrals: payload
      };
    case GET_REFERRAL_ALL:
      return {
        ...state,
        allReferrals: payload.referrals
      };
    case GET_REFERRAL_LANDING:
      console.log(payload);
      return {
        ...state,
        referralLanding: payload
      };
    default:
      return state;
  }
}
