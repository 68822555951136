import React, { useState, useEffect, useRef, lazy, Suspense } from 'react'
import { ActivityIndicator, Alert, Keyboard } from 'react-native'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import {
  logout,
  updateProfile,
  updateProfileImage,
  validateRegistration,
  clearValidationErrors,
  setValidationError
} from '../../stores/actions/userAction'
import { Text, Divider } from '../../elements'
import {
  TextButton,
  LabeledInput,
  ProfileAvatar,
  AddressEdit,
  PhoneNumbersEdit,
  NotificationSettings,
  EmailPasswordEdit,
  Header
} from '../../components'
import { isWeb, getAllCountryCodes, isCountryCodeValid } from '../../utils/functions'
import colors from '../../styles/colors'
import ImageUploader from 'react-images-upload'

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  overflow: hidden;
`
const ScrollView = styled.ScrollView`
  flex: 1;
`
const Border = styled.View`
  width: 100%;
  height: 1px;
  background-color: ${colors.grey08};
`

const Edit = ({
  location,
  history,
  route,
  navigation,
  user,
  uploadingImage,
  updatingProfile,
  profileUpdated,
  validationErrors,
  logOut,
  registrationValidated,
  updateProfileData,
  updateProfileImageData,
  setValidationErrorData,
  validateRegistrationData,
  clearValidationErrorsData
}) => {
  const { shouldGoBack } = location.state || route.params || {}
  const { promo_mail, system_mail, promo_push, system_push } = user.settings || {}
  const { short_bio } = user.role === 'angel' ? user.angel : user.family
  const isAngel = user.role === 'angel'
  const [avatarSource, setAvatarSource] = useState({ uri: user.image })
  const [userEdit, setUserEdit] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    short_bio,
    postalcode: user.postalcode,
    street_number: user.street_number,
    phone: user.phone,
    second_phone: user.second_phone,
    countryCode: 'NL (+31)',
    phoneNumber: '',
    promo_mail: !!promo_mail,
    system_mail: !!system_mail,
    promo_push: !!promo_push,
    system_push: !!system_push,
    email: user.email,
    password: '',
    inputFocused: null,
    editPhoneNumber: false,
    hidePassword: true
  })
  const [t] = useTranslation()
  const nameInput = useRef(null)
  const surnameInput = useRef(null)
  const shortBioInput = useRef(null)
  const postalCodeInput = useRef(null)
  const streetNumberInput = useRef(null)
  const phoneNumberInput = useRef(null)
  const emailInput = useRef(null)
  const passwordInput = useRef(null)
  const countryCodePicker = useRef(null)

  const validate = () => {
    const dataToValidate = {
      postalcode: userEdit.postalcode
    }
    if (user.email !== userEdit.email) {
      dataToValidate.email = userEdit.email
    }
    if (userEdit.password.length > 0 && userEdit.password.length < 7) {
      setValidationErrorData({ password: t('validationErrorsPassword') })
    }
    validateRegistrationData(dataToValidate)
  }

  const onUpdateProfile = () => {
    const dataToUpdate = {
      first_name: userEdit.first_name,
      last_name: userEdit.last_name,
      short_bio: userEdit.short_bio,
      postalcode: userEdit.postalcode,
      street_number: userEdit.street_number,
      phone: userEdit.phone,
      promo_mail: userEdit.promo_mail,
      system_mail: userEdit.system_mail,
      promo_push: userEdit.promo_push,
      system_push: userEdit.system_push
    }
    if (userEdit.second_phone) {
      dataToUpdate.second_phone = userEdit.second_phone
    }
    if (user.email !== userEdit.email) {
      dataToUpdate.email = userEdit.email
    }
    if (userEdit.password.length !== 0) {
      dataToUpdate.password = userEdit.password
    }
    updateProfileData(dataToUpdate)
  }

  const uploadImage = () => {
    const formData = new FormData()
    formData.append('file', avatarSource.file)

    updateProfileImageData(formData)
    validate()
  }

  const onUpdateProfilePress = () => {
    Keyboard.dismiss()
    if (avatarSource.uri !== user.image && user.role === 'family') {
      uploadImage()
    } else {
      validate()
    }
  }

  const goBack = () => {
    if (shouldGoBack) {
      navigation.goBack()
    } else {
      navigation.replace('ProfileAccount')
    }
  }

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('edit')}
        </Text>
      ),
      headerRight: (
        <TextButton text={t('save')} textColor={colors.secondary} fontWeight="normal" onPress={onUpdateProfilePress} />
      ),
      headerLeft: (
        <TextButton
          text={t('cancel')}
          textColor={colors.secondary}
          onPress={() => {
            Alert.alert(
              t('profileScreensLeaveThisScreen'),
              t('profileScreensChangesNotSaved'),
              [
                { text: t('cancel') },
                {
                  text: t('leave'),
                  onPress: goBack
                }
              ],
              { cancelable: false }
            )
          }}
        />
      )
    })
  }

  useEffect(() => {
    if (validationErrors) {
      if (Object.keys(validationErrors).length === 0) {
        clearValidationErrorsData()
        onUpdateProfile()
      } else if (validationErrors.outside_area) {
        setValidationErrorData({ postalcode: t('validationErrorsOutsideArea') })
      }
    } else if (registrationValidated) {
      onUpdateProfile()
    }
  }, [validationErrors, updatingProfile, uploadingImage, registrationValidated])

  useEffect(() => {
    if (profileUpdated && !updatingProfile && registrationValidated) {
      if (navigation) {
        navigation.goBack()
      } else {
        history.goBack()
      }
    }
  }, [profileUpdated, updatingProfile, registrationValidated])

  const isPhoneNumberValid = () => {
    if (userEdit.countryCode.length !== 0) {
      if (!isCountryCodeValid(userEdit.countryCode)) {
        setValidationErrorData({ countryCode: t('validationErrorsCountryCode') })

        return false
      }
      const phone = `${userEdit.countryCode.slice(5, userEdit.countryCode.length - 1)}${userEdit.phoneNumber}`
      if (phone.length < 10) {
        setValidationErrorData({ phone: t('validationErrorsPhone') })

        return false
      }
    }

    return true
  }

  const onAddNumberPress = () => {
    if (isPhoneNumberValid()) {
      const newNumber = `${userEdit.countryCode.slice(5, userEdit.countryCode.length - 1)} ${userEdit.phoneNumber}`
      setUserEdit(() => {
        if (!userEdit.phone) {
          return {
            ...userEdit,
            phone: newNumber,
            countryCode: '',
            phoneNumber: '',
            editPhoneNumber: false
          }
        }
        return {
          ...userEdit,
          second_phone: newNumber,
          countryCode: '',
          phoneNumber: '',
          editPhoneNumber: false
        }
      })
    }
  }

  return (
    <SafeAreaView>
      {isWeb && (
        <Header
          left={<TextButton text={t('cancel')} textColor={colors.secondary} onPress={() => history.goBack()} />}
          right={
            <TextButton
              text={t('save')}
              textColor={colors.secondary}
              onPress={onUpdateProfilePress}
              fontWeight="normal"
            />
          }
        />
      )}

      <Divider />

      <ScrollView>
        <ProfileAvatar
          alignSelf="center"
          margins="20px 10px 0px 10px"
          paddings="0px 25px 0px 25px"
          backgroundColor="transparent"
          borderRadius={0}
          borderLeftWidth={avatarSource.uri ? '0px' : '4px'}
          imageWidth="300px"
          imageHeight="200px"
          source={avatarSource.uri || null}
          isAngel={isAngel}
        >
          {!isAngel && (
            <Suspense fallback={<ActivityIndicator />}>
              <ImageUploader
                singleImages
                withIcon={false}
                withLabel={false}
                fileContainerStyle={{
                  margin: '0px',
                  padding: '0px',
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  top: '-15px'
                }}
                buttonStyles={{
                  margin: '0px'
                }}
                buttonText={t('profileScreensAddFamilyPhoto')}
                onChange={files => setAvatarSource({ file: files[0], uri: URL.createObjectURL(files[0]) })}
                imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                maxFileSize={5242880}
              />
            </Suspense>
          )}
        </ProfileAvatar>

        <LabeledInput
          inputRef={nameInput}
          paddings="0px 5px 0px 5px"
          margins="20px 10px 20px 10px"
          height="auto"
          width="95%"
          borderLeftWidth={userEdit.first_name ? 0 : '4px'}
          borderColor={colors.secondary}
          backgroundColor={colors.white}
          inputBackgroundColor={colors.backgroundColor}
          inputBorderColor={
            userEdit.inputFocused && userEdit.inputFocused.firstName ? colors.secondary : colors.primary
          }
          labelText={t('firstName')}
          inputMargins="5px 0px 0px 0px"
          inputPaddings="0px 0px 0px 5px"
          value={userEdit.first_name}
          onChangeText={value => setUserEdit({ ...userEdit, first_name: value })}
          onFocus={() => {
            setUserEdit({
              ...userEdit,
              inputFocused: { firstName: nameInput.current.isFocused() }
            })
          }}
          onSubmitEditing={() => {
            Keyboard.dismiss()
            setUserEdit({ ...userEdit, inputFocused: { inputFocused: { firstName: false } } })
          }}
          returnKeyType="done"
        />

        <LabeledInput
          inputRef={surnameInput}
          paddings="0px 5px 0px 5px"
          margins="20px 10px 20px 10px"
          height="auto"
          width="95%"
          borderLeftWidth={userEdit.last_name ? 0 : '4px'}
          borderColor={colors.secondary}
          backgroundColor={colors.white}
          inputBackgroundColor={colors.backgroundColor}
          inputBorderColor={userEdit.inputFocused && userEdit.inputFocused.lastName ? colors.secondary : colors.primary}
          labelText={t('surname')}
          inputMargins="5px 0px 0px 0px"
          inputPaddings="0px 0px 0px 5px"
          value={userEdit.last_name}
          onChangeText={value => setUserEdit({ ...userEdit, last_name: value })}
          onFocus={() =>
            setUserEdit({
              ...userEdit,
              inputFocused: { lastName: surnameInput.current.isFocused() }
            })
          }
          onSubmitEditing={() => {
            Keyboard.dismiss()
            setUserEdit({ ...userEdit, inputFocused: { lastName: false } })
          }}
          returnKeyType="done"
        />

        <Border />

        <LabeledInput
          inputRef={shortBioInput}
          paddings="0px 5px 0px 5px"
          margins="20px 10px 20px 10px"
          height="auto"
          width="95%"
          borderLeftWidth={userEdit.short_bio ? 0 : '4px'}
          borderColor={colors.secondary}
          maxInputHeight="135px"
          minInputHeight="135px"
          backgroundColor={colors.white}
          inputBackgroundColor={colors.backgroundColor}
          inputBorderColor={userEdit.inputFocused && userEdit.inputFocused.shortBio ? colors.secondary : colors.primary}
          labelText={user.role === 'family' ? t('familyDescription') : t('description')}
          inputMargins="5px 0px 0px 0px"
          inputPaddings="5px 0px 0px 5px"
          multiline
          value={userEdit.short_bio}
          returnKeyType="default"
          autoCapitalize="sentences"
          textAlignVertical="top"
          onChangeText={value => setUserEdit({ ...userEdit, short_bio: value })}
          onFocus={() =>
            setUserEdit({
              ...userEdit,
              inputFocused: { shortBio: shortBioInput.current.isFocused() }
            })
          }
        />

        <Border />

        <AddressEdit
          postalcode={userEdit.postalcode}
          streetNumber={userEdit.street_number}
          streetName={user.street_name}
          inputFocused={userEdit.inputFocused}
          validationErrors={validationErrors}
          postalcodeInputRef={postalCodeInput}
          onPostalcodeChangeText={value => {
            if (validationErrors && validationErrors.postalcode) {
              clearValidationErrorsData('postalcode')
            }
            setUserEdit({ ...userEdit, postalcode: value.trim() })
          }}
          onPostalcodeFocus={() =>
            setUserEdit({
              ...userEdit,
              inputFocused: { postalCode: postalCodeInput.current.isFocused() }
            })
          }
          streetNumberInputRef={streetNumberInput}
          onStreetNumberChangeText={value => setUserEdit({ ...userEdit, street_number: value })}
          onStreetNumberFocus={() =>
            setUserEdit({
              ...userEdit,
              inputFocused: { streetNumber: streetNumberInput.current.isFocused() }
            })
          }
          onSubmitPostalcode={() => {
            Keyboard.dismiss()
            setUserEdit({ ...userEdit, inputFocused: { postalCode: false } })
          }}
          onSubmitStreetNumber={() => {
            Keyboard.dismiss()
            setUserEdit({ ...userEdit, inputFocused: { streetNumber: false } })
          }}
          returnKeyType="next"
        />

        <Border />

        <PhoneNumbersEdit
          phone={userEdit.phone}
          secondPhone={userEdit.second_phone}
          countryCode={userEdit.countryCode}
          countryCodeOptions={getAllCountryCodes()}
          onCountryChange={country => setUserEdit({ ...userEdit, countryCode: country.value })}
          phoneNumber={userEdit.phoneNumber}
          inputFocused={userEdit.inputFocused}
          validationErrors={validationErrors}
          editPhoneNumber={userEdit.editPhoneNumber}
          onRemovePhonePress={() => setUserEdit({ ...userEdit, phone: null })}
          onRemoveSecondPhonePress={() => setUserEdit({ ...userEdit, second_phone: null })}
          onCountryCodePress={() => {
            setUserEdit({ ...userEdit, inputFocused: { countryCode: true } })
            countryCodePicker.current.setIsVisible(true)
          }}
          phoneNumberInputRef={phoneNumberInput}
          onPhoneNumberChangeText={value => {
            if (validationErrors && validationErrors.phone) {
              clearValidationErrorsData('phone')
            }
            setUserEdit({ ...userEdit, phoneNumber: value })
          }}
          onPhoneNumberFocus={() =>
            setUserEdit({
              ...userEdit,
              inputFocused: { phoneNumber: phoneNumberInput.current.isFocused() }
            })
          }
          onAddPhoneNumberPress={() => setUserEdit({ ...userEdit, editPhoneNumber: true })}
          onCancelPress={() => setUserEdit({ ...userEdit, countryCode: '', phoneNumber: '', editPhoneNumber: false })}
          onAddNumberPress={onAddNumberPress}
        />

        <NotificationSettings
          promoMail={!!userEdit.promo_mail}
          systemMail={!!userEdit.system_mail}
          promoPush={!!userEdit.promo_push}
          systemPush={!!userEdit.system_push}
          onPromoMailChange={value => setUserEdit({ ...userEdit, promo_mail: value })}
          onSystemMailChange={value => setUserEdit({ ...userEdit, system_mail: value })}
          onPromoPushChange={value => setUserEdit({ ...userEdit, promo_push: value })}
          onSystemPushChange={value => setUserEdit({ ...userEdit, system_push: value })}
        />

        <EmailPasswordEdit
          email={userEdit.email}
          password={userEdit.password}
          inputFocused={userEdit.inputFocused}
          validationErrors={validationErrors}
          emailInputRef={emailInput}
          onEmailChangeText={value => {
            if (validationErrors && validationErrors.email) {
              clearValidationErrorsData('email')
            }
            setUserEdit({ ...userEdit, email: value })
          }}
          onEmailFocus={() => setUserEdit({ ...userEdit, inputFocused: { email: emailInput.current.isFocused() } })}
          onEmailSubmitEditing={() => passwordInput.current.focus()}
          passwordInputRef={passwordInput}
          onPasswordChangeText={value => {
            if (validationErrors && validationErrors.password) {
              clearValidationErrorsData('password')
            }
            setUserEdit({ ...userEdit, password: value })
          }}
          onPasswordFocus={() =>
            setUserEdit({
              ...userEdit,
              inputFocused: { password: passwordInput.current.isFocused() }
            })
          }
          hidePassword={userEdit.hidePassword}
          toggleHidePasswordPress={() =>
            setUserEdit({
              ...userEdit,
              hidePassword: !userEdit.hidePassword
            })
          }
          onPasswordSubmitEditing={() => Keyboard.dismiss()}
        />
      </ScrollView>
    </SafeAreaView>
  )
}

Edit.propTypes = {
  location: PropTypes.shape({ state: PropTypes.shape({ shouldGoBack: PropTypes.bool }) }),
  history: PropTypes.shape({ goBack: PropTypes.func }),
  route: PropTypes.shape({ params: PropTypes.shape({ shouldGoBack: PropTypes.bool }) }),
  navigation: PropTypes.shape({
    goBack: PropTypes.func,
    setOptions: PropTypes.func,
    navigate: PropTypes.func,
    replace: PropTypes.func
  }),
  user: PropTypes.shape({
    role: PropTypes.string,
    image: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    short_bio: PropTypes.string,
    postalcode: PropTypes.string,
    street_number: PropTypes.string,
    street_name: PropTypes.string,
    phone: PropTypes.string,
    second_phone: PropTypes.string,
    email: PropTypes.string,
    angel: PropTypes.shape({}),
    family: PropTypes.shape({}),
    settings: PropTypes.shape({
      promo_mail: PropTypes.number,
      system_mail: PropTypes.number,
      promo_push: PropTypes.number,
      system_push: PropTypes.number
    })
  }).isRequired,
  updatingProfile: PropTypes.bool,
  profileUpdated: PropTypes.bool,
  uploadingImage: PropTypes.bool,
  registrationValidated: PropTypes.bool,
  validationErrors: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    phone: PropTypes.string,
    postalcode: PropTypes.string,
    outside_area: PropTypes.string
  }),
  logOut: PropTypes.func.isRequired,
  updateProfileData: PropTypes.func.isRequired,
  updateProfileImageData: PropTypes.func.isRequired,
  validateRegistrationData: PropTypes.func.isRequired,
  clearValidationErrorsData: PropTypes.func.isRequired,
  setValidationErrorData: PropTypes.func.isRequired
}

Edit.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null,
  updatingProfile: false,
  profileUpdated: false,
  uploadingImage: false,
  registrationValidated: false,
  validationErrors: null
}

const mapStateToProps = ({ User }) => ({
  user: User.user,
  updatingProfile: User.updatingProfile,
  profileUpdated: User.profileUpdated,
  uploadingImage: User.uploadingImage,
  validationErrors: User.validationErrors,
  registrationValidated: User.registrationValidated,
  serverError: User.serverError
})

const mapDispatchToProps = {
  logOut: logout,
  updateProfileData: updateProfile,
  updateProfileImageData: updateProfileImage,
  validateRegistrationData: validateRegistration,
  clearValidationErrorsData: clearValidationErrors,
  setValidationErrorData: setValidationError
}

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
