import {
  MembershipsName,
  MembershipsTerm,
  MEMBERSHIP_TYPES,
  LandingScreen
} from '@charlycares/shared'
import { Angel } from './Angel'
import { Family } from './Family'
import { UserRoles } from './User'

/**
 * @deprecated use User when able
 */
export interface OldUser {
  id: number
  angel?: Angel
  family?: Family
  first_name: string
  last_name: string
  email: string
  account_number: string
  street_name: string
  street_number: string
  postalcode: string
  birthdate: string
  image: string
  city: string
  phone: string
  credit: string
  personal_angel_code: string
  current_landing_screen: LandingScreen
  settings: {
    promo_mail: 1 | 0
    system_mail: 1 | 0
    promo_push: 1 | 0
    system_push: 1 | 0
  }
  membership?: {
    current_state: MembershipsName
    previous_state: MembershipsName
    valid_until: string
    payment_term: MembershipsTerm
    payment_method: string
  }
  membership_type: MEMBERSHIP_TYPES
  hasMembership: boolean
  has_membership: boolean
  role: UserRoles
  is_accepted: boolean
  has_active_timer: boolean
  has_pending_bookings: boolean
  area_id: UserAreaId
  locale: string
  waitinglist: boolean
  referral: any
  area: any
  has_outstanding_payments: boolean
  languages: {
    dutch: boolean
    english: boolean
    french: boolean
    german: boolean
    spanish: boolean
    italian: boolean
  }
  payments_total: number
}

export enum UserAreaId {
  OUTSIDE_THE_AREA
}
