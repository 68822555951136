import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../elements';
import InputView from './InputView';
import theme from '../../styles/theme';

const LabeledInput = ({
  flexDirection,
  alignItems,
  width,
  height,
  margins,
  paddings,
  borderTopWidth,
  borderBottomWidth,
  borderLeftWidth,
  borderColor,
  labelText,
  labelColor,
  labelAlign,
  textAlign,
  fontWeight,
  fontFamily,
  fontSize,
  ...inputViewProps
}) => (
  <InputView
    flexDirection={flexDirection}
    alignItems={alignItems}
    width={width}
    height={height}
    margins={margins}
    paddings={paddings}
    borderTopWidth={borderTopWidth}
    borderBottomWidth={borderBottomWidth}
    borderLeftWidth={borderLeftWidth}
    borderColor={borderColor}
    textAlign={textAlign}
    {...inputViewProps}>
    <Text
      width="100%"
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontWeight={fontWeight}
      textAlign={labelAlign}
      color={labelColor}>
      {labelText}
    </Text>
  </InputView>
);

LabeledInput.propTypes = {
  flexDirection: PropTypes.string,
  alignItems: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  margins: PropTypes.string,
  paddings: PropTypes.string,
  borderTopWidth: PropTypes.number,
  borderBottomWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderLeftWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderColor: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  labelColor: PropTypes.string,
  labelAlign: PropTypes.string,
  textAlign: PropTypes.string,
  fontWeight: PropTypes.string,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

LabeledInput.defaultProps = {
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: 'auto',
  height: 'auto',
  margins: '0',
  paddings: '0',
  borderTopWidth: 0,
  borderBottomWidth: 0,
  borderLeftWidth: 0,
  borderColor: theme.colors.grey08,
  labelColor: theme.inputLabelFontColor,
  labelAlign: 'left',
  textAlign: 'left',
  fontWeight: theme.inputLabelFontWeight,
  fontFamily: theme.fontFamilyBase,
  fontSize: theme.fontSize.xs
};

export default LabeledInput;
