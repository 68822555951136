import React from 'react'
import { Box, Button, Heading, HStack, Image, Text, VStack } from 'native-base'
import { useTranslation } from 'react-i18next'
import { CharlyIcon, iconsMap, LandingLayout } from '@charlycares/ui'
import {
  isWeb,
  MembershipsName,
  TrialMembershipBannerEN,
  TrialMembershipBannerNL,
  useRouter
} from '@charlycares/shared'
import { useGetAvailableMembershipsQuery } from '@charlycares/data-access'
import moment from 'moment'

export default function BookingIsAlmostSentScreen() {
  const { navigate } = useRouter()
  const { t, i18n } = useTranslation()

  const { data: availableMemberships } = useGetAvailableMembershipsQuery()
  const membershipName = availableMemberships
    ? (Object.keys(availableMemberships)[0] as MembershipsName)
    : ('full_service' as MembershipsName)
  const days = moment.duration(availableMemberships?.[membershipName].trial?.interval).days()
  const trialBanner = i18n.language === 'nl_NL' ? TrialMembershipBannerNL : TrialMembershipBannerEN

  const usps = (): { text: string; icon: keyof typeof iconsMap; color: string }[] => {
    if (membershipName === 'pet_service') {
      return [
        {
          text: t('bookingIsAlmostSentPetService.usp1'),
          icon: 'icn-send',
          color: 'primary.300'
        },
        {
          text: t('bookingIsAlmostSentPetService.usp2', { days }),
          icon: 'icn-gift',
          color: 'primary.300'
        }
      ]
    }

    return [
      {
        text: t('bookingIsAlmostSentFullService.usp1'),
        icon: 'icn-send',
        color: 'primary.300'
      },
      {
        text: t('bookingIsAlmostSentFullService.usp2', { days }),
        icon: 'icn-gift',
        color: 'primary.300'
      }
    ]
  }

  return (
    <LandingLayout
      withLogo
      headerLeft={
        <CharlyIcon
          name="icn-nav-left"
          size="32"
          color="white"
          onPress={() => navigate('BookingsScreen', `/bookings`)}
        />
      }
      BottomComponent={
        <Box w="100%" p="20px" mt="auto">
          <Button
            onPress={() =>
              navigate('WhatWeOfferMembershipScreen', '/what-we-offer-with-membership', { availableMemberships })
            }
            bgColor="white"
            _text={{ color: 'secondary.400', fontSize: '16px', fontWeight: 600 }}
          >
            {t('bookingIsAlmostSent.button')}
          </Button>
        </Box>
      }
    >
      <Box w="100%" mt="30px">
        <Image
          source={trialBanner as any}
          alt="Trial Membership Banner"
          maxHeight="180px"
          height={isWeb ? '180px' : '120px'}
          resizeMode="contain"
          width="100%"
        />
      </Box>
      <Box w="100%" p="20px" mt="30px">
        <Heading color="white" fontSize="22px" fontWeight="600">
          {t('bookingIsAlmostSent.heading')}
        </Heading>
      </Box>

      <VStack space="20px" p="20px">
        {usps().map(({ text, icon, color }) => (
          <HStack space="10px" key={text}>
            <CharlyIcon name={icon} size="28px" color={color} />
            <Text fontSize="18px" color="white" fontWeight="600" flex={1}>
              {text}
            </Text>
          </HStack>
        ))}
      </VStack>
    </LandingLayout>
  )
}

BookingIsAlmostSentScreen.navigationOptions = () => ({
  headerShown: false
})
