import React, { useEffect, useState } from 'react'
import { Button } from 'native-base'
import { Box, Center, Heading, Icon, IconDescription, Row, Text, Card } from '@charlycares/ui'
import { links, isIos, isWeb } from '@charlycares/shared'
import { Share } from 'react-native'
import { useAppSelector } from '@charlycares/data-access'
import { useTheme } from 'styled-components/native'
import { useTranslation } from 'react-i18next'

export default function InviteAngelCard() {
  const { t } = useTranslation()
  const { colors, fonts } = useTheme()
  const personal_code = useAppSelector(state => state.User.user?.personal_angel_code)
  const first_name = useAppSelector(state => state.User.user?.first_name)
  const [isCopied, setIsCopied] = useState(false)

  const onShareCode = () => {
    if (isWeb) {
      setIsCopied(true)

      navigator.clipboard.writeText(personal_code || '')

      return
    }

    Share.share(
      {
        title: t('sharePersonalAngelCodeTitle'),
        message: t('sharePersonalAngelCodeMessage', {
          first_name,
          personal_code,
          link: isIos ? links.store.ios : links.store.android
        })
      },
      { subject: t('sharePersonalAngelCodeTitle') }
    )
  }

  useEffect(() => {
    let timeout: any

    if (isCopied) {
      timeout = setTimeout(() => {
        setIsCopied(false)
      }, 1000)
    }

    return () => timeout && clearTimeout(timeout)
  }, [isCopied])

  return (
    <Card>
      <Row align="center" justify="space-between">
        <Heading size={18}>Nodig je eigen oppas uit!</Heading>
        <Icon color={colors.secondary} size={32} name="favorites" />
      </Row>
      <Text mt="10px">
        Is jouw vaste oppas (nog) niet bij ons bekend? Nodig haar uit zodat je haar via ons platform kan uitbetalen.
      </Text>

      <Box mt="15px" bg={colors.grey09} p="12px">
        <IconDescription
          font={fonts.openSans.normal}
          name="icn-small-info"
          text="Via Whatsapp, SMS of E-mail stuur je een link waarmee je jouw eigen oppas zich kan aanmelden."
        />
      </Box>

      {isWeb && (
        <Center p="40px 0">
          <Text>{t('shareCodeDescription')}</Text>
          <Heading mt="15px" align="center">
            {personal_code}
          </Heading>
        </Center>
      )}

      <Button w="100%" mt="15px" onPress={onShareCode} alignItems="center" justifyContent="center">
        {isWeb && (isCopied ? t('copiedBusinessCodeBtn') : t('copyBusinessCodeBtn'))}
        {!isWeb && t('shareBusinessCodeBtn')}

        {isCopied && <Icon name="icn-check" color="#FFF" />}
      </Button>
    </Card>
  )
}
