import React from 'react'
import { Box, Flex, IBoxProps, Stack, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import { CharlyIcon, iconsMap } from '@charlycares/ui'

export default function ServicesWeOffer(boxProps: IBoxProps) {
  const { t } = useTranslation()

  const data = (): { text: string; icon: keyof typeof iconsMap; color: string }[] => {
    return [
      {
        text: 'servicesWeOffer.kids',
        icon: 'icn-kids',
        color: 'primary.300'
      },
      {
        text: 'servicesWeOffer.tutoring',
        icon: 'tutoring',
        color: 'primary.300'
      },
      {
        text: 'servicesWeOffer.elderly',
        icon: 'elderly-care',
        color: 'primary.300'
      },
      {
        text: 'servicesWeOffer.pet',
        icon: 'icn-walking-the-dog',
        color: 'primary.300'
      }
    ]
  }

  return (
    <Box p="20px" flex={1} {...boxProps}>
      {data().map((item, index) => (
        <Stack key={index}>
          <Flex mt="20px" direction="row" borderRadius="5px" align="center">
            <CharlyIcon mr="10px" name={item.icon} size={28} color={item.color} />
            <Text fontSize="18px" fontWeight="600" color="white">
              {t(item.text)}
            </Text>
          </Flex>
        </Stack>
      ))}
    </Box>
  )
}
