import React from 'react'
import { Box, Description, ExtendedTextProps, Icon, iconsMap } from '@charlycares/ui'

type IconTextProps = ExtendedTextProps & {
  icon?: () => React.ReactNode
  title?: string
  text: string
  margins?: string | number
  paddings?: string | number
  titleProps?: ExtendedTextProps
  name?: keyof typeof iconsMap
  iconSize?: number
  iconColor?: string
}

/**
 * @deprecated
 */
export default function IconDescription({
  text,
  title,
  icon,
  name,
  margins,
  paddings,
  titleProps,
  iconColor,
  iconSize,
  ...textProps
}: IconTextProps) {
  return (
    <Box row m={margins} p={paddings}>
      {icon?.()}
      {name && <Icon name={name} size={iconSize} color={iconColor} />}

      <Description
        flex={1}
        m="0 10px"
        title={title}
        text={text}
        titleProps={{ size: 16, margin: 0, ...titleProps }}
        textProps={textProps}
      />
    </Box>
  )
}
