import React from 'react'
import { Card, ScreenLayout } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { HStack, Spacer, Text, VStack } from 'native-base'
import { IBookingDetails, useRouter } from '@charlycares/shared'
import BookingCostSummary from './common/BookingCostSummary'

export default function BookingEstimatedCostSummaryScreen() {
  const { t } = useTranslation()
  const { getParam } = useRouter()
  const booking = getParam('booking') as IBookingDetails

  return (
    <ScreenLayout
      supportButton
      title={t('booking')}
      subtitle={booking.obscured_id}
      edges={['right', 'left']}
      _bottomContainer={{
        backgroundColor: 'white',
        paddingBottom: '30px'
      }}
    >
      <Card>
        <HStack alignItems="center" py="10px" w="100%">
          <VStack>
            <Text fontWeight="600" fontSize="16px">
              {t('totalEstimatedCosts')}
            </Text>
          </VStack>
          <Spacer />
        </HStack>
        <BookingCostSummary
          dayRatePerHour={booking.day_rate_per_hour}
          nightRatePerHour={booking.night_rate_per_hour}
          insuranceFeePerHour={booking.insurance_fee_per_hour}
          bookingRate={booking.rate}
          isLoading={false}
        />
      </Card>
    </ScreenLayout>
  )
}

BookingEstimatedCostSummaryScreen.navigationOptions = {
  headerShown: false
}
