import React, { useState } from 'react'
import { Card, CharlyIcon } from '@charlycares/ui'
import { Text, Center, IBoxProps, Pressable } from 'native-base'
import { useTranslation } from 'react-i18next'
import { IBookingDetails } from '@charlycares/shared'

type Props = IBoxProps & {
  booking: IBookingDetails
}

export default function BookingMessage({ booking, ...boxProps }: Props) {
  const { t } = useTranslation()

  const [showMessage, setShowMessage] = useState(true)

  return (
    <Card p="0" {...boxProps}>
      <Center p="20px">
        <Pressable
          alignItems="center"
          onPress={() => setShowMessage(!showMessage)}
          justifyContent="space-between"
          flexDir="row"
          w="100%"
        >
          <Text fontSize="14px" color="primary.400">
            {t('messageOfFamily')}
          </Text>
          <CharlyIcon name={`icn-nav-${showMessage ? 'up' : 'down'}`} color="primary.400" size={25} />
        </Pressable>

        {showMessage && (
          <Text mt="15px" fontSize="15px" w="100%" flex={1}>
            {booking.personal_message}
          </Text>
        )}
      </Center>
    </Card>
  )
}
