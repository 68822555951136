import React, { useEffect } from 'react'
import { Card, Layout, Title } from '@charlycares/ui'
import { Box, Button, CheckIcon, ChevronRightIcon, HStack, Pressable, Spacer, Text } from 'native-base'
import {
  useChangeMembershipMutation,
  useGetAvailableMembershipsQuery,
  useGetMembershipsQuery,
  useGetUserQuery,
  useGetWalletsQuery
} from '@charlycares/data-access'
import {
  AVAILABLE_MEMBERSHIPS,
  decomposeMembershipType,
  formatMoney,
  MembershipsName,
  MembershipsTerm,
  useRouter,
  WalletType
} from '@charlycares/shared'
import { useTranslation } from 'react-i18next'
import { Alert } from 'react-native'

export default function ReactivateMembership() {
  const { t } = useTranslation()
  const { navigate } = useRouter()
  const { data: availableMemberships, isLoading: loadingAvailableMemberships } = useGetAvailableMembershipsQuery()
  const { data: user, isLoading: loadingUser } = useGetUserQuery()
  const { data: memberships, isLoading: loadingMemberships } = useGetMembershipsQuery()
  const { data: wallets, isLoading: loadingWallets } = useGetWalletsQuery()
  const [changeMembership, { isSuccess, data: changedMembershipResponse }] = useChangeMembershipMutation()
  let membershipUsps: string[] = []
  let feePrice = ''
  let insurancePrice = ''

  useEffect(() => {
    if (isSuccess && changedMembershipResponse) {
      Alert.alert(
        t('reactivateMembership.reactivatedTitle'),
        t('reactivateMembership.reactivatedDescription'),
        [{ text: t('ok'), onPress: () => navigate('FamilyDashboardScreen', '/') }],
        { cancelable: false }
      )
    }
  }, [isSuccess, t, changedMembershipResponse])

  if (!memberships || !memberships[0]) {
    return null
  }

  const { membershipPlan, membershipTerm } = decomposeMembershipType(memberships[0].type)

  const selectedMembership = availableMemberships?.full_service.monthly

  if (selectedMembership) {
    membershipUsps = [
      t('reactivateMembership.usp1'),
      t('reactivateMembership.usp2', {
        membershipPrice: formatMoney(selectedMembership.costs),
        membershipType: membershipPlan
      }),
      t('reactivateMembership.usp3')
    ]

    feePrice = formatMoney(selectedMembership.fee_costs)
    insurancePrice = formatMoney(selectedMembership.insurance_costs)
  }

  const setupBankAccount = () => {
    if (selectedMembership) {
      navigate('SetupBankAccount', '/setup-bank-account', {
        membershipType: selectedMembership.type
      })

      return
    }
  }

  const reactivateMembership = () => {
    if (selectedMembership) {
      changeMembership({ membership_type: selectedMembership.type })
    }
  }

  const startMembership = () => {
    const personalWallet = wallets?.find(wallet => {
      return wallet.wallet_type === WalletType.PERSONAL && wallet.active
    })

    if (personalWallet) {
      Alert.alert(
        t('reactivateMembership.bankAccount.alert.title'),
        t('reactivateMembership.bankAccount.alert.description', {
          accountNumber: personalWallet.account_number
        }),
        [
          { text: t('reactivateMembership.bankAccount.change'), onPress: () => setupBankAccount(), style: 'cancel' },
          { text: t('reactivateMembership.bankAccount.agree'), onPress: () => reactivateMembership() }
        ],
        { cancelable: false }
      )
    }
  }

  console.log(t('reactivateMembership.usp1'))

  return (
    <Layout
      title={t('reactivateMembership.title')}
      hideBack
      scrollDisabled
      loading={loadingAvailableMemberships || loadingUser || loadingMemberships || loadingWallets}
    >
      <Card>
        <Title>
          {t('reactivateMembership.uspTitle', {
            firstName: user?.profile.first_name ?? ''
          })}
        </Title>

        <Box margin={'10px'}>
          {membershipUsps.map(text => (
            <HStack space={2} key={text} marginBottom="10px">
              <CheckIcon size="5" mt="0.5" color="secondary.400" />
              <Text fontSize="md" color="gray.900" paddingRight="40px">
                {text}
              </Text>
            </HStack>
          ))}
        </Box>

        <Button backgroundColor="secondary.400" marginBottom="20px" width="100%" onPress={() => startMembership()}>
          {t('reactivateMembership.reactivateButton')}
        </Button>

        <Text fontSize="xs" color="gray.800" fontWeight={300}>
          {t('reactivateMembership.furtherInformation', {
            feePrice,
            insurancePrice
          })}
        </Text>
      </Card>
    </Layout>
  )
}
