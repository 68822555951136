import React from 'react'
import { BoxProps, ExtendedTextProps, Icon, iconsMap, Row, Text } from '@charlycares/ui'

type IconTextProps = ExtendedTextProps & {
  icon?: () => React.ReactNode
  title?: string
  text?: string
  children?: React.ReactNode
  name?: keyof typeof iconsMap
  iconSize?: number
  iconColor?: string
  fontSize?: number | string
  boxProps?: BoxProps
  m?: string | number
  p?: string | number
}

/**
 * @deprecated
 */
export default function IconText({
  text,
  icon,
  name,
  iconColor,
  iconSize,
  color,
  fontSize,
  boxProps,
  p,
  m,
  children,
  ...textProps
}: IconTextProps) {
  return (
    <Row p={p} m={m} {...boxProps}>
      {icon?.()}
      {name && <Icon name={name} size={iconSize} color={iconColor} />}

      <Text size={fontSize} ml="10px" {...textProps}>
        {children || text}
      </Text>
    </Row>
  )
}

IconText.defaultProps = {
  iconSize: 32
}
