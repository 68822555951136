import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { Text } from '../../elements';
import { IconButton } from '../Buttons';
import { PickerWeb } from '../Pickers';
import { isWeb, roundMonetaryValue } from '../../utils/functions';
import colors from '../../styles/colors';

import arrowDownIcon from '../../../assets/icons/icn-nav-down.png';

const View = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 15px 5px 15px;
`;

const AngelEarningsPerYear = ({
  options,
  selectedYear,
  earnings,
  onArrowPress,
  setSelectedYear
}) => {
  const [t] = useTranslation();

  return (
    <View>
      <Text paddings="10px 0px 0px 0px">
        {`${t('paymentsTotalEarnings')} ${selectedYear}: € ${roundMonetaryValue(earnings)}`}
      </Text>

      {!isWeb ? (
        <IconButton
          iconSource={arrowDownIcon}
          iconWidth="24px"
          iconHeight="24px"
          iconColor={colors.secondary}
          onPress={onArrowPress}
        />
      ) : (
        <PickerWeb
          top="145px"
          options={options}
          value={selectedYear}
          onValueChange={setSelectedYear}
        />
      )}
    </View>
  );
};

AngelEarningsPerYear.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedYear: PropTypes.string.isRequired,
  earnings: PropTypes.string.isRequired,
  onArrowPress: PropTypes.func.isRequired,
  setSelectedYear: PropTypes.func.isRequired
};

export default AngelEarningsPerYear;
