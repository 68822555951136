import React, { useRef } from 'react'
import { Box, Text, Button, useTheme, IBoxProps, IButtonProps } from 'native-base'
import { AngelBabysittingExperience, babysittingExperience } from '@charlycares/shared'
import { useTranslation } from 'react-i18next'

import { CharlyIcon } from '../icons'
import Picker from './Picker'

type Props = IBoxProps & {
  label?: string
  value?: AngelBabysittingExperience
  onChange?: (value: AngelBabysittingExperience) => void
  _button?: IButtonProps
}

const SittingExperiencePicker = ({ label, value, onChange, _button, ...boxProps }: Props) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const pickerRef = useRef<any>()

  const selectedExp = babysittingExperience.find(obj => obj.value === value)

  const onExperienceChange = (label: string) => {
    const exp = babysittingExperience.find(obj => t(obj.label) === label)?.value
    if (exp !== undefined) {
      onChange?.(exp)
    }
  }

  return (
    <Box flexDir="row" alignItems="center" w="100%" {...boxProps}>
      <Text fontSize="15px" flex={1}>
        {label || t('sittingMinAgeLabel')}
      </Text>

      <Button
        onPress={() => pickerRef.current?.open()}
        variant={'text'}
        endIcon={<CharlyIcon name="icn-nav-right" size={20} color={colors.gray[400]} />}
        {..._button}
      >
        {selectedExp?.key ? t(selectedExp?.key) : t('select')}
      </Button>

      <Picker
        ref={pickerRef}
        value={selectedExp ? t(selectedExp?.label) : undefined}
        // translation is bad practice, but it's the only way to make it work rn using legacy picker
        options={babysittingExperience.map(obj => t(obj.label))}
        onConfirmPress={(exp: string) => onExperienceChange(exp)}
      />
    </Box>
  )
}

export default SittingExperiencePicker
