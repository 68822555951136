import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../elements';
import { IconButton } from '../Buttons';
import { TextView } from '../Texts';
import InputView from './InputView';
import theme from '../../styles/theme';

const IconButtonLabeledInput = ({
  margins,
  paddings,
  borderLeftWidth,
  borderLeftColor,
  backgroundColor,
  labelText,
  iconSource,
  iconColor,
  onIconPress,
  validationMessage,
  ...inputProps
}) => (
  <TextView
    flexDirection="column"
    alignItems="flex-start"
    margins={margins}
    paddings={paddings}
    borderLeftWidth={borderLeftWidth}
    borderColor={borderLeftColor}
    text={labelText}
    textColor={theme.inputLabelFontColor}
    fontWeight={theme.inputLabelFontWeight}
    fontSize={theme.inputLabelFontSize}>
    <InputView
      flexDirection="row-reverse"
      borderBottomWidth="1px"
      inputFlex={1}
      showUnderline={false}
      inputBackgroundColor={backgroundColor}
      {...inputProps}>
      <IconButton
        paddings="5px"
        height="44px"
        borderRadius="0px"
        backgroundColor={backgroundColor}
        iconColor={iconColor}
        iconSource={iconSource}
        onPress={onIconPress}
      />
    </InputView>
    {validationMessage && (
      <Text fontSize="12px" color={theme.colors.alert}>
        {validationMessage}
      </Text>
    )}
  </TextView>
);

IconButtonLabeledInput.propTypes = {
  margins: PropTypes.string,
  paddings: PropTypes.string,
  borderLeftWidth: PropTypes.number,
  borderLeftColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  iconSource: PropTypes.node.isRequired,
  iconColor: PropTypes.string,
  onIconPress: PropTypes.func.isRequired,
  validationMessage: PropTypes.string
};

IconButtonLabeledInput.defaultProps = {
  iconColor: 'black',
  margins: '0px',
  paddings: '0px',
  borderLeftWidth: 0,
  borderLeftColor: theme.colors.white,
  validationMessage: null,
  backgroundColor: 'transparent'
};

export default IconButtonLabeledInput;
