import { useTranslation } from 'react-i18next'
import {
  capitalize,
  decomposeMembershipType,
  formatDate,
  formatMoney,
  getMembershipMonthlyPlan,
  getMembershipYearlyPlan,
  MEMBERSHIP_TYPES,
  MembershipsName,
  SUBSCRIPTION_STATUS
} from '@charlycares/shared'
import { Card, ScreenLayout } from '@charlycares/ui'
import { Box, Button, Center, CheckIcon, Heading, HStack, Pressable, Spacer, Text, VStack } from 'native-base'
import React, { useEffect, useState } from 'react'
import Animated from 'react-native-reanimated'
import {
  useChangeMembershipMutation,
  useGetAvailableMembershipsQuery,
  useGetMembershipsQuery,
  useGetSubscriptionQuery
} from '@charlycares/data-access'
import { useAlert } from '../../hooks'

export default function ChangeMembershipScreen() {
  const { t } = useTranslation()
  const alert = useAlert()
  const { data: availableMemberships } = useGetAvailableMembershipsQuery()
  const { data: subscription, isLoading: subscriptionsLoading } = useGetSubscriptionQuery()
  const { data: memberships, isLoading: membershipsLoading, refetch: refetchMembership } = useGetMembershipsQuery()
  const [changeMembership, { isLoading: changingMembership, isSuccess: membershipChanged }] =
    useChangeMembershipMutation()

  const membershipName = availableMemberships
    ? (Object.keys(availableMemberships)[0] as MembershipsName)
    : ('full_service' as MembershipsName)

  const membershipTypeMonthly = getMembershipMonthlyPlan(membershipName)
  const membershipTypeYearly = getMembershipYearlyPlan(membershipName)

  const isCancelled = subscription?.status === SUBSCRIPTION_STATUS.CANCELLED
  const { membershipTerm } = decomposeMembershipType(memberships?.[0]?.type)
  const isTrial = memberships?.[0]?.type.includes('trial') && subscription?.membership_type.includes('trial')
  const isBasic = memberships?.[0]?.type.includes('basic') && subscription?.membership_type.includes('basic')
  const isFlexible = memberships?.[0]?.type.includes('flexible') && subscription?.membership_type.includes('flexible')
  const upcomingChange =
    (memberships?.[0].type !== subscription?.membership_type && !membershipsLoading && !subscriptionsLoading) || isTrial
  const upcomingMembership =
    isTrial || !subscription || isBasic || membershipsLoading ? membershipTypeMonthly : subscription.membership_type
  const { membershipTerm: upcomingTerm } = decomposeMembershipType(upcomingMembership)
  const [selectedMembershipType, setSelectedMembershipType] = useState<MEMBERSHIP_TYPES>(upcomingMembership)
  const term = selectedMembershipType === membershipTypeYearly ? 'yearly' : 'monthly'

  const price = formatMoney(availableMemberships?.[membershipName][term].costs)
  const insurancePrice = formatMoney(availableMemberships?.[membershipName][term].insurance_costs)
  const feePrice = formatMoney(availableMemberships?.[membershipName][term].fee_costs)
  const [buttonPosition, setButtonPosition] = useState('0%')

  useEffect(() => {
    setSelectedMembershipType(upcomingMembership)
  }, [upcomingMembership])
  useEffect(() => {
    setButtonPosition(selectedMembershipType === membershipTypeMonthly ? '0%' : '50%')
  }, [selectedMembershipType, setButtonPosition])

  useEffect(() => {
    if (membershipChanged) {
      alert.show(t('changeMembershipScreen.successTitle'), t('changeMembershipScreen.successMessage'))
      refetchMembership()
    }
  }, [membershipChanged])

  const toggleButton = () => {
    setSelectedMembershipType(
      selectedMembershipType === membershipTypeMonthly ? membershipTypeYearly : membershipTypeMonthly
    )
  }

  const changeMembershipAlert = () => {
    alert.show(t('changeMembershipScreen.saveMembershipTitle'), t('changeMembershipScreen.saveMembershipMessage'), [
      { text: t('changeMembershipScreen.saveMembershipCancelButton'), style: 'default' },
      {
        text: t('changeMembershipScreen.saveMembershipProceedButton'),
        style: 'cancel',
        isPreferred: true,
        onPress: () => {
          if (selectedMembershipType) {
            changeMembership({ membership_type: selectedMembershipType })
          }
        }
      }
    ])
  }

  const reactivateMembershipAlert = () => {
    alert.show(
      t('changeMembershipScreen.reactivateMembershipTitle'),
      t('changeMembershipScreen.reactivateMembershipMessage'),
      [
        { text: t('changeMembershipScreen.reactivateMembershipCancelButton'), style: 'default' },
        {
          text: t('changeMembershipScreen.reactivateMembershipProceedButton'),
          style: 'cancel',
          isPreferred: true,
          onPress: () => {
            if (selectedMembershipType) {
              changeMembership({ membership_type: selectedMembershipType })
            }
          }
        }
      ]
    )
  }

  const membershipUsps =
    membershipName === 'pet_service'
      ? [
          t('changeMembershipScreenPetService.usp1', { term: capitalize(t(term)) }),
          t('changeMembershipScreenPetService.usp2'),
          t('changeMembershipScreenPetService.usp3'),
          t('changeMembershipScreenPetService.usp4'),
          t('changeMembershipScreenPetService.usp5'),
          t('changeMembershipScreenPetService.usp6'),
          t('changeMembershipScreenPetService.usp7'),
          t('changeMembershipScreenPetService.usp8'),
          t('changeMembershipScreenPetService.usp9')
        ]
      : [
          t('changeMembershipScreenFullService.usp1', { term: capitalize(t(term)) }),
          t('changeMembershipScreenFullService.usp2'),
          t('changeMembershipScreenFullService.usp3'),
          t('changeMembershipScreenFullService.usp4', { insurancePrice }),
          t('changeMembershipScreenFullService.usp5'),
          t('changeMembershipScreenFullService.usp6'),
          t('changeMembershipScreenFullService.usp7'),
          t('changeMembershipScreenFullService.usp8'),
          t('changeMembershipScreenFullService.usp9')
        ]

  return (
    <ScreenLayout
      title={t('changeMembershipScreen.title')}
      subtitle={
        isCancelled
          ? t('changeMembership.subTitle.cancelled', {
              validUntil: formatDate(subscription?.renews_at)
            })
          : `${t('changeMembership.subTitle.active', {
              renewsAt: formatDate(subscription?.renews_at)
            })}`
      }
      headerRight={
        (upcomingMembership !== selectedMembershipType || isBasic || isFlexible) && !isCancelled ? (
          <Button variant="text" onPress={changeMembershipAlert} isLoading={changingMembership}>
            {t('save')}
          </Button>
        ) : null
      }
      edges={['left', 'right']}
    >
      {upcomingChange && !isCancelled && (
        <Box bg={'alert.success.300'} mt="-10px" pt="20px" textAlign="center" py="10px">
          <Center>
            <Heading>
              {t('membership.' + memberships?.[0]?.type)} {t('membershipTerm.' + membershipTerm)}
            </Heading>
            <Text>
              {t('changeMembership.upcomingChange.current.type', {
                membershipType: t('membership.' + memberships?.[0]?.type) + ' ' + t('membershipTerm.' + membershipTerm)
              })}
            </Text>
            <Text>
              {t('changeMembership.upcomingChange.current.endDate', {
                endDate: formatDate(memberships?.[0]?.ends_at)
              })}
            </Text>
            <Text pt="20px">
              {t('changeMembership.upcomingChange.upcoming.type', {
                membershipType: t('membership.' + upcomingMembership) + ' ' + t('membershipTerm.' + upcomingTerm)
              })}
            </Text>
            <Text>
              {t('changeMembership.upcomingChange.upcoming.startDate', {
                startDate: formatDate(subscription?.renews_at)
              })}
            </Text>
          </Center>
        </Box>
      )}
      {isBasic ||
        (isFlexible && (
          <Box bg={'alert.success.300'} mt="-10px" pt="20px" textAlign="center" py="10px">
            <Center>
              <Heading>{t('changeMembershipScreen.newMembership.heading')}</Heading>
              <Text>{t('changeMembershipScreen.newMembership.subHeading.' + (isBasic ? 'basic' : 'flexible'))}</Text>
            </Center>
          </Box>
        ))}
      <Card>
        <Center height="40px">
          <Pressable onPress={() => toggleButton()} height="100%" width="70%" position="relative">
            <HStack bgColor="gray.alpha900.15" height="32px" rounded="lg">
              <Animated.View style={{ left: buttonPosition, width: '50%', position: 'absolute' }}>
                <Box width="100%" mt="2px" bgColor="white" height="27px" rounded="lg" />
              </Animated.View>
            </HStack>
            <HStack position="absolute" top="6px" width="100%">
              <Box textAlign="center" width="50%">
                <Text fontSize="13px" lineHeight="17.7px" color="text.dark.400" fontWeight="600" textAlign="center">
                  Monthly
                </Text>
              </Box>
              <Spacer />
              <Box textAlign="center" width="50%">
                <Text fontSize="13px" lineHeight="17.7px" color="text.dark.400" fontWeight="600" textAlign="center">
                  Yearly
                </Text>
              </Box>
            </HStack>
            <HStack pt="3px" width="100%">
              <Spacer />
              <Box textAlign="center" width="50%">
                <Text fontSize="13px" lineHeight="17.7px" color="primary.400" fontWeight="400" textAlign="center">
                  {t('changeMembershipScreen.discount')}
                </Text>
              </Box>
            </HStack>
          </Pressable>
        </Center>
        <HStack mt="30px">
          <Text fontWeight="500" fontSize="16px">
            {t('changeMembershipScreen.membership')}
          </Text>
          <Spacer />
          <Text fontWeight="400" fontSize="16px">
            {t('changeMembershipScreen.price.' + term, { price: price })}
          </Text>
        </HStack>
        {isCancelled ? (
          <Button my="20px" onPress={reactivateMembershipAlert}>
            {t('changeMembershipScreen.reactivate')}
          </Button>
        ) : null}
        <VStack space="20px" marginTop="20px" marginLeft="10px">
          {membershipUsps.map(text => (
            <HStack space={'8px'} key={text}>
              <CheckIcon size={'32px'} color="alert.success.400" />
              <Text fontSize="16px" color="gray.900" paddingRight="40px">
                {text}
              </Text>
            </HStack>
          ))}
        </VStack>
        <Text fontSize="xs" color="gray.800" fontWeight={300} mt="30px">
          {t('changeMembershipScreen.furtherInformation', {
            feePrice,
            insurancePrice
          })}
        </Text>
      </Card>
    </ScreenLayout>
  )
}
ChangeMembershipScreen.navigationOptions = () => ({
  headerShown: false
})
