import React, { useCallback, useEffect, useState } from 'react'
import { Box, Center, Image, Spinner, Text, useTheme } from 'native-base'
import { Animated } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useFetchTranslation } from '@charlycares/shared'
import { useDispatch } from 'react-redux'

const AnimatedLogoIcon = Animated.createAnimatedComponent(Image)
const AnimatedCircle = Animated.createAnimatedComponent(Box)

const animatedValue = new Animated.Value(0)
const secondAnimatedValue = new Animated.Value(0)

const LoadingScreen = () => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { loading: loadingTranslation } = useFetchTranslation()

  const [showCircle, setShowCircle] = useState(false)
  const [status, setStatus] = useState('')
  const [updateProgress, setUpdateProgress] = useState(0)

  const scaleValue = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 400]
  })
  const rotateValue = secondAnimatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '90deg']
  })
  const translateValue = secondAnimatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 58]
  })

  const checkForTheUpdate = useCallback(() => {
    //
  }, [])

  return (
    <Center flex={1}>
      <Spinner />
    </Center>
  )
}

export default LoadingScreen
