import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ContainerView, Heading, Text } from '../../elements'
import { TextButton, TextIconButton } from '../Buttons'
import colors from '../../styles/colors'

import arrowRight from '../../../assets/icons/icn-nav-right.png'

const getAgelQuestions = (locale, t) => [
  {
    title: t('dashboardScreensFaqQuestion1'),
    link: `https://www.charlycares.com/${locale}angel-agenda${locale ? '-en' : ''}`
  },
  {
    title: t('dashboardScreensFaqQuestion2'),
    link: `https://www.charlycares.com/${locale ? `${locale}angel-insurance` : 'angel-verzekering'}`
  },
  {
    title: t('dashboardScreensFaqQuestion3'),
    link: `https://www.charlycares.com/${locale}ehbo-angel`
  }
]

const getFamilyQuestions = (locale, t) => [
  {
    title: t('dashboardScreensFaqFamilyQuestion1'),
    link: `https://www.charlycares.com/${locale}screening`
  },
  {
    title: t('dashboardScreensFaqFamilyQuestion2'),
    link: `https://www.charlycares.com/${locale ? `${locale}babysitting-app` : 'oppas-app'}`
  },
  {
    title: t('dashboardScreensFaqFamilyQuestion3'),
    link: `https://www.charlycares.com/${
      locale ? `${locale}angel-liability-insurance` : 'angel-aansprakelijkheidsverzekering'
    }`
  }
]

const Faq = ({ isAngel, locale, onQuestionsPress, onMoreQuestionsPress }) => {
  const [t] = useTranslation()
  const appFeatures = isAngel ? getAgelQuestions(locale, t) : getFamilyQuestions(locale, t)

  return (
    <ContainerView flexDirection="column" alignItems="flex-start" paddings="32px 0" margins="0 20px">
      <Heading xxl ariaLevel={3} text={t('dashboardScreensFaqTitle')} />
      <Text margins="5px 0px 15px 0px" fontSize="16px" color={colors.defaultBtnTextColor}>
        {isAngel ? t('dashboardScreensFaqSubtitle') : t('dashboardScreensFaqFamilySubtitle')}
      </Text>

      {appFeatures.map(({ title, link }, index) => (
        <TextIconButton
          textAlign="left"
          key={title}
          flexDirection="row-reverse"
          margins={index === 1 ? '10px 10px 10px 0px' : '0px 10px 0px 0px'}
          paddings="0px"
          height="auto"
          backgroundColor="transparent"
          text={title}
          fontSize="16px"
          textColor={colors.primaryText}
          icon={arrowRight}
          iconSize="30px"
          iconColor={colors.secondary}
          onPress={() => onQuestionsPress(link)}
        />
      ))}

      <TextButton
        margins="20px 0px 0px 0px"
        borderRightWidth="1px"
        borderLeftWidth="1px"
        borderColor={colors.primary}
        text={t('dashboardScreensFaqMoreQuestions')}
        textColor={colors.primary}
        onPress={onMoreQuestionsPress}
      />
    </ContainerView>
  )
}

Faq.propTypes = {
  isAngel: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  onQuestionsPress: PropTypes.func.isRequired,
  onMoreQuestionsPress: PropTypes.func.isRequired
}

Faq.defaultProps = {
  isAngel: true
}

export default Faq
