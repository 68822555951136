import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../elements';
import { TextView, TextIconView, TextTextView } from '../Texts';
import { TextButton, TextIconButton } from '../Buttons';
import colors from '../../styles/colors';

import rightArrowIcon from '../../../assets/icons/icn-nav-right.png';
import downIcon from '../../../assets/icons/icn-nav-down.png';

const View = styled.View``;
const DetailsView = styled.View`
  padding: 0px 20px 0px 20px;
  border-bottom-width: ${({ borderBottomWidth }) => borderBottomWidth};
  border-color: ${({ borderColor }) => borderColor};
`;
const ButtonsView = styled.View`
  flex-direction: row;
  padding: 10px 0px 10px 0px;
`;
const Separator = styled.View`
  width: '1px';
  height: 100%;
  background-color: ${colors.grey08};
`;

const BookingDetails = ({
  disabled,
  borderBottomWidth,
  borderColor,
  currentState,
  startDate,
  endDate,
  repeat,
  startEndRepeatTextColor,
  showStartEndRepeatArrow,
  message,
  showRepeat,
  isEditable,
  showAllBookingInformation,
  onShowInformationPress,
  onCancelPress,
  onEditPress
}) => {
  const [t] = useTranslation();

  return (
    <DetailsView borderBottomWidth={borderBottomWidth} borderColor={borderColor}>
      <TextView
        justifyContent="space-between"
        paddings="10px 20px 10px 0px"
        margins="0px -20px 0px 0px"
        borderBottomWidth="1px"
        text={t('start')}>
        <TextView text={startDate} textColor={startEndRepeatTextColor} fontSize="16px">
          {showStartEndRepeatArrow && <Icon source={rightArrowIcon} color={colors.grey08} />}
        </TextView>
      </TextView>

      {showAllBookingInformation ? (
        <View>
          <TextView
            justifyContent="space-between"
            paddings="10px 20px 10px 0px"
            margins="0px -20px 0px 0px"
            borderBottomWidth="1px"
            text={t('end')}>
            <TextView text={endDate} textColor={startEndRepeatTextColor} fontSize="16px">
              {showStartEndRepeatArrow && <Icon source={rightArrowIcon} color={colors.grey08} />}
            </TextView>
          </TextView>

          {showRepeat && (
            <TextView
              justifyContent="space-between"
              paddings="10px 0px 10px 0px"
              text={t('repeat')}>
              <TextIconView
                backgroundColor="transparent"
                text={repeat}
                textColor={startEndRepeatTextColor}
                fontSize="16px"
                icon={rightArrowIcon}
                iconColor={colors.grey08}
              />
            </TextView>
          )}

          {message !== '' && (
            <TextTextView
              flexDirection="column"
              alignItems="flex-start"
              margins="0px 0px 20px 0px"
              firstText={t('bookingsScreenPersonalMessage')}
              firstTextFontWeight="600"
              firstTextFontFamily="Martel"
              firstTextFontSize="15px"
              secondText={message}
              secondTextMargins="10px 0px 0px 0px"
              secondTextColor={colors.primaryText}
              secondTextFontSize="15px"
              secondTextFontWeight="100"
              secondTextAlign="left"
            />
          )}

          {(currentState === 'accepted' || currentState.includes('pending')) && (
            <ButtonsView>
              <TextButton
                disabled={disabled}
                paddings="10px 20px 10px 20px"
                width="50%"
                height="auto"
                borderRightWidth="1px"
                borderTopWidth={0}
                borderBottomWidth={0}
                borderWidth={0}
                borderRadius="0"
                borderColor={colors.grey08}
                text={t('bookingsScreenCancelBooking')}
                textColor={colors.secondary}
                fontFamily="Martel"
                fontWeight="600"
                onPress={onCancelPress}
              />

              <Separator />

              <TextButton
                disabled={!isEditable || disabled}
                paddings="10px 20px 10px 20px"
                width="50%"
                height="auto"
                text={t('bookingsScreenEditBooking')}
                textColor={isEditable ? colors.secondary : colors.grey08}
                fontWeight="600"
                fontFamily="Martel"
                onPress={onEditPress}
              />
            </ButtonsView>
          )}
        </View>
      ) : (
        <TextIconButton
          disabled={disabled}
          flexDirection="row"
          justifyContent="space-between"
          paddings="15px 0px 15px 0px"
          text={t('bookingsScreenAllBookingInformation')}
          textColor={colors.grey02}
          icon={downIcon}
          iconColor={colors.secondary}
          onPress={onShowInformationPress}
        />
      )}
    </DetailsView>
  );
};

BookingDetails.propTypes = {
  disabled: PropTypes.bool,
  borderBottomWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderColor: PropTypes.string,
  currentState: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  repeat: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  startEndRepeatTextColor: PropTypes.string,
  showStartEndRepeatArrow: PropTypes.bool,
  showRepeat: PropTypes.bool,
  isEditable: PropTypes.bool,
  showAllBookingInformation: PropTypes.bool,
  onShowInformationPress: PropTypes.func,
  onCancelPress: PropTypes.func.isRequired,
  onEditPress: PropTypes.func.isRequired
};

BookingDetails.defaultProps = {
  disabled: false,
  borderBottomWidth: 0,
  borderColor: colors.grey08,
  startEndRepeatTextColor: colors.secondary,
  showStartEndRepeatArrow: true,
  showRepeat: true,
  isEditable: false,
  showAllBookingInformation: true,
  onShowInformationPress: () => {}
};

export default BookingDetails;
