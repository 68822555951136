import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import colors from '../styles/colors';

const Image = styled.Image`
  position: ${({ position }) => position};
  ${({ top }) => top && { top }};
  ${({ left }) => left && { left }};
  ${({ bottom }) => bottom && { bottom }};
  ${({ right }) => right && { right }};
  width: ${({ width, size }) => size || width};
  height: ${({ height, size }) => size || height};
  margin: ${({ margins }) => margins};
  ${({ useTintColor, color }) => useTintColor && `tint-color: ${color}`};
`;

const Icon = ({
  position,
  top,
  left,
  bottom,
  right,
  width,
  height,
  size,
  margins,
  source,
  color,
  useTintColor,
  resizeMode
}) => (
  <Image
    position={position}
    top={top}
    left={left}
    bottom={bottom}
    right={right}
    width={width}
    height={height}
    size={size}
    margins={margins}
    source={source}
    color={color}
    useTintColor={useTintColor}
    resizeMode={resizeMode}
  />
);

Icon.propTypes = {
  position: PropTypes.string,
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  margins: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  source: PropTypes.node.isRequired,
  useTintColor: PropTypes.bool,
  color: PropTypes.string,
  resizeMode: PropTypes.string
};

Icon.defaultProps = {
  position: 'relative',
  top: null,
  left: null,
  bottom: null,
  right: null,
  width: '20px',
  height: '20px',
  size: null,
  margins: '0px',
  color: colors.primaryText,
  useTintColor: true,
  resizeMode: 'stretch'
};

export default Icon;
