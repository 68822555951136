import React from 'react'
import { Layout } from '@charlycares/ui'

import { InviteAngelCard } from './common'

InviteOwnAngelScreen.navigationOptions = () => ({
  headerShown: false
})

export default function InviteOwnAngelScreen() {
  return (
    <Layout title="Invite your own angel">
      <InviteAngelCard />
    </Layout>
  )
}
