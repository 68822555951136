import React from 'react'
import { CharlyIcon, ScreenLayout } from '@charlycares/ui'
import { BookingServiceType, isWeb, useRouter } from '@charlycares/shared'
import { useTranslation } from 'react-i18next'
import { BookingRequestType, useAppSelector } from '@charlycares/data-access'

import { BookingDatesSelector } from '../../common'

const CreateBookingScreen = ({ navigation }: any) => {
  const { t } = useTranslation()
  const { getParam, navigate, goBack } = useRouter()

  const bookingRequestType = getParam('bookingRequestType') as BookingRequestType | undefined

  const { selectedDates } = useAppSelector(state => state.booking.search)

  const isJobPost =
    bookingRequestType?.service_type === BookingServiceType.PET_CARE ||
    bookingRequestType?.service_type === BookingServiceType.ELDERLY_CARE

  const onSearch = () => {
    if (isJobPost) {
      navigate('JobPost', '/job-post', {
        dates: selectedDates,
        bookingRequestType: bookingRequestType
      })
    } else {
      navigate('BookSearchAngelsResult', '/book-search-angels', {
        dates: selectedDates,
        bookingRequestType: bookingRequestType
      })
    }
  }

  return (
    <ScreenLayout
      supportButton
      headerLeft={
        <CharlyIcon
          name="icn-nav-left"
          color="primary.400"
          size={30}
          onPress={() => {
            if (bookingRequestType) {
              navigate('BookingRequestTypesList', '/booking-request-types-list')
            } else if (isWeb) {
              goBack()
            } else {
              navigation.dismiss()
            }
          }}
        />
      }
      _buttonProps={{
        onPress: onSearch,
        children: isJobPost ? t('postJobRequest') : t('findBabysitter'),
        maxH: '40px',
        leftIcon: <CharlyIcon color="white" name="search" size={30} />
      }}
      title={t('bookingRequestTitle')}
    >
      <BookingDatesSelector bookingRequestType={bookingRequestType} />
    </ScreenLayout>
  )
}

CreateBookingScreen.navigationOptions = {
  headerShown: false
}

export default CreateBookingScreen
