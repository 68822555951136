import React, { memo } from 'react'
import moment from 'moment/moment'
import { Avatar, Box, HStack, Pressable, Spacer, Text, VStack } from 'native-base'
import { ChatMessageCollectionItem, useGetUserQuery } from '@charlycares/data-access'
import { useRouter } from '@charlycares/shared'

interface Props {
  item: ChatMessageCollectionItem
}

const ChatListItem = ({ item }: Props) => {
  const { navigate } = useRouter()
  const date = moment(item.last_contact_at)

  return (
    <Box bgColor="gray.200">
      <Pressable
        onPress={() => {
          navigate('ChatScreen', '/chat', {
            conversationId: item.conversation_id,
            conversation: {
              first_name: item.contact.name,
              image: item.contact.image_url
            },
            contact: {
              name: item.contact.name,
              image_url: item.contact.image_url
            }
          })
        }}
      >
        <HStack width="100%" bgColor="white" borderColor="gray.200" borderBottomWidth="1px">
          <Avatar m="15px" shadow="1" source={{ uri: item.contact.image_url }} />
          <VStack py="15px" flexGrow="1">
            <HStack>
              <Text fontSize="14px">{item.contact.name}</Text>
              <Spacer />
              {item.unread_count > 0 && (
                <Box
                  mt="1px"
                  mr="5px"
                  rounded="full"
                  width="20px"
                  height="20px"
                  bgColor="secondary.300"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text fontSize="12px" px="5px" color="white" bgColor="primary.500" fontWeight="bold">
                    {item.unread_count}
                  </Text>
                </Box>
              )}
              <Text mr="20px">{date.isSame(new Date(), 'day') ? date.format('H:mm') : date.format('L')}</Text>
            </HStack>
            <Text color="gray.800" maxWidth="90%">
              {item.last_message.length > 42 ? `${item.last_message.substring(0, 40)}...` : item.last_message}
            </Text>
          </VStack>
        </HStack>
      </Pressable>
    </Box>
  )
}

export default memo(ChatListItem)
