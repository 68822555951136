import React, { useEffect, useState } from 'react'
import { Card, CharlyIcon, Layout, Switch } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { Box, Button, Center, Checkbox, Heading, HStack, Spinner, Text, useTheme } from 'native-base'
import { AngelAvailability, AngelDailyAvailability, useRouter } from '@charlycares/shared'
import {
  useGetAngelAvailabilitiesQuery,
  useGetUserQuery,
  useSetAngelAvailabilityTypesMutation,
  useUpdateAngelAvailabilitiesMutation
} from '@charlycares/data-access'
import moment from 'moment'
import _ from 'lodash'

const AngelPreferences = () => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { navigate } = useRouter()

  const { data: user } = useGetUserQuery()
  const { data, isLoading } = useGetAngelAvailabilitiesQuery()
  const [updateAngelAvailabilities, { isLoading: isUpdatingAvailabilities }] = useUpdateAngelAvailabilitiesMutation()
  const [updateAvailabilityTypes] = useSetAngelAvailabilityTypesMutation()

  const [availabilities, setAvailabilities] = useState<AngelAvailability[]>()
  const isAvailabilitiesChanged = !_.isEqual(data, availabilities)

  const updateAvailabilities = (day_of_week: number, partOfDay: AngelDailyAvailability) => {
    setAvailabilities(state =>
      state?.map(day => {
        if (day.day_of_week === day_of_week) {
          return {
            ...day,
            [partOfDay]: !day[partOfDay]
          }
        }
        return day
      })
    )
  }

  const onSave = () => {
    if (availabilities) {
      updateAngelAvailabilities({ availabilities })
    }
  }

  useEffect(() => {
    if (data) {
      setAvailabilities(data)
    }
  }, [data])

  return (
    <Layout
      title={t('angelPreferences')}
      headerRight={
        isAvailabilitiesChanged
          ? () => (
              <Button isLoading={isUpdatingAvailabilities} onPress={onSave} variant="text">
                {t('save')}
              </Button>
            )
          : undefined
      }
    >
      <Card>
        <Heading fontWeight="600" fontSize="22px" fontFamily="body">
          {t('preferredServiceTitle')}
        </Heading>
        <Text mt="5px" color="gray.700" fontSize="15px">
          {t('preferredServiceSubtitle')}
        </Text>

        <Box mt="20px">
          <Box w="100%" alignItems="center" flexDir="row">
            <Heading fontWeight="600" fontSize="16px" fontFamily="body" flex={1}>
              {t('varyingServiceTitle')}
            </Heading>
            <Switch
              value={user?.angel?.occasional}
              thumbColor={colors.white}
              trackColor={{
                false: colors.gray[200],
                true: colors.secondary[400]
              }}
              onValueChange={value => {
                updateAvailabilityTypes({ occasional: value, structural: Boolean(user?.angel?.structural) })
              }}
            />
          </Box>
          <HStack alignItems="center" mt="10px" space="10px">
            <CharlyIcon size={51} name="varying-calendar" color={colors.secondary[400]} />
            <Text color="gray.800" flex={1}>
              {t('varyingServiceDescription')}
            </Text>
          </HStack>
        </Box>

        <Box mt="20px">
          <Box w="100%" alignItems="center" flexDir="row">
            <Heading fontWeight="600" fontSize="16px" fontFamily="body" flex={1}>
              {t('fixedServiceTitle')}
            </Heading>
            <Switch
              thumbColor={colors.white}
              trackColor={{
                false: colors.gray[200],
                true: colors.secondary[400]
              }}
              value={user?.angel?.structural}
              onValueChange={value => {
                updateAvailabilityTypes({ structural: value, occasional: Boolean(user?.angel?.occasional) })
              }}
            />
          </Box>
          <HStack alignItems="center" mt="10px" space="10px">
            <CharlyIcon size={51} name="fixed-calendar" color={colors.secondary[400]} />
            <Text color="gray.800" flex={1}>
              {t('fixedServiceDescription')}
            </Text>
          </HStack>
        </Box>
      </Card>

      <Card>
        <Heading fontWeight={600} fontSize="18px">
          {t('babysitTimingTitle')}
        </Heading>

        {!isLoading && availabilities ? (
          <Box mt="15px" w="100%">
            <Box flexDir="row">
              <Box flex={1} />
              <HStack space="10px">
                <Center w="60px">
                  <CharlyIcon size={28} name="icn-morning" />
                  <Text fontSize={'12px'}>{t('morning')}</Text>
                </Center>
                <Center w="60px">
                  <CharlyIcon size={28} name="icn-day" />
                  <Text fontSize={'12px'}>{t('afternoon')}</Text>
                </Center>
                <Center w="60px">
                  <CharlyIcon size={28} name="icn-night" />
                  <Text fontSize={'12px'}>{t('evening')}</Text>
                </Center>
              </HStack>
            </Box>

            <Box mt="10px">
              {availabilities?.map(day => {
                return (
                  <Box
                    key={day.id}
                    alignItems="center"
                    flexDir="row"
                    py="10px"
                    borderBottomWidth={1}
                    borderColor="gray.200"
                  >
                    <Text fontSize="15px" flex={1}>
                      {moment()
                        .day(day.day_of_week + 1)
                        .format('dddd')}
                    </Text>

                    <HStack space="10px">
                      <Center w="60px">
                        <Checkbox
                          size="lg"
                          value={AngelDailyAvailability.morning}
                          _checked={{
                            bg: 'secondary.400',
                            borderColor: 'secondary.400'
                          }}
                          bg="gray.50"
                          borderColor="gray.500"
                          onChange={() => updateAvailabilities(day.day_of_week, AngelDailyAvailability.morning)}
                          defaultIsChecked={day.morning}
                        />
                      </Center>
                      <Center w="60px">
                        <Checkbox
                          size="lg"
                          value={AngelDailyAvailability.afternoon}
                          _checked={{
                            bg: 'secondary.400',
                            borderColor: 'secondary.400'
                          }}
                          bg="gray.50"
                          borderColor="gray.500"
                          onChange={() => updateAvailabilities(day.day_of_week, AngelDailyAvailability.afternoon)}
                          defaultIsChecked={day.afternoon}
                        />
                      </Center>
                      <Center w="60px">
                        <Checkbox
                          size="lg"
                          value={AngelDailyAvailability.evening}
                          _checked={{
                            bg: 'secondary.400',
                            borderColor: 'secondary.400'
                          }}
                          bg="gray.50"
                          borderColor="gray.500"
                          onChange={() => updateAvailabilities(day.day_of_week, AngelDailyAvailability.evening)}
                          defaultIsChecked={day.evening}
                        />
                      </Center>
                    </HStack>
                  </Box>
                )
              })}
            </Box>
          </Box>
        ) : (
          <Center my="20px" w="100%">
            <Spinner mt="10px" />
          </Center>
        )}
      </Card>

      <Box m="20px 10px" p="15px" borderRadius="10px" bg="gray.alpha400.30">
        <HStack alignItems="center" space="10px">
          <CharlyIcon size={51} name="not-available-calendar" color={colors.gray[800]} />
          <Text flex={1} fontWeight={600} fontSize="15px">
            {t('notAvailableDescription')}
          </Text>
        </HStack>

        <Button mt="20px" onPress={() => navigate('CalendarScreen', '/angel-calendar')}>
          {t('letUsKnowButtonLabel')}
        </Button>
      </Box>
    </Layout>
  )
}

AngelPreferences.navigationOptions = () => ({
  headerShown: false
})

export default AngelPreferences
