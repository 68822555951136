import React, { useRef, useState } from 'react'
import { Card, CharlyIcon, TextInput, DateTimePicker } from '@charlycares/ui'
import { Avatar, Box, Button, Center, HStack, Text } from 'native-base'
import {
  browseMedia,
  formatDate,
  formatPhoneNumber,
  isDev,
  isWeb,
  MIN_AGE_REQUIRED,
  uploadFile
} from '@charlycares/shared'
import { FormikErrors } from 'formik'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { Alert } from 'react-native'
import { useGetUserQuery } from '@charlycares/data-access'

type Props = {
  avatarUrl?: string
  editable?: boolean
  handleChange: any
  errors: FormikErrors<{
    first_name: string
    last_name: string
    postalcode: string
    street_number: string
    phone: string
    birthdate: string
    street_name?: string
  }>
  values: {
    first_name: string
    last_name: string
    postalcode: string
    phone: string
    birthdate: string
    street_number: string
    street_name: string
    city: string
  }
}

export default function AngelProfileDetails({ editable, avatarUrl, values, errors, handleChange }: Props) {
  const { first_name, last_name, postalcode, street_number, phone, birthdate, street_name, city } = values
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [uploadingImage, setUploadingImage] = useState(false)

  const { data: user } = useGetUserQuery()

  // BirthDate
  const datePickerRef = useRef<any>(null)
  const currentBirthDate = moment(birthdate).format('YYYY-MM-DD')
  const minBirthDate = moment().subtract(99, 'years').format('YYYY-MM-DD')
  const maxBirthDate = moment().subtract(MIN_AGE_REQUIRED, 'years').format('YYYY-MM-DD')

  /** Functions */
  const onAddPhoto = async () => {
    try {
      const image = await browseMedia()
      if (image.didCancel) return

      setUploadingImage(true)
      const response = await uploadFile('v2/angels/photo', image)

      dispatch({
        type: isWeb ? 'set_user' : 'update_profile',
        payload: { image: response.data.image }
      })
    } catch (error) {
      isDev && console.log(error)
      Alert.alert('', t('error'))
    } finally {
      setUploadingImage(false)
    }
  }

  return (
    <Card>
      <Center w="100%">
        <Avatar
          borderWidth="1px"
          borderColor={'gray.100'}
          size="xl"
          w="150px"
          h="150px"
          bg="gray.200"
          source={{
            uri: avatarUrl
          }}
        >
          {`${first_name.slice(0, 1)}${last_name.slice(0, 1)}`}
        </Avatar>
        {editable && (
          <Button
            isLoading={uploadingImage}
            isLoadingText={t('Uploading')}
            _loading={{
              opacity: 1
            }}
            onPress={onAddPhoto}
            h="40px"
            py="0"
            leftIcon={<CharlyIcon name="icn-photo" size={32} color="white" />}
            w="225px"
            top={'-20px'}
          >
            {t('Change photo')}
          </Button>
        )}
      </Center>

      <TextInput
        variant="filled"
        label={t('firstName')}
        value={first_name}
        textDecoration="none"
        error={errors.first_name}
        onChangeText={handleChange('first_name')}
        isReadOnly={!editable || user?.angel?.is_verified}
        returnKeyType="done"
      />

      <TextInput
        variant="filled"
        label={t('surname')}
        textDecoration="none"
        value={last_name}
        error={errors.last_name}
        onChangeText={handleChange('last_name')}
        isReadOnly={!editable}
        returnKeyType="done"
      />

      {/* Address */}
      {editable ? (
        <Box mb="10px">
          <HStack w="100%" space={'14px'}>
            <TextInput
              label={t('postalCode')}
              value={postalcode}
              error={errors.postalcode}
              onChangeText={handleChange('postalcode')}
              _wrapper={{ flex: 1.5 }}
              isReadOnly={!editable}
              returnKeyType="done"
            />

            <TextInput
              label={t('streetNumber')}
              value={street_number}
              error={errors.street_number}
              onChangeText={handleChange('street_number')}
              _wrapper={{ flex: 1 }}
              isReadOnly={!editable}
              returnKeyType="done"
            />
          </HStack>

          <Center mt="10px" w="100%" flexDir="row">
            <CharlyIcon mr="10px" name="icn-house" size={32} color="black" />
            <Text fontSize="15px">{t('profileScreensAddressWillBeRetrived')}</Text>
          </Center>
        </Box>
      ) : (
        <Box>
          <Text fontSize={'14px'} color={'gray.800'}>
            {t('address')}
          </Text>
          <HStack my="15px" space="10px" alignItems="center">
            <CharlyIcon name="icn-house" size={'32px'} color="black" />
            <Box>
              <Text fontSize="16px">{`${street_name} ${street_number}`}</Text>
              <Text fontWeight={300} fontSize="12px">
                {city}
              </Text>
            </Box>
          </HStack>
        </Box>
      )}

      <TextInput
        label={t('phoneNumber')}
        keyboardType="phone-pad"
        value={editable ? phone : formatPhoneNumber(phone)}
        error={errors.phone}
        onChangeText={handleChange('phone')}
        isReadOnly={!editable}
        InputLeftElement={
          !editable ? <CharlyIcon mx="10px" name="icn-telephone-fill" size={32} color="black" /> : undefined
        }
      />

      <TextInput
        showSoftInputOnFocus={false}
        variant="filled"
        label={t('birthdate')}
        value={formatDate(birthdate)}
        error={errors.birthdate}
        isReadOnly={!editable || user?.angel?.is_verified}
        onFocus={() => editable && datePickerRef.current?.open(currentBirthDate)}
      />

      <DateTimePicker
        ref={datePickerRef}
        type="date"
        dateFormat="YYYY"
        separator=""
        minDate={minBirthDate}
        maxDate={maxBirthDate}
        onConfirmPress={(date: string) => {
          handleChange('birthdate')(date)
        }}
      />
    </Card>
  )
}

AngelProfileDetails.defaultProps = {
  isEditMode: false
}
