import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { ActivityIndicator } from 'react-native';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import Modal from './Modal';
import { TextView } from '../Texts';
import colors from '../../styles/colors';

const View = styled.View`
  justify-content: center;
  align-items: center;
  padding: 22px;
  border-radius: 4px;
  border-color: 'rgba(0, 0, 0, 0.1)';
  background-color: ${colors.white};
`;

const OverlayMessage = forwardRef(({ message, children }, ref) => {
  const modalRef = useRef(null);

  const setIsVisible = (isVisible) => {
    modalRef.current.setIsVisible(isVisible);
  };

  useImperativeHandle(ref, () => ({
    setIsVisible
  }));

  return (
    <Modal ref={modalRef}>
      <View>
        {children || (
          <TextView
            flexDirection="column"
            justifyContent="center"
            text={message}
            fontFamily="Martel"
            fontSize="18px"
            fontWeight="600"
            textAlign="center"
            textColor={colors.secondary}>
            <ActivityIndicator />
          </TextView>
        )}
      </View>
    </Modal>
  );
});

OverlayMessage.propTypes = {
  message: PropTypes.string,
  children: PropTypes.node
};

OverlayMessage.defaultProps = {
  children: null,
  message: null
};

export default OverlayMessage;
