import React from 'react';
import PropTypes from 'prop-types';
import { Button, Text } from '../../elements';
import theme from '../../styles/theme';

const TextButtonText = ({
  margins,
  flexDirection,
  onPress,
  firstText,
  firstTextWidth,
  firstTextMargins,
  firstTextColor,
  firstTextFontFamily,
  firstTextFontSize,
  firstTextFontWeight,
  firstTextAlign,
  firstTextNumberOfLines,
  secondText,
  secondTextMargins,
  secondTextColor,
  secondTextFontFamily,
  secondTextFontSize,
  secondTextFontWeight,
  ...restTextButtonProps
}) => (
  <Button
    margins={margins}
    flexDirection={flexDirection}
    borderWidth={0}
    onPress={onPress}
    {...restTextButtonProps}>
    <Text
      width={firstTextWidth}
      margins={firstTextMargins}
      color={firstTextColor}
      fontFamily={firstTextFontFamily}
      fontSize={firstTextFontSize}
      fontWeight={firstTextFontWeight}
      textAlign={firstTextAlign}
      numberOfLines={firstTextNumberOfLines}>
      {firstText}
      <Text
        margins={secondTextMargins}
        color={secondTextColor}
        fontFamily={secondTextFontFamily}
        fontSize={secondTextFontSize}
        fontWeight={secondTextFontWeight}>
        {` ${secondText}`}
      </Text>
    </Text>
  </Button>
);

TextButtonText.propTypes = {
  margins: PropTypes.string,
  flexDirection: PropTypes.string,
  onPress: PropTypes.func.isRequired,
  firstText: PropTypes.string.isRequired,
  firstTextWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  firstTextMargins: PropTypes.string,
  firstTextColor: PropTypes.string,
  firstTextFontFamily: PropTypes.string,
  firstTextFontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  firstTextFontWeight: PropTypes.string,
  firstTextAlign: PropTypes.string,
  firstTextNumberOfLines: PropTypes.number,
  secondText: PropTypes.string.isRequired,
  secondTextMargins: PropTypes.string,
  secondTextColor: PropTypes.string,
  secondTextFontFamily: PropTypes.string,
  secondTextFontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  secondTextFontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

TextButtonText.defaultProps = {
  margins: '0px',
  flexDirection: 'row',
  firstTextWidth: null,
  firstTextMargins: '0px',
  firstTextColor: theme.textColorBase,
  firstTextFontFamily: theme.fontFamilyBase,
  firstTextFontSize: theme.fontSize.md,
  firstTextFontWeight: theme.textFontWeight,
  firstTextAlign: null,
  firstTextNumberOfLines: null,
  secondTextMargins: '0px',
  secondTextColor: theme.colors.secondary,
  secondTextFontFamily: theme.fontFamilyBase,
  secondTextFontSize: theme.fontSize.md,
  secondTextFontWeight: theme.textFontWeight
};

export default TextButtonText;
