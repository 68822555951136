import React from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'

import { Text, Icon } from '../../elements'
import colors from '../../styles/colors'
import checkIcon from '../../../assets/icons/icn-check.png'

const Container = styled.View`
  padding: 20px 10px 10px 10px;
`
const UspContainer = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  z-index: 2;
  background-color: ${colors.transparentWhite};
`

const Loader = ({ uspCounter }) => {
  const [t] = useTranslation()

  return (
    <Container>
      <UspContainer>
        <Icon margins="0px 8px 4px 0px" size="32px" source={checkIcon} color={colors.secondary} />

        <Text fontFamily="Martel" fontSize="16px">
          {t(`uspLoading${uspCounter}`)}
        </Text>
      </UspContainer>

      {[...Array(6)].map((_, i) => (
        <ContentLoader height={130} width={400} speed={2} color={colors.grey07} key={i.toString()}>
          <circle cx="30.5" cy="30.5" r="25.2" />

          <rect x="80.5" y="15.05" rx="0" ry="0" width="175.96" height="7" />

          <rect x="80.5" y="34.05" rx="0" ry="0" width="150.96" height="7" />

          <rect x="15.5" y="70.05" rx="0" ry="0" width="310.96" height="5" />

          <rect x="15.5" y="85.05" rx="0" ry="0" width="310.96" height="5" />

          <rect x="15.5" y="100.05" rx="0" ry="0" width="170.96" height="5" />
        </ContentLoader>
      ))}
    </Container>
  )
}

Loader.propTypes = {
  uspCounter: PropTypes.number.isRequired
}

export default Loader
