import React from 'react'
import { Card, NumberInput, Switch } from '@charlycares/ui'
import { Box, Heading, IBoxProps, Text } from 'native-base'
import { ANGEL_RATE_MAX_AMOUNT, ANGEL_RATE_MIN_AMOUNT, formatMoney, isWeb } from '@charlycares/shared'
import { useTranslation } from 'react-i18next'
import { useAlert } from '../../../../hooks'
import { Currency } from '@charlycares/data-access'

type Props = IBoxProps & {
  jobBoard: boolean
  onToggle?: (value: boolean) => void
  maxHourlyRate?: number
  onMaxHourlyRateChange?: (value: number) => void
}

const JobBoardOptions = ({ jobBoard, onToggle, maxHourlyRate = 0, onMaxHourlyRateChange, ...boxProps }: Props) => {
  const { t } = useTranslation()
  const alert = useAlert()

  const onAddToJobboardChange = () => {
    onToggle?.(!jobBoard)

    if (jobBoard) {
      alert.show(t('jobboardOffAlertTitle'), t('jobboardOffAlertDescription'), [
        { text: t('jobboardOff'), onPress: () => onToggle?.(false), style: isWeb ? 'cancel' : undefined },
        { text: t('jobboardOn'), onPress: () => onToggle?.(true), style: !isWeb ? 'cancel' : undefined }
      ])
    }
  }

  return (
    <Box {...boxProps}>
      <Card>
        <Box flex={1} w="100%">
          <Box flexDir="row" justifyContent="space-between" alignItems="center">
            <Heading color={jobBoard ? 'text.dark.400' : 'gray.500'} fontSize="15px" fontWeight={600}>
              {t('addToJobboard')}
            </Heading>

            <Switch onValueChange={onAddToJobboardChange} value={jobBoard} />
          </Box>
          <Text mt="15px" fontSize="14px" color={jobBoard ? 'text.dark.400' : 'gray.500'}>
            {t('addToJobboardDescription')}
          </Text>
        </Box>

        <Box flex={1} mt="30px" w="100%">
          <Box flexDir="row" justifyContent="space-between" alignItems="center">
            <Heading
              flex={1}
              mr="10px"
              color={jobBoard ? 'text.dark.400' : 'gray.500'}
              fontSize="15px"
              fontWeight={600}
            >
              {t('maxRateIndicatorTitle')}
            </Heading>

            <NumberInput
              min={ANGEL_RATE_MIN_AMOUNT * 100}
              max={ANGEL_RATE_MAX_AMOUNT * 100}
              disabled={!jobBoard}
              value={maxHourlyRate}
              changeRate={25}
              onChange={onMaxHourlyRateChange}
              _text={{ color: jobBoard ? 'text.dark.400' : 'gray.500' }}
              formatValue={val =>
                formatMoney({
                  amount: val,
                  currency: Currency.EUR
                })
              }
            />
          </Box>
          <Text mt="15px" fontSize="14px" color={jobBoard ? 'text.dark.400' : 'gray.500'}>
            {t('maxRateIndicatorDesc')}
          </Text>
        </Box>
      </Card>
    </Box>
  )
}

export default JobBoardOptions
