import { Platform } from 'react-native'

import { api } from '../api'
import { PaginationArg, PaginationData } from '../paginate'
import { ChatMessage, ChatMessageCollectionItem, ChatMessageItem } from './chat.dto'
import { ApiTags } from '../types'

interface ChatListArgs extends PaginationArg {
  search?: string
  order: 'asc' | 'desc'
}

interface ChatArgs extends PaginationArg {
  conversationId: string
}

interface SendChatMessageArgs {
  message: string
  receiver_id: string
}

interface SendChatMessageResponse {
  data: ChatMessageItem
  meta: {
    identifier: string
  }
}

export const chatApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: ({ mutation, query }) => ({
    getChatList: query<PaginationData<ChatMessageCollectionItem[]>, ChatListArgs>({
      query: arg => ({
        url: `v2/chats/`,
        params: arg,
        method: 'GET'
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newCache, { arg }) => {
        if (arg.cursor === null) {
          return newCache
        }

        Object.assign(currentCache, {
          ...newCache,
          data: [...currentCache.data, ...newCache.data]
        })

        return currentCache
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.cursor !== previousArg?.cursor
      },
      providesTags: (result, error, arg) => {
        return [{ type: ApiTags.CHAT, id: 'LIST' }]
      }
    }),
    getChat: query<PaginationData<ChatMessage>, ChatArgs>({
      query: arg => ({
        url: `v2/chats/${arg.conversationId}`,
        params: {
          cursor: arg.cursor
        },
        method: 'GET'
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName + queryArgs.conversationId
      },
      merge: (currentCache, newCache, { arg }) => {
        if (arg.cursor === null) {
          return newCache
        }

        currentCache.data.messages.push(...newCache.data.messages)
        currentCache.meta.next_cursor = newCache.meta.next_cursor

        return currentCache
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.cursor !== previousArg?.cursor
      },
      providesTags: (result, error, arg) => {
        return [{ type: ApiTags.CHAT, id: arg.conversationId }]
      }
    }),
    sendChatMessage: mutation<SendChatMessageResponse, SendChatMessageArgs>({
      query: body => ({
        url: `v2/chats/`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: [{ type: ApiTags.CHAT, id: 'LIST' }],
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const { data: newChatMessage } = await queryFulfilled

        try {
          dispatch(
            chatApi.util.updateQueryData(
              'getChat',
              { conversationId: newChatMessage.data.contact.conversation_id, cursor: null },
              draft => {
                console.log(newChatMessage)
                draft.data.messages.unshift(newChatMessage.data)
              }
            )
          )
        } catch {
          chatApi.util.invalidateTags([{ type: ApiTags.CHAT, id: newChatMessage.data.contact.conversation_id }])
        }
      }
    }),
    markChatAsRead: mutation<ChatMessageItem, { id: number }>({
      query: ({ id }) => ({
        url: `v2/chats/${id}/read`,
        method: 'POST'
      })
    })
  })
})

export const {
  useGetChatListQuery,
  useLazyGetChatListQuery,
  useGetChatQuery,
  useSendChatMessageMutation,
  useMarkChatAsReadMutation
} = chatApi
