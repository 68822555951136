import styled, { DefaultTheme } from 'styled-components/native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { ThemedStyledProps } from 'styled-components'

import { isWeb, setStyle } from '@charlycares/shared'
import { baseProps, BaseProps } from './base'

export type BoxProps = BaseProps & {
  row?: boolean
  center?: boolean
  align?: 'flex-start' | 'flex-end' | 'center' | 'stretch'
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around'
  dir?: 'row' | 'column'
  direction?: 'row' | 'column'
  alignSelf?: 'flex-start' | 'flex-end' | 'center' | 'stretch'

  borderBottom?: number | string
  borderTop?: number | string
  borderLeft?: number | string
  borderRight?: number | string
  radius?: string | number
  borderColor?: string
  borderStyle?: string
  borderWidth?: number | string
}

const getBoxProps = (p: ThemedStyledProps<BoxProps, DefaultTheme>) => `
${baseProps(p)}
${setStyle('flex', p.flex)};
${setStyle('flex-direction', p.dir, p.row ? 'row' : 'column')};
${setStyle('justify-content', p.justify, p.center ? 'center' : 'flex-start')};
${setStyle('align-items', p.align, p.center ? 'center' : 'flex-start')};
${setStyle('align-self', p.alignSelf)};

${setStyle('border-bottom-width', p.borderBottom)};
${setStyle('border-top-width', p.borderTop)};
${setStyle('border-left-width', p.borderLeft)};
${setStyle('border-right-width', p.borderRight)};
${setStyle('border-color', p.borderColor, p?.theme?.colors?.grey08)};
${setStyle('border-style', p.borderStyle, 'solid')};
${setStyle('border-width', p.borderWidth, 0)};
${setStyle('border-radius', p.radius)};
`
/**
 * @deprecated Use native-base Box
 */
export const Box = styled.View<BoxProps>(getBoxProps)
Box.defaultProps = {
  w: '100%'
}

/**
 * @deprecated Use native-base Hstack
 */
export const Row = styled(Box)``
Row.defaultProps = {
  w: '100%',
  dir: 'row'
}

/**
 * @deprecated Use native-base Center
 */
export const Center = styled(Box)``
Center.defaultProps = {
  justify: 'center',
  align: 'center'
}

/**
 * @deprecated Use native-base Pressable
 */
export const Touchable = styled.TouchableOpacity<BoxProps>(getBoxProps)
Touchable.defaultProps = {
  dir: 'row',
  center: true,
  w: 'auto'
}

/**
 * @deprecated
 */
export const Container = styled(SafeAreaView)`
  background: ${p => p.theme.colors.background};
  width: 100%;
  ${!isWeb && `flex: 1`}
`

/**
 * @deprecated Use native-base Divider
 */
export const Divider = styled(Box)`
  background: ${p => p.bg || p.theme.colors.grey08};
`
Divider.defaultProps = {
  h: '1px'
}
