import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { Icon, Button } from '../../../elements';
import { TextTextView, TextIconView } from '../../Texts';
import Rating from '../Rating';
import FamilyAvatar from '../FamilyAvatar';
import { getFormatedDate } from '../../../utils/functions';
import theme from '../../../styles/theme';

import infoIcon from '../../../../assets/icons/icn-small-info.png';
import rightArrowIcon from '../../../../assets/icons/icn-nav-right.png';

const View = styled.View`
  flex-direction: row;
  align-items: center;
`;
const RatingView = styled.View`
  align-items: flex-end;
  justify-content: space-between;
`;

const ReviewCard = ({ image, startDate, rating, comments, onCardPress }) => {
  const [t] = useTranslation();

  return (
    <Button
      flexDirection="row"
      justifyContent="space-between"
      paddings="20px 0px 20px 15px"
      height="auto"
      borderBottomWidth="1px"
      borderTopWidth={0}
      borderColor={theme.colors.grey08}
      onPress={onCardPress}>
      <View>
        <FamilyAvatar source={image} />

        <TextTextView
          flexDirection="column"
          alignItems="flex-start"
          paddings="0px"
          margins="0px 0px 0px 10px"
          firstText={getFormatedDate(startDate, 'dddd')}
          secondText={getFormatedDate(startDate, 'LL')}
        />
      </View>

      <View>
        <RatingView>
          <Rating rating={rating} iconSize="15px" activeTintColor={theme.ratingStarActiveMuted} />

          {comments === '' && (
            <TextIconView
              backgroundColor="transparent"
              text={t('profileScreensWriteReview')}
              textColor={theme.colors.secondary}
              icon={infoIcon}
              iconMargins="0px 0px 0px 5px"
              iconColor={theme.colors.secondary}
            />
          )}
        </RatingView>

        <Icon
          margins="0px 0px 0px 10px"
          size="32px"
          source={rightArrowIcon}
          color={theme.colors.defaultBtnBackgroundColor}
        />
      </View>
    </Button>
  );
};

ReviewCard.propTypes = {
  image: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  comments: PropTypes.string.isRequired,
  onCardPress: PropTypes.func.isRequired
};

ReviewCard.defaultProps = {
  image: null
};

export default ReviewCard;
