import axiosInstance from '../../utils/axios';
import {
  FETCHING_INVITATIONS,
  GET_ANGEL_INVITATIONS,
  GET_NEXT_ANGEL_INVITATIONS,
  SET_INVITATION_DETAILS,
  UPDATING_INVITATION_DETAILS,
  ACCEPT_INVITATION,
  INVITATION_ACCEPTED_ERROR
} from '../types';

const setInvitationDetails = (invitation, dispatch) => {
  dispatch({
    type: SET_INVITATION_DETAILS,
    payload: invitation
  });
};

export const getInvitations = (state, amountPerPage) => (dispatch) => {
  dispatch({ type: FETCHING_INVITATIONS });
  axiosInstance
    .get(`v1/bookings/angel/v2/${state}/${amountPerPage}`)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_ANGEL_INVITATIONS,
          payload: data.data
        });
      }
    })
    .catch((error) => console.log({ error }));
};

export const getNextInvitations = (nextUrlPage) => (dispatch) => {
  axiosInstance
    .get(nextUrlPage)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_NEXT_ANGEL_INVITATIONS,
          payload: data.data
        });
      }
    })
    .catch((error) => console.log({ error }));
};

export const getInvitationDetails = (id) => (dispatch) => {
  axiosInstance
    .get(`invitations/${id}`)
    .then(({ data }) => {
      if (data) {
        setInvitationDetails(data.data, dispatch);
      }
    })
    .catch((error) => console.log({ error }));
};

export const acceptInvitation = (invitationId, bookingDateResponses) => (dispatch) => {
  dispatch({ type: UPDATING_INVITATION_DETAILS });
  axiosInstance
    .put(`invitations/accept/${invitationId}`, { booking_date_responses: bookingDateResponses })
    .then(({ data }) => {
      if (data) {
        const { id, accepted_invitation, updated_invitations = [] } = data.data;
        if (id) {
          setInvitationDetails(data.data, dispatch);
        } else {
          setInvitationDetails(accepted_invitation, dispatch);
          dispatch({
            type: ACCEPT_INVITATION,
            payload: updated_invitations.filter(
              (updatedInvitation) => updatedInvitation.id !== accepted_invitation.id
            )
          });
        }
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 400) {
        dispatch({
          type: INVITATION_ACCEPTED_ERROR,
          payload: error.response.data && error.response.data.message
        });
      } else {
        console.log({ error });
      }
    });
};

export const declineInvitation = (id, reason, message, shareMessage) => (dispatch) => {
  dispatch({ type: UPDATING_INVITATION_DETAILS });
  axiosInstance
    .put(`invitations/decline/${id}`, { reason, message, show_family: shareMessage })
    .then(({ data }) => {
      if (data) {
        setInvitationDetails(data.data, dispatch);
      }
    })
    .catch((error) => console.log({ error }));
};
