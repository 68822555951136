import React from 'react'
import { Card, CharlyIcon, ScreenLayout } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { useRouter } from '@charlycares/shared'
import { BookingPaymentSummary } from './index'
import { useGetActiveTimerQuery, useGetBookingTransactionsQuery } from '@charlycares/data-access'
import { Button, HStack, Skeleton, Text } from 'native-base'

const BookingPaymentScreen = () => {
  const { t } = useTranslation()
  const { getParam, navigate } = useRouter()
  const transactionUuid = getParam('transactionUuid')
  const bookingId = getParam('bookingId')

  const { isLoading, data: bookingWalletTransaction } = useGetBookingTransactionsQuery({
    booking_id: bookingId,
    transaction_uuid: transactionUuid
  })
  useGetActiveTimerQuery()

  return (
    <ScreenLayout
      edges={['left', 'right']}
      supportButton
      disableScroll
      title={t('payment')}
      subtitle={`${bookingId} (${t(`walletTransactionStatus.${bookingWalletTransaction?.status}`)})`}
    >
      {isLoading && (
        <Card>
          <Skeleton h={500} />
        </Card>
      )}
      {bookingWalletTransaction && <BookingPaymentSummary bookingWalletTransaction={bookingWalletTransaction} />}

      {!!bookingWalletTransaction?.rating?.average_rating && (
        <Card flexDir="row" alignItems="center" justifyContent="space-between">
          <Text fontSize={'15px'}>{t('yourRating')}</Text>

          <HStack space={'30px'} alignItems="center">
            <HStack space="5px" alignItems="center">
              <Text fontSize={'15px'} fontWeight={600}>
                {bookingWalletTransaction.rating.rating.toFixed(2)}
              </Text>
              <CharlyIcon name="star" size={15} color="gray.900" />
            </HStack>

            <Button
              onPress={() =>
                navigate('AngelReviewDetails', '/angel-review-details', {
                  bookingId: bookingId,
                  ratingId: bookingWalletTransaction.rating?.id
                })
              }
              size="xs"
              variant="outline"
            >
              {t('readMore')}
            </Button>
          </HStack>
        </Card>
      )}
    </ScreenLayout>
  )
}

export default BookingPaymentScreen

BookingPaymentScreen.navigationOptions = {
  headerShown: false
}
