import axiosInstance from '../../utils/axios';
import {
  FETCHING_JOBS,
  GET_JOBS,
  ADD_TO_JOBLIST,
  ADDING_TO_JOBLIST,
  ADD_TO_JOBLIST_ERROR,
  SERVER_ERROR
} from '../types';

const logError = (error, dispatch) => {
  if (error.response && error.response.status === 500) {
    dispatch({ type: SERVER_ERROR });
  } else {
    console.log({ error });
  }
};

export const getJobs = (type) => (dispatch) => {
  dispatch({ type: FETCHING_JOBS });
  axiosInstance
    .get(`jobs/${type}`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: GET_JOBS,
          payload: response.data.data
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};

export const addToJoblist = (bookingId) => (dispatch) => {
  dispatch({ type: ADDING_TO_JOBLIST });
  axiosInstance
    .post('invitations/job', { booking_id: bookingId })
    .then((response) => {
      if (response.data) {
        dispatch({ type: ADD_TO_JOBLIST });
      }
    })
    .catch((error) => {
      const { response } = error;
      const { status, data } = response || {};
      if (status === 400) {
        dispatch({
          type: ADD_TO_JOBLIST_ERROR,
          payload: data.message || data.data.message
        });
      } else {
        logError(error, dispatch);
      }
    });
};
