import React from 'react'
import { Box, IBoxProps } from 'native-base'

type Props = IBoxProps

const Card = (boxProps: Props) => {
  return <Box mt="10px" w="100%" bg="white" p="20px" borderColor="gray.200" borderWidth="1px" {...boxProps} />
}

export default Card
