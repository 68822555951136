import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'

import { Text } from '../../elements'
import { TextView } from '../Texts'
import BabysitterCard from './BabysitterCard'
import { DashboardLoader } from '../Loaders'
import colors from '../../styles/colors'

const FlatList = styled.FlatList`
  margin: 20px 0px 0px 0px;
  height: 260px;
  width: 100%;
`
const SittersInYourArea = ({
  availableAngels,
  fetchingAvailableAngels,
  onAngelPress,
  onAngelVideoPress,
  onAngelToggleLikePress
}) => {
  const [t] = useTranslation()

  return (
    <TextView
      flexDirection="column"
      alignItems="flex-start"
      margins="0px 20px 0px 20px"
      paddings="20px 0px 20px 0px"
      text={t('dashboardScreensSitterInYourAreaTitle')}
      fontSize="22px"
      fontWeight="600"
    >
      <Text fontSize="16px" color={colors.defaultBtnTextColor}>
        {t('dashboardScreensSitterInYourAreaDescription')}
      </Text>

      {fetchingAvailableAngels && !availableAngels ? (
        <DashboardLoader />
      ) : (
        <FlatList
          horizontal
          data={availableAngels}
          renderItem={({ item }) => (
            <BabysitterCard
              babysitter={item}
              onAngelPress={onAngelPress}
              onAngelVideoPress={onAngelVideoPress}
              onToggleLikePress={onAngelToggleLikePress}
            />
          )}
          keyExtractor={({ id }) => String(id)}
        />
      )}
    </TextView>
  )
}

SittersInYourArea.propTypes = {
  availableAngels: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  fetchingAvailableAngels: PropTypes.bool,
  onAngelPress: PropTypes.func.isRequired,
  onAngelVideoPress: PropTypes.func.isRequired,
  onAngelToggleLikePress: PropTypes.func.isRequired
}

SittersInYourArea.defaultProps = {
  fetchingAvailableAngels: false,
  availableAngels: []
}

export default SittersInYourArea
