import React from 'react'
import styled, { useTheme } from 'styled-components/native'

type LoadingSpinnerProps = {
  loading?: boolean
  children?: React.ReactElement | React.ReactElement[] | null
  color?: string
  size?: number | 'small' | 'large' | undefined
}

/**
 * @deprecated Use native-base spinner
 */
const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ loading, children, color, size }) => {
  const { colors } = useTheme()

  return loading ? <Loading color={color || colors.primary} size={size || 'large'} /> : <>{children}</>
}

const Loading = styled.ActivityIndicator`
  align-self: center;
  padding: 20px;
`

export default LoadingSpinner
