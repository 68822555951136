import React from 'react'
import { useTheme, Text, HStack, IStackProps, ITextProps } from 'native-base'

import { CharlyIcon } from '../icons'

type Props = IStackProps & {
  disabled?: boolean
  min?: number
  max?: number
  value: number
  changeRate?: number
  onChange?: (value: number) => void
  formatValue?: (value: number) => string | React.ReactNode
  _text?: ITextProps
}

const NumberInput = ({
  disabled,
  min,
  max,
  value,
  formatValue,
  changeRate = 1,
  onChange,
  _text,
  ...boxProps
}: Props) => {
  const { colors } = useTheme()

  const onIncrease = () => {
    const newValue = value + changeRate
    if (max !== undefined && newValue > max) return
    onChange?.(value + changeRate)
  }

  const onDecrease = () => {
    const newValue = value - changeRate
    if (min !== undefined && newValue < min) return
    onChange?.(value - changeRate)
  }

  const isMin = Boolean(min !== undefined && min >= value)
  const isMax = Boolean(max !== undefined && max <= value)

  return (
    <HStack space="5px" justifyContent="space-between" alignItems="center" {...boxProps}>
      <CharlyIcon
        disabled={disabled || isMin}
        onPress={onDecrease}
        name="btn-minus"
        size={32}
        color={disabled || isMin ? colors.gray[300] : colors.secondary[400]}
      />

      <Text minW="60px" textAlign="center" fontSize="16px" {..._text}>
        {formatValue?.(value) || value}
      </Text>

      <CharlyIcon
        disabled={disabled || isMax}
        onPress={onIncrease}
        name="btn-plus"
        size={32}
        color={disabled || isMax ? colors.gray[300] : colors.secondary[400]}
      />
    </HStack>
  )
}

export default NumberInput
