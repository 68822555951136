import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  getAngelPublicDetails,
  getFamilyDetails,
} from '../../stores/actions/favoritesAction';
import { toggleLikeAngel } from '../../stores/actions/userAction';
import { Divider } from '../../elements';
import {
  TextView,
  TextIconView,
  TextButton,
  AngelAvatar,
  AvailableAngelCard,
} from '../../components';
import colors from '../../styles/colors';

const charlyOffers = (t) => [
  { icon: 'icn-calendar-accepted', text: t('favoritesScreensBookBabysiter') },
  { icon: 'icn-validated', text: t('favoritesScreensPersonalCheck') },
  { icon: 'icn-first-aid', text: t('favoritesScreensInsurance') },
  { icon: 'standby', text: t('favoritesScreensSupport') },
];

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  justify-content: space-between;
`;
const ScrollView = styled.ScrollView``;
const ActivityIndicator = styled.ActivityIndicator``;
const ButtonsView = styled.View`
  position: absolute;
  bottom: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 40px 20px;
  border-top-width: 1px;
  border-color: ${colors.grey08};
  background-color: ${colors.backgroundColor};
`;

const Share = ({
  location,
  history,
  route,
  navigation,
  angel,
  family,
  getAngelDetailsData,
  getFamilyDetailsData,
}) => {
  const { familyId, angelId, showSignup } =
    location.state || route.params || {};
  const [t] = useTranslation();
  const { id, first_name } = angel || {};

  useEffect(() => {
    getFamilyDetailsData(familyId);
    getAngelDetailsData(angelId);
  }, []);

  return (
    <SafeAreaView>
      {!angel || !family ? (
        <ActivityIndicator />
      ) : (
        <>
          <ScrollView>
            <TextView
              justifyContent="space-between"
              paddings="10px 15px 10px 20px"
              margins="0px 0px 20px 0px"
              backgroundColor={colors.primaryLight}
              textFlex={1}
              text={t('favoritesScreensShareWithYou', {
                family: family.first_name,
                angel: first_name,
              })}
              fontFamily="Martel"
              fontSize="16px"
              fontWeight="600"
            >
              <AngelAvatar source={family.image} size="80" />
            </TextView>

            {angel && (
              <AvailableAngelCard
                cardDisabled={showSignup}
                share
                angel={angel}
                chatEnabled={false}
                onVideoPress={(video) => {
                  if (navigation) {
                    navigation.navigate('VideoPlayer', { source: video });
                  }
                }}
                onCardPress={() => {
                  if (navigation) {
                    navigation.navigate('FavoritesShareDetails');
                  } else {
                    history.push('/favorites-share-details');
                  }
                }}
              />
            )}

            <Divider minHeight="20px" />

            <TextView
              flexDirection="column"
              alignItems="flex-start"
              paddings="35px 20px 0px 20px"
              text={t('favoritesScreensWhatCharlyOffers')}
              fontFamily="Martel"
              fontSize="16px"
              fontWeight="600"
            >
              {charlyOffers(t).map(({ icon, text }) => (
                <TextIconView
                  key={text}
                  flexDirection="row-reverse"
                  justifyContent="flex-start"
                  margins="10px 0px 10px 0px"
                  backgroundColor="transparent"
                  name={icon}
                  iconSize="32px"
                  iconColor={colors.primaryText}
                  textFlex={1}
                  textMargins="0px 0px 0px 10px"
                  text={text}
                  fontSize="15px"
                  fontWeight="300"
                  textAlign="left"
                />
              ))}
            </TextView>
          </ScrollView>

          <ButtonsView>
            {showSignup ? (
              <TextButton
                flex={1}
                backgroundColor={colors.primary}
                text={t('favoritesScreensSignUp')}
                onPress={() =>
                  navigation.navigate('SignUpStep1', {
                    role: 'family',
                    shareAngelId: id,
                  })
                }
              />
            ) : (
              <>
                <TextButton
                  flex={1}
                  borderTopWidth={0}
                  borderBottomWidth={0}
                  text={t('favoritesScreensReject')}
                  textColor={colors.defaultBtnBackgroundColor}
                  onPress={() => {
                    if (navigation) {
                      navigation.navigate('Favorites');
                    } else {
                      history.push('/favorites');
                    }
                  }}
                />

                <TextButton
                  flex={1}
                  margins="0px 0px 0px 20px"
                  backgroundColor={colors.primary}
                  text={t('favoritesScreensAccept')}
                  onPress={() => {}}
                />
              </>
            )}
          </ButtonsView>
        </>
      )}
    </SafeAreaView>
  );
};

Share.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      familyId: PropTypes.number,
      angelId: PropTypes.number,
      showSignup: PropTypes.bool,
    }),
  }),
  history: PropTypes.shape({ push: PropTypes.func }),
  route: PropTypes.shape({
    params: PropTypes.shape({
      familyId: PropTypes.number,
      angelId: PropTypes.number,
      showSignup: PropTypes.bool,
    }),
  }),
  navigation: PropTypes.shape({ navigate: PropTypes.func }),
  angel: PropTypes.shape({}),
  family: PropTypes.shape({
    first_name: PropTypes.string,
    image: PropTypes.string,
  }),
  getAngelDetailsData: PropTypes.func.isRequired,
  getFamilyDetailsData: PropTypes.func.isRequired,
  toggleLikeAngelData: PropTypes.func.isRequired,
};

Share.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null,
  angel: null,
  family: null,
};

const mapStateToProps = ({ Favorites }) => ({
  angel: Favorites.angelDetails,
  family: Favorites.familyDetails,
});

const mapDispatchToProps = {
  getAngelDetailsData: getAngelPublicDetails,
  getFamilyDetailsData: getFamilyDetails,
  toggleLikeAngelData: toggleLikeAngel,
};

export default connect(mapStateToProps, mapDispatchToProps)(Share);
