import { Linking, Platform, NativeModules } from 'react-native'
import moment from 'moment'

import countryCodes from './countryCodes.json'
import strings, { setLanguageLocale } from '../localization'

import { decomposeMembershipType, i18n, SUBSCRIPTION_STATUS } from '@charlycares/shared'
import { useGetSubscriptionQuery } from '@charlycares/data-access'

export const changeLocale = locale => {
  moment.locale(locale === 'nl_NL' ? 'nl' : 'en')
  setLanguageLocale(locale)
  i18n.changeLanguage(locale)
}

export const getFormatedDate = (date, formatType) => {
  const formatedDate = moment(date).format(formatType)
  return formatedDate
}

export const getAge = birthdate => Math.abs(moment(birthdate).diff(moment(), 'years'))

export const roundMonetaryValue = amount => {
  let parsedAmount = parseFloat(amount)
  if (parsedAmount % 1 === 0) {
    parsedAmount = `${Math.round(parsedAmount)},-`
  } else {
    parsedAmount = parsedAmount.toFixed(2)
    parsedAmount = parsedAmount.replace('.', ',')
  }
  return parsedAmount
}

export const renderDistanceInKilometers = distance => {
  if (distance === undefined || distance === null) return '0 m'
  if (distance < 1) {
    return `${distance.toFixed(2) * 1000}m`
  }
  return `${distance.toFixed(2)}km`
}

export const calculateBikeTime = distance => {
  if (distance === undefined || distance === null) return '0 min'
  const time = distance * 4
  return `${time.toFixed(0)} min`
}

export const formatPhoneNumber = phone => {
  if (phone && phone.length) {
    if (phone.charAt(0) === '0') {
      return `+${phone.substring(0, 2)} ${phone.substring(2, phone.length)}`
    }
    return `+${phone.substring(0, 2)} (0)${phone.substring(2, phone.length)}`
  }
  return ''
}

export const getMembershipPlanName = membershipPlan => {
  switch (membershipPlan) {
    case 'basic':
      return strings.profileScreensBaseMembership
    case 'premium':
      return strings.profileScreensPremiumMembership
    case 'canceled':
      return strings.profileScreensCanceledMembership
    case 'trial':
      return strings.profileScreensTrialMembership
    default:
      return strings.profileScreensFlexibleMembership
  }
}

export const CurrentSubscriptionPlan = () => {
  const { data: subscription } = useGetSubscriptionQuery()

  if (subscription) {
    const { membershipPlan } = decomposeMembershipType(subscription?.membership_type)

    return subscription.status === SUBSCRIPTION_STATUS.ACTIVE ? capitalize(membershipPlan) : 'Cancelled'
  }

  return ''
}

export const getNumberOfProfileMissingFields = user => {
  const { image, first_name, last_name, street_name, phone, family, angel, role } = user
  const { short_bio } = role === 'family' ? family : angel
  const fields = [image, first_name, last_name, short_bio, street_name, phone]
  return fields.filter(field => !field).length
}

export const getVideoDurationFromSeconds = videoDuration => {
  const minutes = Math.floor(videoDuration / 60)
  const seconds = videoDuration - minutes * 60
  return `${minutes}:${seconds}`
}

export const isValidIBAN = accountNumber => {
  // This function check if the checksum is correct
  let accountNum = accountNumber.toUpperCase()
  //   accountNum = accountNumber.replace(/\s+/g, '');

  accountNum = accountNum.replace(/^(.{4})(.*)$/, '$2$1') // Move the first 4 chars from left to the right
  accountNum = accountNum.replace(/[A-Z]/g, $e => $e.charCodeAt(0) - 'A'.charCodeAt(0) + 10) // Convert A-Z to 10-25
  let $sum = 0
  let $ei = 1 // First exponent
  for (let $i = accountNum.length - 1; $i >= 0; $i -= 1) {
    $sum += $ei * parseInt(accountNum.charAt($i), 10) // multiply the digit by it's exponent
    $ei = ($ei * 10) % 97 // compute next base 10 exponent  in modulus 97
  }
  return $sum % 97 === 1
}

export const getRateDescription = (rate, competitiveRate) => {
  const difference = rate - competitiveRate
  if (difference <= 0.25 && difference >= -0.25) {
    return strings.competitive
  }
  if (difference >= 0.5 && difference <= 1) {
    return strings.uncompetitive
  }
  if (difference > 1) {
    return strings.veryUncompetitive
  }
  if (difference <= -0.5 && difference >= -1) {
    return strings.aboveCompetitive
  }

  return strings.veryCompetitive
}

export const capitalize = string => string?.charAt(0).toUpperCase() + string?.slice(1)

export const getDuration = (startDate, endDate) => {
  const differenceHours = moment(endDate).diff(startDate, 'hours', false)
  const differenceMinutes = moment(endDate).diff(startDate, 'minutes', false)
  const minutes = differenceMinutes - differenceHours * 60
  return `${differenceHours} ${strings.hours} ${minutes ? `${minutes} ${strings.minutes}` : ''}`
}

export const makeAPhoneCall = phone => Linking.openURL(`tel${Platform.OS === 'android' ? '' : 'prompt'}:${phone}`)

export const getAllCountryCodes = () => {
  return countryCodes
}

export const getCountryCode = code => {
  const [suggestedCode] = getAllCountryCodes().filter(countryCode => countryCode.includes(`(${code})`))
  return suggestedCode
}

export const isCountryCodeValid = code => getAllCountryCodes().includes(code)

export const formatRating = rating => rating.toString().replace(/\./g, ',')

export const getLocale = () => {
  let deviceLanguage =
    Platform.OS === 'ios'
      ? NativeModules.SettingsManager.settings.AppleLocale
      : NativeModules.I18nManager.localeIdentifier
  if (deviceLanguage !== 'nl_NL') {
    deviceLanguage = 'en_GB'
  }
  return deviceLanguage
}

export const getFormattedChildrenAge = dob => {
  const dobMoment = moment(dob)

  const years = moment().diff(dobMoment, 'years')
  dobMoment.add(years, 'years')
  const months = moment().diff(dobMoment, 'months')
  if (years > 4) {
    return `${years} ${strings.years}`
  }
  if (years > 0 && years <= 4 && months > 0) {
    return `${years} ${strings.years} ${strings.and} ${months} ${strings.months}`
  }

  if (years > 0 && months === 0) {
    return `${years} ${strings.years}`
  }

  return `${months} ${strings.months}`
}

export const getOnboardingStep = onboardingStep => {
  switch (onboardingStep) {
    case 'references':
      return {
        screen: 'SignUpAngelReferences'
      }
    case 'short_bio':
      return {
        screen: 'AngelDescriptionScreen'
      }
    case 'preferences':
      return {
        screen: 'SignUpAngelPreferences'
      }
    case 'availability':
      return {
        screen: 'SignUpAngelAlmostReady',
        progress: 100
      }
    case 'calendar':
      return {
        screen: 'SignUpAngelAlmostReady',
        progress: 100
      }
    case 'video':
      return {
        screen: 'SignUpAngelAlmostReady',
        progress: 100
      }
    default:
      return {
        screen: 'AngelDescriptionScreen'
      }
  }
}

export const getAngelOnboardingProgress = value => {
  const ONBOARDING_STEPS = [
    {
      screen: 'SignUpAngelQuestion',
      progress: 20
    },
    { screen: 'SignUpAngelNoBookings', progress: 20 },
    {
      screen: 'SignUpAngelAlmostReady',
      progress: 30
    },
    {
      screen: 'SignUpAngelPreferences',
      progress: 35
    },
    {
      screen: 'SignUpAngelFixedPreferences',
      progress: 40
    },
    {
      screen: 'AngelDescriptionScreen',
      progress: 45
    },
    {
      screen: 'SignUpAngelMakePicture',
      progress: 50
    },
    {
      screen: 'ConnectAngelToFamilyScreen',
      progress: 60
    },
    {
      screen: 'SignUpAngelReferences',
      progress: 80
    },
    {
      screen: 'SignUpAngelMakeAppointment',
      progress: 90
    },
    {
      screen: 'SignUpReadyToFly',
      progress: 100
    }
  ]

  const step = ONBOARDING_STEPS.find(s => Object.values(s).includes(value))

  return step || ONBOARDING_STEPS[0]
}

export function shuffle(array = []) {
  let currentIndex = array.length
  let randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}

export function validURL(str) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(str)
}
