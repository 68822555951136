import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Card, TextInput, ScreenLayout } from '@charlycares/ui'
import { Box, Spacer, Switch, Text, Heading } from 'native-base'
import { useRouter } from '@charlycares/shared'
import { setB2bCode, useAppSelector, useValidateBusinessDataMutation } from '@charlycares/data-access'

const BusinessAccountConnectionCode = () => {
  const { t } = useTranslation()
  const { navigate } = useRouter()
  const [permission, setPermission] = useState(false)
  const has_membership = useAppSelector(state => state.User.user.has_membership)
  const [code, setCode] = useState(__DEV__ ? '8DBBOYTW' : '')
  const dispatch = useDispatch()
  const [validate, validationState] = useValidateBusinessDataMutation()
  const error: any = validationState.error

  useEffect(() => {
    if (validationState.isSuccess) {
      dispatch(setB2bCode({ code }))

      if (has_membership) {
        navigate('BusinessAccountConnectMembership', '/profile-business-account-connect-membership')
      } else {
        navigate('BusinessAccountStartMembership', '/profile-business-account-start-membership')
      }
    }
  }, [validationState.isSuccess, dispatch, navigate, code, has_membership])

  return (
    <ScreenLayout
      title={t('businessAccountHeaderTitle')}
      _buttonProps={{
        onPress: () => validate({ code }),
        isDisabled: !code || !permission,
        children: t('register'),
        isLoading: validationState.isLoading
      }}
    >
      <Card>
        <Heading fontWeight="600" fontSize="22px" mb="20px">
          {t('connectBusinessAccountTitle')}
        </Heading>
        <Text fontSize="16px" mb="16px">
          {t('businessParringCodeDesc')}
        </Text>

        <TextInput
          onChangeText={setCode}
          value={code}
          label={t('connectBusinessAccountInputLabel')}
          placeholder={t('connectBusinessAccountInputPlaceholder')}
          error={error?.data?.errors?.code ?? ''}
          mt="25px"
          mb="25px"
          returnKeyType="done"
        />

        <Box mt="10px" flexDir="row" alignItems="center" w="100%" justifyContent="space-between">
          <Text fontSize="14px" w="70%">
            {t('businessPermissionDesc')}
          </Text>
          <Spacer />
          <Switch size="md" onToggle={() => setPermission(prev => !prev)} value={permission} />
        </Box>
      </Card>
    </ScreenLayout>
  )
}

BusinessAccountConnectionCode.navigationOptions = () => ({
  headerShown: false
})

export default BusinessAccountConnectionCode
