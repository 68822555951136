import { Platform } from 'react-native'
import config from 'react-native-config'

export const isWeb = Platform.OS === 'web'
export const isIos = Platform.OS === 'ios'
export const isAndroid = Platform.OS === 'android'
export const isDev = process.env.NODE_ENV === 'development'
export const isProduction = config.NX_APP_ENV === 'production'

export const deploymentKey = Platform.select({
  ios: config.NX_CODE_PUSH_KEY_IOS,
  android: config.NX_CODE_PUSH_KEY_ANDROID
})

export const links = {
  store: {
    ios: 'https://apps.apple.com/nl/app/charly-cares/id1140986378',
    android: 'https://play.google.com/store/apps/details?id=nl.digitalchefs.charlycares&hl=nl'
  },
  support: 'https://support.charlycares.com',
  angel: {
    exampleVideo: 'https://s3.eu-central-1.amazonaws.com/charlycares-videos/Mijn+film.mp4'
  },
  terms: {
    en: 'https://www.charlycares.com/en/babysitter-agreement',
    nl: 'https://www.charlycares.com/gebruikersovereenkomst'
  },
  privacy: {
    en: 'https://www.charlycares.com/en/privacy',
    nl: 'https://www.charlycares.com/privacy'
  },
  earnings: {
    en: 'https://www.charlycares.com/babysitters/earnings',
    nl: 'https://www.charlycares.com/nl/oppassers/verdiensten'
  },
  memberships: {
    subscriptions:
      parseInt(Platform.Version as string) > 12
        ? 'https://apps.apple.com/account/subscriptions'
        : 'https://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/manageSubscriptions'
  },
  ratings: {
    family: {
      en: 'https://www.charlycares.com/parents/improve-rating',
      nl: 'https://www.charlycares.com/nl/ouders/rating-verbeteren'
    },
    angel: {
      en: 'https://www.charlycares.com/babysitters/improve-rating',
      nl: 'https://www.charlycares.com/nl/oppassers/rating-verbeteren'
    }
  },
  cancellation_policy: {
    family: {
      en: 'https://www.charlycares.com/parents/cancellation-policy',
      nl: 'https://www.charlycares.com/nl/ouders/annuleringsbeleid'
    },
    angel: {
      en: 'https://www.charlycares.com/babysitters/cancellation-policy',
      nl: 'https://www.charlycares.com/nl/oppassers/annuleringsbeleid'
    }
  },
  job_board: {
    en: 'https://www.charlycares.com/parents/babysitting-app/jobboard',
    nl: 'https://www.charlycares.com/nl/ouders/oppas-app/jobboard'
  }
}
