import React from 'react'

type LoaderProps = {
  loading?: boolean
  children?: React.ReactElement | React.ReactElement[] | null
  component?: React.ReactElement | React.ReactElement[] | null
}

/**
 * @deprecated Use native-base skeleton or a spinner
 */
const Loader: React.FC<LoaderProps> = ({ children, loading, component }) => {
  return loading ? <>{component}</> : <>{children}</>
}

export default Loader
