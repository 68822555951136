import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { isMobile } from 'react-device-detect'
import theme from '../styles/theme'

const Text = styled.Text`
  width: ${({ width }) => width};
  font-family: ${({ fontFamily }) => fontFamily};
  margin: ${({ margins }) => margins || '0px'};
  padding: ${({ paddings }) => paddings};
  color: ${({ color }) =>
    color ||
    (props => {
      if (props.white) return theme.colors.white
      if (props.primary) return theme.colors.primary
      return theme.colors.grey01
    })};
  font-size: ${props => {
    if (props.xxl) return theme.fontSize.xxl
    if (props.xl) return theme.fontSize.xl
    if (props.lg) return theme.fontSize.lg
    if (props.md) return theme.fontSize.md
    if (props.sm) return theme.fontSize.sm
    return theme.fontSize.lg
  }};
  font-weight: ${props => theme.headingsFontWeight};
  ${({ lineHeight }) => lineHeight && { 'line-height': lineHeight }};
  text-align: ${({ textAlign }) => textAlign || 'auto'};
  text-transform: ${({ textTransform }) => textTransform};
`

const Heading = ({
  ariaLevel,
  text,
  width,
  margins,
  paddings,
  color,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  textAlign,
  opacity,
  numberOfLines,
  textTransform,
  ...props
}) => (
  <Text
    accessibilityRole={isMobile ? 'heading' : 'header'}
    width={width}
    aria-level={ariaLevel}
    margins={margins}
    paddings={paddings}
    color={color}
    fontFamily={fontFamily}
    fontSize={fontSize}
    fontWeight={fontWeight}
    lineHeight={lineHeight}
    textAlign={textAlign}
    opacity={opacity}
    numberOfLines={numberOfLines}
    textTransform={textTransform}
    {...props}>
    {text}
  </Text>
)

Heading.propTypes = {
  text: PropTypes.string.isRequired,
  width: PropTypes.string,
  margins: PropTypes.string,
  paddings: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontFamily: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textAlign: PropTypes.string,
  opacity: PropTypes.number,
  numberOfLines: PropTypes.number,
  ariaLevel: PropTypes.number,
  textTransform: PropTypes.string
}

Heading.defaultProps = {
  width: 'auto',
  margins: theme.headingsMargins,
  paddings: '0px',
  fontSize: theme.fontSize.md,
  fontWeight: theme.headingsFontWeight,
  fontFamily: theme.fontFamilyBase,
  textAlign: 'auto',
  lineHeight: null,
  color: theme.headingsColorBase,
  opacity: 1,
  ariaLevel: 2,
  numberOfLines: null,
  textTransform: 'none'
}

export default Heading
