/**
 * @description  New users without membership to start business account membership
 */

import React, { useEffect } from 'react'
import { Card, ScreenLayout, SvgUri } from '@charlycares/ui'
import { Center, Heading, Box } from 'native-base'
import { useTranslation } from 'react-i18next'
import { Alert, Platform } from 'react-native'
import { isWeb, useRouter } from '@charlycares/shared'
import { useAppSelector, useConnectBusinessMembershipMutation, useGetBusinessDataQuery } from '@charlycares/data-access'

import { BusinessAccountUsps } from './common'

const BusinessAccountConnectMembership = () => {
  const code = useAppSelector(state => state.b2b.code)
  const { data, isLoading, isError } = useGetBusinessDataQuery({ code })
  const [connect, state] = useConnectBusinessMembershipMutation()
  const { t } = useTranslation()
  const { navigate } = useRouter()

  useEffect(() => {
    if (state.error) {
      const e: any = state.error
      if (isWeb) {
        alert(e.data?.message)
      } else {
        Alert.alert(t('error'), e.data?.message)
      }
    }
  }, [state.error, t])

  useEffect(() => {
    if (state.isSuccess) {
      navigate('BusinessAccountConnected', '/profile-business-account-connected')
    }
  }, [state.isSuccess, navigate])

  return (
    <ScreenLayout
      isLoading={isLoading || isError || !data}
      title={t('businessAccountHeaderTitle')}
      _buttonProps={{
        onPress: () =>
          connect({
            code,
            share_permission: true,
            channel: Platform.OS
          }),
        isDisabled: !data,
        isLoading: state.isLoading,
        children: t('connect')
      }}
      _buttonDisclaimer={{ children: t('startBusinessFooterDesc') }}
    >
      <Card>
        {data && (
          <>
            <Heading fontWeight="600" fontSize="22px" mb="20px">
              {t('startBusinessAccountTitle')}
            </Heading>
            {data.data.company.logo_url && data.data.company.logo_url.includes('.svg') && (
              <Center w="100%" mt="20px">
                <SvgUri uri={data.data.company.logo_url} width={200} height={100} />
              </Center>
            )}

            <Box mt="20px" mb="50px">
              <BusinessAccountUsps minutes={data?.data.time.amount ?? 0} />
            </Box>
          </>
        )}
      </Card>
    </ScreenLayout>
  )
}

BusinessAccountConnectMembership.navigationOptions = () => ({
  headerShown: false
})

export default BusinessAccountConnectMembership
