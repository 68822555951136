import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../elements';
import TextView from './TextView';
import theme from '../../styles/theme';

const TextTextView = ({
  firstText,
  firstTextWidth,
  firstTextMargins,
  firstTextAlign,
  firstTextColor,
  firstTextFontWeight,
  firstTextFontFamily,
  firstTextFontSize,
  firstTextNumberOfLines,
  secondText,
  secondTextWidth,
  secondTextMargins,
  secondTextAlign,
  secondTextColor,
  secondTextFontWeight,
  secondTextFontFamily,
  secondTextFontSize,
  secondTextNumberOfLines,
  paddings,
  justifyContent,
  ...restViewProps
}) => (
  <TextView
    paddings={paddings}
    justifyContent={justifyContent}
    text={firstText}
    textMargins={firstTextMargins}
    textAlign={firstTextAlign}
    textColor={firstTextColor}
    fontWeight={firstTextFontWeight}
    fontFamily={firstTextFontFamily}
    fontSize={firstTextFontSize}
    textWidth={firstTextWidth}
    numberOfLines={firstTextNumberOfLines}
    {...restViewProps}>
    <Text
      width={secondTextWidth}
      margins={secondTextMargins}
      textAlign={secondTextAlign}
      color={secondTextColor}
      fontWeight={secondTextFontWeight}
      fontFamily={secondTextFontFamily}
      fontSize={secondTextFontSize}
      numberOfLines={secondTextNumberOfLines}>
      {secondText}
    </Text>
  </TextView>
);

TextTextView.propTypes = {
  paddings: PropTypes.string,
  justifyContent: PropTypes.string,
  firstText: PropTypes.string.isRequired,
  firstTextWidth: PropTypes.string,
  firstTextMargins: PropTypes.string,
  firstTextAlign: PropTypes.string,
  firstTextColor: PropTypes.string,
  firstTextFontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  firstTextFontFamily: PropTypes.string,
  firstTextFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  firstTextNumberOfLines: PropTypes.number,
  secondText: PropTypes.string.isRequired,
  secondTextWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  secondTextMargins: PropTypes.string,
  secondTextAlign: PropTypes.string,
  secondTextColor: PropTypes.string,
  secondTextFontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  secondTextFontFamily: PropTypes.string,
  secondTextFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  secondTextNumberOfLines: PropTypes.number
};

TextTextView.defaultProps = {
  paddings: '8px 0px 8px 0px',
  justifyContent: 'space-between',
  firstTextWidth: 'auto',
  firstTextMargins: '0',
  firstTextAlign: 'auto',
  firstTextColor: theme.textColorBase,
  firstTextFontWeight: theme.textFontWeight,
  firstTextFontFamily: theme.fontFamilyBase,
  firstTextFontSize: '16px',
  firstTextNumberOfLines: null,
  secondTextWidth: 'auto',
  secondTextMargins: '0',
  secondTextAlign: 'right',
  secondTextColor: theme.colors.grey03,
  secondTextFontWeight: theme.textFontWeight,
  secondTextFontFamily: theme.fontFamilyBase,
  secondTextFontSize: '13px',
  secondTextNumberOfLines: null
};

export default TextTextView;
