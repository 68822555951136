import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

import { logout, TextButton, theme } from '@charlycares/legacy/web'

const View = styled.View`
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  padding-top: 16px;
  width: 100%;
  border-top-width: 1px;
  border-color: ${theme.dividerLineColor};
`

const Subnavbar = ({ logOut }) => {
  const [t] = useTranslation()
  const history = useHistory()
  const location = useLocation()

  return (
    <View>
      <TextButton
        borderTopWidth={0}
        borderBottomWidth={0}
        text={t('support')}
        textColor={
          location.pathname.includes('support')
            ? theme.colors.secondary
            : theme.colors.defaultBtnTextColor
        }
        fontWeight={theme.textFontWeight}
        onPress={() => history.push('/support')}
      />
      <TextButton
        borderTopWidth={0}
        borderBottomWidth={0}
        text={t('logOut')}
        textColor={theme.colors.defaultBtnTextColor}
        fontWeight={theme.textFontWeight}
        onPress={logOut}
      />
    </View>
  )
}

Subnavbar.propTypes = {
  logOut: PropTypes.func.isRequired
}

const mapDispatchToProps = {
  logOut: logout
}

export default connect(null, mapDispatchToProps)(Subnavbar)
