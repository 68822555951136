import React, { useEffect, useState } from 'react'
import { Actionsheet, Box, CloseIcon, Heading, HStack, IconButton, Modal, Text } from 'native-base'
import { CharlyIcon } from '@charlycares/ui'
import { getUserToken, links } from '@charlycares/shared'
import { useTranslation } from 'react-i18next'
import { Alert, Linking } from 'react-native'
import { isMobile } from 'react-device-detect'
// @ts-ignore
import QRCode from 'qrcode.react'

export default function DownloadAppOverlay({ isOpen = true }: { isOpen: boolean }) {
  const { t } = useTranslation()
  const [token, setToken] = useState<string | null>(null)
  const [show, setShow] = useState(isOpen)

  const url = `https://charlycares.page.link/?link=https://app.charlycares.com/login?screen%3DLogin%26token%3D${token}&apn=nl.digitalchefs.charlycares&isi=1140986378&ibi=com.charlycares.charlycares`

  async function openApp() {
    const supported = await Linking.canOpenURL(url)

    if (supported) {
      await Linking.openURL(url)
    } else {
      Alert.alert('Error', 'Can not open the app!')
    }
  }

  useEffect(() => {
    getUserToken().then(token => {
      setToken(token)
    })
  }, [])

  const closeBtn = (
    <IconButton
      hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
      zIndex={1}
      position="absolute"
      top="10px"
      right="10px"
      bgColor="gray.500"
      rounded="full"
      w="28px"
      h="28px"
      _focus={{ outlineStyle: 'none' } as any}
      icon={<CloseIcon size="12px" color="gray.800" />}
      onPress={() => setShow(false)}
    />
  )

  const linkButtons = (
    <HStack alignItems="center" justifyContent="space-evenly" mb="16px">
      <CharlyIcon
        name="app-store"
        width="140px"
        height="40px"
        color="white"
        onPress={() => (isMobile ? openApp() : Linking.openURL(links.store.ios))}
      />
      <CharlyIcon
        name="google-play"
        width="140px"
        height="40px"
        color="white"
        onPress={() => (isMobile ? openApp() : Linking.openURL(links.store.android))}
      />
    </HStack>
  )

  return (
    <>
      {isMobile ? (
        <Actionsheet isOpen={show} onClose={() => setShow(true)} _backdrop={{ bgColor: 'gray.900', opacity: 0.7 }}>
          <Actionsheet.Content justifyContent="center" bgColor="white" px="20px">
            {/*{closeBtn}*/}
            <Heading fontSize="20px" color="gray.900" alignSelf="center" mb="16px">
              {t('downloadApp.title')}
            </Heading>
            <Box width="100%">
              <Text fontSize="15px" color="gray.800" fontWeight="400" mb="24px">
                {t('downloadApp.description')}
              </Text>
              {linkButtons}
            </Box>
          </Actionsheet.Content>
        </Actionsheet>
      ) : (
        <Modal
          isOpen={show}
          onClose={() => setShow(true)}
          closeOnOverlayClick={false}
          _backdrop={{ bgColor: 'gray.900', opacity: 0.7 }}
        >
          <Modal.Content maxWidth="400px" borderRadius="16px">
            {/*{closeBtn}*/}
            <Modal.Body>
              {/*<Box w="200%" h="1px" bgColor="gray.400" mt="30px" ml="-20px" />*/}
              <Heading fontSize="22px" color="gray.900" alignSelf="center" m="16px">
                {t('downloadApp.web.title')}
              </Heading>
              <Text fontSize="15px" color="gray.800" fontWeight="400" mb="16px">
                {t('downloadApp.web.description')}
              </Text>
              <Heading fontSize="18px" color="gray.900" alignSelf="center" mb="16px">
                {t('downloadApp.web.subTitle')}
              </Heading>
              <Box alignItems="center" justifyContent="space-evenly" mb="24px">
                <QRCode includeMargin value={url} size={150} />
              </Box>
              {linkButtons}
            </Modal.Body>
          </Modal.Content>
        </Modal>
      )}
    </>
  )
}
