/**
 * @description  New users without membership to start business account
 */
import React from 'react'
import { Card, SvgUri, ScreenLayout } from '@charlycares/ui'
import { Button, Text, Heading, Box, Center, VStack, HStack, CheckIcon } from 'native-base'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components/native'
import { useRouter } from '@charlycares/shared'
import { useAppSelector, useGetBusinessDataQuery } from '@charlycares/data-access'

import { BusinessAccountUsps } from './common'

const BusinessAccountStartMembership = () => {
  const code = useAppSelector(state => state.b2b.code)
  const { data, isLoading, isError } = useGetBusinessDataQuery({ code })
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { navigate } = useRouter()

  const START_BUSINESS_ACCOUNT_PRIVATE_CHECKLIST = [
    t('startBusinessAndPrivateUsp1'),
    t('startBusinessAndPrivateUsp2'),
    t('startBusinessAndPrivateUsp3')
  ]

  const onStartMembership = (isPrivate?: boolean) => {
    navigate('BusinessConnectBankAccount', '/profile-business-bank-account', {
      isPrivate
    })
  }

  return (
    <ScreenLayout isLoading={isLoading || isError || !data} title={t('businessAccountHeaderTitle')}>
      {data && (
        <>
          <Card>
            {data.data.company.logo_url && data.data.company.logo_url.includes('.svg') && (
              <Center w="100%">
                <SvgUri uri={data.data.company.logo_url} width={200} height={100} />
              </Center>
            )}

            <Heading mb="16px" fontSize="22px">
              {t('startBusinessAccountTitle')}
            </Heading>

            <BusinessAccountUsps minutes={data?.data.time.amount ?? 0} />

            <Box mt="10px" p="10px 20px" bg={colors.grey09}>
              <Heading fontSize="16">{t('startBusinessPrivateTitle')}</Heading>
              <Text>{t('startBusinessPrivateText')}</Text>
            </Box>

            <Button w="100%" mt="25px" onPress={() => onStartMembership()}>
              {t('startBusinessBtn')}
            </Button>
          </Card>

          <Box p="20px" w="100%">
            <Heading fontSize="22px">{t('startBusinessBookPrivateTitle')}</Heading>
            <Text fontSize="16px" mb="16px">
              {t('startBusinessBookPrivateText')}
            </Text>

            <VStack space="20px" margin="10px">
              {START_BUSINESS_ACCOUNT_PRIVATE_CHECKLIST.map(text => (
                <HStack space="10px" key={text}>
                  <CheckIcon size="30px" color="secondary.400" />
                  <Text fontSize="15px" color="gray.900" paddingRight="40px">
                    {text}
                  </Text>
                </HStack>
              ))}
            </VStack>

            <Button onPress={() => onStartMembership(true)} w="100%">
              {t('startBusinessPrivateBtn')}
            </Button>
          </Box>

          <Card>
            <Text fontSize="12px">{t('startBusinessFooterDesc')}</Text>
          </Card>
        </>
      )}
    </ScreenLayout>
  )
}

BusinessAccountStartMembership.navigationOptions = () => ({
  headerShown: false
})

export default BusinessAccountStartMembership
