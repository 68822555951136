import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CreditCardIcon, IcnSendIcon, ScreenLayout } from '@charlycares/ui'
import { PaymentsMethods } from './common'
import { Box, HStack, Text, Icon, Heading } from 'native-base'
import { useSubscribeMembershipMutation } from '@charlycares/data-access'
import { Linking, Platform } from 'react-native'
import { isWeb, MEMBERSHIP_TYPES, useRouter } from '@charlycares/shared'

export default function SetupBankAccount() {
  const { t } = useTranslation()
  const [subscribeMembership, subscribeResult] = useSubscribeMembershipMutation()
  const { getParam } = useRouter()
  const membershipType = getParam('membershipType') ?? MEMBERSHIP_TYPES['FLEXIBLE_TRIAL']
  const setupBankAccountUsps = [
    {
      text: t('setupBankAccount.usp1'),
      icon: CreditCardIcon
    },
    {
      text: t('setupBankAccount.usp2'),
      icon: IcnSendIcon
    }
  ]
  const setupBankAccount = (paymentMethod: string) => {
    subscribeMembership({ payment_method: paymentMethod, channel: Platform.OS, membership_type: membershipType })
  }

  useEffect(() => {
    if (subscribeResult.data) {
      if (isWeb) {
        window.open(subscribeResult.data?.data.redirect_url, '_self')
      } else {
        Linking.openURL(subscribeResult.data?.data.redirect_url)
      }
    }
  }, [subscribeResult.data])

  return (
    <ScreenLayout title={t('setupBankAccount.title')} bgColor="white">
      <Card borderWidth="0">
        <Heading mb="10px" mt="30px" fontSize="20px" fontWeight="600">
          {t('setupBankAccount.uspTitle')}
        </Heading>

        <Box mb="50px">
          {setupBankAccountUsps.map(({ text, icon }) => (
            <HStack space={2} key={text} marginBottom="10px">
              <Icon as={icon} size="5" mt="0.5" color="gray.900" />
              <Text fontSize="md" color="gray.900" paddingRight="60px">
                {text}
              </Text>
            </HStack>
          ))}
        </Box>

        <PaymentsMethods membershipType={membershipType} onPress={methodId => setupBankAccount(methodId)} />

        <Text fontSize="12px" color="gray.700" mt="80px">
          {t('setupBankAccount.disclaimer')}
        </Text>
      </Card>
    </ScreenLayout>
  )
}
