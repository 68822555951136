import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setNotificationsRead } from '../../stores/actions/chatAction';
import { Text, Divider } from '../../elements';
import { TextView, TextTextView, Header, IconButton } from '../../components';
import { getFormatedDate } from '../../utils/functions';
import colors from '../../styles/colors';

import leftIcon from '../../../assets/icons/icn-nav-left.png';

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  background-color: ${colors.backgroundColor};
`;
const FlatList = styled.FlatList`
  flex: 1;
`;

const Notifications = ({ history, navigation, notifications, setAllNotificationsRead }) => {
  const [t] = useTranslation();

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('chatScreensMessageFromCharly')}
        </Text>
      )
    });
  }

  useEffect(() => {
    setAllNotificationsRead();
  }, []);

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          title={t('chatScreensMessageFromCharly')}
          left={
            <IconButton
              iconSource={leftIcon}
              iconSize="32px"
              iconColor={colors.secondary}
              onPress={history.goBack}
            />
          }
        />
      )}
      <Divider />

      <FlatList
        data={notifications}
        renderItem={({ item, index }) => {
          const { title, message, created_at, read } = item;

          return (
            <TextView
              flexDirection="column-reverse"
              alignItems="flex-start"
              paddings="15px 20px 15px 20px"
              width="100%"
              borderBottomWidth={index === notifications.length - 1 ? '1px' : 0}
              backgroundColor={colors.white}
              text={message}
              fontSize="13px"
              textColor={colors.grey03}>
              <TextTextView
                paddings="0px"
                margins="0px 0px 5px 0px"
                width="100%"
                firstText={title}
                firstTextWidth="70%"
                firstTextFontFamily="Martel-DemiBold"
                firstTextFontWeight="600"
                firstTextNumberOfLines={1}
                secondText={getFormatedDate(created_at, 'DD-MM-YYYY')}
                secondTextFontSize="12px"
                secondTextFontWeight="600"
                secondTextColor={read ? colors.grey04 : colors.notify}
              />
            </TextView>
          );
        }}
        ItemSeparatorComponent={() => <Divider minHeight="5px" />}
        keyExtractor={({ id }) => String(id)}
      />
    </SafeAreaView>
  );
};

Notifications.propTypes = {
  history: PropTypes.shape({ goBack: PropTypes.func }),
  navigation: PropTypes.shape({ setOptions: PropTypes.func }),
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
  setAllNotificationsRead: PropTypes.func.isRequired
};

Notifications.defaultProps = {
  history: null,
  navigation: null,
  notifications: []
};

const mapStateToProps = ({ Chat }) => ({
  notifications: Chat.notifications
});

const mapDispatchToProps = {
  setAllNotificationsRead: setNotificationsRead
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
