import * as React from 'react'
import Svg, { SvgProps, G, Rect, Path, Defs, ClipPath } from 'react-native-svg'

const VideoPlayIcon = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 25 20" fill="none" {...props}>
    <G clipPath="url(#prefix__clip0_1255_4746)">
      <Rect width={25} height={20} rx={3} fill="rgba(151, 151, 154, 0.8)" fillOpacity={0.8} />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.311 5.126l6.956 4.23a.863.863 0 01.04 1.449L9.35 15.578A.863.863 0 018 14.866V5.863a.863.863 0 011.311-.737z"
        fill="white"
      />
    </G>
    <Defs>
      <ClipPath id="prefix__clip0_1255_4746">
        <Path fill="rgba(151, 151, 154, 0.8)" d="M0 0h25v20H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default VideoPlayIcon
