/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Alert } from 'react-native';

import {
  getMembershipReasons,
  cancelMembership,
  updateMembership
} from '../../../../stores/actions/userAction';
import { Text, Divider } from '../../../../elements';
import {
  TextView,
  TextTextView,
  TextButton,
  TextIconButton,
  InputView,
  FreeMembership,
  Header,
  IconButton
} from '../../../../components';
import colors from '../../../../styles/colors';
import { isWeb } from '../../../../utils/functions';

import leftIcon from '../../../../../assets/icons/icn-nav-left.png';

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  justify-content: space-between;
`;
const ScrollView = styled.ScrollView``;
const ButtonsView = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px 10px 20px;
  background-color: ${colors.backgroundColor};
  border-top-width: 1px;
  border-color: ${colors.grey08};
`;

const CancelReasons = ({
  history,
  navigation,
  cancelationReasons,
  updateMembershipPlan,
  cancelMembershipPlan,
  getMembershipCancelationReasons,
  hasMembership
}) => {
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [newReason, setNewReason] = useState('');
  const [showFreeMembership, setShowFreeMembership] = useState(false);
  const [t] = useTranslation();
  const modalRef = useRef(null);

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('profileScreensCancelMembership')}
        </Text>
      )
    });
  }

  useEffect(() => {
    if (cancelationReasons.length === 0) {
      getMembershipCancelationReasons();
    }
  }, []);

  useEffect(() => {
    if (!hasMembership) {
      //   if (showFreeMembership) {
      //     modalRef.show();
      //   }
      if (isWeb) {
        location.reload();
      } else {
        navigation.popToTop();
      }
    }
  }, [hasMembership]);

  const toggleSelectReason = (category, reason, isSelected) => {
    setSelectedReasons(() => {
      if (isSelected) {
        return selectedReasons.filter((selectedReason) => selectedReason.category !== category);
      }
      return [...selectedReasons, { category, reason }];
    });
  };

  const onCancelMembership = () => {
    if (selectedReasons.find(({ category }) => category === 'corona')) {
      setShowFreeMembership(true);
    }
    cancelMembershipPlan(selectedReasons, newReason);
  };

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          title={t('profileScreensCancelMembership')}
          left={
            <IconButton
              iconSize="32px"
              iconColor={colors.secondary}
              iconSource={leftIcon}
              onPress={history.goBack}
            />
          }
        />
      )}
      <ScrollView>
        <Divider />

        <TextTextView
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          margins="20px"
          firstText={t('profileScreensWhyYouCancelSubscription')}
          firstTextFontFamily="Martel"
          firstTextFontSize="18px"
          firstTextFontWeight="600"
          secondText={t('profileScreensCancelFeedback')}
          secondTextMargins="20px 0px 0px 0px"
          secondTextFontSize="15px"
          secondTextFontWeight="300"
          secondTextAlign="left"
        />

        {cancelationReasons.map(({ category, reason }) => {
          const isSelected = selectedReasons.filter(
            (selectedReason) => selectedReason.category === category
          ).length;

          return (
            <TextIconButton
              key={category}
              flexDirection="row"
              justifyContent="space-between"
              margins="0px 20px 0px 20px"
              paddings="15px 0px 15px 0px"
              height="auto"
              borderWidth={0}
              borderBottomWidth="1px"
              borderTopWidth={0}
              borderColor={colors.grey08}
              text={reason}
              textFlex={1}
              textAlign="left"
              fontWeight="300"
              textColor={colors.primaryText}
              iconName={isSelected ? 'btn-check' : 'btn-plus'}
              iconMargins="0px 0px 0px 10px"
              iconSize="32px"
              iconColor={isSelected ? colors.primary : colors.grey07}
              onPress={() => toggleSelectReason(category, reason, isSelected)}
            />
          );
        })}

        <TextView
          flexDirection="column"
          alignItems="flex-start"
          margins="40px 20px 20px 20px"
          text={t('profileScreensPersonalFeedback')}
          fontSize="15px"
          fontWeight="600"
          textColor={colors.grey02}>
          <InputView
            margins="20px 10px 0px 0px"
            width="100%"
            maxInputHeight="100px"
            minInputHeight="100px"
            fontSize="13px"
            textAlignVertical="top"
            multiline
            showUnderline
            inputBorderColor={colors.primary}
            placeholder={t('profileScreensPersonalFeedbackPlaceholder')}
            value={newReason}
            onChangeText={setNewReason}
          />
        </TextView>
      </ScrollView>

      <ButtonsView>
        <TextButton
          width="40%"
          borderColor="transparent"
          text={t('cancel')}
          textColor={colors.secondary}
          onPress={() => {
            if (navigation) {
              navigation.goBack();
            } else {
              history.goBack();
            }
          }}
        />

        <TextButton
          width="60%"
          backgroundColor={colors.primary}
          text={t('profileScreensSendAndCancel')}
          fontSize="16px"
          fontWeight="600"
          onPress={onCancelMembership}
        />
      </ButtonsView>

      <FreeMembership
        ref={modalRef}
        onReactivatePress={() => updateMembershipPlan('flexible', 'monthly')}
      />
    </SafeAreaView>
  );
};

CancelReasons.propTypes = {
  history: PropTypes.shape({ goBack: PropTypes.func, push: PropTypes.func }),
  navigation: PropTypes.shape({
    setOptions: PropTypes.func,
    goBack: PropTypes.func,
    popToTop: PropTypes.func
  }),
  cancelationReasons: PropTypes.arrayOf(PropTypes.string),
  updatingMembership: PropTypes.bool,
  getMembershipCancelationReasons: PropTypes.func.isRequired,
  cancelMembershipPlan: PropTypes.func.isRequired,
  updateMembershipPlan: PropTypes.func.isRequired,
  hasMembership: PropTypes.bool
};

CancelReasons.defaultProps = {
  history: null,
  navigation: null,
  cancelationReasons: [],
  updatingMembership: false,
  hasMembership: false
};

const mapStateToProps = ({ User }) => ({
  cancelationReasons: User.user.cancelationReasons,
  hasMembership: User.user.has_membership
});

const mapDispatchToProps = {
  getMembershipCancelationReasons: getMembershipReasons,
  cancelMembershipPlan: cancelMembership,
  updateMembershipPlan: updateMembership
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelReasons);
