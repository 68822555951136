import React from 'react'
import { Box, Center, Flex, Text, useTheme } from 'native-base'
import { useTranslation } from 'react-i18next'
import { BookingCancellationPolicy } from '@charlycares/data-access'
import moment from 'moment'

type Props = {
  bookingCancellationPolicies: BookingCancellationPolicy[]
}

export default function BookingCancellationPolicySummary({ bookingCancellationPolicies }: Props) {
  const { t } = useTranslation()
  const { colors } = useTheme()

  return (
    <>
      <Box mt="10px" mb="15px">
        <Text color="gray.700" fontSize="14px">
          {'*'}
          {t('bookingsScreenCancelOrEditBookingDescription')}
        </Text>
      </Box>
      <Box>
        <Flex
          direction="row"
          borderColor={colors.gray[200]}
          alignItems="center"
          borderWidth="1"
          justifyContent="space-between"
        >
          <Flex flex={1} direction="column" alignItems="center">
            <Center w="100%" py="5px">
              <Text fontSize="14px">Policy</Text>
            </Center>
            <Center w="100%" py="5px" bg={colors.gray[100]}>
              <Text fontSize="14px" fontWeight="600">
                Comp.
              </Text>
            </Center>
          </Flex>
          {bookingCancellationPolicies.map((cancellation_policy, index) => (
            <Flex key={index} flex={1} direction="column" alignItems="center">
              <Center w="100%" py="5px">
                <Text fontSize="13px">{t(cancellation_policy.policy_type)}</Text>
              </Center>
              <Center w="100%" py="5px" bg={colors.gray[100]}>
                <Text fontSize="14px" fontWeight="600">
                  {moment.duration(cancellation_policy.penalty_interval).hours() === 0
                    ? '-'
                    : moment.duration(cancellation_policy.penalty_interval).hours() + ' hr'}
                </Text>
              </Center>
            </Flex>
          ))}
        </Flex>
      </Box>
    </>
  )
}
