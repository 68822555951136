import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, ScreenLayout } from '@charlycares/ui'

import { Button } from 'native-base'
import { isWeb, TransactionType, useRouter } from '@charlycares/shared'

import { InviteAngelCard } from '../common'
import { useGetWalletsQuery } from '@charlycares/data-access'
import Wallets from '../../../payments/common/Wallets'

export default function BusinessAccountConnected({ navigation, location }: any) {
  const { t } = useTranslation()
  const { navigate } = useRouter()
  const { data: wallets, isLoading } = useGetWalletsQuery(undefined, {
    refetchOnMountOrArgChange: true
  })
  const businessWallets = wallets?.filter(wallet => wallet.transaction_type === TransactionType.MINUTES)
  const shouldGoBack = isWeb ? Boolean(location.state?.shouldGoBack) : navigation.getParam('shouldGoBack', false)

  return (
    <ScreenLayout
      isLoading={isLoading || !businessWallets}
      title={t('businessAccountHeaderTitle')}
      hideBackButton={!shouldGoBack}
      headerRight={
        !shouldGoBack ? (
          <Button variant="text" onPress={() => navigate('ProfileScreen', '/profile')}>
            {t('done')}
          </Button>
        ) : undefined
      }
    >
      <>
        <Card>
          <Wallets wallets={businessWallets} />
        </Card>

        <InviteAngelCard />
      </>
    </ScreenLayout>
  )
}

BusinessAccountConnected.navigationOptions = () => ({
  headerShown: false
})
