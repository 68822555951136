import React, { useCallback } from 'react'
import { loadTranslations } from '../locale'

export default function useFetchTranslation() {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')
  const [isSuccess, setIsSuccess] = React.useState(false)

  const loadTranslation = useCallback(async () => {
    try {
      setLoading(true)
      await loadTranslations()
      setIsSuccess(true)
    } catch (error) {
      setError((error as any).data?.message)
      setIsSuccess(false)
    } finally {
      setLoading(false)
    }
  }, [])

  React.useEffect(() => {
    loadTranslation()
  }, [loadTranslation])

  return { error, loading, isSuccess }
}
