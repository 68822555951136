import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';

import { Icon } from '../../components';
import { Text } from '../../elements';
import { AngelAvatar, FamilyAvatar } from '../Profile';
import {
  getFormatedDate,
  roundMonetaryValue,
  getMembershipPlanName,
} from '../../utils/functions';
import colors from '../../styles/colors';

const CardContainer = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${({ paddings }) => paddings};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-top-width: 1px;
  border-bottom-width: ${({ borderBottomWidth }) => borderBottomWidth};
  border-color: ${colors.grey08};
`;
const AvatarContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;
const DateContainer = styled.View`
  flex-direction: column;
  margin: 0px 0px 0px 10px;
`;
const TotalAmountCurrentStateContainer = styled.View`
  flex-direction: column;
`;
const AmountSelectContainer = styled.View`
  flex-direction: row;
`;

const PaymentsCard = ({
  disabled,
  paddings,
  width,
  height,
  borderBottomWidth,
  role,
  outstanding,
  reason,
  profileImage,
  defaultImage,
  createdAt,
  totalAmount,
  currentState,
  membershipType,
  selected,
  canSelectPayment,
  onCardPress,
}) => {
  const [t] = useTranslation();
  const createdAtDay = getFormatedDate(createdAt, 'dddd');
  const cretedAtFormated = getFormatedDate(createdAt, 'LL');

  return (
    <CardContainer
      disabled={disabled}
      paddings={paddings}
      width={width}
      height={height}
      borderBottomWidth={borderBottomWidth}
      onPress={onCardPress}
    >
      <AvatarContainer>
        {role === 'family' ? (
          <AngelAvatar source={profileImage} defaultImage={defaultImage} />
        ) : (
          <FamilyAvatar source={profileImage} />
        )}

        <DateContainer>
          <Text fontSize="16px">{createdAtDay}</Text>

          <Text fontWeight="300" color={colors.grey02}>
            {`${cretedAtFormated} ${
              membershipType
                ? `(${getMembershipPlanName(t, membershipType)})`
                : ''
            } `}
          </Text>
        </DateContainer>
      </AvatarContainer>

      <AmountSelectContainer>
        <TotalAmountCurrentStateContainer>
          <Text
            fontSize="16px"
            textAlign="right"
            color={outstanding ? colors.alert : colors.grey02}
          >
            {`€ ${roundMonetaryValue(totalAmount)}`}
          </Text>

          {outstanding ? (
            <Text
              fontSize="12px"
              fontWeight="300"
              textAlign="right"
              color={colors.alert}
            >
              {reason}
            </Text>
          ) : (
            <Text
              fontSize="12px"
              fontWeight="300"
              textAlign="right"
              color={colors.grey02}
            >
              {currentState}
            </Text>
          )}
        </TotalAmountCurrentStateContainer>

        {canSelectPayment && (
          <Icon
            margins="0px 0px 0px 10px"
            size="32px"
            name={selected ? 'btn-check' : 'btn-plus'}
            color={selected ? colors.primary : colors.grey07}
          />
        )}
      </AmountSelectContainer>
    </CardContainer>
  );
};

PaymentsCard.propTypes = {
  disabled: PropTypes.bool,
  paddings: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderBottomWidth: PropTypes.number,
  role: PropTypes.string.isRequired,
  outstanding: PropTypes.bool,
  reason: PropTypes.string,
  profileImage: PropTypes.string,
  defaultImage: PropTypes.node,
  createdAt: PropTypes.string.isRequired,
  totalAmount: PropTypes.string.isRequired,
  currentState: PropTypes.string.isRequired,
  membershipType: PropTypes.string,
  selected: PropTypes.bool,
  canSelectPayment: PropTypes.bool,
  onCardPress: PropTypes.func.isRequired,
};

PaymentsCard.defaultProps = {
  disabled: false,
  paddings: '20px',
  width: '100%',
  height: '80px',
  borderBottomWidth: 0,
  reason: '',
  profileImage: null,
  defaultImage: null,
  outstanding: false,
  membershipType: null,
  selected: false,
  canSelectPayment: false,
};

export default PaymentsCard;
