import React, { useState, useEffect, useRef, lazy, Suspense } from 'react'
import { ActivityIndicator, Keyboard } from 'react-native'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { signup } from '../../../stores/actions/userAction'
import { Text } from '../../../elements'
import { TextView, TextButton, LabeledInput, LanguagesEdit, Picker } from '../../../components'
import colors from '../../../styles/colors'
import { isWeb } from '../../../utils/functions'
import DatePicker from '../../../components/Pickers/DatePickerWeb'

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  justify-content: space-between;
  overflow: hidden;
`
const View = styled.View``

const getAllLanguages = t => [
  { value: 'dutch', label: t('dutchLanguage') },
  { value: 'english', label: t('englishLanguage') },
  { value: 'french', label: t('frenchLanguage') },
  { value: 'german', label: t('germanLanguage') },
  { value: 'italian', label: t('italianLanguage') },
  { value: 'spanish', label: t('spanishLanguage') }
]

const Step2 = ({ location, history, route, navigation, fetching, signupError, user, signUp }) => {
  const { step1Data } = route.params || location.state || {}
  const [birthDate, setBirthdate] = useState()
  const [education, setEducation] = useState('')
  const [fieldOfStudy, setFieldOfStudy] = useState('')
  const [languages, setLanguages] = useState([])
  const [inputFocused, setInputFocused] = useState(null)
  const educationInput = useRef(null)
  const fieldOfStudyInput = useRef(null)
  const languagePicker = useRef(null)
  const [t, i18n] = useTranslation()
  const languagesOptions = getAllLanguages(t)

  useEffect(() => {
    if (user) {
      if (navigation) {
        navigation.navigate('OnboardingAngelSignUpStack')
      } else {
        history.push({ pathname: '/signup-angel-final', state: user })
      }
    }
  }, [user])

  const onSignUpPress = () => {
    const dataToSend = { ...step1Data }

    dataToSend.birthdate = moment(birthDate).format('YYYY-MM-DD')
    dataToSend.education = education
    dataToSend.field_of_study = fieldOfStudy

    const selectedLanguages = {}
    languagesOptions.forEach(({ value }) => {
      selectedLanguages[value] = !!languages.find(language => language.value === value)
    })

    dataToSend.languages = selectedLanguages
    dataToSend.locale = i18n.language
    dataToSend.confirmation_link = 'something random'

    signUp('angel', dataToSend)
  }

  const languagesSelected = languages.map(({ label }) => label).join(', ')
  const disabled = !birthDate || !education || !fieldOfStudy || !languagesSelected || fetching

  return (
    <SafeAreaView>
      <View>
        <Text margins="30px 20px 0px 20px" fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('signUpAngelScreensMoreInfo')}
        </Text>
        <Text margins="10px 20px 0px 20px" fontSize="15px" fontWeight="300">
          {t('signUpAngelScreensMoreInfoDescription')}
        </Text>
        <TextView
          flexDirection="column"
          alignItems="flex-start"
          margins="20px 20px 0px 20px"
          text={t('birthdate')}
          fontSize="12px"
          fontWeight={300}
          color={colors.grey02}
        >
          <Suspense fallback={<ActivityIndicator />}>
            <DatePicker
              showTimeSelect={false}
              value={moment(birthDate).toDate()}
              onChange={date => setBirthdate(date)}
              labelValue={birthDate ? moment(birthDate).format('DD-MM-YYYY') : ''}
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              inputStyle={{
                width: 150,
                height: 30
              }}
            />
          </Suspense>
          {signupError && signupError.birthdate && (
            <Text fontSize="12px" color={colors.alert}>
              {signupError.birthdate}
            </Text>
          )}
        </TextView>
        <LabeledInput
          inputRef={educationInput}
          margins="20px 20px 0px 20px"
          borderColor={colors.secondary}
          backgroundColor={colors.white}
          inputBorderColor={
            inputFocused && inputFocused.education
              ? colors.secondary
              : colors.defaultBtnBackgroundColor
          }
          labelText={t('education')}
          inputMargins="5px 0px 0px 0px"
          inputPaddings="0px 0px 0px 5px"
          returnKeyType="done"
          placeholder={t('signUpAngelScreensEducationPlaceholder')}
          value={education}
          onChangeText={setEducation}
          onFocus={() => setInputFocused({ education: educationInput.current.isFocused() })}
          onSubmitEditing={() => Keyboard.dismiss()}
        />
        <LabeledInput
          inputRef={fieldOfStudyInput}
          margins="20px 20px 30px 20px"
          borderColor={colors.secondary}
          backgroundColor={colors.white}
          inputBorderColor={
            inputFocused && inputFocused.fieldOfStudy
              ? colors.secondary
              : colors.defaultBtnBackgroundColor
          }
          labelText={t('fieldOfStudy')}
          inputMargins="5px 0px 0px 0px"
          inputPaddings="0px 0px 0px 5px"
          returnKeyType="done"
          placeholder={t('signUpAngelScreensFieldOfStudyPlaceholder')}
          value={fieldOfStudy}
          onChangeText={setFieldOfStudy}
          onFocus={() =>
            setInputFocused({
              fieldOfStudy: fieldOfStudyInput.current.isFocused()
            })
          }
          onSubmitEditing={() => Keyboard.dismiss()}
        />
        <LanguagesEdit
          description={t('signUpAngelScreensChooseLanguagesDescription')}
          languagesOptions={languagesOptions}
          languages={languages}
          setLanguages={setLanguages}
          onPress={() => languagePicker.current.setIsVisible(true)}
        />
      </View>
      <TextButton
        disabled={disabled}
        margins="20px"
        backgroundColor={disabled ? colors.grey08 : colors.primary}
        text={t('signUpAngelScreensReadyToBecomeAngel')}
        onPress={onSignUpPress}
      />
      {!isWeb && (
        <Picker
          ref={languagePicker}
          multiselect
          value={languages}
          values={languagesOptions}
          onValueChange={setLanguages}
        />
      )}
    </SafeAreaView>
  )
}

Step2.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({ step1Data: PropTypes.shape({}) })
  }),
  history: PropTypes.shape({ push: PropTypes.func }),
  route: PropTypes.shape({
    params: PropTypes.shape({ step1Data: PropTypes.shape({}) })
  }),
  navigation: PropTypes.shape({ navigate: PropTypes.func }),
  fetching: PropTypes.bool,
  signupError: PropTypes.shape({ birthdate: PropTypes.string }),
  user: PropTypes.shape({}),
  signUp: PropTypes.func.isRequired
}

Step2.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null,
  fetching: false,
  signupError: null,
  user: null
}

const mapStateToProps = ({ User }) => ({
  fetching: User.fetching,
  signupError: User.signupError,
  user: User.user
})

const mapDispatchToProps = {
  signUp: signup
}

export default connect(mapStateToProps, mapDispatchToProps)(Step2)
