import BookingCostSummary from '../common/BookingCostSummary'
import { Box, HStack, Pressable, Skeleton, Spacer, Text, VStack } from 'native-base'
import { formatInterval, formatMoney, Money, TransactionType } from '@charlycares/shared'
import { Picker } from '@charlycares/ui'
import React, { useRef, useState } from 'react'
import { BookingPaymentSummary, MoneyWalletTransaction, TimeWalletTransaction } from '@charlycares/data-access'
import { useTranslation } from 'react-i18next'

interface Props {
  isLoading: boolean
  bookingPaymentSummary?: BookingPaymentSummary
  tipChanged?: (tip: Tip) => void
}

export interface Tip extends Money {
  label: string
  value: number
}

export default function BookingCosts({ isLoading, bookingPaymentSummary, tipChanged }: Props) {
  const { t } = useTranslation()
  const tipPicker = useRef<Picker | null>(null)
  const tipOptions: Tip[] = [
    { label: t('none'), amount: 0, currency: 'EUR', value: 0 },
    { label: '€1,-', amount: 100, currency: 'EUR', value: 1 },
    { label: '€2,-', amount: 200, currency: 'EUR', value: 2 },
    { label: '€5,-', amount: 500, currency: 'EUR', value: 3 },
    { label: '€10,-', amount: 1000, currency: 'EUR', value: 4 }
  ]
  const selectedTip =
    (tipOptions.find(tip => tip.amount === bookingPaymentSummary?.rate.tip.amount) as Tip) ?? tipOptions[0]
  const minuteCosts = () => {
    const timeTransaction = bookingPaymentSummary?.wallet_transactions.find(
      walletTransaction => walletTransaction.transaction_type === TransactionType.MINUTES
    ) as TimeWalletTransaction | undefined

    if (timeTransaction?.costs) {
      return formatMoney(timeTransaction.costs)
    }

    return null
  }

  const walletCosts = () => {
    const moneyTransaction = bookingPaymentSummary?.wallet_transactions.find(
      walletTransaction => walletTransaction.transaction_type === TransactionType.MONEY
    ) as MoneyWalletTransaction | undefined

    if (moneyTransaction?.used_saldo && moneyTransaction?.used_saldo.amount > 0) {
      return formatMoney(moneyTransaction?.used_saldo)
    }

    return null
  }

  return (
    <Box width="full" px="20px">
      <Text fontSize="16px" fontWeight="600">
        {t('bookingCosts.title')}
      </Text>
      <BookingCostSummary
        isLoading={isLoading}
        dayRatePerHour={bookingPaymentSummary?.day_rate_per_hour}
        nightRatePerHour={bookingPaymentSummary?.night_rate_per_hour}
        insuranceFeePerHour={bookingPaymentSummary?.insurance_fee_per_hour}
        bookingRate={bookingPaymentSummary?.rate}
      />
      <HStack alignItems="center" py="10px" w="100%" borderColor="gray.200" borderTopWidth="1">
        <Text fontWeight="600" fontSize="16px">
          {t('endBooking.tip')}
        </Text>
        <Spacer />
        <Pressable onPress={() => tipPicker.current?.open(selectedTip)}>
          {isLoading && <Skeleton rounded="full" w={10} h={5} />}
          {!isLoading && (
            <Text color="primary.400">
              {selectedTip.amount !== 0 ? formatMoney(selectedTip as Money) : t('endBooking.addATip')}
            </Text>
          )}
        </Pressable>
        <Picker
          ref={tipPicker}
          value={selectedTip}
          options={tipOptions}
          textConfirm={t('done')}
          textCancel={t('cancel')}
          onConfirmPress={(selectedTip: typeof tipOptions[number]): void => {
            tipChanged?.(selectedTip)
          }}
        />
      </HStack>
      {minuteCosts() && (
        <HStack alignItems="center" py="10px" w="100%" borderColor="gray.200" borderTopWidth="1">
          <Text fontSize="12px">{t('endBooking.companyPays')}</Text>
          <Spacer />
          <Text>{minuteCosts()}</Text>
        </HStack>
      )}
      {walletCosts() && (
        <HStack alignItems="center" py="10px" w="100%" borderColor="gray.200" borderTopWidth="1">
          <Text fontSize="12px">{t('endBooking.usedWallet')}</Text>
          <Spacer />
          <Text>{walletCosts()}</Text>
        </HStack>
      )}

      <HStack alignItems="center" py="10px" w="100%" borderColor="gray.200" borderTopWidth="1">
        <VStack>
          <Text fontWeight="600" fontSize="16px">
            {t('endBooking.total')}
          </Text>
          {!isLoading && <Text>{formatInterval(bookingPaymentSummary?.rate.duration.total)}</Text>}
          {isLoading && <Skeleton rounded="full" h={5} />}
        </VStack>
        <Spacer />
        {!isLoading && (
          <Text fontWeight="600" fontSize="22px">
            {formatMoney(bookingPaymentSummary?.amount_to_pay)}
          </Text>
        )}
        {isLoading && <Skeleton rounded="full" w="80px" h={50} />}
      </HStack>
      <HStack alignItems="center" w="100%">
        <VStack>
          <Text color="gray.800" fontSize="12px">
            {t('endBooking.angelPayout')}
          </Text>
        </VStack>
        <Spacer />
        {!isLoading && (
          <Text color="gray.800" fontSize="12px">
            {formatMoney(bookingPaymentSummary?.rate.angel_payout)}
          </Text>
        )}
        {isLoading && <Skeleton rounded="full" w="80px" h={5} />}
      </HStack>
    </Box>
  )
}
