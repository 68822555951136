import React, { useEffect } from 'react'
import { Linking, Platform } from 'react-native'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { SvgUri, CharlyIcon } from '@charlycares/ui'
import { useGetPaymentsMethodsQuery, useChangeBankAccountMutation } from '@charlycares/data-access'
import { MEMBERSHIP_TYPES } from '@charlycares/shared'
import { Heading, HStack, Skeleton, Text } from 'native-base'

type PaymentsMethodsProps = {
  onPress?: (methodId: string) => void
  membershipType: MEMBERSHIP_TYPES
}

export default function PaymentsMethods({ onPress, membershipType }: PaymentsMethodsProps) {
  const { t } = useTranslation()
  const [changeBankAccount, changeStatus] = useChangeBankAccountMutation()
  const { data, isLoading } = useGetPaymentsMethodsQuery({
    channel: Platform.OS,
    membership_type: membershipType
  })

  useEffect(() => {
    if (changeStatus.data) {
      Linking.openURL(changeStatus.data.data.redirect_url)
    }
  }, [changeStatus.data])

  return (
    <>
      <Heading mb="10px" fontSize="20px" fontWeight="600">
        {t('paymentMethods.title')}
      </Heading>

      {!isLoading &&
        data?.data &&
        data.data.map(item => (
          <MethodButton
            disabled={changeStatus.isLoading}
            onPress={() =>
              onPress ? onPress(item.id) : changeBankAccount({ payment_method: item.id, channel: Platform.OS })
            }
          >
            <SvgUri uri={item.image} alt={item.name} size={40} />

            <Text flex={1} mx="10px" size="16px">
              {item.name}
            </Text>

            <CharlyIcon name="icn-nav-right" color="gray.500" size={25} />
          </MethodButton>
        ))}

      {isLoading &&
        Array.from({ length: 3 }).map((_, index) => (
          <HStack space="3" alignItems="center" key="index">
            <Skeleton size="10" p="1" />
            <Skeleton h="5" flex="2" rounded="full" />
          </HStack>
        ))}
    </>
  )
}

PaymentsMethods.defaultProps = {
  membership_type: MEMBERSHIP_TYPES.FLEXIBLE_TRIAL
}

const MethodButton = styled.TouchableOpacity`
  width: 100%;
  border: 1px solid ${p => p.theme.colors.grey08};
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  padding: 10px;
  flex-direction: row;
  align-items: center;
`
