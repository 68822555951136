import React from 'react'
import { Box, Button, HStack, Text, VStack } from 'native-base'
import { useTranslation } from 'react-i18next'
import { CharlyIcon, iconsMap, LandingLayout } from '@charlycares/ui'
import { MEMBERSHIP_TYPES, MembershipsName, useRouter } from '@charlycares/shared'
import { useGetAvailableMembershipsQuery } from '@charlycares/data-access'
import moment from 'moment/moment'

export default function MembershipActivatedScreen() {
  const { navigate } = useRouter()
  const { t } = useTranslation()

  const { data: availableMemberships } = useGetAvailableMembershipsQuery()

  const membershipName = availableMemberships
    ? (Object.keys(availableMemberships)[0] as MembershipsName)
    : ('full_service' as MembershipsName)
  const days = moment.duration(availableMemberships?.[membershipName].trial?.interval).days()

  const usps = (): { text: string; icon: keyof typeof iconsMap; color: string }[] => {
    if (membershipName === 'pet_service') {
      return [
        {
          text: t('membershipActivatedPetService.usp1', { days }),
          icon: 'icn-check',
          color: 'primary.300'
        },
        {
          text: t('membershipActivatedPetService.usp2'),
          icon: 'icn-check',
          color: 'primary.300'
        },
        {
          text: t('membershipActivatedPetService.usp3'),
          icon: 'icn-check',
          color: 'primary.300'
        }
      ]
    }

    return [
      {
        text: t('membershipActivatedFullService.usp1', { days }),
        icon: 'icn-check',
        color: 'primary.300'
      },
      {
        text: t('membershipActivatedFullService.usp2'),
        icon: 'icn-check',
        color: 'primary.300'
      },
      {
        text: t('membershipActivatedFullService.usp3'),
        icon: 'icn-check',
        color: 'primary.300'
      }
    ]
  }

  const furtherInformationUsps = (): { text: string; icon: keyof typeof iconsMap; color: string }[] => {
    if (membershipName === 'pet_service') {
      return [
        {
          text: t('membershipActivatedFurtherInformationPetService.usp1'),
          icon: 'icn-send',
          color: 'primary.300'
        },
        {
          text: t('membershipActivatedFurtherInformationPetService.usp2'),
          icon: 'icn-payments',
          color: 'primary.300'
        }
      ]
    }

    return [
      {
        text: t('membershipActivatedFurtherInformationFullService.usp1'),
        icon: 'icn-send',
        color: 'primary.300'
      },
      {
        text: t('membershipActivatedFurtherInformationFullService.usp2'),
        icon: 'icn-payments',
        color: 'primary.300'
      }
    ]
  }

  return (
    <LandingLayout
      BottomComponent={
        <Box w="100%" p="20px" mt="auto">
          <Button
            onPress={() => navigate('BookingsScreen', `/bookings`)}
            bgColor="white"
            _text={{ color: 'secondary.400', fontSize: '16px', fontWeight: 600 }}
          >
            {t('membershipActivatedScreen.letsGo')}
          </Button>
        </Box>
      }
      headerRight={() => (
        <Text fontSize="16px" color="white" fontWeight="600" onPress={() => navigate('BookingsScreen', `/bookings`)}>
          {t('membershipActivatedScreen.ready')}
        </Text>
      )}
    >
      <VStack space="20px" p="20px" mt="50px">
        {usps().map(({ text, icon, color }, index) => (
          <HStack space="10px" key={index}>
            <CharlyIcon name={icon} size="28px" color={color} />
            <Text
              fontSize={index === 0 ? '18px' : '16px'}
              color="white"
              fontWeight={index === 0 ? '600' : '400'}
              flex={1}
            >
              {text}
            </Text>
          </HStack>
        ))}
      </VStack>

      <Box w="100%" p="15px" mt="10px">
        <Box borderRadius="8px" bgColor="secondary.alpha.30">
          <Text fontSize="18" fontWeight="600" color="primary.300" pt="10px" pl="10px">
            {t('membershipActivatedScreen.furtherInformation')}
          </Text>
          <VStack space="20px" p="10px">
            {furtherInformationUsps().map(({ text, icon, color }) => (
              <HStack space="10px" key={text}>
                <CharlyIcon name={icon} size="28px" color={color} />
                <Text fontSize="15px" color="white" fontWeight="400" flex={1}>
                  {text}
                </Text>
              </HStack>
            ))}
          </VStack>
        </Box>
      </Box>
    </LandingLayout>
  )
}

MembershipActivatedScreen.navigationOptions = () => ({
  headerShown: false
})
