import React from 'react'
import { Card, CharlyIcon, ScreenLayout, Slider, NumberInput, LanguageSelector, Checkbox } from '@charlycares/ui'
import { Box, Button, HStack, Heading, Text, VStack } from 'native-base'
import { useTranslation } from 'react-i18next'
import { formatMoney, useRouter } from '@charlycares/shared'
import { useFormik } from 'formik'
import {
  countBookingFilters,
  initialBookingState,
  setBookingSearchFilters,
  useAppSelector
} from '@charlycares/data-access'
import { useDispatch } from 'react-redux'

const basicSkills = [
  {
    key: 'first_aid',
    icon: <CharlyIcon color="gray.900" name="icn-first-aid" size={32} />,
    description: 'first_aid_desc',
    title: 'first_aid_title'
  },
  {
    key: 'baby_experience',
    icon: <CharlyIcon color="gray.900" name="icn-baby-bottle" size={32} />,
    description: 'baby_experience_desc',
    title: 'baby_experience_title'
  },
  {
    key: 'tutor',
    icon: <CharlyIcon color="gray.900" name="school" size={32} />,
    description: 'tutor_desc',
    title: 'tutor_title'
  },
  {
    key: 'works_with_kids',
    icon: <CharlyIcon color="gray.900" name="validated" size={32} />,
    description: 'works_with_kids_desc',
    title: 'works_with_kids_title'
  },
  {
    key: 'driving_license',
    icon: <CharlyIcon color="gray.900" name="icn-car" size={32} />,
    description: 'driving_license_desc',
    title: 'driving_license_title'
  }
]

const MatchingFiltersScreen = () => {
  const { t } = useTranslation()
  const { goBack } = useRouter()
  const dispatch = useDispatch()

  const initialValues = useAppSelector(state => state.booking.search.filters)

  const { values, setFieldValue, handleSubmit, setValues } = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: values => {
      dispatch(setBookingSearchFilters(values))
      dispatch(countBookingFilters())
      goBack()
    }
  })

  return (
    <ScreenLayout
      title={t('filters')}
      _buttonProps={{
        children: t('save'),
        onPress: () => handleSubmit()
      }}
      headerRight={
        <Button
          onPress={() => {
            dispatch(setBookingSearchFilters(initialBookingState.search.filters))
            setValues(initialBookingState.search.filters)
          }}
          variant="text"
          _text={{ fontWeight: 500 }}
        >
          {t('reset')}
        </Button>
      }
      headerLeft={<CharlyIcon onPress={goBack} name="btn-close" size={32} />}
    >
      <Card>
        <Box mb="20px" flexDir="row" justifyContent="space-between" alignItems="center">
          <Text fontWeight={600} fontSize={'18px'}>
            {t('age')}
          </Text>
          <Text fontSize={'16px'}>{`${values.age_range.min} - ${values.age_range.max} ${t('age')}`}</Text>
        </Box>

        <Slider
          value={Object.values(values.age_range)}
          min={15}
          max={99}
          onValueChange={val =>
            setFieldValue('age_range', Array.isArray(val) ? { min: Math.round(val[0]), max: Math.round(val[1]) } : val)
          }
        />
      </Card>

      <Card>
        <Box mb="20px" flexDir="row" justifyContent="space-between" alignItems="center">
          <Text fontWeight={600} fontSize={'18px'}>
            {t('distance')}
          </Text>
          <Text fontSize={'16px'}>{`${values.max_distance} km`}</Text>
        </Box>

        <Slider
          value={values.max_distance}
          min={0}
          max={50}
          onValueChange={val => setFieldValue('max_distance', Math.round(val as number))}
        />
      </Card>

      <Card flexDir="row" justifyContent="space-between" alignItems="center">
        <Text fontWeight={600} fontSize={'18px'}>
          {t('maxRateFilter')}
        </Text>

        <NumberInput
          changeRate={25}
          onChange={val => setFieldValue('max_rate_per_hour', { amount: val, currency: 'EUR' })}
          value={values.max_rate_per_hour.amount || 1200}
          formatValue={val => (
            <Text color={values.max_rate_per_hour.amount ? 'gray.900' : 'gray.500'}>
              {formatMoney({ amount: val, currency: 'EUR' })}
            </Text>
          )}
        />
      </Card>

      <Card>
        <VStack space="10px">
          <Heading fontWeight={600} fontSize={'18px'}>
            {t('languages')}
          </Heading>

          <Text fontSize={'16px'}>{t('matchingFiltersLanguageDesc')}</Text>

          <LanguageSelector mt="10px" languages={values.languages} onChange={val => setFieldValue('languages', val)} />
        </VStack>
      </Card>

      <Card>
        <Heading fontWeight={600} fontSize={'18px'}>
          {t('filtersSkillsTitle')}
        </Heading>

        <Text mt="10px" fontSize={'16px'}>
          {t('filterSkillsDesc')}
        </Text>

        <VStack space="20px" mt="15px">
          <Heading fontWeight={600} fontSize={'18px'}>
            {t('filtersBasicSkills')}
          </Heading>

          {basicSkills.map(({ icon, title, description, key }) => (
            <HStack key={key}>
              {icon}
              <VStack flex={1} ml="10px">
                <Text fontWeight={400} fontSize={'15px'}>
                  {t(title)}
                </Text>
                <Text fontSize={12} color="gray.700">
                  {t(description)}
                </Text>
              </VStack>
              <Checkbox
                onChange={val =>
                  setFieldValue('skills', val ? [...values.skills, key] : values.skills.filter(s => s !== key))
                }
                isChecked={values.skills.includes(key)}
              />
            </HStack>
          ))}
        </VStack>
      </Card>
    </ScreenLayout>
  )
}

export default MatchingFiltersScreen

MatchingFiltersScreen.navigationOptions = {
  headerShown: false
}
