import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { TextView, TextIconView } from '../Texts';
import { TextButton, IconButton, TextIconButton } from '../Buttons';
import PhoneNumberEdit from './PhoneNumberEdit';
import { formatPhoneNumber } from '../../utils/functions';
import colors from '../../styles/colors';

const View = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px 20px 0px;
  width: ${({ width }) => width || 'auto'};
`;

const PhoneNumbersEdit = ({
  phone,
  secondPhone,
  countryCode,
  onCountryChange,
  phoneNumber,
  inputFocused,
  validationErrors,
  onRemovePhonePress,
  onRemoveSecondPhonePress,
  onCountryCodePress,
  phoneNumberInputRef,
  onPhoneNumberChangeText,
  onPhoneNumberFocus,
  onPhoneNumberSubmitEditing,
  onCancelPress,
  onAddNumberPress,
  onAddPhoneNumberPress,
  editPhoneNumber,
  countryCodeOptions
}) => {
  const [t] = useTranslation();

  return (
    <TextView
      flexDirection="column"
      alignItems="flex-start"
      paddings="0px 5px 0px 5px"
      margins="20px 10px 20px 10px"
      borderLeftWidth={phone ? 0 : '4px'}
      borderColor={colors.secondary}
      text={t('phoneNumber')}
      fontSize="12px"
      fontWeight="300"
      textColor={colors.grey02}>
      {phone !== '' && (
        <View>
          <IconButton
            margins="0px 20px 0px 0px"
            height="auto"
            iconName="icn-cancel"
            iconWidth="20px"
            iconHeight="20px"
            iconColor={colors.secondary}
            onPress={onRemovePhonePress}
          />

          <TextIconView
            flexDirection="row-reverse"
            backgroundColor="transparent"
            iconName="icn-telephone"
            iconSize="32px"
            iconMargins="0px 10px 0px 0px"
            iconColor="black"
            text={formatPhoneNumber(phone)}
            fontSize="16px"
          />
        </View>
      )}

      {secondPhone && (
        <View>
          <IconButton
            margins="0px 20px 0px 0px"
            height="auto"
            iconName="icn-cancel"
            iconWidth="20px"
            iconHeight="20px"
            iconColor={colors.secondary}
            onPress={onRemoveSecondPhonePress}
          />

          <TextIconView
            flexDirection="row-reverse"
            backgroundColor="transparent"
            iconName="icn-telephone"
            iconSize="32px"
            iconMargins="0px 10px 0px 0px"
            iconColor="black"
            text={formatPhoneNumber(secondPhone)}
            fontSize="16px"
          />
        </View>
      )}

      {!editPhoneNumber && (
        <TextIconButton
          flexDirection="row-reverse"
          justifyContent="flex-start"
          paddings="20px 0px 0px 0px"
          text={t('profileScreensAddTelephoneNumber')}
          textMargins="0px 0px 0px 10px"
          fontSize="16px"
          textColor={colors.secondary}
          iconName="btn-plus"
          iconSize="32px"
          iconColor={colors.defaultBtnBackgroundColor}
          onPress={onAddPhoneNumberPress}
        />
      )}

      {editPhoneNumber && (
        <>
          <PhoneNumberEdit
            margins="20px 0px 0px 0px"
            returnKeyType="done"
            inputFocused={inputFocused}
            validationErrors={validationErrors}
            countryCode={countryCode}
            countryCodeOptions={countryCodeOptions}
            onCountryCodePress={onCountryCodePress}
            onCountryCodeChange={onCountryChange}
            phoneNumberRef={phoneNumberInputRef}
            phoneNumber={phoneNumber}
            onPhoneNumberChangeText={onPhoneNumberChangeText}
            onPhoneNumberFocus={onPhoneNumberFocus}
            onPhoneNumberSubmitEditing={onPhoneNumberSubmitEditing}
          />

          <View width="100%">
            <TextButton text={t('cancel')} textColor={colors.secondary} onPress={onCancelPress} />

            <TextButton
              width="60%"
              backgroundColor={colors.primary}
              text={t('profileScreensAddNumber')}
              onPress={onAddNumberPress}
            />
          </View>
        </>
      )}
    </TextView>
  );
};

PhoneNumbersEdit.propTypes = {
  phone: PropTypes.string,
  secondPhone: PropTypes.string,
  countryCode: PropTypes.string,
  countryCodeOptions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
      PropTypes.string
    ])
  ).isRequired,
  onCountryChange: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string,
  inputFocused: PropTypes.shape({}),
  validationErrors: PropTypes.shape({}),
  onRemovePhonePress: PropTypes.func.isRequired,
  onRemoveSecondPhonePress: PropTypes.func.isRequired,
  onCountryCodePress: PropTypes.func.isRequired,
  phoneNumberInputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]).isRequired,
  onPhoneNumberChangeText: PropTypes.func.isRequired,
  onPhoneNumberFocus: PropTypes.func.isRequired,
  onPhoneNumberSubmitEditing: PropTypes.func,
  onCancelPress: PropTypes.func.isRequired,
  onAddNumberPress: PropTypes.func.isRequired,
  onAddPhoneNumberPress: PropTypes.func.isRequired,
  editPhoneNumber: PropTypes.bool.isRequired
};

PhoneNumbersEdit.defaultProps = {
  phone: null,
  secondPhone: null,
  countryCode: '',
  inputFocused: null,
  phoneNumber: '',
  validationErrors: null,
  onPhoneNumberSubmitEditing: () => {}
};

export default PhoneNumbersEdit;
