import {
  FETCHING_INVITATIONS,
  GET_ANGEL_INVITATIONS,
  GET_NEXT_ANGEL_INVITATIONS,
  SET_INVITATION_DETAILS,
  ACCEPT_INVITATION,
  UPDATING_INVITATION_DETAILS,
  INVITATION_ACCEPTED_ERROR
} from '../types';

const initialState = {
  fetching: false,
  invitations: null,
  active_invitations: null,
  invitationDetails: null,
  updatingInvitation: false,
  acceptedError: null
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_ANGEL_INVITATIONS:
      return {
        ...payload,
        invitationDetails: state.invitationDetails,
        fetching: false
      };
    case GET_NEXT_ANGEL_INVITATIONS:
      return {
        ...payload,
        invitations: {
          ...payload.invitations,
          data: [...state.invitations.data, ...payload.invitations.data]
        },
        invitationDetails: state.invitationDetails,
        fetching: false
      };
    case FETCHING_INVITATIONS:
      return {
        ...state,
        fetching: true
      };
    case SET_INVITATION_DETAILS:
      return {
        ...state,
        invitations: {
          ...state.invitations,
          data:
            state.invitations &&
            state.invitations.data.map((invitation) => {
              if (invitation.id === payload.id) {
                return payload;
              }
              return invitation;
            })
        },
        active_invitations:
          state.active_invitations &&
          state.active_invitations.map((invitation) => {
            if (invitation.id === payload.id) {
              return payload;
            }
            return invitation;
          }),
        invitationDetails: payload,
        updatingInvitation: false,
        fetching: false
      };
    case ACCEPT_INVITATION:
      return {
        ...state,
        active_invitations: [...payload, ...state.active_invitations],
        fetching: false
      };
    case UPDATING_INVITATION_DETAILS:
      return {
        ...state,
        updatingInvitation: true,
        acceptedError: null,
        fetching: false
      };
    case INVITATION_ACCEPTED_ERROR:
      return {
        ...state,
        acceptedError: payload
      };
    default:
      return state;
  }
}
