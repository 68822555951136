import React from 'react'
import { Card, ScreenLayout } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { Button, Heading, Text } from 'native-base'
import { isWeb, useRouter } from '@charlycares/shared'
import { useHistory } from 'react-router-dom'

import { InsuranceCard } from '../../common'
import { InviteReferrals } from '../../../marketing'

const BookingCreatedScreen = ({ navigation }: any) => {
  const { t } = useTranslation()
  const { navigate, getParam } = useRouter()
  const history = useHistory()

  const jobboard = getParam('jobboard', false)

  return (
    <ScreenLayout
      hideBackButton
      title={t('bookScreensRequestSent')}
      headerRight={
        <Button
          onPress={() => {
            if (isWeb) {
              history.replace('/bookings')
            } else {
              navigation.dismiss()
              navigate('BookingsScreen')
            }
          }}
          variant="text"
        >
          {t('done')}
        </Button>
      }
    >
      <Card mt="10px">
        <Heading fontSize="18px" fontWeight={600}>
          {t(jobboard ? 'bookScreensRequestHasBeenSentToJobBoard' : 'bookScreensRequestHasBeenSent')}
        </Heading>
        <Text mt="15px">
          {t(jobboard ? 'bookScreensRequestConfirmationJobBoard' : 'bookScreensRequestConfirmation')}
        </Text>
      </Card>

      <Card>
        <InviteReferrals />
      </Card>
    </ScreenLayout>
  )
}

export default BookingCreatedScreen

BookingCreatedScreen.navigationOptions = {
  headerShown: false
}
