import { isDev, links } from '@charlycares/shared'
import { useEffect } from 'react'
import { Alert, Linking, Platform } from 'react-native'
import checkVersion from 'react-native-store-version'
import { getVersion } from 'react-native-device-info'

export default function useStoreVersion() {
  useEffect(() => {
    const init = async () => {
      try {
        const check = await checkVersion({
          version: getVersion(), // app local version
          iosStoreURL: links.store.ios,
          androidStoreURL: links.store.android,
          country: 'nl'
        })
        
        if (check.result !== 'equal' && !isDev) {
          // if app store version is new
          Alert.alert(
            `New ${check.remote} version available`,
            'Please update the app to continue using it',
            [
              {
                text: 'Update',
                onPress: () => Linking.openURL(links.store[Platform.OS === 'android' ? 'android' : 'ios'])
              },
              { text: 'Later' }
            ],
            { cancelable: true }
          )
        }
      } catch (e) {
        __DEV__ && console.log(e)
      }
    }

    init()
  }, [])
}
