import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import {
  ChangeBankAccountScreen,
  CancelMembershipScreen,
  ChangeMembershipScreen,
  BusinessAccountStartMembership,
  BusinessAccountConnectionCode,
  BusinessAccountConnectMembership,
  BusinessAccountConnected,
  BusinessAccountConnectionFailed,
  InviteOwnAngelScreen,
  AngelDetailsScreen,
  FamilyDetailsScreen,
  CancelOrChangeMembershipScreen,
  FamilyProfileScreen,
  ProfileReviewsScreen,
  ReviewDetailsScreen,
  PromotionsScreen,
  MembershipInfoScreen
} from '@charlycares/features'

import {
  ProfileAccount,
  FamilyProfileEdit,
  FamilyProfileSettings,
  FamilyProfileSettingsEdit,
  Promotions,
  Referral,
  ReferralOverview,
  PaymentOverview,
  PaymentDetails,
  PaymentCreatePDF,
  Membership,
  MembershipSuccess,
  GeneralConditions,
  AngelProfilePreferences,
  AngelProfilePersonalSettings,
  AngelProfileEditPersonalSettings,
  AngelPaymentOverview,
  BusinessBankAccount
} from '@charlycares/legacy/web'
import { ProtectedRoute } from '../components'
import PaymentListScreen from '../../../../../libs/features/src/components/payments/PaymentListScreen'

const ProfileRoutes = user => {
  const { role } = user || {}

  return [
    <ProtectedRoute
      key="profile"
      path="/profile"
      user={user}
      component={props => <FamilyProfileScreen {...props} />}
    />,
    <ProtectedRoute key="profile-account" path="/profile-account" user={user} component={ProfileAccount} />,
    <ProtectedRoute key="profile-edit" path="/profile-edit" user={user} component={FamilyProfileEdit} />,
    <ProtectedRoute
      key="profile-settings"
      path="/profile-settings"
      user={user}
      component={props =>
        role === 'angel' ? <AngelProfilePersonalSettings {...props} /> : <FamilyProfileSettings {...props} />
      }
    />,
    <ProtectedRoute
      key="profile-settings-edit"
      path="/profile-settings-edit"
      user={user}
      component={props =>
        role === 'angel' ? <AngelProfileEditPersonalSettings {...props} /> : <FamilyProfileSettingsEdit {...props} />
      }
    />,
    <ProtectedRoute
      key="membership"
      path="/profile-membership"
      user={user}
      component={props => <MembershipInfoScreen {...props} />}
    />,
    <ProtectedRoute
      key="profile-reviews"
      path="/profile-reviews"
      user={user}
      component={props => <ProfileReviewsScreen {...props} />}
    />,
    <ProtectedRoute
      key="profile-reviews-details"
      path="/profile-reviews-details"
      user={user}
      component={props => <ReviewDetailsScreen {...props} />}
    />,
    <ProtectedRoute
      key="profile-preferences"
      path="/profile-preferences"
      user={user}
      component={AngelProfilePreferences}
    />,
    <ProtectedRoute key="profile-promotions" path="/profile-promotions" user={user} component={PromotionsScreen} />,
    <ProtectedRoute key="profile-referral" path="/profile-referral" user={user} component={Referral} />,
    <ProtectedRoute
      key="profile-referral-details"
      path="/profile-referral-details"
      user={user}
      component={() => <PromotionsScreen />}
    />,
    <ProtectedRoute key="profile-payments" path="/profile-payments" user={user} component={PaymentListScreen} />,
    <ProtectedRoute
      key="profile-payments-details"
      path="/profile-payment-details"
      user={user}
      component={PaymentDetails}
    />,
    <ProtectedRoute key="profile-payments-pdf" path="/profile-payments-pdf" user={user} component={PaymentCreatePDF} />,
    <ProtectedRoute
      key="profile-connect-business-account"
      path="/profile-business-account"
      user={user}
      component={BusinessAccountConnectionCode}
    />,
    <ProtectedRoute
      key="profile-business-account-connect-membership"
      path="/profile-business-account-connect-membership"
      user={user}
      component={BusinessAccountConnectMembership}
    />,
    <ProtectedRoute
      key="profile-business-account-connected"
      path="/profile-business-account-connected"
      user={user}
      component={BusinessAccountConnected}
    />,
    <ProtectedRoute
      key="profile-business-account-failed"
      path="/profile-business-account-failed"
      user={user}
      component={BusinessAccountConnectionFailed}
    />,
    <ProtectedRoute
      key="profile-business-bank-account"
      path="/profile-business-bank-account"
      user={user}
      component={BusinessBankAccount}
    />,
    <ProtectedRoute
      key="profile-business-account-start-membership"
      path="/profile-business-account-start-membership"
      user={user}
      component={BusinessAccountStartMembership}
    />,
    <ProtectedRoute
      key="profile-membership-success"
      path="/profile-membership-success"
      user={user}
      component={MembershipSuccess}
    />,
    <ProtectedRoute
      key="profile-membership-details"
      path="/profile-membership-details"
      user={user}
      component={ChangeMembershipScreen}
    />,
    <ProtectedRoute
      key="profile-membership-cancel"
      path="/profile-membership-cancel"
      user={user}
      component={CancelOrChangeMembershipScreen}
    />,
    <ProtectedRoute
      key="profile-membership-cancel-reasons"
      path="/profile-membership-cancel-reasons"
      user={user}
      component={CancelMembershipScreen}
    />,
    <ProtectedRoute
      key="profile-bank-account"
      path="/profile-bank-account"
      user={user}
      component={ChangeBankAccountScreen}
    />,
    <Route key="profile-general-conditions" path="/profile-general-conditions" component={GeneralConditions} />,
    <ProtectedRoute key="profile-angel" path="/profile-angel" user={user} component={AngelDetailsScreen} />,
    <ProtectedRoute key="profile-family" path="/profile-family" user={user} component={FamilyDetailsScreen} />,
    <ProtectedRoute
      key="profile-invite-your-own-angel"
      path="/profile-invite-your-own-angel"
      user={user}
      component={InviteOwnAngelScreen}
    />
  ]
}

ProfileRoutes.propTypes = {
  user: PropTypes.shape({ role: PropTypes.string })
}

ProfileRoutes.defaultProps = {
  user: null
}

export default ProfileRoutes
