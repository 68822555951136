import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const IcnChatIcon = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 32 32" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.0001 7.64998C7.25451 7.64998 6.6501 8.25439 6.6501 8.99998V24.5675L9.2893 22.2582C9.59008 21.995 9.97616 21.85 10.3758 21.85H24.0001C24.7457 21.85 25.3501 21.2456 25.3501 20.5V8.99998C25.3501 8.25439 24.7457 7.64998 24.0001 7.64998H8.0001ZM5.3501 8.99998C5.3501 7.53642 6.53654 6.34998 8.0001 6.34998H24.0001C25.4637 6.34998 26.6501 7.53642 26.6501 8.99998V20.5C26.6501 21.9635 25.4637 23.15 24.0001 23.15H10.3758C10.2911 23.15 10.2092 23.1807 10.1454 23.2366L6.42813 26.4891C6.23618 26.6571 5.96376 26.6973 5.73151 26.5919C5.49925 26.4865 5.3501 26.255 5.3501 26V8.99998Z"
      fill="currentColor"
    />
  </Svg>
)

export default IcnChatIcon
