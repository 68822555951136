import React from 'react'
import { Avatar, Box, Button, Factory, Heading, HStack, IBoxProps, Text } from 'native-base'
import { BookingServiceType, IJobPost } from '@charlycares/shared'
import { TouchableOpacity } from 'react-native'

import { useTranslation } from 'react-i18next'
import { CharlyIcon } from '@charlycares/ui'
import { BookingCardDates, getInfoFromBookingServiceType } from '@charlycares/features'

const Touchable = Factory(TouchableOpacity) as any

type Props = IBoxProps & {
  data: IJobPost
  onPress?: () => void
}

const JobCard = ({ data, onPress, ...boxProps }: Props) => {
  const { family, booking_dates } = data
  const { t } = useTranslation()
  const { icon: iconName, icon_color: iconColor } = getInfoFromBookingServiceType(data.service_type)

  return (
    <Touchable
      onPress={onPress}
      flexDir="row"
      w="100%"
      p="20px"
      bg="white"
      borderTopWidth={1}
      borderBottomWidth={1}
      borderColor="gray.200"
      {...boxProps}
    >
      <Box flex={1} mr="10px">
        <HStack alignItems="center" space="10px">
          <Avatar borderWidth={1} borderColor="gray.400" size="40px" source={{ uri: family.image }} />
          <Heading flex={1} fontSize="16px" fontWeight="600" numberOfLines={1} ellipsizeMode="tail">
            {family.name}
          </Heading>
        </HStack>
        <BookingCardDates bookingDates={booking_dates} startsAt={data.start_date} endsAt={data.end_date} />
      </Box>

      <Box justifyContent="center" alignItems="flex-end">
        <HStack space="4px" alignItems="center">
          <Text fontSize="12px">{t('pending')}</Text>
          <Box h="12px" w="12px" borderRadius="30px" bg="alert.warning.400" />
        </HStack>

        <Button
          disabled
          mt="10px"
          h="30px"
          w="76px"
          px="4px"
          py="0px"
          variant="outline"
          _text={{
            fontSize: '13px',
            fontWeight: 600
          }}
          leftIcon={
            data.service_type && data.service_type !== BookingServiceType.CHILD_CARE_SHORT_TERM && data.service_type !== BookingServiceType.SINGLE ? (
              <CharlyIcon size={20} color={`${iconColor}.400`} name={iconName} />
            ) : undefined
          }
        >
          {t('view')}
        </Button>
      </Box>
    </Touchable>
  )
}

export default JobCard
