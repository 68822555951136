import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { Text } from '../../elements';
import ProfileAvatar from './ProfileAvatar';
import LanguageSwitch from './LanguageSwitch';

const View = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 20px;
`;

const ProfileHeader = ({
  image,
  firstName,
  lastName,
  isAngel,
  showPlaceholder,
  onLanguagePress,
  selectedLanguage
}) => {
  const [t] = useTranslation();

  return (
    <View>
      <ProfileAvatar
        width={isAngel ? '81px' : '123px'}
        height={isAngel ? '81px' : '82px'}
        imageWidth={isAngel ? '81px' : '123px'}
        imageHeight={isAngel ? '81px' : '82px'}
        source={image}
        showPlaceholder={showPlaceholder}
        isAngel={isAngel}
      />

      <Text margins="0px 0px 0px 10px" fontFamily="Martel" fontSize="16px" fontWeight="600">
        {isAngel ? `${firstName} ${lastName}` : `${t('profileScreensFam')} ${lastName}`}
      </Text>

      <LanguageSwitch onPress={onLanguagePress} selectedLanguage={selectedLanguage} />
    </View>
  );
};

ProfileHeader.propTypes = {
  image: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  isAngel: PropTypes.bool.isRequired,
  showPlaceholder: PropTypes.bool,
  onLanguagePress: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired
};

ProfileHeader.defaultProps = {
  image: null,
  showPlaceholder: false
};

export default ProfileHeader;
