import React from 'react';
import { Linking } from 'react-native';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { Text, Divider } from '../../elements';
import { TextIconButton, Header, IconButton } from '../../components';
import colors from '../../styles/colors';

import arrowRight from '../../../assets/icons/icn-nav-right.png';
import leftIcon from '../../../assets/icons/icn-nav-left.png';

const items = (t) => [
  {
    description: t('profileScreensThermsOfUse'),
    uri: 'https://www.charlycares.com/gebruikersovereenkomst'
  },
  {
    description: t('profileScreensBabysittingAgreement'),
    uri: 'https://www.charlycares.com/oppasovereenkomst'
  },
  {
    description: t('profileScreensPrivacyStatement'),
    uri: 'https://www.charlycares.com/regeling-dienstverlening-aan-huis'
  },
  {
    description: t('profileScreensExplanationServiceAtHome'),
    uri: 'https://www.charlycares.com/regeling-dienstverlening-aan-huis'
  },
  {
    description: t('profileScreensPolictyConditionsAngelInsurance'),
    uri: 'https://www.charlycares.com/voorwaarden-aansprakelijkheidsverzekering'
  }
];

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
`;

const GeneralConditions = ({ history, navigation }) => {
  const [t] = useTranslation();

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('profileScreensTermsAndConditions')}
        </Text>
      )
    });
  }

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          title={t('profileScreensTermsAndConditions')}
          left={
            <IconButton
              iconSize="32px"
              iconColor={colors.secondary}
              iconSource={leftIcon}
              onPress={history.goBack}
            />
          }
        />
      )}
      <Divider />

      {items(t).map((item) => (
        <TextIconButton
          key={item.description}
          flexDirection="row"
          justifyContent="space-between"
          paddings="20px 0px 20px 0px"
          margins="0px 20px 0px 20px"
          borderWidth={0}
          borderBottomWidth="1px"
          borderTopWidth={0}
          borderRadius={0}
          borderColor={colors.grey08}
          text={item.description}
          textColor={colors.primaryText}
          icon={arrowRight}
          iconSize="32px"
          iconColor={colors.grey08}
          onPress={() => {
            if (navigation) {
              navigation.navigate('WebView', { uri: item.uri });
            } else {
              Linking.openURL(item.uri);
            }
          }}
        />
      ))}
    </SafeAreaView>
  );
};

GeneralConditions.propTypes = {
  history: PropTypes.shape({ goBack: PropTypes.func }),
  navigation: PropTypes.shape({ navigate: PropTypes.func, setOptions: PropTypes.func })
};

GeneralConditions.defaultProps = {
  history: null,
  navigation: null
};

export default GeneralConditions;
