import {
  FETCHING_RATINGS,
  GET_RATINGS,
  GET_NEXT_RATINGS,
  UPDATING_RATING,
  UPDATE_RATING
} from '../types';

const initialState = {
  fetching: false,
  ratingUpdated: false,
  ratings: null
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCHING_RATINGS:
      return {
        ...state,
        fetching: true
      };
    case GET_RATINGS:
      return {
        ...payload,
        fetching: false
      };
    case GET_NEXT_RATINGS:
      return {
        ...payload,
        ratings: {
          ...payload.ratings,
          data: [...state.ratings.data, ...payload.ratings.data]
        },
        fetching: false
      };
    case UPDATING_RATING:
      return {
        ...state,
        ratingUpdated: false
      };
    case UPDATE_RATING:
      return {
        ...state,
        ratings: {
          ...state.ratings,
          data: state.ratings.data.map((rating) => (rating.id === payload.id ? payload : rating))
        },
        ratingUpdated: true
      };
    default:
      return state;
  }
}
