import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { TextView, TextIconView } from '../Texts';
import LabeledInput from '../Inputs/LabeledInput';
import colors from '../../styles/colors';

import houseIcon from '../../../assets/icons/icn-house.png';

const View = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const AddressEdit = ({
  postalcode,
  streetNumber,
  streetName,
  inputFocused,
  validationErrors,
  postalcodeInputRef,
  onPostalcodeChangeText,
  onPostalcodeFocus,
  streetNumberInputRef,
  onStreetNumberChangeText,
  onStreetNumberFocus,
  onSubmitPostalcode,
  onSubmitStreetNumber
}) => {
  const [t] = useTranslation();

  return (
    <TextView
      flexDirection="column"
      alignItems="flex-start"
      paddings="0px 5px 0px 5px"
      margins="20px 10px 20px 10px"
      borderLeftWidth={
        streetName &&
        !(validationErrors && (validationErrors.postalcode || validationErrors.street_number))
          ? 0
          : 4
      }
      borderColor={colors.secondary}
      text={t('address')}
      textMargins="0px 0px 20px 0px"
      fontSize="12px"
      fontWeight="300"
      textColor={colors.grey02}>
      <View>
        <LabeledInput
          inputRef={postalcodeInputRef}
          height="auto"
          width="60%"
          backgroundColor={colors.white}
          inputBorderColor={
            inputFocused && inputFocused.postalCode ? colors.secondary : colors.primary
          }
          inputBackgroundColor={colors.backgroundColor}
          labelText={t('postalCode')}
          value={postalcode}
          inputPaddings="0px 0px 0px 5px"
          validationMessage={validationErrors && validationErrors.postalcode}
          onChangeText={onPostalcodeChangeText}
          onFocus={onPostalcodeFocus}
          onSubmitEditing={onSubmitPostalcode}
          returnKeyType="done"
        />

        <LabeledInput
          inputRef={streetNumberInputRef}
          margins="0px 0px 0px 20px"
          height="auto"
          width="35%"
          backgroundColor={colors.white}
          inputBackgroundColor={colors.backgroundColor}
          inputBorderColor={
            inputFocused && inputFocused.streetNumber ? colors.secondary : colors.primary
          }
          labelText={t('streetNumber')}
          value={streetNumber}
          inputPaddings="0px 0px 0px 5px"
          validationMessage={validationErrors && validationErrors.street_number}
          onChangeText={onStreetNumberChangeText}
          onFocus={onStreetNumberFocus}
          onSubmitEditing={onSubmitStreetNumber}
          returnKeyType="done"
        />
      </View>

      <TextIconView
        flexDirection="row-reverse"
        margins="20px 0px 0px 0px"
        backgroundColor="transparent"
        text={t('profileScreensAddressWillBeRetrived')}
        textMargins="0px 0px 0px 10px"
        icon={houseIcon}
        iconSize="32px"
        iconColor={colors.grey04}
      />
    </TextView>
  );
};

AddressEdit.propTypes = {
  postalcode: PropTypes.string.isRequired,
  streetNumber: PropTypes.string.isRequired,
  streetName: PropTypes.string,
  inputFocused: PropTypes.shape({ postalCode: PropTypes.bool, streetNumber: PropTypes.bool }),
  validationErrors: PropTypes.shape({
    postalcode: PropTypes.string,
    street_number: PropTypes.string
  }),
  postalcodeInputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]).isRequired,
  onPostalcodeChangeText: PropTypes.func.isRequired,
  onPostalcodeFocus: PropTypes.func.isRequired,
  streetNumberInputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]).isRequired,
  onStreetNumberChangeText: PropTypes.func.isRequired,
  onStreetNumberFocus: PropTypes.func.isRequired,
  onSubmitPostalcode: PropTypes.func.isRequired,
  onSubmitStreetNumber: PropTypes.func.isRequired
};

AddressEdit.defaultProps = {
  inputFocused: null,
  validationErrors: null,
  streetName: ''
};

export default AddressEdit;
