import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import Text from '../../elements/Text';
import Circle from '../../elements/Circle';
import Icon from '../../elements/Icon';
import TextView from '../Texts/TextView';
import TextTextView from '../Texts/TextTextView';
import AngelAvatar from '../Profile/AngelAvatar';
import colors from '../../styles/colors';

import sticker1Icon from '../../../assets/icons/icn-sticker-01.png';
import sticker2Icon from '../../../assets/icons/icn-sticker-02.png';
import sticker3Icon from '../../../assets/icons/icn-sticker-03.png';
import sticker4Icon from '../../../assets/icons/icn-sticker-04.png';
import sticker5Icon from '../../../assets/icons/icn-sticker-05.png';

const getStickerIcon = (rating) => {
  switch (rating) {
    case 1:
      return sticker1Icon;
    case 2:
      return sticker2Icon;
    case 3:
      return sticker3Icon;
    case 4:
      return sticker4Icon;
    default:
      return sticker5Icon;
  }
};

const View = styled.View`
  ${({ flex }) => flex && { flex }};
  flex-direction: row;
  ${({ justifyContent }) => justifyContent && { 'justify-content': justifyContent }};
  ${({ paddings }) => paddings && { padding: paddings }};
  margin-bottom: ${({ marginBottom }) => marginBottom || '40px'};
  ${({ width }) => width && { width }};
  ${({ backgroundColor }) => backgroundColor && { 'background-color': backgroundColor }};
`;
const IconView = styled.View`
  position: absolute;
  bottom: -10;
  right: -10;
  padding: 3px;
  shadow-color: black;
  shadow-offset: 0px 0px;
  shadow-opacity: 0.25;
  shadow-radius: 3px;
  elevation: 5;
  border-radius: 16px;
  background-color: ${colors.white};
`;

const AngelScores = ({
  image,
  averageRating,
  dashboard: { should_update_agenda, requests_received, requests_responded, completed_bookings }
}) => {
  const [t] = useTranslation();

  return (
    <TextView
      flexDirection="column"
      alignItems="flex-start"
      paddings="20px 20px 0px 20px"
      width="100%"
      text={t('dashboardScreensAngelScores')}
      textMargins="0px 0px 15px 0px"
      fontSize="22px"
      fontWeight="600">
      <View>
        <AngelAvatar alignItems="center" source={image}>
          <TextView
            flexDirection="column"
            alignItems="center"
            margins="10px 0px 0px 0px"
            text={t('rating')}
            fontSize="14px"
            fontWeight="300">
            <Text fontSize="32px" fontWeight="600">
              {averageRating}
            </Text>

            <Text fontSize="14px" color={colors.defaultBtnTextColor}>
              {t('dashboardScreensRatingOutOf')}
            </Text>
          </TextView>
        </AngelAvatar>

        <TextView
          flex={1}
          flexDirection="column"
          alignItems="flex-start"
          margins="0px 0px 0px 20px"
          text={t('dashboardScreensCalendarStatus')}
          fontSize="14px"
          fontWeight="300">
          <TextView
            flexDirection="row-reverse"
            alignItems="center"
            margins="0px 10px 10px 0px"
            text={
              should_update_agenda
                ? t('dashboardScreensAgendaNeedsToBeUpdated')
                : t('dashboardScreensAgendaUpdated')
            }
            textMargins="0px 0px 0px 5px"
            fontSize="14px">
            <Circle
              borderWidth="6px"
              borderColor={should_update_agenda ? colors.orange : colors.green}
            />
          </TextView>

          <View
            justifyContent="space-between"
            marginBottom="0px"
            paddings="10px 20px 10px 20px"
            width="100%"
            backgroundColor={colors.darkBackgroundColor}>
            <TextTextView
              flexDirection="column"
              justifyContent="flex-start"
              firstText={t('dashboardScreensRequests')}
              firstTextFontSize="14px"
              firstTextFontWeight="300"
              secondText={String(requests_received)}
              secondTextFontSize="16px"
              secondTextFontWeight="600"
            />

            <TextTextView
              flexDirection="column"
              justifyContent="flex-start"
              firstText={t('dashboardScreensResponded')}
              firstTextFontSize="14px"
              firstTextFontWeight="300"
              secondText={String(requests_responded)}
              secondTextFontSize="16px"
              secondTextFontWeight="600"
            />

            <TextTextView
              flexDirection="column"
              justifyContent="flex-start"
              firstText={t('dashboardScreensCompleted')}
              firstTextFontSize="14px"
              firstTextFontWeight="300"
              secondText={String(completed_bookings)}
              secondTextFontSize="16px"
              secondTextFontWeight="600"
            />

            <IconView>
              <Icon
                size="24px"
                color={colors.secondary}
                source={getStickerIcon(Math.floor(averageRating))}
              />
            </IconView>
          </View>
        </TextView>
      </View>
    </TextView>
  );
};

AngelScores.propTypes = {
  image: PropTypes.node,
  averageRating: PropTypes.string.isRequired,
  dashboard: PropTypes.shape({
    should_update_agenda: PropTypes.bool,
    requests_received: PropTypes.number,
    requests_responded: PropTypes.number,
    completed_bookings: PropTypes.number
  }).isRequired
};

AngelScores.defaultProps = {
  image: null
};

export default AngelScores;
