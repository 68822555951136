import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const VerifiedFalseIcon = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 40 40" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 33.313c-7.352 0-13.313-5.96-13.313-13.313 0-7.352 5.96-13.313 13.313-13.313 7.352 0 13.313 5.96 13.313 13.313 0 7.352-5.96 13.313-13.313 13.313zm0-1.626c6.455 0 11.688-5.232 11.688-11.687 0-2.928-1.077-5.604-2.856-7.654L12.346 28.832A11.642 11.642 0 0020 31.687zm-8.806-4.002l16.491-16.49A11.643 11.643 0 0020 8.311C13.545 8.313 8.312 13.546 8.312 20c0 2.942 1.088 5.63 2.883 7.685z"
      fill="currentColor"
    />
  </Svg>
)

export default VerifiedFalseIcon
