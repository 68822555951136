import { useTranslation } from 'react-i18next'
import { formatMoney, useRouter } from '@charlycares/shared'
import { useGetMembershipTransactionQuery } from '@charlycares/data-access'
import { Card, ScreenLayout } from '@charlycares/ui'
import { HStack, Skeleton, Spacer, Text, VStack } from 'native-base'
import React from 'react'
import moment from 'moment/moment'

const MembershipPaymentScreen = () => {
  const { t } = useTranslation()
  const { getParam } = useRouter()
  const transactionUuid = getParam('transactionUuid')

  const { isLoading, data: membershipWalletTransaction } = useGetMembershipTransactionQuery({
    transaction_uuid: transactionUuid
  })

  return (
    <ScreenLayout
      edges={['left', 'right']}
      supportButton
      disableScroll
      title={t('payment')}
      subtitle={`(${t(`walletTransactionStatus.${membershipWalletTransaction?.status}`)})`}
    >
      {isLoading &&
        Array.from({ length: 4 }).map((_, index) => (
          <HStack alignItems="center" mt="10px" py="5px" px="20px" key={index}>
            <Skeleton h="10" w="100%" startColor="gray.400" />
          </HStack>
        ))}
      {membershipWalletTransaction && (
        <Card>
          <Text fontWeight="600" fontSize="18px" mb="20px">
            {membershipWalletTransaction.description}
          </Text>
          <HStack alignItems="center" py="10px" w="100%" borderColor="gray.200" borderBottomWidth="1">
            <Text fontWeight="500" fontSize="16px">
              {t('start')}
            </Text>
            <Spacer />
            <HStack alignItems="center">
              <Text fontSize="16px">{moment(membershipWalletTransaction.starts_at).format('ll')}</Text>
            </HStack>
          </HStack>
          <HStack alignItems="center" py="10px" w="100%" borderColor="gray.200" borderBottomWidth="1">
            <Text fontWeight="500" fontSize="16px">
              {t('end')}
            </Text>
            <Spacer />
            <HStack alignItems="center">
              <Text fontSize="16px">{moment(membershipWalletTransaction.ends_at).format('ll')}</Text>
            </HStack>
          </HStack>
          <HStack alignItems="center" py="10px" w="100%">
            <VStack>
              <Text fontWeight="600" fontSize="16px">
                {t('walletTransaction.total')}
              </Text>
            </VStack>
            <Spacer />
            <Text fontWeight="600" fontSize="20px">
              {formatMoney(membershipWalletTransaction.total_amount)}
            </Text>
          </HStack>
          <HStack alignItems="center" w="100%">
            <VStack>
              <Text color="gray.800" fontSize="12px">
                {t('walletTransaction.payment_method.' + membershipWalletTransaction.payment_method)}
              </Text>
            </VStack>
            <Spacer />
            <Text color="gray.800" fontSize="12px">
              {formatMoney(membershipWalletTransaction.total_amount)}
            </Text>
          </HStack>
          <HStack alignItems="center" w="100%">
            <VStack>
              <Text color="gray.800" fontSize="12px">
                {t('walletTransaction.vat')}
              </Text>
            </VStack>
            <Spacer />
            <Text color="gray.800" fontSize="12px">
              {formatMoney(membershipWalletTransaction.vat)}
            </Text>
          </HStack>
        </Card>
      )}
    </ScreenLayout>
  )
}

export default MembershipPaymentScreen

MembershipPaymentScreen.navigationOptions = {
  headerShown: false
}
