import { GET_SUPPORT, FETCHING_SUPPORT } from '../types';

const initialState = {
  fetching: false,
  topics: []
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_SUPPORT:
      return {
        topics: payload,
        fetching: false
      };
    case FETCHING_SUPPORT:
      return {
        fetching: true
      };
    default:
      return state;
  }
}
