import { useTranslation } from 'react-i18next'
import { formatMoney, useRouter, UserRoles } from '@charlycares/shared'
import { useGetCancelledBookingTransactionQuery, useGetUserQuery } from '@charlycares/data-access'
import { Card, ScreenLayout } from '@charlycares/ui'
import { Avatar, HStack, Skeleton, Spacer, Text, VStack } from 'native-base'
import React from 'react'
import moment from 'moment/moment'

const CancelledBookingPaymentScreen = () => {
  const { t } = useTranslation()
  const { getParam } = useRouter()
  const transactionUuid = getParam('transactionUuid')
  const bookingId = getParam('bookingId')

  const { data: user } = useGetUserQuery()
  const role = user?.role || UserRoles.FAMILY

  const { isLoading, data: cancelledBookingWalletTransaction } = useGetCancelledBookingTransactionQuery({
    booking_id: bookingId,
    transaction_uuid: transactionUuid
  })

  return (
    <ScreenLayout
      edges={['left', 'right']}
      supportButton
      disableScroll
      title={t('payment')}
      subtitle={`${bookingId} (${t(`walletTransactionStatus.${cancelledBookingWalletTransaction?.status}`)})`}
    >
      {isLoading &&
        Array.from({ length: 5 }).map((_, index) => (
          <HStack alignItems="center" mt="10px" py="5px" px="20px" key={index}>
            <Skeleton h="10" w="100%" startColor="gray.400" />
          </HStack>
        ))}
      {cancelledBookingWalletTransaction && (
        <Card>
          <HStack alignItems="center" w="100%" pb="10px" borderColor="gray.200" borderBottomWidth="1">
            <Avatar
              source={{
                uri:
                  role === UserRoles.FAMILY
                    ? cancelledBookingWalletTransaction.angel.image
                    : cancelledBookingWalletTransaction.family.image
              }}
              borderWidth="1px"
              borderColor="gray.200"
            />
            <Text fontSize="16px" ml="10px">
              {role === UserRoles.FAMILY && cancelledBookingWalletTransaction.angel.first_name}
              {role === UserRoles.ANGEL && cancelledBookingWalletTransaction.family.last_name}
            </Text>
          </HStack>
          <HStack alignItems="center" py="10px" w="100%" borderColor="gray.200" borderBottomWidth="1">
            <Text fontWeight="600" fontSize="16px">
              {t('start')}
            </Text>
            <Spacer />
            <HStack fontSize="16px" alignItems="center">
              <Text color="primary.400">{moment(cancelledBookingWalletTransaction.starts_at).format('ll HH:mm')}</Text>
            </HStack>
          </HStack>
          <HStack alignItems="center" py="10px" w="100%" borderColor="gray.200" borderBottomWidth="1">
            <Text fontWeight="600" fontSize="16px">
              {t('end')}
            </Text>
            <Spacer />
            <HStack fontSize="16px" alignItems="center">
              <Text color="primary.400">{moment(cancelledBookingWalletTransaction.ends_at).format('ll HH:mm')}</Text>
            </HStack>
          </HStack>
          <HStack alignItems="center" py="10px">
            <Text color={'gray.900'}>{t('cancelledBooking.penaltyAmount')}</Text>
            <Spacer />
            <Text color={'gray.900'}>{formatMoney(cancelledBookingWalletTransaction.penalty_amount)}</Text>
          </HStack>
          {role !== UserRoles.ANGEL && cancelledBookingWalletTransaction.service_fee && (
            <HStack alignItems="center" py="10px">
              <Text color={'gray.900'}>{t('cancelledBooking.serviceFee')}</Text>
              <Spacer />
              <Text color={'gray.900'}>{formatMoney(cancelledBookingWalletTransaction.service_fee)}</Text>
            </HStack>
          )}
          <HStack alignItems="center" py="10px" w="100%" borderColor="gray.200" borderTopWidth="1">
            <VStack>
              <Text fontWeight="600" fontSize="16px">
                {t('cancelledBooking.total')}
              </Text>
            </VStack>
            <Spacer />
            <Text fontWeight="600" fontSize="22px">
              {role === UserRoles.FAMILY && formatMoney(cancelledBookingWalletTransaction.total_amount)}
              {role === UserRoles.ANGEL && formatMoney(cancelledBookingWalletTransaction.penalty_amount)}
            </Text>
          </HStack>
          {role === UserRoles.FAMILY && (
            <>
              <HStack alignItems="center" w="100%">
                <VStack>
                  <Text color="gray.800" fontSize="12px">
                    {t('cancelledBooking.angelPayout')}
                  </Text>
                </VStack>
                <Spacer />
                <Text color="gray.800" fontSize="12px">
                  {formatMoney(cancelledBookingWalletTransaction.penalty_amount)}
                </Text>
              </HStack>
              <HStack alignItems="center" w="100%">
                <VStack>
                  <Text color="gray.800" fontSize="12px">
                    {t('cancelledBooking.payment_method.' + cancelledBookingWalletTransaction.payment_method)}
                  </Text>
                </VStack>
                <Spacer />
                <Text color="gray.800" fontSize="12px">
                  {formatMoney(cancelledBookingWalletTransaction.total_amount)}
                </Text>
              </HStack>
              <HStack alignItems="center" w="100%">
                <VStack>
                  <Text color="gray.800" fontSize="12px">
                    {t('endBooking.vat')}
                  </Text>
                </VStack>
                <Spacer />
                <Text color="gray.800" fontSize="12px">
                  {formatMoney(cancelledBookingWalletTransaction.vat)}
                </Text>
              </HStack>
            </>
          )}
        </Card>
      )}
    </ScreenLayout>
  )
}

export default CancelledBookingPaymentScreen

CancelledBookingPaymentScreen.navigationOptions = {
  headerShown: false
}
