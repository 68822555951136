import styled from 'styled-components/native';
import colors from '../../styles/colors';

const ButtonSwitch = styled.View`
  flex-direction: row;
  padding: 2px;
  margin: 15px;
  border-width: 1px;
  border-color: ${colors.primary};
  border-radius: 5px;
`;

export default ButtonSwitch;
