import { i18n } from '@charlycares/shared'
import moment, { Moment } from 'moment'
import { Days } from '../types'

export const formatDate = (date?: string | Date | Moment, formatType?: string | { [key: string]: string }): string => {
  if (!date) return ''

  if (typeof formatType === 'object') {
    const locale = moment().locale()
    return moment(date).format(formatType?.[locale] || 'LL')
  }

  return moment(date).format(formatType || 'LL')
}

export function minutesToCredit(n: number): string {
  const hours = n / 60
  const restHours = Math.floor(hours)
  const minutes = (hours - restHours) * 60
  const restMinutes = Math.round(minutes)

  return `${restHours}:${restMinutes}`
}

export const getLastOnline = (date?: string | Date | Moment) => {
  const lastOnline = moment(date)
  const now = moment()
  const duration = moment.duration(now.diff(lastOnline))
  const years = duration.get('y')
  const months = duration.get('months')
  const weeks = duration.get('weeks')
  const days = duration.get('d')
  const hours = duration.get('h')
  const minutes = duration.get('m')

  if (years >= 1) {
    return lastOnline.format('DD MMM YYYY')
  } else if (months > 0 && months <= 12) {
    return i18n.t('durations.months', { date: months })
  } else if (weeks > 0 && weeks <= 5) {
    return i18n.t('durations.weeks', { date: weeks })
  } else if (days > 0 && days <= 7) {
    return i18n.t('durations.days', { date: days })
  } else if (hours > 0 && hours <= 24) {
    return i18n.t('durations.hours', { date: hours })
  } else if (minutes > 0 && minutes < 60) {
    return i18n.t('durations.minutes', { date: minutes })
  } else {
    return i18n.t('durations.now')
  }
}

export const getDuration = (startDate: string | Date | Moment, endDate: string | Date | Moment) => {
  const diff = moment(endDate).diff(moment(startDate))
  const diffDuration = moment.duration(diff)

  return diffDuration
}

export const getBookingPickerDatesRange = (startDate?: Moment | string | Date) => {
  const now = moment()
  now.set('seconds', 0)
  const roundMinutes = 15 - (now.minute() % 15)
  now.add(roundMinutes, 'minutes')

  const minStartDate = now.add(30, 'minutes')
  const maxStartDate = moment(minStartDate).add(6, 'months')
  const minEndDate = moment(startDate || minStartDate).add(2, 'hours')
  const maxEndDate = moment(startDate || minStartDate).add(1, 'months')

  return { minStartDate, maxStartDate, minEndDate, maxEndDate }
}

export const getDaysOfWeek = () => {
  return Object.keys(Days)
    .filter(v => !isNaN(Number(v)))
    .map(day =>
      moment()
        .day(parseInt(day) + 1)
        .format('dd')
    )
}

export const getDurationString = (startDate: Moment | string, endDate: Moment | string, short = false) => {
  const differenceHours = moment(endDate).diff(startDate, 'hours', false)
  const differenceMinutes = moment(endDate).diff(startDate, 'minutes', false)
  const minutes = differenceMinutes - differenceHours * 60

  if (short) {
    return `${differenceHours}:${minutes ? `${minutes}` : '00'}`
  }

  return `${differenceHours} ${i18n.t('hours')} ${minutes ? `${minutes} ${i18n.t('minutes')}` : ''}`
}
