import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'

import { Modal } from '../Modals'
import { TextButton } from '../Buttons'
import { isWeb } from '../../utils/functions'
import colors from '../../styles/colors'

const FlatList = styled.FlatList.attrs(({ itemHeight, multiselect, centerItems }) => ({
  contentContainerStyle: {
    flex: 1,
    justifyContent: centerItems ? 'center' : 'flex-start',
    paddingTop: multiselect || isWeb ? 0 : 2 * itemHeight,
    paddingBottom: multiselect || isWeb ? 0 : 2 * itemHeight
  }
}))`
  width: 100%;
  height: ${({ itemHeight }) => 5 * itemHeight};
`

const Border = styled.View`
  position: absolute;
  bottom: ${({ bottom }) => bottom || 0};
  width: 100%;
  height: 2px;
  background-color: ${colors.border};
`

const View = styled.View`
  ${({ flexDirection }) => flexDirection && { 'flex-direction': flexDirection }};
  ${({ justifyContent }) => justifyContent && { 'justify-content': justifyContent }};
  ${({ alignItems }) => alignItems && { 'align-items': alignItems }};
  ${({ paddings }) => paddings && { padding: paddings }};
  ${({ width }) => width && { width }};
  ${({ height }) => height && { height }};
  ${({ backgroundColor }) => backgroundColor && { 'background-color': backgroundColor }};
  ${({ borderBottom }) => borderBottom && { border: `1px solid ${colors.grey08}` }}
`

const Picker = forwardRef(({ itemHeight, multiselect, values, value, onValueChange }, ref) => {
  const [selected, setSelected] = useState(value)
  const modalRef = useRef(null)
  const flatListRef = useRef(null)
  const initialScrollIndex = !multiselect && value ? values.indexOf(value) : 0
  const [t] = useTranslation()

  const setIsVisible = isVisible => {
    if (modalRef) {
      modalRef.current.setIsVisible(isVisible)
    }
  }

  useImperativeHandle(ref, () => ({
    setIsVisible
  }))

  const scrollToIndex = index => {
    if (flatListRef && flatListRef.current && index > -1) {
      flatListRef.current.scrollToIndex({ animated: true, index })
    }
  }

  const onMomentumScrollEnd = ({ nativeEvent }) => {
    if (!multiselect) {
      const offset = nativeEvent.contentOffset
      const newSelectedIndex = Math.round(offset.y / itemHeight)
      const newSelected = values[newSelectedIndex]
      if (newSelected && newSelected !== selected) {
        setSelected(newSelected)
      }
    }
  }

  const onValueSelect = (item, index, isSelected) => {
    if (!multiselect) {
      setSelected(item)
      scrollToIndex(index)
    } else {
      setSelected(
        isSelected
          ? selected.filter(selectedItem => selectedItem.value !== item.value)
          : [...selected, item]
      )
    }
  }

  return (
    <Modal ref={modalRef} bottomModal>
      <View backgroundColor={colors.white}>
        <View
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          height="45px"
          width="100%"
          borderBottom
        >
          <TextButton
            text={t('cancel')}
            textColor={colors.secondary}
            onPress={() => setIsVisible(false)}
          />
          <TextButton
            text={t('done')}
            textColor={colors.secondary}
            onPress={() => {
              setIsVisible(false)
              onValueChange(selected)
            }}
          />
        </View>
        <FlatList
          ref={flatListRef}
          multiselect={multiselect}
          itemHeight={itemHeight}
          centerItems={values.length <= 4}
          snapToAlignment="center"
          snapToInterval={itemHeight}
          showsVerticalScrollIndicator={false}
          initialScrollIndex={initialScrollIndex}
          getItemLayout={(_, index) => ({
            length: itemHeight,
            offset: itemHeight * index,
            index
          })}
          onContentSizeChange={() => scrollToIndex(initialScrollIndex)}
          onMomentumScrollEnd={onMomentumScrollEnd}
          data={values}
          renderItem={({ item, index }) => {
            const isSelected =
              selected && multiselect
                ? !!selected.find(
                    selectedItem => JSON.stringify(selectedItem) === JSON.stringify(item)
                  )
                : JSON.stringify(selected) === JSON.stringify(item)

            return (
              <TextButton
                paddings="0px"
                margins="0px"
                height={itemHeight}
                width="100%"
                text={item.label || item}
                textColor={isSelected ? colors.primary : colors.primaryText}
                onPress={() => onValueSelect(item, index, isSelected)}
              />
            )
          }}
          keyExtractor={item => item.label || item}
        />
        {!multiselect && values.length > 4 && (
          <>
            <Border bottom={125 - itemHeight / 2} />
            <Border bottom={125 + itemHeight / 2} />
          </>
        )}
      </View>
    </Modal>
  )
})

Picker.propTypes = {
  itemHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  multiselect: PropTypes.bool,
  values: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.any }))
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.any }),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf({ label: PropTypes.string, value: PropTypes.any })
  ]),
  onValueChange: PropTypes.func.isRequired
}

Picker.defaultProps = {
  itemHeight: 38,
  multiselect: false,
  values: [],
  value: null
}

export default Picker
