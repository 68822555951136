export default {
  black: '#000',
  grey01: '#303036',
  grey02: '#68686e',
  grey03: '#97979a',
  grey04: '#a9a9ac',
  grey05: '#c7c7c9',
  grey06: '#d9d9d9',
  grey07: '#dedede',
  grey08: '#e6e6e6',
  grey09: '#f2f2f2',
  grey10: '#fafafa',
  white: '#fff',

  primary: '#0B5394',
  primaryAlpha80: '#68aebf80',
  primaryLight: '#deedf1',

  secondary: '#f56b87',
  secondaryLight: '#f1d4dc',

  green: '#33d69d',
  greenLight: '#c1f2e1',

  orange: '#ffba59',
  orangeAlpha50: 'rgba(255, 186, 89, 0.5)',
  yellow: '#fffd8d',
  yellowAlpha20: 'rgba(255, 253, 141, 0.2)',

  warning: '#F50B3A',
  alert: '#dd0000',
  danger: '#dd0000',
  notify: '#4a8fe2',
  chat: '#dff1d4',

  // old colors, TODO

  primaryText: '#303036',
  primaryTextBackground: '#30303680',
  defaultBtnTextColor: '#97979a',
  defaultBtnBackgroundColor: '#D9D9D9',

  backgroundColor: '#f9f8f9',

  transparentBlack: '#00000080',
  transparentWhite: 'rgba(255, 255, 255, 0.6)',
  transparentModal: '#30303680'
}
