import { Box, Text, Heading, Stack, Avatar, useTheme, Pressable } from 'native-base'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CharlyIcon } from '@charlycares/ui'
import { AngelSummery, useRouter } from '@charlycares/shared'

type Props = {
  data: AngelSummery[]
}

const SelectedAngelsList = ({ data }: Props) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { navigate } = useRouter()

  return (
    <Card>
      <Heading fontWeight={600} fontSize="16px">
        {t('selectedAngels')}
      </Heading>

      <Stack mt="15px" space="15px">
        {data.map(angel => (
          <Pressable
            onPress={() => navigate('AngelProfile', `/booking-angel-profile`, { angelId: angel?.obscured_id })}
            key={angel.id}
            alignItems="center"
            flexDir="row"
          >
            <Box>
              <Avatar shadow="5" borderWidth="1px" borderColor="white" source={{ uri: angel.image }} />
              {angel.is_liked && (
                <CharlyIcon
                  style={{ elevation: 5 }}
                  zIndex={10}
                  position="absolute"
                  left="-10px"
                  top="-5px"
                  color={colors.primary[400]}
                  name="heart"
                  size={20}
                />
              )}
            </Box>
            <Text ml="15px" fontSize="14px">
              {angel.first_name}
            </Text>
          </Pressable>
        ))}
      </Stack>
    </Card>
  )
}

export default SelectedAngelsList
