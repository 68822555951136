import React, { useEffect, useState } from 'react'
import ContentLoader, { Rect, Circle } from 'react-content-loader/native'
import { CharlyIcon } from '@charlycares/ui'
import { Box, Center, Text } from 'native-base'
import { useTranslation } from 'react-i18next'

const SearchingAngels = () => {
  const { t } = useTranslation()

  const [upsIndex, setUpsIndex] = useState(1)

  useEffect(() => {
    const interval = setInterval(() => {
      setUpsIndex(prevState => (prevState < 2 ? prevState + 1 : 0))
    }, 2000)

    return () => clearInterval(interval)
  }, [])

  return (
    <Box w="100%" flex={1} bg="white">
      <Center position="absolute" zIndex={2} top={'40%'} left={0} right={0} flexDir="row" flex={1}>
        <CharlyIcon margin="0px 8px 4px 0px" size={'32px'} name="icn-check" color="primary.400" />

        <Text fontFamily="heading" fontSize={16}>
          {t(`uspLoading${upsIndex}`)}
        </Text>
      </Center>

      {[...Array(6)].map((_, i) => (
        <ContentLoader height={130} width={'100%'} speed={2} key={i.toString()}>
          <Circle x="40.4" cy="30.5" r="25.2" />

          <Rect x="80.5" y="15.05" rx="0" ry="0" width="175.96" height="7" />

          <Rect x="80.5" y="34.05" rx="0" ry="0" width="150.96" height="7" />

          <Rect x="15.5" y="70.05" rx="0" ry="0" width="90%" height="5" />

          <Rect x="15.5" y="85.05" rx="0" ry="0" width="90%" height="5" />

          <Rect x="15.5" y="100.05" rx="0" ry="0" width="90%" height="5" />
        </ContentLoader>
      ))}
    </Box>
  )
}
export default SearchingAngels
