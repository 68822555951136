import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { Icon, Text } from '../../elements';
import colors from '../../styles/colors';

const View = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 10px;
  height: 45px;
`;
const IconTextView = styled.View`
  flex-direction: ${({ flexDirection }) => flexDirection};
  align-items: center;
`;

const TextTextView = ({
  leftIcon,
  leftIconColor,
  leftText,
  leftTextFontFamily,
  leftTextFontSize,
  leftTextAlign,
  leftTextColor,
  rightText,
  rightTextFontFamily,
  rightTextFontSize,
  rightTextAlign,
  rightTextColor,
  flexDirection
}) => (
  <View>
    <IconTextView flexDirection={flexDirection}>
      <Icon source={leftIcon} color={leftIconColor} />

      <Text
        margins="0px 0px 0px 10px"
        fontFamily={leftTextFontFamily}
        fontSize={leftTextFontSize}
        textAlign={leftTextAlign}
        color={leftTextColor}>
        {leftText}
      </Text>
    </IconTextView>

    <Text
      fontFamily={rightTextFontFamily}
      fontSize={rightTextFontSize}
      textAlign={rightTextAlign}
      color={rightTextColor}>
      {rightText}
    </Text>
  </View>
);

TextTextView.propTypes = {
  leftIcon: PropTypes.node.isRequired,
  leftIconColor: PropTypes.string,
  leftText: PropTypes.string.isRequired,
  leftTextFontFamily: PropTypes.string,
  leftTextFontSize: PropTypes.number,
  leftTextAlign: PropTypes.string,
  leftTextColor: PropTypes.string,
  rightText: PropTypes.string.isRequired,
  rightTextFontFamily: PropTypes.string,
  rightTextFontSize: PropTypes.number,
  rightTextAlign: PropTypes.string,
  rightTextColor: PropTypes.string,
  flexDirection: PropTypes.string
};

TextTextView.defaultProps = {
  flexDirection: 'row',
  leftIconColor: 'black',
  leftTextFontFamily: 'Open Sans',
  leftTextFontSize: '13px',
  leftTextAlign: 'auto',
  leftTextColor: colors.primaryText,
  rightTextFontFamily: 'Open Sans',
  rightTextFontSize: '13px',
  rightTextAlign: 'right',
  rightTextColor: colors.grey02
};

export default TextTextView;
