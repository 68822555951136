import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { ImageBackground, FullLogo, Heading } from '../../elements'
import { TextButton, LanguageSwitch } from '../../components'
import theme from '../../styles/theme'
import { isWeb, changeMomentLocale } from '../../utils/functions'

import backgroundSource from '../../../assets/images/boardingBackground2.png'
import { getCityLaunch, useRouter, UserRoles } from '@charlycares/shared'
import { ServiceGroupTypesScreen, SignUpScreen, PreBoardingFamilyStep1Screen } from '@charlycares/features'
import { Box } from 'native-base'

const SafeAreaView = styled.SafeAreaView`
  flex: 1 1 auto;
  justify-content: ${({ justifyContent }) => justifyContent};
  min-height: 100%;
`
const Header = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
`
const View = styled.View`
  align-items: center;
`
const ButtonsContainer = styled.View`
  align-items: center;
  width: 100%;
  padding: 20px;
  padding-bottom: 60px;
  margin-bottom: -40px;
`

const Boarding = ({ navigation, history }) => {
  const [t, i18n] = useTranslation()
  const { navigate, getParam } = useRouter()

  return (
    <Box w="100%" maxW="600px" margin="auto">
      <ImageBackground source={!isWeb && backgroundSource}>
        <SafeAreaView justifyContent={isWeb ? 'center' : 'space-between'}>
          <Header>
            <FullLogo />

            <LanguageSwitch
              top="20px"
              selectedLanguage={i18n.language}
              onPress={language => {
                i18n.changeLanguage(language)
                changeMomentLocale(language === 'nl_NL' ? 'nl' : 'en')
              }}
            />
          </Header>
          <View>
            <ButtonsContainer>
              <Heading
                xxl
                ariaLevel={1}
                text={t('boardingFinalWelcomeTitle')}
                textAlign="center"
                color={isWeb ? theme.headingsColorBase : theme.colors.white}
              />
              <TextButton
                margins="10px 0px 0px 0px"
                width="100%"
                backgroundColor={theme.colors.primary}
                text={t('boardingFinalParentButton')}
                onPress={() =>
                  navigate('PreBoardingFamilyStep1Screen', '/family-preboarding-step-1', {
                    referralCode: getParam('referralCode', null)
                  })
                }
              />
              <TextButton
                margins="20px 0px 0px 0px"
                width="100%"
                borderRightWidth="1px"
                borderLeftWidth="1px"
                borderColor={theme.colors.primary}
                backgroundColor={theme.colors.white}
                text={t('boardingFinalBabysitterButton')}
                textColor={theme.colors.primary}
                onPress={() =>
                  navigate(SignUpScreen, '/signup/angel', {
                    cityLaunch: getCityLaunch(),
                    role: UserRoles.ANGEL,
                    referralCode: getParam('referralCode')
                  })
                }
              />
              <TextButton
                margins="20px 0px 0px 0px"
                width="100%"
                borderRightWidth="1px"
                borderLeftWidth="1px"
                backgroundColor={theme.colors.primary}
                text={t('boardingFinalLogIn')}
                textColor={theme.colors.white}
                onPress={() => {
                  if (navigation) {
                    navigation.navigate('Login')
                  } else {
                    history.push('/login')
                  }
                }}
              />
            </ButtonsContainer>
          </View>
        </SafeAreaView>
      </ImageBackground>
    </Box>
  )
}

Boarding.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

Boarding.defaultProps = {
  navigation: null,
  history: null
}

export default Boarding
