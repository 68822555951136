import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { TextIconView } from '../../Texts';
import { roundMonetaryValue, renderDistanceInKilometers } from '../../../utils/functions';
import theme from '../../../styles/theme';

import extraRateIcon from '../../../../assets/icons/icn-night.png';
import normalRateIcon from '../../../../assets/icons/icn-day.png';
import fixedRateIcon from '../../../../assets/icons/icn-repeat.png';
import distanceIcon from '../../../../assets/icons/icn-location.png';

const View = styled.View`
  width: ${({ width }) => width};
  margin: ${({ margins }) => margins};
  padding: ${({ paddings }) => paddings};
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent};
  border-top-width: ${({ borderTopWidth }) => borderTopWidth};
  border-bottom-width: ${({ borderBottomWidth }) => borderBottomWidth};
  border-color: ${theme.dividerLineColor};
`;

const RatesDistance = ({
  width,
  margins,
  justifyContent,
  paddings,
  fontSize,
  borderTopWidth,
  borderBottomWidth,
  iconSize,
  iconMargins,
  normalRate,
  extraRate,
  fixedRate,
  distance,
  iconColor
}) => (
  <View
    justifyContent={justifyContent}
    width={width}
    margins={margins}
    paddings={paddings}
    borderTopWidth={borderTopWidth}
    borderBottomWidth={borderBottomWidth}>
    <TextIconView
      minWidth="35px"
      margins={iconMargins}
      flexDirection="column-reverse"
      backgroundColor="transparent"
      text={renderDistanceInKilometers(distance)}
      fontSize={fontSize}
      icon={distanceIcon}
      iconSize={iconSize}
      iconColor={iconColor}
    />

    <TextIconView
      minWidth="35px"
      margins={iconMargins}
      flexDirection="column-reverse"
      backgroundColor="transparent"
      text={`€ ${roundMonetaryValue(normalRate)}`}
      fontSize={fontSize}
      icon={normalRateIcon}
      iconSize={iconSize}
      iconColor={iconColor}
    />

    <TextIconView
      minWidth="35px"
      margins={iconMargins}
      flexDirection="column-reverse"
      backgroundColor="transparent"
      text={`€ ${roundMonetaryValue(extraRate)}`}
      fontSize={fontSize}
      icon={extraRateIcon}
      iconSize={iconSize}
      iconColor={iconColor}
    />

    <TextIconView
      minWidth="35px"
      margins={iconMargins}
      flexDirection="column-reverse"
      backgroundColor="transparent"
      text={`€ ${roundMonetaryValue(fixedRate)}`}
      fontSize={fontSize}
      icon={fixedRateIcon}
      iconSize={iconSize}
      iconColor={iconColor}
    />
  </View>
);

RatesDistance.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  justifyContent: PropTypes.string,
  margins: PropTypes.string,
  paddings: PropTypes.string,
  borderTopWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderBottomWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconMargins: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  normalRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  extraRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fixedRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  distance: PropTypes.number,
  iconColor: PropTypes.string
};

RatesDistance.defaultProps = {
  justifyContent: null,
  width: 'auto',
  margins: '0',
  paddings: '0',
  borderTopWidth: 0,
  borderBottomWidth: 0,
  iconSize: '24px',
  fontSize: theme.fontSize.sm,
  iconMargins: '0px 10px 0px 0px',
  distance: 0,
  iconColor: theme.textColorBase
};

export default RatesDistance;
