import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon as ImageIcon } from '../../elements';
import CharlyIcon from '../Icons/Icon';

const IconButton = ({
  iconWidth,
  iconHeight,
  iconSize,
  iconSource,
  iconColor,
  useIconTintColor,
  children,
  iconMargins,
  iconName,
  ...buttonProps
}) => {
  const btnRef = React.createRef();

  React.useLayoutEffect(() => {
    const { current } = btnRef;

    if (current) {
      current.setNativeProps({
        tabIndex: -1,
        style: { outline: 'none' }
      });
    }
  }, []);

  return (
    <Button {...buttonProps} ref={btnRef}>
      {iconName ? (
        <CharlyIcon name={iconName} color={iconColor} size={iconSize} />
      ) : (
        <ImageIcon
          width={iconSize || iconWidth}
          height={iconSize || iconHeight}
          source={iconSource}
          color={iconColor}
          useTintColor={useIconTintColor}
          margins={iconMargins}
        />
      )}
      {children}
    </Button>
  );
};

IconButton.propTypes = {
  iconWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  iconHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  iconSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  iconSource: PropTypes.node,
  iconColor: PropTypes.string,
  useIconTintColor: PropTypes.bool,
  children: PropTypes.node,
  iconMargins: PropTypes.string,
  iconName: PropTypes.string
};

IconButton.defaultProps = {
  iconWidth: '20px',
  iconHeight: '20px',
  iconSize: null,
  iconColor: '',
  useIconTintColor: true,
  children: null,
  iconMargins: '0px',
  iconName: '',
  iconSource: undefined
};

export default IconButton;
