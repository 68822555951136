import React from 'react'
import { toggleSelectedAngels, useAppSelector } from '@charlycares/data-access'
import { Avatar, Box, HStack } from 'native-base'
import { CharlyIcon } from '@charlycares/ui'
import { useDispatch } from 'react-redux'
import { Pressable } from 'react-native'
import { useRouter } from '@charlycares/shared'
import { IHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack'

const SelectedAngelsContainer = (props: IHStackProps) => {
  const dispatch = useDispatch()
  const { navigate } = useRouter()
  const selectedAngels = useAppSelector(state => state.booking.search.selectedAngels)

  if (!selectedAngels.length) {
    return null
  }

  return (
    <HStack space="10px" flexWrap="wrap" {...props}>
      {selectedAngels.map(angel => (
        <Box key={angel.id}>
          <Pressable onPress={() => navigate('AngelProfile', `/booking-angel-profile`, { angelId: angel.obscured_id })}>
            <Avatar borderColor="white" borderWidth={2} source={{ uri: angel.image }} size={'40px'} />
          </Pressable>
          <CharlyIcon
            hitSlop={{ top: 5, bottom: 5, left: 5, right: 5 }}
            onPress={() => dispatch(toggleSelectedAngels(angel))}
            position="absolute"
            right={-6}
            top={-6}
            name="btn-close"
            color="gray.800"
            size={20}
          />
        </Box>
      ))}
    </HStack>
  )
}

export default SelectedAngelsContainer
