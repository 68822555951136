import { Day } from 'react-native-gifted-chat';
import styled from 'styled-components/native';
import colors from '../../styles/colors';

const StyledDay = styled(Day).attrs({
  containerStyle: {
    backgroundColor: colors.grey05,
    width: '100px',
    padding: '4px',
    borderRadius: '11px',
    alignSelf: 'center'
  },
  textStyle: {
    fontFamily: 'Open Sans',
    color: colors.primaryText
  }
})``;

export default StyledDay;
