import React from 'react'
import styled from 'styled-components/native'
import { useLocation } from 'react-router-dom'
import { isBrowser } from 'react-device-detect'

import { showNavbar } from '@charlycares/legacy/web'

const ColumnRight = styled.View`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  ${({ alignItems }) => alignItems && { 'align-items': alignItems }};
  width: 250px;
  height: ${({ height }) => height || 'auto'};
`

const RightBanner = () => {
  const location = useLocation()

  return (
    isBrowser &&
    showNavbar(location) && <ColumnRight accessibilityRole="none" nativeID="layoutRightColumn" />
  )
}

export default RightBanner
