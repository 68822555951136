import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextView } from '../Texts';
import { LabeledInput, IconButtonLabeledInput } from '../Inputs';
import colors from '../../styles/colors';

import passwordShowIcon from '../../../assets/icons/icn-small-eye-open.png';
import passwordHideIcon from '../../../assets/icons/icn-small-eye-shut.png';

const EmailPasswordEdit = ({
  showTitle,
  highlightInputBackground,
  validationErrors,
  email,
  password,
  inputFocused,
  emailInputRef,
  onEmailChangeText,
  onEmailFocus,
  onEmailSubmitEditing,
  passwordInputRef,
  onPasswordChangeText,
  hidePassword,
  toggleHidePasswordPress,
  onPasswordFocus,
  onPasswordSubmitEditing,
  returnKeyTypeEmail,
  returnKeyTypePassword
}) => {
  const [t] = useTranslation();

  return (
    <>
      {showTitle && (
        <TextView
          paddings="15px 15px 5px 15px"
          borderTopWidth="1px"
          borderBottomWidth="1px"
          backgroundColor={colors.backgroundColor}
          text={t('account')}
          fontSize="14px"
          textColor={colors.grey02}
        />
      )}

      <LabeledInput
        inputRef={emailInputRef}
        margins="20px"
        paddings={validationErrors && validationErrors.email ? '0px 0px 0px 5px' : '0px'}
        borderLeftWidth={validationErrors && validationErrors.email ? '4px' : 0}
        borderColor={colors.secondary}
        inputBorderColor={
          inputFocused && inputFocused.email ? colors.secondary : colors.defaultBtnBackgroundColor
        }
        inputBackgroundColor={highlightInputBackground ? colors.backgroundColor : colors.white}
        labelText={t('emailAddress')}
        inputPaddings="0px 0px 0px 5px"
        keyboardType="email-address"
        validationMessage={validationErrors && validationErrors.email}
        value={email}
        onChangeText={onEmailChangeText}
        onFocus={onEmailFocus}
        onSubmitEditing={onEmailSubmitEditing}
        returnKeyType={returnKeyTypeEmail}
      />

      <IconButtonLabeledInput
        inputRef={passwordInputRef}
        margins="0px 20px 20px 20px"
        paddings={validationErrors && validationErrors.password ? '0px 0px 0px 5px' : '0px'}
        width="100%"
        borderLeftWidth={validationErrors && validationErrors.password ? '4px' : 0}
        borderLeftColor={colors.secondary}
        borderColor={
          inputFocused && inputFocused.password
            ? colors.secondary
            : colors.defaultBtnBackgroundColor
        }
        backgroundColor={highlightInputBackground ? colors.backgroundColor : colors.white}
        inputPaddings="0px 0px 0px 5px"
        labelText={t('password')}
        returnKeyType={returnKeyTypePassword}
        validationMessage={validationErrors && validationErrors.password}
        value={password}
        onChangeText={onPasswordChangeText}
        secureTextEntry={hidePassword}
        iconSource={hidePassword ? passwordHideIcon : passwordShowIcon}
        iconColor={colors.secondary}
        onIconPress={toggleHidePasswordPress}
        onFocus={onPasswordFocus}
        onSubmitEditing={onPasswordSubmitEditing}
      />
    </>
  );
};

EmailPasswordEdit.propTypes = {
  showTitle: PropTypes.bool,
  highlightInputBackground: PropTypes.bool,
  validationErrors: PropTypes.shape({ email: PropTypes.string, password: PropTypes.string }),
  email: PropTypes.string.isRequired,
  password: PropTypes.string,
  inputFocused: PropTypes.shape({ email: PropTypes.bool, password: PropTypes.bool }),
  emailInputRef: PropTypes.shape({}).isRequired,
  onEmailChangeText: PropTypes.func.isRequired,
  onEmailFocus: PropTypes.func.isRequired,
  onEmailSubmitEditing: PropTypes.func,
  passwordInputRef: PropTypes.shape({}).isRequired,
  onPasswordChangeText: PropTypes.func.isRequired,
  hidePassword: PropTypes.bool.isRequired,
  toggleHidePasswordPress: PropTypes.func.isRequired,
  onPasswordFocus: PropTypes.func.isRequired,
  onPasswordSubmitEditing: PropTypes.func,
  returnKeyTypeEmail: PropTypes.string,
  returnKeyTypePassword: PropTypes.string
};

EmailPasswordEdit.defaultProps = {
  showTitle: true,
  highlightInputBackground: true,
  validationErrors: null,
  password: null,
  inputFocused: null,
  onEmailSubmitEditing: () => {},
  onPasswordSubmitEditing: () => {},
  returnKeyTypeEmail: 'done',
  returnKeyTypePassword: 'done'
};

export default EmailPasswordEdit;
