import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatMoney, useRouter } from '@charlycares/shared'
import { Card, CharlyIcon, ScreenLayout } from '@charlycares/ui'
import { Button, Flex, Heading, Text } from 'native-base'
import { useGetAvailableMembershipsQuery } from '@charlycares/data-access'

export default function NewCityStep3Screen() {
  const { t } = useTranslation()
  const { navigate } = useRouter()

  const { data: availableMemberships } = useGetAvailableMembershipsQuery()
  const priceMonthlyFlex = formatMoney(availableMemberships?.flexible.monthly.costs)

  return (
    <ScreenLayout
      progress={`${75}%`}
      subtitle={''}
      headerLeft={<CharlyIcon name="logo" color="primary.400" size={30} />}
      bgColor="white"
      hideBackButton
    >
      <Card borderWidth="0" minHeight="530px">
        <Text fontSize="16px" color="gray.800">
          {t('newCityStep3Screen.headingDisclaimer')}
        </Text>
        <Heading mb="25px" mt="5px" fontSize="20" fontWeight="600" lineHeight="26px">
          {t('newCityStep3Screen.header', {
            price: priceMonthlyFlex
          })}
        </Heading>

        <Flex mt="45px" direction="row">
          <CharlyIcon name="icn-insurance" size="30px" color="primary.400" />
          <Text fontSize="18px" ml="10px" lineHeight="26px">
            {t('newCityStep3Screen.insurance')}
          </Text>
        </Flex>
      </Card>

      <Button mx="20px" onPress={() => navigate('NewCityStep4Screen', '/family/new-city/step-4')}>
        {t('newCityStep3Screen.nextStep')}
      </Button>
    </ScreenLayout>
  )
}
