import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { addToJoblist } from '../../stores/actions/jobFinderActions'
import { Divider } from '../../elements'
import {
  TextTextView,
  TextButton,
  TextIconButton,
  BookingInvitationDetails,
  RequestActiveDetails,
  BookingInvitationAdditionalDetails,
  Header
} from '../../components'
import colors from '../../styles/colors'

import calendarIcon from '../../../assets/icons/tabbar-icn-today.png'
import { Linking } from 'react-native'

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  justify-content: space-between;
`
const ScrollView = styled.ScrollView`
  flex: 1;
`
const ButtonsView = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 10px 20px 10px 20px;
  border-top-width: 1px;
  border-color: ${colors.grey08};
`
const Separator = styled.View`
  margin: 0px 0px 0px 20px;
  width: 1px;
  height: 100%;
  background-color: ${colors.grey08};
`
const AddView = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: center;
`

const Details = ({ location, history, route, navigation, addedToJoblist, addToJoblistData, addToJoblistError }) => {
  const { bookingId, invitationDetails } = location.state || route.params || {}
  const { bookings = [] } = invitationDetails || {}
  const [expandRequest, setExpandRequest] = useState(bookings.map(() => false))
  const [selectedRequestsDates, setSelectedRequestDates] = useState(
    bookings.map(({ bookingdates }) => bookingdates.map(() => true))
  )
  const [t] = useTranslation()
  const scrollView = useRef(null)

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <TextTextView
          flexDirection="column"
          firstText={`${t('booking')}`}
          firstTextFontFamily="Martel"
          firstTextAlign="center"
          firstFontWeight="600"
          secondText={`${t('detail')}: BKF-${bookingId}`}
          secondTextAlign="center"
        />
      ),
      headerRight: (
        <TextButton
          paddings="0px 10px 0px 0px"
          margins="0px 10px 0px 0px"
          height={30}
          text={t('support')}
          fontWeight="normal"
          textColor={colors.secondary}
          onPress={() => Linking.openURL('https://support.charlycares.com')}
        />
      )
    })
  }

  useEffect(() => {
    if (addToJoblistError) {
      // Alert.alert(addToJoblistError, '', [{ text: strings.ok }]);
    }

    if (addedToJoblist) {
      if (navigation) {
        navigation.navigate('Bookings')
      } else {
        history.push('/bookings')
      }
    }
  })

  const expandRequestPress = i => {
    const copyExpandRequest = [...expandRequest]
    copyExpandRequest[i] = true
    setExpandRequest(copyExpandRequest)
  }

  const {
    id,
    family_data,
    distance,
    expected_earnings,
    // bookings,
    message,
    normal_rate,
    extra_rate,
    fixed_rate,
    repeat_qty
  } = invitationDetails
  const {
    last_name,
    image,
    short_bio,
    lat,
    lon,
    city,
    street_name,
    street_number,
    child_count,
    cat,
    dog,
    conversation_id,
    family_id,
    obscured_id
  } = family_data

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          title={`${t('booking')}`}
          subtitle={`${t('detail')}: BKF-${bookingId}`}
          right={
            <TextButton
              paddings="0px 10px 0px 0px"
              margins="0px 10px 0px 0px"
              height="30px"
              text={t('support')}
              fontWeight="normal"
              textColor={colors.secondary}
              onPress={() => history.push('/support')}
            />
          }
        />
      )}
      <ScrollView ref={scrollView}>
        <Divider />

        <BookingInvitationDetails
          family={last_name}
          familyImage={image}
          familyShortBio={short_bio}
          childCount={child_count}
          isFixedBooking={repeat_qty > 1}
          hasMessage={!!message}
          hasCat={!!cat}
          hasDog={!!dog}
          onMessagePress={() => {
            if (conversation_id) {
              navigation.navigate('ChatScreen', { conversationId: conversation_id })
            }
          }}
          onFamilyPress={() => {
            history.push({ pathname: '/profile-family', state: { familyId: obscured_id || family_id } })
          }}
          angelNormalRate={normal_rate.toString()}
          angelExtraRate={extra_rate.toString()}
          angelFixedRate={fixed_rate.toString()}
          distance={distance}
          expectedErnings={expected_earnings}
          onReadPersonalMessagePress={() => scrollView.current.scrollToEnd({ animated: true })}
        />

        {invitationDetails.bookings &&
          invitationDetails.bookings.map((bookingDetails, index) => (
            <RequestActiveDetails
              key={bookingDetails.id}
              index={index}
              startDate={bookingDetails.start_date}
              endDate={bookingDetails.end_date}
              bookingDates={bookingDetails.bookingdates}
              expand={expandRequest[index]}
              selectedDays={selectedRequestsDates[index]}
              onShowAllPress={() => expandRequestPress(index)}
              showToggeSelectButton={false}
            />
          ))}

        <Divider />

        <BookingInvitationAdditionalDetails
          streetName={street_name}
          streetNumber={street_number}
          city={city}
          lon={lon}
          lat={lat}
          message={message}
        />
      </ScrollView>

      <ButtonsView>
        <TextIconButton
          flexDirection="column-reverse"
          paddings="0px"
          text={t('show')}
          fontSize="10px"
          fontWeight="300"
          textColor={colors.secondary}
          icon={calendarIcon}
          iconSize="32px"
          iconColor={colors.secondary}
          onPress={() => navigation.navigate('FamilyCalendar', { familyId: family_id })}
        />

        <Separator />

        <AddView>
          <TextButton
            width="95%"
            backgroundColor={colors.primary}
            text={t('addMeToBooking')}
            onPress={() => addToJoblistData(id)}
          />
        </AddView>
      </ButtonsView>
    </SafeAreaView>
  )
}

Details.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({ bookingId: PropTypes.string, bookingDetails: PropTypes.shape({}) })
  }),
  history: PropTypes.shape({ push: PropTypes.func }),
  route: PropTypes.shape({
    params: PropTypes.shape({ bookingId: PropTypes.string, bookingDetails: PropTypes.shape({}) })
  }),
  navigation: PropTypes.shape({ navigate: PropTypes.func, setOptions: PropTypes.func }),
  addedToJoblist: PropTypes.bool,
  addToJoblistError: PropTypes.string,
  addToJoblistData: PropTypes.func.isRequired
}

Details.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null,
  addedToJoblist: false,
  addToJoblistError: null
}

const mapStateToProps = ({ JobFinder }) => ({
  addedToJoblist: JobFinder.addedToJoblist,
  addToJoblistError: JobFinder.addToJoblistError
})

const mapDispatchToProps = {
  addToJoblistData: addToJoblist
}

export default connect(mapStateToProps, mapDispatchToProps)(Details)
