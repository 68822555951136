import React, { useMemo } from 'react'
import { Card, CharlyIcon, List, ScreenLayout } from '@charlycares/ui'
import { HStack, Heading, Text, Box, Spacer, Pressable } from 'native-base'
import { useTranslation } from 'react-i18next'
import { decomposeMembershipType, links, SUBSCRIPTION_STATUS, useRouter, WalletType } from '@charlycares/shared'
import { useGetSubscriptionQuery, useGetUserQuery, useGetWalletsQuery } from '@charlycares/data-access'
import { Linking, Platform } from 'react-native'

export default function MembershipInfoScreen() {
  const { t } = useTranslation()
  const { navigate } = useRouter()

  const { data: user } = useGetUserQuery()
  const { data: subscription } = useGetSubscriptionQuery()
  const { data: wallets } = useGetWalletsQuery()
  const isCancelled = subscription?.status === SUBSCRIPTION_STATUS.CANCELLED

  const personalWallet = wallets?.find(wallet => {
    return wallet.wallet_type === WalletType.PERSONAL && wallet.active
  })

  const menuItems = useMemo(() => {
    let currentMembership = null
    let membershipTerm = null

    if (subscription) {
      membershipTerm = decomposeMembershipType(subscription.membership_type).membershipTerm
      currentMembership =
        subscription.status === SUBSCRIPTION_STATUS.ACTIVE ? subscription.membership_type : 'cancelled'
    }

    return [
      {
        label: t('membershipInfoScreen.membership'),
        onPress: () =>
          Platform.OS === 'ios' && subscription?.membership_type.includes('apple') && !isCancelled
            ? Linking.openURL(links.memberships.subscriptions)
            : navigate('ProfileMembershipDetails', '/profile-membership-details'),
        suffix: (
          <Text fontSize={17} textTransform="capitalize" color="gray.700">
            {t('membership.' + currentMembership)}, {t('membershipTerm.' + membershipTerm)}
          </Text>
        )
      },
      {
        label: t('membershipInfoScreen.cancelMembership'),
        onPress: () => navigate('ProfileCancelMembership', '/profile-membership-cancel')
      }
    ]
  }, [t, navigate, user?.family, subscription])

  if (!user?.family) {
    return <ScreenLayout isLoading title={t('membershipInfoScreen.heading')} />
  }

  return (
    <ScreenLayout title={t('membershipInfoScreen.title')} supportButton edges={['left', 'right']}>
      <Card>
        <List items={menuItems} />
      </Card>
      <Card>
        <Heading>{t('membershipInfoScreen.membership')}</Heading>

        <Pressable onPress={() => navigate('ProfileChangeBankAccount', 'profile-bank-account')}>
          <HStack mt="10px">
            <Box>
              <CharlyIcon name={'credit-card'} size={35} color="black" />
            </Box>
            <Box ml="5px">
              <Text>{personalWallet?.account_number}</Text>
              <Text>{t('profileScreensBankCredit')}</Text>
            </Box>
            <Spacer />
            <CharlyIcon size={30} color={'gray.500'} name="icn-caret-right" />
          </HStack>
        </Pressable>
      </Card>
    </ScreenLayout>
  )
}

MembershipInfoScreen.navigationOptions = () => ({
  headerShown: false
})
