import React, { useEffect, useState } from 'react'
import Echo from 'laravel-echo'
import {
  api,
  ChatMessageCollectionItem,
  ChatMessageItem,
  useAppDispatch,
  useBroadcastTokenMutation,
  useGetUserQuery
} from '@charlycares/data-access'

const LoadingContext = React.createContext<{
  loading: boolean
  setLoading: (loading: boolean) => void
}>({ loading: true, setLoading: () => {} })
// @ts-ignore
window.Pusher = require('pusher-js')

function LoadingProvider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = useState(true)

  return <LoadingContext.Provider value={{ loading, setLoading }}>{children}</LoadingContext.Provider>
}

function useLoading() {
  const context = React.useContext(LoadingContext)

  if (!context) {
    throw new Error('use streaming updates need context and user')
  }

  return context
}

export { LoadingProvider, useLoading }
