import React, { useEffect, useState } from 'react'
import { Card, CharlyIcon, ScreenLayout, TextInput } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Divider, Heading, Text } from 'native-base'
import { formatDate, useRouter, UserRoles } from '@charlycares/shared'
import {
  useGetBookingDetailsQuery,
  useGetRatingsCategoriesQuery,
  useGetReviewDetailsQuery,
  useGetUserQuery,
  useGiveReviewMutation
} from '@charlycares/data-access'
import { TouchableOpacity } from 'react-native'

import GiveRating from './common/GiveRating'
import { useAlert } from '../../hooks'

const ReviewDetailsScreen = () => {
  const { t } = useTranslation()
  const { getParam, navigate, goBack } = useRouter()
  const alert = useAlert()

  const ratingId = getParam('ratingId')
  const bookingId = getParam('bookingId')

  const { data: ratingCategories } = useGetRatingsCategoriesQuery({})
  const [giveReview, { isLoading: giveReviewLoading }] = useGiveReviewMutation()
  const { data: user } = useGetUserQuery()
  const { data: booking, isLoading: loadingBooking } = useGetBookingDetailsQuery(
    { obscured_id: bookingId },
    { skip: !bookingId }
  )
  const { data: review, isLoading: loadingReview } = useGetReviewDetailsQuery(
    {
      ratingId
    },
    { skip: !user || !ratingId }
  )

  const [ratings, setRatings] = useState<{ label: string; key: string; value: number }[]>([])
  const [comment, setComment] = useState('')

  const isReadOnly = review?.has_review || user?.role === UserRoles.ANGEL

  useEffect(() => {
    if (review?.has_review) {
      const initialRatings = review.rating_categories.map(category => ({
        key: category.category,
        label: t(`review_${category.category}`),
        value: category.rating
      }))
      setRatings(initialRatings)
    } else if (!review?.has_review && ratingCategories) {
      const initialRatings = ratingCategories.map(category => ({
        key: category,
        label: t(`review_${category}`),
        value: 0
      }))
      setRatings(initialRatings)
    }
  }, [review, ratingCategories, t])

  if (loadingReview || loadingBooking) {
    return <ScreenLayout isLoading title={t('review')} subtitle={ratingId} />
  }

  const isButtonDisabled = ratings.some(rating => rating.value === 0) || comment.length === 0

  const onReviewSubmit = async () => {
    try {
      const categories: {
        [key: string]: number
      } = {}

      ratings.forEach(rating => {
        categories[rating.key] = rating.value
      })

      await giveReview({
        booking_id: bookingId,
        ratings: categories,
        comment
      }).unwrap()

      goBack()
    } catch (error) {
      alert.show(t('errorAlert'), (error as any)?.data?.message || t('somethingWentWrong'))
    }
  }

  return (
    <ScreenLayout
      _buttonProps={
        !isReadOnly
          ? {
              children: t('giveFeedback'),
              isDisabled: isButtonDisabled,
              onPress: onReviewSubmit,
              isLoading: giveReviewLoading
            }
          : undefined
      }
      title={t('review')}
      subtitle={ratingId}
    >
      {!!booking && (
        <TouchableOpacity
          onPress={() => {
            if (user?.role === UserRoles.ANGEL) {
              navigate('FamilyProfile', `/booking-family-profile`, { familyId: booking.family.obscured_id })
            } else {
              navigate('AngelProfile', `/booking-angel-profile`, { angelId: booking.angel?.obscured_id })
            }
          }}
        >
          <Card flexDir="row" alignItems="center">
            <Avatar source={{ uri: user?.role === UserRoles.FAMILY ? booking.angel?.image : booking.family.image }} />
            <Box ml="10px" flex={1}>
              <Heading>{user?.role === UserRoles.FAMILY ? booking.angel?.first_name : booking.family.name}</Heading>
              {review?.start_date && <Text>{formatDate(review.start_date, 'MMMM DD, yyyy')}</Text>}
            </Box>
            <CharlyIcon name="icn-caret-right" color="gray.800" size={32} />
          </Card>
        </TouchableOpacity>
      )}

      <Card>
        {!!ratings.length && (
          <>
            <Heading fontSize={18}>{t('feedback')}</Heading>
            <Text mt="7px" fontSize={15}>
              {t('familyFeedbackDesc')}
            </Text>

            <GiveRating mt="25px" isDisabled={isReadOnly} ratings={ratings} onChange={setRatings} />

            <Divider my="25px" />
          </>
        )}

        <Heading fontSize={18} mb="20px">
          {t('reviewCommentTitle')}
        </Heading>

        {!isReadOnly ? (
          <TextInput
            placeholder={t('reviewTextPlaceholder')}
            maxLength={500}
            h="110px"
            variant="filled"
            multiline
            numberOfLines={5}
            value={comment}
            onChangeText={setComment}
          />
        ) : (
          <Text fontSize={15}>{review?.comment || t('noCommentGiven')}</Text>
        )}
      </Card>
    </ScreenLayout>
  )
}

export default ReviewDetailsScreen

ReviewDetailsScreen.navigationOptions = () => ({
  headerShown: false
})
