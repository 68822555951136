import React from 'react'
import { Center, Heading, HStack, ICenterProps, VStack } from 'native-base'
import { CharlyIcon } from '@charlycares/ui'

type Props = ICenterProps & {
  ratings: {
    label: string
    value: number
    key: string
  }[]
  onChange: (
    rating: {
      label: string
      value: number
      key: string
    }[]
  ) => void
  isDisabled?: boolean
  _starColor?: {
    selected: string
    unselected: string
  }
  _headerTextColor?: string
}

const GiveRating = ({ ratings, _headerTextColor, onChange, isDisabled, _starColor, ...boxProps }: Props) => {
  const selected = _starColor?.selected ?? 'gray.800'
  const unselected = _starColor?.unselected ?? 'gray.300'

  return (
    <Center {...boxProps}>
      <VStack space="30px">
        {ratings.map(({ key, value, label }, index) => (
          <Center key={key}>
            <Heading color={_headerTextColor}>{label}</Heading>
            <HStack space="5px" mt="10px">
              {Array.from({ length: 5 }, (_, i) => (
                <CharlyIcon
                  isDisabled={isDisabled}
                  onPress={() => {
                    const newRatings = [...ratings]
                    newRatings[index].value = i + 1
                    onChange(newRatings)
                  }}
                  key={i}
                  name="star"
                  color={value >= i + 1 ? selected : unselected}
                  size={28}
                />
              ))}
            </HStack>
          </Center>
        ))}
      </VStack>
    </Center>
  )
}

export default GiveRating
