import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  getAvailability,
  saveAvailability
} from '../../../stores/actions/angelPreferencesAvailabilityAction';
import { setOnboardingProgress } from '../../../stores/actions/userAction';
import { Text } from '../../../elements';
import {
  TextView,
  TextTextView,
  TextIconView,
  IconButton,
  TextButton,
  Progress,
  Header
} from '../../../components';
import colors from '../../../styles/colors';

import dayIcon from '../../../../assets/icons/icn-day.png';
import nightIcon from '../../../../assets/icons/icn-night.png';
import morningIcon from '../../../../assets/icons/icn-morning.png';

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  justify-content: space-between;
`;
const Content = styled.ScrollView``;
const View = styled.View`
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
  margin: ${({ margins }) => margins || '0px 20px 10px 20px'};
  ${({ paddings }) => paddings && { padding: paddings }};
  ${({ borderTopWidth }) => borderTopWidth && { 'border-top-width': borderTopWidth }};
  border-color: ${colors.grey08};
`;

const Availability = ({
  location,
  history,
  route,
  navigation,
  availability,
  setProgress,
  getAvailabilityData,
  saveAvailabilityData
}) => {
  const { progress = 86 } = route.params || location.state || {};
  const [angelAvailability, setAngelAvailability] = useState(null);
  const [t] = useTranslation();

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize={18} fontWeight="600">
          {`${progress}% ${t('complete')}`}
        </Text>
      )
    });
  }

  useEffect(() => {
    setProgress(progress);
    getAvailabilityData();
  }, []);

  useEffect(() => {
    if (availability.length && !angelAvailability) {
      setAngelAvailability(availability);
    }
  }, [availability]);

  return (
    <SafeAreaView>
      {!navigation && <Header title={`${progress}% ${t('complete')}`} />}
      <Content>
        <Progress
          progress={progress}
          color={progress === 100 ? colors.primary : colors.secondary}
        />

        <TextTextView
          flexDirection="column"
          alignItems="flex-start"
          margins="20px"
          paddings="0px"
          firstText={t('signUpAngelScreensPeriodicBabysitting')}
          firstTextFontFamily="Martel"
          firstTextFontSize="18px"
          firstTextFontWeight="600"
          secondTextMargins="10px 0px 0px 0px"
          secondText={t('signUpAngelScreensPeriodicBabysittingDescription')}
          secondTextFontSize="15px"
          secondTextFontWeight="300"
          secondTextAlign="left"
        />

        <View>
          <TextIconView
            flexDirection="column-reverse"
            minWidth="60px"
            backgroundColor="transparent"
            icon={nightIcon}
            iconSize="32px"
            iconColor={colors.primaryText}
            text={t('evening')}
            fontSize="12px"
            textAlign="center"
          />

          <TextIconView
            flexDirection="column-reverse"
            margins="0px 20px 0px 20px"
            minWidth="60px"
            backgroundColor="transparent"
            icon={dayIcon}
            iconSize="32px"
            iconColor={colors.primaryText}
            text={t('afternoon')}
            fontSize="12px"
            textAlign="center"
          />

          <TextIconView
            flexDirection="column-reverse"
            minWidth="60px"
            backgroundColor="transparent"
            icon={morningIcon}
            iconSize="32px"
            iconColor={colors.primaryText}
            text={t('morning')}
            fontSize="12px"
            textAlign="center"
          />
        </View>

        {availability.map(({ id, morning, afternoon, evening }, index) => (
          <TextView
            key={id}
            margins="0px 20px 0px 20px"
            paddings="5px 0px 5px 0px"
            borderBottomWidth={index < availability.length - 1 ? '1px' : 0}
            textFlex={1}
            text={t(`weekDays${index}`)}>
            <IconButton
              disabled={!angelAvailability}
              width="60px"
              iconName={morning ? 'btn-check' : 'btn-plus'}
              iconSize="32px"
              iconColor={morning ? colors.primary : colors.defaultBtnBackgroundColor}
              onPress={() => {
                const angelAvailabilityCopy = [...angelAvailability];
                angelAvailabilityCopy[index].morning = !morning;
                setAngelAvailability(angelAvailabilityCopy);
              }}
            />

            <IconButton
              disabled={!angelAvailability}
              margins="0px 20px 0px 20px"
              width="60px"
              iconName={afternoon ? 'btn-check' : 'btn-plus'}
              iconSize="32px"
              iconColor={afternoon ? colors.primary : colors.defaultBtnBackgroundColor}
              onPress={() => {
                const angelAvailabilityCopy = [...angelAvailability];
                angelAvailabilityCopy[index].afternoon = !afternoon;
                setAngelAvailability(angelAvailabilityCopy);
              }}
            />

            <IconButton
              disabled={!angelAvailability}
              width="60px"
              iconName={evening ? 'btn-check' : 'btn-plus'}
              iconSize="32px"
              iconColor={evening ? colors.primary : colors.defaultBtnBackgroundColor}
              onPress={() => {
                const angelAvailabilityCopy = [...angelAvailability];
                angelAvailabilityCopy[index].evening = !evening;
                setAngelAvailability(angelAvailabilityCopy);
              }}
            />
          </TextView>
        ))}
      </Content>

      <View paddings="10px 20px 10px 20px" margins="0px" borderTopWidth="1px">
        <TextButton
          width="50%"
          backgroundColor={colors.primary}
          text={t('next')}
          onPress={() => {
            const newProgress = progress + 7 >= 100 ? 100 : progress + 7;
            saveAvailabilityData(angelAvailability);
            if (newProgress === 100 && progress < 100) {
              setProgress(100);
              if (navigation) {
                navigation.popToTop();
              } else {
                history.goBack();
              }
            } else if (navigation) {
              navigation.navigate('SignUpAngelStep10', { progress: newProgress });
            } else {
              history.push({
                pathname: '/signup-angel-video',
                state: { progress: newProgress }
              });
            }
          }}
        />
      </View>
    </SafeAreaView>
  );
};

Availability.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      progress: PropTypes.number
    })
  }),
  history: PropTypes.shape({ push: PropTypes.func, goBack: PropTypes.func }),
  route: PropTypes.shape({
    params: PropTypes.shape({
      progress: PropTypes.number
    })
  }),
  navigation: PropTypes.shape({
    setOptions: PropTypes.func,
    popToTop: PropTypes.func,
    navigate: PropTypes.func
  }),
  availability: PropTypes.arrayOf(PropTypes.shape({})),
  getAvailabilityData: PropTypes.func.isRequired,
  saveAvailabilityData: PropTypes.func.isRequired,
  setProgress: PropTypes.func.isRequired
};

Availability.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null,
  availability: []
};

const mapStateToProps = ({ AngelPreferences }) => ({
  availability: AngelPreferences.availability
});

const mapDispatchToProps = {
  getAvailabilityData: getAvailability,
  saveAvailabilityData: saveAvailability,
  setProgress: setOnboardingProgress
};

export default connect(mapStateToProps, mapDispatchToProps)(Availability);
