import React from 'react'
import { formatDate } from '@charlycares/shared'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Heading, Text } from 'native-base'
import { Card } from '@charlycares/ui'

type Props = {
  startsAt: string
  endsAt: string
  withTitle?: boolean
}

const SingleBookingDates = ({ startsAt, endsAt, withTitle = true }: Props) => {
  const { t } = useTranslation()

  return (
    <Card>
      {withTitle && (
        <Heading fontSize="22px" mb="10px">
          {t('when')}
        </Heading>
      )}
      <Box pb="10px" flexDir="row" justifyContent="space-between" alignItems="center">
        <Text fontSize="16px" fontWeight="600">
          {t('start')}
        </Text>
        <Text color="gray.800" fontSize="16px">
          {formatDate(startsAt, { nl: 'ddd D MMMM HH:mm', en: 'ddd, MMMM D HH:mm' })}
        </Text>
      </Box>
      <Divider />
      <Box pt="10px" flexDir="row" justifyContent="space-between" alignItems="center">
        <Text fontSize="16px" fontWeight="600">
          {t('end')}
        </Text>
        <Text color="gray.800" fontSize="16px">
          {formatDate(endsAt, { nl: 'ddd D MMMM HH:mm', en: 'ddd, MMMM D HH:mm' })}
        </Text>
      </Box>
    </Card>
  )
}

export default SingleBookingDates
