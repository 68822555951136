import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { Linking } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import { getLatestAngelReviews, getAngelDashboard } from '../../stores/actions/dashboardAction'
import { setAnnouncementRead } from '../../stores/actions/userAction'
import {
  AppFeatures,
  Stories,
  CharlysShop,
  RegularBabysitter,
  AngelScores,
  Faq,
  ParentReviews,
  Announcements
} from '../../components'

import headerBackground from '../../../assets/images/img-angel-header-discover.png'
import { InviteReferrals } from '@charlycares/features'
import { Box } from 'native-base'

const SafeAreaView = styled.SafeAreaView`
  flex: 1 1 auto;
`
const ScrollView = styled.ScrollView`
  flex: 1 1 auto;
`
const Image = styled.Image`
  width: 100%;
  height: 200px;
`

const Angel = ({
  navigation,
  user,
  getDashboard,
  getLatestReviews,
  dashboard,
  reviews,
  onAnnouncementsRead,
  history
}) => {
  const { image, angel, has_unread_announcements, announcements, locale } = user
  const { average_rating } = angel
  const localeLink = locale === 'en_GB' ? 'en/' : ''

  const [t] = useTranslation()
  const modalRef = useRef(null)

  useEffect(() => {
    getDashboard()
    getLatestReviews('angel') // or user.locale as param? TODO
  }, [])

  useEffect(() => {
    if (modalRef && modalRef.current) {
      modalRef.current.setIsVisible(has_unread_announcements)
    }
  }, [has_unread_announcements])

  return (
    <SafeAreaView>
      <ScrollView showsVerticalScrollIndicator={false}>
        <Image source={headerBackground} />

        {dashboard && <AngelScores image={image} dashboard={dashboard} averageRating={average_rating?.toFixed(1)} />}

        <AppFeatures isAngel locale={localeLink} onFeaturePress={uri => navigation.navigate('WebView', { uri })} />

        <Box m="20px">
          <InviteReferrals />
        </Box>

        <Stories
          isAngel
          onReadMorePress={() => {
            if (navigation) {
              navigation.navigate('WebView', {
                uri: t('dashboardAngelStoryUrl')
              })
            } else {
              Linking.openURL(t('dashboardAngelStoryUrl'))
            }
          }}
        />

        <CharlysShop onGoToShopPress={() => navigation.navigate('ShopOverview')} isAngel />

        <RegularBabysitter
          isAngel
          locale={localeLink}
          onReadMorePress={() => {
            if (navigation) {
              navigation.navigate('WebView', {
                uri: t('dashboardRegularSitterAngelUrl')
              })
            } else {
              Linking.openURL(t('dashboardRegularSitterAngelUrl'))
            }
          }}
        />

        <Faq
          isAngel
          locale={localeLink}
          onQuestionsPress={uri => {
            if (navigation) {
              navigation.navigate('WebView', { uri })
            } else {
              Linking.openURL(uri)
            }
          }}
          onMoreQuestionsPress={() => {
            Linking.openURL('https://support.charlycares.com')
          }}
        />

        <ParentReviews isAngel reviews={reviews.slice(0, 3)} />
      </ScrollView>

      {has_unread_announcements && (
        <Announcements
          ref={modalRef}
          history={history}
          announcements={announcements}
          onClose={redirect => {
            onAnnouncementsRead()
            if (redirect) {
              history.push(redirect)
            }
          }}
        />
      )}
    </SafeAreaView>
  )
}

Angel.propTypes = {
  navigation: PropTypes.shape({ navigate: PropTypes.func, addListener: PropTypes.func }),
  history: PropTypes.shape({ push: PropTypes.func }),
  dashboard: PropTypes.shape({}),
  reviews: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({
    locale: PropTypes.string,
    first_name: PropTypes.string,
    image: PropTypes.string,
    angel: PropTypes.shape({ average_rating: PropTypes.number }),
    has_unread_announcements: PropTypes.bool,
    announcements: PropTypes.shape({})
  }),
  getDashboard: PropTypes.func.isRequired,
  getLatestReviews: PropTypes.func.isRequired,
  onAnnouncementsRead: PropTypes.func.isRequired
}

Angel.defaultProps = {
  navigation: null,
  history: null,
  dashboard: null,
  reviews: [],
  user: null
}

function mapStateToProps(state) {
  return {
    dashboard: state.Dashboard.angel,
    reviews: state.Dashboard.reviews,
    user: state.User.user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getDashboard: getAngelDashboard,
      getLatestReviews: getLatestAngelReviews,
      onAnnouncementsRead: setAnnouncementRead
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Angel)
