import styled from 'styled-components/native';
import colors from '../styles/colors';

const Circle = styled.View`
  position: ${({ position }) => position || 'relative'};
  ${({ bottom }) => bottom && { bottom }};
  ${({ right }) => right && { right }};
  margin: ${({ margins }) => margins || '0px'};
  width: ${({ size }) => size || '12px'};
  height: ${({ size }) => size || '12px'};
  align-self: ${({ alignSelf }) => alignSelf || 'auto'};
  border-radius: ${({ size }) => size || '12px'};
  border-width: ${({ borderWidth }) => borderWidth || '3px'};
  border-color: ${({ borderColor }) => borderColor || colors.orange};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
`;

export default Circle;
