import React from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator } from 'react-native';
import styled from 'styled-components/native';

import colors from '../styles/colors';

const TouchableOpacity = styled.TouchableOpacity`
  ${({ flex }) => flex && { flex }};
  ${({ zIndex }) => zIndex && { 'z-index': zIndex }}
  position: ${({ position }) => position};
  ${({ top }) => top !== null && { top }};
  ${({ bottom }) => bottom !== null && { bottom }};
  ${({ right }) => right !== null && { right }};
  ${({ left }) => left !== null && { left }};
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  height: ${({ height }) => height};
  padding: ${({ paddings }) => paddings};
  margin: ${({ margins }) => margins};
  flex-direction: ${({ flexDirection }) => flexDirection};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-self: ${({ alignSelf }) => alignSelf};
  ${({ transform }) => transform && { transform }};
  ${({ opacity }) => opacity && { opacity }};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-top-width: ${({ borderTopWidth }) => borderTopWidth};
  border-bottom-width: ${({ borderBottomWidth }) => borderBottomWidth};
  border-right-width: ${({ borderRightWidth }) => borderRightWidth};
  border-left-width: ${({ borderLeftWidth }) => borderLeftWidth};
  border-width: ${({ borderWidth }) => borderWidth};
  border-radius: ${({ borderRadius }) => borderRadius};
  border-color: ${({ backgroundColor, borderColor }) =>
    borderColor ||
    (backgroundColor !== 'transparent' ? 'transparent' : colors.white)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

const Button = React.forwardRef((props, ref) => {
  const {
    flex,
    zIndex,
    position,
    top,
    bottom,
    right,
    left,
    width,
    maxWidth,
    height,
    paddings,
    margins,
    flexDirection,
    alignItems,
    justifyContent,
    alignSelf,
    transform,
    opacity,
    borderWidth,
    borderTopWidth,
    borderBottomWidth,
    borderRightWidth,
    borderLeftWidth,
    borderRadius,
    borderColor,
    backgroundColor,
    disabled,
    onPress,
    onPressIn,
    children,
    loading,
    ...restButtonProps
  } = props;

  return (
    <TouchableOpacity
      ref={ref}
      accessibilityRole="button"
      flex={flex}
      zIndex={zIndex}
      position={position}
      top={top}
      bottom={bottom}
      right={right}
      left={left}
      width={width}
      maxWidth={maxWidth}
      height={height}
      paddings={paddings}
      margins={margins}
      flexDirection={flexDirection}
      alignItems={alignItems}
      justifyContent={justifyContent}
      alignSelf={alignSelf}
      transform={transform}
      opacity={opacity}
      borderWidth={borderWidth}
      borderTopWidth={borderTopWidth}
      borderBottomWidth={borderBottomWidth}
      borderRightWidth={borderRightWidth}
      borderLeftWidth={borderLeftWidth}
      borderRadius={borderRadius}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      disabled={disabled}
      onPress={onPress}
      onPressIn={onPressIn}
      {...restButtonProps}
    >
      {loading ? <ActivityIndicator color={colors.white} /> : children}
    </TouchableOpacity>
  );
});

Button.propTypes = {
  flex: PropTypes.number,
  zIndex: PropTypes.number,
  position: PropTypes.string,
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  paddings: PropTypes.string,
  margins: PropTypes.string,
  flexDirection: PropTypes.string,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  alignSelf: PropTypes.string,
  transform: PropTypes.string,
  opacity: PropTypes.number,
  borderWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderTopWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderBottomWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderRightWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderLeftWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  disabled: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
  onPressIn: PropTypes.func,
  children: PropTypes.node,
  loading: PropTypes.bool,
};

Button.defaultProps = {
  flex: null,
  zIndex: null,
  position: 'relative',
  top: null,
  bottom: null,
  right: null,
  left: null,
  width: 'auto',
  maxWidth: null,
  height: '38px',
  paddings: '0px',
  margins: '0px',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'auto',
  transform: null,
  opacity: null,
  borderWidth: '1px',
  borderTopWidth: '1px',
  borderRightWidth: 0,
  borderLeftWidth: 0,
  borderBottomWidth: '1px',
  borderRadius: '19px',
  borderColor: null,
  backgroundColor: 'transparent',
  disabled: false,
  onPressIn: () => {},
  children: null,
  loading: false,
};

export default Button;
