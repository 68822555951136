import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Center, Divider, Heading, Pressable, Text, useTheme } from 'native-base'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { Card, CharlyIcon, ScreenLayout } from '@charlycares/ui'
import { SearchBookingsDates, formatDate, useRouter } from '@charlycares/shared'
import { updateBookingDates, useAppSelector } from '@charlycares/data-access'

const RepeatingDaysScreen = () => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { goBack, getParam } = useRouter()
  const dispatch = useDispatch()

  const bookingIndex = getParam('bookingIndex', 0) as number

  const { startDate, endDate, repeatDates } = useAppSelector(state => state.booking.search.selectedDates[bookingIndex])

  const [dates, setDates] = useState<SearchBookingsDates[]>([])
  const [selectedDates, setSelectedDates] = useState<SearchBookingsDates[]>(repeatDates || [])
  const [showAllDates, setShowAllDates] = useState(selectedDates.length > 4)

  const generateWeeks = useCallback(
    (weeks: number) => {
      const displayedDates = []
      if (startDate && endDate) {
        const startDateMoment = moment(startDate)
        const endDateMoment = moment(endDate)

        for (let i = 0; i < weeks; i += 1) {
          startDateMoment.add(7, 'days')
          endDateMoment.add(7, 'days')
          displayedDates.push({
            startDate: startDateMoment.format('YYYY-MM-DD HH:mm:ss'),
            endDate: endDateMoment.format('YYYY-MM-DD HH:mm:ss')
          })
        }
      }

      setDates(displayedDates)
    },
    [startDate, endDate]
  )

  useEffect(() => {
    generateWeeks(showAllDates ? 12 : 4)
  }, [generateWeeks, showAllDates])

  const toggleDate = (date: SearchBookingsDates) => {
    const index = selectedDates.findIndex((item: SearchBookingsDates) =>
      moment(item.startDate).isSame(moment(date.startDate))
    )
    if (index === -1) {
      setSelectedDates(state => [...state, date])
    } else {
      setSelectedDates(state => state.filter((_, i) => index !== i))
    }
  }

  const toggleAll = () => {
    if (selectedDates.length === dates.length) {
      setSelectedDates([])
    } else {
      setSelectedDates(dates)
    }
  }

  const onSave = () => {
    dispatch(updateBookingDates({ index: bookingIndex, dates: { startDate, endDate, repeatDates: selectedDates } }))
    goBack()
  }

  return (
    <ScreenLayout
      supportButton
      title={formatDate(startDate, 'dddd')}
      subtitle={`${formatDate(startDate, 'HH:mm')} - ${formatDate(endDate, 'HH:mm')}`}
      _buttonProps={{
        children: t('bookScreensRepeatDaysButtonReady'),
        onPress: onSave
      }}
    >
      <Card>
        <Center mb="15px" w="100%">
          <Heading fontWeight={600} fontSize="18px">
            {`${selectedDates.length} of ${dates.length}`}
          </Heading>

          <Button onPress={toggleAll} position="absolute" right={0} variant="text">
            {t(selectedDates.length === dates.length ? 'deselectAll' : 'selectAll')}
          </Button>
        </Center>

        <Divider background="gray.200" />

        {dates.slice(0, showAllDates ? dates.length : 4).map(date => {
          const isSelected = !!selectedDates.find(item => moment(item.startDate).isSame(moment(date.startDate)))

          return (
            <Box
              key={date.startDate.toString()}
              w="100%"
              py="10px"
              flexDir="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text fontSize="15px">{formatDate(date.startDate, 'MMMM D')}</Text>
              <CharlyIcon
                onPress={() => toggleDate(date)}
                name={isSelected ? 'btn-check' : 'btn-plus'}
                size={30}
                color={isSelected ? colors.secondary[400] : colors.gray[500]}
              />
            </Box>
          )
        })}

        <Pressable w="100%" bg="gray.100" p="15px" onPress={() => setShowAllDates(state => !state)}>
          <Box flexDir="row" alignItems="center">
            <CharlyIcon name="warning" color={colors.alert.danger[300]} size={20} />
            <Heading fontSize="15px" fontWeight={600} ml="15px" flex={1}>
              {t('addMoreRepeatingDatesTitle')}
            </Heading>

            <CharlyIcon name={`icn-caret-${showAllDates ? 'up' : 'down'}`} size={30} />
          </Box>

          <Text mt="15px" fontSize="14px">
            {t('addMoreRepeatingDatesDesc')}
          </Text>
        </Pressable>
      </Card>
    </ScreenLayout>
  )
}

export default RepeatingDaysScreen

RepeatingDaysScreen.navigationOptions = {
  headerShown: false
}
