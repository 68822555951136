import React, { useEffect, useState } from 'react'
import { Card, ScreenLayout } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import Wallets from './common/Wallets'
import {
  useGetLandingScreensQuery,
  useGetLegacyPaymentMutation,
  useGetTransactionsQuery,
  useGetUserQuery,
  useGetWalletsQuery
} from '@charlycares/data-access'
import { Center, Heading, Spinner, Text } from 'native-base'
import { FlatList } from 'react-native'
import PaymentCard from './common/PaymentCard'
import {
  colors,
  contactPaginatedData,
  isDev,
  MixedTransaction,
  MixedTransactionType,
  useRouter,
  UserRoles
} from '@charlycares/shared'
import OutstandingPayments from './common/OutstandingPayments'
import { withNavigationFocus } from 'react-navigation'
import TransactionsOverviewDownload from './common/TransactionsOverviewDownload'

const PaymentListScreen = ({ isFocused }: { isFocused: boolean }) => {
  const { navigate } = useRouter()
  const { t } = useTranslation()
  const [required, setRequired] = useState(!isDev)
  const [page, setPage] = useState(1)
  const [transactions, setTransactions] = useState<MixedTransaction[]>([])
  const { data: user, refetch: refetchUser } = useGetUserQuery()
  const { data: wallets, refetch: refetchWallets } = useGetWalletsQuery()
  const {
    data: transactionResult,
    refetch: refetchTransactions,
    isFetching,
    isSuccess,
    isLoading
  } = useGetTransactionsQuery({ page: page })
  const [getLegacyPayment] = useGetLegacyPaymentMutation()
  const { data: landingScreen, refetch: refetchLandingScreen } = useGetLandingScreensQuery()
  const role = user?.role ?? UserRoles.FAMILY

  const refetch = () => {
    refetchWallets()
    refetchTransactions()
    refetchUser()
    refetchLandingScreen()
  }

  useEffect(() => {
    if (isSuccess && !isFetching && transactionResult) {
      setTransactions(state => contactPaginatedData(state, transactionResult))
    }
  }, [transactionResult, isFetching, isSuccess])

  useEffect(() => {
    if (landingScreen && landingScreen.current_screen.screen_name !== 'PaymentListScreen') {
      setRequired(false)
    }
  }, [landingScreen])

  useEffect(() => {
    if (isFocused && !isLoading) {
      refetch()
    }
  }, [isFocused, isLoading])

  const renderMessage = (title: string, message: string) => {
    return (
      <Card borderWidth={1} mt="0" mb="10px" minHeight="300">
        <Center w="100%">
          <Heading fontWeight="600" fontSize="15px" textAlign="center">
            {title}
          </Heading>
          <Text fontSize="14px" mt="7px" textAlign="center">
            {message}
          </Text>
        </Center>
      </Card>
    )
  }

  const getLegacyPaymentDetails = (transaction: MixedTransaction) => {
    getLegacyPayment({ payment_id: transaction.id })
      .unwrap()
      .then(res => {
        navigate('PaymentsDetails', 'payment-details', { paymentDetails: res, role: role, user: user })
      })
  }

  const navigateToCorrectScreen = (transaction: MixedTransaction) => {
    switch (transaction.type) {
      case MixedTransactionType.LEGACY_PAYMENT:
        getLegacyPaymentDetails(transaction)
        break
      case MixedTransactionType.BOOKING:
        navigate('BookingPaymentScreen', 'booking-payment-screen', {
          bookingId: transaction.subject_id,
          useBusiness: false,
          status: transaction.status,
          transactionUuid: transaction.id
        })
        break
      case MixedTransactionType.BUSINESS_BOOKING:
        navigate('BookingPaymentScreen', 'booking-payment-screen', {
          bookingId: transaction.subject_id,
          useBusiness: true,
          status: transaction.status,
          transactionUuid: transaction.id
        })
        break
      case MixedTransactionType.CANCELLED_BOOKING:
        navigate('CancelledBookingPaymentScreen', 'cancelled-booking-payment-screen', {
          bookingId: transaction.subject_id,
          status: transaction.status,
          transactionUuid: transaction.id
        })
        break
      case MixedTransactionType.MEMBERSHIP:
        navigate('MembershipPaymentScreen', 'membership-payment-screen', {
          transactionUuid: transaction.id
        })
        break
    }
  }

  return (
    <ScreenLayout edges={['left', 'right']} disableScroll hideBackButton={required} supportButton title={t('payment')}>
      <FlatList
        style={{ borderTopWidth: 1, borderColor: colors.grey08 }}
        data={transactions}
        keyExtractor={item => item.id}
        refreshing={isFetching}
        onRefresh={() => {
          setPage(1)
          refetch()
        }}
        onEndReachedThreshold={0.5}
        onEndReached={() => {
          if (transactionResult?.meta.last_page && page < transactionResult?.meta.last_page && !isFetching) {
            setPage(page + 1)
          }
        }}
        renderItem={({ item }) => (
          <PaymentCard transaction={item} onPress={transaction => navigateToCorrectScreen(transaction)} />
        )}
        ListEmptyComponent={() => renderMessage(t('paymentListScreen.emptyTitle'), t('paymentListScreen.emptyMessage'))}
        ListHeaderComponent={() => (
          <>
            {user && <OutstandingPayments user={user} />}
            <Card marginBottom="10px" pb="12px">
              <Wallets wallets={wallets} />
              {transactions?.length > 0 && <TransactionsOverviewDownload />}
            </Card>
          </>
        )}
        ListFooterComponent={() =>
          (page !== 1 && page !== transactionResult?.meta.last_page) || isFetching ? <Spinner mt="10px" /> : null
        }
      />
    </ScreenLayout>
  )
}

const screen = withNavigationFocus(PaymentListScreen)

export default screen

// @ts-ignore
screen.navigationOptions = {
  headerShown: false,
  gestureEnabled: false,
  gesturesEnabled: false,
  swipeEnabled: false
}
