import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { Range } from 'rc-slider';
import { TextTextView } from '../Texts';
import colors from '../../styles/colors';

const View = styled.View`
  padding: ${({ paddings }) => paddings};
`;

const RangeWrapper = styled.View`
  width: 70%;
  margin: auto;
`;

const MultiRangeSlider = ({
  paddings,
  minValue,
  maxValue,
  values,
  initialMin,
  initialMax,
  leftText,
  rightText,
  sliderRef,
  onValueChanged,
  rangeEnabled,
  step,
  firstTextFontFamily,
  firstTextFontSize,
  secondTextFontFamily,
  secondTextFontSize,
  secondTextFontWeight
}) => (
  <View paddings={paddings}>
    <TextTextView
      flexDirection="row"
      justifyContent="space-between"
      alignItems="stretch"
      paddings="0px"
      width="100%"
      firstText={leftText}
      firstTextFontFamily={firstTextFontFamily}
      firstTextFontSize={firstTextFontSize}
      secondText={rightText}
      secondTextFontFamily={secondTextFontFamily}
      secondTextFontSize={secondTextFontSize}
      secondTextFontWeight={secondTextFontWeight}
    />

    <RangeWrapper>
      <Range
        ref={sliderRef}
        lineWidth={8}
        gravity="center"
        thumbRadius={15}
        thumbBorderWidth={1}
        thumbBorderColor={colors.grey08}
        labelStyle="none"
        step={step}
        min={minValue}
        max={maxValue}
        value={values}
        defaultValue={[initialMin, initialMax]}
        selectionColor={colors.primary}
        blankColor={colors.grey08}
        onChange={onValueChanged}
        rangeEnabled={rangeEnabled}
      />
    </RangeWrapper>
  </View>
);

MultiRangeSlider.propTypes = {
  paddings: PropTypes.string,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  initialMin: PropTypes.number.isRequired,
  initialMax: PropTypes.number.isRequired,
  leftText: PropTypes.string.isRequired,
  rightText: PropTypes.string.isRequired,
  sliderRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape, PropTypes.node]),
  onValueChanged: PropTypes.func.isRequired,
  rangeEnabled: PropTypes.bool,
  step: PropTypes.number,
  firstTextFontFamily: PropTypes.string,
  secondTextFontFamily: PropTypes.string,
  firstTextFontSize: PropTypes.number,
  secondTextFontSize: PropTypes.number,
  secondTextFontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

MultiRangeSlider.defaultProps = {
  paddings: '20px 0px 0px 0px',
  sliderRef: null,
  rangeEnabled: true,
  firstTextFontFamily: 'Open Sans',
  firstTextFontSize: '16px',
  secondTextFontFamily: 'Open Sans',
  secondTextFontSize: '16px',
  step: 1,
  secondTextFontWeight: 'normal'
};

export default MultiRangeSlider;
