import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { TextView } from '../Texts';
import { Circle } from '../../elements';
import { getFormatedDate } from '../../utils/functions';
import colors from '../../styles/colors';

const TouchableOpacity = styled.TouchableOpacity`
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  border-bottom-width: 1px;
  border-color: ${colors.grey08};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const JobCard = ({ bookingDates, repeatQty, family, onCardPress, bookings }) => {
  const bookingDays = bookings
    .map((booking) => getFormatedDate(booking.start_date, 'dddd'))
    .join(', ');
  const startEndTime =
    repeatQty > 1
      ? ''
      : `${getFormatedDate(bookingDates[0].start_date, 'HH:mm')} - ${getFormatedDate(
          bookingDates[0].end_date,
          'HH:mm'
        )}`;
  const startDate = getFormatedDate(bookingDates[0].start_date, 'LL');

  return (
    <TouchableOpacity onPress={onCardPress} backgroundColor={colors.yellowAlpha20}>
      <TextView
        flexDirection="row-reverse"
        justifyContent="space-between"
        text={startEndTime}
        fontFamily="Martel">
        <TextView
          flexDirection="row-reverse"
          text={bookingDays}
          fontFamily="Martel"
          color={colors.primaryText}>
          {repeatQty > 1 && (
            <TextView
              justifyContent="center"
              alignItems="center"
              margins="0px 5px 0px 0px"
              height="16px"
              width="24px"
              borderRadius="8px"
              backgroundColor={colors.grey05}
              text={`${repeatQty}x`}
              fontSize="12px"
              color={colors.primaryText}
            />
          )}
        </TextView>
      </TextView>

      <TextView
        margins="10px 0px 10px 0px"
        justifyContent="space-between"
        text={startDate}
        fontSize="13px"
        fontWeight="300"
        color={colors.primaryText}>
        <TextView
          justifyContent="flex-end"
          text="pending"
          textMargins="0px 5px 0px 0px"
          fontSize="12px"
          textColor={colors.primaryText}>
          <Circle backgroundColor={colors.orange} borderColor={colors.orange} />
        </TextView>
      </TextView>

      <TextView justifyContent="space-between" text={family} />
    </TouchableOpacity>
  );
};

JobCard.propTypes = {
  bookingDates: PropTypes.arrayOf(
    PropTypes.shape({ start_date: PropTypes.string, end_date: PropTypes.string })
  ).isRequired,
  bookings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  repeatQty: PropTypes.number.isRequired,
  family: PropTypes.string.isRequired,
  onCardPress: PropTypes.func.isRequired
};

export default JobCard;
