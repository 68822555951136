import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProtectedRoute = ({ component: Component, user, ...rest }) => {
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user) {
          return <Component {...rest} {...props} />;
        }
        return <Redirect exact to={{ pathname: '/', state: location }} />;
      }}
    />
  );
};
ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  user: PropTypes.shape({})
};

ProtectedRoute.defaultProps = {
  user: null
};

export default ProtectedRoute;
