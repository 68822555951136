import {
  countJobboardFilters,
  updateJobboardFilters,
  setDefaultJobboardFilters,
  useGetUserQuery
} from '@charlycares/data-access'
import { JobboardFilters, StorageKeys } from '@charlycares/shared'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const useLoadJobboardFilters = () => {
  const dispatch = useDispatch()
  const { data: user } = useGetUserQuery()

  const [isLoading, setIsLoading] = useState(true)

  const loadUserFilters = useCallback(async () => {
    try {
      // Get saved user filters
      const data = await AsyncStorage.getItem(`${StorageKeys.JOBBOARD_FILTERS}_${user?.id}`)

      if (!data) throw new Error('no saved filters')

      const savedFilters: JobboardFilters = JSON.parse(data)
      dispatch(updateJobboardFilters(savedFilters))
      user && dispatch(countJobboardFilters(user))
    } catch (error) {
      // Set default angel filters by there profile preferences
      if (user) {
        dispatch(setDefaultJobboardFilters(user))
      }
    } finally {
      setIsLoading(false)
    }
  }, [user, dispatch])

  useEffect(() => {
    loadUserFilters()
  }, [loadUserFilters])

  return { isLoading }
}

export default useLoadJobboardFilters
