export default {
  primaryColor: '#0B5394',
  lightPrimaryColor: '#68AEBF80',
  lighterPrimaryColor: '#DEEDF1',
  secondaryColor: '#F56B87',
  lightSecondaryColor: '#F1D4DC',
  primaryText: '#303036',
  primaryTextBackground: '#30303680',
  primaryDark: '#303036',
  secondaryText: '#fff',
  defaultBtnTextColor: '#97979a',
  grey: '#97979a',
  darkGray: '#C7C7C9',
  orange: '#FFBA59',
  green: '#33D69D',
  yellow: '#FFFD8D',
  lighterGreen: '#C1F2E1',
  pendingYellow: '#FFBA59',
  acceptedGreen: '#51c19a',
  lightGreen: '#DFF1D4',
  lightGrey: '#68686E',
  ligthterGrey: '#A9A9AC',
  defaultBtnBackgroundColor: '#D9D9D9',
  defaultGrey: '#e6e6e6',
  accentText: '#F56B87',
  warning: '#F50B3A',
  errorColor: '#e2574c',
  white: '#fff',
  lightestGrey: '#DEDEDE',
  lightOrange: 'rgba(255, 186, 89, 0.5)',
  backgroundColor: '#f9f8f9',
  darkBackgroundColor: '#d8d8d84d',
  ligthYellow: 'rgba(255, 253, 141, 0.2)',
  transparentBlack: '00000099',
  transparentWhite: 'rgba(255, 255, 255, 0.6)',
  greyBackground: '#F2F2F2',
  darkerGray: '#636366',
  blue: '#4A8FE2'
}
