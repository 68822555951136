import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Text } from '../../elements';
import { TextView } from '../Texts';
import { TextButton, TextIconButton } from '../Buttons';
import { CountdownTimer } from '../Invitations';
import { AngelAvatar } from '../Profile';
import colors from '../../styles/colors';
import { Time } from '../Icons';

import rightArrowIcon from '../../../assets/icons/icn-nav-right.png';
import chatIcon from '../../../assets/icons/icn-chat.png';
import pendingIcon from '../../../assets/icons/icn-days-unknown.png';

const CardView = styled.View`
  padding: 20px 10px 20px 20px;
  border-bottom-width: 1px;
  border-color: ${colors.grey07};
`;
const ButtonsViews = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 20px 0px 0px;
`;
const IconCountdownTimerView = styled.View`
  flex-direction: row;
  align-items: center;
`;
const DaysCountdownView = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 30px 0px 5px;
`;
const MessageArrowView = styled.View`
  flex-direction: row;
  align-items: center;
`;

const getDescription = (t, currentState) => {
  switch (currentState) {
    case 'pending':
      return t('bookingsScreenAwaitingResponseFromAngel');
    case 'declined':
      return t('bookingsScreenAngelDeclined');
    case 'declined_family':
      return t('bookingsScreenFamilyDeclined');
    default:
      return '';
  }
};

const AngelInvitationCard = ({
  currentState,
  angelImage,
  angelName,
  angelAge,
  availabilityType,
  allowedChat,
  totalDays,
  acceptedDays,
  hasAcceptedAllDates,
  expiresAt,
  onMessagePress,
  onNavigateAngelProfile,
  onDeclinePress,
  onAcceptPress
}) => {
  const [t] = useTranslation();

  return (
    <CardView>
      <Button
        flexDirection="row"
        justifyContent="space-between"
        height="auto"
        onPress={onNavigateAngelProfile}>
        <TextView
          flexDirection="row-reverse"
          text={`${angelName} (${angelAge})`}
          textMargins="0px 0px 0px 10px"
          fontFamily="Martel"
          fontSize="16px"
          fontWeight="600">
          <AngelAvatar source={angelImage} size="52px" />
        </TextView>

        <MessageArrowView>
          {allowedChat && (
            <TextIconButton
              flexDirection="column-reverse"
              margins="0px 10px 0px 0px"
              paddings="0px"
              text={t('message')}
              fontSize="12px"
              textColor={colors.secondary}
              icon={chatIcon}
              iconColor={colors.secondary}
              iconSize="32px"
              onPress={onMessagePress}
            />
          )}

          <Icon
            iconSize="30px"
            color={colors.grey08}
            source={rightArrowIcon}
            onPress={onNavigateAngelProfile}
          />
        </MessageArrowView>

        {availabilityType === 'job' && (
          <TextView
            position="absolute"
            top={-10}
            left={60}
            paddings="0px 5px 0px 5px"
            text={t('bookingsScreenViaJobFinder')}
            fontSize="12px"
            fontWeight="300"
            backgroundColor={colors.grey09}
          />
        )}
      </Button>

      {['pending_approval'].includes(currentState) ? (
        <>
          <DaysCountdownView>
            <TextView
              flexDirection="row-reverse"
              text={currentState === 'pending_approval' ? ` / ${totalDays} ${t('days')}` : ''}>
              <TextView
                justifyContent="center"
                width="30px"
                height="25px"
                borderRadius="4px"
                alignItems="center"
                text={currentState === 'pending_approval' ? acceptedDays.toString() : 'X'}
                backgroundColor={colors.grey07}
              />
            </TextView>

            {currentState === 'expired' && (
              <Text fontSize="12px" textAlign="right">
                {t('bookingsScreenAngelExpired')}
              </Text>
            )}

            {currentState !== 'expired' && !currentState.includes('declined') && (
              <IconCountdownTimerView>
                <Time color={colors.grey03} />

                <CountdownTimer expiresAt={expiresAt} />
              </IconCountdownTimerView>
            )}
          </DaysCountdownView>

          {!currentState.includes('declined') && currentState !== 'expired' && (
            <ButtonsViews>
              <TextButton
                width="30%"
                text={t('decline')}
                textColor={colors.grey03}
                onPress={onDeclinePress}
              />

              <TextButton
                width="55%"
                text={
                  hasAcceptedAllDates || acceptedDays === 0
                    ? t('accept')
                    : t('bookingsScreenViewOffer')
                }
                fontWeight="600"
                fontSize="16px"
                backgroundColor={colors.primary}
                onPress={onAcceptPress}
              />
            </ButtonsViews>
          )}
        </>
      ) : (
        <TextView
          flexDirection="row-reverse"
          justifyContent="space-between"
          paddings="10px 0px 0px 20px"
          text={getDescription(t, currentState)}
          fontSize="12px">
          {currentState.includes('pending') && (
            <Icon source={pendingIcon} size="24px" useTintColor={false} />
          )}
        </TextView>
      )}
    </CardView>
  );
};

AngelInvitationCard.propTypes = {
  currentState: PropTypes.string.isRequired,
  angelImage: PropTypes.string,
  angelName: PropTypes.string.isRequired,
  angelAge: PropTypes.number.isRequired,
  allowedChat: PropTypes.bool,
  availabilityType: PropTypes.string.isRequired,
  totalDays: PropTypes.number.isRequired,
  acceptedDays: PropTypes.number.isRequired,
  hasAcceptedAllDates: PropTypes.bool.isRequired,
  expiresAt: PropTypes.string.isRequired,
  onMessagePress: PropTypes.func.isRequired,
  onNavigateAngelProfile: PropTypes.func.isRequired,
  onDeclinePress: PropTypes.func.isRequired,
  onAcceptPress: PropTypes.func.isRequired
};

AngelInvitationCard.defaultProps = {
  angelImage: null,
  allowedChat: false
};

export default AngelInvitationCard;
