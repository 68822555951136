import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';

import logoSource from '../../assets/images/logo2.png';

const Image = styled.Image`
  margin: ${({ margins }) => margins};
  width: ${({ width }) => width};
  max-width: 100%;
  height: ${({ height }) => height};
`;

const FullLogo = ({ margins, width, height }) => (
  <Image
    accessible
    accessibilityLabel="Charly Cares logo"
    accessibilityRole="image"
    margins={margins}
    width={width}
    height={height}
    source={logoSource}
    resizeMode="contain"
  />
);

FullLogo.propTypes = {
  margins: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

FullLogo.defaultProps = {
  margins: '0px 0px 0px 0px',
  width: '133px',
  height: '65px'
};

export default FullLogo;
