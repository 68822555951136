import { ServiceGroupType } from '@charlycares/shared'
import { ResponseData } from '../types'

export interface AngelReference {
  id: number
  name: string
  email: string
  association_type: string
  phone: string
}

export type AddAngelReferenceResponse = ResponseData<AngelReference>
export type AddAngelReferenceBody = {
  first_name: string
  last_name?: string
  association_type: string
  email?: string
  phone: string
}

export type AngelReferenceReviewResponse = ResponseData<any>
export type AngelReferenceReviewBody = {
  angelReferenceId: number
  angel_id: number
  review: string
  rating_categories: { [key: string]: number }
}

export enum ToDoType {
  PROFILE_VERIFIED = 'profile_verified',
  PROFILE_PHOTO = 'profile_photo',
  PHOTO_QUALITY_PASSED = 'photo_quality_passed',
  PROFILE_DETAILS = 'profile_details',
  SCREENING = 'screening',
  REFERENCES = 'references',
  BANK_AND_CITIZEN_SERVICE_NUMBER = 'bank_and_citizen_service_number',
  BANK_ACCOUNT = 'bank_account',
  ACCOUNT_DETAILS = 'account_details',
  PREFERENCES = 'preferences',
  PROMO_VIDEO = 'promo_video'
}

export type AngelTodo = {
  item: ToDoType
  completed: boolean
  pending: boolean
  additional_data: any
  service_group_type: ServiceGroupType
}

export type AngelReferenceReviewInfoResponse = {
  rating_categories: string[]
  user: {
    first_name: string
    image_url: string
  }
}
