import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'

import { TextButton, colors } from '@charlycares/legacy/web'
import { Slide } from '../../components'

const SafeAreaView = styled.SafeAreaView`
  flex: 1 1 auto;
  flex-direction: column;
  padding-top: 20px;
  overflow: hidden;
  min-height: 100dvh;
`

const Dot = styled.View`
  margin: 5px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: ${({ active }) => (active ? colors.secondary : colors.grey03)};
`

const View = styled.View`
  ${({ flexDirection }) => flexDirection && { 'flex-direction': flexDirection }};
  ${({ alignItems }) => alignItems && { 'align-items': alignItems }};
  ${({ justifyContent }) => justifyContent && { 'justify-content': justifyContent }};
  ${({ margins }) => margins && { margin: margins }};
`

const Onboarding = ({ history, location }) => {
  const [currentSlide, setCurrentSlide] = useState(1)
  const [t] = useTranslation()
  const { role } = (location && location.state) || {} // route && route.params && route.params.role;
  const isFamily = role === 'family'
  const numberOfSlides = isFamily ? 4 : 3

  useEffect(() => {
    const cycleInterval = setTimeout(() => {
      if (currentSlide < numberOfSlides) {
        setCurrentSlide(currentSlide + 1)
      }
    }, 6000)

    return () => clearTimeout(cycleInterval)
  }, [currentSlide])

  return (
    <SafeAreaView>
      <Slide
        videoSource={`https://charlycares-videos.s3.eu-central-1.amazonaws.com/onboarding-${
          isFamily ? 'parent' : 'angel'
        }-video-${currentSlide}.mp4`}
        header={t(`onboarding${role}Title${currentSlide}`)}
        description={t(`onboarding${role}Description${currentSlide}`)}
        lastSlide={numberOfSlides === currentSlide}
        navigateTo={() => history.push({ pathname: `/signup${role}` })}
      />
      <View flexDirection="row" justifyContent="center" margins="0px 0px 30px 0px">
        <View flexDirection="row" alignItems="center">
          {[...new Array(numberOfSlides).keys()].map(index => (
            <Dot key={String(index)} active={index + 1 === currentSlide} />
          ))}
        </View>
        {currentSlide < numberOfSlides && (
          <TextButton
            height="auto"
            text={t('skip')}
            textColor={colors.primaryText}
            onPress={() => history.push({ pathname: `/signup${role}` })}
          />
        )}
      </View>
    </SafeAreaView>
  )
}

Onboarding.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      role: PropTypes.string
    })
  }).isRequired
}

export default Onboarding
