import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { TextIconView } from '../Texts';
import { renderDistanceInKilometers, calculateBikeTime } from '../../utils/functions';
import colors from '../../styles/colors';

import distanceIcon from '../../../assets/icons/icn-location.png';
import childIcon from '../../../assets/icons/icn-kids.png';
import bikeIcon from '../../../assets/icons/icn-cycling.png';

const View = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
  margin-top: 5px;
`;

const InfoIcons = ({ distance, childCount, bookingChildCount, hasCat, hasDog }) => {
  const [t] = useTranslation();

  return (
    <View>
      <TextIconView
        minWidth="35px"
        iconSize="32px"
        margins="0px 20px 0px 0px"
        flexDirection="column-reverse"
        backgroundColor="transparent"
        text={renderDistanceInKilometers(distance)}
        fontSize="13px"
        icon={distanceIcon}
        iconColor="black"
      />

      <TextIconView
        minWidth="35px"
        iconSize="32px"
        margins="0px 20px 0px 0px"
        flexDirection="column-reverse"
        backgroundColor="transparent"
        text={calculateBikeTime(distance)}
        fontSize="13px"
        icon={bikeIcon}
        iconColor="black"
      />

      <TextIconView
        minWidth="35px"
        iconSize="32px"
        margins="0px 20px 0px 0px"
        flexDirection="column-reverse"
        backgroundColor="transparent"
        text={
          bookingChildCount
            ? t('childCount', { 0: bookingChildCount, 1: childCount })
            : String(childCount)
        }
        fontSize="13px"
        icon={childIcon}
        iconColor="black"
      />

      <TextIconView
        minWidth="35px"
        iconSize="32px"
        margins="0px 20px 0px 0px"
        flexDirection="column-reverse"
        backgroundColor="transparent"
        text={hasCat ? t('yes') : t('no')}
        fontSize="13px"
        name="cat"
        iconColor={hasCat ? colors.grey01 : colors.grey07}
        textColor={hasCat ? colors.primaryText : colors.grey07}
      />

      <TextIconView
        minWidth="35px"
        iconSize="32px"
        margins="0px 20px 0px 0px"
        flexDirection="column-reverse"
        backgroundColor="transparent"
        text={hasDog ? t('yes') : t('no')}
        fontSize="13px"
        name="dog"
        iconColor={hasCat ? colors.grey01 : colors.grey07}
        textColor={hasCat ? colors.primaryText : colors.grey07}
      />
    </View>
  );
};

InfoIcons.propTypes = {
  distance: PropTypes.number,
  hasDog: PropTypes.bool.isRequired,
  hasCat: PropTypes.bool.isRequired,
  childCount: PropTypes.number.isRequired,
  bookingChildCount: PropTypes.number
};

InfoIcons.defaultProps = {
  distance: 0,
  bookingChildCount: 0
};

export default InfoIcons;
