import { Money } from '@charlycares/data-access'

export enum TransactionType {
  MONEY = 'money',
  MINUTES = 'minutes'
}

export enum WalletType {
  PERSONAL = 'personal',
  COMPANY = 'company'
}

export interface Wallet {
  uuid: string
  transaction_type: TransactionType
  wallet_type: WalletType
  saldo?: Money
  time?: string
  company?: {
    name: string
    logo_url: string
  }
  valid_until: string
  active: boolean
  account_number: string
}
