import {
  FETCHING_BOOK,
  SEARCH_AVAILABLE_ANGELS,
  SEARCH_AVAILABLE_ANGELS_ERROR,
  TOGGLE_LIKE_BOOK_ANGEL,
  GET_BOOK_ANGEL_RATINGS,
  SEND_BOOK_REQUEST,
  SEND_BOOK_REQUEST_ERROR,
  ADD_ANGELS
} from '../types';

const initialState = {
  fetching: false,
  bookingDays: []
};

export default function (state = initialState, { type, payload }) {
  let angelsCopy = [];
  switch (type) {
    case SEARCH_AVAILABLE_ANGELS:
      return {
        ...payload,
        fetching: false
      };
    case ADD_ANGELS:
      return {
        ...payload,
        fetching: false
      };
    case SEARCH_AVAILABLE_ANGELS_ERROR:
      return {
        fetching: false,
        errorMessage: payload
      };
    case TOGGLE_LIKE_BOOK_ANGEL:
      angelsCopy = state.angels.map((angel) => {
        if (angel.id === payload.angelId) {
          const angelCopy = { ...angel };
          angelCopy.is_liked = payload.like ? 1 : 0;
          return angelCopy;
        }
        return angel;
      });
      return {
        ...state,
        angels: [...angelsCopy],
        fetching: false
      };
    case GET_BOOK_ANGEL_RATINGS:
      return {
        ...state,
        currentAngelRatings: payload,
        fetching: false
      };
    case SEND_BOOK_REQUEST:
      return {
        ...state,
        bookRequest: payload,
        bookRequestErrorMessage: null,
        fetching: false
      };
    case SEND_BOOK_REQUEST_ERROR:
      return {
        ...state,
        bookRequest: null,
        bookRequestErrorMessage: payload,
        fetching: false
      };
    case FETCHING_BOOK:
      return {
        ...state,
        fetching: true,
        angels: []
      };
    default:
      return state;
  }
}
