export enum Days {
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday
}

export type PaginatedResults<T> = {
  data: T[]
  meta: {
    from: number
    per_page: number
    current_page: number
    last_page: number
  }
}
