import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toggleLikeAngel } from '../../stores/actions/userAction';
import { getAngelRatingsFavorite } from '../../stores/actions/favoritesAction';
import { IconButton, TextButton, AngelDetails, Header } from '../../components';
import colors from '../../styles/colors';

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
`;
const HeaderRightView = styled.View`
  flex-direction: row;
`;
const ButtonsView = styled.View`
  position: absolute;
  bottom: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 40px 20px;
  border-top-width: 1px;
  border-color: ${colors.grey08};
  background-color: ${colors.backgroundColor};
`;

const ShareDetails = ({
  history,
  navigation,
  angel,
  getAngelRatingsData,
  toggleLikeAngelData,
}) => {
  const [numberOfCharacters, setNumberOfCharacters] = useState(120);
  const [t] = useTranslation();
  const scrollRef = useRef(null);
  const { video, ratings } = angel;

  if (navigation) {
    navigation.setOptions({
      headerRight: (
        <HeaderRightView>
          <IconButton
            disabled
            margins="0px 10px 0px 0px"
            iconName="icn-chat"
            iconSize="32px"
            iconColor={colors.grey03}
            onPress={() => {}}
          />

          <IconButton
            disabled
            margins="0px 10px 0px 0px"
            iconName="icn-telephone"
            iconSize="32px"
            iconColor={colors.grey03}
            onPress={() => {}}
          />

          <IconButton
            disabled
            margins="0px 10px 0px 0px"
            iconName="icn-share"
            iconSize="32px"
            iconColor={colors.grey03}
            onPress={() => {}}
          />

          <IconButton
            disabled
            margins="0px 10px 0px 0px"
            iconName="icn-calendar-add"
            iconSize="32px"
            iconColor={colors.grey03}
            onPress={() => {}}
          />

          <IconButton
            disabled
            iconSize="32px"
            margins="0px 10px 0px 0px"
            iconName="icn-calendar"
            iconColor={colors.grey03}
            onPress={() => {}}
          />
        </HeaderRightView>
      ),
      headerLeft: (
        <IconButton
          iconSize="32px"
          iconName="icn-nav-left"
          iconColor={colors.secondary}
          onPress={() => navigation.goBack()}
        />
      ),
    });
  }

  useEffect(() => {
    getAngelRatingsData(angel.id);
  }, []);

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          left={
            <IconButton
              iconSize="32px"
              iconName="icn-nav-left"
              iconColor={colors.secondary}
              onPress={history.goBack}
            />
          }
          right={
            <HeaderRightView>
              <IconButton
                disabled
                margins="0px 10px 0px 0px"
                iconName="icn-chat"
                iconSize="32px"
                iconColor={colors.grey03}
                onPress={() => {}}
              />

              <IconButton
                disabled
                margins="0px 10px 0px 0px"
                iconName="icn-telephone"
                iconSize="32px"
                iconColor={colors.grey03}
                onPress={() => {}}
              />

              <IconButton
                disabled
                margins="0px 10px 0px 0px"
                iconName="icn-share"
                iconSize="32px"
                iconColor={colors.grey03}
                onPress={() => {}}
              />

              <IconButton
                disabled
                margins="0px 10px 0px 0px"
                iconName=" icn-calendar-add"
                iconSize="32px"
                iconColor={colors.grey03}
                onPress={() => {}}
              />

              <IconButton
                disabled
                iconSize="32px"
                margins="0px 10px 0px 0px"
                iconName="icn-calendar"
                iconColor={colors.grey03}
                onPress={() => {}}
              />
            </HeaderRightView>
          }
        />
      )}
      <AngelDetails
        scrollRef={scrollRef}
        angel={{ ...angel, is_liked: true }}
        ratings={ratings}
        onVideoPress={() =>
          navigation.navigate('VideoPlayer', { source: video })
        }
        onCharacteristicsInfoPress={() =>
          navigation.navigate('BookSceeningInformation')
        }
        onSkillsInfoPress={() => navigation.navigate('BookExplanationFeatures')}
        onReviewsPress={() => scrollRef.scrollToEnd()}
        onReadMorePress={() => setNumberOfCharacters(numberOfCharacters + 1000)}
        numberOfCharacters={numberOfCharacters}
      />

      <ButtonsView>
        <TextButton
          flex={1}
          borderTopWidth={0}
          borderBottomWidth={0}
          text={t('favoritesScreensReject')}
          textColor={colors.defaultBtnBackgroundColor}
          onPress={() => navigation.navigate('Favorites')}
        />

        <TextButton
          flex={1}
          margins="0px 0px 0px 20px"
          backgroundColor={colors.primary}
          text={t('favoritesScreensAccept')}
          onPress={() => {
            toggleLikeAngelData(angel.id, true);
            navigation.navigate('Bookings');
          }}
        />
      </ButtonsView>
    </SafeAreaView>
  );
};

ShareDetails.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  navigation: PropTypes.shape({
    setOptions: PropTypes.func,
    navigate: PropTypes.func,
    goBack: PropTypes.func,
  }),
  angel: PropTypes.shape({
    id: PropTypes.number,
    video: PropTypes.string,
    ratings: PropTypes.shape({}),
  }).isRequired,
  toggleLikeAngelData: PropTypes.func.isRequired,
  getAngelRatingsData: PropTypes.func.isRequired,
};

ShareDetails.defaultProps = {
  history: null,
  navigation: null,
};

const mapStateToProps = ({ Favorites }) => ({
  angel: Favorites.angelDetails,
});

const mapDispatchToProps = {
  getAngelRatingsData: getAngelRatingsFavorite,
  toggleLikeAngelData: toggleLikeAngel,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareDetails);
