import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { Linking, Alert } from 'react-native'
import QRCode from 'qrcode.react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import { IconButton, Text, theme, logout } from '@charlycares/legacy/web'

import appstoreIcon from '../../../assets/icons/appstore_button.png'
import googlePlayIcon from '../../../assets/icons/google-play.png'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getUserToken } from '@charlycares/shared'
import { useEffect, useState } from 'react'
import { Button } from 'native-base'

export default function AngelSignupFinal({ location }) {
  const { first_name, preventLogin } = location.state || {}
  const [token, setToken] = useState()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { replace } = useHistory()

  const url = `https://charlycares.page.link/?link=https://app.charlycares.com/login?screen%3DLogin%26token%3D${token}&apn=nl.digitalchefs.charlycares&isi=1140986378&ibi=com.charlycares.charlycares`

  useEffect(() => {
    getUserToken().then(setToken)
  }, [])

  async function openApp() {
    const supported = await Linking.canOpenURL(url)

    if (supported) {
      Linking.openURL(url)
    } else {
      Alert.alert('Error', 'Can not open the app!')
    }
  }

  return (
    <Container>
      <Section>
        <Row margin="20px 0" justify="center">
          <IconButton disabled iconName="favorites" iconSize={60} iconColor={theme.colors.secondary} />
          <Text fontWeight={600} fontSize={18} margins="0 0 0 10px">
            {preventLogin ? t('angelWebLoginTitle') : t('youAreAlmostAngel')}
          </Text>
        </Row>

        <Text fontWeight={300} textAlign="center" fontSize={15} margins="20px 0">
          {preventLogin ? t('angelWebLoginDescription') : t('compleatProfileOnMobile')}
        </Text>

        {isMobile && (
          <>
            <Text fontWeight={600} fontSize={18} margins="20px 0" width="100%" textAlign="center">
              {t('downloadApp')}
            </Text>

            <Row justify="center" margin="20px 0">
              <IconButton
                onPress={openApp}
                iconSource={appstoreIcon}
                iconWidth={129}
                iconHeight={42}
                iconMargins="10px"
              />
              <IconButton
                onPress={openApp}
                iconSource={googlePlayIcon}
                iconWidth={129}
                iconHeight={42}
                iconMargins="10px"
              />
            </Row>
          </>
        )}

        {!isMobile && (
          <>
            <Text fontWeight={600} fontSize={18} margins="20px 0" width="100%" textAlign="center">
              {t('angelScanQrCode', { first_name })}
            </Text>
            <QRCode includeMargin value={url} size={200} />
          </>
        )}

        <Button
          mt="30px"
          type="text"
          onPress={() => {
            dispatch(logout())
            setTimeout(() => replace('/'), 1000)
          }}
        >
          {t('logOut')}
        </Button>
      </Section>
    </Container>
  )
}

const Container = styled.View`
  flex: 1;
  max-width: 1000px;
  margin: auto;
`

const Section = styled.View`
  width: 100%;
  padding: 15px;
  background-color: ${theme.colors.white};
  margin-bottom: 9px;
  justify-content: center;
  align-items: center;
`

const Row = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin: ${p => p.margin || 0};
  justify-content: ${p => p.justify || 'flex-start'};
  flex-wrap: wrap;
`

AngelSignupFinal.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({ step1Data: PropTypes.shape({}) })
  }).isRequired
}
