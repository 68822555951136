import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { TextView } from '../Texts';
import { TextButton } from '../Buttons';
import Video from '../Video';
import colors from '../../styles/colors';

const exampleVideo = 'https://s3.eu-central-1.amazonaws.com/charlycares-videos/Mijn+film.mp4';

const View = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 20px 20px 10px;
  margin: 0px 0px 0px 10px;
  width: 100%;
  height: 150px;
  border-left-width: ${({ valid }) => (valid ? 0 : '5px')};
  border-color: ${colors.secondary};
`;

const VideoEdit = ({
  validate,
  disabled,
  source,
  paused,
  onEditPress,
  onStartExampleVideoPress
}) => {
  const fileInputRef = useRef(null);
  const [t] = useTranslation();

  return (
    <View valid={!validate || source}>
      <Video width="40%" height="80%" fluid={false} src={source || exampleVideo} paused={paused} />

      <TextView
        flexDirection="column"
        alignItems="flex-start"
        width="50%"
        text={t('profileScreensPromovideo')}
        fontFamily="Martel"
        fontSize="16px"
        fontWeight="600">
        <TextButton
          disabled={disabled}
          height="25px"
          width="100px"
          margins="10px 0px 10px 0px"
          borderWidth={0}
          borderBottomWidth={0}
          borderTopWidth={0}
          text={t('upload')}
          fontSize="13px"
          fontWeight="600"
          backgroundColor={disabled ? colors.primaryAlpha80 : colors.primary}
          onPress={() => fileInputRef.current.click()}>
          <input
            style={{ display: 'none' }}
            ref={fileInputRef}
            type="file"
            onChange={onEditPress}
          />
        </TextButton>

        <TextButton
          height="auto"
          paddings="0px"
          text={t('profileScreensStartExampleVideo')}
          textColor={colors.secondary}
          fontSize="12px"
          onPress={() => onStartExampleVideoPress(exampleVideo)}
        />
      </TextView>
    </View>
  );
};

VideoEdit.propTypes = {
  validate: PropTypes.bool,
  disabled: PropTypes.bool,
  source: PropTypes.string,
  paused: PropTypes.bool.isRequired,
  onEditPress: PropTypes.func,
  onStartExampleVideoPress: PropTypes.func.isRequired
};

VideoEdit.defaultProps = {
  validate: true,
  disabled: false,
  source: exampleVideo,
  onEditPress: () => {}
};

export default VideoEdit;
