import React, { useEffect, useState } from 'react'
import { LandingLayout } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Heading, HStack, Skeleton, Text } from 'native-base'
import { useGetBookingDetailsQuery, useGetRatingsCategoriesQuery } from '@charlycares/data-access'
import { useRouter } from '@charlycares/shared'

import GiveRating from '../../reviews/common/GiveRating'

const BookingFeedbackScreen = () => {
  const { t } = useTranslation()
  const { navigate, getParam } = useRouter()

  const bookingId = getParam('bookingId', []) as string
  const isBookedBefore = getParam('isBookedBefore', false) as boolean
  const paymentMethod = getParam('paymentMethod') as string

  const { data: categories } = useGetRatingsCategoriesQuery({})
  const { data: booking } = useGetBookingDetailsQuery({ obscured_id: bookingId }, { skip: !bookingId })

  const [ratings, setRatings] = useState<
    {
      label: string
      value: number
      key: string
    }[]
  >([])

  useEffect(() => {
    if (categories) {
      setRatings(categories.map(category => ({ key: category, label: t(`rating_${category}`), value: 0 })))
    }
  }, [categories, t])

  const isNextDisabled = ratings.some(rating => rating.value === 0)

  return (
    <LandingLayout
      BottomComponent={
        <HStack space={'5px'} px="20px">
          {isBookedBefore && (
            <Button
              onPress={() => navigate('BookingFinished', '/booking-finished', { bookingId, paymentMethod })}
              variant="unstyled"
              _text={{ color: 'white' }}
              borderWidth="1px"
              borderColor="white"
              flex={1}
              rounded="full"
            >
              {t('later')}
            </Button>
          )}
          <Button
            isDisabled={isNextDisabled}
            onPress={() =>
              navigate('SendFeedback', '/bookings/send-feedback', { ratings, bookingId, isBookedBefore, paymentMethod })
            }
            flex={1}
            bgColor="white"
            _text={{ color: 'secondary.400' }}
          >
            {t('next')}
          </Button>
        </HStack>
      }
    >
      <Flex justifyContent="center" height="full" px="20px" mt="40px">
        <Heading fontSize={22} color="white">
          {t('feedback')}
        </Heading>
        <Text mt="10px" mb="30px" color="white" fontSize={15}>
          {t('giveRatingsDescription', { angel: booking?.angel?.first_name })}
        </Text>

        {categories?.length ? (
          <GiveRating
            _headerTextColor="white"
            ratings={ratings}
            onChange={setRatings}
            _starColor={{ selected: 'primary.350', unselected: 'gray.alpha900.50' }}
          />
        ) : (
          <Skeleton borderRadius="5px" h={'300px'} startColor="secondary.400" endColor="secondary.alpha.10" />
        )}
      </Flex>
    </LandingLayout>
  )
}

export default BookingFeedbackScreen

BookingFeedbackScreen.navigationOptions = {
  headerShown: false
}
