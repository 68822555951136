import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import {
  BookingRequestType,
  countBookingFilters,
  initialBookingState,
  setBookingSearchFilters,
  setBookingSearchName,
  setBookingSearchSkills,
  setBookingSearchSort,
  setSelectedAngels,
  toggleSelectedAngels,
  useAddAngelToBookingMutation,
  useAppSelector,
  useGetBookingDetailsQuery,
  useSearchAngelsQuery
} from '@charlycares/data-access'
import {
  AngelSummery,
  BookingServiceType,
  flattenSearchBookingDates,
  formatDate,
  formatMoney,
  isWeb,
  MAXIMUM_AMOUNT_OF_ANGELS,
  SearchBookingsDates,
  useRouter
} from '@charlycares/shared'
import { Badge, Box, Button, Factory, FlatList, HStack, Input, Spacer, StatusBar, Text, useTheme } from 'native-base'
import { SafeAreaInsetsContext, SafeAreaView } from 'react-native-safe-area-context'
import { Card, CharlyIcon, DebouncedNumberInput, Picker } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import AngelCard from '../../angel/common/AngelCard'
import { SearchingAngels, SelectedAngelsContainer } from '../common'
import { useAlert } from '../../../../hooks'

const SafeAreaContainer = Factory(SafeAreaView)

const sortItems = [
  {
    column: 'distance',
    label: 'filters_sort_distance'
  },
  {
    column: 'day_rate_amount',
    label: 'filters_day_rate_amount_sort'
  }
]

export default function MatchingListScreen() {
  const { getParam, goBack, navigate } = useRouter()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { colors } = useTheme()
  const inset = useContext(SafeAreaInsetsContext)
  const alert = useAlert()
  const showLowOfferPopup = useRef(true)
  const sortPickerRef = useRef<any>()

  const dates: SearchBookingsDates[] = getParam('dates', [])
  const bookingId = getParam('bookingId')
  const bookingRequestType = getParam('bookingRequestType') as BookingRequestType | undefined
  const bookingServiceType = bookingRequestType?.service_type

  const [showSearch, setShowSearch] = useState(false)
  const [firstName, setFirstName] = useState('')

  const selectedAngels = useAppSelector(state => state.booking.search.selectedAngels)
  const filters = useAppSelector(state => state.booking.search.filters)
  const sorts = useAppSelector(state => state.booking.search.sorts)
  const filtersCount = useAppSelector(state => state.booking.search.filtersCount)

  const { data: booking } = useGetBookingDetailsQuery({ obscured_id: bookingId }, { skip: !bookingId })
  const [addAngelsToBooking] = useAddAngelToBookingMutation()
  const {
    data: angels,
    isFetching,
    isLoading,
    error
  } = useSearchAngelsQuery({
    dates: flattenSearchBookingDates(dates),
    filters,
    sorts: sorts.length ? sorts : undefined,
    bookingObscuredId: bookingId
  })

  const isSingleBooking = dates.length === 1 && (!dates[0].repeatDates || dates[0].repeatDates.length === 0)
  const hasRepeatedDates = dates.find(date => date.repeatDates && date.repeatDates.length > 0)

  useEffect(() => {
    dispatch(setSelectedAngels([]))
    dispatch(setBookingSearchFilters(initialBookingState.search.filters))
    if (bookingServiceType === BookingServiceType.TUTORING) {
      dispatch(setBookingSearchSkills(['tutor']))
    }
    dispatch(countBookingFilters())
  }, [dispatch, bookingServiceType])

  useEffect(() => {
    const angelsWithIn5Km = angels?.filter(angel => angel.distance <= 5)
    if (angels && showLowOfferPopup.current) {
      showLowOfferPopup.current = false

      if (angelsWithIn5Km && angelsWithIn5Km.length < 3) {
        alert.show(t('limitedAngels'), t('limitedAngelsDesc'), [
          { text: t('continue') },
          {
            text: t('jobPost'),
            onPress: () =>
              navigate('JobPost', '/job-post', {
                dates,
                bookingRequestType: bookingRequestType
              })
          }
        ])
      }
    }
  }, [angels, navigate, t, alert, dates])

  const renderSearchDates = (): React.ReactNode => {
    if (isSingleBooking) {
      return `${formatDate(dates[0].startDate, 'ddd D MMMM HH:mm')} - ${formatDate(dates[0].endDate, 'HH:mm')}`
    } else {
      return dates.map(date => formatDate(date.startDate, { nl: 'dd', en: 'ddd' })).join(', ')
    }
  }

  const onSendBookingRequest = () => {
    if (selectedAngels.length < 3) {
      alert.show(
        t('bookScreensAttention'),
        t('bookScreensSelectedAngelsAttention', { length: selectedAngels.length }),
        [
          { text: t('cancel') },
          {
            text: t('bookScreensGiveItATry'),
            onPress: () =>
              navigate('SendBookingRequest', '/book-send-requests', { selectedAngels, dates, bookingServiceType })
          }
        ]
      )
    } else {
      navigate('SendBookingRequest', '/book-send-requests', { selectedAngels, dates, bookingServiceType })
    }
  }

  const onAddAngelsToBooking = () => {
    addAngelsToBooking({
      bookingObscuredId: bookingId,
      selected_angels: selectedAngels.map(angel => ({ angel_user_id: angel.angel_user_id, rank: angel.rank || 0 }))
    })
    navigate('BookRequestSent', '/book-request-sent')
  }

  //TODO: Legacy picker should be refactored
  const currentSort = sortItems.find(item => item.column === sorts[0]?.column)?.label || ''
  const onSortChange = (label: string) => {
    const sortColumn = sortItems.find(item => t(item.label) === t(label))?.column
    if (sortColumn) {
      dispatch(setBookingSearchSort(sortColumn))
    }
  }

  const maximumAmountOfAngels = booking?.maximum_amount_of_angels ?? MAXIMUM_AMOUNT_OF_ANGELS
  const maximumAmountOfAngelsSelected = selectedAngels.length >= maximumAmountOfAngels

  const onSelectAngel = (angel: AngelSummery, rank: number) => {
    if (maximumAmountOfAngelsSelected && !selectedAngels.find(a => a.id === angel.id)) {
      alert.show(t('bookScreensAttention'), t('maximumAmountOfAngelsNote', { maximumAmountOfAngels }), [
        { text: t('ok') }
      ])
    } else {
      dispatch(toggleSelectedAngels({ ...angel, rank: rank }))
    }
  }

  const paddingTop = (inset?.top || 0) + (isWeb ? 60 : 40) + 'px'

  const renderItem = useCallback(
    ({ item, index }) => (
      <AngelCard
        _pressed={{
          opacity: 0.5
        }}
        mt="5px"
        angel={item}
        isSelected={!!selectedAngels.find(angel => angel.id === item.id)}
        onSelect={angel => onSelectAngel(angel, index + 1)}
        isSelectableInDetails={!maximumAmountOfAngelsSelected}
      />
    ),
    [selectedAngels]
  )

  const keyExtractor = useCallback((item: AngelSummery) => item.obscured_id, [])

  return (
    <Box flex={1}>
      <Box bg="secondary.400" pt={paddingTop}>
        <StatusBar backgroundColor={colors.secondary[400]} barStyle="dark-content" animated />
        <Box
          zIndex={10}
          style={{ elevation: 1 }}
          left="0"
          right="0"
          position="absolute"
          bg="white"
          mx="20px"
          px="10px"
          py="6px"
          borderRadius="4px"
          borderColor="secondary.400"
          borderWidth="5px"
          bottom="-15px"
          flexDir="row"
          alignItems="center"
          h="55px"
        >
          {showSearch ? (
            <CharlyIcon
              onPress={() => dispatch(setBookingSearchName(firstName))}
              size="32px"
              name="icn-search"
              color="gray.800"
            />
          ) : (
            <CharlyIcon onPress={() => goBack()} size="32px" name="icn-nav-left" color="gray.800" />
          )}

          <Box w="1px" h="70%" bg="gray.800" mx="10px" />

          {showSearch ? (
            <Input
              autoFocus
              onSubmitEditing={() => dispatch(setBookingSearchName(firstName))}
              value={firstName}
              borderBottomWidth={0}
              _focus={{
                borderBottomWidth: 0
              }}
              onChangeText={setFirstName}
              minH="100%"
              flex={1}
              fontSize={15}
              returnKeyType="search"
            />
          ) : (
            <Text flex={1} textAlign={'center'} fontSize={'16px'}>
              {renderSearchDates() + '  '}
              {hasRepeatedDates && <Text color="primary.400">{t('repeated')}</Text>}
            </Text>
          )}

          {showSearch && (
            <CharlyIcon
              onPress={() => {
                setShowSearch(false)
                dispatch(setBookingSearchName(''))
              }}
              ml="5px"
              name="btn-close"
              size={24}
            />
          )}
        </Box>
      </Box>

      <Card zIndex={-1} mt="0" pt="25px" pb="15px" flexDir="row" justifyContent="space-around">
        <Button
          _text={{
            color: 'gray.800',
            fontSize: '13px',
            fontWeight: 600
          }}
          variant="text"
          leftIcon={<CharlyIcon size="24px" name="search" />}
          onPress={() => setShowSearch(true)}
        >
          {t('name')}
        </Button>
        <Button
          _text={{
            color: 'gray.800',
            fontSize: '13px',
            fontWeight: 600
          }}
          onPress={() => navigate('MatchingFilters', '/book-filters')}
          variant="text"
          leftIcon={<CharlyIcon size="24px" name="icn-filter" />}
          rightIcon={filtersCount > 0 ? <Badge>{filtersCount}</Badge> : undefined}
        >
          {t('filters')}
        </Button>
      </Card>

      {isLoading || isFetching ? (
        <SearchingAngels />
      ) : (
        <SafeAreaContainer bg="bg.light.400" flex={1} edges={['bottom', 'left', 'right']}>
          <>
            <FlatList
              data={angels}
              keyExtractor={keyExtractor}
              refreshing={isFetching}
              windowSize={3}
              initialNumToRender={5}
              maxToRenderPerBatch={5}
              renderItem={renderItem}
              ListEmptyComponent={
                <Box px="20px">
                  <Text textAlign="center" fontSize="16px" color="gray.800" mt="40px">
                    {(error as any)?.data?.message || t('noAngelsFound')}
                  </Text>

                  <Button mt="40px" onPress={() => navigate('InviteOwnAngelScreen', '/invite-own-angel')}>
                    {t('bookScreenInviteYourOwnAngel')}
                  </Button>
                </Box>
              }
            />
            {Boolean(angels?.length) && (
              <Box px="20px" py="10px" borderTopWidth={1} borderColor="gray.400">
                <SelectedAngelsContainer mb="5px" />
                <Button
                  isDisabled={!selectedAngels.length}
                  onPress={() => (bookingId ? onAddAngelsToBooking() : onSendBookingRequest())}
                >
                  {bookingId ? t('bookScreensConfirmAddAngels') : t('next')}
                </Button>
              </Box>
            )}
            <HStack
              position="absolute"
              bottom="120px"
              bgColor="gray.alpha900.80"
              rounded="3xl"
              px="15px"
              py="5px"
              width="230px"
              alignSelf="center"
              alignItems="center"
            >
              <Text fontWeight={600} fontSize="14px" color="white">
                {t('matchingListScreen.maxRate')}
              </Text>
              <Spacer />

              <DebouncedNumberInput
                changeRate={25}
                onChange={val => {
                  dispatch(setBookingSearchFilters({ ...filters, max_rate_per_hour: { amount: val, currency: 'EUR' } }))
                  dispatch(countBookingFilters())
                }}
                initialValue={filters.max_rate_per_hour.amount || 1000}
                buttonColor="gray.200"
                formatValue={val => (
                  <Text color={val !== 1200 ? 'white' : 'gray.700'} fontWeight="600">
                    {formatMoney({ amount: val, currency: 'EUR' })}
                  </Text>
                )}
              />
            </HStack>
          </>
        </SafeAreaContainer>
      )}

      {!isWeb && (
        <Picker
          ref={sortPickerRef}
          options={sortItems.map(item => t(item.label))}
          value={t(currentSort)}
          onConfirmPress={onSortChange}
        />
      )}
    </Box>
  )
}

MatchingListScreen.navigationOptions = {
  headerShown: false
}
