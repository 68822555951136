import React, { useEffect, useMemo, useRef, useState } from 'react'
import { SvgXml } from 'react-native-svg'
import { isWeb } from '@charlycares/shared'

type SvgUriProps = {
  uri: string
  size: number | string
  width?: number | string
  height?: number | string
  alt?: string
}

function LoadSvg(props: SvgUriProps) {
  const { uri } = props
  const [xml, setXml] = useState<string | null>(null)

  useEffect(() => {
    uri
      ? fetch(uri)
          .then(response => {
            if (response.ok) {
              return response.text()
            }
            throw new Error(`${response.status} ${response.statusText}`)
          })
          .then(setXml)
      : setXml(null)
  }, [uri])

  return <SvgXml xml={xml} override={props} />
}

export default function SvgUri({ uri, size, width, height, alt }: SvgUriProps) {
  return isWeb ? (
    <img src={uri} alt={alt} width={width || size} height={height || size} />
  ) : (
    // @ts-ignore
    <LoadSvg uri={uri} width={width || size} height={height || size} />
  )
}

SvgUri.defaultProps = {
  size: 20
}
