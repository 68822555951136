import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Text, Switch } from '../../elements';
import { TextView } from '../Texts';
import colors from '../../styles/colors';

const NotificationSettings = ({
  disabled,
  promoMail,
  systemMail,
  promoPush,
  systemPush,
  onPromoMailChange,
  onSystemMailChange,
  onPromoPushChange,
  onSystemPushChange
}) => {
  const [t] = useTranslation();

  const switchColor = {
    true: disabled ? colors.primaryAlpha80 : colors.primary,
    false: disabled ? colors.grey08 : colors.grey04
  };

  return (
    <>
      <TextView
        paddings="15px 15px 5px 15px"
        borderTopWidth="1px"
        borderBottomWidth="1px"
        backgroundColor={colors.backgroundColor}
        text={t('profileScreensNotificationSettings')}
        fontSize="14px"
        textColor={colors.grey02}
      />

      <TextView
        paddings="20px"
        flexDirection="column"
        alignItems="flex-start"
        borderBottomWidth="1px"
        text={t('email')}
        fontFamily="Martel"
        fontSize="16px"
        fontWeight="600">
        <TextView
          justifyContent="space-between"
          paddings="15px 0px 15px 0px"
          width="100%"
          text={t('profileScreensPromotional')}
          fontWeight="300">
          <Switch
            disabled={disabled}
            value={promoMail}
            onValueChange={onPromoMailChange}
            trackColor={switchColor}
          />
        </TextView>

        <TextView
          justifyContent="space-between"
          paddings="15px 0px 15px 0px"
          width="100%"
          text={t('profileScreensReservationsAndReviews')}
          fontWeight="300">
          <Switch
            disabled={disabled}
            value={systemMail}
            onValueChange={onSystemMailChange}
            trackColor={switchColor}
          />
        </TextView>
      </TextView>

      <TextView
        flexDirection="column"
        alignItems="flex-start"
        paddings="20px"
        borderBottomWidth="1px"
        text={t('profileScreensPushNotifications')}
        fontFamily="Martel"
        fontSize="16px"
        fontWeight="600">
        <Text fontSize="12px" color={colors.defaultBtnTextColor}>
          {t('profileScreensForMobileAndTablet')}
        </Text>

        <TextView
          justifyContent="space-between"
          paddings="15px 0px 15px 0px"
          width="100%"
          text={t('profileScreensPromotional')}
          fontWeight="300">
          <Switch
            disabled={disabled}
            value={promoPush}
            onValueChange={onPromoPushChange}
            trackColor={switchColor}
          />
        </TextView>

        <TextView
          justifyContent="space-between"
          paddings="15px 0px 15px 0px"
          width="100%"
          text={t('profileScreensReservationsAndReviews')}
          fontWeight="300">
          <Switch
            disabled={disabled}
            value={systemPush}
            onValueChange={onSystemPushChange}
            trackColor={switchColor}
          />
        </TextView>
      </TextView>
    </>
  );
};

NotificationSettings.propTypes = {
  disabled: PropTypes.bool,
  promoMail: PropTypes.bool.isRequired,
  systemMail: PropTypes.bool.isRequired,
  promoPush: PropTypes.bool.isRequired,
  systemPush: PropTypes.bool.isRequired,
  onPromoMailChange: PropTypes.func,
  onSystemMailChange: PropTypes.func,
  onPromoPushChange: PropTypes.func,
  onSystemPushChange: PropTypes.func
};

NotificationSettings.defaultProps = {
  disabled: false,
  onPromoMailChange: () => {},
  onSystemMailChange: () => {},
  onPromoPushChange: () => {},
  onSystemPushChange: () => {}
};

export default NotificationSettings;
