const Heading = {
  baseStyle: {
    color: 'text.dark.400',
    fontWeight: 600
  },
  sizes: {
    lg: {
      fontSize: '22px'
    },
    md: {
      fontSize: '16px'
    }
  },
  defaultProps: {
    size: 'md'
  }
}

export default Heading
