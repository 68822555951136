import React from 'react'
import { Card, ScreenLayout, SvgUri } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { formatInterval, formatMoney, TransactionType, useRouter } from '@charlycares/shared'
import { Avatar, Box, Flex, Spacer, Text, VStack } from 'native-base'
import { useGetTransactionQuery } from '@charlycares/data-access'
import moment from 'moment/moment'

const TopUpScreen = () => {
  const { t } = useTranslation()
  const { getParam } = useRouter()
  const walletTransactionId = getParam('wallet_transaction_id')
  const { data: transaction } = useGetTransactionQuery({ transaction_id: walletTransactionId })

  return (
    <ScreenLayout edges={['left', 'right']} supportButton disableScroll title={t('payment')}>
      <Card>
        {transaction && (
          <>
            <VStack mb="10px">
              <Flex direction="row">
                {transaction?.image && transaction.image.includes('.svg') ? (
                  <SvgUri uri={transaction.image} width={80} height={36} />
                ) : (
                  <Avatar borderWidth={1} borderColor="gray.400" size="40px" source={{ uri: transaction.image }} />
                )}
                <Text
                  fontSize="16px"
                  fontWeight="400"
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  marginLeft="10px"
                  marginTop="8px"
                >
                  {transaction.description}
                </Text>
                <Spacer />
                <Text fontSize="16px" fontWeight="400">
                  {transaction.type === TransactionType.MINUTES && formatInterval(transaction.interval)}
                  {transaction.type === TransactionType.MONEY && formatMoney(transaction.amount)}
                </Text>
              </Flex>
              <Flex direction="row-reverse" marginTop="50px">
                <Text fontSize="16px" color="gray.800">
                  {moment(transaction.created_at).format('ll HH:mm')}
                </Text>
              </Flex>
            </VStack>
            <Box borderTopColor="gray.200" borderTopWidth="1" pt="10px">
              <Text fontWeight="600" fontSize="16px">
                {t('reason')}
              </Text>
              <Text fontSize="15px">{transaction.reason}</Text>
            </Box>
          </>
        )}
      </Card>
    </ScreenLayout>
  )
}

export default TopUpScreen

TopUpScreen.navigationOptions = {
  headerShown: false
}
