export const space = {
  '2xs': '2px',
  xs: '4px',
  sm: '8px',
  md: '12px',
  lg: '16px',
  xl: '24px',
  '2xl': '32px',
  '3xl': '64px'
}
export type Space = typeof space

export const fontSize = {
  '2xs': '11px',
  xs: '12px',
  sm: '14px',
  md: '15px',
  lg: '18px',
  xl: '22px',
  '2xl': '25px'
}
export type FontSize = typeof fontSize

export default { space, fontSize }
