import axios, { AxiosRequestConfig } from 'axios'
import { Platform } from 'react-native'

import { getAppVersion } from './platformUtils'
import { getUserToken } from './authToken'
import { getBaseUrl } from "./baseUrl";

export const axiosBase = axios.create({
  headers: { 'Content-Type': 'application/json' }
})

const authenticationRequestInterceptor = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const token = await getUserToken()
  const version = await getAppVersion()

  if (config.baseURL && token) {
    return {
      ...config,
      headers: {
        Authorization: `Bearer ${token}`,
        AppVersion: `${Platform.OS}-${version}`
      }
    }
  }

  return config
}

const versionRequestInterceptor = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const baseUrl = await getBaseUrl()

  if (config.url?.includes('v1') || config.url?.includes('v2')) {
    return {
      ...config,
      baseURL: baseUrl
    }
  }

  return {
    ...config,
    baseURL: `${baseUrl}v1/`
  }
}

axiosBase.interceptors.request.use(authenticationRequestInterceptor)
axiosBase.interceptors.request.use(versionRequestInterceptor)
