import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'

import { Text } from '../../elements'
import Modal from './Modal'
import { TextButton, IconButton } from '../Buttons'
import theme from '../../styles/theme'

const Overlay = styled.View`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: ${theme.colors.transparentModal};
`

const AlertCard = styled.View`
  flex-direction: column;
  background: ${theme.colors.white};
  border-radius: 16px;
  margin: 16px;
  ${p => p.maxWidth && `max-width: ${p.maxWidth};`}
`

const Content = styled.View`
  flex-direction: ${p => (p.col ? 'column' : 'row')};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  padding: 15px 20px;
  ${p =>
    p.border &&
    `
	  border-top-width: 1px; border-color: ${theme.colors.grey08};
	  border-bottom-width: 1px; border-color: ${theme.colors.grey08}
	  `}
`

const Alert = forwardRef(
  (
    {
      title,
      subtitle,
      confirmButtonText,
      secondConfirmButtonText,
      showCancelButton,
      showSecondConfirmButton,
      onCancelPress,
      onConfirmPress,
      onSecondConfirmPress,
      maxWidth
    },
    ref
  ) => {
    const [t] = useTranslation()
    const modalRef = useRef(null)

    const setIsVisible = isVisible => {
      modalRef.current.setIsVisible(isVisible)
    }

    useImperativeHandle(ref, () => ({
      setIsVisible
    }))

    return (
      <Modal ref={modalRef}>
        <Overlay>
          <AlertCard maxWidth={maxWidth}>
            <Content justifyContent="space-between">
              <Text fontWeight="bold" lg>
                {title}
              </Text>

              {showCancelButton && (
                <IconButton
                  height="auto"
                  iconName="close"
                  iconSize="15px"
                  iconColor={theme.colors.grey04}
                  onPress={onCancelPress}
                />
              )}
            </Content>

            <Content border>
              <Text>{subtitle}</Text>
            </Content>

            <Content col>
              <TextButton
                backgroundColor={theme.colors.primary}
                textColor={theme.colors.white}
                text={confirmButtonText || t('confirm')}
                onPress={onConfirmPress}
                margins="0 0 8px 0"
              />
              {showSecondConfirmButton && (
                <TextButton
                  borderColor={theme.colors.primary}
                  textColor={theme.colors.primary}
                  text={secondConfirmButtonText || t('confirm')}
                  onPress={onSecondConfirmPress}
                  borderLeftWidth="1px"
                  borderRightWidth="1px"
                />
              )}
            </Content>
          </AlertCard>
        </Overlay>
      </Modal>
    )
  }
)

Alert.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  confirmButtonText: PropTypes.string,
  secondConfirmButtonText: PropTypes.string,
  showCancelButton: PropTypes.bool,
  showSecondConfirmButton: PropTypes.bool,
  onCancelPress: PropTypes.func,
  onConfirmPress: PropTypes.func.isRequired,
  onSecondConfirmPress: PropTypes.func,
  maxWidth: PropTypes.number
}

Alert.defaultProps = {
  subtitle: null,
  confirmButtonText: null,
  secondConfirmButtonText: '',
  showCancelButton: true,
  showSecondConfirmButton: false,
  onCancelPress: () => {},
  onSecondConfirmPress: () => {},
  maxWidth: undefined
}

export default Alert
