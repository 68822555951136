import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextView } from '../../Texts';
import HourlyRateEdit from './HourlyRateEdit';
import colors from '../../../styles/colors';

const HourlyRatesEdit = ({ disabled, hourlyRates, onDecreasePress, onIncreasePress }) => {
  const [t] = useTranslation();

  return (
    <TextView
      paddings="20px"
      flexDirection="column"
      alignItems="flex-start"
      borderTopWidth="1px"
      text={t('hourlyRate')}
      fontSize="12px"
      fontWeight="300"
      textColor={colors.grey02}>
      {hourlyRates.map(({ type, desc, icon, rate, competitiveRate, minimumRate, maximumRate }) => (
        <HourlyRateEdit
          key={type}
          disabled={disabled}
          type={type}
          desc={desc}
          icon={icon}
          rate={rate}
          competitiveRate={competitiveRate}
          minimumRate={minimumRate}
          maximumRate={maximumRate}
          onDecreasePress={() => onDecreasePress(type)}
          onIncreasePress={() => onIncreasePress(type)}
        />
      ))}
    </TextView>
  );
};

HourlyRatesEdit.propTypes = {
  disabled: PropTypes.bool,
  hourlyRates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDecreasePress: PropTypes.func,
  onIncreasePress: PropTypes.func
};

HourlyRatesEdit.defaultProps = {
  disabled: false,
  onDecreasePress: () => {},
  onIncreasePress: () => {}
};

export default HourlyRatesEdit;
