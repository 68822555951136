import React from 'react'
import { Card, ScreenLayout } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { Button, Heading, Text } from 'native-base'
import { useRouter } from '@charlycares/shared'
import { InsuranceCard } from '../common'

const InviteAcceptedScreen = ({ navigation }: any) => {
  const { t } = useTranslation()
  const { navigate } = useRouter()

  return (
    <ScreenLayout
      hideBackButton
      title={t('inviteAccepted')}
      headerRight={
        <Button
          onPress={() => {
            navigation.popToTop()
            navigate('BookingsAngelScreen')
          }}
          variant="text"
        >
          {t('done')}
        </Button>
      }
    >
      <Card mt="10px">
        <Heading fontSize="18px" fontWeight={600}>
          {t('invitationAcceptedTitle')}
        </Heading>
        <Text mt="15px">{t('invitationAcceptedDesc')}</Text>
      </Card>

      <InsuranceCard />
    </ScreenLayout>
  )
}

export default InviteAcceptedScreen

InviteAcceptedScreen.navigationOptions = {
  headerShown: false
}
