import React from 'react'
import { CharlyIcon } from '@charlycares/ui'
import { Box, HStack, Skeleton, Spacer, Text } from 'native-base'
import { formatInterval, formatMoney, Money, UserRoles } from '@charlycares/shared'
import { useTranslation } from 'react-i18next'
import { BookingRate } from '@charlycares/data-access'

type Props = {
  dayRatePerHour?: Money
  nightRatePerHour?: Money
  insuranceFeePerHour?: Money
  bookingRate?: BookingRate
  role?: UserRoles
  isLoading: boolean
}

export default function BookingCostSummary({
  isLoading,
  dayRatePerHour,
  nightRatePerHour,
  insuranceFeePerHour,
  bookingRate,
  role
}: Props) {
  const { t } = useTranslation()

  return (
    <>
      <HStack alignItems="center" py="10px" space="5px">
        <CharlyIcon color={'gray.900'} name="icn-day" size={24} />
        {!isLoading && (
          <HStack flex={1}>
            <Text flex={1} fontSize={12} color="gray.900" pl="8px">
              {formatInterval(bookingRate?.duration.day)} ({formatMoney(dayRatePerHour)} {t('perHour')})
            </Text>
            <Text color="gray.900">{formatMoney(bookingRate?.day_costs)}</Text>
          </HStack>
        )}
        {isLoading && <Skeleton ml="8px" h="5" flex={2} rounded="full" startColor="gray.400" />}
      </HStack>
      <HStack alignItems="center" py="10px" space="5px">
        <CharlyIcon color={'gray.900'} name="icn-night" size={24} />
        {!isLoading && (
          <HStack flex={1}>
            <Text flex={1} fontSize={12} color={'gray.900'} pl="8px">
              {formatInterval(bookingRate?.duration.night)} ({formatMoney(nightRatePerHour)} {t('perHour')})
            </Text>
            <Text color={'gray.900'}>{formatMoney(bookingRate?.night_costs)}</Text>
          </HStack>
        )}

        {isLoading && <Skeleton ml="8px" h="5" flex={2} rounded="full" startColor="gray.400" />}
      </HStack>
      <HStack alignItems="center" py="10px">
        <Text color={'gray.900'}>{t('travelAllowance')}</Text>
        <Spacer />
        {!isLoading && <Text color={'gray.900'}>{formatMoney(bookingRate?.travel_allowance)}</Text>}
        {isLoading && <Skeleton h="5" width="45px" rounded="full" startColor="gray.400" />}
      </HStack>
      {role !== UserRoles.ANGEL && (
        <>
          <HStack alignItems="center" py="10px">
            <Text color={'gray.900'}>{t('endBooking.transactionCosts')}</Text>
            <Spacer />
            {!isLoading && <Text color={'gray.900'}>{formatMoney(bookingRate?.transaction_fee)}</Text>}
            {isLoading && <Skeleton h="5" width="45px" rounded="full" startColor="gray.400" />}
          </HStack>
          <HStack alignItems="center" py="10px">
            {!isLoading && (
              <Text color={'gray.900'}>
                {t('endBooking.insuranceCosts')} ({formatMoney(insuranceFeePerHour)} {t('perHour')})
              </Text>
            )}
            {isLoading && <Skeleton ml="8px" h="5" flex={2} rounded="full" startColor="gray.400" />}
            <Spacer />
            {!isLoading && <Text color={'gray.900'}>{formatMoney(bookingRate?.insurance_fee)}</Text>}
            {isLoading && <Skeleton h="5" width="45px" rounded="full" startColor="gray.400" />}
          </HStack>
        </>
      )}
    </>
  )
}
