import { strings as legacyStrings } from '@charlycares/shared'

export const strings = legacyStrings

export const setLanguageLocale = locale => {
  strings.setLanguage(locale === 'nl_NL' ? 'nl' : 'en')
}

export const setTranslation = (enTranslation, nlTranslation) => {
  strings.setContent({
    en: enTranslation,
    nl: nlTranslation
  })
}

export default strings
