import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from '@charlycares/shared'
import { Card, CharlyIcon, ScreenLayout } from '@charlycares/ui'
import { Button, Heading, Text } from 'native-base'

export default function NewCityStep2Screen() {
  const { t } = useTranslation()
  const { navigate } = useRouter()

  return (
    <ScreenLayout
      progress={`${50}%`}
      subtitle={''}
      headerLeft={<CharlyIcon name="logo" color="primary.400" size={30} />}
      bgColor="white"
      hideBackButton
    >
      <Card borderWidth="0" minHeight="530px">
        <Text fontSize="16px" color="gray.800">
          {t('newCityStep2Screen.headingDisclaimer')}
        </Text>
        <Heading mb="25px" mt="5px" fontSize="20" fontWeight="600" lineHeight="26px">
          {t('newCityStep2Screen.header')}
        </Heading>
        <Text fontSize="18px" ml="10px" lineHeight="26px">
          {t('newCityStep2Screen.description')}
        </Text>
      </Card>

      <Button mx="20px" onPress={() => navigate('NewCityStep3Screen', '/family/new-city/step-3')}>
        {t('newCityStep2Screen.nextStep')}
      </Button>
    </ScreenLayout>
  )
}
