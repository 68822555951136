import React from 'react'
import ContentLoader from 'react-content-loader'
import styled from 'styled-components/native'

const Container = styled.View`
  flex-direction: row;
  padding: 10px;
`

const Loader = () => (
  <Container>
    {[...new Array(3).keys()].map((key) => (
      <ContentLoader key={String(key)} height={260} width={200} speed={2} color="#f3f3f3">
        <circle cx="100" cy="60" r="50" />
        <rect x="30" y="130" rx="0" ry="0" width="140" height="20" />
        <rect x="10" y="160" rx="0" ry="0" width="180" height="20" />
        <rect x="30" y="190" rx="0" ry="0" width="140" height="20" />
      </ContentLoader>
    ))}
  </Container>
)

export default Loader
