import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { TextView } from '../Texts';
import { IconButton } from '../Buttons';
import { InfoIcons, FamilyAvatar } from '../Profile';
import colors from '../../styles/colors';

import arrowRight from '../../../assets/icons/icn-nav-right.png';

const View = styled.View``;

const FamilyDetails = ({
  family,
  familyImage,
  onFamilyPress,
  distance,
  hasDog,
  hasCat,
  childCount,
  bookingChildCount
}) => {
  const [t] = useTranslation();

  return (
    <View>
      <IconButton
        flexDirection="row-reverse"
        justifyContent="space-between"
        padding="0px"
        width="100%"
        height="auto"
        iconSource={arrowRight}
        iconSize="20px"
        iconColor={colors.grey08}
        onPress={onFamilyPress}>
        <TextView
          flexDirection="row-reverse"
          text={t('familyName', { 0: family || '' })}
          textMargins="0px 0px 0px 10px"
          fontSize="16px"
          fontWeight="600"
          fontFamily="Martel">
          <FamilyAvatar width="90px" height="60px" source={familyImage} />
        </TextView>
      </IconButton>

      <InfoIcons
        hasCat={hasCat}
        hasDog={hasDog}
        distance={distance}
        childCount={childCount}
        bookingChildCount={bookingChildCount}
      />
    </View>
  );
};

FamilyDetails.propTypes = {
  family: PropTypes.string.isRequired,
  familyImage: PropTypes.node,
  distance: PropTypes.number.isRequired,
  hasDog: PropTypes.bool.isRequired,
  hasCat: PropTypes.bool.isRequired,
  childCount: PropTypes.number.isRequired,
  bookingChildCount: PropTypes.number.isRequired,
  onFamilyPress: PropTypes.func.isRequired
};

FamilyDetails.defaultProps = {
  familyImage: null
};

export default FamilyDetails;
