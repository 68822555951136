import React from 'react';
import PropTypes from 'prop-types';
import TextButton from './TextButton';
import { Icon } from '../../elements';
import CharlyIcon from '../Icons/Icon';

const TextIconButton = ({
  iconName,
  icon,
  iconColor,
  iconSize,
  iconMargins,
  useIconTintColor,
  height,
  ...buttonProps
}) => (
  <TextButton height={height} {...buttonProps}>
    {iconName ? (
      <CharlyIcon name={iconName} color={iconColor} size={iconSize} />
    ) : (
      <Icon
        margins={iconMargins}
        width={iconSize}
        height={iconSize}
        source={icon}
        color={iconColor}
        useTintColor={useIconTintColor}
      />
    )}
  </TextButton>
);

TextIconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  iconColor: PropTypes.string,
  iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconMargins: PropTypes.string,
  useIconTintColor: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconName: PropTypes.string
};

TextIconButton.defaultProps = {
  iconColor: 'black',
  iconSize: '20px',
  iconMargins: '0px',
  useIconTintColor: true,
  height: 'auto',
  iconName: ''
};

export default TextIconButton;
