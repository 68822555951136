import React, { useCallback, useEffect, useState } from 'react'
import {
  useGetFavoriteAngelsQuery,
  useGetUserIndicatorsQuery,
  useSendMessageToFavoriteAngelsMutation
} from '@charlycares/data-access'
import { AngelSummery, contactPaginatedData, useRouter } from '@charlycares/shared'
import { Avatar, Box, Button, Center, FlatList, HStack, Skeleton, Spinner, Text, VStack } from 'native-base'
import { useTranslation } from 'react-i18next'
import { useAlert } from '../../hooks'
import AngelCard from '../bookings/angel/common/AngelCard'
import { Card, CharlyIcon, ScreenLayout, TextInput } from '@charlycares/ui'
import { Pressable } from 'react-native'
import SkeletonAngelCard from '../bookings/angel/common/SkeletonAngelCard'
import { withNavigationFocus } from 'react-navigation'

const FavoriteAngelsScreen = ({ isFocused }: { isFocused: boolean }) => {
  const { navigate } = useRouter()
  const { t } = useTranslation()
  const alert = useAlert()

  const [page, setPage] = useState(1)
  const [message, setMessage] = useState<string>('')
  const [angels, setAngels] = useState<AngelSummery[]>([])
  const [selectedAngels, setSelectedAngels] = useState<AngelSummery[]>([])

  const {
    data: favoriteAngelsResult,
    isFetching,
    refetch,
    isSuccess,
    isLoading,
    error
  } = useGetFavoriteAngelsQuery({ page: page })
  const [sendMessageToFavoriteAngels, { isLoading: postingData }] = useSendMessageToFavoriteAngelsMutation()
  const { refetch: refetchIndicators } = useGetUserIndicatorsQuery()

  useEffect(() => {
    if (isSuccess && !isFetching && favoriteAngelsResult) {
      setAngels(state => contactPaginatedData(state, favoriteAngelsResult))
    }
  }, [favoriteAngelsResult, isFetching, isSuccess])

  const toggleSelectedAngels = (angel: AngelSummery) => {
    const isSelected = selectedAngels.find(a => a.id === angel.id)
    if (isSelected) {
      setSelectedAngels(selectedAngels.filter(a => a.id !== angel.id))
    } else {
      setSelectedAngels([...selectedAngels, angel])
    }
  }

  const sendMessageToFavorites = async () => {
    if (selectedAngels.length > 0 && message.trim().length > 0) {
      try {
        await sendMessageToFavoriteAngels({
          selected_angels: selectedAngels.map(angel => ({ angel_user_id: angel.angel_user_id })),
          message: message
        }).unwrap()

        setMessage('')
        setSelectedAngels([])
        alert.show(t('success'), t('favoritesChatSuccess'))
      } catch (error) {
        alert.show(t('error'), error?.status === 422 ? error?.data?.message || error?.data?.error : t('requestError'))
      }
    }
  }

  const renderItem = useCallback(
    ({ item }) => (
      <AngelCard
        isSelectableInDetails={false}
        _pressed={{
          opacity: 0.5
        }}
        mt="5px"
        angel={item}
        isSelected={!!selectedAngels.find(angel => angel.id === item.id)}
        onSelect={angel => toggleSelectedAngels(angel)}
        borderColor={'transparent'}
      />
    ),
    [selectedAngels]
  )

  const keyExtractor = useCallback((item: AngelSummery) => item.obscured_id, [])

  const getTextForSendButton = () => {
    if (angels.length === 0) {
      return t('favoritesNoAngelsChatSendButton')
    } else if (selectedAngels.length === 0) {
      return t('favoritesSelectAngelSendButton')
    } else {
      return t('favoritesChatSendButton')
    }
  }

  useEffect(() => {
    if (isFocused) {
      refetchIndicators()
    }
  }, [isFocused])

  return (
    <ScreenLayout disableScroll hideBackButton title={t('Favorites')} edges={['left', 'right']}>
      <Card p="15px" mt="10px" mb="5px">
        <Box mb="15px" flexDir="row" alignItems="center">
          <Text fontSize="16px" fontWeight="600" mr="10px">
            {t('favoritesChatTitle')}
          </Text>
          <CharlyIcon
            name="icn-small-info"
            size={18}
            color="blue.400"
            onPress={() => alert.show(t('favoritesChatPopUpTitle'), t('favoritesChatInfo'))}
          />
        </Box>

        {isLoading || isFetching ? (
          <Skeleton h="122px" w="100%" rounded="md" startColor="gray.100" mb="10px" />
        ) : (
          <>
            <Text
              fontSize="16px"
              color={postingData ? 'gray.400' : 'gray.700'}
              bg={postingData ? 'gray.50' : 'gray.100'}
              pt="10px"
              pl="10px"
              h="35px"
            >
              {angels.length > 0 ? t('Hi [name],') : ''}
            </Text>

            <TextInput
              minHeight="70px"
              variant="filled"
              isDisabled={postingData}
              value={message}
              onChangeText={setMessage}
              placeholder={
                !isLoading && !isFetching && angels?.length === 0
                  ? t('favoritesChatNoAngelsPlaceholder')
                  : t('favoritesChatPlaceholder')
              }
              multiline
              numberOfLines={5}
              maxLength={250}
              returnKeyType="default"
            />
          </>
        )}

        <Button
          h="40px"
          w="100%"
          size="md"
          isDisabled={!selectedAngels.length || !message.trim() || postingData || isLoading || isFetching}
          isLoading={postingData}
          onPress={sendMessageToFavorites}
        >
          {getTextForSendButton()}
        </Button>
      </Card>

      <Box flex={1}>
        {!isLoading && !isFetching && (
          <FlatList
            data={angels}
            keyExtractor={keyExtractor}
            refreshing={isFetching}
            onRefresh={() => {
              setPage(1)
              refetch()
            }}
            onEndReachedThreshold={0.5}
            onEndReached={() => {
              if (favoriteAngelsResult?.meta.last_page && page < favoriteAngelsResult?.meta.last_page && !isFetching) {
                if (favoriteAngelsResult?.meta.current_page === page) {
                  setPage(page + 1)
                }
              }
            }}
            windowSize={3}
            initialNumToRender={5}
            maxToRenderPerBatch={5}
            renderItem={renderItem}
            ListFooterComponent={() =>
              (page !== 1 && page !== favoriteAngelsResult?.meta.last_page) || isFetching ? <Spinner mt="10px" /> : null
            }
            ListEmptyComponent={
              !isLoading && !isFetching && isSuccess && !favoriteAngelsResult?.data.length ? (
                <Box px="20px">
                  <Text textAlign="center" fontSize="16px" color="gray.800" mt="40px">
                    {(error as any)?.data?.message || t('noFavorites')}
                  </Text>

                  <Button mt="40px" onPress={() => navigate('BookingRequestTypesList', '/booking-request-types-list')}>
                    {t('searchAngels')}
                  </Button>
                </Box>
              ) : undefined
            }
          />
        )}

        {(isLoading || isFetching) &&
          Array.from({ length: 4 }).map((_, index) => <SkeletonAngelCard index={index} key={index} />)}

        {Boolean(selectedAngels?.length) && (
          <Box pb="5px" pt="10px" py="10px" borderTopWidth={1} borderColor="gray.400" w="100%" bgColor="gray.50">
            <HStack space="10px" flexWrap="wrap">
              {selectedAngels.map(angel => (
                <Box key={angel.id}>
                  <Pressable
                    onPress={() => navigate('AngelProfile', `/booking-angel-profile`, { angelId: angel.obscured_id })}
                  >
                    <Avatar borderColor="white" borderWidth={2} source={{ uri: angel.image }} size={'40px'} />
                  </Pressable>
                  <CharlyIcon
                    hitSlop={{ top: 5, bottom: 5, left: 5, right: 5 }}
                    onPress={() => toggleSelectedAngels(angel)}
                    position="absolute"
                    right={-6}
                    top={-6}
                    name="btn-close"
                    color="gray.800"
                    size={20}
                  />
                </Box>
              ))}
            </HStack>
          </Box>
        )}
      </Box>
    </ScreenLayout>
  )
}

const screen = withNavigationFocus(FavoriteAngelsScreen)
export default screen

// @ts-ignore
screen.navigationOptions = {
  headerShown: false
}
