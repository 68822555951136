import {
  SET_PREFERENCES,
  SET_PREFERENCE,
  SET_AVAILABILITIES,
  SET_AVAILABILITY,
  SET_WAS_EDITED
} from '../types';

const initialState = {
  wasEdited: false,
  preferences: {},
  availability: []
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SET_PREFERENCES:
      return {
        ...state,
        preferences: payload
      };
    case SET_PREFERENCE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          [payload.key]: payload.value
        }
      };
    case SET_AVAILABILITIES:
      return {
        ...state,
        availability: payload
      };
    case SET_AVAILABILITY:
      return {
        ...state,
        availability: state.availability.map((d) => {
          if (d.day_of_week === payload.day.day_of_week) {
            return {
              ...d,
              [payload.partOfDay]: d[payload.partOfDay] === 0 ? 1 : 0
            };
          }
          return d;
        }),
        wasEdited: true
      };
    case SET_WAS_EDITED:
      return {
        ...state,
        wasEdited: payload
      };
    default:
      return state;
  }
}
