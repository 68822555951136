import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { TextView, TextTextView } from '../Texts';
import { TextButton } from '../Buttons';
import { getFormatedDate } from '../../utils/functions';
import colors from '../../styles/colors';

const View = styled.View`
  padding: ${({ paddings }) => paddings};
  border-bottom-width: 1px;
  border-color: ${colors.grey08};
`;
const DaysDeselect = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const RequestBookingDetails = ({
  paddings,
  startDate,
  endDate,
  bookingDates,
  selectedDays,
  onToggleSelectAllPress,
  showToggeSelectButton
}) => {
  const [t] = useTranslation();
  const numberOfSelectedDays = selectedDays.filter((selectedDay) => selectedDay).length;

  return (
    <View paddings={paddings}>
      <TextTextView
        firstText={getFormatedDate(startDate, 'dddd')}
        firstTextFontSize="20px"
        firstTextFontWeight="600"
        firstTextFontFamily="Martel"
        secondText={`${getFormatedDate(startDate, 'HH:mm')} - ${getFormatedDate(
          endDate,
          'HH:mm'
        )} `}
        secondTextFontSize="16px"
        secondTextFontWeight="600"
        secondTextFontFamily="Martel"
      />

      <DaysDeselect>
        <TextView
          flexDirection="row-reverse"
          text={` / ${bookingDates.length} ${t('days')}`}
          margins="0px 0px 10px 0px">
          <TextView
            width="20px"
            height="20px"
            justifyContent="center"
            alignItems="center"
            text={numberOfSelectedDays.toString()}
            backgroundColor={colors.grey08}
          />
        </TextView>

        {showToggeSelectButton && (
          <TextButton
            paddings="0px"
            fontFamily="Martel"
            text={
              numberOfSelectedDays > selectedDays.length / 2
                ? t('bookingsScreenAngelDeselectAll')
                : t('bookingsScreenAngelSelectAll')
            }
            textColor={colors.secondary}
            onPress={() => onToggleSelectAllPress(numberOfSelectedDays > selectedDays.length / 2)}
          />
        )}
      </DaysDeselect>
    </View>
  );
};

RequestBookingDetails.propTypes = {
  paddings: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  bookingDates: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedDays: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleSelectAllPress: PropTypes.func,
  showToggeSelectButton: PropTypes.bool
};

RequestBookingDetails.defaultProps = {
  paddings: '20px 20px 0px 20px',
  onToggleSelectAllPress: () => {},
  showToggeSelectButton: true
};

export default RequestBookingDetails;
