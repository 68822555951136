import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { TextView, TextTextView } from '../Texts';
import { getFormatedDate, capitalize } from '../../utils/functions';
import colors from '../../styles/colors';
import CharlyIcon from '../Icons/Icon';

const View = styled.View``;
const RequestDatesView = styled.View`
  padding: 10px 20px 10px 20px;
`;

const RequestDetails = ({ currentState, startDate, endDate, bookingDates }) => {
  const [t] = useTranslation();

  return (
    <View>
      <TextView
        paddings="15px 15px 5px 15px"
        borderTopWidth="1px"
        borderBottomWidth="1px"
        backgroundColor={colors.backgroundColor}
        text={t('request')}
        textColor={colors.grey02}
        fontSize="14px"
      />

      <RequestDatesView>
        <TextTextView
          paddings="10px 0px 10px 0px"
          firstText={capitalize(getFormatedDate(startDate, 'dddd'))}
          firstTextFontSize="20px"
          firstTextFontFamily="Martel"
          secondText={`${getFormatedDate(startDate, 'HH:mm')} - ${getFormatedDate(
            endDate,
            'HH:mm'
          )} `}
          secondTextFontSize="16px"
          secondTextFontFamily="Martel"
        />

        {bookingDates.map(({ id, start_date }) => (
          <TextView
            key={id}
            paddings="10px 0px 10px 0px"
            borderTopWidth="1px"
            justifyContent="space-between"
            text={getFormatedDate(start_date, 'LL')}>
            <CharlyIcon
              width="30px"
              height="30px"
              name={currentState === 'canceled' || currentState === 'declined' ? 'close' : 'check'}
              color={colors.secondary}
            />
          </TextView>
        ))}
      </RequestDatesView>
    </View>
  );
};

RequestDetails.propTypes = {
  currentState: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  bookingDates: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, start_date: PropTypes.string })
  ).isRequired
};

export default RequestDetails;
