import React, { useState, useEffect } from 'react';
import { Linking } from 'react-native';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getSupport } from '../../stores/actions/supportAction';
import { Text, Divider } from '../../elements';
import {
  TextButton,
  IconButton,
  SupportTopic,
  SupportItem,
  SupportLoader,
  Header
} from '../../components';
import colors from '../../styles/colors';

const SafeAreaView = styled.SafeAreaView`
  justify-content: space-between;
`;
const ScrollView = styled.ScrollView``;
const VerticalScroll = styled.ScrollView`
  padding-bottom: 50px;
`;
const ItemsContainer = styled.ScrollView`
  padding-right: 20px;
`;

const Overview = ({ history, navigation, Support, getSupportData }) => {
  const [topicIndex, setTopicIndex] = useState(0);
  const [t] = useTranslation();
  const { topics, fetching } = Support;

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize={18} fontWeight="600">
          {t('support')}
        </Text>
      ),
      headerRight: (
        <TextButton
          height={30}
          text={t('help')}
          fontWeight="normal"
          textColor={colors.secondary}
          onPress={() => Linking.openURL('whatsapp://send?phone=+31202102323')}
        />
      ),
      headerLeft: (
        <IconButton
          margins="0px 0px 0px 10px"
          iconSize={20}
          iconName="close"
          iconColor={colors.secondary}
          onPress={() => navigation.goBack()}
        />
      )
    });
  }

  useEffect(() => {
    getSupportData();
  }, []);

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          left={
            <IconButton
              margins="0px 0px 0px 10px"
              iconSize="20px"
              iconName="close"
              iconColor={colors.secondary}
              onPress={history.goBack}
            />
          }
          title={t('support')}
          right={
            <TextButton
              height="30px"
              text={t('help')}
              fontWeight="normal"
              textColor={colors.secondary}
              onPress={() => Linking.openURL('whatsapp://send?phone=+31202102323')}
            />
          }
        />
      )}
      {fetching ? (
        <SupportLoader />
      ) : (
        <VerticalScroll>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            {topics.map((item, index) => (
              <SupportTopic
                key={item.title}
                title={item.title}
                onPress={() => setTopicIndex(index)}
                selected={topicIndex === index}
              />
            ))}
          </ScrollView>

          <Divider />

          <ItemsContainer>
            <Text
              margins="15px"
              fontSize="16px"
              fontWeight={600}
              onPress={() => Linking.openURL('tel:+31202102323')}>
              {t('supportTitle')}
            </Text>

            {topics[topicIndex] &&
              topics[topicIndex].items.map((item) => <SupportItem key={item.id} item={item} />)}
          </ItemsContainer>
        </VerticalScroll>
      )}
    </SafeAreaView>
  );
};

Overview.propTypes = {
  history: PropTypes.shape({ goBack: PropTypes.func }),
  navigation: PropTypes.shape({ goBack: PropTypes.func, setOptions: PropTypes.func }),
  getSupportData: PropTypes.func.isRequired,
  Support: PropTypes.shape({
    fetching: PropTypes.bool,
    topics: PropTypes.arrayOf(
      PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })) })
    )
  }).isRequired
};

Overview.defaultProps = {
  history: null,
  navigation: null
};

const mapStateToProps = ({ Support }) => ({
  Support
});

const mapDispatchToProps = {
  getSupportData: getSupport
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
