import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { Button } from '../../elements';
import { TextView } from '../Texts';
import AngelAvatar from './AngelAvatar';
import { TextIconButton } from '../Buttons';
import colors from '../../styles/colors';

import { IcnNavRight } from '../Icons';

const ButtonsView = styled.View`
  flex-direction: row;
  align-items: center;
`;
const View = styled.View`
  flex-direction: row;
  align-items: center;
`;

const AngelAvatarCard = ({
  angelImage,
  angelName,
  angelAge,
  messageDisabled,
  callDisabled,
  bookingDisabled,
  calendarDisabled,
  onMessagePress,
  onCallPress,
  onBookingPress,
  ...buttonProps
}) => {
  const [t] = useTranslation();

  return (
    <Button
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      height="auto"
      {...buttonProps}>
      <View>
        <AngelAvatar size="80px" source={angelImage} />

        <TextView
          margins="0px 0px 0px 10px"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="space-between"
          text={`${angelName} (${angelAge})`}
          fontFamily="Martel"
          fontSize="16px"
          fontWeight="600">
          <ButtonsView>
            <TextIconButton
              disabled={messageDisabled}
              margins="0px 20px 0px 0px"
              paddings="0px"
              flexDirection="column-reverse"
              text={t('message')}
              fontSize="12px"
              textColor={messageDisabled ? colors.grey05 : colors.secondary}
              iconName="icn-chat"
              iconColor={messageDisabled ? colors.grey05 : colors.secondary}
              iconSize="32px"
              onPress={onMessagePress}
            />

            <TextIconButton
              disabled={callDisabled}
              margins="0px 20px 0px 0px"
              paddings="0px"
              flexDirection="column-reverse"
              text={t('call')}
              fontSize="12px"
              textColor={callDisabled ? colors.grey05 : colors.secondary}
              iconName="icn-telephone"
              iconColor={callDisabled ? colors.grey05 : colors.secondary}
              iconSize="32px"
              onPress={onCallPress}
            />

            {!calendarDisabled && (
              <TextIconButton
                margins="0px 0px 0px 0px"
                paddings="0px"
                flexDirection="column-reverse"
                text={t('booking')}
                fontSize="12px"
                textColor={colors.secondary}
                iconName="icn-calendar"
                iconColor={colors.secondary}
                iconSize="32px"
                onPress={onBookingPress}
              />
            )}
          </ButtonsView>
        </TextView>
      </View>

      <IcnNavRight size="32px" color={colors.grey03} />
    </Button>
  );
};

AngelAvatarCard.propTypes = {
  angelImage: PropTypes.string,
  angelName: PropTypes.string.isRequired,
  angelAge: PropTypes.number.isRequired,
  messageDisabled: PropTypes.bool,
  callDisabled: PropTypes.bool,
  bookingDisabled: PropTypes.bool,
  calendarDisabled: PropTypes.bool,
  onMessagePress: PropTypes.func.isRequired,
  onCallPress: PropTypes.func.isRequired,
  onBookingPress: PropTypes.func.isRequired
};

AngelAvatarCard.defaultProps = {
  angelImage: null,
  messageDisabled: false,
  callDisabled: false,
  bookingDisabled: false,
  calendarDisabled: true
};

export default AngelAvatarCard;
