import React from 'react'
import { Card, CharlyIcon, ScreenLayout } from '@charlycares/ui'
import { Box, Center, Divider, Heading, HStack, Image, Stack, Text } from 'native-base'
import {
  BookingServiceType,
  calculateBikeTime,
  filterUserLanguages,
  getDistance,
  isWeb,
  makeCall,
  useRouter
} from '@charlycares/shared'
import { useGetFamilyDetailsQuery, useGetUserRatingsQuery } from '@charlycares/data-access'
import { useTranslation } from 'react-i18next'

import { AddressDetails } from '../../profile'
import RatingsOverview from '../../reviews/common/RatingsOverview'
import moment from 'moment'

const FamilyDetailsScreen = () => {
  const { getParam, navigate } = useRouter()
  const { t } = useTranslation()
  const familyObscuredId = getParam('familyId') as string
  const bookingServiceType = getParam('bookingServiceType') as BookingServiceType | undefined

  const { data: family } = useGetFamilyDetailsQuery({ familyObscuredId }, { skip: !familyObscuredId })
  const { data: ratings } = useGetUserRatingsQuery(
    { user_id: family?.family_user_id },
    { skip: !family?.family_user_id }
  )
  const isPetCare = bookingServiceType === BookingServiceType.PET_CARE
  const isElderlyCare = bookingServiceType === BookingServiceType.ELDERLY_CARE

  if (!family) {
    return <ScreenLayout isLoading />
  }

  return (
    <ScreenLayout
      headerRight={
        <HStack space={isWeb ? '15px' : '10px'} alignItems="center" mt="5px">
          <CharlyIcon
            onPress={() => navigate('ChatScreen', '/chat', { conversationId: family?.conversation_id })}
            disabled={!family.conversation_id}
            name="icn-chat"
            size={30}
            color={family.conversation_id ? 'primary.400' : 'gray.400'}
          />

          <CharlyIcon
            disabled={!family.phone}
            onPress={() => family.phone && makeCall(family.phone)}
            name="icn-telephone-fill"
            size={30}
            color={family.phone ? 'primary.400' : 'gray.400'}
          />

          <CharlyIcon
            onPress={() => navigate('BookDirect', '/book-direct', { familyId: family.obscured_id })}
            name="icn-calendar"
            size={30}
            color="primary.400"
          />
        </HStack>
      }
    >
      <Card p="0">
        {!!family.image && <Image h="250px" w="full" source={{ uri: family.image }} />}
        <Box p="20px">
          <Heading fontSize={'18px'}>{`${t('family')} ${family.name}`}</Heading>
          <Text mt="5px" fontSize={'15px'}>
            {family.short_bio.trim()}
          </Text>

          <Divider my="20px" />

          <HStack
            justifyContent={isPetCare || isElderlyCare ? 'flex-start' : 'space-evenly'}
            alignItems="center"
            space={isPetCare || isElderlyCare ? '40px' : '0'}
            w="100%"
          >
            <Center>
              <CharlyIcon name="icn-location" size={30} color="gray.900" />
              <Text fontSize="14px" mt="5px">
                {getDistance(family.distance)}
              </Text>
            </Center>
            <Center>
              <CharlyIcon name="icn-cycling" size={30} color="gray.900" />
              <Text fontSize="14px" mt="5px">
                {calculateBikeTime(family.distance)}
              </Text>
            </Center>
            {!isPetCare && !isElderlyCare && (
              <Center>
                <CharlyIcon name="icn-kids" size={30} color="gray.900" />
                <Text fontSize="14px" mt="5px">
                  {family.child_count}
                </Text>
              </Center>
            )}
            {!isPetCare && (
              <>
                <Center opacity={family.has_dog ? 1 : 0.5}>
                  <CharlyIcon name="icn-dog" size={30} color="gray.900" />
                  <Text fontSize="14px" mt="5px">
                    {family.has_dog ? t('yes') : t('no')}
                  </Text>
                </Center>
                <Center opacity={family.has_cat ? 1 : 0.5}>
                  <CharlyIcon name="icn-cat" size={30} color="gray.900" />
                  <Text fontSize="14px" mt="5px">
                    {family.has_cat ? t('yes') : t('no')}
                  </Text>
                </Center>
              </>
            )}
          </HStack>
        </Box>
      </Card>

      <AddressDetails
        distance={family.distance}
        lat={family.lat}
        lon={family.lon}
        street_name={family.street_name}
        street_number={family.street_number}
        city={family.city}
      />

      <Card>
        <Heading fontSize={18}>{t('languages')}</Heading>
        <Text mt="5px" color="gray.700" fontSize={12}>
          {t('languagesSubTitle')}
        </Text>
        <Text fontSize="15px" textTransform="capitalize" mt="15px">
          {filterUserLanguages(family.languages)
            .map(lng => t(lng))
            .join(', ')}
        </Text>
      </Card>
      {!isPetCare && !isElderlyCare && (
        <Card>
          <Heading fontSize={18}>{t('kids')}</Heading>
          <Text mt="5px" color="gray.700" fontSize={12}>
            {t('kidsSubTitle')}
          </Text>
          <Stack space={'20px'} mt="15px">
            {family.kids_birthdays
              ? family.kids_birthdays.map((birthday, index) => {
                  const ageInMonths = moment().diff(birthday, 'months')

                  return (
                    <HStack key={index} space="10px" alignItems="center">
                      <CharlyIcon name="icn-kids" size={35} color="gray.900" />
                      <Text fontSize="15px">
                        {ageInMonths <= 24
                          ? `${ageInMonths} ${t('months')}`
                          : `${(ageInMonths / 12).toFixed()} ${t('years')}`}
                      </Text>
                    </HStack>
                  )
                })
              : family.kids.map(age => (
                  <HStack key={age} space="10px" alignItems="center">
                    <CharlyIcon name="icn-kids" size={35} color="gray.900" />
                    <Text fontSize="15px">{`${age} ${t('years')}`}</Text>
                  </HStack>
                ))}
          </Stack>
        </Card>
      )}
      {!!ratings?.category_ratings.length && (
        <Card>
          <RatingsOverview
            _title={{ fontSize: 'md' }}
            ratings={ratings.category_ratings}
            averageRating={ratings.average_rating}
            reviewsCount={ratings.ratings_count}
            title={t('angelAboutFamily', { family: family.name })}
          />
        </Card>
      )}
    </ScreenLayout>
  )
}

export default FamilyDetailsScreen

FamilyDetailsScreen.navigationOptions = {
  headerShown: false
}
