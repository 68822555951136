import React, { useState, useEffect } from 'react'
import { FlatList, ActivityIndicator, Linking } from 'react-native'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getRatings, getNextRatings } from '../../../stores/actions/ratingsAction'
import { Text, Divider } from '../../../elements'
import { TextButton, ReviewCard, Header, IconButton } from '../../../components'
import { formatRating } from '../../../utils/functions'
import colors from '../../../styles/colors'

import leftIcon from '../../../../assets/icons/icn-nav-left.png'

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
`

const Reviews = ({
  history,
  navigation,
  ratings,
  getRatingsData,
  user,
  fetching,
  getNextRatingsData
}) => {
  const [page, setPage] = useState(1)
  const [t] = useTranslation()
  const { angel } = user || {}
  const { average_rating } = angel || {}

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('profileScreensRatingAndReviews')}
        </Text>
      ),
      headerRight: average_rating && (
        <TextButton
          height="30px"
          paddings="0px 10px 0px 0px"
          margins="0px 10px 0px 0px"
          text={`Rating: ${formatRating(average_rating)}`}
          fontWeight="normal"
          textColor={colors.secondary}
          onPress={() => Linking.openURL('https://support.charlycares.com')}
        />
      )
    })
  }

  useEffect(() => {
    if (!ratings) {
      getRatingsData()
    }
  }, [])

  const refreshRatings = () => {
    setPage(1)
    getRatingsData()
  }

  if (fetching && (!ratings || (ratings && ratings.data === 0))) {
    return <ActivityIndicator />
  }
  const { data, current_page, next_page_url } = ratings

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          title={t('profileScreensRatingAndReviews')}
          right={
            average_rating && (
              <TextButton
                height="30px"
                paddings="0px 10px 0px 0px"
                margins="0px 10px 0px 0px"
                text={`Rating: ${formatRating(average_rating)}`}
                fontWeight="normal"
                textColor={colors.secondary}
                onPress={() => history.push('/support')}
              />
            )
          }
          left={
            <IconButton
              iconSource={leftIcon}
              iconSize="32px"
              iconColor={colors.secondary}
              onPress={history.goBack}
            />
          }
        />
      )}
      <Divider />

      {ratings && data.length ? (
        <FlatList
          initialNumToRender={10}
          data={data}
          renderItem={({ item }) => {
            const { family, start_date, rating, comments, created_at } = item

            return (
              <ReviewCard
                image={family ? family.image : null}
                startDate={start_date || created_at}
                rating={rating}
                comments={comments}
                onCardPress={() => {
                  if (navigation) {
                    navigation.navigate('ProfileReviewDetails', { review: item })
                  } else {
                    history.push({ pathname: '/profile-reviews-details', state: { review: item } })
                  }
                }}
              />
            )
          }}
          onEndReachedThreshold={0.9}
          onEndReached={() => {
            if (current_page === page && next_page_url) {
              setPage(page + 1)
              getNextRatingsData(next_page_url)
            }
          }}
          refreshing={fetching}
          onRefresh={refreshRatings}
          keyExtractor={({ id }) => id.toString()}
        />
      ) : (
        <Text paddings="20px" textAlign="center">
          {t('profileScreensRatingAndReviewsNoReviews')}
        </Text>
      )}
    </SafeAreaView>
  )
}

Reviews.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func, goBack: PropTypes.func }),
  navigation: PropTypes.shape({ navigate: PropTypes.func, setOptions: PropTypes.func }),
  ratings: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    current_page: PropTypes.number,
    next_page_url: PropTypes.string
  }),
  fetching: PropTypes.bool,
  getRatingsData: PropTypes.func.isRequired,
  getNextRatingsData: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired
}

Reviews.defaultProps = {
  history: null,
  navigation: null,
  ratings: null,
  fetching: true
}

const mapStateToProps = ({ Ratings, User }) => ({
  ratings: Ratings.ratings,
  fetching: Ratings.fetching,
  user: User.user
})

const mapDispatchToProps = {
  getRatingsData: getRatings,
  getNextRatingsData: getNextRatings
}

export default connect(mapStateToProps, mapDispatchToProps)(Reviews)
