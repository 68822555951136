import React from 'react'
import { Box, Button, Heading } from 'native-base'
import { useTranslation } from 'react-i18next'
import { LandingLayout } from '@charlycares/ui'
import { MembershipsName, useRouter } from '@charlycares/shared'
import { FeaturesWeOffer } from '../common'
import { GetAvailableMembershipsResponse } from '@charlycares/data-access'
import moment from 'moment/moment'

export default function WhatWeOfferMembershipScreen() {
  const { navigate, getParam } = useRouter()
  const { t } = useTranslation()

  const availableMemberships = getParam('availableMemberships') as GetAvailableMembershipsResponse

  return (
    <LandingLayout
      withGoBack
      withLogo
      BottomComponent={
        <Box w="100%" p="20px" mt="auto">
          <Button
            onPress={() => navigate('ActivateTrialMembershipInfoScreen', '/activate-trial-membership-info')}
            bgColor="white"
            _text={{ color: 'secondary.400', fontSize: '16px', fontWeight: 600 }}
          >
            {t('whatWeOfferMembershipScreen.next')}
          </Button>
        </Box>
      }
    >
      <Box w="100%" px="20px" mt="50px">
        <Heading color="primary.300" fontSize="22px" fontWeight="600">
          {t('whatWeOfferMembershipScreen.heading')}
        </Heading>
      </Box>

      <FeaturesWeOffer availableMemberships={availableMemberships} />
    </LandingLayout>
  )
}

WhatWeOfferMembershipScreen.navigationOptions = () => ({
  headerShown: false
})
