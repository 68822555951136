import styled from 'styled-components/native'
import theme from '../styles/theme'

const ContainerView = styled.View`
  ${({ flex }) => flex && { flex }};
  ${({ zIndex }) => zIndex && { 'z-index': zIndex }}
  position: ${({ position }) => position || 'relative'};
  ${({ top }) => top && { top }};
  ${({ bottom }) => bottom && { bottom }};
  ${({ right }) => right && { right }};
  ${({ left }) => left && { left }};
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  min-width: ${({ minWidth }) => minWidth || 'auto'};
  min-height: ${({ minHeight }) => minHeight || 'auto'};
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
  margin: ${({ margins }) => margins || '0px'};
  padding: ${({ paddings }) => paddings || '0px'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  align-self: ${({ alignSelf }) => alignSelf};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  opacity: ${({ opacity }) => opacity || '1'};
  border-right-width: ${({ borderRightWidth }) => borderRightWidth || '0px'};
  border-left-width: ${({ borderLeftWidth }) => borderLeftWidth || '0px'};
  border-top-width: ${({ borderTopWidth }) => borderTopWidth || '0px'};
  border-bottom-width: ${({ borderBottomWidth }) => borderBottomWidth || '0px'};
  border-color: ${({ borderColor }) => borderColor || theme.dividerLineColor};
  border-radius: ${({ borderRadius }) => borderRadius || '0px'};
  ${({ transform }) => transform && { transform }};
`

export default ContainerView
