import AsyncStorage from '@react-native-async-storage/async-storage'

import { isWeb, StorageKeys } from '../const'
import config from 'react-native-config'

export enum Environment {
  Production = 'Production',
  Acceptance = 'Acceptance'
}

export const getBaseUrl = async (): Promise<string> => {
  const defaultUrl = (isWeb ? process.env.NX_API_URL : config.NX_API_URL) || 'https://api.charlycares.com/api/'

  try {
    const baseUrl = await AsyncStorage.getItem(StorageKeys.BASE_URL)

    return baseUrl || defaultUrl
  } catch (error) {
    return defaultUrl
  }
}

interface ApiUrl {
  name: string
  url: string
}

export const getEndpointEnvironment = async (): Promise<{ name: Environment; apiUrls: ApiUrl[] }> => {
  const apiUrls: ApiUrl[] = JSON.parse(config.NX_API_URLS)
  const baseUrl = await getBaseUrl()
  const currentEnv = apiUrls.find(url => url.url === baseUrl)
  const currentEnvName =
    Environment[currentEnv?.name as keyof typeof Environment] || currentEnv?.name || Environment.Production

  return {
    name: currentEnvName,
    apiUrls: apiUrls
  }
}
