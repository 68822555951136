import React from 'react'
import { Edge, SafeAreaView } from 'react-native-safe-area-context'
import { Box, Button, Factory, IBoxProps, IButtonProps, ITextProps, Spinner, Text } from 'native-base'
import { links } from '@charlycares/shared'
import { useTranslation } from 'react-i18next'
import { Linking, ScrollViewProps } from 'react-native'

import Header from './Header'
import { PlatformScrollView } from '../platform'
import { ProgressBar } from '../feedback'

const SafeAreaContainer = Factory(SafeAreaView)

type LayoutProps = IBoxProps & {
  children?: React.ReactNode
  title?: string
  subtitle?: string
  disableScroll?: boolean
  progress?: string
  headerRight?: React.ReactNode
  headerLeft?: React.ReactNode
  hideBackButton?: boolean
  hideHeader?: boolean
  scrollRef?: React.LegacyRef<PlatformScrollView>
  edges?: Edge[]
  supportButton?: boolean
  safeAreaBg?: string
  _buttonProps?: IButtonProps
  _bottomContainer?: IBoxProps
  _buttonDisclaimer?: ITextProps
  _scroll?: ScrollViewProps
  _header?: IBoxProps
  isLoading?: boolean
  BottomComponent?: React.ReactNode
  _bottomViewKeyboardAware?: boolean
  blueBackground?: boolean
  customHeader?: React.ReactNode
}

export default function ScreenLayout({
  children,
  title,
  subtitle,
  disableScroll,
  headerRight,
  headerLeft,
  hideBackButton,
  hideHeader,
  scrollRef,
  supportButton,
  edges,
  safeAreaBg,
  _buttonProps,
  _buttonDisclaimer,
  _bottomContainer,
  _scroll,
  _header,
  isLoading,
  BottomComponent,
  _bottomViewKeyboardAware,
  progress,
  blueBackground,
  customHeader,
  ...boxProps
}: LayoutProps) {
  const { t } = useTranslation()
  const safeAreaEdges: Edge[] = edges || ['right', 'bottom', 'left']

  if (hideHeader && !edges) {
    safeAreaEdges.push('top')
  }

  if (supportButton) {
    headerRight = (
      <Button
        variant="text"
        _text={{ color: blueBackground ? 'white' : 'primary.400' }}
        onPress={() => Linking.openURL(links.support)}
      >
        {t('support')}
      </Button>
    )
  }
  const showSearch = true

  if (progress && subtitle === undefined) {
    subtitle = `${progress} ${t('complete')}`
  }

  return (
    <Box w="100%" minH="100%" flex={1}>
      {!hideHeader && (
        <Header
          title={title}
          subtitle={subtitle}
          headerRight={headerRight ? () => headerRight : undefined}
          headerLeft={headerLeft ? () => headerLeft : undefined}
          hideBack={hideBackButton}
          backgroundColor={blueBackground ? 'secondary.400' : undefined}
          textColor={blueBackground ? 'white' : undefined}
          customHeader={customHeader ?? undefined}
          {..._header}
        />
      )}
      {!!progress && (
        <Box w="100%" bg="gray.200" height="10px">
          <ProgressBar progress={progress} barColor="secondary.400" barHeight="100%" />
        </Box>
      )}

      <SafeAreaContainer bg={safeAreaBg || 'bg.light.400'} flex={1} edges={safeAreaEdges}>
        <Box flex={1} {...boxProps}>
          {isLoading ? (
            <Spinner color="secondary.500" mt="40px" />
          ) : (
            <>
              {disableScroll ? (
                children
              ) : (
                <PlatformScrollView ref={scrollRef} {..._scroll}>
                  {children}
                </PlatformScrollView>
              )}

              {BottomComponent}

              {Boolean(_buttonProps) && (
                <Box
                  w="100%"
                  flexDir="column"
                  alignItems="center"
                  justifyContent="space-around"
                  borderTopWidth={1}
                  borderColor="gray.400"
                  py="11px"
                  px="20px"
                  {..._bottomContainer}
                >
                  {_buttonDisclaimer && (
                    <Text marginBottom="10px" fontSize="12px" color="gray.800" {..._buttonDisclaimer} />
                  )}
                  {_buttonProps && <Button w="100%" {..._buttonProps} />}
                </Box>
              )}
            </>
          )}
        </Box>
      </SafeAreaContainer>
    </Box>
  )
}
