import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { Text, Icon } from '../../elements';
import { TextButton } from '../Buttons';
import colors from '../../styles/colors';

const ListHeaderView = styled.View`
  width: 100%;
  height: 92px;
  background-color: ${colors.greenLight};
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 20px 15px 20px;
  margin: 0px 0px 5px 0px;
`;
const ListHeaderIconTextContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;
const ListHeaderTextIconTextContainer = styled.View`
  flex-direction: column;
  width: 60%;
`;
const ListHeaderTextButtonContainer = styled.View`
  flex-direction: column;
`;

const PaymentsOverviewListHeader = ({ toInvitePress, amount, icon }) => {
  const [t] = useTranslation();

  return (
    <ListHeaderView>
      <ListHeaderTextIconTextContainer>
        <Text fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('paymentsScreenBabysittingCredit')}
        </Text>

        <ListHeaderIconTextContainer>
          <Icon size="32px" source={icon} color={colors.grey01} />

          <Text
            flex={1}
            margins="0px 0px 0px 10px"
            fontSize="13px"
            color={colors.primaryText}
            numberOfLines={2}>
            {t('paymentsScreenEarnCreditsByInvitingFriends')}
          </Text>
        </ListHeaderIconTextContainer>
      </ListHeaderTextIconTextContainer>

      <ListHeaderTextButtonContainer>
        <Text
          margins="0px 0px 10px 0px"
          fontSize="24px"
          textAlign="right"
          color={colors.primaryText}>
          {`€ ${amount}`}
        </Text>

        <TextButton
          padding="2px 10px 2px 10px"
          height="24px"
          backgroundColor={colors.secondary}
          text={t('toInvite')}
          fontSize="13px"
          fontWeight="600"
          onPress={toInvitePress}
        />
      </ListHeaderTextButtonContainer>
    </ListHeaderView>
  );
};

PaymentsOverviewListHeader.propTypes = {
  toInvitePress: PropTypes.func.isRequired,
  amount: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired
};

export default PaymentsOverviewListHeader;
