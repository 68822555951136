import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import styled from 'styled-components/native'

const Wrapper = styled.View`
  ${({ margins }) => margins && { margin: margins }};
`

const PickerWeb = ({
  width,
  height,
  isMulti,
  options,
  value,
  onValueChange,
  margins,
  ...selectProps
}) => {
  return (
    <Wrapper margins={margins}>
      <Select
        styles={{
          menu: provided => ({ ...provided, zIndex: 999 }),
          control: provided => ({
            ...provided,
            width,
            height,
            borderWidth: 0,
            borderBottomWidth: '1px',
            borderRadius: 0
          })
        }}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        closeMenuOnSelect={!isMulti}
        isMulti={isMulti}
        options={options}
        value={value}
        onChange={onValueChange}
        {...selectProps}
      />
    </Wrapper>
  )
}

PickerWeb.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margins: PropTypes.string,
  isMulti: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
      PropTypes.string
    ])
  ).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
    PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }))
  ]).isRequired,
  onValueChange: PropTypes.func.isRequired
}

PickerWeb.defaultProps = {
  isMulti: false,
  margins: '0px',
  width: '200px',
  height: 'auto'
}

export default PickerWeb
