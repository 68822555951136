import { Bubble } from 'react-native-gifted-chat';
import styled from 'styled-components/native';
import theme from '../../styles/theme';

const textStyle = {
  fontFamily: theme.fontFamilyBase,
  fontSize: '15px',
  color: theme.colors.primaryText
};
const timeTextStyle = {
  fontFamily: theme.fontFamilyBase,
  fontSize: '12px',
  color: theme.colors.grey02
};

const StyledBubble = styled(Bubble).attrs(({ currentMessage }) => ({
  containerStyle: {
    left: {},
    right: {}
  },
  wrapperStyle: {
    left: {
      backgroundColor: theme.chatBubbleLeftBg,
      marginLeft: -35,
      padding: '4px',
      width: 'auto',
      minWidth: '30%'
    },
    right: {
      backgroundColor: theme.chatBubbleRightBg,
      padding: '4px',
      width: 'auto',
      minWidth: '30%'
    }
  },
  textStyle: {
    left: textStyle,
    right: textStyle
  },
  timeTextStyle: {
    left: timeTextStyle,
    right: timeTextStyle
  },
  tickStyle: {
    fontSize: '12px',
    fontWeight: 600,
    color: currentMessage.received ? theme.colors.notify : theme.colors.grey02
  }
}))``;

export default StyledBubble;
