import { useEffect, useState } from 'react'

type Props<T> = {
  queryData?: T[]
  meta: {
    from: number
    per_page: number
    current_page: number
  }
  next: boolean
}

// FIX: creates infinite loop
const useInfinityScroll = <T>({ queryData, next, meta }: Props<T>) => {
  const [data, setData] = useState<T[]>([])

  useEffect(() => {
    if (queryData && next) {
      setData(state => {
        const newData = [...state]

        const deleted = newData.splice(meta.from - 1, meta.per_page, ...queryData)

        // on refetch should remove all the items in the list and add first page
        if (meta.current_page === 1 && deleted?.length) {
          return [...queryData]
        }

        return newData
      })
    }
  }, [queryData, next, meta])

  return data
}

export default useInfinityScroll
