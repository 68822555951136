import { Angel } from './Angel'
import { Family } from './Family'
import { Profile } from './Profile'
import { Money } from './Money'
import { LandingScreen } from './LandingScreen'
import { ServiceGroupType } from './Booking'
export interface User {
  obscured_id: string
  id: number
  email: string
  role: UserRoles
  is_accepted: boolean
  locale: string
  has_active_timer: boolean
  has_pending_bookings: boolean
  has_unread_messages: boolean
  has_unread_announcements: boolean
  notification_settings: {
    promotional_email: boolean
    system_email: boolean
    promotional_push: boolean
    system_push: boolean
  }
  languages: [UserLanguages]
  profile: Profile
  angel?: Angel
  family?: Family
  outstanding_balance?: Money
  late_payment_link?: string
  current_landing_screen: LandingScreen
  service_group_types?: ServiceGroupType[]
  preferred_service_type: ServiceGroupType
  websocket_url: string
  websocket_key: string
  in_beta_projects: string[]
}

export enum UserRoles {
  FAMILY = 'family',
  ANGEL = 'angel'
}

export type UserLanguages = {
  dutch: boolean
  english: boolean
  french: boolean
  german: boolean
  spanish: boolean
  italian: boolean
}

export type UserBookingMetric = {
  completed_bookings: number
  cancelled_bookings: number
  total_bookings: number
  success_rate_percentage: number
}
