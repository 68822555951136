import React from 'react'
import PropTypes from 'prop-types'
import { ProtectedRoute } from '../components'

import {
  BookingCreatedScreen,
  SendBookingRequestScreen,
  CreateBookingScreen,
  JobPostScreen,
  RepeatingDaysScreen,
  DirectBookingScreen,
  MatchingListScreen,
  MatchingFiltersScreen,
  AngelDetailsScreen,
  BookingRequestTypesListScreen,
  BookingServiceTypeComingSoonScreen
} from '@charlycares/features'

const BookRoutes = user => [
  <ProtectedRoute key="book-create" path="/book-create" user={user} component={CreateBookingScreen} />,
  <ProtectedRoute key="book-send-request" path="/book-send-request" user={user} component={SendBookingRequestScreen} />,
  <ProtectedRoute
    key="book-send-requests"
    path="/book-send-requests"
    user={user}
    component={SendBookingRequestScreen}
  />,
  <ProtectedRoute key="job-post" path="/job-post" user={user} component={JobPostScreen} />,
  <ProtectedRoute key="book-request-sent" path="/book-request-sent" user={user} component={BookingCreatedScreen} />,
  <ProtectedRoute key="book-repeat-dates" path="/book-repeat-dates" user={user} component={RepeatingDaysScreen} />,
  <ProtectedRoute key="book-direct" path="/book-direct" user={user} component={DirectBookingScreen} />,
  <ProtectedRoute key="book-search-angels" path="/book-search-angels" user={user} component={MatchingListScreen} />,
  <ProtectedRoute key="book-angel-details" path="/book-angel-details" user={user} component={AngelDetailsScreen} />,
  <ProtectedRoute key="book-filters" path="/book-filters" user={user} component={MatchingFiltersScreen} />,
  <ProtectedRoute
    key="booking-request-types-list"
    path="/booking-request-types-list"
    user={user}
    component={BookingRequestTypesListScreen}
  />,
  <ProtectedRoute
    key="booking-service-type-coming-soon"
    path="/booking-service-type-coming-soon"
    user={user}
    component={BookingServiceTypeComingSoonScreen}
  />
]

BookRoutes.propTypes = {
  user: PropTypes.shape({})
}

BookRoutes.defaultProps = {
  user: null
}

export default BookRoutes
