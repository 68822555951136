import React from 'react'
import { Switch as RNSwitch, SwitchProps } from 'react-native'
import { useTheme } from 'native-base'

type Props = SwitchProps

const Switch = (props: Props) => {
  const { colors } = useTheme()

  return (
    <RNSwitch
      thumbColor={colors.white}
      trackColor={{
        false: colors.gray[200],
        true: colors.secondary[400]
      }}
      {...props}
    />
  )
}

export default Switch
