import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Card, CharlyIcon, Picker, ScreenLayout, Select } from '@charlycares/ui'
import { Button, Center, Heading, SectionList, Spinner, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import { BookingStates, IBookingDetails, isWeb, useRouter, UserRoles } from '@charlycares/shared'
import { useGetActiveTimerQuery, useGetUserIndicatorsQuery, useGetUserQuery } from '@charlycares/data-access'

import { BookingCard, useBookingsData, isBookingPending, ActiveBookingTimer } from '../bookings'
import { RunningBookingOverlay } from './family'
import { withNavigationFocus } from 'react-navigation'

const BookingsListScreen = ({ isFocused }: { isFocused: boolean }) => {
  const { t } = useTranslation()
  const { navigate } = useRouter()

  const filterPickerRef = useRef<any>()
  const sectionListRef = useRef<any>()

  const [page, setPage] = useState(1)
  const [status, setStatus] = useState<BookingStates>()

  const { data: user } = useGetUserQuery()
  const { bookings, meta, isFetching, isLoading, refetch, data } = useBookingsData({ page, status })

  const { isSuccess: activeTimerQuerySuccess } = useGetActiveTimerQuery()
  const { refetch: refetchIndicators } = useGetUserIndicatorsQuery()

  const onBookingPress = (booking: IBookingDetails) => {
    if (user?.role === UserRoles.FAMILY) {
      if (isBookingPending(booking)) {
        navigate('FamilyEditBooking', `/booking-edit`, { bookingId: booking.obscured_id })
      } else if (booking.angel) {
        navigate('FamilyBookingDetails', `/booking`, { bookingId: booking.obscured_id })
      }
    } else if (user?.role === UserRoles.ANGEL) {
      navigate('AngelBookingDetails', `angel/booking/${booking.obscured_id}`, { bookingId: booking.obscured_id })
    }
  }

  useEffect(() => {
    if (isFocused && !isLoading) {
      refetch()
      refetchIndicators()
    }
  }, [isFocused, isLoading])

  const getSectionHeader = (title: string) => {
    switch (title) {
      case 'pending':
        return t('bookingsLabelActionRequired')
      case 'accepted':
        return t('bookingsLabelUpcoming')
      case 'archived':
        return t('bookingsLabelArchive')
      default:
        return ''
    }
  }

  const filterItems = [
    {
      column: undefined,
      label: t('bookingFilterState_all')
    },
    {
      column: BookingStates.ACCEPTED,
      label: t('bookingFilterState_accepted')
    },
    {
      column: BookingStates.CANCELED,
      label: t('bookingFilterState_cancelled')
    },
    {
      column: BookingStates.DECLINED,
      label: t('bookingFilterState_declined')
    },
    {
      column: BookingStates.ENDED,
      label: t('bookingFilterState_ended')
    }
  ]

  const bookingSections = useMemo(() => {
    const isEmpty = !Object.values(bookings).filter(section => section.length).length
    return !isEmpty
      ? Object.keys(bookings).map(key => ({ title: key, data: bookings[key as keyof typeof bookings] }))
      : []
  }, [bookings])

  return (
    <ScreenLayout
      edges={['left', 'right']}
      supportButton
      _header={{ borderBottomWidth: 0 }}
      disableScroll
      hideBackButton
      title={t('bookings')}
    >
      <Card pt="0" pb="10px" mt="0" borderTopWidth={0} alignItems="flex-end" justifyContent="center">
        {isWeb ? (
          <Select
            _wrapper={{ flex: 1, w: '150px' }}
            selectedValue={filterItems.find(filter => filter.column === status)?.label as string}
            onValueChange={val => {
              setPage(1)
              setStatus(filterItems.find(filter => filter.label === val)?.column)
              sectionListRef.current?.scrollToLocation({ sectionIndex: 0, itemIndex: 0, animated: false })
            }}
            items={filterItems.map(item => t(item.label))}
          />
        ) : (
          <Button
            _text={{
              color: 'text.dark.400',
              fontSize: '15px'
            }}
            rightIcon={<CharlyIcon name="icn-nav-down" size={25} color="primary.500" />}
            variant="text"
            onPress={() => filterPickerRef.current?.open()}
          >
            {filterItems.find(filter => filter.column === status)?.label}
          </Button>
        )}
      </Card>

      <SectionList
        ref={sectionListRef}
        refreshing={isFetching}
        onRefresh={() => {
          setPage(1)
          refetch()
        }}
        ListHeaderComponent={activeTimerQuerySuccess && user ? <ActiveBookingTimer role={user.role} /> : undefined}
        onEndReachedThreshold={0.5}
        onEndReached={() => {
          if (meta?.last_page && page < meta?.last_page && !isFetching) {
            setPage(current => current + 1)
          }
        }}
        sections={bookingSections}
        keyExtractor={item => item.obscured_id}
        renderItem={({ item }) => <BookingCard booking={item} onPress={() => onBookingPress(item)} />}
        renderSectionHeader={({ section: { title } }) =>
          bookings[title as keyof typeof bookings]?.length ? (
            <Text m="15px" mb="5px" color="gray.800">
              {getSectionHeader(title)}
            </Text>
          ) : null
        }
        ListFooterComponent={
          isFetching && (isWeb || data?.data.length) ? (
            <Center p="20px">
              <Spinner />
            </Center>
          ) : undefined
        }
        ListEmptyComponent={
          !isLoading && !data?.data.length ? (
            <Center p="50px 20px">
              <Heading fontSize="16px" fontWeight="600">
                {t('emptyBookingsList')}
              </Heading>
              {user?.role === UserRoles.FAMILY && (
                <Button onPress={() => navigate('BookingRequestTypesList', '/booking-request-types-list')} mt="20px">
                  {t('makeYourFirstBooking')}
                </Button>
              )}
            </Center>
          ) : undefined
        }
      />

      {!isWeb && (
        <Picker
          ref={filterPickerRef}
          options={filterItems.map(item => t(item.label))}
          value={filterItems.find(filter => filter.column === status)?.label}
          onConfirmPress={(val: string) => {
            setPage(1)
            setStatus(filterItems.find(filter => filter.label === val)?.column)
            sectionListRef.current?.scrollToLocation({ sectionIndex: 0, itemIndex: 0, animated: false })
          }}
        />
      )}
    </ScreenLayout>
  )
}

const screen = withNavigationFocus(BookingsListScreen)
export default screen

// @ts-ignore
screen.navigationOptions = {
  headerShown: false
}
