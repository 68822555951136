import { BookingDate, formatDate } from '@charlycares/shared'
import React, { useMemo } from 'react'

export default function useBookingDates(booking_dates: BookingDate[]) {
  const data = useMemo(() => {
    const lastBooking = booking_dates[booking_dates?.length - 1] as BookingDate | undefined
    const days = booking_dates?.map(date => formatDate(date.start_date, { nl: 'dd', en: 'ddd' }))
    const startDate = booking_dates[0]?.start_date
    const firstBookingEndDate = booking_dates[0]?.end_date
    const lastBookingStartDate =
      lastBooking?.repeat_days[lastBooking?.repeat_days.length - 1]?.start_date || lastBooking?.start_date
    const bookingsCount =
      booking_dates.length + booking_dates.reduce((carry, date) => carry + date.repeat_days.length, 0)

    return {
      days,
      startDate,
      firstBookingEndDate,
      lastBookingStartDate,
      bookingsCount,
      lastBooking
    }
  }, [booking_dates])

  return data
}
