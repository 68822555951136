import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextView, TextIconView } from '../Texts';
import { IconButton } from '../Buttons';
import colors from '../../styles/colors';

const PetsEdit = ({
  paddings,
  borderBottomWidth,
  borderTopWidth,
  title,
  titleFontFamily,
  titleFontSize,
  titleFontWeight,
  titleColor,
  secondIconButtonMargins,
  dog,
  cat,
  toggleDogPress,
  toggleCatPress
}) => {
  const [t] = useTranslation();

  return (
    <TextView
      flexDirection="column"
      alignItems="flex-start"
      paddings={paddings}
      borderBottomWidth={borderBottomWidth}
      borderTopWidth={borderTopWidth}
      text={title}
      fontFamily={titleFontFamily}
      fontSize={titleFontSize}
      fontWeight={titleFontWeight}
      textColor={titleColor}>
      <IconButton
        flexDirection="row-reverse"
        justifyContent="space-between"
        margins="10px 0px 0px 0px"
        width="100%"
        iconName={dog ? 'btn-check' : 'btn-plus'}
        iconMargins="0px"
        iconSize="32px"
        iconColor={dog ? colors.primary : colors.defaultBtnBackgroundColor}
        onPress={toggleDogPress}>
        <TextIconView
          flexDirection="row-reverse"
          justifyContent="flex-start"
          backgroundColor="transparent"
          text={t('dog')}
          textMargins="0px 0px 0px 10px"
          textAlign="left"
          name="dog"
          iconSize="32px"
          iconColor={colors.primaryText}
        />
      </IconButton>

      <IconButton
        flexDirection="row-reverse"
        justifyContent="space-between"
        margins={secondIconButtonMargins}
        width="100%"
        iconName={cat ? 'btn-check' : 'btn-plus'}
        iconMargins="0px"
        iconSize="32px"
        iconColor={cat ? colors.primary : colors.defaultBtnBackgroundColor}
        onPress={toggleCatPress}>
        <TextIconView
          flexDirection="row-reverse"
          justifyContent="flex-start"
          backgroundColor="transparent"
          text={t('cat')}
          textMargins="0px 0px 0px 10px"
          textAlign="left"
          name="cat"
          iconSize="30px"
          iconColor={colors.primaryText}
        />
      </IconButton>
    </TextView>
  );
};

PetsEdit.propTypes = {
  paddings: PropTypes.string,
  borderBottomWidth: PropTypes.number,
  borderTopWidth: PropTypes.number,
  title: PropTypes.string.isRequired,
  titleFontFamily: PropTypes.string,
  titleFontSize: PropTypes.number,
  titleFontWeight: PropTypes.string,
  titleColor: PropTypes.string,
  dog: PropTypes.bool.isRequired,
  cat: PropTypes.bool.isRequired,
  secondIconButtonMargins: PropTypes.string,
  toggleDogPress: PropTypes.func.isRequired,
  toggleCatPress: PropTypes.func.isRequired
};

PetsEdit.defaultProps = {
  paddings: '20px',
  borderBottomWidth: '1px',
  borderTopWidth: '1px',
  titleFontFamily: 'Open Sans',
  titleFontSize: '12px',
  titleFontWeight: '300',
  titleColor: colors.grey02,
  secondIconButtonMargins: '20px 0px 10px 0px'
};

export default PetsEdit;
