import {
  LOGOUT,
  GET_PAYMENTS,
  GET_NEXT_PAYMENTS,
  CREATE_PDF,
  FETCHING_PAYMENTS,
  GET_OUTSTANDING_PAYMENTS,
  PAYMENT_METHODS
} from '../types';

const initialState = {
  paymentMethods: [],
  fetching: true,
  current_page: 1,
  next_page_url: null
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case LOGOUT:
      return initialState;
    case FETCHING_PAYMENTS:
      return {
        ...state,
        fetching: true
      };
    case PAYMENT_METHODS:
      return {
        paymentMethods: payload
      };
    case GET_PAYMENTS:
      return {
        ...payload,
        fetching: false
      };
    case GET_NEXT_PAYMENTS:
      return {
        ...payload,
        payments: {
          ...payload.payments,
          data: [...state.payments.data, ...payload.payments.data]
        },
        fetching: false
      };
    case CREATE_PDF:
      return {
        ...state,
        cretePdf: payload
      };
    case GET_OUTSTANDING_PAYMENTS:
      return {
        ...state,
        outstandingPayments: payload.outstanding_payments,
        outstandingAmount: payload.payment_sum,
        paymentLink: payload.payment_link
      };
    default:
      return state;
  }
}
