import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { getUserToken, getAppVersion, isImpersonated } from '@charlycares/shared'
import { Platform } from 'react-native'
import { ApiTags } from './types'
import { getBaseUrl } from '@charlycares/shared'

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  WebApi,
  extraOptions
) => {
  const baseUrl = await getBaseUrl()

  const rawBaseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async headers => {
      headers.set('Accept', 'application/json')

      const token = await getUserToken()
      const version = await getAppVersion()

      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
        if (!isImpersonated(token)) {
          headers.set('AppVersion', `${Platform.OS}-${version}`)
        }
      }

      return headers
    }
  })

  return rawBaseQuery(args, WebApi, extraOptions)
}

export const api = createApi({
  reducerPath: 'api',
  tagTypes: Object.values(ApiTags),
  baseQuery: dynamicBaseQuery,
  endpoints: () => ({})
})
