import React, { useRef } from 'react'
import {
  Box,
  IInputProps,
  FormControl,
  WarningOutlineIcon,
  Text,
  ITextProps,
  IBoxProps,
  Pressable,
  HStack,
  Flex,
  Spacer
} from 'native-base'
import { CharlyIcon, Picker } from '@charlycares/ui'
import { isWeb } from '@charlycares/shared'

type Props = IInputProps & {
  _wrapper?: IBoxProps
  _label?: ITextProps
  _helper?: ITextProps
  label?: string
  error?: string
  hideErrorMessage?: boolean
  onValueChange: (value: string) => void
  items: string[]
  selectedValue: string
}

const Select = React.forwardRef(
  (
    {
      label,
      error,
      _wrapper,
      value,
      isReadOnly,
      variant,
      _label,
      _helper,
      hideErrorMessage,
      onValueChange,
      items,
      selectedValue,
      ...inputProps
    }: Props,
    ref: any
  ) => {
    const pickerRef = useRef<any>()

    return (
      <Box w="100%" pb="10px" {..._wrapper}>
        <FormControl isInvalid={!!error}>
          {!!label && (
            <FormControl.Label>
              <Text fontSize={'14px'} color={error ? 'alert.danger.400' : 'gray.800'} {..._label}>
                {label}
              </Text>
            </FormControl.Label>
          )}

          {!isWeb && (
            <Pressable
              onPress={() => pickerRef.current?.open()}
              variant={'text'}
              borderBottomWidth="1px"
              borderColor="gray.500"
              height="48px"
            >
              <Flex pt="14px" direction="row">
                <Text fontSize="16px">{selectedValue}</Text>
                <Spacer />
                <CharlyIcon name="icn-nav-right" size={20} color="gray.400" pt="4px" />
              </Flex>
            </Pressable>
          )}

          {isWeb && (
            <Picker
              width="100%"
              height="48px"
              ref={pickerRef}
              value={{ value: selectedValue, label: selectedValue }}
              options={items.map(item => ({ label: item, value: item }))}
              onValueChange={({ value }: { value: string }) => onValueChange(value)}
            />
          )}
          {!isWeb && (
            <Picker
              ref={pickerRef}
              value={selectedValue}
              options={items}
              onConfirmPress={(exp: string) => onValueChange(exp)}
            />
          )}

          {!hideErrorMessage && (
            <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
              <Text fontSize={'14px'} color={'alert.danger.400'} {..._helper}>
                {error}
              </Text>
            </FormControl.ErrorMessage>
          )}
        </FormControl>
      </Box>
    )
  }
)

export default Select
