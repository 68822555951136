import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { Text, Divider } from '../../elements';
import { TextButton } from '../Buttons';
import colors from '../../styles/colors';

const View = styled.View`
  padding: 20px;
  height: 200px;
`;

const NoBookings = ({ navigateToPreferences, navigateToSettings }) => {
  const [t] = useTranslation();

  return (
    <>
      <Divider />
      <View>
        <Text color={colors.primaryText} fontSize="18px" fontFamily="Martel">
          {t('bookingsScreenAngelNoBookings')}
        </Text>

        <Text color={colors.primaryText} fontSize="15px" margins="10px 0px 0px 0px">
          {t('bookingsScreenAngelNoBookingsDesc')}
        </Text>

        <TextButton
          margins="25px 0px 10px 0px"
          width="100%"
          borderRadius="20px"
          backgroundColor={colors.primary}
          text={t('bookingsScreenAngelNoBookingsBtnPreferences')}
          fontSize="18px"
          onPress={navigateToPreferences}
        />

        <TextButton
          margins="10px 0px 10px 0px"
          width="100%"
          backgroundColor={colors.white}
          borderRightWidth="1px"
          borderLeftWidth="1px"
          borderRadius="20px"
          borderColor={colors.primary}
          text={t('bookingsScreenAngelNoBookingsBtnSettings')}
          fontSize="18px"
          textColor={colors.primary}
          onPress={navigateToSettings}
        />
      </View>
    </>
  );
};

NoBookings.propTypes = {
  navigateToPreferences: PropTypes.func.isRequired,
  navigateToSettings: PropTypes.func.isRequired
};

export default NoBookings;
