import React, { useState } from 'react'
import { Card, CharlyIcon, ScreenLayout, TextInput } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { flattenSearchBookingDates, links, useRouter, AngelSummery, BookingServiceType } from '@charlycares/shared'
import { Linking } from 'react-native'
import { Box, Button, Heading, HStack, Text } from 'native-base'

import { BookingDatesList, JobBoardOptions, SelectedAngelsList, useHandleCreateBooking } from '../common'

export default function SendBookingRequestScreen({ navigation }: any) {
  const { t } = useTranslation()
  const { getParam } = useRouter()

  const selectedAngels: AngelSummery[] = getParam('selectedAngels', [])
  const bookingDates = getParam('dates', [])
  const bookingServiceType = getParam('bookingServiceType') as BookingServiceType | undefined

  const [personalMessage, setPersonalMessage] = useState('')
  const [jobBoard, setJobBoard] = useState(true)
  const [maxHourlyRate, setMaxHourlyRate] = useState<number>(1000)

  const { createBooking, isLoading } = useHandleCreateBooking({
    navigation,
    jobboard: selectedAngels.length === 0
  })

  const onSubmit = () => {
    createBooking({
      dates: flattenSearchBookingDates(bookingDates),
      selected_angels: selectedAngels.map((angel, i) => ({
        angel_user_id: angel.angel_user_id,
        rank: angel.rank || i + 1
      })),
      job_board: jobBoard,
      personal_message: personalMessage,
      max_hourly_rate: {
        amount: maxHourlyRate,
        currency: 'EUR'
      },
      travel_allowance: {
        amount: 0,
        currency: 'EUR'
      },
      service_type: bookingServiceType
    })
  }

  return (
    <ScreenLayout
      _buttonProps={{
        onPress: onSubmit,
        children: t('sendRequest'),
        isLoading: isLoading,
        isDisabled: !personalMessage
      }}
      title={t('bookScreensBookingsRequest')}
      headerRight={
        <Button variant="text" onPress={() => Linking.openURL(links.support)}>
          {t('support')}
        </Button>
      }
    >
      <SelectedAngelsList data={selectedAngels} />

      <BookingDatesList bookingDates={bookingDates} />

      <Card mt="10px">
        <Box w="100%" flexDir="row" justifyContent="space-between" alignItems="center">
          <Heading fontSize="14px" fontWeight={400}>
            {t('bookingPersonalMessageLabel')}
          </Heading>
          <HStack space="5px" alignItems="center">
            <Text fontSize="12px" color="primary.400">
              {t('required')}
            </Text>
            <CharlyIcon name="icn-edit" size={20} color={'primary.400'} />
          </HStack>
        </Box>

        <TextInput
          placeholder={
            bookingServiceType && bookingServiceType === BookingServiceType.MEET_AND_GREET
              ? t('bookingPersonalMessagePlaceholderMeetAndGreet')
              : t('bookingPersonalMessagePlaceholder')
          }
          onChangeText={setPersonalMessage}
          value={personalMessage}
          maxLength={500}
          mt="15px"
          h="150px"
          variant="filled"
          multiline
          numberOfLines={5}
          _focus={{
            _important: { borderWidth: 0 }
          }}
          _hover={{
            _important: { borderWidth: 0 }
          }}
        />
      </Card>

      <JobBoardOptions
        maxHourlyRate={maxHourlyRate}
        onMaxHourlyRateChange={setMaxHourlyRate}
        jobBoard={jobBoard}
        onToggle={value => setJobBoard(value)}
        mb="47px"
      />
    </ScreenLayout>
  )
}

SendBookingRequestScreen.navigationOptions = {
  headerShown: false
}
