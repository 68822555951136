import React, { useCallback, useEffect } from 'react'
import { ResponseError, useCreateBookingMutation, useGetUserQuery } from '@charlycares/data-access'
import { useRouter } from '@charlycares/shared'
import { useTranslation } from 'react-i18next'
import { useAlert } from '../../../../hooks'

type Params = {
  jobboard?: boolean
  navigation?: any
}

export default function useHandleCreateBooking({ jobboard, navigation }: Params) {
  const { t } = useTranslation()
  const { popToTop, navigate } = useRouter(navigation)
  const alert = useAlert()

  const { data: user, refetch: refetchUser, isLoading: isUserLoading } = useGetUserQuery()
  const [createBooking, createBookingStatus] = useCreateBookingMutation()
  const { isError, error, isSuccess } = createBookingStatus

  const getErrorMessage = useCallback(
    (responseError: ResponseError) => {
      if (responseError.status === 403) {
        return t('alreadyHaveBookingAtThisTimeError')
      } else if (responseError.status >= 400 && responseError.status < 500) {
        return responseError.data?.message || responseError.data?.error || t('somethingWentWrong')
      } else {
        return t('serverError')
      }
    },
    [t]
  )

  useEffect(() => {
    if (!isUserLoading) {
      refetchUser()
    }
  }, [])

  useEffect(() => {
    if (isError) {
      const err = error as ResponseError
      alert.show(t('errorAlert'), getErrorMessage(err), [
        { text: t('ok'), style: 'cancel' },
        {
          text: err.status === 403 ? t('alertChangeDates') : t('goBack'),
          onPress: () => navigate('BookingRequestTypesList', '/booking-request-types-list')
        }
      ])
    }
  }, [isError, error, getErrorMessage, t])

  useEffect(() => {
    if (isSuccess) {
      if (user?.family?.has_membership) {
        navigate('BookRequestSent', '/book-request-sent', { jobboard })
      } else if (user?.family?.has_canceled_membership) {
        navigate('MembershipInfoScreen', '/profile-membership')
      } else {
        navigate('BookingIsAlmostSentScreen', '/booking-is-almost-sent')
      }
    }
  }, [isSuccess, navigate, user?.family?.has_membership, jobboard])

  return { createBooking, getErrorMessage, ...createBookingStatus }
}
