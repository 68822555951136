import { BookingDate, BookingServiceType, BookingStates } from './Booking'
import { AngelBabysittingExperience } from './Angel'
import { Days } from './General'
import { Money } from './Money'

export interface IJobPost {
  obscured_id: string
  booking_dates: BookingDate[]
  start_date: string
  end_date: string
  current_state: BookingStates
  has_offer: number
  max_hourly_rate: Money
  extra_hourly_rate: Money
  normal_hourly_rate: Money
  travel_allowance: Money
  on_job_board: boolean
  personal_message: string
  id: number
  distance: number
  expected_earnings: Money
  service_type?: BookingServiceType
  family: {
    has_cat: number
    has_dog: number
    image: string
    lat: number
    lon: number
    name: string
    short_bio: string
    city: string
    street_name: string
    street_number: string
    child_count: number
    family_id: string
    conversation_id: string
  }
}

export enum JobboardSortTypes {
  BOOKING_DATE = 'start_date',
  DISTANCE = 'distance',
  HOURLY_RATE = 'max_hourly_rate_amount'
}

export type JobboardFilters = {
  single: boolean
  multi: boolean
  start_time_range: {
    from: string
    to: string
  }
  days_of_week: Days[]
  languages: string[]
  min_age_children_in_months: AngelBabysittingExperience
  postal_code: string
  distance_in_meters: number
}

export type JobboardSorts = { column: JobboardSortTypes; direction: 'asc' | 'desc' }[]

export enum JobboardErrorTypes {
  NOT_ACCEPTED = 'not_accepted',
  NOT_SCREENED = 'not_screened',
  PENDING_OFFERS_EXCEEDED = 'pending_offers_exceeded'
}
