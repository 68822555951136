import React from 'react'
import { Card, CharlyIcon, ScreenLayout } from '@charlycares/ui'
import { Box, Button, Center, Heading, HStack, Text } from 'native-base'
import { BookingServiceType, IBookingDetails, InvitationsStates, useRouter } from '@charlycares/shared'
import { useGetBookingDetailsQuery, useGetBookingCancellationReasonsQuery } from '@charlycares/data-access'
import { useTranslation } from 'react-i18next'

import { OffersAndInvitesList } from './common'
import BookingDatePicker from '../common/BookingDatePicker'

const EditBookingScreen = () => {
  const { getParam, navigate } = useRouter()
  const { t } = useTranslation()

  const obscured_id = getParam('bookingId') as string
  const { data: booking } = useGetBookingDetailsQuery(
    { obscured_id },
    {
      skip: !obscured_id
    }
  )
  //  TODO: Cancellation reasons should be refactored
  const { data: cancellationReasons } = useGetBookingCancellationReasonsQuery(
    {
      booking_id: (booking as IBookingDetails)?.id.toString()
    },
    { skip: !booking }
  )

  if (!booking) {
    return <ScreenLayout isLoading title={t('booking')} />
  }

  return (
    <ScreenLayout
      title={t('booking')}
      BottomComponent={
        booking.invitations_allowed && booking.maximum_amount_of_angels && booking.maximum_amount_of_angels > 0 ? (
          <HStack borderWidth={1} borderColor="gray.200" bg="white" space="10px" p="20px" alignItems="center">
            <Center flex={1}>
              <Text fontSize="12px" color="gray.800">
                {t('maximumAmountOfAngelsNote', { maximumAmountOfAngels: booking.maximum_amount_of_angels })}
              </Text>
            </Center>
            <Button
              onPress={() =>
                navigate('BookSearchAngelsResult', '/book-search-angels', {
                  bookingId: booking.obscured_id,
                  dates: [{ startDate: booking.start_date, endDate: booking.end_date }]
                })
              }
              size="xs"
              flex={1}
              variant="outline"
              leftIcon={<CharlyIcon name="icn-search" color="secondary.400" size={30} />}
            >
              {t('addNewAngels')}
            </Button>
          </HStack>
        ) : undefined
      }
      subtitle={booking.obscured_id}
      supportButton
    >
      {booking.booking_dates.map(date => (
        <BookingDatePicker
          hideDuration
          readonly
          key={date.obscured_id}
          startDate={date.start_date}
          endDate={date.end_date}
          repeatDates={date.repeat_days.length}
          mt="10px"
        />
      ))}

      <Card mt="0" borderTopWidth={0}>
        {!!booking.personal_message && (
          <Box mb="20px">
            <Heading fontSize="md">{t('personalMessage')}</Heading>
            <Text mt="5px">{booking.personal_message}</Text>
          </Box>
        )}

        <HStack space={'10px'} justifyContent="space-between">
          <Button
            _important={{
              borderColor: 'gray.600',
              _text: {
                fontSize: '14px',
                color: 'gray.600'
              }
            }}
            variant="outline"
            flex={1}
            onPress={() => {
              if (cancellationReasons) {
                navigate('BookingCancellationReasons', '/booking-cancellation-reasons', {
                  bookingId: booking.obscured_id,
                  cancellationReasons: cancellationReasons
                })
              }
            }}
          >
            {t('cancelBooking')}
          </Button>
        </HStack>
      </Card>

      <OffersAndInvitesList booking={booking} />
    </ScreenLayout>
  )
}

export default EditBookingScreen

EditBookingScreen.navigationOptions = {
  headerShown: false
}
