import React, { useState } from 'react'
import { Card, CharlyIcon, MultiSelectList, ScreenLayout, TextInput } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { Button, Center, Divider, Heading, Image, Text } from 'native-base'
import { DefaultElderlyImage, DefaultFamilyImage, DefaultPetImage } from '@charlycares/assets'
import { browseMedia, isDev, ServiceGroupType, uploadFile, useRouter } from '@charlycares/shared'
import {
  api,
  ApiTags,
  useAppDispatch,
  useGetLandingScreensQuery,
  useGetUserQuery,
  useUpdateProfileMutation
} from '@charlycares/data-access'
import { useAlert } from '../../../hooks'
import { Keyboard } from 'react-native'

export default function FamilyIntroductionScreen() {
  const { t } = useTranslation()
  const { navigate } = useRouter()
  const dispatch = useAppDispatch()
  const { data: user, isLoading: loadingUser } = useGetUserQuery()
  const { data: screen } = useGetLandingScreensQuery()
  const [familyDescription, setFamilyDescription] = useState(user?.family?.short_bio || '')
  const [animals, setAnimals] = useState({ dog: !!user?.family?.dog, cat: !!user?.family?.cat })
  const [isPhotoUploading, setIsPhotoUploading] = useState(false)
  const [updateProfile] = useUpdateProfileMutation()
  const alert = useAlert()

  const availableAnimals = [
    {
      value: 'dog',
      label: t('familyIntroductionScreen.dog'),
      leftIcon: <CharlyIcon name="icn-dog" size="25px" color="gray.900" />
    },
    {
      value: 'cat',
      label: t('familyIntroductionScreen.cat'),
      leftIcon: <CharlyIcon name="icn-cat" size="25px" color="gray.900" />
    }
  ]

  const selectPhoto = async () => {
    try {
      const image = await browseMedia()
      if (image.didCancel) return

      setIsPhotoUploading(true)
      uploadFile('v2/users/profile/photo', image).finally(() => {
        dispatch(api.util.invalidateTags([ApiTags.USER, ApiTags.ONBOARDING_SCREENS]))
        setIsPhotoUploading(false)
      })
    } catch (error) {
      isDev && console.log(error)
      alert.show(t('error'), t('error'))
    }
  }

  const complete = () => {
    updateProfile({
      short_bio: familyDescription,
      dog: animals.dog,
      cat: animals.cat
    })
  }

  const serviceType = user?.preferred_service_type
  const defaultImage = () => {
    switch (serviceType) {
      case ServiceGroupType.PET_CARE:
        return DefaultPetImage
      case ServiceGroupType.ELDERLY_CARE:
        return DefaultElderlyImage
      default:
        return DefaultFamilyImage
    }
  }

  const image = user?.profile.image ? { uri: user?.profile.image } : defaultImage()

  if (screen && screen.current_screen.screen_name !== 'FamilyIntroductionScreen') {
    navigate(screen.current_screen.screen_name, screen.current_screen.web_path)
  }

  if (loadingUser) {
    return <ScreenLayout isLoading />
  }

  return (
    <ScreenLayout
      title={t(`familyIntroductionScreen.title.${serviceType}`)}
      progress={`${screen?.current_screen.progress ?? 0}%`}
      hideBackButton={serviceType !== ServiceGroupType.CHILD_CARE}
      _buttonProps={{
        onPress: () => complete(),
        isDisabled: familyDescription?.length === 0 || user?.profile.image === null,
        isLoading: false,
        children: t('FamilyIntroductionScreen.nextStep')
      }}
      _bottomViewKeyboardAware
    >
      <Card>
        <Heading mb="20px" fontSize={'18px'} fontWeight={600}>
          {t(`familyIntroductionScreen.header.${serviceType}`)}
        </Heading>
        <Text fontSize="15px">{t(`familyIntroductionScreen.description.${serviceType}`)}</Text>

        <Heading mt="25px" mb="10px" fontSize={'16px'} fontWeight={600}>
          {t(`familyIntroductionScreen.moreInfo.${serviceType}`)}
        </Heading>
        <Center>
          <Image
            maxHeight="310px"
            minHeight="200px"
            width="100%"
            // @ts-ignore
            source={image}
            resizeMode="contain"
          />

          <Button
            _text={{
              color: 'white',
              fontSize: '15px'
            }}
            isLoading={isPhotoUploading}
            isLoadingText={t('uploading')}
            _loading={{
              opacity: 0.9
            }}
            mt="-15px"
            rounded="full"
            h="40px"
            width="165px"
            bgColor="secondary.400"
            leftIcon={<CharlyIcon name="icn-photo" size={30} color="white" />}
            variant="text"
            onPress={() => selectPhoto()}
          >
            {t('upload')}
          </Button>
        </Center>

        <Divider my="24px" />

        <Heading mb="10px" fontSize={'16px'} fontWeight={600}>
          {t(`familyIntroductionScreen.familyDescriptionHeader.${serviceType}`)}{' '}
          <Text color="red.500" fontSize="12px">
            {t('familyIntroductionScreen.required')}
          </Text>
        </Heading>
        <TextInput
          minHeight="110px"
          variant="filled"
          value={familyDescription}
          onChangeText={text => setFamilyDescription(text)}
          placeholder={t(`familyIntroductionScreen.familyDescriptionPlaceholder.${serviceType}`)}
          multiline
          numberOfLines={5}
          maxLength={500}
          returnKeyType="default"
          onSubmitEditing={() => Keyboard.dismiss()}
        />
        {(serviceType === ServiceGroupType.ELDERLY_CARE || serviceType === ServiceGroupType.CHILD_CARE) && (
          <>
            <Divider my="24px" />

            <Heading fontSize={'16px'} fontWeight={600}>
              {t('familyIntroductionScreen.animalsHeader')}
            </Heading>
            <MultiSelectList
              values={availableAnimals}
              selected={animals}
              // @ts-ignore TODO: Ask Stefan how to fix
              onChange={setAnimals}
            />
          </>
        )}
      </Card>
    </ScreenLayout>
  )
}

FamilyIntroductionScreen.navigationOptions = {
  headerShown: false
}
