import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { IconButton } from '../Buttons';
import theme from '../../styles/theme';

const View = styled.View`
  flex-direction: row;
  margin: ${({ margins }) => margins};
  padding: ${({ paddings }) => paddings};
`;

const CustomRating = ({
  rating,
  margins,
  paddings,
  iconSize,
  iconMargins,
  iconSource,
  iconName,
  activeTintColor,
  inactiveTintColor,
  ratingPress,
  height
}) => {
  const ratingsArray = [false, false, false, false, false];
  for (let i = 0; i < rating; i += 1) {
    ratingsArray[i] = true;
  }

  return (
    <View margins={margins} paddings={paddings}>
      {ratingsArray.map((item, index) => (
        <IconButton
          key={index.toString()}
          iconSize={iconSize}
          iconWidth={iconSize}
          iconHeight={iconSize}
          margins={iconMargins}
          iconName={iconName}
          iconSource={iconSource}
          iconColor={item ? activeTintColor : inactiveTintColor}
          onPress={() => ratingPress(index + 1)}
          height={height}
        />
      ))}
    </View>
  );
};

CustomRating.propTypes = {
  margins: PropTypes.string,
  paddings: PropTypes.string,
  iconSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  iconMargins: PropTypes.string,
  rating: PropTypes.number.isRequired,
  ratingPress: PropTypes.func,
  iconSource: PropTypes.node,
  activeTintColor: PropTypes.string,
  inactiveTintColor: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  iconName: PropTypes.string
};

CustomRating.defaultProps = {
  margins: '0px',
  paddings: '0px',
  iconSize: '30px',
  iconMargins: '0px',
  iconName: 'star',
  activeTintColor: theme.ratingStarActive,
  inactiveTintColor: theme.ratingStarInactive,
  height: 'auto',
  ratingPress: () => {},
  iconSource: undefined
};

export default CustomRating;
