import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ContainerView, Heading, Text } from '../../../elements';
import Rating from '../Rating';
import { getFormatedDate } from '../../../utils/functions';
import theme from '../../../styles/theme';

const AngelRatings = ({ firstName, ratings }) => {
  const [t] = useTranslation();

  return (
    <ContainerView
      flexDirection="column"
      alignItems="flex-start"
      margins="20px 0px 0px 0px"
      width="100%">
      <Heading
        xl
        fontFamily={theme.fontFamilyAlt}
        text={t('angelProfileReviews', { 0: firstName, 1: ratings.length })}
      />
      {ratings.map(({ id, comments, rating, created_at, family_name }) => (
        <ContainerView
          key={id.toString()}
          flexDirection="column"
          alignItems="flex-start"
          margins="4px 0px 16px"
          width="100%"
          borderbottom>
          <ContainerView flexDirection="row" alignItems="flex-start" width="100%">
            <Rating margins="2px 8px 4px 0px" iconSize="12px" rating={rating} />
            <Text sm lighterColor>{`${family_name} | ${getFormatedDate(created_at, 'LL')}`}</Text>
          </ContainerView>
          <Text>{comments}</Text>
        </ContainerView>
      ))}
    </ContainerView>
  );
};

AngelRatings.propTypes = {
  firstName: PropTypes.string.isRequired,
  ratings: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default AngelRatings;
