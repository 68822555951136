import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { Send } from 'react-native-gifted-chat';
import { useTranslation } from 'react-i18next';
import { IconButton } from '../Buttons';
import { isWeb, makeAPhoneCall } from '../../utils/functions';
import colors from '../../styles/colors';

const StyledSend = styled(Send).attrs(({ disabled }) => ({
  containerStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-end',
    marginLeft: 'auto',
    paddingLeft: '15px',
    paddingRight: '15px',
    height: '34px',
    borderRadius: '6px',
    borderWidth: disabled ? '1.5px' : 0,
    borderColor: colors.defaultBtnBackgroundColor,
    backgroundColor: disabled ? colors.white : colors.primary
  },
  textStyle: {
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: '600',
    color: disabled ? colors.defaultBtnBackgroundColor : colors.white,
    textAlign: 'center'
  }
}))``;
const View = styled.View`
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const CustomSend = ({
  sendDisabled,
  sendText,
  phone,
  onImagePress,
  onCameraPress,
  onAddBookingPress,
  onSend
}) => {
  const [t] = useTranslation();

  return (
    <View>
      <IconButton
        margins="0px 10px 0px 0px"
        height="auto"
        iconName="icn-image"
        iconSize="32px"
        iconColor={colors.primaryText}
        onPress={onImagePress}
      />

      {!isWeb && (
        <IconButton
          margins="0px 10px 0px 0px"
          height="auto"
          iconName="icn-photo"
          iconSize="32px"
          iconColor={colors.primaryText}
          onPress={onCameraPress}
        />
      )}

      <IconButton
        margins="0px 10px 0px 0px"
        height="auto"
        iconName="icn-calendar-add"
        iconSize="32px"
        iconColor={colors.primaryText}
        onPress={onAddBookingPress}
      />

      {phone !== '' && !isWeb && (
        <IconButton
          disabled={!phone}
          height="auto"
          iconName="icn-telephone"
          iconSize="32px"
          iconColor={colors.primaryText}
          onPress={() => makeAPhoneCall(phone)}
        />
      )}

      <StyledSend
        alwaysShowSend
        disabled={sendDisabled}
        text={sendText}
        label={t('send')}
        onSend={onSend}
      />
    </View>
  );
};

CustomSend.propTypes = {
  sendDisabled: PropTypes.bool.isRequired,
  sendText: PropTypes.string.isRequired,
  phone: PropTypes.string,
  onImagePress: PropTypes.func.isRequired,
  onCameraPress: PropTypes.func.isRequired,
  onAddBookingPress: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired
};

CustomSend.defaultProps = {
  phone: null
};

export default CustomSend;
