import React from 'react'
import PropTypes from 'prop-types'
import { Player, ControlBar, PlayerProps } from 'video-react'

type Props = PlayerProps & {
  src: string
}

const WebVideo = ({ src, ...props }: Props) => (
  <Player {...props} playsInline src={src}>
    <ControlBar />
  </Player>
)

WebVideo.propTypes = {
  src: PropTypes.string.isRequired,
  disableControls: PropTypes.bool
}

WebVideo.defaultProps = {
  disableControls: true
}

export default WebVideo
