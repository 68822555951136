import axiosInstance from '../../utils/axios'
import {
  GET_BOOKINGS,
  FETCHING_BOOKINGS,
  GET_NEXT_BOOKINGS,
  SET_BOOKING_DETAILS,
  ACCEPT_BOOKING,
  BOOKING_ACCEPTED_ERROR,
  CHECK_AVAILABILITY,
  CHECK_AVAILABILITY_ERROR,
  UPDATING_BOOKING_DETAILS,
  FETCHING_TIMING,
  GET_UPDATED_TIMING,
  FINISH_BOOKING,
  GET_ACTIVE_TIMINGS,
  SERVER_ERROR
} from '../types'

const logError = (error, dispatch) => {
  if (error.response && error.response.status === 500) {
    dispatch({ type: SERVER_ERROR })
  }
}

export const getBookings = (state, amountPerPage) => dispatch => {
  dispatch({ type: FETCHING_BOOKINGS })
  axiosInstance
    .get(`v1/bookings/family/v2/${state}/${amountPerPage}`)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_BOOKINGS,
          payload: data.data
        })
      }
    })
    .catch(error => console.log({ error }))
}

export const getNextBookings = nextUrlPage => dispatch => {
  axiosInstance
    .get(nextUrlPage)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_NEXT_BOOKINGS,
          payload: data.data
        })
      }
    })
    .catch(error => console.log({ error }))
}

export const getBookingDetails = id => dispatch => {
  dispatch({ type: FETCHING_BOOKINGS })
  axiosInstance
    .get(`bookings/${id}`)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: SET_BOOKING_DETAILS,
          payload: data.data
        })
      }
    })
    .catch(error => console.log({ error }))
}

export const declineBooking = (bookingId, angelId) => dispatch => {
  dispatch({ type: UPDATING_BOOKING_DETAILS })
  axiosInstance
    .post(`bookings/${bookingId}/decline/${angelId}`)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: SET_BOOKING_DETAILS,
          payload: data.data
        })
      }
    })
    .catch(error => console.log({ error }))
}

export const acceptBooking = (bookingId, angelId) => dispatch => {
  dispatch({ type: UPDATING_BOOKING_DETAILS })
  dispatch({ type: BOOKING_ACCEPTED_ERROR })
  axiosInstance
    .post(`bookings/${bookingId}/accept/${angelId}`)
    .then(({ data }) => {
      if (data) {
        const { id, updated_booking, other_bookings } = data.data
        if (id) {
          dispatch({
            type: SET_BOOKING_DETAILS,
            payload: data.data
          })
        } else {
          dispatch({
            type: SET_BOOKING_DETAILS,
            payload: updated_booking
          })
          if (other_bookings && other_bookings.length > 0) {
            dispatch({
              type: ACCEPT_BOOKING,
              payload: other_bookings.filter(otherBooking => otherBooking.id !== updated_booking.id)
            })
          }
        }
      }
    })
    .catch(error => {
      if (error.response && error.response.status === 400) {
        dispatch({
          type: BOOKING_ACCEPTED_ERROR,
          payload: error.response.data && error.response.data.message
        })
      } else {
        console.log({ error })
      }
    })
}

export const extendBookingRequest = (bookingId, invitationId) => dispatch => {
  dispatch({ type: UPDATING_BOOKING_DETAILS })
  axiosInstance
    .post(`bookings/${bookingId}/extend/${invitationId}`)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: SET_BOOKING_DETAILS,
          payload: data.data
        })
      }
    })
    .catch(error => console.log({ error }))
}

export const cancelBooking =
  (bookingId, reasons = [], reason) =>
  dispatch => {
    dispatch({ type: UPDATING_BOOKING_DETAILS })
    axiosInstance
      .post(`bookings/${bookingId}/cancel`, { reasons, add_reason: reason })
      .then(({ data }) => {
        if (data) {
          dispatch({
            type: SET_BOOKING_DETAILS,
            payload: data.data
          })
        }
      })
      .catch(error => console.log({ error }))
  }

export const updateBooking = (bookingId, bookingDates, message) => dispatch => {
  dispatch({ type: UPDATING_BOOKING_DETAILS })
  dispatch({ type: CHECK_AVAILABILITY_ERROR })
  axiosInstance
    .put('bookings/update', { booking_id: bookingId, booking_dates: bookingDates, message })
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: SET_BOOKING_DETAILS,
          payload: data.data
        })
      }
    })
    .catch(error => {
      if (error.response && error.response.data) {
        dispatch({
          type: CHECK_AVAILABILITY_ERROR,
          payload: error.response.data.message
        })
      } else {
        console.log({ error })
      }
    })
}

export const checkAvailability = (bookingId, bookingDates) => dispatch => {
  axiosInstance
    .put('bookings', { booking_id: bookingId, booking_dates: bookingDates })
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: CHECK_AVAILABILITY,
          payload: data.data
        })
      }
    })
    .catch(error => {
      if (error.response && error.response.data) {
        dispatch({
          type: CHECK_AVAILABILITY_ERROR,
          payload: error.response.data.message
        })
      } else {
        console.log({ error })
      }
    })
}

export const clearAvailabilityErrorMessage = () => dispatch => dispatch({ type: CHECK_AVAILABILITY_ERROR })

export const calculateTiming = (start_time, end_time, tip) => dispatch => {
  dispatch({ type: FETCHING_TIMING })
  axiosInstance
    .post('active_timings/calculate', { start_time, end_time, tip })
    .then(response => {
      dispatch({
        type: GET_UPDATED_TIMING,
        payload: response.data.data
      })
    })
    .catch(error => logError(error, dispatch))
}

export const finishBooking = (start_time, end_time, tip, comments, rating, employer_pays) => dispatch => {
  axiosInstance
    .post('bookings/finish', {
      start_time,
      end_time,
      tip,

      comments,
      rating,
      employer_pays
    })
    .then(() => dispatch({ type: FINISH_BOOKING }))
    .catch(error => logError(error, dispatch))
}

export const getActiveTiming = () => dispatch => {
  axiosInstance
    .get('active_timings/time')
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_ACTIVE_TIMINGS,
          payload: data.data
        })
      }
    })
    .catch(error => logError(error, dispatch))
}
