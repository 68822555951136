import React, { useEffect, useState } from 'react'
import { Card, CharlyIcon, ScreenLayout } from '@charlycares/ui'
import { Box, FlatList, Heading, HStack, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import { useGetAllReviewsQuery } from '@charlycares/data-access'
import { contactPaginatedData, formatDate, IReview, useRouter } from '@charlycares/shared'

const ReviewsOverviewScreen = () => {
  const { t } = useTranslation()
  const { getParam } = useRouter()
  const [page, setPage] = useState(1)
  const [ratings, setRatings] = useState<IReview[]>([])

  const angel_user_id: string = getParam('user_id')

  const { data, isLoading, isFetching, refetch, isSuccess } = useGetAllReviewsQuery({ user_id: angel_user_id, page })

  useEffect(() => {
    if (isSuccess && !isFetching && data) {
      setRatings(state => contactPaginatedData(state, data))
    }
  }, [data, isFetching, isSuccess])

  if (!ratings) {
    return <ScreenLayout isLoading title={t('reviews_screen')} />
  }

  return (
    <ScreenLayout disableScroll isLoading={isLoading} title={t('reviews_screen')}>
      <Card flex={1}>
        <FlatList
          data={ratings}
          keyExtractor={item => item.booking_id || item.rating_id}
          refreshing={isFetching}
          onRefresh={() => {
            setPage(1)
            refetch()
          }}
          onEndReachedThreshold={0.5}
          onEndReached={() => {
            if (
              data?.meta.last_page &&
              page < data?.meta.last_page &&
              !isFetching &&
              data?.meta.current_page === page
            ) {
              setPage(page + 1)
            }
          }}
          renderItem={({ item }) => (
            <Box mb="30px">
              <Box flexDir="row" alignItems="center" justifyContent="space-between">
                <Text fontSize={15}>{`${item.profile.first_name} - ${formatDate(
                  item.start_date,
                  'DD MMMM YYYY'
                )}`}</Text>
                {!!item?.average_rating && (
                  <HStack space="5px" mt="5px" alignItems="center">
                    <Heading fontSize={'15px'}>{item?.average_rating?.toFixed(2)}</Heading>
                    <CharlyIcon name="star" size={15} color={'gray.800'} />
                  </HStack>
                )}
              </Box>

              {!!item.comment && (
                <Text color="gray.800" mt="5px" fontSize={14}>
                  {item.comment}
                </Text>
              )}
            </Box>
          )}
        />
      </Card>
    </ScreenLayout>
  )
}

export default ReviewsOverviewScreen

ReviewsOverviewScreen.navigationOptions = {
  headerShown: false
}
