import React from 'react'
import moment from 'moment'
import { Box, Center, Heading, HStack, IBoxProps, Stack, Text } from 'native-base'
import { AngelAvailability } from '@charlycares/shared'
import { useTranslation } from 'react-i18next'
import { CharlyIcon } from '@charlycares/ui'

type Props = IBoxProps & {
  availabilities: AngelAvailability[]
}

const AvailabilityTable = ({ availabilities, ...boxProps }: Props) => {
  const { t } = useTranslation()

  return (
    <Box {...boxProps}>
      <Heading fontSize="md">{t('availability')}</Heading>
      <Text mt="5px" color="gray.700" fontSize="12px">
        {t('familyBabysitterPreferencesDescription')}
      </Text>

      <Box mt="15px" flexDir="row">
        <Box flex={1}>
          <Stack space="5px">
            <Text textTransform="capitalize" fontSize="14px"></Text>
            <Text textTransform="capitalize" fontSize="14px">
              {t('morning')}
            </Text>
            <Text textTransform="capitalize" fontSize="14px">
              {t('afternoon')}
            </Text>
            <Text textTransform="capitalize" fontSize="14px">
              {t('evening')}
            </Text>
          </Stack>
        </Box>

        <HStack space="5px">
          {availabilities?.map(({ id, day_of_week, morning, afternoon, evening }) => {
            return (
              <Stack space="5px" key={id} alignItems="center">
                <Center bg="gray.300" opacity={morning || afternoon || evening ? 1 : 0.3} w="20px" h="20px">
                  <Text textTransform="capitalize" textAlign="center" fontSize="14px">
                    {moment()
                      .day(day_of_week + 1)
                      .format('dddd')
                      .substring(0, 1)}
                  </Text>
                </Center>

                <Center bg="gray.300" opacity={morning ? 1 : 0.3} w="20px" h="20px">
                  {morning && <CharlyIcon name="icn-check" size={20} />}
                </Center>
                <Center bg="gray.300" opacity={afternoon ? 1 : 0.3} w="20px" h="20px">
                  {afternoon && <CharlyIcon name="icn-check" size={20} />}
                </Center>
                <Center bg="gray.300" opacity={evening ? 1 : 0.3} w="20px" h="20px">
                  {evening && <CharlyIcon name="icn-check" size={20} />}
                </Center>
              </Stack>
            )
          })}
        </HStack>
      </Box>
    </Box>
  )
}

export default AvailabilityTable
