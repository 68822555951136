import React from 'react'
import { Linking, Platform } from 'react-native'
import { useGetSubscriptionQuery } from '@charlycares/data-access'
import { useAlert } from "@charlycares/features";
import { useTranslation } from "react-i18next";
import { Box, Button, Heading, Text } from "native-base";
import { Card, ScreenLayout } from "@charlycares/ui";
import { formatDate, useRouter, links } from "@charlycares/shared";

export default function CancelOrChangeMembershipScreen() {
  const { data: subscription } = useGetSubscriptionQuery()
  const alert = useAlert()
  const { t } = useTranslation()
  const { navigate } = useRouter()

  const cancelSubscription = () => {
    alert.show(
      t('cancelSubscriptionAlertTitle'),
      t('cancelSubscriptionAlertMessage', {renews_at: formatDate(subscription?.renews_at)}),
      [
        {text: t('cancel')},
        {
          text: t('confirm'),
          onPress: () => {
            Platform.OS === 'ios' && subscription?.membership_type.includes('apple')
              ? Linking.openURL(links.memberships.subscriptions)
              :
              navigate('ProfileCancelMembershipReasons', 'profile-membership-cancel-reasons')
          }
        }
      ])
  }
  return (
    <ScreenLayout title={t('profileScreensCancelMembership')}>
      <Card>
        <Box w="100%">
          <Heading fontSize={'18px'} fontWeight="600" mb="20px">
            {t('profileScreensPayLess')}
          </Heading>
          <Text mt="10px" fontSize={'15px'}>
            {t('profileScreensAnotherSubscription')}
          </Text>

          <Button
            mt="30px"
            onPress={() => navigate('ProfileMembershipDetails', '/profile-membership-details',  { mode: 'edit' })}
          >
            {t('profileScreensChangeMembership')}
          </Button>
        </Box>
      </Card>

      <Card>
        <Box w="100%">
          <Heading fontSize={'18px'} fontWeight="600" mb="20px">
            {t('profileScreensCancelAnyway')}
          </Heading>
          <Text mt="10px" fontSize={'15px'}>
            {t('profileScreensWhenYouCancelMembership')}
          </Text>

          <Button
            mt="30px"
            variant="outline"
            onPress={() => cancelSubscription()}
          >
            {t('profileScreensCancelMembership')}
          </Button>
        </Box>
      </Card>
    </ScreenLayout>
  )
}

CancelOrChangeMembershipScreen.navigationOptions = () => ({
  headerShown: false
})
