import React, { useState, useEffect } from 'react'
import { ActivityIndicator } from 'react-native'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { Text } from '../../../elements'
import { addAngelReference, getAngelPublic } from '../../../stores/actions/userAction'
import { AngelAvatar, Rating, TextButton } from '../../../components'
import colors from '../../../styles/colors'
import { useAngelReferenceReviewMutation } from '@charlycares/data-access'

const AngelLeaveReference = ({ getAngelData, angelData }) => {
  const [t] = useTranslation()
  const history = useHistory()
  const [saveReview, { isSuccess, isError }] = useAngelReferenceReviewMutation()
  const params = useParams()
  const [disableSend, setDisableSend] = useState(true)
  const [review, setReview] = useState('')
  const [ratings, setRatings] = useState([
    {
      title: t('rating1'),
      rating: 0
    },
    {
      title: t('rating2'),
      rating: 0
    },
    {
      title: t('rating3'),
      rating: 0
    },
    {
      title: t('rating4'),
      rating: 0
    },
    {
      title: t('rating5'),
      rating: 0
    }
  ])

  useEffect(() => {
    const isUnrated = ratings.find(rating => !rating.rating)
    if (review && !isUnrated) {
      setDisableSend(false)
    }
  }, [ratings, review])

  useEffect(() => {
    getAngelData(params.angelId)
  }, [])

  if (!angelData) {
    return (
      <Center>
        <ActivityIndicator size="large" />
      </Center>
    )
  }

  console.log(params)

  const submitReference = () => {
    const average_rating =
      ratings.reduce((value, rating) => value + rating.rating, 0) / ratings.length

    saveReview({
      angel_id: params.angelId,
      angelReferenceId: params.referenceId,
      average_rating,
      review
    })
  }

  const { first_name, image } = angelData

  return (
    <SafeAreaView>
      {!isSuccess ? (
        <>
          <Text margins="0px 0px 10px 0px" fontSize="20px">
            {t('leaveReferenceTitle', { first_name })}
          </Text>

          <AngelAvatar source={image} size="60px" margins="5px 0px 15px 0px" />

          {ratings.map((rating, index) => {
            return (
              <>
                <Text fontSize="16px" fontFamily="Martel-DemiBold" margins="15px 0px 5px 0px">
                  {rating.title}
                </Text>
                <Rating
                  iconMargins="2px"
                  iconSize={30}
                  rating={rating.rating}
                  ratingPress={stars => {
                    const ratingsCopy = [...ratings]
                    ratingsCopy[index].rating = stars
                    setRatings(ratingsCopy)
                  }}
                />
              </>
            )
          })}

          <Text fontSize="18px" fontFamily="Martel-DemiBold" margins="25px 0px 5px 0px">
            {t('leaveReferenceReviewTitle')}
          </Text>

          <Input
            margins="15px 0px 30px 0px"
            maxHeight="150px"
            autoCapitalize="none"
            autoCorrect={false}
            multiline
            returnKeyType="done"
            blurOnSubmit
            textAlignVertical="top"
            placeholder={t('leaveReferenceReviewPlaceholder')}
            value={review}
            onChangeText={text => setReview(text)}
          />

          <TextButton
            disabled={disableSend}
            backgroundColor={disableSend ? colors.grey06 : colors.primary}
            text={t('sendReference')}
            onPress={submitReference}
          />

          {isError && (
            <Text textAlign="center" color="red" fontSize="15px" margins="10px">
              {t('error')}
            </Text>
          )}
        </>
      ) : (
        <Center>
          <Text margins="0px 0px 10px 0px" fontSize="20px">
            {t('leaveReferenceThanksTitle', { 0: first_name })}
          </Text>

          <Text margins="20px 0px 10px 0px" fontSize="16px">
            {t('leaveReferenceThanksBody', { 0: first_name })}
          </Text>

          <TextButton
            backgroundColor={colors.primary}
            text={t('goBack')}
            onPress={() => history.replace('/')}
          />
        </Center>
      )}
    </SafeAreaView>
  )
}

const SafeAreaView = styled.SafeAreaView`
  min-height: 100%;
  justify-content: center;
  margin: 20px;
`

const Center = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`

const Input = styled.TextInput`
  margin: ${({ margins }) => margins};
  width: 100%;
  min-height: 70px;
  max-height: ${({ maxHeight }) => maxHeight};
  font-size: 16px;
  background: ${colors.grey09};
  border-radius: 5px;
  padding: 10px;
  outline: none;
`

AngelLeaveReference.propTypes = {
  angelData: PropTypes.shape({
    first_name: PropTypes.string,
    image: PropTypes.string
  }),
  getAngelData: PropTypes.func.isRequired,
  addAngelReferenceData: PropTypes.func.isRequired,
  referenceAdded: PropTypes.bool
}

AngelLeaveReference.defaultProps = {
  angelData: null,
  referenceAdded: false
}

const mapStateToProps = User => ({
  angelData: User.User.angelData,
  referenceAdded: User.User.referenceAdded
})

const mapDispatchToProps = {
  getAngelData: getAngelPublic,
  addAngelReferenceData: addAngelReference
}

export default connect(mapStateToProps, mapDispatchToProps)(AngelLeaveReference)
