import React from 'react';
import Icon from './Icon';

export const Add = (props) => <Icon {...props} name="add" />;
export const BtnCheck = (props) => <Icon {...props} name="btn-check" />;
export const BtnMinus = (props) => <Icon {...props} name="btn-minus" />;
export const BtnPlay = (props) => <Icon {...props} name="btn-play" />;
export const BtnPlus = (props) => <Icon {...props} name="btn-plus" />;
export const Cat = (props) => <Icon {...props} name="cat" />;
export const Close = (props) => <Icon {...props} name="close" />;
export const Dog = (props) => <Icon {...props} name="dog" />;
export const Favorites = (props) => <Icon {...props} name="favorites" />;
export const IcnAlertOff = (props) => <Icon {...props} name="icn-alert-off" />;
export const IcnAlert = (props) => <Icon {...props} name="icn-alert" />;
export const IcnBabyBottle = (props) => <Icon {...props} name="icn-baby-bottle" />;
export const IcnBackpack = (props) => <Icon {...props} name="icn-backpack" />;
export const IcnBlock = (props) => <Icon {...props} name="icn-block" />;
export const IcnBookings = (props) => <Icon {...props} name="icn-bookings" />;
export const IcnCalculate = (props) => <Icon {...props} name="icn-calculate" />;
export const IcnCalendarAccepted = (props) => <Icon {...props} name="icn-calendar-accepted" />;
export const IcnCalendarAdd = (props) => <Icon {...props} name="icn-calendar-add" />;
export const IcnCalendarCanceled = (props) => <Icon {...props} name="icn-calendar-canceled" />;
export const IcnCalendarSync = (props) => <Icon {...props} name="icn-calendar-sync" />;
export const IcnCalendar = (props) => <Icon {...props} name="icn-calendar" />;
export const IcnCancel = (props) => <Icon {...props} name="icn-cancel" />;
export const IcnCar = (props) => <Icon {...props} name="icn-car" />;
export const IcnCaretDown = (props) => <Icon {...props} name="icn-caret-down" />;
export const IcnCaretLeft = (props) => <Icon {...props} name="icn-caret-left" />;
export const IcnCaretRight = (props) => <Icon {...props} name="icn-caret-right" />;
export const IcnCaretUp = (props) => <Icon {...props} name="icn-caret-up" />;
export const IcnCat = (props) => <Icon {...props} name="icn-cat" />;
export const IcnChat = (props) => <Icon {...props} name="icn-chat" />;
export const IcnCheck = (props) => <Icon {...props} name="icn-check" />;
export const IcnConversation = (props) => <Icon {...props} name="icn-conversation" />;
export const IcnCooking = (props) => <Icon {...props} name="icn-cooking" />;
export const IcnCreditcard = (props) => <Icon {...props} name="icn-creditcard" />;
export const IcnCycling = (props) => <Icon {...props} name="icn-cycling" />;
export const IcnDay = (props) => <Icon {...props} name="icn-day" />;
export const IcnDining = (props) => <Icon {...props} name="icn-dining" />;
export const IcnDishes = (props) => <Icon {...props} name="icn-dishes" />;
export const IcnDog = (props) => <Icon {...props} name="icn-dog" />;
export const IcnDrums = (props) => <Icon {...props} name="icn-drums" />;
export const IcnEdit = (props) => <Icon {...props} name="icn-edit" />;
export const IcnEyeOpen = (props) => <Icon {...props} name="icn-eye-open" />;
export const IcnFacebook = (props) => <Icon {...props} name="icn-facebook" />;
export const IcnFamily = (props) => <Icon {...props} name="icn-family" />;
export const IcnFavoriteFill = (props) => <Icon {...props} name="icn-favorite-fill" />;
export const IcnFavoriteInactive = (props) => <Icon {...props} name="icn-favorite-inactive" />;
export const IcnFavorites = (props) => <Icon {...props} name="icn-favorites" />;
export const IcnFilter = (props) => <Icon {...props} name="icn-filter" />;
export const IcnFirstAid = (props) => <Icon {...props} name="icn-first-aid" />;
export const IcnFlute = (props) => <Icon {...props} name="icn-flute" />;
export const IcnGift = (props) => <Icon {...props} name="icn-gift" />;
export const IcnGuitar = (props) => <Icon {...props} name="icn-guitar" />;
export const IcnHouse = (props) => <Icon {...props} name="icn-house" />;
export const IcnImage = (props) => <Icon {...props} name="icn-image" />;
export const IcnInsurance = (props) => <Icon {...props} name="icn-insurance" />;
export const IcnIroning = (props) => <Icon {...props} name="icn-ironing" />;
export const IcnKids = (props) => <Icon {...props} name="icn-kids" />;
export const IcnLanguageDe = (props) => <Icon {...props} name="icn-language-de" />;
export const IcnLanguageEn = (props) => <Icon {...props} name="icn-language-en" />;
export const IcnLanguageFr = (props) => <Icon {...props} name="icn-language-fr" />;
export const IcnLanguageIt = (props) => <Icon {...props} name="icn-language-it" />;
export const IcnLanguageNl = (props) => <Icon {...props} name="icn-language-nl" />;
export const IcnLanguageSp = (props) => <Icon {...props} name="icn-language-sp" />;
export const IcnLastMinute = (props) => <Icon {...props} name="icn-last-minute" />;
export const IcnLaundry = (props) => <Icon {...props} name="icn-laundry" />;
export const IcnLocation = (props) => <Icon {...props} name="icn-location" />;
export const IcnMath = (props) => <Icon {...props} name="icn-math" />;
export const IcnMicrophone = (props) => <Icon {...props} name="icn-microphone" />;
export const IcnMobilePhone = (props) => <Icon {...props} name="icn-mobile-phone" />;
export const IcnMorning = (props) => <Icon {...props} name="icn-morning" />;
export const IcnNavAdd = (props) => <Icon {...props} name="icn-nav-add" />;
export const IcnNavDown = (props) => <Icon {...props} name="icn-nav-down" />;
export const IcnNavEnd = (props) => <Icon {...props} name="icn-nav-end" />;
export const IcnNavLeft = (props) => <Icon {...props} name="icn-nav-left" />;
export const IcnNavRight = (props) => <Icon {...props} name="icn-nav-right" />;
export const IcnNavStart = (props) => <Icon {...props} name="icn-nav-start" />;
export const IcnNavUp = (props) => <Icon {...props} name="icn-nav-up" />;
export const IcnNight = (props) => <Icon {...props} name="icn-night" />;
export const IcnPayments = (props) => <Icon {...props} name="icn-payments" />;
export const IcnPeople = (props) => <Icon {...props} name="icn-people" />;
export const IcnPhoto = (props) => <Icon {...props} name="icn-photo" />;
export const IcnPiano = (props) => <Icon {...props} name="icn-piano" />;
export const IcnPost = (props) => <Icon {...props} name="icn-post" />;
export const IcnProfile = (props) => <Icon {...props} name="icn-profile" />;
export const IcnReading = (props) => <Icon {...props} name="icn-reading" />;
export const IcnRepeat = (props) => <Icon {...props} name="icn-repeat" />;
export const IcnSaxophone = (props) => <Icon {...props} name="icn-saxophone" />;
export const IcnSearch = (props) => <Icon {...props} name="icn-search" />;
export const IcnSend = (props) => <Icon {...props} name="icn-send" />;
export const IcnSettings = (props) => <Icon {...props} name="icn-settings" />;
export const IcnShare = (props) => <Icon {...props} name="icn-share" />;
export const IcnSinging = (props) => <Icon {...props} name="icn-singing" />;
export const IcnTelephoneFill = (props) => <Icon {...props} name="icn-telephone-fill" />;
export const IcnTelephone = (props) => <Icon {...props} name="icn-telephone" />;
export const IcnTime = (props) => <Icon {...props} name="icn-time" />;
export const IcnTrash = (props) => <Icon {...props} name="icn-trash" />;
export const IcnVacuum = (props) => <Icon {...props} name="icn-vacuum" />;
export const IcnValidated = (props) => <Icon {...props} name="icn-validated" />;
export const IcnViolin = (props) => <Icon {...props} name="icn-violin" />;
export const IcnWalkingTheDog = (props) => <Icon {...props} name="icn-walking-the-dog" />;
export const NavClose = (props) => <Icon {...props} name="nav-close" />;
export const Standby = (props) => <Icon {...props} name="standby" />;
export const Star = (props) => <Icon {...props} name="star" />;
export const Time = (props) => <Icon {...props} name="time" />;