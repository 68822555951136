import { api } from '../api'
import { AreaPromotionResource, RedeemableResource, ReferralResource } from './marketing.dto'
import { ApiTags } from '../types'

export const marketingApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: ({ mutation, query }) => ({
    promotions: query<AreaPromotionResource, void>({
      query: body => ({
        url: 'v2/marketing/promotions',
        method: 'GET',
        body
      }),
      providesTags: [ApiTags.MARKETING],
      transformResponse: ({ data }): AreaPromotionResource => data
    }),
    referralPromotion: query<AreaPromotionResource, { code: string }>({
      query: ({ code }) => ({
        url: `v2/marketing/promotions/referrals/${code}`,
        method: 'GET'
      }),
      providesTags: [ApiTags.MARKETING],
      transformResponse: ({ data }): AreaPromotionResource => data
    }),
    redeemPromotionCode: mutation<RedeemableResource, { code: string }>({
      query: body => ({
        url: 'v2/marketing/promotions/redeem',
        method: 'POST',
        body
      }),
      invalidatesTags: [ApiTags.MARKETING],
      transformResponse: ({ data }): RedeemableResource => data
    })
  })
})

export const { usePromotionsQuery, useReferralPromotionQuery, useRedeemPromotionCodeMutation } = marketingApi
