import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { Divider } from '../../elements';
import colors from '../../styles/colors';

const ProgressBar = styled.View`
  flex: 1;
  margin: 1px;
  width: ${({ progress }) => `${progress}%`};
  background-color: ${({ color }) => color};
`;

const Progress = ({ progress, color }) => (
  <Divider>
    <ProgressBar progress={progress} color={color} />
  </Divider>
);

Progress.propTypes = {
  progress: PropTypes.number.isRequired,
  color: PropTypes.string
};

Progress.defaultProps = {
  color: colors.secondary
};

export default Progress;
