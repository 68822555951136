export { default as BecomeMemberBackground } from './BecomeMemberBackground.png'
export { default as DefaultFamilyImage } from './defaultFamilyImage.png'
export { default as NewCityBannerEN } from './new-city-banner-EN.png'
export { default as NewCityBannerNL } from './new-city-banner-NL.png'
export { default as IdinLogo } from './idin-logo.png'
export { default as BookingCancelledImage } from './booking-cancelled.png'
export { default as LogoNameImage } from './logo-name.png'
export { default as LogoIconImage } from './logo-icon.png'
export { default as PhotoTip1Right } from './photo-tip-1-right.png'
export { default as PhotoTip1Wrong } from './photo-tip-1-wrong.png'
export { default as PhotoTip2Right } from './photo-tip-2-right.png'
export { default as PhotoTip2Wrong } from './photo-tip-2-wrong.png'
export { default as PhotoTip3Right } from './photo-tip-3-right.png'
export { default as PhotoTip3Wrong } from './photo-tip-3-wrong.png'
export { default as PhotoTip4Right } from './photo-tip-4-right.png'
export { default as PhotoTip4Wrong } from './photo-tip-4-wrong.png'
export { default as PhotoTip5Right } from './photo-tip-5-right.png'
export { default as PhotoTip5Wrong } from './photo-tip-5-wrong.png'
export { default as DefaultPetImage } from './default-pet-image.png'
export { default as DefaultElderlyImage } from './default-elderly-image.png'
export { default as PreBoardingStep1 } from './pre-boarding-step-1.png'
export { default as TrialMembershipBannerEN } from './trial-membership-banner-en.png'
export { default as TrialMembershipBannerNL } from './trial-membership-banner-nl.png'
export { default as LogoNameAndIconImage } from './logo-charly-cares.png'
