import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components/native'
import { Description, Card, IconDescription, Icon, Layout } from '@charlycares/ui'
import { useGetMembershipsQuery, useGetWalletsQuery } from '@charlycares/data-access'
import { WalletType } from '@charlycares/shared'

import { PaymentsMethods } from './common'

function ChangeBankAccountScreen() {
  const { data: membership, isLoading: loadingMembership } = useGetMembershipsQuery()
  const { data: wallets, isLoading: loadingWallets } = useGetWalletsQuery()
  const { t } = useTranslation()
  const theme = useTheme()

  const personalWallet = wallets?.find(wallet => {
    return wallet.wallet_type === WalletType.PERSONAL && wallet.active
  })

  return (
    <Layout title={t('changeBankAccountScreen.title')} scrollDisabled loading={loadingMembership || loadingWallets}>
      <Card>
        <Description
          title={t('changeBankAccountScreen.description.title')}
          text={t('changeBankAccountScreen.description.message')}
        />

        <IconDescription
          margins="20px 0"
          icon={() => <Icon name="credit-card" size={35} />}
          title={personalWallet?.account_number}
          text={t('profileScreensBankCredit')}
          size={12}
          color={theme.colors.grey03}
        />

        {membership && <PaymentsMethods membershipType={membership[0].type} />}
      </Card>
    </Layout>
  )
}

ChangeBankAccountScreen.navigationOptions = {
  headerShown: false
}

export default ChangeBankAccountScreen
