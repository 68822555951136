import React, { useEffect, useState } from 'react'
import {
  ANGEL_DAILY_PET_CARE_RATE_MAX_AMOUNT,
  ANGEL_DAILY_PET_CARE_RATE_MIN_AMOUNT,
  ANGEL_RATE_MAX_AMOUNT,
  ANGEL_RATE_MIN_AMOUNT,
  BookingServiceType,
  flattenSearchBookingDates,
  formatMoney,
  links,
  useRouter
} from '@charlycares/shared'
import { Card, CharlyIcon, NumberInput, ScreenLayout, TextInput } from '@charlycares/ui'
import { Box, Button, Heading, HStack, Text, useTheme } from 'native-base'
import { Linking } from 'react-native'
import { useTranslation } from 'react-i18next'

import { BookingDatesList, useHandleCreateBooking } from '../common'
import { BookingRequestType } from '@charlycares/data-access'

const JobPostScreen = ({ navigation }: any) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { getParam, navigate } = useRouter()

  const bookingDates = getParam('dates', [])
  const bookingRequestType = getParam('bookingRequestType') as BookingRequestType | undefined
  const bookingServiceType = bookingRequestType?.service_type
  const isPetCare = bookingServiceType === BookingServiceType.PET_CARE

  const [maxHourlyRate, setMaxHourlyRate] = useState(0)
  const [travelCompensation, setTravelCompensation] = useState(0)
  const [personalMessage, setPersonalMessage] = useState('')

  const { createBooking, isLoading } = useHandleCreateBooking({ jobboard: true, navigation })

  const onSubmit = () => {
    createBooking({
      dates: flattenSearchBookingDates(bookingDates),
      selected_angels: [],
      job_board: true,
      personal_message: personalMessage,
      max_hourly_rate: {
        amount: maxHourlyRate,
        currency: 'EUR'
      },
      travel_allowance: {
        amount: travelCompensation,
        currency: 'EUR'
      },
      service_type: bookingServiceType
    })
  }

  useEffect(() => {
    setMaxHourlyRate(isPetCare ? 2500 : 1000)
  }, [isPetCare])

  return (
    <ScreenLayout
      headerLeft={
        <CharlyIcon
          name="icn-nav-left"
          color={colors.primary[400]}
          size={30}
          onPress={() => navigate('CreateBooking', '/book-create', { bookingRequestType })}
        />
      }
      _buttonProps={{
        onPress: onSubmit,
        children: t('postJobRequest'),
        isLoading,
        isDisabled: !personalMessage
      }}
      title={t('postToJobboard')}
      headerRight={
        <Button variant="text" onPress={() => Linking.openURL(links.support)}>
          {t('support')}
        </Button>
      }
    >
      <Card>
        <HStack space={'13px'} alignItems="center">
          <CharlyIcon name="icn-post" color={colors.secondary[500]} size={30} />
          <Text fontWeight={600} fontSize="15px">
            {t('whatIsJobboard')}
          </Text>
        </HStack>
        <Text mt="10px">{t('jobboardDescription')}</Text>
      </Card>

      <BookingDatesList bookingDates={bookingDates} />

      <Card>
        <Box flex={1} w="100%">
          <Box flexDir="row" justifyContent="space-between" alignItems="center">
            <Heading fontSize="15px" fontWeight={600}>
              {isPetCare ? t('maxDailyRate') : t('maxHourlyRate')}
            </Heading>

            <NumberInput
              min={isPetCare ? ANGEL_DAILY_PET_CARE_RATE_MIN_AMOUNT * 100 : ANGEL_RATE_MIN_AMOUNT * 100}
              max={isPetCare ? ANGEL_DAILY_PET_CARE_RATE_MAX_AMOUNT * 100 : ANGEL_RATE_MAX_AMOUNT * 100}
              changeRate={isPetCare ? 100 : 25}
              value={maxHourlyRate}
              onChange={setMaxHourlyRate}
              formatValue={val =>
                formatMoney({
                  amount: val,
                  currency: 'EUR'
                })
              }
            />
          </Box>
          <Text mt="15px" fontSize="14px">
            {isPetCare ? t('maxDailyRateDesc') : t('maxHourlyRateDesc')}
          </Text>
        </Box>

        <Box flex={1} mt="30px" w="100%">
          <Box flexDir="row" justifyContent="space-between" alignItems="center">
            <Heading flex={1} mr="10px" fontSize="15px" fontWeight={600}>
              {t('travelCompensation')}
            </Heading>

            <NumberInput
              min={0}
              value={travelCompensation}
              changeRate={25}
              onChange={setTravelCompensation}
              formatValue={val =>
                formatMoney({
                  amount: val,
                  currency: 'EUR'
                })
              }
            />
          </Box>
          <Text mt="15px" fontSize="14px">
            {t('travelCompensationDesc')}
          </Text>
        </Box>
      </Card>

      <Card>
        <Box w="100%" flexDir="row" justifyContent="space-between" alignItems="center">
          <Heading fontSize="14px" fontWeight={400}>
            {t('bookingPersonalMessageLabel')}
          </Heading>
          <HStack space="5px" alignItems="center">
            <Text fontSize="12px" color="primary.400">
              {t('required')}
            </Text>
            <CharlyIcon name="icn-edit" size={20} color={colors.primary[400]} />
          </HStack>
        </Box>

        <TextInput
          onChangeText={setPersonalMessage}
          value={personalMessage}
          placeholder={
            bookingServiceType &&
            (bookingServiceType === BookingServiceType.PET_CARE ||
              bookingServiceType === BookingServiceType.ELDERLY_CARE)
              ? t(`jobPostPersonalMessagePlaceholder.${bookingServiceType}`)
              : t('jobboardPostPersonalMessagePlaceholder')
          }
          maxLength={250}
          mt="5px"
          h="110px"
          variant="filled"
          multiline
          numberOfLines={5}
        />
      </Card>
    </ScreenLayout>
  )
}

export default JobPostScreen

JobPostScreen.navigationOptions = {
  headerShown: false
}
