import React from 'react'
import { Card, ScreenLayout } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { Button, Heading, Text } from 'native-base'
import { isWeb, useRouter } from '@charlycares/shared'
import { useHistory } from 'react-router-dom'

import { InsuranceCard } from '../common'

const AngelBookingRequestSentScreen = ({ navigation }: any) => {
  const { t } = useTranslation()
  const { navigate } = useRouter()
  const history = useHistory()

  return (
    <ScreenLayout
      hideBackButton
      title={t('bookScreensRequestSent')}
      headerRight={
        <Button
          onPress={() => {
            if (isWeb) {
              history.replace('/bookings')
            } else {
              navigation.dismiss()
              navigate('BookingsScreen')
            }
          }}
          variant="text"
        >
          {t('done')}
        </Button>
      }
    >
      <Card mt="10px">
        <Heading fontSize="18px" fontWeight={600}>
          {t('angelBookingRequestSentTitle')}
        </Heading>
        <Text mt="15px">{t('angelBookingRequestSentDesc')}</Text>
      </Card>

      <InsuranceCard />
    </ScreenLayout>
  )
}

export default AngelBookingRequestSentScreen

AngelBookingRequestSentScreen.navigationOptions = {
  headerShown: false
}
