import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
  getAppointmentTimeslots,
  makeAppointment
} from '../../../stores/actions/angelAppointmentAction'
import { TextTextView, IconButton, TextButton, Alert, Header } from '../../../components'
import { getFormatedDate } from '../../../utils/functions'
import colors from '../../../styles/colors'

import arrowRightIcon from '../../../../assets/icons/icn-nav-right.png'
import arrowLeftIcon from '../../../../assets/icons/icn-nav-left.png'
import { Linking } from 'react-native'

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  justify-content: space-between;
  padding: 10px 0px 0px 0px;
  overflow: hidden;
`
const StyledFlatList = styled.FlatList.attrs(({ styleContainer }) => ({
  contentContainerStyle: {
    padding: styleContainer ? '5px' : 0,
    marginBottom: styleContainer ? '50px' : 0
  }
}))``
const Footer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  border-top-width: 1px;
  border-color: ${colors.grey08};
`
const StyledActivityIndicator = styled.ActivityIndicator`
  position: absolute;
  top: 50%;
  left: 50%;
`

const ScheduleAppointment = ({
  location,
  history,
  route,
  navigation,
  loading,
  appointmentTimeslots,
  timeslotsError,
  makeNewAppointment,
  getAppointmentTimeslotsData
}) => {
  const { appointmentLocation } = route.params || location.state || {}
  const { id, city, address, postalcode } = appointmentLocation || {}
  const [currentWeek, setCurrentWeek] = useState(0)
  const [when, setWhen] = useState('')
  const [appointmentId, setAppiontmentId] = useState()
  const [t] = useTranslation()
  const alertRef = useRef(null)
  const selectedWeek = moment().add(currentWeek, 'weeks')
  const { timeslots = [], showNextWeek, showPreviousWeek } = appointmentTimeslots
  const rearangedApointmentTimeslotss = timeslots.map(
    (timeslot, index) => timeslots[(index * 28) % (timeslots.length - 1) || index]
  )

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <TextTextView
          flexDirection="column"
          firstText={selectedWeek.format('MMMM YYYY')}
          firstTextAlign="center"
          firstFontWeight="600"
          firstTextFontFamily="Martel"
          secondText={city || ''}
          secondTextAlign="center"
        />
      ),
      headerLeft: (
        <IconButton
          iconName="close"
          iconSize={32}
          iconColor={colors.secondary}
          onPress={() => {
            if (navigation) {
              navigation.goBack()
            } else {
              history.goBack()
            }
          }}
        />
      ),
      headerRight: (
        <TextButton
          text={t('support')}
          fontWeight="normal"
          textColor={colors.secondary}
          onPress={() => Linking.openURL('https://support.charlycares.com')}
        />
      )
    })
  }

  useEffect(() => {
    if (timeslotsError) {
      console.log({ timeslotsError })
      if (navigation) {
        navigation.goBack()
      } else {
        history.goBack()
      }
    }
  }, [timeslotsError])

  useEffect(() => {
    getAppointmentTimeslotsData(id, selectedWeek.week(), selectedWeek.year())
  }, [currentWeek])

  const onConfirmAppointmentPress = (appointmentid, startDate, endDate) => {
    setAppiontmentId(appointmentid)
    setWhen(
      `${getFormatedDate(startDate, 'dddd, MMMM DD, YYYY')}\n${getFormatedDate(
        startDate,
        'HH:mm'
      )} ${t('to')} ${getFormatedDate(endDate, 'HH:mm')}\n`
    )
    alertRef.current.setIsVisible(true)
  }

  const getWeeksDays = () => {
    let startOfWeeks = selectedWeek.startOf('isoWeek').format('YYYY-MM-DD')
    const days = []
    for (let i = 0; i < 7; i += 1) {
      days.push(startOfWeeks)
      startOfWeeks = moment(startOfWeeks).add(1, 'day').format('YYYY-MM-DD')
    }
    return days
  }

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          left={
            <IconButton
              iconName="close"
              iconSize="32px"
              iconColor={colors.secondary}
              onPress={history.goBack}
            />
          }
          title={selectedWeek.format('MMMM YYYY')}
          subtitle={city || ''}
          right={
            <TextButton
              text={t('support')}
              fontWeight="normal"
              textColor={colors.secondary}
              onPress={() => history.push('/support')}
            />
          }
        />
      )}
      {timeslots.length && (
        <>
          <Header>
            {getWeeksDays().map((item, index) => {
              const isInPast = moment(item).isBefore(moment().format('YYYY-MM-DD'))

              return (
                <TextTextView
                  key={item}
                  flexDirection="column"
                  width={`${100 / 7}%`}
                  borderBottomWidth="1px"
                  firstText={t(`calendarScreensMonth${index}`)}
                  firstTextFontSize="10px"
                  firstTextFontWeight="600"
                  firstTextColor={isInPast ? colors.defaultBtnBackgroundColor : colors.grey02}
                  secondText={getFormatedDate(item, 'DD')}
                  secondTextFontSize="15px"
                  secondTextFontWeight="600"
                  secondTextColor={isInPast ? colors.defaultBtnBackgroundColor : colors.primaryText}
                />
              )
            })}
          </Header>

          <StyledFlatList
            styleContainer
            data={rearangedApointmentTimeslotss}
            renderItem={({ item, index }) => {
              const { available, start_date, end_date } = item

              return (
                <TextButton
                  disabled={!available}
                  flex={1}
                  justifyContent="center"
                  paddings="10px 0px 10px 0px"
                  margins={`0px ${(index + 1) % 7 ? 1 : 0}px ${
                    index + 7 < rearangedApointmentTimeslotss.length ? 1 : 0
                  }px 0px`}
                  backgroundColor={available ? colors.greenLight : colors.grey08}
                  borderRadius="0px"
                  text={getFormatedDate(start_date, 'HH:mm')}
                  fontSize="13px"
                  fontWeight="600"
                  textColor={available ? colors.primaryText : colors.grey04}
                  onPress={() => onConfirmAppointmentPress(item.id, start_date, end_date)}
                />
              )
            }}
            numColumns={7}
            keyExtractor={(item, index) => `${item}-${index}`}
          />

          <Footer>
            <IconButton
              disabled={!showPreviousWeek}
              iconSource={arrowLeftIcon}
              iconSize="32px"
              iconColor={!showPreviousWeek ? colors.defaultBtnBackgroundColor : colors.secondary}
              onPress={() => setCurrentWeek(currentWeek - 1)}
            />

            <IconButton
              disabled={!showNextWeek}
              iconSource={arrowRightIcon}
              iconSize="32px"
              iconColor={!showNextWeek ? colors.defaultBtnBackgroundColor : colors.secondary}
              onPress={() => setCurrentWeek(currentWeek + 1)}
            />
          </Footer>

          <Alert
            ref={alertRef}
            title={t('signUpAngelScreensConfirmAppointment')}
            subtitle={t('signUpAngelScreensConfirmAppointmentDetails', {
              0: when,
              1: `\n${city}\n${address}\n${postalcode}`
            })}
            onCancelPress={() => alertRef.current.setIsVisible(false)}
            onConfirmPress={() => {
              makeNewAppointment(appointmentId)
              analytics.track('AScreeningAppointment')
              if (navigation) {
                navigation.goBack()
              } else {
                history.goBack()
              }
            }}
          />
        </>
      )}
      {loading && <StyledActivityIndicator />}
    </SafeAreaView>
  )
}

ScheduleAppointment.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({ appointmentLocation: PropTypes.shape({}), date: PropTypes.string })
  }),
  history: PropTypes.shape({ push: PropTypes.func, goBack: PropTypes.func }),
  route: PropTypes.shape({
    params: PropTypes.shape({ appointmentLocation: PropTypes.shape({}), date: PropTypes.string })
  }),
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    setOptions: PropTypes.func,
    goBack: PropTypes.func
  }),
  loading: PropTypes.bool,
  appointmentTimeslots: PropTypes.shape({
    timeslots: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        available: PropTypes.bool,
        start_date: PropTypes.string,
        end_date: PropTypes.string
      })
    ),
    showNextWeek: PropTypes.bool,
    showPreviousWeek: PropTypes.bool
  }),
  timeslotsError: PropTypes.string,
  getAppointmentTimeslotsData: PropTypes.func.isRequired,
  makeNewAppointment: PropTypes.func.isRequired
}

ScheduleAppointment.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null,
  loading: false,
  appointmentTimeslots: {},
  timeslotsError: ''
}

const mapStateToProps = ({ AngelAppointment }) => ({
  loading: AngelAppointment.loading,
  appointmentTimeslots: AngelAppointment.appointmentTimeslots,
  timeslotsError: AngelAppointment.timeslotsError
})

const mapDispatchToProps = {
  getAppointmentTimeslotsData: getAppointmentTimeslots,
  makeNewAppointment: makeAppointment
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleAppointment)
