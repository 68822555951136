import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
import { Box, Icon, MembershipLoader, Text, Title } from '@charlycares/ui'
import { useGetAvailableMembershipsQuery, GetAvailableMembershipsResponse } from '@charlycares/data-access'
import {
  DISPLAYED_TERMS,
  AVAILABLE_MEMBERSHIPS,
  MEMBERSHIP_TYPES,
  setStyle,
  decomposeMembershipType,
  AvailableMembershipDetails,
  formatMoney,
  AvailableMembership,
  MembershipsTerm
} from '@charlycares/shared'

type MembershipSelectorProps = {
  editable?: boolean
  membership_type: MEMBERSHIP_TYPES
  onChange?: (membershipType: MEMBERSHIP_TYPES) => void
}

export default function MembershipSelector({ editable, membership_type, onChange }: MembershipSelectorProps) {
  const { t } = useTranslation()
  const { colors, fonts } = useTheme()
  const { isLoading, data: availableMemberships } = useGetAvailableMembershipsQuery()

  const { selectedPlan, selectedTerm, selectedMembership } = useMemo(() => {
    const { membershipPlan, membershipTerm } = decomposeMembershipType(membership_type)
    const selectedPlan = membershipPlan || AVAILABLE_MEMBERSHIPS[1]
    const selectedTerm = membershipTerm || ('monthly' as MembershipsTerm)
    const selectedMembership = availableMemberships?.[selectedPlan]?.[selectedTerm]

    return { selectedPlan, selectedTerm, selectedMembership }
  }, [membership_type, availableMemberships])

  const detailsKeysMap = {
    costs:
      selectedTerm === 'monthly'
        ? t('membershipSelector.details.costs.monthly')
        : t('membershipSelector.details.costs.monthly'),
    fee_costs: t('membershipSelector.details.feeCosts'),
    monthly_terminable: t('membershipSelector.details.monthlyTerminable'),
    support: t('membershipSelector.details.personalSupport'),
    insurance: t('membershipSelector.details.insurance', {
      insurancePrice: selectedMembership ? formatMoney(selectedMembership.insurance_costs) : ''
    })
  }

  const changeMembershipType = (plan: string, term: string) => {
    onChange?.(`${plan}_${term}` as MEMBERSHIP_TYPES)
  }

  return (
    <Box m="20px 0">
      <Box center m="0 0 15px 0">
        <Title align="center" font={fonts.openSans.semiBold}>
          {t('membershipSelector.title')}
        </Title>
        <Text color={colors.grey04} size={12}>
          {t('membershipSelector.subTitle')}
        </Text>
      </Box>

      <MembershipLoader loading={isLoading}>
        <Box row background={colors.grayAlpha(0.15)} p={2} radius={9}>
          {AVAILABLE_MEMBERSHIPS.map(name => (
            <PlanButton
              key={name}
              hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
              disabled={!editable}
              selected={selectedPlan === name}
              onPress={() => changeMembershipType(name, selectedTerm)}
            >
              <Text transform="capitalize" align="center" font={fonts.openSans.semiBold} size={16}>
                {name}
              </Text>
            </PlanButton>
          ))}
        </Box>

        <Box m="20px 0">
          {!!availableMemberships &&
            Object.keys(detailsKeysMap).map(key => (
              <Box center m="10px 0" key={key}>
                <Text margin="0 0 7px 0">{detailsKeysMap[key as keyof typeof detailsKeysMap]}</Text>
                <Box row>
                  {AVAILABLE_MEMBERSHIPS.map(plan => {
                    const isSelected = selectedPlan === plan

                    const membership = availableMemberships[plan as keyof GetAvailableMembershipsResponse]
                    const membershipDetails = membership[
                      selectedTerm as keyof AvailableMembership
                    ] as AvailableMembershipDetails
                    const value = membershipDetails[key as keyof AvailableMembershipDetails]

                    return (
                      <Details key={plan} selected={isSelected}>
                        {typeof value === 'boolean' ? (
                          <Icon
                            name={value ? 'icn-check' : 'nav-close'}
                            size={30}
                            color={isSelected ? colors.primaryDark : colors.grey05}
                          />
                        ) : (
                          <DetailText selected={isSelected}>
                            {typeof value === 'string' ? value : formatMoney(value)}
                          </DetailText>
                        )}
                      </Details>
                    )
                  })}
                </Box>
              </Box>
            ))}
        </Box>

        <Box row background={colors.grayAlpha(0.15)} p={2} radius={9}>
          {DISPLAYED_TERMS.map(term => (
            <PlanButton
              key={term}
              hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
              disabled={!editable}
              selected={selectedTerm === term}
              onPress={() => changeMembershipType(selectedPlan, term)}
            >
              <Text transform="capitalize" align="center" font={fonts.openSans.semiBold} size={16}>
                {t(`membershipSelector.term.${term}`)}
              </Text>
            </PlanButton>
          ))}
        </Box>

        <Box row width="100%">
          <Box flex={1} />
          <Box flex={1} justify="center" align="center">
            <Discount>{t('membershipSelector.discount')}</Discount>
          </Box>
        </Box>

        <Text color={colors.grey03} align="center" w="100%" size={12} margin="30px 0 0 0">
          {t('membershipSelector.disclaimer')}
        </Text>
      </MembershipLoader>
    </Box>
  )
}

MembershipSelector.defaultProps = {
  membership_type: MEMBERSHIP_TYPES.FLEXIBLE_MONTHLY
}

const PlanButton = styled.TouchableOpacity<{ selected?: boolean }>`
  ${p => setStyle('elevation', 2, 2, Platform.OS === 'android' || p.selected)}
  background-color: ${p => (p.selected ? p.theme.colors.cardBackground : 'transparent')};
  flex: 1;
  border-radius: 6px;
  padding: 5px;
  justify-content: center;
  align-items: center;
`

const Details = styled.View<{ selected?: boolean }>`
  background-color: ${p => (p.selected ? p.theme.colors.primaryAlpha(0.22) : 'transparent')};
  flex: 1;
  border-radius: 6px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  height: 40px;
`

const Discount = styled(Text)`
  color: ${p => p.theme.colors.secondary};
  font-size: 14px;
`

const DetailText = styled(Text)<{ selected?: boolean }>`
  font-size: 16px;
  ${p => setStyle('font-family', p.theme.fonts.openSans.semiBold, undefined, !p.selected)}
  ${p => setStyle('color', p.selected ? p.theme.colors.primaryDark : p.theme.colors.grey03)}
`
