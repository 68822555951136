import React, { useRef, useEffect, useState } from 'react'
import { Linking } from 'react-native'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useGetLandingScreensQuery, useGetNearestAngelsQuery } from '@charlycares/data-access'

import theme from '../../styles/theme'
import { getLatestAngelReviews, toggleLikeAngelDashboard } from '../../stores/actions/dashboardAction'
import { setAnnouncementRead } from '../../stores/actions/userAction'
import {
  TextButton,
  AppFeatures,
  Stories,
  CharlysShop,
  RegularBabysitter,
  SittersInYourArea,
  Faq,
  ParentReviews,
  Announcements
} from '../../components'
import { Heading } from '../../elements'

import headerBackground from '../../../assets/images/img-parent-dashboardheader.png'
import { DownloadAppOverlay, InviteReferrals, useHandleDownloadAppOverlay } from '@charlycares/features'
import { Box } from 'native-base'
import { isWeb } from '@charlycares/shared'

const SafeAreaView = styled.SafeAreaView`
  flex: 1 1 auto;
`
const SectionContainer = styled.View`
  padding-left: ${theme.spacer.xl};
  padding-right: ${theme.spacer.xl};
`
const ScrollView = styled.ScrollView`
  flex: 1 1 auto;
`
const Image = styled.Image`
  width: 100%;
  height: 300px;
  margin-top: 50px;
`
const StickySearchView = styled.View`
  padding: 16px;
  width: 100%;
  border-bottom-width: 1px;
  border-color: ${theme.colors.grey08};
  background-color: ${theme.colors.white};
`

const Family = ({ history, navigation, user, reviews, onAnnouncementsRead, likeAngel, getLatestReviews }) => {
  const { data, isLoading } = useGetNearestAngelsQuery()
  const { data: screenData, isSuccess: screensLoadedSuccessfully } = useGetLandingScreensQuery()
  const [t] = useTranslation()
  const { shouldShowDownloadAppOverlay } = useHandleDownloadAppOverlay()

  const [showDownloadApp, setShowDownloadApp] = useState(false)
  const modalRef = useRef(null)

  const { first_name, membership, has_unread_announcements, announcements, locale } = user
  const wasCanceled = membership && membership.active_payments && membership.current_state === 'canceled'
  const localeLink = locale === 'en_GB' ? 'en/' : ''
  const webUrl = 'https://charlycares.com/'

  useEffect(() => {
    getLatestReviews('family')
  }, [])

  useEffect(() => {
    if (modalRef && modalRef.current) {
      modalRef.current.setIsVisible(has_unread_announcements)
    }
  }, [has_unread_announcements])

  useEffect(async () => {
    shouldShowDownloadAppOverlay().then(show => {
      setShowDownloadApp(show)
    })
  }, [])

  if (screensLoadedSuccessfully && screenData.current_screen.web_path !== '/discover') {
    history.push({ pathname: screenData.current_screen.web_path, state: [] })
  }

  return (
    <SafeAreaView>
      <ScrollView showsVerticalScrollIndicator={false} stickyHeaderIndices={[1]}>
        <SectionContainer>
          <Image resizeMode="contain" source={headerBackground} />

          <Heading
            xxl
            ariaLevel={2}
            textAlign="center"
            text={
              wasCanceled
                ? t('dashboardScreensFamilyWasCanceledTitleWeb', { first_name: first_name })
                : t('dashboardScreensFamilyTitleWeb', { first_name: first_name })
            }
          />
        </SectionContainer>

        <StickySearchView>
          <TextButton
            backgroundColor={theme.colors.primary}
            width="500px"
            margins="0 auto"
            text={t('dashboardScreensSearchNow')}
            textColor={theme.colors.white}
            onPress={() => {
              history.push('/booking-request-types-list')
            }}
          />
        </StickySearchView>

        <SittersInYourArea
          availableAngels={data?.data}
          fetchingAvailableAngels={isLoading}
          onAngelPress={(id, obscured_id) => {
            history.push({
              pathname: '/profile-angel',
              state: { angelId: obscured_id || id }
            })
          }}
          onAngelVideoPress={video => {
            if (navigation) {
              navigation.navigate('VideoPlayer', { source: video })
            }
          }}
          onAngelToggleLikePress={({ id, liked }) => likeAngel(id, liked)}
        />

        <RegularBabysitter
          isAngel={false}
          locale={localeLink}
          onReadMorePress={uri => {
            if (navigation) {
              navigation.navigate('WebView', { uri })
            } else {
              Linking.openURL(uri)
            }
          }}
        />

        <AppFeatures
          isAngel={false}
          locale={localeLink}
          onFeaturePress={uri => {
            if (navigation) {
              navigation.navigate('WebView', { uri })
            } else {
              Linking.openURL(uri)
            }
          }}
        />

        <Box m="20px">
          <InviteReferrals />
        </Box>

        <Stories
          onReadMorePress={() => {
            Linking.openURL(t('dashboardFamilyStoryUrl'))
          }}
        />

        <CharlysShop onGoToShopPress={() => history.push('/profile-promotions')} isAngel={false} />

        <Faq
          isAngel={false}
          locale={localeLink}
          onQuestionsPress={uri => {
            if (navigation) {
              navigation.navigate('WebView', { uri })
            } else {
              Linking.openURL(uri)
            }
          }}
          onMoreQuestionsPress={() => {
            Linking.openURL('https://support.charlycares.com')
          }}
        />

        <ParentReviews reviews={reviews.slice(0, 3)} />
      </ScrollView>

      {announcements && (
        <Announcements
          ref={modalRef}
          history={history}
          announcements={announcements}
          onClose={redirect => {
            onAnnouncementsRead()
            if (redirect) {
              history.push(redirect)
            }
          }}
        />
      )}

      {isWeb && <DownloadAppOverlay isOpen={true} />}
    </SafeAreaView>
  )
}

Family.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }),
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    addListener: PropTypes.func
  }),
  user: PropTypes.shape({
    locale: PropTypes.string,
    first_name: PropTypes.string,
    membership: PropTypes.shape({
      active_payments: PropTypes.bool,
      current_state: PropTypes.string
    }),
    has_unread_announcements: PropTypes.bool,
    announcements: PropTypes.shape({})
  }),
  availableAngels: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  fetchingAvailableAngels: PropTypes.bool.isRequired,
  standbyAngels: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  fetchingStandbyAngels: PropTypes.bool.isRequired,
  reviews: PropTypes.arrayOf(PropTypes.shape({})),
  getLatestReviews: PropTypes.func.isRequired,
  onAnnouncementsRead: PropTypes.func.isRequired,
  getAvailableAngelsData: PropTypes.func.isRequired,
  getStandbyAngelsData: PropTypes.func.isRequired,
  likeAngel: PropTypes.func.isRequired
}

Family.defaultProps = {
  history: null,
  navigation: null,
  user: null,
  availableAngels: {},
  standbyAngels: {},
  reviews: []
}

const mapStateToProps = ({ User, Dashboard }) => ({
  user: User.user,
  reviews: Dashboard.reviews,
  availableAngels: Dashboard.availableAngels,
  standbyAngels: Dashboard.standbyAngels,
  fetchingStandbyAngels: Dashboard.fetchingStandbyAngels,
  fetchingAvailableAngels: Dashboard.fetchingAvailableAngels
})

const mapDispatchToProps = {
  getLatestReviews: getLatestAngelReviews,
  onAnnouncementsRead: setAnnouncementRead,
  likeAngel: toggleLikeAngelDashboard
}

export default connect(mapStateToProps, mapDispatchToProps)(Family)
