import { Platform } from 'react-native'

const fontConfig = {
  OpenSans: {
    100: {
      normal: 'OpenSans-Light',
      italic: 'OpenSans-LightItalic'
    },
    200: {
      normal: 'OpenSans-Light',
      italic: 'OpenSans-LightItalic'
    },
    300: {
      normal: 'OpenSans-Light',
      italic: 'OpenSans-LightItalic'
    },
    400: {
      normal: 'OpenSans-Regular',
      italic: 'OpenSans-Italic'
    },
    500: {
      normal: 'OpenSans-SemiBold',
      italic: 'OpenSans-SemiboldItalic'
    },
    600: {
      normal: 'OpenSans-SemiBold',
      italic: 'OpenSans-SemiboldItalic'
    },
    700: {
      normal: 'OpenSans-Bold',
      italic: 'OpenSans-BoldItalic'
    },
    800: {
      normal: 'OpenSans-ExtraBold',
      italic: 'OpenSans-ExtraBoldItalic'
    },
    900: {
      normal: 'OpenSans-ExtraBold',
      italic: 'OpenSans-ExtraBoldItalic'
    }
  },
  Martel: {
    100: {
      normal: 'Martel-UltraLight'
    },
    200: {
      normal: 'Martel-UltraLight'
    },
    300: {
      normal: 'Martel-Light'
    },
    400: {
      normal: 'Martel-Regular'
    },
    500: {
      normal: 'Martel-DemiBold'
    },
    600: {
      normal: 'Martel-DemiBold'
    },
    700: {
      normal: 'Martel-Bold'
    },
    800: {
      normal: 'Martel-ExtraBold'
    },
    900: {
      normal: 'Martel-Heavy'
    }
  }
}

const fonts = {
  heading: Platform.select({
    web: 'Open Sans',
    native: 'OpenSans'
  }),
  body: Platform.select({
    web: 'Open Sans',
    native: 'OpenSans'
  }),
  mono: Platform.select({
    web: 'Open Sans',
    native: 'OpenSans'
  })
}

export default { fontConfig, fonts }
