import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setOnboardingProgress, updateProfileVideo } from '../../../stores/actions/userAction';
import { Text } from '../../../elements';
import { TextView, TextButton, Progress, VideoEdit, Header } from '../../../components';
import colors from '../../../styles/colors';

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  justify-content: space-between;
`;
const View = styled.View`
  ${({ flexDirection }) => flexDirection && { 'flex-direction': flexDirection }}
  ${({ paddings }) => paddings && { padding: paddings }};
  ${({ borderTopWidth }) => borderTopWidth && { 'border-top-width': borderTopWidth }};
  border-color: ${colors.grey08};
`;

const Video = ({
  location,
  history,
  route,
  navigation,
  user,
  uploadingVideo,
  uploadProfileVideo,
  setProgress
}) => {
  const { progress = 99 } = route.params || location.state || {};
  const { video_upload_url, angel } = user;
  const [video, setVideo] = useState(null);
  const [videoPaused, setVideoPaused] = useState(false);
  const [t] = useTranslation();

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize={18} fontWeight="600">
          {`${progress}% ${t('complete')}`}
        </Text>
      )
    });
  }

  useEffect(() => {
    setProgress(progress);
  }, []);

  useEffect(() => {
    if (!uploadingVideo && video) {
      if (navigation) {
        navigation.popToTop();
      } else {
        history.push({ pathname: '/signup-angel-step5', state: { progress: 100 } });
      }
    }
  }, [uploadingVideo]);

  const onEditVideoPress = (event) => {
    console.log(URL.createObjectURL(event.target.files[0]));
    setVideo(URL.createObjectURL(event.target.files[0]));
  };

  return (
    <SafeAreaView>
      {!navigation && <Header title={`${progress}% ${t('complete')}`} />}
      <View>
        <Progress progress={progress} color={colors.primary} />

        <TextView
          flexDirection="column"
          alignItems="flex-start"
          margins="20px"
          text={t('signUpAngelScreensAngelVideo')}
          fontFamily="Martel"
          fontSize="18px"
          fontWeight="600">
          <Text margins="10px 0px 20px 0px" fontWeight="300">
            {t('signUpAngelScreensVideoDescription')}
          </Text>

          <Text margins="0px 0px 20px 0px" fontWeight="300">
            {t('signUpAngelScreensVideoRules')}
          </Text>

          <VideoEdit
            validate={false}
            source={video || angel.video}
            paused={videoPaused}
            onEditPress={onEditVideoPress}
            onTogglePlayPress={() => setVideoPaused(!videoPaused)}
            onStartExampleVideoPress={() => {
              if (navigation) {
                navigation.navigate('VideoPlayer', {
                  source: 'https://s3.eu-central-1.amazonaws.com/charlycares-videos/Mijn+film.mp4'
                });
              } else {
                setVideo('https://s3.eu-central-1.amazonaws.com/charlycares-videos/Mijn+film.mp4');
              }
            }}
          />
        </TextView>
      </View>

      <View
        flexDirection="row-reverse"
        paddings="10px 20px 10px 20px"
        margins="0px"
        borderTopWidth="1px">
        <TextButton
          width="50%"
          backgroundColor={colors.primary}
          text={t('next')}
          onPress={() => {
            if (!uploadingVideo) {
              setProgress(progress + 1 >= 100 ? 100 : progress + 1);
              if (video !== angel.video) {
                uploadProfileVideo(video_upload_url, video);
              } else if (navigation) {
                navigation.popToTop();
              } else {
                history.push({ pathname: '/signup-angel-step5', state: { progress: 100 } });
              }
            }
          }}
        />
      </View>

      {/* <OverlayMessage visible={uploadingVideo} message={t('profileScreensVideoUploadingMessage')} /> */}
    </SafeAreaView>
  );
};

Video.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      progress: PropTypes.number
    })
  }),
  history: PropTypes.shape({ push: PropTypes.func, goBack: PropTypes.func }),
  route: PropTypes.shape({
    params: PropTypes.shape({
      progress: PropTypes.number
    })
  }),
  navigation: PropTypes.shape({
    setOptions: PropTypes.func,
    navigate: PropTypes.func,
    popToTop: PropTypes.func
  }),
  user: PropTypes.shape({
    video_upload_url: PropTypes.string,
    angel: PropTypes.shape({ video: PropTypes.string })
  }).isRequired,
  uploadingVideo: PropTypes.bool,
  setProgress: PropTypes.func.isRequired,
  uploadProfileVideo: PropTypes.func.isRequired
};

Video.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null,
  uploadingVideo: false
};

const mapStateToProps = ({ User }) => ({
  user: User.user,
  uploadingVideo: User.uploadingVideo
});

const mapDispatchToProps = {
  setProgress: setOnboardingProgress,
  uploadProfileVideo: updateProfileVideo
};

export default connect(mapStateToProps, mapDispatchToProps)(Video);
