import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { ContainerView, Heading, Text } from '../../elements'
import { TextView } from '../Texts'
import theme from '../../styles/theme'

import jobFinderImage from '../../../assets/images/2004-App-job-finder.jpg'
import calendarImage from '../../../assets/images/2004-App-angel-agenda.jpg'
import standByImage from '../../../assets/images/2004-App-stand-by-2.jpg'
import profilePreferenceImage from '../../../assets/images/2004-App-profiel-voorkeuren.jpg'
import importCalnedarImage from '../../../assets/images/2004-App-angel-importeer-agenda.jpg'
import insuranceImage from '../../../assets/images/2004-App-trust-page2.jpg'
import shareImage from '../../../assets/images/2004-App-angel-delen.jpg'
import skillsImage from '../../../assets/images/2004-App-profiel-voorkeuren.jpg'
import chatImage from '../../../assets/images/2004-App-chat-2.jpg'

const getAngelAppFeatures = (locale, t) => [
  {
    title: t('jobFinder'),
    isNew: false,
    image: jobFinderImage,
    link: `https://www.charlycares.com/${locale}jobfinder-angel`
  },
  {
    title: t('calendar'),
    isNew: false,
    image: calendarImage,
    link: `https://www.charlycares.com/${locale}angel-agenda`
  },
  {
    title: t('standBy'),
    isNew: false,
    image: standByImage,
    link: `https://www.charlycares.com/${locale}stand-by`
  },
  {
    title: t('dashboardScreensProfileAndPreferences'),
    isNew: false,
    image: profilePreferenceImage,
    link: `https://www.charlycares.com/${locale ? `${locale}profile-preferences` : 'profiel-voorkeuren'}`
  },
  {
    title: t('calendarScreensImportPrivateCalendar'),
    isNew: false,
    image: importCalnedarImage,
    link: `https://www.charlycares.com/${locale ? `${locale}import-calendar` : 'importeer-agenda'}`
  }
]

const getFamilyAppFeatures = (locale, t) => [
  {
    title: t('jobFinder'),
    isNew: false,
    image: jobFinderImage,
    link: `https://www.charlycares.com/${locale}jobfinder`
  },
  {
    title: t('dashboardScreensInsuranceAndMedicalHelpline'),
    isNew: false,
    image: insuranceImage,
    link: `https://www.charlycares.com/${locale}trust-page`
  },
  {
    title: t('dashboardScreensShareAngel'),
    isNew: false,
    image: shareImage,
    link: `https://www.charlycares.com/${locale ? `${locale}share-angel` : 'angel-delen'}`
  },
  {
    title: t('dashbpardScreensAngelSkills'),
    isNew: false,
    image: skillsImage,
    link: `https://www.charlycares.com/${locale}skills`
  },
  {
    title: t('chatScreensChat'),
    isNew: false,
    image: chatImage,
    link: `https://www.charlycares.com/${locale}chat`
  }
]

const ScrollView = styled.ScrollView`
  width: 100%;
`
const TouchableOpacity = styled.TouchableOpacity`
  margin: 5px;
  width: 140px;
  min-height: 140px;
  border-radius: ${theme.borderRadius.md};
  box-shadow: ${theme.boxShadow};
  background-color: ${theme.colors.white};
`
const Image = styled.Image`
  width: 100%;
  height: 100px;
  border-top-left-radius: ${theme.borderRadius.md};
  border-top-right-radius: ${theme.borderRadius.md};
`
const View = styled.View`
  flex: 1 1 auto;
`

const AppFeatures = ({ isAngel, locale, onFeaturePress }) => {
  const [t] = useTranslation()
  const appFeatures = isAngel ? getAngelAppFeatures(locale, t) : getFamilyAppFeatures(locale, t)

  return (
    <ContainerView
      flexDirection="column"
      alignItems="flex-start"
      paddings="32px 0"
      margins="0 20px"
      borderBottomWidth="1px"
    >
      <Heading xxl ariaLevel={3} text={t('dashboardScreensDiscoverCharlyCares')} />
      <Text margins="0 0 8px 0" lg lighterColor>
        {t('dashboardScreensAppFeatures')}
      </Text>

      <ScrollView horizontal>
        {appFeatures.map(({ title, image, isNew, link }) => (
          <TouchableOpacity key={title} onPress={() => onFeaturePress(link)}>
            <View>
              <Image source={image} />

              {isNew && (
                <TextView
                  fontSize="12px"
                  lineHeight={1.1}
                  position="absolute"
                  top="72px"
                  left="-4px"
                  paddings="1px 7px 1px 7px"
                  borderRadius={theme.borderRadius.sm}
                  backgroundColor={theme.colors.secondary}
                  text="NEW"
                  textColor={theme.colors.white}
                />
              )}

              <Text paddings="4px 8px 8px">{title}</Text>
            </View>
          </TouchableOpacity>
        ))}
      </ScrollView>
    </ContainerView>
  )
}

AppFeatures.propTypes = {
  isAngel: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  onFeaturePress: PropTypes.func.isRequired
}

export default AppFeatures
