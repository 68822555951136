import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { Text } from '../../elements';
import colors from '../../styles/colors';

const Container = styled.View`
  margin: 0px 25px 20px 25px;
`;
const View = styled.View`
  ${({ position }) => position && { position }};
  ${({ justifyContent }) => justifyContent && { 'justify-content': justifyContent }};
  ${({ alignItems }) => alignItems && { 'align-items': alignItems }};
  ${({ radius }) =>
    radius && {
      width: radius * 2,
      height: radius * 2,
      'border-radius': `${radius}px`
    }};
  ${({ width }) => width && { width }};
  ${({ height }) => height && { height }};
  ${({ borderWidth }) => borderWidth && { 'border-width': borderWidth }};
  ${({ borderColor }) => borderColor && { 'border-color': borderColor }};
  ${({ borderTopRightColor }) =>
    borderTopRightColor && {
      'border-top-color': borderTopRightColor,
      'border-right-color': borderTopRightColor,
      'border-bottom-color': 'transparent',
      'border-left-color': 'transparent'
    }};
`;

/**
 * Function that calculates rotation of the semicircle for firstProgressLayer
 * ( when percent is less than equal to 50 ) or for the secondProgressLayer
 * when percent is greater than 50.
 * */
const rotateByStyle = (percent, base_degrees, clockwise) => {
  let rotateBy = base_degrees;
  if (clockwise) {
    rotateBy = base_degrees + percent * 3.6;
  } else {
    // anti clockwise progress
    rotateBy = base_degrees - percent * 3.6;
  }
  return {
    transform: [{ rotateZ: `${rotateBy}deg` }]
  };
};

const renderThirdLayer = (
  radius,
  ringColor,
  innerRadius,
  ringBgColor,
  percent,
  clockwise,
  bgRingWidth,
  progressRingWidth,
  startDegrees
) => {
  let rotation = 45 + startDegrees;
  let offsetLayerRotation = -135 + startDegrees;
  if (!clockwise) {
    rotation += 180;
    offsetLayerRotation += 180;
  }
  if (percent > 50) {
    /**
     * Third layer circles default rotation is kept 45 degrees for clockwise rotation,
     * so by default it occupies the right half semicircle.
     * Since first 50 percent is already taken care  by second layer circle, hence we subtract it
     * before passing to the rotateByStyle function
     * */

    return (
      <View
        position="absolute"
        radius={radius}
        borderWidth={progressRingWidth}
        borderTopRightColor={ringColor}
        style={rotateByStyle(percent - 50, rotation, clockwise)}
      />
    );
  }
  return (
    <View
      position="absolute"
      radius={innerRadius}
      borderWidth={bgRingWidth}
      borderTopRightColor={ringBgColor}
      style={{ transform: [{ rotateZ: `${offsetLayerRotation}deg` }] }}
    />
  );
};

const CircularProgress = ({
  percent,
  radius,
  bgRingWidth,
  progressRingWidth,
  ringColor,
  ringBgColor,
  clockwise,
  bgColor,
  startDegrees,
  text,
  count
}) => {
  /**
   * Calculate radius for base layer and offset layer.
   * If progressRingWidth == bgRingWidth, innerRadius is equal to radius
   * */
  const widthDiff = progressRingWidth - bgRingWidth;
  const innerRadius = radius - progressRingWidth + bgRingWidth + widthDiff / 2;

  let rotation = -135 + startDegrees;
  /**
   * If we want our ring progress to be displayed in anti-clockwise direction
   * */
  if (!clockwise) {
    rotation += 180;
  }
  let firstProgressLayerStyle;
  /* when ther ring's border widths are different and percent is less than 50,
   * then we need an offsetLayer before the original offser layer to avoid
   * ring color of the thick portion to be visible in the background.
   */
  let displayThickOffsetLayer = false;
  if (percent > 50) {
    firstProgressLayerStyle = rotateByStyle(50, rotation, clockwise);
  } else {
    firstProgressLayerStyle = rotateByStyle(percent, rotation, clockwise);
    if (progressRingWidth > bgRingWidth) {
      displayThickOffsetLayer = true;
    }
  }

  let offsetLayerRotation = -135 + startDegrees;
  if (!clockwise) {
    offsetLayerRotation += 180;
  }

  return (
    <Container>
      <View alignItems="center" justifyContent="center" width={radius * 2} height={radius * 2}>
        <View
          position="absolute"
          radius={innerRadius}
          borderWidth={bgRingWidth}
          borderColor={ringBgColor}
        />

        <View
          position="absolute"
          radius={radius}
          borderWidth={progressRingWidth}
          borderTopRightColor={ringColor}
          style={firstProgressLayerStyle}
        />

        {displayThickOffsetLayer && (
          <View
            position="absolute"
            radius={radius}
            borderWidth={progressRingWidth}
            borderTopRightColor={bgColor}
            style={{ transform: [{ rotateZ: `${offsetLayerRotation}deg` }] }}
          />
        )}

        {renderThirdLayer(
          radius,
          ringColor,
          innerRadius,
          ringBgColor,
          percent,
          clockwise,
          bgRingWidth,
          progressRingWidth,
          startDegrees
        )}

        <Text
          fontFamily="OpenSans-SemiBold"
          fontSize="24px"
          fontWeight="600"
          color={colors.secondary}>
          {count}
        </Text>
      </View>

      <Text margins="5px 0px 0px 0px" fontSize="14px" textAlign="center">
        {text}
      </Text>
    </Container>
  );
};

CircularProgress.propTypes = {
  percent: PropTypes.number,
  radius: PropTypes.number,
  bgRingWidth: PropTypes.number,
  progressRingWidth: PropTypes.number,
  ringColor: PropTypes.string,
  ringBgColor: PropTypes.string,
  clockwise: PropTypes.bool,
  startDegrees: PropTypes.number,
  text: PropTypes.string,
  bgColor: PropTypes.string,
  count: PropTypes.number
};

CircularProgress.defaultProps = {
  percent: 0,
  radius: 100,
  bgRingWidth: 10,
  progressRingWidth: 20,
  ringColor: '#3498db',
  ringBgColor: 'grey',
  clockwise: true,
  bgColor: 'white',
  startDegrees: 0,
  text: '',
  count: 0
};

export default CircularProgress;
