import React from 'react';
import styled from 'styled-components/native';
import BoardingImage from '../../../assets/images/boarding.jpg';

const View = styled.View`
  width: 40%;
  min-height: 100dvh;
`;

const Image = styled.Image`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const BoardingBanner = () => {
  return (
    <View>
      <Image
        accessibilityRole="image"
        accessibilityLabel="Charly Cares"
        source={BoardingImage}
      />
    </View>
  );
};

export default BoardingBanner;
