import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BookingCancellationReasonType, useRouter, UserRoles } from '@charlycares/shared'
import { Card, ScreenLayout, TextInput } from '@charlycares/ui'
import { useGetBookingDetailsQuery, useGetUserQuery } from '@charlycares/data-access'
import { Box, Flex, Spacer, Text } from 'native-base'
import { useHandleCancelBooking } from './common'
import FamilyBookingCancellationRate from './family/common/FamilyBookingCancellationRate'

export default function CancelBookingScreen() {
  const { getParam, navigate } = useRouter()
  const { t } = useTranslation()

  const bookingId = getParam('bookingId') as string
  const cancellationReason = getParam('cancellationReason') as BookingCancellationReasonType

  const [message, setMessage] = useState('')

  const { data: user } = useGetUserQuery()
  const { data: booking } = useGetBookingDetailsQuery({ obscured_id: bookingId }, { skip: !bookingId })
  const { onCancelBooking, cancelBookingResponse } = useHandleCancelBooking({ booking: booking, navigation: navigate })

  const cancelBookingError = cancelBookingResponse.error as any

  const onNextStep = () => {
    if (cancellationReason && message && booking) {
      onCancelBooking(booking.obscured_id, cancellationReason, message)
    }
  }

  const getCancellationDescriptionMessage = () => {
    if (cancellationReason === BookingCancellationReasonType.ANGEL_CANCELLED) {
      return t('cancellationMessageDescriptionAngelCancelled')
    } else if (cancellationReason === BookingCancellationReasonType.ANGEL_NO_SHOW) {
      return t('cancellationMessageDescriptionNoShow')
    } else if (user?.role === UserRoles.ANGEL) {
      return t('cancellationMessageDescriptionAngel')
    } else {
      return t('cancellationMessageDescription')
    }
  }

  if (!booking) {
    return <ScreenLayout isLoading title={t('bookingsScreenCancelBooking')} />
  }

  return (
    <ScreenLayout
      progress={`${65}%`}
      supportButton
      title={t('bookingsScreenCancelBooking')}
      subtitle={booking.obscured_id}
      edges={['right', 'left']}
      _bottomContainer={{
        backgroundColor: 'white',
        paddingBottom: '30px'
      }}
      _buttonProps={{
        onPress: onNextStep,
        isDisabled: !message,
        isLoading: cancelBookingResponse.isLoading,
        children: t('bookingsScreenCancelBooking'),
        w: '50%',
        ml: 'auto'
      }}
      _buttonDisclaimer={
        user?.role === UserRoles.FAMILY
          ? {
              children: t('disagreeWithCancellationConsequences')
            }
          : undefined
      }
    >
      {user?.role === UserRoles.FAMILY && (
        <FamilyBookingCancellationRate booking={booking} cancellationReason={cancellationReason} />
      )}

      <Card mt="0px">
        <Text color="gray.900" fontSize="18px" fontWeight="600" mb="10px">
          {cancellationReason === BookingCancellationReasonType.ANGEL_NO_SHOW ||
          cancellationReason === BookingCancellationReasonType.ANGEL_CANCELLED
            ? t('cancellationMessageTitleNoShow')
            : t('cancellationMessageTitle', {
                user:
                  user?.role === UserRoles.FAMILY
                    ? booking.angel?.first_name || 'angel'
                    : `${t('family')} ${booking.family.name}`
              })}
        </Text>
        <Text color="gray.800" fontSize="14px">
          {getCancellationDescriptionMessage()}
        </Text>

        <Box mt="50px" mb="50px">
          <Flex direction="row">
            <Text>{t('sendPersonalMessage')}</Text>
            <Spacer />
            <Text color="red.600" fontSize="14px">
              {t('required')}
            </Text>
          </Flex>

          <TextInput
            isReadOnly={cancelBookingResponse.isLoading}
            value={message}
            error={cancelBookingError?.data?.errors?.message?.[0]}
            onChangeText={setMessage}
            placeholder={t('personalCancellationMessage', {
              user: user?.role === UserRoles.FAMILY ? 'angel' : 'family'
            })}
            mt="10px"
            h={user?.role === UserRoles.FAMILY ? '100px' : '208px'}
            multiline
            variant="filled"
            numberOfLines={5}
            maxLength={500}
            _focus={{
              _important: { bgColor: 'gray.50' }
            }}
          />
        </Box>
      </Card>
    </ScreenLayout>
  )
}

CancelBookingScreen.navigationOptions = () => ({
  headerShown: false
})
