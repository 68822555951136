import React from 'react'
import { Edge, SafeAreaView } from 'react-native-safe-area-context'
import { Box, Factory, Flex, IBoxProps, View } from 'native-base'
import Svg, { Defs, Rect, LinearGradient, Stop } from 'react-native-svg'
import { KeyboardAvoidingView, Platform, StyleSheet } from 'react-native'
import { CharlyIcon } from '../icons'
import { useRouter } from '@charlycares/shared'
import Header from './Header'
import { PlatformScrollView } from '../platform'

const SafeAreaContainer = Factory(SafeAreaView)

type LayoutProps = IBoxProps & {
  children?: React.ReactNode
  edges?: Edge[]
  isLoading?: boolean
  withLogo?: boolean
  withGoBack?: boolean
  headerLeft?: React.ReactNode
  headerRight?: () => React.ReactNode
  headerTitle?: string
  BottomComponent?: React.ReactNode
}

export default function LandingLayout({
  children,
  edges,
  isLoading,
  withLogo,
  withGoBack,
  headerLeft,
  headerTitle,
  headerRight,
  BottomComponent,
  ...boxProps
}: LayoutProps) {
  const { goBack } = useRouter()
  const safeAreaEdges: Edge[] = edges || ['right', 'bottom', 'left']

  if (!headerTitle) {
    safeAreaEdges.push('top')
  }

  return (
    <View style={{ flex: 1 }}>
      <Svg height="100%" width="100%" style={StyleSheet.absoluteFillObject}>
        <Defs>
          <LinearGradient id="grad" x1="0%" y1="0%" x2="0%" y2="100%">
            <Stop offset="0" stopColor="#0B5394" />
            <Stop offset="1" stopColor="#4A8FE2" />
          </LinearGradient>
        </Defs>
        <Rect width="100%" height="100%" fill="url(#grad)" />
      </Svg>

      <Box w="100%" minH="100%" flex={1}>
        {headerTitle && (
          <Header
            title={headerTitle}
            hideBack={!withGoBack}
            bg="secondary.500"
            textColor="white"
            borderWidth={0}
            headerRight={headerRight}
          />
        )}
        <SafeAreaContainer flex={1} edges={safeAreaEdges}>
          <>
            <Box flex={1} {...boxProps}>
              {!headerTitle && (withLogo || withGoBack) && (
                <Flex minH="30px" direction="row" alignItems="center" justify="space-between" px="20px" mt="10px">
                  {!headerLeft && withGoBack && (
                    <CharlyIcon name="icn-nav-left" size="32" color="white" onPress={goBack} />
                  )}
                  {headerLeft && <Box w="auto">{headerLeft}</Box>}
                  {withLogo && <CharlyIcon name="logo-icn" size="44" color="primary.400" />}
                </Flex>
              )}
              <PlatformScrollView>{children}</PlatformScrollView>
            </Box>

            {BottomComponent && (
              <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} keyboardVerticalOffset={20}>
                {BottomComponent}
              </KeyboardAvoidingView>
            )}
          </>
        </SafeAreaContainer>
      </Box>
    </View>
  )
}
