import React from 'react'
import { isIos } from '@charlycares/shared'
import { Card } from '@charlycares/ui'
import { FormikErrors } from 'formik'
import { Box, Switch, Text } from 'native-base'
import { useTranslation } from 'react-i18next'

type Props = {
  editable?: boolean
  values: {
    promo_mail: boolean
    system_mail: boolean
    promo_push: boolean
    system_push: boolean
  }
  setFieldValue: (
    field: string,
    value: boolean,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<any>>
}

export default function NotificationsSettings({ editable, values, setFieldValue }: Props) {
  const { t } = useTranslation()

  return (
    <Box>
      <Text mx="20px" my="5px" fontSize="16px" fontWeight={400} color="gray.700">
        {t('profileScreensNotificationSettings')}
      </Text>
      <Card mt="0">
        <Box w="100%">
          <Text fontSize="16px" fontWeight="bold">
            {t('email')}
          </Text>

          <Box mt="20px" flexDir="row" alignItems="center">
            <Text fontSize="15px" flex={1}>
              {t('profileScreensPromotional')}
            </Text>

            <Switch
              disabled={!editable}
              size={isIos ? 'md' : 'lg'}
              value={values.promo_mail}
              onValueChange={value => {
                setFieldValue('promo_mail', value)
              }}
            />
          </Box>

          <Box mt="20px" flexDir="row" alignItems="center">
            <Text fontSize="15px" flex={1}>
              {t('profileScreensReservationsAndReviews')}
            </Text>

            <Switch
              disabled={!editable}
              size={isIos ? 'md' : 'lg'}
              value={values.system_mail}
              onValueChange={value => {
                setFieldValue('system_mail', value)
              }}
            />
          </Box>

          <Text mt="20px" fontSize="16px" fontWeight="bold">
            {t('profileScreensPushNotifications')}
          </Text>
          <Text fontSize="13px" fontWeight={600} color="gray.700">
            {t('profileScreensForMobileAndTablet')}
          </Text>

          <Box mt="20px" flexDir="row" alignItems="center">
            <Text fontSize="15px" flex={1}>
              {t('profileScreensPromotional')}
            </Text>

            <Switch
              disabled={!editable}
              size={isIos ? 'md' : 'lg'}
              value={values.promo_push}
              onValueChange={value => {
                setFieldValue('promo_push', value)
              }}
            />
          </Box>

          <Box mt="20px" flexDir="row" alignItems="center">
            <Text fontSize="15px" flex={1}>
              {t('profileScreensReservationsAndReviews')}
            </Text>

            <Switch
              disabled={!editable}
              size={isIos ? 'md' : 'lg'}
              value={values.system_push}
              onValueChange={value => {
                setFieldValue('system_push', value)
              }}
            />
          </Box>
        </Box>
      </Card>
    </Box>
  )
}
