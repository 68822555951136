import colors from './colors'
import { spacer, fontSize, borderRadius } from './sizes'

export default {
  mode: 'light',
  colors,
  spacer,
  fontSize,
  borderRadius,

  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',

  statusAcceptedColor: colors.green,
  statusCanceledColor: colors.secondary,
  statusDeclinedColor: colors.alert,
  statusErrorColor: colors.danger,
  statusNeutralColor: colors.grey05,
  statusPendingColor: colors.orange,

  fontFamilyBase: 'Open Sans',
  fontFamilyAlt: 'Martel',

  headingsColorBase: colors.grey01,
  headingsFontWeight: '600',
  headingsMargins: '0 0 4px 0',

  inputLabelFontWeight: '400',
  inputLabelFontColor: colors.grey02,
  inputLabelFontSize: '12px',

  textFontWeight: '400',
  textFontWeightBold: '600',
  textColorBase: colors.grey01,
  textColorLight: colors.grey02,
  textColorLighter: colors.grey03,
  textColorLightest: colors.grey04,
  textLinkColor: colors.secondary,

  dividerLineColor: colors.grey08,
  buttonsFontWeight: '600',
  layoutMainContentPaddingHorizontal: '0 16px',

  ratingStarActive: colors.secondary,
  ratingStarActiveMuted: colors.grey02,
  ratingStarInactive: colors.grey08,

  labelFontSize: '12px',
  labelIconSize: '20px',
  labelBorderRadius: borderRadius.sm,
  labelFixedSitterBg: '#E6E6E685',
  labelFixedSitterColor: colors.grey02,
  labelStandbySitterBg: '#F56B8785',
  labelStandbySitterColor: colors.white,

  chatBackgroundColor: colors.grey07,
  chatBubbleLeftBg: colors.white,
  chatBubbleRightBg: colors.chat,

  fonts: {
    openSans: 'Open Sans'
  }
}
