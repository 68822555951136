import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getUser } from '../../../stores/actions/userAction'
import { Text } from '../../../elements'
import { TextIconView, TextButton, Header } from '../../../components'
import colors from '../../../styles/colors'

import checkIcon from '../../../../assets/icons/icn-check.png'

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  padding: 20px;
`

const BankAccountSuccess = ({ history }) => {
  const { hasBookingPending } = useParams()
  const [t] = useTranslation()

  return (
    <SafeAreaView>
      <Header
        title={hasBookingPending ? t('connectBankAccountHeaderRequestSent') : t('connectBankAccountHeader')}
        right={
          <TextButton
            paddings="0px 10px 0px 0px"
            margins="0px 10px 0px 0px"
            height="auto"
            text={t('ready')}
            fontWeight="normal"
            textColor={colors.secondary}
            onPress={() => {
              history.replace('/discover')
            }}
          />
        }
      />

      <TextIconView
        flexDirection="row-reverse"
        justifyContent="flex-end"
        paddings="10px 20px 20px 0px"
        borderBottomWidth="1px"
        backgroundColor={colors.white}
        iconMargins="0px 10px 0px 0px"
        icon={checkIcon}
        iconSize="32px"
        iconColor={colors.primary}
        text={t('connectBankAccountSuccesfully')}
        textWidth="80%"
        textAlign="left"
      />

      {hasBookingPending && (
        <TextIconView
          flexDirection="row-reverse"
          justifyContent="flex-end"
          paddings="10px 20px 20px 0px"
          borderBottomWidth="1px"
          backgroundColor={colors.white}
          iconMargins="0px 10px 0px 0px"
          icon={checkIcon}
          iconSize="32px"
          iconColor={colors.primary}
          text={t('bookScreensRequestHasBeenSent')}
          textWidth="80%"
          textAlign="left"
        />
      )}

      <Text margins="20px 20px 0px 20px" fontSize="16px" fontWeight="bold">
        {hasBookingPending ? t('connectBankAccountHowDoesItWorkRequestSent') : t('connectBankAccountHowDoesItWork')}
      </Text>

      <Text margins="10px 20px 0px 20px">{t('connectBankAccountHowDoesItWorkFirstExplanation')}</Text>
    </SafeAreaView>
  )
}

BankAccountSuccess.propTypes = {
  location: PropTypes.shape({ state: PropTypes.shape({ hasBookingPending: PropTypes.bool }) }),
  history: PropTypes.shape({ replace: PropTypes.func, push: PropTypes.func }),
  route: PropTypes.shape({ params: PropTypes.shape({ hasBookingPending: PropTypes.bool }) }),
  navigation: PropTypes.shape({
    setOptions: PropTypes.func,
    navigate: PropTypes.func
  }),
  getUserData: PropTypes.func.isRequired
}

BankAccountSuccess.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null
}

const mapDispatchToProps = {
  getUserData: getUser
}

export default connect(null, mapDispatchToProps)(BankAccountSuccess)
