const Button = {
  sizes: {
    sm: {
      _text: {
        fontSize: '11px'
      }
    }
  },
  variants: {
    solid: {
      zIndex: 1,
      bg: 'alert.info.400'
    }
  },
  defaultProps: {
    variant: 'solid',
    rounded: 'full'
  }
}

export default Button
