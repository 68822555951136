import React from 'react'
import { Card, CharlyIcon } from '@charlycares/ui'
import { Avatar, Box, Button, Heading, Text, useTheme } from 'native-base'
import { useTranslation } from 'react-i18next'
import { useGetUserQuery } from '@charlycares/data-access'
import { formatDate, useRouter } from '@charlycares/shared'

const AngelAppointment = () => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { navigate } = useRouter()

  const { data: user } = useGetUserQuery()

  if (!user?.angel?.angel_appointment) {
    return null
  }

  return (
    <Card mt="0">
      <Box flexDir="row" alignItems="center">
        <Heading textAlign="center" flex={1} fontWeight="600" fontSize="18px">
          {t('meetCharlyCares')}
        </Heading>
        <Avatar size="60px" source={{ uri: user?.angel?.angel_appointment?.recruiter.image_url }} />
      </Box>

      <Box flexDir="row" alignItems="center" py="12px" borderBottomWidth="1px" borderColor="gray.200">
        <Text fontSize="15px" flex={1} mr="10px">
          {`${formatDate(user.angel.angel_appointment.timeslot.start_date, 'HH:mm')} ${t('to')} ${formatDate(
            user.angel.angel_appointment.timeslot.end_date,
            'HH:mm'
          )}`}
        </Text>
        <Button
          onPress={() => navigate('EditAppointmentJb', '/', { reschedule: true })}
          _text={{
            color: 'secondary.500'
          }}
          rightIcon={<CharlyIcon name="icn-nav-right" size={20} color={colors.gray[500]} />}
          variant="text"
        >
          {t('edit')}
        </Button>
      </Box>
      <Box flexDir="row" alignItems="center" py="12px" borderBottomWidth="1px" borderColor="gray.200">
        <Text fontSize="15px" flex={1} mr="10px">
          {t('signUpAngelScreensDoYouWantToCancelAppointment')}
        </Text>
        <Button
          onPress={() => navigate('CancelAppointmentJb')}
          _text={{
            color: 'secondary.500'
          }}
          rightIcon={<CharlyIcon name="icn-nav-right" size={20} color={colors.gray[500]} />}
          variant="text"
        >
          {t('cancel')}
        </Button>
      </Box>
    </Card>
  )
}

export default AngelAppointment
