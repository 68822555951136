import styled from 'styled-components/native';
import colors from '../styles/colors';

const Divider = styled.View`
  ${({ flex }) => flex && { flex }};
  min-height: ${({ minHeight }) => minHeight || '10px'};
  background-color: ${colors.backgroundColor};
  border-style: solid;
  border-color: ${colors.grey08};
  border-top-width: 1px;
  border-bottom-width: 1px;
`;

export default Divider;
