import React from 'react'
import { useTranslation } from 'react-i18next'
import { ImageBackground } from 'react-native'
import { BecomeMemberBackground } from '@charlycares/assets'
import { Box, Text, Button } from 'native-base'
import { useMembershipIAP } from './common'
import { isWeb, useRouter } from '@charlycares/shared'
import { useGetMembershipsQuery, useGetSubscriptionQuery } from '@charlycares/data-access'

export default function BecomeMember() {
  useMembershipIAP()

  const { t } = useTranslation()
  const { navigate } = useRouter()
  const { data: currentSubscription, refetch: refetchCurrentSubscription } = useGetSubscriptionQuery(undefined)
  const { data: memberships, refetch: refetchMemberships } = useGetMembershipsQuery(undefined, {
    refetchOnMountOrArgChange: true
  })
  const becomeMember = async () => {
    await refetchCurrentSubscription()
    await refetchMemberships()

    if (currentSubscription?.status === 'active') {
      navigate('FamilyDashboardScreen', '/')

      return
    }

    if (currentSubscription === undefined && memberships?.length === 0) {
      navigate('BookingIsAlmostSentScreen', '/booking-is-almost-sent')

      return
    }

    if (currentSubscription?.status === 'cancelled') {
      navigate('ReactivateMembership', '/reactivate-membership')

      return
    }

    if (currentSubscription?.membership_type.includes('apple')) {
      navigate('AppleIapActivated', '/apple-iap-activated', { membershipType: currentSubscription?.membership_type })
    }
  }

  function background() {
    return isWeb ? null : BecomeMemberBackground
  }

  return (
    <ImageBackground source={background() as any} style={{ height: '100%' }}>
      <Box
        position="absolute"
        backgroundColor="bg.dark.alpha.50"
        bottom={isWeb ? '40%' : 0}
        padding="20px"
        width="100%"
      >
        <Text fontSize={21} width="100%" color="white" textAlign="center">
          {t('becomeMember.title')}
        </Text>

        <Text fontSize={16} marginTop="15px" marginBottom="15px" color="white" width="100%" textAlign="center">
          {t('becomeMember.description')}
        </Text>

        <Button backgroundColor="secondary.400" marginBottom="20px" onPress={becomeMember}>
          {t('becomeMember.button')}
        </Button>
      </Box>
    </ImageBackground>
  )
}
