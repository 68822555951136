import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';

import CharlyIcon from '../Icons/Icon';
import { TextTextView } from '../Texts';
import colors from '../../styles/colors';
import { Icon } from '../../elements';

const View = styled.TouchableOpacity`
  flex-direction: row;
  padding: 20px 0px 20px 0px;
  border-bottom-width: 1px;
  border-color: ${colors.grey08};
`;

const SkillFilterCard = ({ skillIcon, skill, description, selected, onSelectPress, iconName }) => (
  <View onPress={onSelectPress}>
    {iconName ? (
      <CharlyIcon name={iconName} size={30} />
    ) : (
      <Icon source={skillIcon} width="30px" height="30px" />
    )}

    <TextTextView
      width="80%"
      paddings="0px 0px 0px 10px"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      firstText={skill}
      firstTextFontSize="16px"
      secondText={description}
      secondTextAlign="left"
      secondTextFontSize="16px"
      secondTextFontWeight="300"
      secondTextNumberOfLines={3}
    />

    <CharlyIcon
      margins="0px 0px 0px auto"
      size={30}
      name={selected ? 'btn-check' : 'btn-plus'}
      color={selected ? colors.primary : colors.grey07}
    />
  </View>
);

SkillFilterCard.propTypes = {
  skillIcon: PropTypes.node.isRequired,
  skill: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelectPress: PropTypes.func.isRequired,
  iconName: PropTypes.string.isRequired
};

export default SkillFilterCard;
