import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../elements';
import { TextView } from '../Texts';
import { TextButton } from '../Buttons';
import colors from '../../styles/colors';

import giftIcon from '../../../assets/icons/icn-gift.png';

const Referral = ({ title, content, sendText }) => {
  const [t] = useTranslation();

  return (
    <TextView
      flexDirection="column"
      alignItems="flex-start"
      paddings="20px"
      text={title}
      fontSize="16px"
      fontFamily="Martel"
      fontWeight="600">
      <TextView
        margins="10px 0px 0px 0px"
        flexDirection="row-reverse"
        alignItems="flex-start"
        text={content}
        textFlex={1}
        fontWeight="300">
        <Icon
          margins="0px 10px 0px 0px"
          width="40px"
          height="40px"
          source={giftIcon}
          color={colors.secondary}
        />
      </TextView>

      <TextButton
        alignSelf="center"
        margins="20px 0px 0px 0px"
        text={t('shareWithYourFriends')}
        backgroundColor={colors.primary}
        onPress={() => {
          //TODO: web share
        }}
      />
    </TextView>
  );
};

Referral.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  sendText: PropTypes.string.isRequired
};

export default Referral;
