import React, { useEffect, useState } from 'react'
import Echo from 'laravel-echo'
import {
  api,
  AppDispatch,
  ChatMessageCollectionItem,
  ChatMessageItem,
  useAppDispatch,
  useBroadcastTokenMutation,
  useGetUserQuery
} from '@charlycares/data-access'

const StreamingUpdatesContext = React.createContext<{
  connected: boolean
}>({ connected: false })
// @ts-ignore
window.Pusher = require('pusher-js')

const handleChatUpdate = (dispatch: AppDispatch, message: ChatMessageItem) => {
  dispatch(
    // @ts-ignore
    api.util.updateQueryData('getChat', { conversationId: message.contact.conversation_id, cursor: null }, draft => {
      // @ts-ignore
      draft.data.messages.unshift(message)
    })
  )
}

const handleChatListUpdate = (dispatch: AppDispatch, message: string, conversationId: string, unreadCount: number) => {
  // @ts-ignore
  const update = api.util.updateQueryData('getChatList', { cursor: null, order: 'desc' }, draft => {
    // @ts-ignore
    draft.data = draft.data
      .map((item: ChatMessageCollectionItem) => {
        if (item.conversation_id !== conversationId) {
          return item
        }

        return {
          ...item,
          last_message: message,
          unread_count: unreadCount
        }
      })
      .sort((a: ChatMessageCollectionItem, b: ChatMessageCollectionItem) => {
        if (a.conversation_id === conversationId) {
          return -1
        }

        return 0
      })
  })

  // @ts-ignore
  dispatch(update)
}

const handleMessageRead = (dispatch: AppDispatch, conversation_id: string, message_id: number) => {
  dispatch(
    // @ts-ignore
    api.util.updateQueryData('getChat', { conversationId: conversation_id, cursor: null }, draft => {
      // @ts-ignore
      draft.data.messages = draft.data.messages.map((item: ChatMessageItem) => {
        if (item.id === message_id) {
          return {
            ...item,
            viewed_at: new Date().toISOString()
          }
        }

        return item
      })
    })
  )
}

function StreamingUpdateProvider({ children }: { children: React.ReactNode }) {
  const [broadcastToken] = useBroadcastTokenMutation()
  const { data: user } = useGetUserQuery()
  const dispatch = useAppDispatch()
  const [connected, setConnected] = useState(false)
  const echoRef = React.useRef<Echo>()

  if (!echoRef.current && user) {
    echoRef.current = new Echo({
      broadcaster: 'reverb',
      key: user.websocket_key,
      forceTLS: true,
      wsHost: user.websocket_url,
      wsPort: 443,
      wssPort: 443,
      disableStats: true,
      encrypted: true,
      enabledTransports: ['ws', 'wss'],
      disabledTransports: ['xhr_streaming', 'xhr_polling'],
      authorizer: (channel: { name: string }) => {
        return {
          authorize: (socketId: string, callback: any) => {
            broadcastToken({ socket_id: socketId, channel_name: channel.name })
              .unwrap()
              .then(data => {
                callback(null, { auth: data.token })
              })
              .catch(e => callback(e))
          }
        }
      }
    })

    let changeConnectionStatus: number

    echoRef.current.connector.pusher.connection.bind('connected', (e: any) => {
      changeConnectionStatus = setTimeout(() => {
        setConnected(true)
      }, 1000)
    })

    echoRef.current.connector.pusher.connection.bind('disconnected', () => {
      clearTimeout(changeConnectionStatus)
    })
  }

  useEffect(() => {
    if (!echoRef.current || !user) {
      return
    }

    if (user) {
      echoRef.current.private(`chat.${user.id}`).listen('.new-chat-message', (e: any) => {
        handleChatUpdate(dispatch, e.lastMessage)
        handleChatListUpdate(dispatch, e.lastMessage.message, e.lastMessage.contact.conversation_id, e.unreadCount)
      })

      echoRef.current.private(`chat.${user.id}`).listen('.message-read', (e: any) => {
        console.log(e)
        handleMessageRead(dispatch, e.conversation_id, e.id)
      })
    }

    return () => {
      if (echoRef.current && user) {
        echoRef.current.leave(`chat.${user.id}`)
      }
    }
  }, [echoRef, user])

  return <StreamingUpdatesContext.Provider value={{ connected }}>{children}</StreamingUpdatesContext.Provider>
}

function useStreamingUpdates() {
  const context = React.useContext(StreamingUpdatesContext)

  if (!context) {
    throw new Error('use streaming updates need context and user')
  }

  return context
}

export { StreamingUpdateProvider, useStreamingUpdates }
