import React, { useEffect, useRef, useState } from 'react'
import { Avatar, Box, Button, Center, Heading, HStack, Text } from 'native-base'
import { CharlyIcon, PlatformScrollView, ScreenLayout } from '@charlycares/ui'
import {
  PhotoTip1Right,
  browseMedia,
  isDev,
  isWeb,
  uploadFile,
  useRouter,
  PhotoTip1Wrong,
  PhotoTip2Wrong,
  PhotoTip2Right,
  PhotoTip3Wrong,
  PhotoTip3Right,
  PhotoTip4Wrong,
  PhotoTip4Right,
  PhotoTip5Wrong,
  PhotoTip5Right
} from '@charlycares/shared'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useDispatch } from 'react-redux'
import { useGetAngelTodosQuery, useGetUserQuery } from '@charlycares/data-access'
import { useTranslation } from 'react-i18next'

const AngelPhotoScreen = () => {
  const { goBack } = useRouter()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { bottom } = useSafeAreaInsets()

  const { refetch, data: user } = useGetUserQuery()
  const { refetch: refetchTodos } = useGetAngelTodosQuery()

  const scrollRef = useRef<PlatformScrollView>()
  const [photo, setPhoto] = useState('')
  const [imageUploaded, setImageUploaded] = useState(false)
  const [uploadingError, setUploadingError] = useState(false)
  const [uploadingImage, setUploadingImage] = useState(false)

  useEffect(() => {
    if (user?.profile.image) {
      setPhoto(user?.profile.image)
    }
  }, [user?.profile.image])

  useEffect(() => {
    if (imageUploaded) {
      scrollRef.current?.scrollToPosition(0, 0, false)
    }
  }, [imageUploaded])

  const tips = [
    { title: t('photoTip1'), wrong: PhotoTip1Wrong, right: PhotoTip1Right },
    { title: t('photoTip2'), wrong: PhotoTip2Wrong, right: PhotoTip2Right },
    { title: t('photoTip3'), wrong: PhotoTip3Wrong, right: PhotoTip3Right },
    { title: t('photoTip4'), wrong: PhotoTip4Wrong, right: PhotoTip4Right },
    { title: t('photoTip5'), wrong: PhotoTip5Wrong, right: PhotoTip5Right }
  ]

  const onAddPhoto = async (type?: 'camera' | 'library') => {
    try {
      const image = await browseMedia(undefined, type)
      if (image.didCancel || !image.uri) return

      setUploadingImage(true)
      image.uri && setPhoto(image.uri)

      const response = await uploadFile('v2/angels/photo', image)

      dispatch({
        type: isWeb ? 'set_user' : 'update_profile',
        payload: { image: response.data.image }
      })

      refetch()
      refetchTodos()
      setImageUploaded(true)
    } catch (error) {
      isDev && console.log(error)
      setUploadingError(true)
      setImageUploaded(false)
    } finally {
      setUploadingError(false)
      setUploadingImage(false)
    }
  }

  return (
    <ScreenLayout
      scrollRef={scrollRef as any}
      edges={['right', 'top', 'left']}
      BottomComponent={
        <Box
          flexDir="row"
          alignItems="center"
          justifyContent="space-around"
          bg="white"
          borderTopLeftRadius={'16px'}
          borderTopRightRadius={'16px'}
          w="100%"
          p="20px"
          pb={bottom + 20 + 'px'}
        >
          <Center>
            <CharlyIcon onPress={() => onAddPhoto('camera')} name="photo-btn" color="alert.info.400" size={'64px'} />
            <Text fontSize="16px" fontWeight={600} color="alert.info.400">
              {t('makePhoto')}
            </Text>
          </Center>
          <Center>
            <CharlyIcon
              onPress={() => onAddPhoto('library')}
              name="photo-lib-btn"
              color="alert.info.400"
              size={'64px'}
            />
            <Text fontSize="16px" fontWeight={600} color="alert.info.400">
              {t('choosePhoto')}
            </Text>
          </Center>
        </Box>
      }
      safeAreaBg="secondary.400"
      hideHeader
    >
      <Box flex={1} p="20px">
        {imageUploaded ? (
          <Button onPress={() => goBack()} bg="alert.info.400" alignSelf="flex-end">
            {t('done')}
          </Button>
        ) : (
          <CharlyIcon
            onPress={() => goBack()}
            alignSelf="flex-end"
            color="alert.info.400"
            name="close-btn"
            size={'40px'}
          />
        )}

        <Heading mt="10px" color="white" fontWeight={700} fontSize="40px">
          {t('profilePhotoTitle')}
        </Heading>

        <Center mt="15px">
          {!photo ? (
            <Box>
              <CharlyIcon name="avatar" size={'120px'} />
              <CharlyIcon
                position="absolute"
                bottom={'-8px'}
                right={'0px'}
                name="photo-btn"
                color="primary.400"
                size="48px"
              />
            </Box>
          ) : (
            <Avatar
              source={{ uri: photo as any }}
              alignSelf="center"
              borderColor="white"
              w="120px"
              h="120px"
              borderWidth={'6px'}
            />
          )}
        </Center>

        {imageUploaded && (
          <>
            <HStack
              space="10px"
              mt="40px"
              alignItems="center"
              bg="alert.success.400"
              px="16px"
              py="10px"
              borderRadius="8px"
            >
              <CharlyIcon name="check-btn" size={32} color="alert.success.400" />
              <Text fontSize={'16px'} color="white" fontWeight={600}>
                {t('photoUploadedAlert')}
              </Text>
            </HStack>

            <Text color="white" mt="10px" fontSize={'16px'}>
              {t('photoUploadedMessage')}
            </Text>
          </>
        )}

        {uploadingError && (
          <HStack
            space="10px"
            mt="40px"
            alignItems="center"
            bg="alert.danger.400"
            px="16px"
            py="10px"
            borderRadius="8px"
          >
            <CharlyIcon name="close-btn" size={32} color="alert.danger.400" />
            <Text fontSize={'16px'} color="white" fontWeight={600}>
              {t('photoFailedToUpload')}
            </Text>
          </HStack>
        )}

        {Boolean(!imageUploaded && !uploadingError && user?.profile.image && !user?.angel?.image_quality_passed) && (
          <HStack
            space="10px"
            mt="40px"
            alignItems="center"
            bg="alert.danger.400"
            px="16px"
            py="10px"
            borderRadius="8px"
          >
            <CharlyIcon name="close-btn" size={32} color="alert.danger.400" />
            <Text fontSize={'16px'} color="white" fontWeight={600}>
              {t('photoIsRejected')}
            </Text>
          </HStack>
        )}

        {!imageUploaded && !uploadingError && (
          <Text color="white" mt="40px" fontSize={'16px'}>
            {t('angelPhotoDesc')}
          </Text>
        )}

        <Text fontSize="18px" fontWeight={600} color="white" mt="36px">
          {t('photoTipsTitle')}
        </Text>

        {tips.map(({ title, wrong, right }, i) => (
          <Center key={title} p="16px" mt="20px" w="100%" bg="white" borderRadius="8px">
            <Text fontSize="16px" fontWeight={600} color="secondary.400">
              {title}
            </Text>

            <HStack mt="10px" space="50px">
              <Box>
                <Avatar
                  source={wrong as any}
                  alignSelf="center"
                  borderColor="alert.danger.400"
                  size={'100px'}
                  borderWidth={'4px'}
                />
                <CharlyIcon
                  size={'32px'}
                  name="close-btn"
                  color="alert.danger.400"
                  position={'absolute'}
                  right={'0px'}
                  bottom={'0px'}
                />
              </Box>
              <Box>
                <Avatar
                  source={right as any}
                  alignSelf="center"
                  borderColor="alert.success.400"
                  size={'100px'}
                  borderWidth={'4px'}
                />
                <CharlyIcon
                  size={'32px'}
                  name="check-btn"
                  color="alert.success.400"
                  position={'absolute'}
                  right={'0px'}
                  bottom={'0px'}
                />
              </Box>
            </HStack>
          </Center>
        ))}
      </Box>
    </ScreenLayout>
  )
}

export default AngelPhotoScreen

AngelPhotoScreen.navigationOptions = {
  headerShown: false,
  tabBarVisible: false
}
