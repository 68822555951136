import axiosInstance from '../../utils/axios'
import {
  GET_ANGEL_DASHBOARD,
  GET_FAMILY_DASHBOARD,
  GET_ANGEL_LATEST_REVIEWS,
  GET_AVAILABLE_ANGELS,
  FETCHING_AVAILABLE_ANGElS,
  GET_STANDBY_ANGELS,
  FETCHING_STANDBY_ANGElS,
  TOGGLE_LIKE_DASHBOARD_ANGELS,
  SERVER_ERROR
} from '../types'

const logError = (error, dispatch) => {
  if (error.response && error.response.status === 500) {
    dispatch({ type: SERVER_ERROR })
  }
}

export const getAngelDashboard = () => dispatch => {
  axiosInstance
    .get('angel-dashboard')
    .then(({ data }) => {
      if (data) {
        const { should_update_agenda, requests_received, requests_responded, completed_bookings } = data.data
        dispatch({
          type: GET_ANGEL_DASHBOARD,
          payload: {
            should_update_agenda,
            requests_received,
            requests_responded,
            completed_bookings
          }
        })
      }
    })
    .catch(error => logError(error, dispatch))
}

export const getFamilyDashboard = () => dispatch => {
  axiosInstance
    .get('family-dashboard')
    .then(response => {
      if (response.data) {
        dispatch({
          type: GET_FAMILY_DASHBOARD,
          payload: response.data
        })
      }
    })
    .catch(error => logError(error, dispatch))
}

export const getLatestAngelReviews = role => dispatch => {
  // axiosInstance.get(`latest_reviews/${locale}`).then((response) => {
  //   console.log({ response });
  //   if (response.data) {
  //     dispatch({
  //       type: GET_ANGEL_LATEST_REVIEWS,
  //       payload: response.data
  //     });
  //   }
  // }).catch((error) => logError(error, dispatch));

  if (role === 'family') {
    dispatch({
      type: GET_ANGEL_LATEST_REVIEWS,
      payload: [
        {
          id: 1,
          first_name: 'Lidia'
        },
        {
          id: 2,
          first_name: 'Thijs'
        },
        {
          id: 3,
          first_name: 'Peter'
        }
      ]
    })
  } else {
    dispatch({
      type: GET_ANGEL_LATEST_REVIEWS,
      payload: [
        {
          id: 1,
          first_name: 'Irene'
        },
        {
          id: 2,
          first_name: 'Kirsten'
        },
        {
          id: 3,
          first_name: 'Admina'
        }
      ]
    })
  }
}

export const toggleLikeAngelDashboard = (angelId, like) => dispatch => {
  axiosInstance
    .post('angel/like', { angel_id: angelId, like })
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: TOGGLE_LIKE_DASHBOARD_ANGELS,
          payload: { angelId, like }
        })
      }
    })
    .catch(error => logError(error, dispatch))
}
