import React, { createRef, useEffect, useState } from 'react'
import { withNavigationFocus } from 'react-navigation'
import { CharlyIcon, ScreenLayout } from '@charlycares/ui'
import { BookingStates, colors, useRouter, UserRoles } from '@charlycares/shared'
import { Avatar, Box, Button, Center, HStack, Spacer, Spinner, StatusBar, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import {
  ChatMessageItem,
  useGetChatQuery,
  useGetUserQuery,
  useMarkChatAsReadMutation,
  useSendChatMessageMutation
} from '@charlycares/data-access'
import moment from 'moment'
import { GiftedChat, IMessage } from 'react-native-gifted-chat'
import { ChatToolbar } from './common'
import { FlatList } from 'react-native'
import { useStreamingUpdates } from '../providers'

const ChatScreen = ({ isFocused }: { isFocused: boolean }) => {
  const { t } = useTranslation()
  const { getParam, navigate } = useRouter()
  const conversationId = getParam('conversationId')
  const contact = getParam('contact')
  const chatRef = createRef<FlatList<IMessage>>()
  const [cursor, setCursor] = useState<string | null>(null)
  const { data: chatData, isLoading, refetch } = useGetChatQuery({ cursor: cursor, conversationId: conversationId })
  const date = moment(chatData?.data.contact.last_activity_at)
  const online = !isLoading ? date.isAfter(moment().subtract(10, 'minutes')) : false
  const [messages, setMessages] = useState<IMessage[]>([])
  const [sendNewChatMessage] = useSendChatMessageMutation()
  const { connected } = useStreamingUpdates()
  const { data: user } = useGetUserQuery()
  const [markChatAsRead] = useMarkChatAsReadMutation()

  const mapMessage = (message: ChatMessageItem): IMessage => {
    let received = message.viewed_at !== null

    if (user && message.contact.obscured_id === user.obscured_id && message.viewed_at === null) {
      markChatAsRead({ id: message.id })

      received = true
    }

    return {
      _id: message.id,
      text: message.message,
      createdAt: new Date(message.created_at),
      received: received,
      system: !!message.booking,
      user: {
        _id: message.contact.obscured_id,
        name: message.contact.name,
        avatar: message.contact.image_url
      }
    }
  }

  useEffect(() => {
    if (!isLoading) {
      setMessages(chatData?.data.messages.map(mapMessage) ?? [])
    }
  }, [chatData, isLoading])

  const sendNewMessage = (newMessage: string) => {
    if (chatData) {
      sendNewChatMessage({
        message: newMessage,
        receiver_id: chatData.data.contact.obscured_id
      })

      chatRef.current?.scrollToOffset({ offset: 0, animated: true })
    }
  }

  useEffect(() => {
    if (isFocused && !isLoading) {
      refetch()
    }
  }, [isFocused, isLoading])

  return (
    <ScreenLayout
      edges={['left', 'right']}
      _header={{
        borderBottomWidth: 0,
        borderWidth: 0
      }}
      disableScroll
      title={chatData?.data.contact.name ?? contact?.name ?? ''}
      subtitle={!isLoading ? (date.isSame(new Date(), 'day') ? date.format('H:mm') : date.format('lll')) : undefined}
      blueBackground
      headerRight={
        <Avatar shadow="1" size={'sm'} source={{ uri: chatData?.data.contact.image_url ?? contact?.image_url }}>
          <Avatar.Badge bg={online ? 'green.500' : 'gray.200'} />
        </Avatar>
      }
    >
      <StatusBar backgroundColor={colors.primary} barStyle={isFocused ? 'light-content' : 'dark-content'} animated />
      {!connected && (
        <Box bgColor="alert.warning.alpha.20" p="20px">
          <HStack>
            <CharlyIcon name="icn-block" size="24px" color="gray.900" />
            <Text ml="10px" color="gray.900">
              {t('chat.connectionError')}
            </Text>
          </HStack>
        </Box>
      )}
      <GiftedChat
        messageContainerRef={chatRef}
        messages={messages}
        isTyping={true}
        minInputToolbarHeight={100}
        onLoadEarlier={() => {
          const nextCursor = chatData?.meta.next_cursor
          if (nextCursor != null) {
            setCursor(nextCursor)
          }
        }}
        loadEarlier={chatData?.meta.next_cursor !== null && !isLoading}
        renderChatEmpty={() => (
          <Center alignSelf="center" justifyContent="center">
            <Spinner mt="10px" mb="20px" size="sm" color="secondary.300" />
          </Center>
        )}
        renderMessage={e => {
          const isCurrentUser = e.currentMessage?.user._id === chatData?.data.contact.obscured_id
          const currentDate = moment(e.currentMessage?.createdAt)
          const booking = e.currentMessage?.system
            ? chatData?.data.messages.find(m => m.id === e.currentMessage?._id)?.booking
            : null

          return (
            <>
              <Box
                bgColor={isCurrentUser ? 'chat.400' : 'white'}
                p="10px"
                my="12px"
                mx="20px"
                width="80%"
                alignSelf={isCurrentUser ? 'flex-end' : 'flex-start'}
                borderTopRadius="md"
                borderBottomLeftRadius={isCurrentUser ? 'md' : '0'}
                borderBottomRightRadius={isCurrentUser ? '0' : 'md'}
              >
                <Text fontSize="15px" color="gray.900">
                  {e.currentMessage?.text}
                </Text>
                <HStack alignSelf="flex-end">
                  <Text fontSize="12px" color="gray.700">
                    {currentDate.format('H:mm')}
                  </Text>
                  {isCurrentUser && !booking && !e.currentMessage?.received && (
                    <CharlyIcon name="icn-check" size="18px" color="gray.900" />
                  )}
                  {isCurrentUser && !booking && e.currentMessage?.received && (
                    <>
                      <CharlyIcon name="icn-check" size="18px" color="gray.900" />
                      <CharlyIcon name="icn-check" size="18px" color="gray.900" ml="-10px" />
                    </>
                  )}
                </HStack>
                {booking && (
                  <Box borderLeftColor="secondary.400" borderLeftWidth="4px" ml="-10px" pl="15px">
                    <HStack alignContent="center" alignItems="center">
                      <CharlyIcon name="icn-calendar" size="32px" color="gray.900" />
                      <Text ml="5px" color="gray.900">
                        {t('chatScreen.bookingBlock.title')}
                      </Text>
                    </HStack>
                    <HStack alignContent="center" alignItems="center" mt="10px">
                      <Text color="gray.900" fontSize="15px" fontWeight="600">
                        {t('chatScreen.bookingBlock.start')}
                      </Text>
                      <Spacer />
                      <Text alignSelf="flex-end" color="secondary.300" fontSize="16px" fontWeight="600">
                        {moment(booking.start_date).format('ddd D MMMM HH:mm')}
                      </Text>
                    </HStack>
                    <HStack alignContent="center" alignItems="center" mt="10px">
                      <Text color="gray.900" fontSize="15px" fontWeight="600">
                        {t('chatScreen.bookingBlock.end')}
                      </Text>
                      <Spacer />
                      <Text alignSelf="flex-end" color="secondary.300" fontSize="16px" fontWeight="600">
                        {moment(booking.end_date).format('HH:mm')}
                      </Text>
                    </HStack>
                    <Button
                      size="xs"
                      mt="15px"
                      onPress={() => {
                        if (user) {
                          if (user.role === UserRoles.FAMILY) {
                            if (booking.status === BookingStates.PENDING) {
                              navigate('FamilyEditBooking', `/booking-edit`, { bookingId: booking.obscured_id })
                            } else if (booking.status === BookingStates.ACCEPTED) {
                              navigate('FamilyBookingDetails', `/booking`, { bookingId: booking.obscured_id })
                            }
                          } else if (user.role === UserRoles.ANGEL) {
                            navigate('AngelBookingDetails', `angel/booking/${booking.obscured_id}`, {
                              bookingId: booking.obscured_id
                            })
                          }
                        }
                      }}
                    >
                      {t('chatScreen.bookingBlock.viewBooking')}
                    </Button>
                  </Box>
                )}
              </Box>
              {currentDate.isSame(e.previousMessage?.createdAt, 'day') ? null : (
                <Box alignSelf="center" bgColor="gray.400" rounded="full" px="10px" py="2px" mt="5px">
                  <Text fontSize="12px" color="gray.900">
                    {currentDate.isSame(new Date(), 'day')
                      ? currentDate.format('H:mm')
                      : currentDate.format('ddd D MMM')}
                  </Text>
                </Box>
              )}
            </>
          )
        }}
        renderInputToolbar={() =>
          chatData && <ChatToolbar contact={chatData.data.contact} sendMessage={sendNewMessage} />
        }
      />
    </ScreenLayout>
  )
}

const screen = withNavigationFocus(ChatScreen)
export default screen

// @ts-ignore
screen.navigationOptions = {
  headerShown: false
}
