import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { Divider } from '../../../elements'
import { TextView, TextTextView, IconButton, Rating, FamilyAvatar, Header } from '../../../components'
import { getFormatedDate } from '../../../utils/functions'
import colors from '../../../styles/colors'

import arrowRight from '../../../../assets/icons/icn-nav-right.png'
import leftIcon from '../../../../assets/icons/icn-nav-left.png'

const SafeAreaView = styled.SafeAreaView`
  justify-content: flex-start;
`

const ReviewDetails = ({ location, history, navigation }) => {
  const { review } = location.state
  const { family, rating, comments, start_date } = review
  const { family_id, first_name, last_name, image, obscured_id } = family || {}
  const [t] = useTranslation()

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <TextTextView
          flexDirection="column"
          firstText={getFormatedDate(start_date, 'dddd')}
          firstTextFontWeight="600"
          firstTextFontSize="16px"
          firstTextColor={colors.primaryText}
          firstTextAlign="center"
          firstTextxFontFamily="Martel"
          secondText={getFormatedDate(start_date, 'LL')}
          secondTextFontSize="13px"
          secondTextColor={colors.grey03}
        />
      )
    })
  }

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          title={getFormatedDate(start_date, 'dddd')}
          subtitle={getFormatedDate(start_date, 'LL')}
          left={
            <IconButton iconSource={leftIcon} iconSize="32px" iconColor={colors.secondary} onPress={history.goBack} />
          }
        />
      )}

      <Divider />

      <IconButton
        disabled={!family_id}
        flexDirection="row-reverse"
        justifyContent="space-between"
        paddings="10px"
        width="100%"
        height="auto"
        iconSource={family_id ? arrowRight : null}
        iconSize="20px"
        iconColor={colors.grey08}
        onPress={() => {
          history.push({ pathname: '/profile-family', state: { familyId: obscured_id || family_id } })
        }}
      >
        <TextView
          flexDirection="row-reverse"
          text={first_name ? t('familyName', { 0: `${first_name} ${last_name}` }) : t('reference')}
          textMargins="0px 0px 0px 10px"
          fontSize="16px"
          fontWeight="600"
          fontFamily="Martel"
        >
          <FamilyAvatar width="90px" height="60px" source={image || null} />
        </TextView>
      </IconButton>

      <Divider />

      <TextView
        flexDirection="column"
        alignItems="flex-start"
        paddings="20px"
        text={t('rating')}
        fontFamily="Martel"
        fontSize="16px"
        fontWeight="600"
      >
        <Rating rating={rating} iconSize="27px" />
      </TextView>

      <TextView
        flexDirection="column"
        alignItems="flex-start"
        paddings="0px 20px 0px 20px"
        text={t('review')}
        fontFamily="Martel"
        fontSize="16px"
        fontWeight="600"
      />

      <TextView
        flexDirection="column"
        alignItems="flex-start"
        paddings="15px 20px 0px 20px"
        text={comments}
        fontSize="15px"
        fontFamily="OpenSans-Light"
        fontWeight={300}
        color={colors.primaryText}
      />
    </SafeAreaView>
  )
}

ReviewDetails.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      review: PropTypes.shape({
        family: PropTypes.shape({}),
        rating: PropTypes.number,
        comments: PropTypes.string,
        start_date: PropTypes.string
      })
    })
  }),
  history: PropTypes.shape({ push: PropTypes.func, goBack: PropTypes.func }),
  navigation: PropTypes.shape({ navigate: PropTypes.func, setOptions: PropTypes.func })
}

ReviewDetails.defaultProps = {
  location: {},
  history: null,
  navigation: null
}

export default ReviewDetails
