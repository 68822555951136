import * as React from 'react'
import Svg, { SvgProps, Rect, Path } from 'react-native-svg'

const AvatarIcon = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 42 42" fill="none" {...props}>
    <Rect x={1} y={1} width={40} height={40} rx={20} fill="#C7C7C9" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.818 21c0 10.04 8.14 18.18 18.18 18.18C31.04 39.18 39.18 31.04 39.18 21c0-10.042-8.14-18.182-18.181-18.182S2.819 10.958 2.819 21zm17.956 16.372l-3.514-3.514c-.056.001-.11.005-.16.012-.223-.04-.45-.123-.697-.213a7.196 7.196 0 00-.776-.249c-.714-.174-1.403-.283-2.277.088-.408.172-.885.554-1.43 1.145-4.405-2.938-7.306-7.95-7.306-13.642 0-9.05 7.336-16.385 16.385-16.385 9.05 0 16.385 7.336 16.385 16.385 0 5.93-3.15 11.123-7.866 14-.62-.797-1.173-1.298-1.659-1.503-.874-.37-1.563-.262-2.277-.088-.287.07-.54.163-.776.248-.167.062-.325.119-.478.162l-3.553 3.554zm-7.257-19.147c2.479-.188 4.297-1.05 5.426-2.59 1.266 2.346 4.503 3.882 8.756 3.914-.104 5.032-4.39 9.755-7.117 9.755-2.76 0-7.118-4.84-7.118-9.94 0-.387.018-.767.053-1.139zm7.065-7.904c-3.422 0-6.28 2.987-6.963 7.124 2.462-.22 4.132-1.157 5.049-2.805l.413-.742.285.8c.408 1.143 1.388 2.169 2.826 2.903 1.463.746 3.333 1.162 5.494 1.18-.232-4.815-3.324-8.46-7.104-8.46zm-1.228 19.535c.421.142.834.217 1.228.217s.807-.075 1.228-.217c-.1 2.43.282 3.657 1.455 3.938l-2.49 2.49-2.586-2.586c.946-.408 1.256-1.624 1.165-3.842zm7.087-22.214c-1.8-.327-3.303.355-4.508 2.048 3.762.782 6.537 4.788 6.537 9.674 0 1.523-.361 3.077-1.003 4.537.441.87.986 1.602 1.632 2.198 1.3 1.197 2.818 1.558 4.55 1.081-2.135-1.036-3.266-2.544-3.39-4.524-.09-1.407.289-2.57.683-3.778.438-1.343.895-2.744.75-4.602-.275-3.53-2.135-6.067-5.251-6.634z"
      fill="white"
    />
    <Rect x={1} y={1} width={40} height={40} rx={20} stroke="#D9D9D9" />
  </Svg>
)

export default AvatarIcon
