import React, { useEffect, useState } from 'react'
import { Card, CharlyIcon, NumberInput } from '@charlycares/ui'
import { Text, Box, Center, HStack, IBoxProps, Divider, Flex, Skeleton } from 'native-base'
import { useTranslation } from 'react-i18next'
import { formatMoney, Money } from '@charlycares/shared'

type Props = IBoxProps & {
  dayRate: Money
  nightRate: Money
  maxRate: Money
  travelAllowance: Money
  expectedEarnings: Money
  showRateWarning?: boolean
  showAdjustedRate?: boolean
  showPetCareRateWarning?: boolean
  onRateAmountChange?: (value: number) => void
}

export default function BookingRatesDetails({
  dayRate,
  nightRate,
  maxRate,
  travelAllowance,
  expectedEarnings,
  showRateWarning,
  showAdjustedRate,
  showPetCareRateWarning,
  onRateAmountChange,
  ...boxProps
}: Props) {
  const { t } = useTranslation()

  const [adjustRateForAmount, setAdjustRateForAmount] = useState(0)
  const [isRateAdjusting, setIsRateAdjusting] = useState(false)

  const onRatesChange = (value: number) => {
    setIsRateAdjusting(true)
    setAdjustRateForAmount(value)
    onRateAmountChange && onRateAmountChange(value)
  }

  useEffect(() => {
    if (onRateAmountChange) {
      setIsRateAdjusting(true)
    }
  }, [travelAllowance.amount])

  useEffect(() => {
    if (onRateAmountChange) {
      setIsRateAdjusting(false)
    }
  }, [expectedEarnings.amount])

  return (
    <Card p="0" {...boxProps}>
      <Box mx="20px" px="10px" py="20px" flexDir="row" borderTopWidth={1} borderColor="gray.200">
        <HStack flex={1} space="20px" alignItems="center" justifyContent="space-around">
          <Center>
            <CharlyIcon name="icn-day" size={30} color="gray.900" />
            <Text fontSize="14px" mt="5px">
              {formatMoney(dayRate)}
            </Text>
          </Center>
          <Center>
            <CharlyIcon name="icn-night" size={30} color="gray.900" />
            <Text fontSize="14px" mt="5px">
              {formatMoney(nightRate)}
            </Text>
          </Center>
          <Center>
            <CharlyIcon name="icn-car" size={30} color={travelAllowance ? 'primary.400' : 'gray.900'} />
            <Text fontSize="14px" mt="5px" color={travelAllowance ? 'primary.400' : undefined}>
              {travelAllowance ? `+ ${formatMoney(travelAllowance)}` : formatMoney(travelAllowance)}
            </Text>
          </Center>
        </HStack>

        <Divider mx="20px" w="1px" h="100%" />

        {isRateAdjusting ? (
          <Center>
            <Skeleton h="3" w="102px" rounded="full" startColor="gray.400" />
            <Skeleton mt="10px" h="30px" w="61px" rounded="full" startColor="gray.400" />
          </Center>
        ) : (
          <Center>
            <Text fontSize="12px">{t('expectedEarnings')}</Text>
            <Text mt="5px" fontSize="22px" fontWeight="600">
              {formatMoney(expectedEarnings)}
            </Text>
          </Center>
        )}
      </Box>
      {showPetCareRateWarning && (
        <Box px="20px" py="10px" flexDir="row" alignItems="center" bg="gray.100">
          <CharlyIcon name="icn-small-info" size={18} color="primary.400" />
          <Text fontSize="12px" ml="15px" flex={1}>
            {t('sendOfferPetCareRateWarningMessage')}
          </Text>
        </Box>
      )}
      {showRateWarning && (
        <Box px="20px" py="10px" flexDir="row" alignItems="center" bg="gray.100">
          <CharlyIcon name="icn-small-info" size={32} color="primary.400" />
          <Text fontSize="12px" ml="15px" flex={1}>
            {t('showRateWarning')}
          </Text>
        </Box>
      )}

      {showAdjustedRate && (
        <Flex
          direction="row"
          align="center"
          mt="10px"
          w="100%"
          bg="white"
          p="20px"
          borderColor="gray.200"
          borderTopWidth={showRateWarning ? 0 : 1}
        >
          <Text fontSize="14px" mr="20px" flex={1}>
            {t('adjustRates')}
          </Text>
          <NumberInput
            min={-2000}
            value={adjustRateForAmount}
            changeRate={25}
            onChange={onRatesChange}
            formatValue={val =>
              formatMoney({
                amount: val,
                currency: 'EUR'
              })
            }
          />
        </Flex>
      )}
    </Card>
  )
}
