import React from 'react'
import { Box, Button, Heading } from 'native-base'
import { useTranslation } from 'react-i18next'
import { LandingLayout } from '@charlycares/ui'
import { useRouter } from '@charlycares/shared'
import { FeaturesWeOffer } from '../../common'

export default function PreBoardingFamilyStep2Screen() {
  const { navigate, getParam } = useRouter()
  const { t } = useTranslation()

  return (
    <LandingLayout
      withGoBack
      BottomComponent={
        <Box w="100%" p="20px" mt="auto">
          <Button
            onPress={() =>
              navigate('ServiceGroupTypesScreen', `/service-group-types`, {
                referralCode: getParam('referralCode', null)
              })
            }
            bgColor="white"
            _text={{ color: 'secondary.400', fontSize: '16px', fontWeight: 600 }}
          >
            {t('preBoardingFamilyStep2Screen.next')}
          </Button>
          <Button
            onPress={() => navigate('Login', `/login`)}
            variant="text"
            mt="10px"
            _text={{ color: 'white', fontSize: '16px' }}
          >
            {t('login')}
          </Button>
        </Box>
      }
    >
      <Box w="100%" p="20px" mt="30px">
        <Heading color="white" fontSize="22px" fontWeight="600">
          {t('preBoardingFamilyStep2Screen.heading')}
        </Heading>
      </Box>

      <FeaturesWeOffer mt="20px" />
    </LandingLayout>
  )
}

PreBoardingFamilyStep2Screen.navigationOptions = () => ({
  headerShown: false
})
