import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'

import { Text } from '../../../../elements'
import { TextIconView, TextButton, Header } from '../../../../components'
import colors from '../../../../styles/colors'

import checkIcon from '../../../../../assets/icons/icn-check.png'

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  padding: 20px;
`

const MembershipSuccess = ({ history, navigation }) => {
  const [t] = useTranslation()

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize={18} fontWeight="600">
          {t('connectBankAccountHeader')}
        </Text>
      ),
      headerRight: (
        <TextButton
          paddings="0px 10px 0px 0px"
          margins="0px 10px 0px 0px"
          height="auto"
          text={t('ready')}
          fontWeight="normal"
          textColor={colors.secondary}
          onPress={() => {
            if (navigation) {
              navigation.navigate('ParentTabNavigator')
            } else {
              history.replace('/')
            }
          }}
        />
      )
    })
  }

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          title={t('membershipSuccessTitle')}
          right={
            <TextButton
              paddings="0px 10px 0px 0px"
              margins="0px 10px 0px 0px"
              height="auto"
              text={t('ready')}
              fontWeight="normal"
              textColor={colors.secondary}
              onPress={() => {
                history.replace('/')
              }}
            />
          }
        />
      )}
      <TextIconView
        flexDirection="row-reverse"
        justifyContent="flex-end"
        paddings="10px 20px 20px 0px"
        borderBottomWidth="1px"
        backgroundColor={colors.white}
        iconMargins="0px 10px 0px 0px"
        icon={checkIcon}
        iconSize="32px"
        iconColor={colors.primary}
        text={t('membershipIsActive')}
        textWidth="80%"
        textAlign="left"
      />
      <TextIconView
        flexDirection="row-reverse"
        justifyContent="flex-end"
        paddings="10px 20px 20px 0px"
        borderBottomWidth="1px"
        backgroundColor={colors.white}
        iconMargins="0px 10px 0px 0px"
        icon={checkIcon}
        iconSize="32px"
        iconColor={colors.primary}
        text={t('connectBankAccountSuccesfully')}
        textWidth="80%"
        textAlign="left"
      />
    </SafeAreaView>
  )
}

MembershipSuccess.propTypes = {
  location: PropTypes.shape({ state: PropTypes.shape({ hasBookingPending: PropTypes.bool }) }),
  history: PropTypes.shape({ replace: PropTypes.func, push: PropTypes.func }),
  route: PropTypes.shape({ params: PropTypes.shape({ hasBookingPending: PropTypes.bool }) }),
  navigation: PropTypes.shape({
    setOptions: PropTypes.func,
    navigate: PropTypes.func
  })
}

MembershipSuccess.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null
}

export default MembershipSuccess
