import React from 'react'
import { Dimensions } from 'react-native'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'

import { Text, TextButton, Video, colors, isWeb } from '@charlycares/legacy/web'

const View = styled.View`
  ${({ flex }) => flex && { flex }};
  ${({ justifyContent }) => justifyContent && { 'justify-content': justifyContent }};
  ${({ alignItems }) => alignItems && { 'align-items': alignItems }}
  ${({ paddings }) => paddings && { padding: paddings }};
  ${({ margins }) => margins && { margin: margins }};
  ${({ height }) => height && { height }};
  ${({ width }) => width && { width }};
`

const Slide = ({ videoSource, header, description, lastSlide, navigateTo }) => {
  const [t] = useTranslation()

  return (
    <View flex={1} alignItems="center" justifyContent="space-between">
      <Video
        width={isWeb ? '375px' : '100%'}
        height={isWeb ? '375px' : Dimensions.get('window').width}
        fluid={false}
        src={videoSource}
        autoPlay
        loop={lastSlide}
      />
      <View
        flex={1}
        justifyContent="space-between"
        paddings="0px 0px 10px 0px"
        margins="20px 0px 0px 0px"
        height="50%">
        <View flex={1} justifyContent="flex-start">
          <Text
            paddings="0px 25px 0px 25px"
            margins="20px 0px 0px 0px"
            fontFamily="Martel-DemiBold"
            fontSize="22px"
            textAlign="center">
            {header}
          </Text>
          <Text
            paddings="0px 25px 0px 25px"
            margins="10px 0px 0px 0px"
            fontFamily="OpenSans-Light"
            fontSize="15px"
            textAlign="center">
            {description}
          </Text>
        </View>
        {lastSlide && (
          <TextButton
            alignSelf="flex-end"
            margins="0px auto 0px auto"
            width="90%"
            borderRightWidth="1px"
            borderLeftWidth="1px"
            borderColor={colors.primary}
            text={t('onboardingCompleteBtn')}
            fontFamily="OpenSans-SemiBold"
            fontWeight="bold"
            fontSize="16px"
            textColor={colors.primary}
            onPress={navigateTo}
          />
        )}
      </View>
    </View>
  )
}

Slide.propTypes = {
  videoSource: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  lastSlide: PropTypes.bool,
  navigateTo: PropTypes.func
}

Slide.defaultProps = {
  lastSlide: false,
  navigateTo: () => {}
}

export default Slide
