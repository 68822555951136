import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { Text } from '../../elements'
import { TextView } from '../Texts'
import { IconButton, TextIconButton } from '../Buttons'
import { getFormatedDate } from '../../utils/functions'
import colors from '../../styles/colors'
import { IcnKids } from '../Icons'
import DatePicker from '../Pickers/DatePickerWeb'

const KidView = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
`

const KidsEdit = ({
  margins,
  paddings,
  borderBottomWidth,
  borderLeftWidth,
  borderColor,
  title,
  titleFontFamily,
  titleFontSize,
  titleFontWeight,
  titleColor,
  description,
  kids,
  onAddPress,
  onDeletePress
}) => {
  const [t] = useTranslation()
  const datePickerRef = useRef(null)

  return (
    <TextView
      flexDirection="column"
      alignItems="flex-start"
      margins={margins}
      paddings={paddings}
      borderBottomWidth={borderBottomWidth}
      borderLeftWidth={borderLeftWidth}
      borderColor={borderColor}
      text={title}
      fontFamily={titleFontFamily}
      fontSize={titleFontSize}
      fontWeight={titleFontWeight}
      textColor={titleColor}
    >
      {description && (
        <Text fontWeight="300" margins="0px 0px 15px 0px">
          {description}
        </Text>
      )}

      {kids.map((birthdate, index) => (
        <KidView key={`${birthdate}-${String(index)}`}>
          <TextView
            flex={1}
            flexDirection="row-reverse"
            justifyContent="flex-end"
            margins="10px 0px 10px 0px"
            text={getFormatedDate(birthdate, 'LL')}
          >
            <IcnKids size="32px" margins="0px 10px 0px 0px" />
          </TextView>

          <IconButton
            margins="0px 0px 0px 10px"
            iconName="icn-cancel"
            iconColor={colors.defaultBtnBackgroundColor}
            onPress={() => onDeletePress(birthdate)}
          />
        </KidView>
      ))}

      <TextIconButton
        flexDirection="row-reverse"
        justifyContent="center"
        alignItems="center"
        width="50%"
        backgroundColor={colors.primary}
        text={t('profileScreensAddChild')}
        textMargins="0px 10px 0px 10px"
        fontSize="16px"
        textColor={colors.white}
        iconName="add"
        iconSize="32px"
        iconColor={colors.white}
        onPress={() => datePickerRef.current.setOpen(true)}
      />

      <DatePicker
        ref={datePickerRef}
        inputHidden
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        showTimeSelect={false}
        value={moment().toDate()}
        onChange={date => onAddPress(moment(date).format('YYYY-MM-DD'))}
      />
    </TextView>
  )
}

KidsEdit.propTypes = {
  margins: PropTypes.string,
  paddings: PropTypes.string,
  borderBottomWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderLeftWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleFontFamily: PropTypes.string,
  titleFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  titleFontWeight: PropTypes.string,
  titleColor: PropTypes.string,
  description: PropTypes.string,
  kids: PropTypes.arrayOf(PropTypes.string),
  onAddPress: PropTypes.func.isRequired,
  onDeletePress: PropTypes.func.isRequired
}

KidsEdit.defaultProps = {
  margins: '20px',
  paddings: '0px',
  borderBottomWidth: '1px',
  borderLeftWidth: 0,
  borderColor: colors.grey08,
  titleFontFamily: 'Martel',
  titleFontSize: '18px',
  titleFontWeight: '600',
  titleColor: colors.primaryText,
  description: null,
  kids: []
}

export default KidsEdit
