import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { connect } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Text, Divider } from '../../../elements'
import {
  TextButton,
  TextTextView,
  VideoEdit,
  HourlyRatesEdit,
  Skills,
  BankAccount,
  Header,
  IconButton
} from '../../../components'
import { getFormatedDate } from '../../../utils/functions'
import colors from '../../../styles/colors'

import dayIcon from '../../../../assets/icons/icn-day.png'
import nightIcon from '../../../../assets/icons/icn-night.png'
import repeatIcon from '../../../../assets/icons/icn-repeat.png'
import standbyIcon from '../../../../assets/icons/icn-standby.png'
import leftIcon from '../../../../assets/icons/icn-nav-left.png'

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
`
const ScrollView = styled.ScrollView``

const PersonalSettings = ({ history, navigation, user }) => {
  const [videoPaused, setVideoPaused] = useState(true)
  const [t] = useTranslation()
  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('profileScreensPersonalSettings')}
        </Text>
      ),
      headerRight: (
        <TextButton
          text={t('edit')}
          textColor={colors.secondary}
          fontWeight="normal"
          onPress={() => navigation.navigate('ProfilePersonalSettings')}
        />
      )
    })
  }

  const { birthdate, account_number, angel, languages, rates_info } = user
  const {
    video,
    normal_rate,
    extra_rate,
    fixed_rate,
    special_rate,
    first_aid,
    babysit_expertise,
    min_age_children,
    driving_license,
    tutor,
    liability_insurance,
    updated_at
  } = angel
  const { dutch, english, french, german, italian, spanish } = languages
  const languagesArray = [
    dutch ? t('dutchLanguage') : null,
    english ? t('englishLanguage') : null,
    german ? t('germanLanguage') : null,
    french ? t('frenchLanguage') : null,
    spanish ? t('spanishLanguage') : null,
    italian ? t('italianLanguage') : null
  ]
  const angelLanguages = languagesArray.filter(lan => lan)
  const accounntNumberLength = account_number.length
  const accountLastDigits = account_number.slice(accounntNumberLength - 4, accounntNumberLength)

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          title={t('profileScreensPersonalSettings')}
          right={
            <TextButton
              text={t('edit')}
              textColor={colors.secondary}
              onPress={() =>
                history.push({ pathname: '/profile-settings-edit', state: { shouldGoBack: true } })
              }
            />
          }
          left={
            <IconButton
              iconSource={leftIcon}
              iconSize="32px"
              iconColor={colors.secondary}
              onPress={history.goBack}
            />
          }
        />
      )}

      <Divider />

      <ScrollView>
        <VideoEdit
          disabled
          source={`${video}?${moment(updated_at).unix()}`}
          paused={videoPaused}
          onTogglePlayPress={() => setVideoPaused(!videoPaused)}
          onStartExampleVideoPress={source => {
            if (navigation) {
              navigation.navigate('VideoPlayer', { source })
            }
          }}
          onVideoLoadStart={() => setVideoPaused(true)}
          onVideoLoad={() => setVideoPaused(false)}
        />

        <HourlyRatesEdit
          disabled
          hourlyRates={[
            {
              desc: t('dayRate'),
              type: 'normal_rate',
              icon: dayIcon,
              rate: normal_rate,
              competitiveRate: rates_info.average_normal_rate
            },
            {
              desc: t('nightRate'),
              type: 'extra_rate',
              icon: nightIcon,
              rate: extra_rate,
              competitiveRate: rates_info.average_extra_rate
            },
            {
              desc: t('fixedRate'),
              type: 'fixed_rate',
              icon: repeatIcon,
              rate: fixed_rate,
              competitiveRate: rates_info.average_fixed_rate
            },
            {
              desc: t('specialRate'),
              type: 'special_rate',
              icon: standbyIcon,
              rate: special_rate,
              competitiveRate: rates_info.average_special_rate
            }
          ]}
        />

        <Skills
          paddings="20px"
          firstAid={!!first_aid}
          drivingLicense={!!driving_license}
          tutor={!!tutor}
          babyExperience={min_age_children <= 12}
          pro={!!babysit_expertise}
          liabilityInsurance={!!liability_insurance}
          showInfo={false}
        />

        <TextTextView
          paddings="20px"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          borderBottomWidth="1px"
          firstText={t('languages').toUpperCase()}
          firstTextFontSize="12px"
          firstTextFontWeight="300"
          firstTextColor={colors.grey02}
          secondText={angelLanguages.join(', ')}
          secondTextMargins="15px 0px 0px 0px"
          secondTextFontSize="16px"
          secondTextColor={colors.primaryText}
          secondTextAlign="left"
        />

        <BankAccount paddings="20px" isAngel accountLastDigits={accountLastDigits} />

        <TextTextView
          paddings="20px"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          borderTopWidth="1px"
          borderBottomWidth="1px"
          firstText={t('birthdate')}
          firstTextFontSize="12px"
          firstTextFontWeight="300"
          firstTextColor={colors.grey02}
          secondText={getFormatedDate(birthdate, 'LL')}
          secondTextMargins="15px 0px 0px 0px"
          secondTextFontSize="15px"
          secondTextColor={colors.primaryText}
          secondTextAlign="left"
        />
      </ScrollView>
    </SafeAreaView>
  )
}

PersonalSettings.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func, goBack: PropTypes.func }),
  navigation: PropTypes.shape({ navigate: PropTypes.func, setOptions: PropTypes.func }),
  user: PropTypes.shape({
    birthdate: PropTypes.string,
    account_number: PropTypes.string,
    angel: PropTypes.shape({
      video: PropTypes.string,
      normal_rate: PropTypes.number,
      extra_rate: PropTypes.number,
      fixed_rate: PropTypes.number,
      special_rate: PropTypes.number,
      first_aid: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      babysit_expertise: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      min_age_children: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      driving_license: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      tutor: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      liability_insurance: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      updated_at: PropTypes.string
    }),
    languages: PropTypes.shape({
      dutch: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      english: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      french: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      german: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      italian: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      spanish: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
    }),
    rates_info: PropTypes.shape({
      average_normal_rate: PropTypes.number,
      average_extra_rate: PropTypes.number,
      average_fixed_rate: PropTypes.number,
      average_special_rate: PropTypes.number
    })
  }).isRequired
}

PersonalSettings.defaultProps = {
  history: null,
  navigation: null
}

const mapStateToProps = ({ User }) => ({
  user: User.user
})

export default connect(mapStateToProps, null)(PersonalSettings)
