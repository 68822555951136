import React, { useEffect, useState } from 'react'
import { CharlyIcon, HeaderSearch, ScreenLayout } from '@charlycares/ui'
import { Box, Button, Center, Heading, HStack, Pressable, Spacer, Spinner, StatusBar, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import { colors, useRouter, UserRoles } from '@charlycares/shared'
import {
  ChatMessageCollectionItem,
  useGetChatListQuery,
  useGetUserIndicatorsQuery,
  useGetUserQuery
} from '@charlycares/data-access'

import { FlatList } from 'react-native'
import ChatListItem from './common/ChatListItem'
import { withNavigationFocus } from 'react-navigation'
import { useStreamingUpdates } from '../providers'

const ChatListScreen = ({ isFocused }: { isFocused: boolean }) => {
  const { t } = useTranslation()
  const { navigate } = useRouter()
  const { data: user } = useGetUserQuery()
  const [showSearch, setShowSearch] = useState(false)
  const [cursor, setCursor] = useState<string | null>(null)
  const [searchValue, setSearchValue] = useState('')
  const [order, setOrder] = useState<'asc' | 'desc'>('desc')
  const {
    data: chatData,
    isLoading,
    isFetching,
    refetch
  } = useGetChatListQuery({ cursor: cursor, search: searchValue, order: order })

  const { refetch: refetchIndicators } = useGetUserIndicatorsQuery()
  const { connected } = useStreamingUpdates()

  const renderItem = ({ item }: { item: ChatMessageCollectionItem }) => (
    <ChatListItem key={`conversation-${item.conversation_id}`} item={item} />
  )

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      refetch()
    }, 500)

    return () => clearTimeout(delayInputTimeoutId)
  }, [searchValue])

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      refetch()
    }, 100)

    return () => clearTimeout(delayInputTimeoutId)
  }, [order])

  useEffect(() => {
    if (isFocused && !isLoading) {
      refetch()
      refetchIndicators()
    }
  }, [isFocused, isLoading])

  return (
    <ScreenLayout
      edges={['left', 'right']}
      supportButton
      _header={{
        borderBottomWidth: 0,
        borderWidth: 0
      }}
      customHeader={
        showSearch ? (
          <HeaderSearch
            searchValue={searchValue}
            onChange={setSearchValue}
            onCancel={() => {
              setShowSearch(false)
              setSearchValue('')
            }}
          />
        ) : undefined
      }
      disableScroll
      hideBackButton
      title={t('ChatListScreen.title')}
      blueBackground
    >
      <StatusBar backgroundColor={colors.primary} barStyle={isFocused ? 'light-content' : 'dark-content'} animated />
      <Box
        bgColor="white"
        height="55px"
        mb="10px"
        alignItems="center"
        justifyContent="center"
        borderBottomWidth="1px"
        borderColor="gray.200"
      >
        <HStack>
          <Spacer maxWidth="60px" />
          <Pressable onPress={() => setShowSearch(true)}>
            <HStack>
              <CharlyIcon name="icn-search" size="24px" />
              <Text bold color="gray.800">
                Name
              </Text>
            </HStack>
          </Pressable>
          <Spacer />
          <Pressable
            onPress={() => {
              setOrder(prev => (prev === 'asc' ? 'desc' : 'asc'))
            }}
          >
            <HStack>
              <CharlyIcon name="sort" size="24px" />
              <Text bold color="gray.800">
                Sort
              </Text>
            </HStack>
          </Pressable>
          <Spacer maxWidth="60px" />
        </HStack>
      </Box>
      <FlatList
        style={{ borderTopWidth: 1, borderColor: colors.grey08 }}
        data={chatData?.data}
        refreshing={isLoading}
        onRefresh={() => {
          if (cursor == null) {
            refetch()
            refetchIndicators()
            return
          }

          setCursor(null)
        }}
        onEndReachedThreshold={0.5}
        onEndReached={() => {
          if (isLoading) return

          if (chatData?.meta.next_cursor && cursor !== chatData?.meta.next_cursor) {
            setCursor(chatData?.meta.next_cursor)
          }
        }}
        maxToRenderPerBatch={10}
        renderItem={renderItem}
        ListEmptyComponent={() => (
          <Center p="50px 20px">
            {!isLoading && chatData?.data.length === 0 && (
              <Box>
                <Heading fontSize="16px" fontWeight="600" textAlign="center">
                  {t('emptyChatList')}
                </Heading>
                {user?.role === UserRoles.FAMILY && (
                  <Button onPress={() => navigate('BookingRequestTypesList', '/booking-request-types-list')} mt="20px">
                    {t('makeYourFirstBooking')}
                  </Button>
                )}
              </Box>
            )}
          </Center>
        )}
        ListFooterComponent={() =>
          isFetching && chatData?.data.length !== 0 ? (
            <Spinner mt="10px" mb="20px" size="sm" color="secondary.300" />
          ) : null
        }
      />
    </ScreenLayout>
  )
}

const screen = withNavigationFocus(ChatListScreen)
export default screen

// @ts-ignore
screen.navigationOptions = {
  headerShown: false
}
