import React, { useState, useEffect } from 'react';
import { Keyboard, KeyboardAvoidingView, Platform } from 'react-native';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { cancelAppointment } from '../../../stores/actions/angelAppointmentAction';
import { Text, Divider } from '../../../elements';
import {
  TextView,
  TextTextView,
  IconButton,
  TextButton,
  LabeledInput,
  Header
} from '../../../components';
import colors from '../../../styles/colors';

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  justify-content: space-between;
`;
const ScrollView = styled.ScrollView`
  flex: 1;
`;
const ButtonsView = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  border-top-width: 1px;
  border-color: ${colors.grey08};
`;

const CancelAppointment = ({ history, navigation, appointment, onCancelAppointment }) => {
  const [reason, setReason] = useState('');
  const [t] = useTranslation();
  const reasons = [...new Array(7).keys()].map((key) => t(`signUpAngelScreensReasons${key}`));

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize={18} fontWeight="600">
          {t('signUpAngelScreensAppointment')}
        </Text>
      )
    });
  }

  useEffect(() => {
    if (!appointment) {
      if (navigation) {
        navigation.navigate('SignUpAngelMakeAppointment');
      } else {
        history.push('/signup-angel-appointment');
      }
    }
  }, [appointment]);

  return (
    <SafeAreaView>
      {!navigation && <Header title={t('signUpAngelScreensAppointment')} />}
      <ScrollView>
        <KeyboardAvoidingView
          enabled
          behavior="position"
          keyboardVerticalOffset={Platform.OS === 'android' ? '-120' : '70'}>
          <Divider />

          <TextTextView
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            paddings="20px"
            firstText={t('signUpAngelScreensCancelAppointmentTitle')}
            firstTextFontFamily="Martel"
            firstTextFontSize="18px"
            firstTextFontWeight="bold"
            secondTextMargins="5px 0px 0px 0px"
            secondText={t('signUpAngelScreensCancelAppointmentDescription')}
            secondTextFontSize="15px"
            secondTextFontWeight="300"
          />

          <TextView
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            paddings="10px 20px 20px 20px"
            text={t('signUpAngelScreensReasons')}
            fontFamily="Martel"
            fontSize="16px"
            fontWeight="bold">
            {reasons.map((cancelReason) => (
              <TextView
                key={cancelReason}
                justifyContent="space-between"
                paddings="10px 0px 10px 0px"
                width="100%"
                borderBottomWidth="1px"
                textFlex={1}
                text={cancelReason}
                numberOfLines={2}
                fontWeight="300">
                <IconButton
                  disabled={reason !== '' && !reasons.includes(reason)}
                  iconName="btn-plus"
                  iconSize="30px"
                  iconColor={
                    reason === cancelReason ? colors.primary : colors.defaultBtnBackgroundColor
                  }
                  onPress={() => setReason(cancelReason)}
                />
              </TextView>
            ))}

            <LabeledInput
              margins="20px 0px 0px 0px"
              width="100%"
              returnKeyType="done"
              labelText={t('signUpAngelScreensReasonsOther').toUpperCase()}
              value={reasons.includes(reason) ? '' : reason}
              onChangeText={setReason}
              onSubmitEditing={() => Keyboard.dismiss()}
            />
          </TextView>
        </KeyboardAvoidingView>
      </ScrollView>

      <ButtonsView>
        <TextButton
          text={t('cancel')}
          textColor={colors.secondary}
          fontFamily="Martel"
          fontWeight="600"
          onPress={() => {
            if (navigation) {
              navigation.goBack();
            } else {
              history.goBack();
            }
          }}
        />

        <TextButton
          disabled={reason === ''}
          opacity={reason === '' ? 0.5 : 1}
          backgroundColor={colors.primary}
          text={t('signUpAngelScreensCancelAppointment')}
          fontWeight="bold"
          onPress={() => onCancelAppointment(reason)}
        />
      </ButtonsView>
    </SafeAreaView>
  );
};

CancelAppointment.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func
  }),
  navigation: PropTypes.shape({
    setOptions: PropTypes.func,
    navigate: PropTypes.func,
    goBack: PropTypes.func
  }),
  appointment: PropTypes.shape({}),
  onCancelAppointment: PropTypes.func.isRequired
};

CancelAppointment.defaultProps = {
  history: null,
  navigation: null,
  appointment: null
};

const mapStateToProps = ({ User }) => ({
  appointment: User.user.angel.angel_appointment
});

const mapDispatchToProps = {
  onCancelAppointment: cancelAppointment
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelAppointment);
