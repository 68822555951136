import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, TextInput, ScreenLayout, CharlyIcon } from '@charlycares/ui'
import { Text, Button, Heading } from 'native-base'
import { useRouter } from '@charlycares/shared'
import { useAlert } from '../../hooks'
import { useResetPasswordMutation } from '@charlycares/data-access'

export default function ResetPassword() {
  const { t } = useTranslation()
  const { navigate, getParam } = useRouter()
  const alert = useAlert()

  const code = getParam('code') as string
  const email = getParam('email') as string

  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)

  const [resetPassword, resetPasswordState] = useResetPasswordMutation()

  const onSubmit = () => {
    if (password && passwordConfirmation && password === passwordConfirmation && code && email) {
      resetPassword({ email, code, password, password_confirmation: passwordConfirmation })
    }
    setPassword('')
    setPasswordConfirmation('')
    setShowPassword(false)
    setShowPasswordConfirmation(false)
  }

  useEffect(() => {
    if (resetPasswordState.isSuccess) {
      alert.show(t('resetPasswordScreen.alertSuccessTitle'), t('resetPasswordScreen.alertSuccessMessage'), [
        {
          text: t('ok'),
          onPress: () => navigate('Login', '/login')
        }
      ])
    }
  }, [resetPasswordState.isSuccess])

  useEffect(() => {
    if (resetPasswordState.isError) {
      alert.show(t('resetPasswordScreen.alertErrorTitle'), (resetPasswordState.error as any)?.data?.message, [
        { text: t('ok'), style: 'cancel' }
      ])
    }
  }, [resetPasswordState.isError])

  return (
    <ScreenLayout title={t('resetPasswordScreen.header')} disableScroll>
      <Card h="100%">
        <Heading mb="20px" fontSize="18px" fontWeight={600}>
          {t('resetPasswordScreen.title')}
        </Heading>
        <Text fontSize="15px" mb="20px">
          {t('resetPasswordScreen.subtitle')}
        </Text>
        <TextInput
          mb="20px"
          autoCapitalize="none"
          value={password}
          secureTextEntry={!showPassword}
          label={t('resetPasswordScreen.label')}
          onChangeText={value => setPassword(value)}
          error={(resetPasswordState.error as any)?.data?.message}
          letterSpacing={showPassword && password.length ? '2px' : '0px'}
          placeholder={t('resetPasswordScreen.placeholder')}
          returnKeyType="done"
          InputRightElement={
            <CharlyIcon
              onPress={() => setShowPassword(!showPassword)}
              name={showPassword ? 'eye-open' : 'eye-shut'}
              size={25}
              color="primary['400']"
            />
          }
        />
        <TextInput
          isDisabled={password.length < 6}
          autoCapitalize="none"
          value={passwordConfirmation}
          secureTextEntry={!showPasswordConfirmation}
          label={t('resetPasswordScreen.confirmationLabel')}
          onChangeText={value => setPasswordConfirmation(value)}
          error={(resetPasswordState.error as any)?.data?.message}
          letterSpacing={showPasswordConfirmation && password.length ? '2px' : '0px'}
          placeholder={t('resetPasswordScreen.confirmationPlaceholder')}
          onSubmitEditing={() => onSubmit()}
          returnKeyType="done"
          InputRightElement={
            <CharlyIcon
              onPress={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
              name={showPasswordConfirmation ? 'eye-open' : 'eye-shut'}
              size={25}
              color="primary['400']"
            />
          }
        />
        <Button
          onPress={() => onSubmit()}
          isLoading={resetPasswordState.isLoading}
          isDisabled={!password || !passwordConfirmation || password !== passwordConfirmation || !code || !email}
          mt="20px"
          height="50px"
        >
          {t('resetPasswordScreen.submitButton')}
        </Button>
      </Card>
    </ScreenLayout>
  )
}

ResetPassword.navigationOptions = () => ({
  headerShown: false
})
