import React from 'react'
import { BookingDate, formatDate, flattenBookingDates, IOffer } from '@charlycares/shared'
import { Card, CharlyIcon } from '@charlycares/ui'
import { Box, Divider, Heading, Stack, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import { SingleBookingDates } from './index'

type Props = {
  bookings: BookingDate[]
  offer: IOffer
}

const OfferDatesList = ({ bookings, offer }: Props) => {
  const { t } = useTranslation()
  const bookingDates = flattenBookingDates(bookings)

  return (
    <Box pb="40px">
      {bookings.length === 1 && bookingDates.length == 1 ? (
        <SingleBookingDates startsAt={bookings[0].start_date} endsAt={bookings[0].end_date} withTitle={false} />
      ) : (
        bookings.map(booking => {
          const daysCount = flattenBookingDates([booking]).filter(book =>
            offer.offers.find(offer => offer.booking_obscured_id === book.obscured_id)
          ).length
          const isSelected = !!offer.offers.find(offer => offer.booking_obscured_id === booking.obscured_id)

          return (
            <Card key={booking.obscured_id}>
              <Box flexDir="row" justifyContent="space-between" alignItems="center">
                <Box>
                  <Heading size="md">{formatDate(booking.start_date, 'dddd')}</Heading>
                  <Text fontSize="15px">{`${formatDate(booking.start_date, 'HH:mm')} - ${formatDate(
                    booking.end_date,
                    'HH:mm'
                  )}`}</Text>
                </Box>
                <Text color="primary.400" fontWeight="600" fontSize="16px">{`${daysCount} ${t('of')} ${
                  booking.repeat_days.length + 1
                } ${t('days')}`}</Text>
              </Box>

              <Divider my="15px" />

              <Stack space="20px">
                <Box flexDir="row" justifyContent="space-between" alignItems="center" key={booking.obscured_id}>
                  <Text fontSize="16px" color={isSelected ? 'text.dark.400' : 'gray.500'}>
                    {formatDate(booking.start_date, 'DD MMMM')}
                  </Text>
                  {isSelected && <CharlyIcon name="icn-check" size={30} color={'secondary.400'} />}
                </Box>

                {booking.repeat_days.map(repeatBooking => {
                  const isSelected = !!offer.offers.find(
                    offer => offer.booking_obscured_id === repeatBooking.obscured_id
                  )
                  return (
                    <Box
                      flexDir="row"
                      justifyContent="space-between"
                      alignItems="center"
                      key={repeatBooking.obscured_id}
                    >
                      <Text fontSize="16px" color={isSelected ? 'text.dark.400' : 'gray.500'}>
                        {formatDate(repeatBooking.start_date, 'DD MMMM')}
                      </Text>
                      {isSelected && <CharlyIcon name="icn-check" size={30} color={'secondary.400'} />}
                    </Box>
                  )
                })}
              </Stack>
            </Card>
          )
        })
      )}
    </Box>
  )
}

export default OfferDatesList
