import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components/native'
import { NavigationStackProp } from 'react-navigation-stack'
import { RouteComponentProps } from 'react-router-dom'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { Alert } from 'react-native'
import { useRouter, isWeb, CancellationReason } from '@charlycares/shared'
import { Text, Card, Description, Icon, Paragraph, Layout, Box, Loader } from '@charlycares/ui'
import { useCancelMembershipMutation, useGetCancellationReasonsQuery } from '@charlycares/data-access'
import { useAlert } from '../../hooks'

type CancelMembershipScreenProps = RouteComponentProps & {
  navigation?: NavigationStackProp<{ accountNumber: string }>
}

export default function CancelMembershipScreen({ navigation }: CancelMembershipScreenProps) {
  const { data, isLoading } = useGetCancellationReasonsQuery()
  const [cancelMembership, cancelMembershipStatus] = useCancelMembershipMutation()
  const [selectedReasons, setSelectedReasons] = useState<CancellationReason>()
  const [feedback, setFeedback] = useState('')
  const { popToTop, goBack } = useRouter(navigation)
  const { colors } = useTheme()
  const { t } = useTranslation()
  const alert = useAlert()

  useEffect(() => {
    if (cancelMembershipStatus.isSuccess) {
      isWeb
        ? alert.show(t('membershipCancelled'))
        : Alert.alert('', t('membershipCancelled'), [{ text: t('ok') }], { cancelable: false })

      popToTop()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelMembershipStatus.isSuccess, t, popToTop])

  const handleSubmit = useCallback(async () => {
    try {
      if (!selectedReasons || !feedback) return
      await cancelMembership({
        feedback,
        reason: selectedReasons.reason,
        category: selectedReasons.category
      }).unwrap()
    } catch (error) {
      if ((error as any).status === 422) {
        alert.show(t('cancelationNotPossibleTitle'), t('cancelationNotPossibleDesc'), [
          { text: t('goBack'), onPress: () => goBack() }
        ])
      } else {
        alert.show(t('error'), (error as any).data?.message, [{ text: t('ok') }])
      }
    }
  }, [selectedReasons, cancelMembership, feedback, t, goBack, alert])

  return (
    <Layout
      title={t('profileScreensCancelMembership')}
      onCancel={goBack}
      onConfirm={handleSubmit}
      buttonLoading={cancelMembershipStatus.isLoading}
      disabled={!selectedReasons || !feedback}
    >
      <Card mt="10px" borderBottomWidth={0}>
        <Description
          title={t('profileScreensWhyYouCancelSubscription')}
          text={t('profileScreensCancelFeedback')}
          m="0 0 30px 0"
        />

        <Loader
          loading={isLoading}
          component={
            <Box>
              {[...Array(5)].map((_, i) => (
                <ContentLoader height={50} width="100%" speed={2} key={i.toString()}>
                  <Rect x="0" y="0" rx="0" ry="0" width="100%" height="25" />
                </ContentLoader>
              ))}
            </Box>
          }
        >
          {data?.map(item => {
            const isSelected = selectedReasons?.reason === item.reason

            return (
              <ReasonItem key={item.reason}>
                <Paragraph flex={1}>{item.reason}</Paragraph>
                <Icon
                  onPress={() => setSelectedReasons(isSelected ? undefined : item)}
                  name={isSelected ? 'btn-check' : 'btn-plus'}
                  color={isSelected ? colors.primary : colors.grey06}
                  size={30}
                />
              </ReasonItem>
            )
          })}
        </Loader>

        <Paragraph color={colors.danger} transform="uppercase" margin="35px 0 10px 0" size={12}>
          {t('required')}
        </Paragraph>

        <Text>{t('profileScreensPersonalFeedbackPlaceholder')}</Text>

        <FeedbackInput value={feedback} onChangeText={setFeedback} multiline numberOfLines={6} />
      </Card>
    </Layout>
  )
}

CancelMembershipScreen.navigationOptions = () => ({
  headerShown: false
})

const ReasonItem = styled.View`
  padding: 20px 0;
  border: 1px solid ${p => p.theme.colors.grey08};
  width: 100%;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  flex-direction: row;
  align-items: center;
  min-height: 70px;
`

const FeedbackInput = styled.TextInput`
  border: 1px solid ${p => p.theme.colors.primary};
  width: 100%;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  min-height: 80px;
  margin-top: 10px;
`
