import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setOnboardingProgress } from '../../../stores/actions/userAction';
import {
  getPreferences,
  savePreferences
} from '../../../stores/actions/angelPreferencesAvailabilityAction';
import { Text, Switch } from '../../../elements';
import {
  TextView,
  TextTextView,
  TextIconView,
  IconButton,
  TextButton,
  Progress,
  RangeSlider,
  Header
} from '../../../components';
import colors from '../../../styles/colors';

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  justify-content: space-between;
`;
const ScrollView = styled.ScrollView`
  flex: 1;
`;
const View = styled.View`
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  ${({ justifyContent }) => justifyContent && { 'justify-content': justifyContent }};
  ${({ margins }) => margins && { margin: margins }};
  ${({ paddings }) => paddings && { padding: paddings }};
  ${({ width }) => width && { width }};
  ${({ borderTopWidth }) => borderTopWidth && { 'border-top-width': borderTopWidth }};
  ${({ borderTopWidth }) => borderTopWidth && { 'border-color': colors.grey08 }};
`;

const Preferences = ({
  location,
  history,
  route,
  navigation,
  preferences,
  setProgress,
  savePreferencesData,
  getPreferencesData
}) => {
  const { progress = 75 } = route.params || location.state || {};
  const [angelPreferences, setAngelPreferences] = useState(preferences || null);
  const [t] = useTranslation();

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize={18} fontWeight="600">
          {`${progress}% ${t('complete')}`}
        </Text>
      )
    });
  }

  useEffect(() => {
    if (progress < 100) {
      setProgress(progress);
    }
    getPreferencesData();
  }, []);

  useEffect(() => {
    console.log({ preferences });
    if (preferences && !angelPreferences) {
      setAngelPreferences(preferences);
    }
  }, [preferences]);

  const getDurationPreferences = () => {
    const {
      week_duration_request4,
      week_duration_request6,
      week_duration_request8,
      week_duration_request_over_8,
      weekend_duration_request4,
      weekend_duration_request6,
      weekend_duration_request8,
      weekend_duration_request_over_8
    } = angelPreferences || {};
    const durationPreferences = [
      {
        subtitle: t('profileScreensToHours', { from: 2, to: 4 }),
        weekday: { week_duration_request4 },
        weekend: { weekend_duration_request4 }
      },
      {
        subtitle: t('profileScreensToHours', { from: 4, to: 6 }),
        weekday: { week_duration_request6 },
        weekend: { weekend_duration_request6 }
      },
      {
        subtitle: t('profileScreensToHours', { from: 6, to: 8 }),
        weekday: { week_duration_request8 },
        weekend: { weekend_duration_request8 }
      },
      {
        subtitle: `> 8 ${t('hours')}`,
        weekday: { week_duration_request_over_8 },
        weekend: { weekend_duration_request_over_8 }
      }
    ];

    return durationPreferences;
  };

  const getTimePreferences = () => {
    const {
      week_early_morning,
      weekend_early_morning,
      week_morning,
      weekend_morning,
      week_afternoon,
      weekend_afternoon,
      after_school_requests,
      week_evening,
      weekend_evening
    } = angelPreferences || {};
    const timePreferences = [
      {
        subtitle: t('signUpAngelScreensEarlyMorningPreference'),
        weekday: { week_early_morning },
        weekend: { weekend_early_morning }
      },
      {
        subtitle: t('signUpAngelScreensMorningPreference'),
        weekday: { week_morning },
        weekend: { weekend_morning }
      },
      {
        subtitle: t('signUpAngelScreensAfternoonPreference'),
        weekday: { week_afternoon },
        weekend: { weekend_afternoon }
      },
      {
        subtitle: t('signUpAngelScreensAfterSchoolPreference'),
        weekday: { after_school_requests }
      },
      {
        subtitle: t('signUpAngelScreensEveningPreference'),
        weekday: { week_evening },
        weekend: { weekend_evening }
      }
    ];

    return timePreferences;
  };

  const getRequestPreferences = () => {
    const {
      week_last_minute_requests,
      weekend_last_minute_requests,
      week_advanced_notice2,
      weekend_advanced_notice2,
      week_advanced_notice3,
      weekend_advanced_notice3,
      week_advanced_notice4,
      weekend_advanced_notice4
    } = angelPreferences || {};
    const requestPreferences = [
      {
        subtitle: t('signUpAngelScreensRequestLastMinute'),
        weekday: { week_last_minute_requests },
        weekend: { weekend_last_minute_requests }
      },
      {
        subtitle: t('signUpAngelScreensRequestToWeeks', { 0: 1, 1: 2 }),
        weekday: { week_advanced_notice2 },
        weekend: { weekend_advanced_notice2 }
      },
      {
        subtitle: t('signUpAngelScreensRequestToWeeks', { 0: 3, 1: 4 }),
        weekday: { week_advanced_notice3 },
        weekend: { weekend_advanced_notice3 }
      },
      {
        subtitle: `> 4 ${t('weeks')}`,
        weekday: { week_advanced_notice4 },
        weekend: { weekend_advanced_notice4 }
      }
    ];

    return requestPreferences;
  };

  const { late_night_bookings, likes_dog, likes_cat, journey_time, max_number_kids } =
    angelPreferences || {};
  const durationTimeRequestPreferences = [
    { title: t('signUpAngelScreensBabysitPreference'), data: getDurationPreferences() },
    { title: t('signUpAngelScreensTimePreference'), data: getTimePreferences(), toggle: true },
    { title: t('signUpAngelScreensRequestPreferences'), data: getRequestPreferences() }
  ];

  return (
    <SafeAreaView>
      {!navigation && <Header title={`${progress}% ${t('complete')}`} />}
      <ScrollView>
        <Progress
          progress={progress}
          color={progress === 100 ? colors.primary : colors.secondary}
        />

        <TextTextView
          flexDirection="column"
          alignItems="flex-start"
          margins="20px"
          paddings="0px"
          firstText={t('signUpAngelScreensPersonalPreferences')}
          firstTextFontFamily="Martel"
          firstTextFontSize="18px"
          firstTextFontWeight="600"
          secondTextMargins="10px 0px 0px 0px"
          secondText={t('signUpAngelScreensPersonalPreferencesDescription')}
          secondTextFontSize="15px"
          secondTextFontWeight="300"
          secondTextAlign="left"
        />

        {durationTimeRequestPreferences.map(({ title, data, toggle }, index) => (
          <TextView
            key={title}
            flexDirection="column"
            alignItems="flex-end"
            margins="20px 20px 0px 20px"
            paddings="0px 0px 20px 0px"
            borderBottomWidth={index < 2 ? '1px' : 0}
            textWidth="100%"
            text={title}
            fontSize="16px">
            <TextTextView
              flexDirection="row"
              justifyContent="flex-start"
              margins="10px 0px 0px 0px"
              paddings="0px"
              firstTextWidth="60px"
              firstText={t('workweek')}
              firstTextFontSize="12px"
              firstTextColor={colors.grey04}
              secondTextMargins="0px 0px 0px 10px"
              secondTextWidth="60px"
              secondText={t('weekend')}
              secondTextFontSize="12px"
              secondTextColor={colors.grey04}
              secondTextAlign="center"
            />

            {data.map(({ subtitle, weekday, weekend }) => {
              const [weekdayValue] = Object.values(weekday);
              const [weekendValue] = Object.values(weekend || {});

              return (
                <TextView
                  key={subtitle}
                  justifyContent="space-between"
                  margins="10px 0px 0px 0px"
                  width="100%"
                  text={subtitle}
                  fontWeight="300">
                  <View>
                    <IconButton
                      margins={weekend ? '0px' : '0px 70px 0px 0px'}
                      width="60px"
                      iconName={weekdayValue ? 'btn-check' : 'btn-plus'}
                      iconSize="32px"
                      iconColor={weekdayValue ? colors.primary : colors.defaultBtnBackgroundColor}
                      onPress={() => {
                        const key = Object.keys(weekday)[0];
                        setAngelPreferences({ ...angelPreferences, [key]: !weekdayValue });
                      }}
                    />

                    {weekend && (
                      <IconButton
                        margins="0px 0px 0px 10px"
                        width="60px"
                        iconName={weekendValue ? 'btn-check' : 'btn-plus'}
                        iconSize="32px"
                        iconColor={weekendValue ? colors.primary : colors.defaultBtnBackgroundColor}
                        onPress={() => {
                          const key = Object.keys(weekend)[0];
                          setAngelPreferences({ ...angelPreferences, [key]: !weekendValue });
                        }}
                      />
                    )}
                  </View>
                </TextView>
              );
            })}

            {toggle && (
              <TextView
                justifyContent="space-between"
                paddings="20px 10px 10px 0px"
                width="100%"
                textWidth="70%"
                text={t('signUpAngelScreensBabysittingAfter23')}
                fontWeight="300">
                <Switch
                  value={late_night_bookings || false}
                  onValueChange={(value) =>
                    setAngelPreferences({ ...angelPreferences, late_night_bookings: value })
                  }
                />
              </TextView>
            )}
          </TextView>
        ))}

        <TextView
          backgroundColor={colors.backgroundColor}
          paddings="15px 15px 0px 15px"
          width="100%"
          borderTopWidth="1px"
          borderBottomWidth="1px"
          text={t('profileScreensGeneralQuestions')}
          fontSize="14px"
          fontWeight="300"
          textColor={colors.grey02}
        />

        <TextView
          flexDirection="column"
          alignItems="flex-start"
          margins="20px"
          paddings="0px 0px 20px 0px"
          borderBottomWidth="1px"
          textMargins="0px 0px 10px 0px"
          text={t('signUpAngelScreensAnimalsAtHome')}
          fontSize="16px">
          <View justifyContent="space-between" width="100%">
            <TextIconView
              flexDirection="row-reverse"
              backgroundColor="transparent"
              text={t('dog')}
              fontWeight="300"
              textAlign="left"
              iconMargins="0px 10px 0px 0px"
              name="dog"
              iconSize="32px"
              iconColor={colors.primaryText}
            />

            <IconButton
              width="50px"
              iconSource={likes_dog ? 'btn-check' : 'btn-plus'}
              iconSize="32px"
              iconColor={likes_dog ? colors.primary : colors.defaultBtnBackgroundColor}
              onPress={() => setAngelPreferences({ ...angelPreferences, likes_dog: !likes_dog })}
            />
          </View>

          <View justifyContent="space-between" margins="10px 0px 0px 0px" width="100%">
            <TextIconView
              flexDirection="row-reverse"
              backgroundColor="transparent"
              text={t('cat')}
              fontWeight="300"
              textAlign="left"
              iconMargins="0px 10px 0px 0px"
              name="cat"
              iconSize="32px"
              iconColor={colors.primaryText}
            />

            <IconButton
              width="50px"
              iconSource={likes_cat ? 'btn-check' : 'btn-plus'}
              iconSize="32px"
              iconColor={likes_cat ? colors.primary : colors.defaultBtnBackgroundColor}
              onPress={() => setAngelPreferences({ ...angelPreferences, likes_cat: !likes_cat })}
            />
          </View>
        </TextView>

        <View flexDirection="column" margins="20px">
          <RangeSlider
            paddings="0px"
            step={1}
            minValue={1}
            maxValue={5}
            initialMin={parseInt(max_number_kids || '5', 10)}
            initialMax={5}
            onValueChanged={(value) =>
              setAngelPreferences({ ...angelPreferences, max_number_kids: String(value) })
            }
            leftText={t('signUpAngelScreensNumberOfChildren')}
            rightText={t('signUpAngelScreensChildrens', {
              children: parseInt(max_number_kids || '5', 10)
            })}
            secondTextFontSize="15px"
            secondTextFontWeight="300"
          />

          <RangeSlider
            paddings="0px"
            step={15}
            minValue={0}
            maxValue={45}
            initialMin={parseInt(journey_time || '45', 10)}
            initialMax={45}
            onValueChanged={(value) =>
              setAngelPreferences({ ...angelPreferences, journey_time: String(value) })
            }
            leftText={t('signUpAngelScreensTravelDistance')}
            rightText={t('signUpAngelScreensDistance', { 0: parseInt(journey_time || '45', 10) })}
            secondTextFontSize="15px"
            secondTextFontWeight="300"
          />
        </View>
      </ScrollView>

      <View flexDirection="row-reverse" paddings="10px 20px 10px 20px" borderTopWidth={1}>
        <TextButton
          width="50%"
          backgroundColor={colors.primary}
          text={t('next')}
          onPress={() => {
            const newProgress = progress + 11 >= 100 ? 100 : progress + 11;
            savePreferencesData(preferences);
            if (newProgress === 100 && progress < 100) {
              setProgress(100);
              if (navigation) {
                navigation.popToTop();
              } else {
                history.goBack();
              }
            } else if (navigation) {
              navigation.navigate('SignUpAngelStep9', { progress: newProgress });
            } else {
              history.push({
                pathname: '/signup-angel-availability',
                state: { progress: newProgress }
              });
            }
          }}
        />
      </View>
    </SafeAreaView>
  );
};

Preferences.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      progress: PropTypes.number
    })
  }),
  history: PropTypes.shape({ push: PropTypes.func, goBack: PropTypes.func }),
  route: PropTypes.shape({
    params: PropTypes.shape({
      progress: PropTypes.number
    })
  }),
  navigation: PropTypes.shape({
    setOptions: PropTypes.func,
    popToTop: PropTypes.func,
    navigate: PropTypes.func
  }),
  preferences: PropTypes.shape({}),
  getPreferencesData: PropTypes.func.isRequired,
  savePreferencesData: PropTypes.func.isRequired,
  setProgress: PropTypes.func.isRequired
};

Preferences.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null,
  preferences: {}
};

const mapStateToProps = ({ AngelPreferences }) => ({
  preferences: AngelPreferences.preferences
});

const mapDispatchToProps = {
  getPreferencesData: getPreferences,
  savePreferencesData: savePreferences,
  setProgress: setOnboardingProgress
};

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
