import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAngelReferenceReviewInfoQuery, useAngelReferenceReviewMutation } from '@charlycares/data-access'
import { useRouter } from '@charlycares/shared'
import { Avatar, Box, Heading, HStack } from 'native-base'
import { TextInput, ScreenLayout } from '@charlycares/ui'
import GiveRating from '../../reviews/common/GiveRating'
import { useAlert } from '../../../hooks'

export default function ReviewByReference() {
  const { t } = useTranslation()
  const { getParam, routeParams } = useRouter()
  const alert = useAlert()

  const angelId = getParam('angelId') ?? routeParams?.angelId
  const referenceId = getParam('referenceId') ?? routeParams?.referenceId

  const [ratings, setRatings] = useState<{ label: string; key: string; value: number }[]>([])
  const [comment, setComment] = useState('')

  const { data: angelReferenceReviewInfo, error: angelReferenceReviewInfoError } = useAngelReferenceReviewInfoQuery(
    { angel_id: angelId, angel_reference_id: referenceId },
    { skip: !angelId || !referenceId }
  )
  const [angelReferenceReview, { isSuccess, isLoading }] = useAngelReferenceReviewMutation()

  const ratingCategories = angelReferenceReviewInfo?.rating_categories
  const user = angelReferenceReviewInfo?.user

  useEffect(() => {
    if (ratingCategories) {
      const initialRatings = ratingCategories.map(category => ({
        key: category,
        label: t(`review_${category}`),
        value: 0
      }))

      setRatings(initialRatings)
    }
  }, [ratingCategories])

  const submitReview = async () => {
    try {
      const categories: {
        [key: string]: number
      } = {}

      ratings.forEach(rating => {
        categories[rating.key] = rating.value
      })

      await angelReferenceReview({
        angel_id: angelId,
        angelReferenceId: referenceId,
        review: comment,
        rating_categories: categories
      }).unwrap()
    } catch (error) {
      alert.show(t('error'), (error as any)?.data?.error || t('postReviewError'))
    }
  }

  if (angelReferenceReviewInfoError) {
    return (
      <ScreenLayout title={t('error')} hideBackButton>
        <Box w="100%" mt="20px" px="20px">
          <Heading fontSize="18" mt="5">
            {(angelReferenceReviewInfoError as any)?.data?.error ||
              (angelReferenceReviewInfoError as any)?.data?.message ||
              t('postReviewError')}
          </Heading>
        </Box>
      </ScreenLayout>
    )
  }

  return (
    <ScreenLayout
      isLoading={!angelReferenceReviewInfo}
      title={t('leaveReferenceReviewTitle')}
      _buttonProps={{
        onPress: () => submitReview(),
        isDisabled: ratings.some(rating => rating.value === 0) || comment.length < 10 || isSuccess,
        isLoading: isLoading,
        children: t('sendReference')
      }}
      hideBackButton
    >
      {!isSuccess ? (
        <Box w="100%" mt="30px" px="20px">
          <HStack space="15px" alignItems="center">
            <Avatar size="60px" source={{ uri: user?.image_url }} />
            <Heading fontSize="18">{t('leaveReferenceTitle', { first_name: user?.first_name })}</Heading>
          </HStack>
          <GiveRating mt="60px" ratings={ratings} onChange={setRatings} />

          <TextInput
            mt="100px"
            placeholder={t('leaveReferenceReviewPlaceholder')}
            maxLength={500}
            h="110px"
            variant="filled"
            multiline
            numberOfLines={5}
            value={comment}
            onChangeText={setComment}
          />
        </Box>
      ) : (
        <Box w="100%" mt="20px" px="20px">
          <Heading fontSize="18" mt="5">
            {t('leaveReferenceThanksBody')}
          </Heading>
        </Box>
      )}
    </ScreenLayout>
  )
}
