import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react'
import { Animated, TouchableWithoutFeedback, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import { Portal } from 'react-portal'

const styles = StyleSheet.create({
  modal: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%'
  }
})

const Modal = forwardRef(({ children }, ref) => {
  const [visible, setVisible] = useState(false)
  const animatedValue = useRef(new Animated.Value(0)).current

  const opacity = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1]
  })

  const translateY = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [1000, 0]
  })

  const setIsVisible = isVisible => {
    setVisible(isVisible)
  }

  useImperativeHandle(ref, () => ({
    setIsVisible
  }))

  useEffect(() => {
    Animated.timing(animatedValue, {
      toValue: visible ? 1 : 0,
      duration: 500
    }).start()
  }, [visible])

  return (
    <Portal>
      <Animated.View
        style={[
          styles.modal,
          {
            opacity,
            transform: [{ translateY }]
          }
        ]}>
        <TouchableWithoutFeedback onPress={() => setIsVisible(false)}>
          {children}
        </TouchableWithoutFeedback>
      </Animated.View>
    </Portal>
  )
})

Modal.propTypes = {
  children: PropTypes.node
}

Modal.defaultProps = {
  children: null
}

export default Modal
