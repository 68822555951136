import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const VerifiedAngelIcon = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 27 28" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5.875C6.51.875.844 6.541.844 13.531v.938c0 6.99 5.666 12.656 12.656 12.656s12.656-5.666 12.656-12.656v-.938C26.156 6.541 20.49.875 13.5.875zM6.575 13.819a.823.823 0 011.194 0l3.622 3.756 8.684-9.006a.823.823 0 011.194 0c.329.341.329.896 0 1.237L11.39 20.05l-4.816-4.994a.898.898 0 010-1.237z"
      fill="#4A8FE2"
    />
  </Svg>
)

export default VerifiedAngelIcon
