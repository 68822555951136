import React, { useEffect } from 'react'
import { Card, CharlyIcon, ScreenLayout } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { Button, Divider, Heading, HStack, Text } from 'native-base'
import { useRouter } from '@charlycares/shared'
import { useGetAngelTodosQuery } from '@charlycares/data-access'

const VerificationSuccessScreen = () => {
  const { t } = useTranslation()
  const { navigate } = useRouter()

  const { refetch } = useGetAngelTodosQuery()

  useEffect(() => {
    refetch()
  }, [])

  return (
    <ScreenLayout hideBackButton title={t('profileVerified')}>
      <Card>
        <HStack space="20px" alignItems="center">
          <CharlyIcon size={40} color="alert.success.400" name="icn-check" />
          <Text flex={1} fontSize={15}>
            {t('profileVerifiedDesc')}
          </Text>
        </HStack>

        <Divider my="25px" />

        <Heading fontSize={18}>{t('whatNowTitle')}</Heading>
        <Text mt="10px" fontSize={15}>
          {t('whatNowDesc')}
        </Text>

        <Button onPress={() => navigate('Dashboard', '/dashboard')} mt="35px">
          {t('continueToTheApp')}
        </Button>
      </Card>
    </ScreenLayout>
  )
}

export default VerificationSuccessScreen

VerificationSuccessScreen.navigationOptions = {
  headerShown: false
}
