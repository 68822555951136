import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TextIconView } from '../Texts';
import { IconButton, TextIconButton } from '../Buttons';
import colors from '../../styles/colors';

import kidsIcon from '../../../assets/icons/icn-kids.png';
import rightIcon from '../../../assets/icons/icn-nav-right.png';

const View = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 15px 15px 15px;
`;

const SelectKids = ({ expandKids, kids, selectedKids, onExpand, onToggleSelect }) => {
  const [t] = useTranslation();

  return (
    <>
      {!expandKids ? (
        <View>
          <TextIconView
            flexDirection="row-reverse"
            backgroundColor="transparent"
            iconMargins="0px 10px 0px 0px"
            icon={kidsIcon}
            iconSize="32px"
            iconColor={colors.primaryText}
            text={t('children')}
            fontSize="16px"
          />

          <TextIconButton
            flexDirection="row"
            paddings="0px"
            icon={rightIcon}
            iconColor={colors.grey07}
            text={selectedKids.length.toString()}
            textColor={colors.secondary}
            onPress={onExpand}
          />
        </View>
      ) : (
        kids.map((kid) => {
          const selected = selectedKids.filter((id) => kid.id === id).length;

          return (
            <View key={kid.id}>
              <TextIconView
                flexDirection="row-reverse"
                backgroundColor="transparent"
                iconMargins="0px 10px 0px 0px"
                icon={kidsIcon}
                iconSize="32px"
                iconColor={colors.primaryText}
                text={moment(kid.birthdate).format('LL')}
                fontSize="16px"
              />

              <IconButton
                iconName={selected ? 'btn-check' : 'btn-plus'}
                iconSize={32}
                iconColor={selected ? colors.primary : colors.defaultBtnBackgroundColor}
                onPress={() => onToggleSelect(selected, kid)}
              />
            </View>
          );
        })
      )}
    </>
  );
};

SelectKids.propTypes = {
  expandKids: PropTypes.bool.isRequired,
  kids: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedKids: PropTypes.arrayOf(PropTypes.number).isRequired,
  onExpand: PropTypes.func.isRequired,
  onToggleSelect: PropTypes.func.isRequired
};

export default SelectKids;
