import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { Text, Divider } from '../../../../elements'
import {
  TextButton,
  TextTextView,
  IconTextViewTextView,
  Rating,
  FamilyAvatar,
  AngelAvatar,
  Header,
  IconButton
} from '../../../../components'
import { getFormatedDate, roundMonetaryValue, isWeb } from '../../../../utils/functions'
import colors from '../../../../styles/colors'

import iconDay from '../../../../../assets/icons/icn-day.png'
import iconNight from '../../../../../assets/icons/icn-night.png'
import iconFixed from '../../../../../assets/icons/icn-repeat.png'
import leftIcon from '../../../../../assets/icons/icn-nav-left.png'
import { Linking } from 'react-native'

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  justify-content: space-between;
`
const ScrollView = styled.ScrollView``
const DetailsView = styled.View`
  padding: 10px 20px 0px 20px;
`
const AvatarView = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 10px 0px 10px 0px;
`
const View = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px 10px 0px;
`

const Details = ({ location, history, route, navigation }) => {
  const { paymentDetails, role } = route.params || location.state || {}
  const {
    angel,
    family,
    booking,
    booking_id,
    rating = {},
    costs_summary,
    fee,
    hotline_fee,
    insurance_fee,
    credit_used,
    tip,
    total_amount
  } = paymentDetails
  const [t] = useTranslation()

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <TextTextView
          flexDirection="column"
          firstText={`${t('payment')}`}
          firstTextAlign="center"
          fontFamily="Martel"
          firstFontWeight="600"
          secondText={`${t('detail')}: BKF-${booking_id}`}
          secondTextAlign="center"
        />
      ),
      headerRight: (
        <TextButton
          paddings="0px"
          margins="0px 20px 0px 0px"
          height={30}
          text={t('support')}
          fontWeight="normal"
          textColor={colors.secondary}
          onPress={() => Linking.openURL('https://support.charlycares.com')}
        />
      )
    })
  }

  const { first_name, image } = angel
  const { last_name } = family
  const { actual_start, actual_end } = booking
  const { rates } = costs_summary
  const {
    day_length,
    night_length,
    fixed_length,
    total_length,
    day_amount,
    night_amount,
    fixed_amount,
    day_rate,
    night_rate,
    fixed_rate
  } = rates
  const { comments = '' } = rating
  const actualStart = getFormatedDate(actual_start, 'llll')
  const actualEnd = getFormatedDate(actual_end, 'llll')
  const dayLength = day_length.split(':')
  const nightLength = night_length.split(':')
  const fixedLength = fixed_length ? fixed_length.split(':') : '0:0'.split(':')
  const totalHours = total_length ? total_length.split(':') : '0:0'.split(':')

  return (
    <SafeAreaView>
      {isWeb && (
        <Header
          title={`${t('payment')}`}
          subtitle={`${t('detail')}: BKF-${booking_id}`}
          right={
            <TextButton
              paddings="0px"
              margins="0px 20px 0px 0px"
              height="30px"
              text={t('support')}
              fontWeight="normal"
              textColor={colors.secondary}
              onPress={() => history.push('/support')}
            />
          }
          left={
            <IconButton iconSource={leftIcon} iconSize="32px" iconColor={colors.secondary} onPress={history.goBack} />
          }
        />
      )}
      <ScrollView>
        <DetailsView>
          <AvatarView>
            {role === 'family' ? <AngelAvatar source={image} /> : <FamilyAvatar source={family.image} />}

            <Text margins="0px 0px 0px 10px" fontFamily="Martel" fontSize="16px" fontWeight={600}>
              {role === 'family' ? first_name : `Fam. ${last_name}`}
            </Text>
          </AvatarView>

          <TextTextView
            borderBottomWidth="1px"
            borderTopWidth="1px"
            firstText={t('start')}
            secondText={actualStart}
            secondTextFontSize="16px"
          />

          <TextTextView borderBottomWidth="1px" firstText={t('end')} secondText={actualEnd} secondTextFontSize="16px" />

          {fixed_amount === 0 && (
            <>
              <IconTextViewTextView
                leftIcon={iconDay}
                leftText={`${dayLength[0]} ${t('hours')} ${dayLength[1]} ${t('minutes')} (€ ${roundMonetaryValue(
                  day_rate
                )} ${t('perHour')})`}
                rightText={`€ ${roundMonetaryValue(day_amount)}`}
              />

              <IconTextViewTextView
                leftIcon={iconNight}
                leftText={`${nightLength[0]} ${t('hours')} ${nightLength[1]} ${t('minutes')} (€ ${roundMonetaryValue(
                  night_rate
                )} ${t('perHour')})`}
                rightText={`€ ${roundMonetaryValue(night_amount)}`}
              />
            </>
          )}

          {fixed_amount > 0 && (
            <IconTextViewTextView
              leftIcon={iconFixed}
              leftText={`${fixedLength[0]} ${t('hours')} ${fixedLength[1]} ${t('minutes')} (€ ${roundMonetaryValue(
                fixed_rate
              )} ${t('perHour')})`}
              rightText={`€ ${roundMonetaryValue(fixed_amount)}`}
            />
          )}
          {/* {role === 'angel' && (
            <TextTextView
              firstText={t('paymentsScreenAngelFee')}
              firstTextFontSize="13px"
              secondText={`€ ${roundMonetaryValue(fee)}`}
              secondTextFontSize="13px"
            />
          )} */}

          {role === 'family' && (
            <>
              <TextTextView
                firstText={t('paymentsScreenTransactionCost')}
                firstTextFontSize="13px"
                secondText={`€ ${roundMonetaryValue(fee)}`}
                secondTextFontSize="13px"
              />

              <TextTextView
                firstText={`${t('paymentsScreenMedicalHelpline')} (€0,04 ${t('perHour')})`}
                firstTextFontSize="13px"
                secondText={`€ ${roundMonetaryValue(hotline_fee)}`}
                secondTextFontSize="13px"
              />

              <TextTextView
                firstText={`${t('paymentsScreenAngelInsurance')} (€0,12 ${t('perHour')})`}
                firstTextFontSize="13px"
                secondText={`€ ${roundMonetaryValue(insurance_fee)}`}
                secondTextFontSize="13px"
              />
            </>
          )}

          {credit_used > 0 && (
            <TextTextView
              firstText={t('paymentsScreenBabysittingcredit')}
              firstTextFontSize="13px"
              secondText={`€ ${roundMonetaryValue(credit_used)}`}
              secondTextFontSize="13px"
            />
          )}

          <TextTextView
            margins="20px 0px 0px 0px"
            borderBottomWidth="1px"
            borderTopWidth="1px"
            firstText={t('tip')}
            secondText={tip > 0 ? `€ ${roundMonetaryValue(tip)}` : t('noTip')}
          />

          <View>
            <TextTextView
              flexDirection="column"
              alignItems="flex-start"
              firstText={t('total')}
              firstFontWeight="600"
              secondText={`${totalHours[0]} ${t('hours')}, ${totalHours[1]} ${t('minutes')}`}
            />

            <Text fontSize="30px" textAlign="right">
              {`€ ${roundMonetaryValue(total_amount)}`}
            </Text>
          </View>
        </DetailsView>

        <Divider />

        <DetailsView>
          <TextTextView
            flexDirection="column"
            alignItems="flex-start"
            borderBottomWidth="1px"
            firstText={`${t('feedback')}`}
            firstTextFontFamily="Martel"
            firstFontWeight="600"
            firstTextFontSize="22px"
            secondText={t('paymentsScreenFeedbackQuestion', { 0: first_name })}
            secondTextMargins="10px 0px 10px 0px"
            secondTextColor={colors.primaryText}
            secondTextAlign="left"
          />

          <View>
            <Text fontSize="16px">{t('rating')}</Text>

            <Rating rating={rating.rating || 0} />
          </View>

          {comments !== '' && (
            <TextTextView
              flexDirection="column"
              alignItems="flex-start"
              margins="0px 0px 20px 0px"
              borderTopWidth="1px"
              firstText={t('review')}
              firstTextMargins="0px 0px 5px 0px"
              secondText={comments}
            />
          )}
        </DetailsView>
      </ScrollView>
    </SafeAreaView>
  )
}

Details.propTypes = {
  location: PropTypes.shape({ state: PropTypes.shape({ paymentDetails: PropTypes.shape({}) }) }),
  history: PropTypes.shape({ push: PropTypes.func }),
  route: PropTypes.shape({ params: PropTypes.shape({ paymentDetails: PropTypes.shape({}) }) }),
  navigation: PropTypes.shape({ setOptions: PropTypes.func, navigate: PropTypes.func })
}

Details.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null
}

export default Details
