import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { Text } from '../../elements';
import { AngelAvatar } from '../Profile';
import colors from '../../styles/colors';
import CharlyIcon from '../Icons/Icon';

const View = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  width: 100%;
`;
const AngelAvatarNameView = styled.View`
  flex-direction: row;
  align-items: center;
`;

const AngelAvailabilityCard = ({ angelImage, angelName, angelAvailable }) => (
  <View>
    <AngelAvatarNameView>
      <AngelAvatar source={angelImage} />

      <Text margins="0px 0px 0px 10px">{angelName}</Text>
    </AngelAvatarNameView>

    <CharlyIcon
      size="32px"
      name={angelAvailable ? 'check' : 'close'}
      color={angelAvailable ? colors.primary : colors.alert}
    />
  </View>
);

AngelAvailabilityCard.propTypes = {
  angelImage: PropTypes.node,
  angelName: PropTypes.string.isRequired,
  angelAvailable: PropTypes.bool.isRequired
};

AngelAvailabilityCard.defaultProps = {
  angelImage: null
};

export default AngelAvailabilityCard;
