import React from 'react'
import { useTranslation } from 'react-i18next'
import { useChangeLocaleMutation } from '@charlycares/data-access'
import moment from 'moment'
import { setLegacyLocale } from '@charlycares/shared'
import { useDispatch } from 'react-redux'

const useSetLocale = () => {
  const { i18n } = useTranslation()
  const [changeUserLocale] = useChangeLocaleMutation()
  const dispatch = useDispatch()

  const setLocale = async (locale: string) => {
    i18n.changeLanguage(locale)
    setLegacyLocale(locale.split('_')[0])
    moment.locale(locale.split('_')[0])
    dispatch({ type: 'set_locale', payload: locale })

    await changeUserLocale({ locale })
  }

  return { setLocale }
}

export default useSetLocale
