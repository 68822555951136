import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import Modal from './Modal';
import { Text } from '../../elements';
import { TextTextView } from '../Texts';
import { TextButton } from '../Buttons';
import colors from '../../styles/colors';

const View = styled.View`
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: ${colors.white};
`;
const Header = styled.View`
  padding: 10px 20px 10px 20px;
  align-items: center;
  width: 100%;
  height: auto;
  border-bottom-width: 1px;
  border-color: ${colors.grey08};
`;
const HeaderIndicator = styled.View`
  width: 70px;
  height: 6px;
  border-radius: 6px;
  background-color: ${colors.grey08};
`;
const Content = styled.View`
  flex: 1;
  justify-content: space-between;
  padding: 20px 20px 40px 20px;
`;

const FreeMembership = forwardRef(({ onReactivatePress }, ref) => {
  const [t] = useTranslation();
  const modalRef = useRef(null);

  const setIsVisible = (isVisible) => {
    modalRef.current.setIsVisible(isVisible);
  };

  useImperativeHandle(ref, () => ({
    setIsVisible
  }));

  return (
    <Modal ref={modalRef}>
      <View>
        <Header>
          <HeaderIndicator />

          <Text margins="10px 0px 0px 0px" fontFamily="Martel" fontSize="18px" fontWeight="600">
            {t('freeMembership')}
          </Text>
        </Header>

        <Content>
          <TextTextView
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            firstText={t('freeMembershipDescription')}
            firstTextAlign="left"
            secondText={t('freeMembershipExplanation')}
            secondTextMargins="10px 0px 0px 0px"
            secondTextAlign="left"
          />

          <TextButton
            backgroundColor={colors.primary}
            text={t('freeMembershipReactivate')}
            onPress={onReactivatePress}
          />
        </Content>
      </View>
    </Modal>
  );
});

FreeMembership.propTypes = {
  onReactivatePress: PropTypes.func.isRequired
};

export default FreeMembership;
