import * as React from 'react'
import Svg, { SvgProps, Rect, Path } from 'react-native-svg'

const BtnCloseIcon = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 32 32" fill="none" {...props}>
    <Rect x={1} y={1} width={30} height={30} rx={15} fill="#303036" fillOpacity={0.15} />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.707 11.707l-10 10a1 1 0 01-1.414-1.414l10-10a1 1 0 011.414 1.414z"
      fill="#97979A"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.293 10.293a1 1 0 011.414 0l10 10a1 1 0 01-1.414 1.414l-10-10a1 1 0 010-1.414z"
      fill="#97979A"
    />
  </Svg>
)

export default BtnCloseIcon
