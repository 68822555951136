import { MembershipsName, MembershipsTerm } from '../types'

export const DISPLAYED_MEMBERSHIPS: MembershipsName[] = [
  'basic',
  'flexible',
  'premium',
  'business',
  'full_service',
  'pet_service'
]
export const AVAILABLE_MEMBERSHIPS: MembershipsName[] = ['full_service', 'pet_service']
export const DISPLAYED_TERMS: MembershipsTerm[] = ['monthly', 'yearly', 'trial']
