import axiosInstance from '../../utils/axios';
import {
  SERVER_ERROR,
  FETCHING_RATINGS,
  GET_RATINGS,
  GET_NEXT_RATINGS,
  UPDATING_RATING,
  UPDATE_RATING
} from '../types';

const logError = (error, dispatch) => {
  if (error.response && error.response.status === 500) {
    dispatch({ type: SERVER_ERROR });
  } else {
  }
};

export const getRatings = (amountPerPage = 10) => (dispatch) => {
  dispatch({ type: FETCHING_RATINGS });
  axiosInstance
    .get(`ratings/${amountPerPage}`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: GET_RATINGS,
          payload: response.data.data
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};

export const getNextRatings = (nextUrlPage) => (dispatch) => {
  dispatch({ type: FETCHING_RATINGS });
  axiosInstance
    .get(nextUrlPage)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: GET_NEXT_RATINGS,
          payload: response.data.data
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};

export const resetUpdateRating = () => (dispatch) => {
  dispatch({ type: UPDATING_RATING });
};

export const updateRating = (bookingId, comments) => (dispatch) => {
  dispatch({ type: UPDATING_RATING });
  axiosInstance
    .put(`ratings/${bookingId}`, { comments })
    .then((response) => {
      if (response.data) {
        dispatch({
          type: UPDATE_RATING,
          payload: response.data.data
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};
