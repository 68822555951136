import React from 'react'
import { HStack, IPressableProps, Pressable, Text, VStack } from 'native-base'
import { CharlyIcon } from '../icons'

type Props = {
  items: {
    label: string
    onPress: () => void
    suffix?: React.ReactNode
  }[]
  _item?: IPressableProps
}

const List = ({ items, _item }: Props) => {
  return (
    <VStack>
      {items.map(({ label, suffix, onPress }) => (
        <Pressable
          _pressed={{ opacity: 0.3 }}
          key={label}
          onPress={onPress}
          py="15px"
          borderBottomWidth={1}
          w="100%"
          borderColor="gray.200"
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
          {..._item}
        >
          <Text fontSize="17px" fontWeight="400">
            {label}
          </Text>

          <HStack alignItems="center">
            {suffix}
            <CharlyIcon size={30} color={'gray.500'} name="icn-caret-right" />
          </HStack>
        </Pressable>
      ))}
    </VStack>
  )
}

export default List
