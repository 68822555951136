import React from 'react'
import { Box, BoxProps, Text, Title, ExtendedTextProps } from '@charlycares/ui'
import { useTheme } from 'styled-components/native'

export type DescriptionProps = BoxProps & {
  title?: string
  text?: string
  children?: React.ReactNode | string
  titleProps?: ExtendedTextProps
  textProps?: ExtendedTextProps
}

/**
 * @deprecated
 */
export default function Description({ title, text, children, titleProps, textProps, ...boxProps }: DescriptionProps) {
  const theme = useTheme()

  return (
    <Box {...boxProps}>
      {!!title && (
        <Title margin="0 0 10px 0" {...titleProps}>
          {title}
        </Title>
      )}

      <Text font={theme.fonts.openSans.light} weight="300" {...textProps}>
        {text || children}
      </Text>
    </Box>
  )
}
