import React from 'react'
import { Card, CharlyIcon, TextInput } from '@charlycares/ui'
import { Box, Divider, Button, Text, useTheme } from 'native-base'
import { useGetUserQuery } from '@charlycares/data-access'
import { useTranslation } from 'react-i18next'
import { useRouter } from '@charlycares/shared'

export default function AngelAccount() {
  const { t } = useTranslation()
  const { navigate } = useRouter()
  const { colors } = useTheme()

  const { data: user } = useGetUserQuery()

  return (
    <Box>
      <Text mx="20px" my="5px" fontSize="16px" fontWeight={400} color="gray.700">
        {t('account')}
      </Text>

      <Card mt="0">
        <Box mt="0" w="100%">
          <Box flexDir={'row'} alignItems="center">
            <Text flex={1} fontSize={'15px'}>
              {t('account')}
            </Text>
            <Button
              variant={'ghost'}
              _text={{
                color: 'primary.400'
              }}
              _pressed={{
                bg: 'primary.alpha.10'
              }}
              rightIcon={<CharlyIcon name="icn-nav-right" size={20} color={colors.gray[700]} />}
              onPress={() => navigate('ProfileAngelUnsubscribe', '/ProfileAngelUnsubscribe')}
            >
              {t('deactiveOrUnsubscribe')}
            </Button>
          </Box>
          <Divider bg="gray.200" mt="10px" my="20px" />

          <TextInput isReadOnly value={user?.email} label={t('emailAddress')} />
          <TextInput isReadOnly value={'**************'} label={t('password')} />
        </Box>
      </Card>
    </Box>
  )
}
