const Button = {
  baseStyle: {},
  sizes: {
    xs: {
      _text: {
        fontSize: '13px'
      },
      pt: '4px',
      pb: '4px',
      pr: '16px',
      pl: '16px'
    },
    sm: {
      _text: {
        fontSize: '13px'
      },
      pt: '8px',
      pb: '8px',
      pr: '16px',
      pl: '16px'
    },
    md: {
      _text: {
        fontSize: '16px'
      },
      pt: '8px',
      pb: '8px',
      pr: '16px',
      pl: '16px'
    }
  },
  variants: {
    solid: ({ colorScheme }: any) => ({
      borderRadius: '30px',
      background: `${colorScheme}.400`,
      _hover: {
        background: `${colorScheme}.500`
      },
      _pressed: {
        background: `${colorScheme}.500`
      },
      _text: { fontWeight: 600 },
      _disabled: {
        background: 'gray.400',
        _important: {
          borderColor: 'gray.400',
          _text: {
            color: 'gray.700'
          }
        }
      }
    }),
    ghost: {
      padding: '5px',
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingTop: '5px',
      paddingBottom: '5px',
      _text: { color: 'primary.400' },
      _pressed: {
        background: 'primary.alpha.20'
      },
      _hover: {
        background: 'primary.alpha.20'
      }
    },
    text: {
      padding: '0',
      paddingLeft: '0',
      paddingRight: '0',
      paddingTop: '0',
      paddingBottom: '0',
      w: 'auto',
      _text: { color: 'primary.400', fontWeight: 400 },
      _pressed: {
        opacity: 0.3
      }
    },
    outline: ({ colorScheme }: any) => ({
      borderRadius: '30px',
      borderWidth: '1px',
      _important: {
        borderColor: `${colorScheme}.400`,
        _text: {
          fontSize: '16px',
          color: `${colorScheme}.400`,
          fontWeight: 600
        }
      },
      _hover: {
        background: `${colorScheme}.alpha.10`
      },
      _pressed: {
        background: `${colorScheme}.alpha.10`
      },
      _disabled: {
        background: 'gray.400',
        _important: {
          borderColor: 'gray.400',
          _text: {
            color: 'gray.700'
          }
        }
      }
    })
  },
  defaultProps: {
    variant: 'solid',
    size: 'md',
    colorScheme: 'secondary'
  }
}

export default Button
