import React from 'react';
import PropTypes from 'prop-types';
import { Button, Text } from '../../elements';
import theme from '../../styles/theme';

const TextButton = ({
  text,
  textFlex,
  textMargins,
  textColor,
  fontFamily,
  fontSize,
  fontWeight,
  textAlign,
  lineHeight,
  numberOfLines,
  paddings,
  margins,
  children,
  textWidth,
  ...buttonProps
}) => (
  <Button paddings={paddings} margins={margins} {...buttonProps}>
    <Text
      flex={textFlex}
      margins={textMargins}
      color={textColor}
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontWeight={fontWeight}
      textAlign={textAlign}
      lineHeight={lineHeight}
      numberOfLines={numberOfLines}
      width={textWidth}>
      {text}
    </Text>
    {children}
  </Button>
);

TextButton.propTypes = {
  text: PropTypes.string.isRequired,
  textFlex: PropTypes.number,
  textMargins: PropTypes.string,
  textColor: PropTypes.string,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textAlign: PropTypes.string,
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  numberOfLines: PropTypes.number,
  paddings: PropTypes.string,
  margins: PropTypes.string,
  children: PropTypes.node,
  textWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

TextButton.defaultProps = {
  textFlex: null,
  textMargins: '0px',
  textColor: theme.colors.white,
  fontFamily: theme.fontFamilyBase,
  fontSize: theme.fontSize.lg,
  fontWeight: theme.buttonsFontWeight,
  textAlign: 'center',
  lineHeight: null,
  numberOfLines: null,
  paddings: '0px 20px 0px 20px',
  margins: '0px 0px 0px 0px',
  children: null,
  textWidth: 'auto'
};

export default TextButton;
