import React from 'react'
import { IBoxProps, Pressable } from 'native-base'
import { CharlyIcon } from '../icons'

type Props = IBoxProps & {
  isChecked: boolean
  isDisabled?: boolean
  onChange?: (value: boolean) => void
}

const Checkbox = ({ isChecked, isDisabled, onChange }: Props) => {
  return (
    <Pressable
      onPress={() => onChange?.(!isChecked)}
      _pressed={{
        opacity: 0.5
      }}
      hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
      isDisabled={isDisabled}
      _disabled={{
        opacity: 0.1
      }}
      justifyContent="center"
      alignItems="center"
      borderWidth={1}
      borderRadius="3px"
      borderColor={isChecked ? 'secondary.400' : 'gray.300'}
      bg={isChecked ? 'secondary.400' : 'gray.100'}
      w="24px"
      h="24px"
    >
      {isChecked && <CharlyIcon pointerEvents="none" color="white" name="icn-check" size={20} />}
    </Pressable>
  )
}

export default Checkbox
