/**
 * @desc This file is used for platform specific functions for Web
 */
import { getUserToken, getBaseUrl } from '@charlycares/shared'

export const getDevicePushToken = async (): Promise<string | undefined> => undefined
export const deleteDevicePushToken = async (): Promise<void> => undefined
export const browseMedia = async (options?: any, type?: 'camera' | 'library'): Promise<any> => {
  return new Promise(resolve => {
    const inputElement = document.createElement('input')

    // Set its type to file
    inputElement.type = 'file'

    // Set accept to the file types you want the user to select.
    // Include both the file extension and the mime type
    inputElement.accept = 'image/*'

    // set onchange event to call callback when user has selected file
    inputElement.addEventListener('change', (event: any) => {
      resolve(inputElement?.files?.[0])
    })

    // dispatch a click event to open the file dialog
    inputElement.dispatchEvent(new MouseEvent('click'))
  })
}
export const cameraRoll = async (): Promise<any> => undefined
export const getAppVersion = async (): Promise<string> => ''
export const uploadFile = async (apiPath: string, file: File, name = 'image'): Promise<any> => {
  const token = await getUserToken()
  const baseUrl = await getBaseUrl()

  const formData = new FormData()
  formData.append('image', file)

  const res = await fetch(`${baseUrl}${apiPath}`, {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    }
  })

  if (res.status === 200) {
    return res.json()
  } else {
    throw new Error('error')
  }
}
export const uploadVideo = async (): Promise<any> => undefined
export const setLegacyLocale = (locale: string) => undefined
export const initLegacyStrings = (en: any, nl: any) => undefined
