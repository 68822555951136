import { Box, Button, Column, Heading, Row, Spacer, Text } from 'native-base'
import { formatMoney, User } from '@charlycares/shared'
import { Linking } from 'react-native'
import React from 'react'
import { Card } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'

interface Props {
  user: User
}

export default function OutstandingPayments({ user }: Props) {
  const { t } = useTranslation()

  if (!user?.outstanding_balance || !user?.late_payment_link) {
    return null
  }

  return (
    <Card bgColor="gray.100">
      <Heading fontSize="22px" fontWeight="600" mb="10px">
        {t('outstandingPayments.title')}
      </Heading>
      <Text fontSize="15px" marginBottom="20px">
        {t('outstandingPayments.message')}
      </Text>
      <Box borderTopWidth="1px" borderColor="gray.200">
        <Row marginTop="10px">
          <Column space="xs" marginLeft="20px">
            <Text fontSize="15px" fontWeight="400" mt="10px" color="alert.danger.400" textAlign="center">
              {t('outstandingPayments.outstandingBalance')}
            </Text>
            <Text color="alert.danger.400" fontSize="32px" fontWeight="600" textAlign="center">
              {formatMoney(user.outstanding_balance)}
            </Text>
          </Column>
          <Spacer />
          <Column>
            <Spacer />
            <Button onPress={() => Linking.openURL(user.late_payment_link ?? '')} mb="5px" width="135px">
              {t('outstandingPayments.payNow')}
            </Button>
          </Column>
        </Row>
      </Box>
    </Card>
  )
}
