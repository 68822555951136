import React, { useEffect, useRef, useState } from 'react'
import { Card, CharlyIcon, DateTimePicker, Picker, ScreenLayout } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { Avatar, HStack, Pressable, Skeleton, Spacer, Text, useTheme, VStack } from 'native-base'
import moment, { Moment } from 'moment/moment'
import { formatInterval, formatMoney, isWeb, Money, TransactionType, useRouter } from '@charlycares/shared'
import {
  useGetPaymentSummaryMutation,
  useStopTimerMutation,
  MoneyWalletTransaction,
  TimeWalletTransaction
} from '@charlycares/data-access'
import WalletTransaction from './common/WalletTransaction'
import { useAlert } from '../../../hooks'
import BookingCostSummary from './common/BookingCostSummary'

interface Tip extends Money {
  label: string
  value: number
}

function EndBookingScreen() {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { getParam, navigate } = useRouter()
  const alert = useAlert()

  const bookingId = getParam('bookingId')
  const useBusiness = getParam('useBusiness')

  const startDatePicker = useRef<DateTimePicker | null>(null)
  const endDatePicker = useRef<DateTimePicker | null>(null)
  const tipPicker = useRef<Picker | null>(null)

  const [getPaymentSummaryData, { isLoading, data: bookingPaymentSummary }] = useGetPaymentSummaryMutation()
  const [postStopTimer, { isLoading: postingData }] = useStopTimerMutation()

  const startsAt = moment(bookingPaymentSummary?.starts_at)
  const minStartDate = startsAt.clone().subtract(12, 'hours').format('YYYY-MM-DD HH:mm:ss')
  const maxStartDate = startsAt.clone().add(1, 'day').format('YYYY-MM-DD HH:mm:ss')
  const endsAt = moment(bookingPaymentSummary?.ends_at)

  const tipOptions: Tip[] = [
    { label: t('none'), amount: 0, currency: 'EUR', value: 0 },
    { label: '€1,-', amount: 100, currency: 'EUR', value: 1 },
    { label: '€2,-', amount: 200, currency: 'EUR', value: 2 },
    { label: '€5,-', amount: 500, currency: 'EUR', value: 3 },
    { label: '€10,-', amount: 1000, currency: 'EUR', value: 4 }
  ]

  const minEndDate = startsAt.clone().add(2, 'hours')
  const maxEndDate = endsAt.clone().add(72, 'hours')

  const [startDate, setStartDate] = useState<Moment>(startsAt)
  const [endDate, setEndDate] = useState<Moment>(endsAt)
  const [tip, setTip] = useState<typeof tipOptions[number]>(tipOptions[0])

  useEffect(() => {
    getPaymentSummaryData({ booking_id: bookingId, use_business: useBusiness })

    if (useBusiness && !__DEV__) {
      alert.show(t('endBooking.businessAlert.title'), t('endBooking.businessAlert.description'), [
        {
          text: t('endBooking.businessAlert.goBack'),
          onPress: () => navigate('ChatInbox', '/'),
          style: isWeb ? 'cancel' : undefined
        },
        { text: t('endBooking.businessAlert.letsGo'), style: !isWeb ? 'cancel' : undefined }
      ])
    }
  }, [getPaymentSummaryData, bookingId])

  useEffect(() => {
    if (!isLoading && bookingPaymentSummary) {
      setStartDate(moment(bookingPaymentSummary?.starts_at))
      setEndDate(moment(bookingPaymentSummary?.ends_at))
      setTip(
        tipOptions.find(
          tip =>
            tip.currency === bookingPaymentSummary.rate.tip.currency &&
            tip.amount === bookingPaymentSummary.rate.tip.amount
        ) ?? tipOptions[0]
      )
    }
  }, [isLoading, bookingPaymentSummary])

  const updateBookingData = (startsAt: string | Moment, endsAt: string | Moment, tip: typeof tipOptions[number]) => {
    setStartDate(moment(startsAt))
    setEndDate(moment(endsAt))
    setTip(tip)

    getPaymentSummaryData({
      booking_id: bookingId,
      use_business: useBusiness,
      starts_at: startsAt,
      ends_at: endsAt,
      tip: { amount: tip.amount, currency: tip.currency }
    })
  }

  const totalCosts = () => {
    const moneyTransaction = bookingPaymentSummary?.wallet_transactions.find(
      walletTransaction => walletTransaction.transaction_type === TransactionType.MONEY
    ) as MoneyWalletTransaction | undefined

    if (moneyTransaction?.used_saldo) {
      return formatMoney(moneyTransaction.used_saldo)
    }

    return formatMoney({ amount: 0, currency: 'EUR' })
  }

  const minuteCosts = () => {
    const timeTransaction = bookingPaymentSummary?.wallet_transactions.find(
      walletTransaction => walletTransaction.transaction_type === TransactionType.MINUTES
    ) as TimeWalletTransaction | undefined

    if (timeTransaction?.costs) {
      return formatMoney(timeTransaction.costs)
    }

    return null
  }
  const endBooking = async () => {
    try {
      const res = await postStopTimer({
        booking_id: bookingId,
        use_business: useBusiness,
        starts_at: startsAt,
        ends_at: endsAt,
        tip: { amount: tip.amount, currency: tip.currency }
      }).unwrap()

      navigate('BookingFeedback', '/booking/feedback', { bookingId, isBookedBefore: res.is_booked_before })
    } catch (error) {
      alert.show(t('error'), (error as any)?.data?.message)
    }
  }

  return (
    <ScreenLayout
      title={t('endBooking.headerTitle')}
      edges={['right', 'left']}
      _bottomContainer={{
        backgroundColor: 'white',
        paddingBottom: '30px'
      }}
      _buttonProps={{
        onPress: endBooking,
        isLoading: postingData,
        children: t('endBooking.endBookingButton')
      }}
      _buttonDisclaimer={{
        children: t('endBooking.endBookingDisclaimer')
      }}
    >
      {bookingPaymentSummary?.wallet_transactions.map((walletTransaction, index) => (
        <WalletTransaction key={`transaction-${index}`} walletTransaction={walletTransaction} />
      ))}
      {useBusiness && isLoading && (
        <Card>
          <Skeleton h={100} />
        </Card>
      )}
      <Card>
        <HStack alignItems="center" w="100%" pb="10px" borderColor="gray.200" borderBottomWidth="1">
          <Avatar source={{ uri: bookingPaymentSummary?.angel.image }} borderWidth="1px" borderColor="gray.200" />
          <Text fontSize="16px" ml="10px">
            {bookingPaymentSummary?.angel.first_name}
          </Text>
        </HStack>
        <HStack alignItems="center" py="10px" w="100%" borderColor="gray.200" borderBottomWidth="1">
          <Text fontWeight="600" fontSize="16px">
            {t('endBooking.start')}
          </Text>
          <Spacer />
          <Pressable onPress={() => startDatePicker.current?.open(startDate.format('YYYY-MM-DD HH:mm:ss'))}>
            <HStack fontSize="16px" alignItems="center">
              <Text color="primary.400">{startDate.format('ll HH:mm')}</Text>
              <CharlyIcon color={colors.primary[400]} name="icn-edit" size={20} />
            </HStack>
          </Pressable>

          <DateTimePicker
            ref={startDatePicker}
            type="datetime"
            minDate={minStartDate}
            maxDate={maxStartDate}
            textConfirm={t('done')}
            textCancel={t('cancel')}
            minuteInterval={5}
            onConfirmPress={(value: string) => updateBookingData(value, endDate, tip)}
          />
        </HStack>
        <HStack alignItems="center" py="10px" w="100%" borderColor="gray.200" borderBottomWidth="1">
          <Text fontWeight="600" fontSize="16px">
            {t('endBooking.end')}
          </Text>
          <Spacer />
          <Pressable onPress={() => endDatePicker.current?.open(endDate.format('YYYY-MM-DD HH:mm:ss'))}>
            <HStack fontSize="16px" alignItems="center">
              <Text color="primary.400">{endDate.format('ll HH:mm')}</Text>
              <CharlyIcon color={colors.primary[400]} name="icn-edit" size={20} />
            </HStack>
          </Pressable>

          <DateTimePicker
            ref={endDatePicker}
            type="datetime"
            minDate={minEndDate}
            maxDate={maxEndDate}
            minuteInterval={5}
            textConfirm={t('done')}
            textCancel={t('cancel')}
            onConfirmPress={(value: string) => updateBookingData(startDate, value, tip)}
          />
        </HStack>
        {!isLoading && (
          <BookingCostSummary
            dayRatePerHour={bookingPaymentSummary?.day_rate_per_hour}
            nightRatePerHour={bookingPaymentSummary?.night_rate_per_hour}
            insuranceFeePerHour={bookingPaymentSummary?.insurance_fee_per_hour}
            bookingRate={bookingPaymentSummary?.rate}
            isLoading={isLoading}
          />
        )}
        {isLoading &&
          Array.from({ length: 4 }).map((_, index) => (
            <HStack alignItems="center" py="10px" key={index}>
              <Skeleton h="4" w="100%" rounded="full" startColor="gray.400" />
            </HStack>
          ))}
        <HStack alignItems="center" py="10px" w="100%" borderColor="gray.200" borderTopWidth="1">
          <Text fontWeight="600" fontSize="16px">
            {t('endBooking.tip')}
          </Text>
          <Spacer />
          <Pressable onPress={() => tipPicker.current?.open(tip)}>
            <Text color="primary.400">{tip.amount !== 0 ? formatMoney(tip as Money) : t('endBooking.addATip')}</Text>
          </Pressable>
          <Picker
            ref={tipPicker}
            value={tip}
            options={tipOptions}
            textConfirm={t('done')}
            textCancel={t('cancel')}
            onConfirmPress={(selectedTip: typeof tipOptions[number]): void => {
              updateBookingData(startsAt, endsAt, selectedTip)
            }}
          />
        </HStack>
        {minuteCosts() && (
          <HStack alignItems="center" py="10px" w="100%" borderColor="gray.200" borderTopWidth="1">
            <Text fontSize="12px">{t('endBooking.companyPays')}</Text>
            <Spacer />
            <Text>{minuteCosts()}</Text>
          </HStack>
        )}

        <HStack alignItems="center" py="10px" w="100%" borderColor="gray.200" borderTopWidth="1">
          <VStack>
            <Text fontWeight="600" fontSize="16px">
              {t('endBooking.total')}
            </Text>
            {!isLoading && <Text>{formatInterval(bookingPaymentSummary?.rate.duration.total)}</Text>}
            {isLoading && <Skeleton rounded="full" h={5} />}
          </VStack>
          <Spacer />
          {!isLoading && (
            <Text fontWeight="600" fontSize="22px">
              {bookingPaymentSummary?.wallet_transactions && totalCosts()}
            </Text>
          )}
          {isLoading && <Skeleton rounded="full" w="80px" h={5} />}
        </HStack>

        <HStack alignItems="center" w="100%">
          <VStack>
            <Text color="gray.800" fontSize="12px">
              {t('endBooking.angelPayout')}
            </Text>
          </VStack>
          <Spacer />
          {!isLoading && (
            <Text color="gray.800" fontSize="12px">
              {formatMoney(bookingPaymentSummary?.rate.angel_payout)}
            </Text>
          )}
          {isLoading && <Skeleton rounded="full" w="80px" h={5} />}
        </HStack>
      </Card>
    </ScreenLayout>
  )
}

EndBookingScreen.navigationOptions = {
  headerShown: false
}

export default EndBookingScreen
