import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { Text, Input } from '../../elements';
import theme from '../../styles/theme';

const View = styled.View`
  ${({ flex }) => flex && { flex }};
  ${({ zIndex }) => zIndex && { 'z-index': zIndex }}
  position: ${({ position }) => position};
  ${({ top }) => top && { top }};
  ${({ bottom }) => bottom && { bottom }};
  ${({ right }) => right && { right }};
  ${({ left }) => left && { left }};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-width: ${({ minWidth }) => minWidth};
  min-height: ${({ minHeight }) => minHeight};
  margin: ${({ margins }) => margins};
  padding: ${({ paddings }) => paddings};
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  align-self: ${({ alignSelf }) => alignSelf};
  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: ${({ opacity }) => opacity};
  border-right-width: ${({ borderRightWidth }) => borderRightWidth};
  border-left-width: ${({ borderLeftWidth }) => borderLeftWidth};
  border-top-width: ${({ borderTopWidth }) => borderTopWidth};
  border-bottom-width: ${({ borderBottomWidth }) => borderBottomWidth};
  border-color: ${({ borderColor }) => borderColor};
  border-radius: ${({ borderRadius }) => borderRadius};
  ${({ transform }) => transform && { transform }};
`;

const InputView = ({
  flex,
  zIndex,
  position,
  top,
  bottom,
  right,
  left,
  width,
  height,
  minWidth,
  minHeight,
  margins,
  paddings,
  flexDirection,
  justifyContent,
  alignItems,
  alignSelf,
  backgroundColor,
  opacity,
  borderRightWidth,
  borderLeftWidth,
  borderTopWidth,
  borderBottomWidth,
  borderColor,
  borderRadius,
  transform,
  validationMessage,
  children,
  ...inputProps
}) => (
  <View
    flex={flex}
    zIndex={zIndex}
    position={position}
    top={top}
    bottom={bottom}
    right={right}
    left={left}
    width={width}
    height={height}
    minWidth={minWidth}
    minHeight={minHeight}
    margins={margins}
    paddings={paddings}
    flexDirection={flexDirection}
    justifyContent={justifyContent}
    alignItems={alignItems}
    alignSelf={alignSelf}
    backgroundColor={backgroundColor}
    opacity={opacity}
    borderRightWidth={borderRightWidth}
    borderLeftWidth={borderLeftWidth}
    borderTopWidth={borderTopWidth}
    borderBottomWidth={borderBottomWidth}
    borderColor={borderColor}
    borderRadius={borderRadius}
    transform={transform}>
    {children}
    <Input {...inputProps} />
    {validationMessage && (
      <Text fontSize="12px" color={theme.colors.alert}>
        {validationMessage}
      </Text>
    )}
  </View>
);

InputView.propTypes = {
  flex: PropTypes.number,
  zIndex: PropTypes.number,
  position: PropTypes.string,
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  margins: PropTypes.string,
  paddings: PropTypes.string,
  flexDirection: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  alignSelf: PropTypes.string,
  backgroundColor: PropTypes.string,
  opacity: PropTypes.number,
  borderRightWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderLeftWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderTopWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderBottomWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderColor: PropTypes.string,
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transform: PropTypes.string,
  validationMessage: PropTypes.string,
  children: PropTypes.node
};

InputView.defaultProps = {
  flex: null,
  zIndex: null,
  position: 'relative',
  top: null,
  bottom: null,
  right: null,
  left: null,
  width: 'auto',
  height: 'auto',
  minWidth: 0,
  minHeight: 0,
  margins: '0px',
  paddings: '0px',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  alignSelf: 'auto',
  backgroundColor: 'transparent',
  opacity: 1,
  borderRightWidth: 0,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  borderBottomWidth: 0,
  borderColor: theme.colors.grey08,
  borderRadius: 0,
  transform: null,
  validationMessage: null,
  children: null
};

export default InputView;
