import { GET_AGENDAS, GET_APPOINTMENT_TIMESLOTS, GET_APPOINTMENT_TIMESLOTS_ERROR } from '../types';

const initialState = {
  loading: false,
  agendas: [],
  appointmentTimeslots: {},
  timeslotsError: ''
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_AGENDAS:
      return {
        ...initialState,
        agendas: payload,
        loading: false
      };
    case GET_APPOINTMENT_TIMESLOTS:
      return {
        ...state,
        appointmentTimeslots: payload,
        loading: false
      };
    case GET_APPOINTMENT_TIMESLOTS_ERROR:
      return {
        ...state,
        timeslotsError: payload.error,
        loading: payload.loading
      };
    default:
      return state;
  }
}
