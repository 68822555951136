import React from 'react'
import { Button, HStack, IBoxProps } from 'native-base'
import { languageCountryCodes } from '@charlycares/shared'

type Props = IBoxProps & {
  onChange: (value: string[]) => void
  languages: string[]
}

const LanguageSelector = ({ onChange, languages, ...boxProps }: Props) => {
  return (
    <HStack space="10px" flexDir="row" alignItems="center" justifyContent="space-between" w="100%" {...boxProps}>
      {languageCountryCodes.map(lng => {
        const isSelected = languages.includes(lng.country)
        return (
          <Button
            onPress={() => {
              onChange(
                isSelected
                  ? languages.length > 1
                    ? languages.filter(l => l !== lng.country)
                    : languages
                  : [...languages, lng.country]
              )
            }}
            key={lng.country}
            borderWidth={2}
            _important={{ borderColor: isSelected ? 'secondary.500' : 'gray.200' }}
            borderRadius="5px"
            py="5px"
            px="7px"
            minWidth="40px"
            variant="outline"
            _text={{ textTransform: 'uppercase', color: isSelected ? 'secondary.500' : 'gray.200' }}
          >
            {lng.code}
          </Button>
        )
      })}
    </HStack>
  )
}

export default LanguageSelector
