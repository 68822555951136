import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SocketIOClient from 'socket.io-client'
import { connect } from 'react-redux'

import { Box, Text } from '@charlycares/ui'
import { Button } from 'native-base'

import { getConversations } from '../../stores/actions/chatAction'
import Inbox from './Inbox'
import Room from './Room'
import { Linking } from 'react-native'

const disableChat = true

const Chat = ({ location, history }) => {
  const [showInbox, setShowInbox] = useState(!location.showRoom)
  const [activeConversation, setActiveConversation] = useState({})
  const socket = SocketIOClient('http://charlycares-chat-dev.herokuapp.com/', {
    transports: ['websocket'],
    forceNode: true
  })

  const openChatInApp = () => {
    Linking.openURL(
      'https://charlycares.page.link/?link=https://app.charlycares.com/chat?apn=nl.digitalchefs.charlycares&isi=1140986378&ibi=com.charlycares.charlycares&_imcp=1'
    )
  }

  if (disableChat) {
    return (
      <Box center flex={1}>
        <Text weight="bold" size={22}>
          Chat is tijdelijk uitgeschakeld
        </Text>
        <Button mt="10px" onPress={openChatInApp} type="text">
          open in app
        </Button>
      </Box>
    )
  }

  return showInbox ? (
    <Inbox
      location={location}
      history={history}
      socket={socket}
      openRoom={conversation => {
        socket.removeAllListeners()
        setActiveConversation(conversation)
        setShowInbox(false)
      }}
    />
  ) : (
    <Room
      location={location}
      history={history}
      socket={socket}
      setScreen={() => {
        setShowInbox(true)
        setActiveConversation({})
        history.replace('/chat')
      }}
      {...activeConversation}
    />
  )
}

Chat.propTypes = {
  location: PropTypes.shape({ showRoom: PropTypes.bool }).isRequired,
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired
}

const mapStateToProps = state => ({
  conversations: state.Chat.conversations
})

const mapDispatchToProps = {
  getAllConversations: getConversations
}

export default connect(mapStateToProps, mapDispatchToProps)(Chat)
