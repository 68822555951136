const colors = {
  white: '#FFFFFF',
  primary: {
    500: '#d64d68',
    400: '#F56B87',
    350: '#F890A5',
    300: '#F1D4DC',
    alpha: {
      10: 'rgba(245, 107, 135, 0.1)',
      20: 'rgba(245, 107, 135, 0.2)',
      30: 'rgba(245, 107, 135, 0.3)',
      40: 'rgba(245, 107, 135, 0.4)',
      50: 'rgba(245, 107, 135, 0.5)',
      60: 'rgba(245, 107, 135, 0.6)',
      70: 'rgba(245, 107, 135, 0.7)',
      80: 'rgba(245, 107, 135, 0.8)',
      90: 'rgba(245, 107, 135, 0.9)'
    }
  },
  secondary: {
    600: '#062D51',
    500: '#084172',
    400: '#0B5394',
    300: '#488de0',
    alpha: {
      10: 'rgba(11, 83, 148, 0.1)',
      15: 'rgba(11, 83, 148, 0.15)',
      20: 'rgba(11, 83, 148, 0.2)',
      30: 'rgba(11, 83, 148, 0.3)',
      40: 'rgba(111, 83, 148, 0.4)',
      50: 'rgba(111, 83, 148, 0.5)',
      60: 'rgba(111, 83, 148, 0.6)',
      70: 'rgba(111, 83, 148, 0.7)',
      80: 'rgba(111, 83, 148, 0.8)',
      90: 'rgba(111, 83, 148, 0.9)'
    }
  },
  chat: {
    400: '#DFF1D4'
  },
  alert: {
    warning: {
      400: '#FFBA59',
      alpha: {
        50: 'rgba(255, 186, 89, 0.5)',
        20: 'rgba(255, 186, 89, 0.2)'
      }
    },
    success: {
      400: '#33D69D',
      300: '#C1F2E1'
    },
    info: {
      400: '#4A8FE2'
    },
    danger: {
      400: '#DD0000',
      300: '#F50B3A',
      alpha: {
        30: 'rgba(221, 0, 0, 0.3)'
      }
    }
  },
  text: {
    dark: {
      400: '#303036'
    }
  },
  bg: {
    light: {
      400: '#f9f8f9',
      alpha: {
        50: '#f9f8f9'
      }
    },
    dark: {
      alpha: {
        50: '#30303680'
      }
    }
  },
  gray: {
    900: '#303036',
    800: '#68686e',
    700: '#97979a',
    600: '#a9a9ac',
    500: '#c7c7c9',
    400: '#d9d9d9',
    300: '#dedede',
    200: '#e6e6e6',
    100: '#f2f2f2',
    50: '#fafafa',
    alpha400: {
      30: 'rgba(217, 217, 217, 0.3)'
    },
    alpha900: {
      10: 'rgba(48, 48, 54, 0.1)',
      15: 'rgba(48, 48, 54, 0.15)',
      20: 'rgba(48, 48, 54, 0.2)',
      30: 'rgba(48, 48, 54, 0.3)',
      40: 'rgba(48, 48, 54, 0.4)',
      50: 'rgba(48, 48, 54, 0.5)',
      60: 'rgba(48, 48, 54, 0.6)',
      70: 'rgba(48, 48, 54, 0.7)',
      80: 'rgba(48, 48, 54, 0.8)',
      90: 'rgba(48, 48, 54, 0.9)'
    }
  }
}

export default colors
