import React from 'react'
import { TouchableOpacity } from 'react-native'
import { Box, Center, Factory, Heading, Text, useTheme } from 'native-base'
import { CharlyIcon } from '@charlycares/ui'
import { useGetUserQuery } from '@charlycares/data-access'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useRouter } from '@charlycares/shared'

const Touchable = Factory(TouchableOpacity)

const AvailabilityWidget = () => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const { data: user } = useGetUserQuery()
  const { navigate } = useRouter()

  const availabilities = user?.angel?.availabilities

  return (
    <Touchable p="10px" bg="gray.100" borderRadius="4px" flex={1} onPress={() => navigate('ProfileAngelPreferences')}>
      <Box flexDir="row" alignItems="center">
        <Heading flex={1} fontWeight={600} fontSize="15px">
          {t('minAvailabilityTitle')}
        </Heading>
        <CharlyIcon size={30} color={colors.gray[500]} name="icn-caret-right" />{' '}
      </Box>

      <Box mt="15px" w="100%">
        <Box flexDir="row">
          <Box flex={1} />

          <Box flexDir={'row'} alignItems="center" justifyContent="space-around" flex={3} overflow="hidden">
            <CharlyIcon size={25} name="icn-morning" />
            <CharlyIcon size={25} name="icn-day" />
            <CharlyIcon size={25} name="icn-night" />
          </Box>
        </Box>

        <Box mt="5px">
          {availabilities?.map(day => {
            return (
              <Box flex={1} key={day.id} alignItems="center" flexDir="row" py="2px">
                <Text textAlign="center" flex={1} fontSize="11px">
                  {moment()
                    .day(day.day_of_week + 1)
                    .format('dddd')
                    .substring(0, 2)}
                </Text>

                <Box flexDir={'row'} alignItems="center" justifyContent="space-around" flex={3}>
                  <Center>
                    <Box borderRadius="1px" bg={day.morning ? 'secondary.400' : 'gray.400'} w="10px" h="10px" />
                  </Center>
                  <Center>
                    <Box borderRadius="1px" bg={day.afternoon ? 'secondary.400' : 'gray.400'} w="10px" h="10px" />
                  </Center>
                  <Center>
                    <Box borderRadius="1px" bg={day.evening ? 'secondary.400' : 'gray.400'} w="10px" h="10px" />
                  </Center>
                </Box>
              </Box>
            )
          })}
        </Box>
      </Box>
    </Touchable>
  )
}

export default AvailabilityWidget
