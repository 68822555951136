import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { TextTextView } from '../Texts';
import { Address } from '../Profile';

const View = styled.View`
  flex: 1;
  padding: 20px;
`;

const BookingInvitationAdditionalDetails = ({
  streetName,
  streetNumber,
  city,
  lon,
  lat,
  message
}) => {
  const [t] = useTranslation();

  return (
    <View>
      {/* <Address
        streetName={streetName}
        streetNumber={streetNumber}
        city={city}
        lon={lon}
        lat={lat}
      /> */}

      {message.length > 0 && (
        <TextTextView
          flex={1}
          flexDirection="column"
          alignItems="flex-start"
          firstText={t('bookingsScreenPersonalMessage')}
          firstTextFontFamily="Martel"
          firstTextFontWeight="600"
          secondText={message}
          secondTextMargins="10px 0px 0px 0px"
          secondTextFontSize="15px"
          secondTextFontWeight="300"
          secondTextAlign="left"
        />
      )}
    </View>
  );
};

BookingInvitationAdditionalDetails.propTypes = {
  streetName: PropTypes.string.isRequired,
  streetNumber: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  lon: PropTypes.number.isRequired,
  lat: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired
};

export default BookingInvitationAdditionalDetails;
