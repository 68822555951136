import styled from 'styled-components/native'
import { isWeb, setStyle, styledTheme } from '@charlycares/shared'

import { BaseProps, baseProps } from './base'

export type ExtendedTextProps = BaseProps & {
  size?: string | number
  font?: string
  color?: string
  align?: 'left' | 'center' | 'right'
  weight?: 'normal' | 'bold' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900'

  style?: 'normal' | 'italic'
  transform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none'
  letterSpacing?: string
  letterHeight?: string
}

/**
 * @deprecated Use native-base Text
 */
export const Text = styled.Text<ExtendedTextProps>`
  ${baseProps}
  ${p => setStyle('font-size', p.size, p.theme.fontSize.md)};
  ${p => setStyle('font-family', p.font, p.theme.fonts.openSans.normal)};
  ${p => setStyle('font-weight', p.weight, undefined, !isWeb)};
  ${p => setStyle('text-align', p.align)};
  ${p => setStyle('color', p.color, p.theme.colors.primaryText)};
  ${p => setStyle('font-style', p.style)};
  ${p => setStyle('text-transform', p.transform)};
  ${p => setStyle('letter-spacing', p.letterSpacing)};
  ${p => setStyle('letter-height', p.letterHeight)};
`

/**
 * @deprecated Use native-base Heading
 */
export const Title = styled(Text)`
  ${p => setStyle('font-size', p.size, p.theme.fontSize.lg)};
  ${p => setStyle('font-family', p.font, p.theme.fonts.martel.semiBold)};
  ${p => setStyle('font-weight', p.weight, '600', !isWeb)};
  ${p => setStyle('text-align', p.align)};
`

/**
 * @deprecated Use native-base Heading
 */
export const Heading = styled(Text)``
Heading.defaultProps = {
  size: '22px',
  weight: '600',
  font: styledTheme.fonts.openSans.semiBold
}

/**
 * @deprecated Use native-base Text
 */
export const Paragraph = styled(Text)`
  ${p => setStyle('font-family', p.font, p.theme.fonts.openSans.light)};
  ${p => setStyle('font-weight', p.weight, '300', !isWeb)};
`
