import React from 'react';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';

import { Heading, Text } from '../elements';
import { theme } from '../styles';

const View = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 50px;
  padding: 0px 12px;
  border-bottom-width: 1px;
  border-bottom-color: ${theme.colors.grey08};
  background-color: ${theme.colors.white};
`;
const Item = styled.View`
  flex: 1 1 0;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent};
`;
const TitleView = styled.View`
  flex-direction: column;
  text-align: center;
`;

const Header = ({ left, center, title, subtitle, right }) => (
  <View accessibilityRole="navigation">
    <Item justifyContent="flex-start">{left}</Item>
    <Item justifyContent="center">
      <TitleView>
        {title && (
          <Heading
            xl={!subtitle}
            lineHeight={1.1}
            margins="10px"
            fontFamily={theme.fontFamilyAlt}
            ariaLevel={2}
            text={title}
          />
        )}
        {subtitle && (
          <Text xs lighterColor>
            {subtitle}
          </Text>
        )}
      </TitleView>
      {center}
    </Item>
    <Item justifyContent="flex-end">{right}</Item>
  </View>
);

Header.propTypes = {
  left: PropTypes.node,
  center: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  right: PropTypes.node,
};

Header.defaultProps = {
  left: null,
  center: null,
  title: null,
  subtitle: null,
  right: null,
};

export default Header;
