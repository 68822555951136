import React, { useEffect } from 'react'
import { ButtonsTabs, Card, CharlyIcon } from '@charlycares/ui'
import { Box, Button, Center, Stack, Text } from 'native-base'
import {
  addBookingDay,
  BookingRequestType,
  changeBookingType,
  removeBookingDay,
  resetBookingDates,
  updateBookingDates,
  useAppSelector
} from '@charlycares/data-access'
import { BookingTypes, isWeb } from '@charlycares/shared'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import BookingDatePicker from './BookingDatePicker'

type Props = {
  bookingRequestType?: BookingRequestType
}

const BookingDatesSelector = ({ bookingRequestType }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { selectedDates, bookingType } = useAppSelector(state => state.booking.search)

  useEffect(() => {
    if (!isWeb) {
      // This is problematic on web, because it will reset the dates when the user navigates back to the page
      //TODO: Probably think about a better solution to manage dates
      dispatch(resetBookingDates())
    }
  }, [dispatch])

  useEffect(() => {
    if (bookingRequestType && !bookingRequestType.supported_date_types?.includes(BookingTypes.SINGLE)) {
      dispatch(changeBookingType(BookingTypes.MULTI))
    } else {
      dispatch(changeBookingType(BookingTypes.SINGLE))
    }
  }, [bookingRequestType, dispatch])

  return (
    <Box w="100%">
      {!bookingRequestType && (
        <Card>
          <ButtonsTabs
            tabs={[
              { label: t('singleBookingTab'), key: BookingTypes.SINGLE },
              { label: t('multiBookingTab'), key: BookingTypes.MULTI }
            ]}
            activeTab={bookingType}
            onChange={tab => dispatch(changeBookingType(tab as BookingTypes))}
          />
        </Card>
      )}

      <Stack space="10px" mt="10px">
        {selectedDates.map(({ startDate, endDate, repeatDates }, index) => (
          <Box key={startDate.toString() + index}>
            {bookingType === BookingTypes.MULTI && (
              <Box px="20px" mb="5px" flexDir="row" justifyContent="space-between" alignItems="center">
                <Text fontSize="18px" fontWeight={600}>{`${t('day')} ${index + 1}`}</Text>
                {selectedDates.length > 1 && (
                  <CharlyIcon
                    onPress={() => dispatch(removeBookingDay(index))}
                    name="btn-close-sm"
                    color="gray.700"
                    size={20}
                  />
                )}
              </Box>
            )}

            <BookingDatePicker
              bookingIndex={index}
              showRepeat={bookingType === BookingTypes.MULTI}
              repeatDates={repeatDates?.length}
              startDate={startDate}
              endDate={endDate}
              onChange={dates => dispatch(updateBookingDates({ index, dates }))}
            />
          </Box>
        ))}
      </Stack>

      {bookingType === BookingTypes.MULTI && selectedDates.length < 3 && (
        <Center w="100%" mb="40px" mt="20px">
          <Button
            _text={{
              color: 'gray.700',
              fontSize: '15px'
            }}
            leftIcon={<CharlyIcon name="btn-plus" size={30} color={'gray.500'} />}
            variant="text"
            onPress={() => dispatch(addBookingDay())}
          >
            {t('addDay')}
          </Button>
        </Center>
      )}
    </Box>
  )
}

export default BookingDatesSelector
