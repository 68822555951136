import React from 'react';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Time } from '../Icons';
import { TextView } from '../Texts';
import { Circle, ContainerView, Heading, Icon, Text } from '../../elements';
import CountdownTimer from './CountdownTimer';
import { getFormatedDate } from '../../utils/functions';
import theme from '../../styles/theme';

import warningIcon from '../../../assets/icons/icn-small-error.png';

const TouchableOpacity = styled.TouchableOpacity`
  flex-direction: column;
  justify-content: space-between;
  margin: 0px;
  padding: ${theme.spacer.lg};
  border-bottom-width: 1px;
  border-color: ${theme.dividerLineColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
const IconCountdownTimerView = styled.View`
  flex-direction: row;
  align-items: center;
`;

const View = styled.View`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin: ${({ margins }) => margins || 0};
`;

const getStateColor = (currentState) => {
  switch (currentState) {
    case 'accepted':
      return { background: theme.statusAcceptedColor };
    case 'pending':
      return { background: theme.statusPendingColor };
    case 'pending_edit':
      return { background: theme.statusPendingColor };
    case 'pending_approval':
      return { background: theme.colors.white, border: theme.statusPendingColor };
    case 'given':
      return { background: theme.statusNeutralColor };
    case 'ended':
      return { background: theme.statusNeutralColor };
    default:
      return { background: theme.statusErrorColor };
  }
};

const InvitationCard = ({
  booking,
  bookings,
  repeatQty,
  family,
  expiresAt,
  currentState,
  pendingEdit,
  onCardPress
}) => {
  const [t] = useTranslation();
  const bookingDays = bookings
    .map(({ start_date }) => getFormatedDate(start_date, 'dddd'))
    .join(', ');
  const startEndTime =
    repeatQty > 1
      ? ''
      : `${getFormatedDate(booking.start_date, 'HH:mm')} - ${getFormatedDate(
          booking.end_date,
          'HH:mm'
        )}`;
  const startDate = getFormatedDate(booking.start_date, 'LL');
  const { background, border } = getStateColor(currentState);

  return (
    <TouchableOpacity
      onPress={onCardPress}
      backgroundColor={currentState !== 'pending' ? 'transparent' : theme.colors.yellowAlpha20}>
      <TextView flexDirection="row-reverse" justifyContent="space-between" text={startEndTime}>
        <ContainerView flexDirection="row-reverse">
          <Heading ariaLevel={4} text={bookingDays} />
          {repeatQty > 1 && (
            <TextView
              margins="0px 4px 0px 0px"
              height="16px"
              width="24px"
              justifyContent="center"
              alignItems="center"
              borderRadius="8px"
              backgroundColor={theme.colors.grey05}
              text={`${repeatQty}x`}
              fontSize="12px"
            />
          )}
        </ContainerView>
      </TextView>

      <View margins="0 0 4px 0">
        <Text lightColor>{startDate}</Text>
        <ContainerView justifyContent="flex-end" flexDirection="row" alignItems="center">
          <Text xs lightColor textAlign="right">
            {t(`invitationStates${currentState}`)}
          </Text>
          <Circle
            margins="0 0 0 4px"
            backgroundColor={background}
            borderColor={border || background}
          />
        </ContainerView>
      </View>

      <View>
        <Text xs lighterColor>
          {family}
        </Text>
        {currentState === 'pending' && (
          <IconCountdownTimerView>
            <Time color={theme.colors.grey03} />

            <CountdownTimer expiresAt={expiresAt} />
          </IconCountdownTimerView>
        )}
        {pendingEdit && (
          <IconCountdownTimerView>
            <Icon source={warningIcon} color={theme.colors.alert} size="17px" />

            <Text margins="0px 0px 0px 5px" fontSize="12px" color={theme.colors.grey02}>
              {t('bookingsAngelFamilyHasEdited')}
            </Text>
          </IconCountdownTimerView>
        )}
      </View>
    </TouchableOpacity>
  );
};

InvitationCard.propTypes = {
  booking: PropTypes.shape({ start_date: PropTypes.string, end_date: PropTypes.string }).isRequired,
  bookings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  repeatQty: PropTypes.number.isRequired,
  family: PropTypes.string.isRequired,
  expiresAt: PropTypes.string,
  pendingEdit: PropTypes.bool,
  currentState: PropTypes.string.isRequired,
  onCardPress: PropTypes.func.isRequired
};

InvitationCard.defaultProps = {
  expiresAt: moment().format('YYYY-MM-DD HH:mm:ss'),
  pendingEdit: false
};

export default InvitationCard;
