import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { updateProfile, setOnboardingProgress } from '../../../stores/actions/userAction';
import { Text } from '../../../elements';
import { TextView, LabeledInput, TextButton, Progress, Header } from '../../../components';
import colors from '../../../styles/colors';

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  justify-content: space-between;
`;
const View = styled.View`
  flex: 1;
`;
const ScrollView = styled.ScrollView``;
const ButtonView = styled.View`
  flex-direction: row-reverse;
  padding: 10px 20px 10px 20px;
  width: 100%;
  border-top-width: 1px;
  border-color: ${colors.grey08};
`;

const ShortBio = ({
  location,
  history,
  route,
  navigation,
  angel,
  updatingProfile,
  updateProfileData,
  setProgress
}) => {
  const { progress = 70 } = route.params || location.state || {};
  const [shortBio, setShortBio] = useState(angel.short_bio || '');
  const [inputFocused, setInputFocused] = useState(false);
  const [t] = useTranslation();
  const descriptionInputRef = useRef(null);

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize={18} fontWeight="600">
          {`${progress}% ${t('complete')}`}
        </Text>
      )
    });
  }

  useEffect(() => {
    if (progress < 100) {
      setProgress(progress);
    }
  }, []);

  useEffect(() => {
    if (!updatingProfile && shortBio && angel.short_bio === shortBio) {
      const newProgress = progress + 5 >= 100 ? 100 : progress + 5;
      if (newProgress === 100 && progress < 100) {
        setProgress(100);
        if (navigation) {
          navigation.popToTop();
        } else {
          history.goBack();
        }
      } else if (navigation) {
        navigation.navigate('SignUpAngelStep8', { progress: newProgress });
      } else {
        history.push({
          pathname: '/signup-angel-preferences',
          state: { progress: newProgress }
        });
      }
    }
  }, [angel, updateProfile]);

  return (
    <SafeAreaView>
      {!navigation && <Header title={`${progress}% ${t('complete')}`} />}
      <ScrollView enableAutomaticScroll extraScrollHeight={40}>
        <View>
          <Progress
            progress={progress}
            color={progress === 100 ? colors.primary : colors.secondary}
          />

          <TextView
            flexDirection="column"
            alignItems="flex-start"
            margins="20px"
            text={t('signUpAngelScreensPersonalDescription')}
            fontFamily="Martel"
            fontSize="18px"
            fontWeight="600">
            <Text fontWeight="300">{t('signUpAngelScreensPersonalDescriptionDescription')}</Text>

            <LabeledInput
              inputRef={descriptionInputRef}
              margins="20px 0px 0px 0px"
              width="100%"
              inputHeight="100px"
              borderColor={colors.secondary}
              backgroundColor={colors.white}
              inputBorderColor={inputFocused ? colors.secondary : colors.defaultBtnBackgroundColor}
              labelText={t('description')}
              inputMargins="5px 0px 0px 0px"
              inputPadings="0px 0px 0px 5px"
              multiline
              returnKeyType="default"
              blurOnSubmit
              textAlignVertical="top"
              placeholder={t('signUpAngelScreensPersonalDescriptionPlaceholder')}
              value={shortBio || ''}
              onChangeText={setShortBio}
              onFocus={() => setInputFocused(descriptionInputRef.current.isFocused())}
            />
          </TextView>
        </View>
      </ScrollView>

      <ButtonView>
        <TextButton
          width="50%"
          backgroundColor={colors.primary}
          text={t('next')}
          onPress={() => {
            if (!updatingProfile) {
              updateProfileData({ short_bio: shortBio });
            }
          }}
        />
      </ButtonView>
    </SafeAreaView>
  );
};

ShortBio.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      progress: PropTypes.number
    })
  }),
  history: PropTypes.shape({ push: PropTypes.func, goBack: PropTypes.func }),
  route: PropTypes.shape({
    params: PropTypes.shape({
      progress: PropTypes.number
    })
  }),
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    popToTop: PropTypes.func,
    setOptions: PropTypes.func
  }),
  updateProfileData: PropTypes.func.isRequired,
  setProgress: PropTypes.func.isRequired,
  angel: PropTypes.shape({ short_bio: PropTypes.string }).isRequired,
  updatingProfile: PropTypes.bool
};

ShortBio.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null,
  updatingProfile: false
};

const mapStateToProps = ({ User }) => ({
  angel: User.user.angel,
  updatingProfile: User.updatingProfile
});

const mapDispatchToProps = {
  updateProfileData: updateProfile,
  setProgress: setOnboardingProgress
};

export default connect(mapStateToProps, mapDispatchToProps)(ShortBio);
