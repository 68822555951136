import React, { useEffect, useMemo } from 'react'
import { Button, Box, Avatar, Text, Flex, Heading, HStack, Center, Pressable } from 'native-base'
import { CharlyIcon, Card, CountDownTimer } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import {
  flattenBookingDates,
  IBookingDetails,
  InvitationsStates,
  IOffer,
  OfferStates,
  useRouter
} from '@charlycares/shared'
import { useDeclineOfferMutation } from '@charlycares/data-access'

import { useAlert } from '../../../../hooks'

type Props = {
  booking: IBookingDetails
}

const OffersAndInvitesList = ({ booking }: Props) => {
  const { t } = useTranslation()
  const { navigate } = useRouter()
  const alert = useAlert()

  const { offers, invitations, booking_dates } = booking

  const [decline, declineState] = useDeclineOfferMutation()

  const { action, pending, declined } = useMemo(() => {
    const declineInvites = invitations.filter(invite =>
      [
        InvitationsStates.GIVEN,
        InvitationsStates.EXPIRED,
        InvitationsStates.DECLINED,
        InvitationsStates.CANCELLED,
        InvitationsStates.CANCELLED,
        InvitationsStates.DECLINED_BY_FAMILY,
        InvitationsStates.ENDED
      ].includes(invite.current_state)
    )
    const declineOffers = offers.filter(offer =>
      [OfferStates.DECLINED, OfferStates.CANCELLED, OfferStates.EXPIRED, OfferStates.GIVEN].includes(offer.status)
    )

    return {
      action: offers.filter(offer =>
        [OfferStates.ACCEPTED, OfferStates.PENDING, OfferStates.PENDING_EDIT].includes(offer.status)
      ),
      pending: invitations.filter(invite =>
        [InvitationsStates.PENDING, InvitationsStates.PENDING_EDIT, InvitationsStates.PENDING_APPROVAL].includes(
          invite.current_state
        )
      ),
      declined: [...declineInvites, ...declineOffers]
    }
  }, [offers, invitations])

  const onDecline = (offer: IOffer) => {
    alert.show(t('declineOfferTitle'), t('declineOfferMessage'), [
      { text: t('cancel') },
      {
        text: t('confirm'),
        onPress: () =>
          decline({
            parentBookingObscuredId: offer.parent_booking_obscured_id,
            offerObscuredId: offer.obscured_id
          })
      }
    ])
  }

  useEffect(() => {
    if (declineState.isError) {
      alert.show(t('error'), t('declineOfferError'))
    }
  }, [declineState.isError, t, alert])

  return (
    <Box pb="100px">
      {!!action.length && (
        <Box mt="15px">
          <Text fontSize="14px" color="gray.800" mx="20px">
            {t('offerNeedAction')}
          </Text>

          {action.map(offer => {
            return (
              <Card key={offer.obscured_id}>
                <Pressable
                  onPress={() =>
                    navigate('AngelProfile', `/booking-angel-profile`, { angelId: offer.angel.obscured_id })
                  }
                >
                  <HStack alignItems="center" space="15px">
                    <Avatar borderWidth={1} borderColor="gray.400" size="51px" source={{ uri: offer.angel.image }} />
                    <Heading flex={1} fontSize="16px" fontWeight="600" numberOfLines={1} ellipsizeMode="tail">
                      {`${offer.angel.first_name} (${offer.angel.age})`}
                    </Heading>
                    <Text fontSize="13px" fontWeight="600" color="primary.400">
                      {offer.from_invite ? t('offerFromInvite') : t('offerFromJobboard')}
                    </Text>
                  </HStack>

                  <Flex mt="15px" flexDir="row" justify="space-between" align="center">
                    <HStack space="5px">
                      <Text>{t('available')}</Text>
                      <Center w="20px" h="20px" bg="gray.200">
                        <Text fontWeight={600}>{offer.offers.length}</Text>
                      </Center>
                      <Text>{t('of')}</Text>
                      <Text>{flattenBookingDates(booking_dates).length}</Text>
                    </HStack>

                    {!!offer.expires_at && [OfferStates.PENDING, OfferStates.PENDING_EDIT].includes(offer.status) && (
                      <HStack space="1px" alignItems="center">
                        <CharlyIcon name="icn-time" />
                        <CountDownTimer date={offer.expires_at} />
                      </HStack>
                    )}
                  </Flex>
                </Pressable>

                <HStack mt="15px" space="13px">
                  <Button
                    _important={{
                      borderColor: 'gray.600',
                      _text: {
                        color: 'gray.600'
                      }
                    }}
                    size="xs"
                    variant="outline"
                    flex={1}
                    isLoading={declineState.isLoading}
                    onPress={() => onDecline(offer)}
                  >
                    {t('decline')}
                  </Button>

                  <Button
                    onPress={() =>
                      navigate('ViewOffer', '/booking-offer', {
                        bookingId: booking.obscured_id,
                        offerId: offer.obscured_id
                      })
                    }
                    size="xs"
                    flex={1}
                  >
                    {t('viewOffer')}
                  </Button>
                </HStack>
              </Card>
            )
          })}
        </Box>
      )}

      {!!pending.length && (
        <Box mt="15px">
          <Text fontSize="14px" color="gray.800" mx="20px">
            {t('inviteAwaitingResponse')}
          </Text>

          {pending.map(invite => (
            <Pressable
              key={invite.obscured_id}
              onPress={() => navigate('AngelProfile', `/booking-angel-profile`, { angelId: invite.angel.obscured_id })}
            >
              <Card>
                <HStack alignItems="center" space="15px">
                  <Avatar borderWidth={1} borderColor="gray.400" size="51px" source={{ uri: invite.angel.image }} />
                  <Heading flex={1} fontSize="16px" fontWeight="600" numberOfLines={1} ellipsizeMode="tail">
                    {`${invite.angel.first_name} (${invite.angel.age})`}
                  </Heading>
                  <CountDownTimer date={invite.expires_at} />
                </HStack>

                <HStack mt="15px" space="20px" alignItems="center">
                  <CharlyIcon bg="gray.200" size={22} name="icn-small-unknown" />
                  <Text fontSize="12px">{t(`invitePendingStatusMessage_${invite.current_state}`)}</Text>
                </HStack>
              </Card>
            </Pressable>
          ))}
        </Box>
      )}

      {!!declined.length && (
        <Box mt="15px">
          <Text fontSize="14px" color="gray.800" mx="20px">
            {t('inviteDeclinedOrExpired')}
          </Text>

          {declined.map(item => (
            <Pressable
              key={item.obscured_id}
              onPress={() => navigate('AngelProfile', `/booking-angel-profile`, { angelId: item.angel.obscured_id })}
            >
              <Card>
                <HStack alignItems="center" space="15px">
                  <Avatar borderWidth={1} borderColor="gray.400" size="51px" source={{ uri: item.angel.image }} />
                  <Heading flex={1} fontSize="16px" fontWeight="600" numberOfLines={1} ellipsizeMode="tail">
                    {`${item.angel.first_name} (${item.angel.age})`}
                  </Heading>
                  <CountDownTimer date={item.expires_at} />
                </HStack>

                <HStack mt="15px" space="20px" alignItems="center">
                  <CharlyIcon bg="gray.200" size={22} name="icn-small-declined" />
                  <Text fontSize="12px">
                    {'status' in item
                      ? t(`offerDeclineStatusMessage_${item.status}`)
                      : t(`inviteDeclineStatusMessage_${item.current_state}`)}
                  </Text>
                </HStack>
              </Card>
            </Pressable>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default OffersAndInvitesList
