import { useCallback, useContext } from 'react'
import { NavigationStackProp } from 'react-navigation-stack'
import { useHistory, useParams } from 'react-router-dom'
import { NavigationContext } from 'react-navigation'

import { isWeb } from '../const'

/**
  @description cross platform router functions
**/
export default function useRouter(provided?: NavigationStackProp<any>) {
  const history = useHistory()
  const navigation = useContext(NavigationContext)

  let routeParams: { [key: string]: string } = {}

  try {
    const paramsFromRouter = useParams()

    routeParams = paramsFromRouter
  } catch (e) {
    // do nothing
  }

  const navigate = useCallback(
    (nativeRoute: string, webRoute?: string, params?: any) => {
      isWeb && webRoute ? history.push(webRoute, params) : navigation?.navigate(nativeRoute, params)
    },
    [history, navigation]
  )

  const goBack = useCallback(() => {
    isWeb ? history.goBack() : navigation?.goBack()
  }, [history, navigation])

  const popToTop = useCallback(
    (replaceUrl?: string, params?: any) => {
      isWeb ? history.replace(replaceUrl || '/', params) : provided?.popToTop()
    },
    [history, provided]
  )

  const replace = useCallback(
    (nativeRoute: string, webRoute?: string, params?: any) => {
      isWeb ? history.replace(webRoute || '/', params) : provided?.replace(nativeRoute, params)
    },
    [history, provided]
  )

  const getParam = useCallback(
    (param: string, defaultValue?: any) => {
      if (isWeb) {
        const params = new URLSearchParams(history.location.search)
        // @ts-ignore
        return history.location?.state?.[param] || params.get(param) || defaultValue
      }

      return navigation.getParam(param, defaultValue)
    },
    [history, navigation]
  )

  const didFocus = useCallback(
    (callback: () => void) => {
      const listener = navigation.addListener('didFocus', callback)

      return () => {
        listener.remove()
      }
    },
    [navigation]
  )

  return { navigate, goBack, popToTop, getParam, replace, routeParams, didFocus }
}
