import React, { useEffect } from 'react'
import _ from 'lodash'
import { Box, Text, Button, HStack, Heading, Divider, Badge } from 'native-base'
import {
  Card,
  CharlyIcon,
  LanguageSelector,
  NumberInput,
  PlatformScrollView,
  ScreenLayout,
  SittingExperiencePicker,
  Slider,
  TextInput
} from '@charlycares/ui'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useTranslation } from 'react-i18next'
import {
  ANGEL_RATE_MAX_AMOUNT,
  ANGEL_RATE_MIN_AMOUNT,
  Days,
  formatMoney,
  getDaysOfWeek,
  getDistance,
  getDuration,
  StorageKeys,
  useRouter
} from '@charlycares/shared'
import { useFormik } from 'formik'
import {
  countJobboardFilters,
  setDefaultJobboardFilters,
  updateJobboardFilters,
  useAppSelector,
  useGetUserQuery
} from '@charlycares/data-access'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  postal_code: Yup.string().required()
})

const JobboardFiltersScreen = () => {
  const { t } = useTranslation()
  const { top } = useSafeAreaInsets()
  const { goBack } = useRouter()
  const dispatch = useDispatch()

  const { data: user } = useGetUserQuery()
  const filters = useAppSelector(state => state.jobboard.filters)
  const filtersCount = useAppSelector(state => state.jobboard.filtersCount)

  const { values, setFieldValue, handleChange, handleSubmit, setValues, errors } = useFormik({
    initialValues: filters,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: data => {
      dispatch(updateJobboardFilters(data))
      user && dispatch(countJobboardFilters(user))
      AsyncStorage.setItem(`${StorageKeys.JOBBOARD_FILTERS}_${user?.id}`, JSON.stringify(data))
      goBack()
    }
  })

  const timeRangeToHours = (time: string) => {
    const splitTime = time.split(':')
    const hours = getDuration(
      moment({ h: 0, m: 0 }),
      moment({ h: parseInt(splitTime[0]), m: parseInt(splitTime[1]) })
    ).asHours()

    return !isNaN(hours) ? hours : 0
  }

  const onTimeRangeChange = (hours: number[] | number) => {
    if (!Array.isArray(hours)) return

    const from = moment.duration(hours[0], 'hours')
    const to = moment.duration(hours[1], 'hours')

    // const fromMinutes = from.minutes() % 5 !== 0 ? 5 - (from.minutes() % 5) + from.minutes() : from.minutes()
    // const toMinutes = to.minutes() % 5 !== 0 ? 5 - (to.minutes() % 5) + to.minutes() : to.minutes()

    setFieldValue('start_time_range', {
      from: `${('0' + from.hours()).slice(-2)}:${('0' + from.minutes()).slice(-2)}`,
      to: `${('0' + to.hours()).slice(-2)}:${('0' + to.minutes()).slice(-2)}`
    })
  }

  const onReset = () => {
    user && dispatch(setDefaultJobboardFilters(user))
  }

  useEffect(() => {
    setValues(filters)
  }, [filters, setValues])

  return (
    <ScreenLayout
      disableScroll
      hideHeader
      edges={['bottom', 'left', 'right']}
      _buttonProps={{
        children: t('save'),
        onPress: () => handleSubmit()
      }}
    >
      <Box borderBottomWidth="1px" borderColor="gray.500" bg="gray.50" pt={`${top + 10}px`} pb="10px">
        <Box px="15px" flexDir="row" justifyContent="space-between" alignItems="center">
          <Box zIndex={2}>
            <CharlyIcon onPress={() => goBack()} name="btn-close" size={30} />
          </Box>

          <HStack
            position="absolute"
            left={0}
            right={0}
            top={0}
            bottom={0}
            space="6px"
            justifyContent="center"
            alignItems="center"
          >
            {!!filtersCount && <Badge>{filtersCount}</Badge>}
            <Text fontWeight={600} fontSize="18px">
              {t('filters')}
            </Text>
          </HStack>

          {(!_.isEqual(values, filters) || !!filtersCount) && (
            <Button onPress={onReset} variant="text" _text={{ fontWeight: 600, fontSize: '16px' }}>
              {t('reset')}
            </Button>
          )}
        </Box>
      </Box>

      <PlatformScrollView>
        <Card m="0" borderWidth={0}>
          <Box>
            <Heading fontSize="15px" fontWeight={600}>
              {t('bookingsPerFamily')}
            </Heading>

            <HStack space="18px" w="100%" mt="18px">
              <Button
                onPress={() => {
                  if (values.single && !values.multi) return
                  setFieldValue('single', !values.single)
                }}
                _important={{
                  borderColor: values.single ? 'secondary.500' : 'gray.100',
                  _text: { color: values.single ? 'secondary.500' : 'gray.500' }
                }}
                bg={values.single ? 'transparent' : 'gray.100'}
                variant="outline"
                flex={1}
              >
                {t('lessThen3')}
              </Button>
              <Button
                onPress={() => {
                  if (values.multi && !values.single) return
                  setFieldValue('multi', !values.multi)
                }}
                _important={{
                  borderColor: values.multi ? 'secondary.500' : 'gray.100',
                  _text: { color: values.multi ? 'secondary.500' : 'gray.500' }
                }}
                bg={values.multi ? 'transparent' : 'gray.100'}
                variant="outline"
                flex={1}
              >
                {t('moreThen4')}
              </Button>
            </HStack>
          </Box>

          <Divider my="20px" bg="gray.200" />

          <Box>
            <Heading fontSize="15px" fontWeight={600}>
              {t('babysittingPreference')}
            </Heading>

            <Box flexDir="row" flexWrap="wrap" mt="18px">
              {getDaysOfWeek().map((day, index) => {
                const currentDay = Days[Days[index] as keyof typeof Days]
                const isSelected = values.days_of_week.includes(currentDay)

                return (
                  <Button
                    onPress={() => {
                      setFieldValue(
                        'days_of_week',
                        isSelected
                          ? values.days_of_week.length > 1
                            ? values.days_of_week.filter(val => val !== currentDay)
                            : values.days_of_week
                          : [...values.days_of_week, currentDay]
                      )
                    }}
                    _important={{
                      borderColor: isSelected ? 'secondary.500' : 'gray.100',
                      _text: { color: isSelected ? 'secondary.500' : 'gray.500' }
                    }}
                    bg={isSelected ? 'transparent' : 'gray.100'}
                    key={day}
                    m="2px"
                    mb="10px"
                    variant="outline"
                    w="75px"
                  >
                    {day.substring(0, 3)}
                  </Button>
                )
              })}
            </Box>
          </Box>

          <Divider my="20px" bg="gray.200" />

          <Box w="100%">
            <Box mb="18px" flexDir="row" justifyContent="space-between" alignItems="center">
              <Heading fontSize="15px" fontWeight={600}>
                {t('jobboardStartTime')}
              </Heading>
              <Text fontSize="16px">{`${values.start_time_range.from} - ${values.start_time_range.to}`}</Text>
            </Box>

            <Slider
              value={[timeRangeToHours(values.start_time_range.from), timeRangeToHours(values.start_time_range.to)]}
              min={0}
              max={23.984}
              onValueChange={onTimeRangeChange}
            />
          </Box>

          <Divider my="20px" bg="gray.200" />

          <HStack space="10px" flexDir="row" w="100%">
            <Box flex={1}>
              <Heading fontSize="15px" mb="4px" fontWeight={600}>
                {t('postalCodeNumber')}
              </Heading>

              <TextInput
                hideErrorMessage
                value={values.postal_code}
                onChangeText={handleChange('postal_code')}
                variant="filled"
                w="100%"
                error={errors.postal_code}
                returnKeyType="done"
              />
            </Box>

            <Box flex={1} alignItems="flex-end">
              <Heading fontSize="15px" fontWeight={600}>
                {t('maxDistance')}
              </Heading>

              <NumberInput
                value={values.distance_in_meters}
                onChange={distance => setFieldValue('distance_in_meters', distance)}
                changeRate={1000}
                min={1000}
                max={50000}
                mt="15px"
                formatValue={getDistance}
              />
            </Box>
          </HStack>

          <Divider my="20px" bg="gray.200" />

          <LanguageSelector languages={values.languages} onChange={val => setFieldValue('languages', val)} />

          <Divider my="20px" bg="gray.200" />

          <Box>
            <Heading fontSize="15px" fontWeight={600}>
              {t('whatAgeYouWantToBabysit')}
            </Heading>
            <Text mt="10px" fontSize="14px">
              {t('experienceNote')}
            </Text>

            <SittingExperiencePicker
              _button={{ _text: { color: 'secondary.400' } }}
              mt="10px"
              py="12px"
              value={values.min_age_children_in_months}
              onChange={exp => setFieldValue('min_age_children_in_months', exp)}
            />
          </Box>
        </Card>
      </PlatformScrollView>
    </ScreenLayout>
  )
}

JobboardFiltersScreen.navigationOptions = {
  headerShown: false,
  headerMode: 'modal'
}

export default JobboardFiltersScreen
