import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { Text } from '../../elements';
import colors from '../../styles/colors';

const Container = styled.View`
  padding-top: 10px;
  padding-bottom: 10px;
`;
const LanguageContainer = styled.View``;
const Language = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
`;

const listOfLanguages = (languages) => {
  const availableLanguages = ['dutch', 'english', 'french', 'italian', 'german', 'spanish'];
  const list = availableLanguages.filter((lan) => languages[lan]);

  return list;
};

const Languages = ({ languages }) => {
  const [t] = useTranslation();

  return (
    <Container>
      <Text fontFamily="Martel" fontSize="16px">
        {t('languages')}
      </Text>

      <Text margins="5px 0px 0px 0px" fontSize="12px" color={colors.lightGrey}>
        {t('familyProfileLanguagesSpoken')}
      </Text>

      <LanguageContainer>
        {listOfLanguages(languages).map((lan) => (
          <Language key={lan}>
            <Text paddings="3px 0px 3px 0px" fontSize="16px">
              {t(`${lan}Language`)}
            </Text>
          </Language>
        ))}
      </LanguageContainer>
    </Container>
  );
};

Languages.propTypes = {
  languages: PropTypes.shape({}).isRequired
};

export default Languages;
