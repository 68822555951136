import React from 'react';
import { Dimensions } from 'react-native';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TextTextView, TextIconView } from '../Texts';
import { TextButton } from '../Buttons';
import { getFormatedDate } from '../../utils/functions';
import theme from '../../styles/theme';

const View = styled.View`
  padding: 15px;
  margin: 10px -4px 10px -4px;
  border-left-width: 4px;
  min-width: ${Dimensions.get('screen').width * 0.7};
  border-style: solid;
  border-color: ${({ borderColor }) => borderColor};
  background-color: ${theme.colors.backgroundColor};
`;
const ButtonContainer = styled.View`
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const getIconAndColor = (currentState) => {
  let borderColor = theme.colors.primary;
  let calendarIcon = 'icn-calendar-add';
  if (currentState === 'pending') {
    borderColor = theme.statusPendingColor;
    calendarIcon = 'icn-calendar-accepted';
  } else if (currentState === 'accepted') {
    borderColor = theme.statusAcceptedColor;
    calendarIcon = 'icn-calendar-accepted';
  } else if (currentState === 'canceled') {
    borderColor = theme.statusCanceledColor;
    calendarIcon = 'icn-calendar-canceled';
  } else if (currentState === 'declined') {
    borderColor = theme.statusDeclinedColor;
    calendarIcon = 'icn-calendar-canceled';
  }
  return { borderColor, calendarIcon };
};

const getTitle = (currentState, myMessage, name, t) => {
  let title;
  if (currentState !== 'request') {
    title = t(`bookingState${currentState}`);
  } else if (myMessage) {
    title = t('chatScreensRequestSentTo', { 0: name });
  } else {
    title = `${t('chatScreensRequestFrom')} ${name}`;
  }
  return title;
};

const BookingBubble = ({ name, myMessage, currentMessage, onCancelPress, onGoToRequestPress }) => {
  const { id, booking, proposed_date, invitation_id } = currentMessage;
  const [t] = useTranslation();
  if (!booking && !proposed_date) {
    return null;
  }

  const { current_state = 'request', start_date = proposed_date.date, end_date, repeat_qty } =
    booking || {};
  const bookingId = booking.id;
  let endDate = end_date;
  if (proposed_date && !end_date) {
    endDate = moment(proposed_date.date).add(2, 'hours').format('YYYY-MM-DD HH:mm:ss');
  }
  const { borderColor, calendarIcon } = getIconAndColor(current_state);
  const title = getTitle(current_state, myMessage, name, t);

  return (
    <View borderColor={borderColor}>
      <TextIconView
        flexDirection="row-reverse"
        justifyContent="flex-end"
        backgroundColor="transparent"
        name={calendarIcon}
        iconSize="32px"
        iconColor={theme.textColorBase}
        text={title}
        textMargins="0px 0px 0px 5px"
        fontFamily="Martel"
        fontSize="16px"
        fontWeight="600"
        textAlign="left"
      />

      <TextTextView
        firstText={t('start')}
        firstTextFontSize="16px"
        secondText={getFormatedDate(start_date, 'dd. DD MMM HH:mm')}
        secondTextFontSize="16px"
        secondTextColor={theme.colors.grey02}
      />

      <TextTextView
        firstText={t('end')}
        firstTextFontSize="16px"
        secondText={getFormatedDate(endDate, 'HH:mm')}
        secondTextFontSize="16px"
        secondTextColor={theme.colors.grey02}
      />

      {repeat_qty > 1 && (
        <TextTextView
          firstText={t('repeat')}
          firstTextFontSize="16px"
          secondText={`${repeat_qty} x`}
          secondTextFontSize="16px"
          secondTextColor={theme.colors.grey02}
        />
      )}

      {!current_state.includes('declined') && !current_state.includes('canceled') && (
        <ButtonContainer>
          <TextButton
            paddings="0px 10px 0px 10px"
            height="22px"
            backgroundColor={theme.colors.primary}
            text={booking ? t('goToBooking') : t('chatScreensSentRequest')}
            fontSize="13px"
            fontWeight="600"
            onPress={() =>
              onGoToRequestPress(id, bookingId, booking, { start_date, endDate }, invitation_id)
            }
          />

          {!booking && (
            <TextButton
              paddings="0px 10px 0px 10px"
              height="22px"
              borderColor="transparent"
              text={t('cancel')}
              textColor={theme.colors.secondary}
              fontSize="13px"
              fontWeight="600"
              onPress={() => onCancelPress(id)}
            />
          )}
        </ButtonContainer>
      )}
    </View>
  );
};

BookingBubble.propTypes = {
  name: PropTypes.string.isRequired,
  myMessage: PropTypes.bool.isRequired,
  currentMessage: PropTypes.shape({
    id: PropTypes.number,
    booking: PropTypes.shape({
      id: PropTypes.number,
      current_state: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      repeat_qty: PropTypes.number
    }),
    proposed_date: PropTypes.string,
    invitation_id: PropTypes.number
  }).isRequired,
  onCancelPress: PropTypes.func.isRequired,
  onGoToRequestPress: PropTypes.func.isRequired
};

export default BookingBubble;
