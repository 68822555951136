import React from 'react'
import { Input, Box, IInputProps, FormControl, WarningOutlineIcon, Text, ITextProps, IBoxProps } from 'native-base'

type Props = IInputProps & {
  _wrapper?: IBoxProps
  _label?: ITextProps
  _helper?: ITextProps
  label?: string
  error?: string
  hideErrorMessage?: boolean
}

const TextInput = React.forwardRef(
  (
    { label, error, _wrapper, value, isReadOnly, variant, _label, _helper, hideErrorMessage, ...inputProps }: Props,
    ref: any
  ) => {
    return (
      <Box w="100%" pb="10px" {..._wrapper}>
        <FormControl isInvalid={!!error}>
          {!!label && (
            <FormControl.Label>
              <Text fontSize={'14px'} color={error ? 'alert.danger.400' : 'gray.800'} {..._label}>
                {label}
              </Text>
            </FormControl.Label>
          )}

          <Input
            ref={ref}
            borderBottomColor={error ? 'alert.danger.400' : undefined}
            variant={isReadOnly ? 'unstyled' : variant}
            isReadOnly={isReadOnly}
            _readOnly={{
              color: 'black',
              opacity: 1
            }}
            _focus={{
              borderBottomColor: error ? 'alert.danger.400' : undefined
            }}
            value={value}
            {...inputProps}
          />

          {inputProps.maxLength && !isReadOnly && (
            <Text fontSize="14px" color="gray.800" alignSelf="flex-end">{`${value?.length || 0}/${
              inputProps.maxLength
            }`}</Text>
          )}

          {!hideErrorMessage && (
            <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
              <Text fontSize={'14px'} color={'alert.danger.400'} {..._helper}>
                {error}
              </Text>
            </FormControl.ErrorMessage>
          )}
        </FormControl>
      </Box>
    )
  }
)

export default TextInput
