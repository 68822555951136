import React, { useEffect, useMemo, useState } from 'react'
import { useGetBookingsQuery, useGetUserQuery } from '@charlycares/data-access'
import {
  AngelBookingStatus,
  BookingStates,
  contactPaginatedData,
  IBookingDetails,
  UserRoles
} from '@charlycares/shared'

import { isAngelBookingArchived, isBookingArchived } from './booking.utils'

type Props = {
  status?: BookingStates
  skip?: boolean
  page: number
}

const useBookingsData = ({ page, status, skip }: Props) => {
  const result = useGetBookingsQuery({ status, page }, { skip })
  const { data: user } = useGetUserQuery()
  const [bookings, setBookings] = useState<IBookingDetails[]>([])

  useEffect(() => {
    if (result.isSuccess && !result.isFetching) {
      setBookings(state => contactPaginatedData(state, result.data))
    }
  }, [result])

  const bookingSections = useMemo(() => {
    const sections: {
      pending: IBookingDetails[]
      accepted: IBookingDetails[]
      archived: IBookingDetails[]
    } = {
      pending: [],
      accepted: [],
      archived: []
    }

    bookings?.forEach(booking => {
      if (user?.role === UserRoles.ANGEL) {
        // Angel
        if (booking.angel_status === AngelBookingStatus.ACCEPTED) {
          sections.accepted.push(booking)
        } else if (isAngelBookingArchived(booking)) {
          sections.archived.push(booking)
        } else {
          sections.pending.push(booking)
        }
      } else {
        // Family
        if (booking.current_state === BookingStates.ACCEPTED) {
          sections.accepted.push(booking)
        } else if (isBookingArchived(booking)) {
          sections.archived.push(booking)
        } else {
          sections.pending.push(booking)
        }
      }
    })

    return sections
  }, [bookings, user?.role])

  return { ...result, bookings: bookingSections, meta: result.data?.meta }
}

export default useBookingsData
