import { Money } from '@charlycares/data-access'

export enum MEMBERSHIP_TYPES {
  TRIAL = 'trial',
  CANCELLED = 'cancelled',
  FLEXIBLE_TRIAL = 'flexible_trial',
  FULL_SERVICE_TRIAL = 'full_service_trial',
  FULL_SERVICE_MONTHLY = 'full_service_monthly',
  FULL_SERVICE_YEARLY = 'full_service_yearly',
  FLEXIBLE_TRIAL_3_MONTHS = 'flexible_trial_3_months',
  PREMIUM_MONTHLY = 'premium_monthly',
  BASIC_MONTHLY = 'basic_monthly',
  FLEXIBLE_MONTHLY = 'flexible_monthly',
  PREMIUM_YEARLY = 'premium_yearly',
  BASIC_YEARLY = 'basic_yearly',
  FLEXIBLE_YEARLY = 'flexible_yearly',
  APPLE_BASIC_TRIAL = 'apple_basic_trial',
  APPLE_BASIC_MONTHLY = 'apple_basic_monthly',
  APPLE_BASIC_YEARLY = 'apple_basic_yearly',
  APPLE_FLEXIBLE_MONTHLY = 'apple_flexible_monthly',
  APPLE_FULL_SERVICE_MONTHLY = 'apple_full_service_monthly',
  APPLE_FLEXIBLE_YEARLY = 'apple_flexible_yearly',
  APPLE_FLEXIBLE_TRIAL = 'apple_flexible_trial',
  APPLE_PREMIUM_TRIAL = 'apple_premium_trial',
  APPLE_PREMIUM_MONTHLY = 'apple_premium_monthly',
  APPLE_PREMIUM_YEARLY = 'apple_premium_yearly',
  BUSINESS_FLEXIBLE_MONTHLY = 'business_flexible_monthly',
  BUSINESS_MONTHLY = 'business_monthly',
  PET_SERVICE_TRIAL = 'pet_service_trial',
  PET_SERVICE_MONTHLY = 'pet_service_monthly',
  PET_SERVICE_YEARLY = 'pet_service_yearly',
  APPLE_PET_SERVICE_TRIAL = 'apple_pet_service_trial',
  APPLE_PET_SERVICE_MONTHLY = 'apple_pet_service_monthly',
  APPLE_PET_SERVICE_YEARLY = 'apple_pet_service_yearly'
}

export enum SUBSCRIPTION_STATUS {
  ACTIVE = 'active',
  CANCELLED = 'cancelled'
}

export type MembershipsName =
  | 'canceled'
  | 'flexible'
  | 'basic'
  | 'premium'
  | 'business'
  | 'full_service'
  | 'pet_service'

export type MembershipsTerm = 'monthly' | 'yearly' | 'trial'

export type AvailableMembershipDetails = {
  costs: Money
  apple_costs: Money
  fee_costs: Money
  insurance_costs: Money
  monthly_terminable: boolean
  interval: string
  support: boolean
  helpline: boolean
  insurance: boolean
  type: MEMBERSHIP_TYPES
}

export type AvailableMembership = {
  trial?: AvailableMembershipDetails
  monthly: AvailableMembershipDetails
  yearly: AvailableMembershipDetails
}

export type Membership = {
  type: MEMBERSHIP_TYPES
  starts_at: string
  ends_at: string
}

export interface CancellationReason {
  id: number
  locale: 'en_GB' | 'nl_NL'
  category: string
  reason: string
  created_at: null | string
  updated_at: null | string
  deleted_at: null | string
}

export interface PaymentMethod {
  id: string
  name: string
  image: string
}
