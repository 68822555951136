import {
  useDismissRatingMutation,
  useGetBookingDetailsQuery,
  useGetRatingsCategoriesQuery,
  useGiveReviewMutation
} from '@charlycares/data-access'
import { CharlyIcon, TextInput } from '@charlycares/ui'
import {
  Actionsheet,
  Box,
  Button,
  Center,
  Divider,
  Heading,
  KeyboardAvoidingView,
  ScrollView,
  Text,
  useDisclose
} from 'native-base'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAlert } from '../../../hooks'
import GiveRating from './GiveRating'

type Props = {
  bookingId: string
  show?: boolean
}

function ReviewActionSheet({ bookingId, show }: Props) {
  const { isOpen, onClose, onOpen } = useDisclose(false)
  const { t } = useTranslation()
  const alert = useAlert()

  const { data: ratingCategories, isSuccess } = useGetRatingsCategoriesQuery({})
  const [giveReview, { isLoading: giveReviewLoading }] = useGiveReviewMutation()
  const { data: booking } = useGetBookingDetailsQuery({ obscured_id: bookingId }, { skip: !bookingId })
  const [dismiss] = useDismissRatingMutation()

  const [isFeedbackSet, setIsFeedbackSet] = useState(false)
  const [ratings, setRatings] = useState<{ label: string; key: string; value: number }[]>([])
  const [comment, setComment] = useState('')

  useEffect(() => {
    if (show && isSuccess) {
      onOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, isSuccess])

  useEffect(() => {
    if (ratingCategories) {
      const initialRatings = ratingCategories.map(category => ({
        key: category,
        label: t(`review_${category}`),
        value: 0
      }))
      setRatings(initialRatings)
    }
  }, [ratingCategories, t])

  const isButtonDisabled = ratings.some(rating => rating.value === 0)

  const onSubmit = async () => {
    try {
      if (!isFeedbackSet) {
        setIsFeedbackSet(true)
        return
      }

      const categories: {
        [key: string]: number
      } = {}

      ratings.forEach(rating => {
        categories[rating.key] = rating.value
      })

      await giveReview({ booking_id: bookingId, ratings: categories, comment }).unwrap()
      onClose()
    } catch (error) {
      alert.show(t('errorAlert'), (error as any)?.data?.message || t('somethingWentWrong'))
    }
  }

  return (
    <Actionsheet
      isOpen={isOpen}
      onClose={() => {
        dismiss({ booking_id: bookingId })
        onClose()
      }}
    >
      <Actionsheet.Content p="0">
        <KeyboardAvoidingView>
          <ScrollView w="100%" showsVerticalScrollIndicator={false}>
            <Center alignItems="center" justifyContent="flex-end" flexDir="row" w="100%" px="20px" py="10px">
              <Center position="absolute" textAlign="center" left={0} right={0} top={0} bottom={0}>
                <Heading>{t('feedback')}</Heading>
              </Center>
              <CharlyIcon
                onPress={() => {
                  dismiss({ booking_id: bookingId })
                  onClose()
                }}
                name="btn-close"
                size="30px"
              />
            </Center>
            <Divider />

            <Box w="100%" mt="40px" px="20px">
              <Heading fontSize={22}>{isFeedbackSet ? t('writeReviewTitle') : t('shareYourToughs')}</Heading>
              <Text mt="5px" fontSize={15}>
                {isFeedbackSet ? t('writeReviewDesc') : t('shareYourToughsDesc', { family: booking?.family.name })}
              </Text>

              {isFeedbackSet ? (
                <TextInput
                  mt="20px"
                  placeholder={t('reviewTextPlaceholder')}
                  maxLength={500}
                  h="110px"
                  variant="filled"
                  multiline
                  numberOfLines={5}
                  value={comment}
                  onChangeText={setComment}
                />
              ) : (
                <GiveRating mt="20px" ratings={ratings} onChange={setRatings} />
              )}

              <Button
                isLoading={giveReviewLoading}
                onPress={onSubmit}
                mb="20px"
                isDisabled={isFeedbackSet ? !comment : isButtonDisabled}
                mt="50px"
              >
                {isFeedbackSet ? t('send') : t('giveFeedback')}
              </Button>
            </Box>
          </ScrollView>
        </KeyboardAvoidingView>
      </Actionsheet.Content>
    </Actionsheet>
  )
}

export default ReviewActionSheet
