import React from 'react'
import PropTypes from 'prop-types'
import DatePicker, { registerLocale } from 'react-datepicker'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'

import nl from 'date-fns/locale/nl'
import en from 'date-fns/locale/en-GB'

import { Button } from 'native-base'

registerLocale('nl_NL', nl)
registerLocale('en_GB', en)

const DateTimePicker = React.forwardRef(
  ({ value, onValueChange, labelValue, inputHidden, inputStyle, defaultInput, disabled, _button, ...props }, ref) => {
    const { i18n } = useTranslation()

    return (
      <DatePicker
        style={{
          width: '100px'
        }}
        locale={i18n.language}
        ref={ref}
        showTimeSelect
        selected={value}
        onChange={onValueChange}
        disabled={disabled}
        popperContainer={({ children }) => createPortal(children, document.body)}
        customInput={
          <Button
            _important={{
              cursor: disabled ? 'arrow' : 'cursor',
              opacity: 1
            }}
            _text={{ color: disabled ? 'gray.800' : 'secondary.400', opacity: 1 }}
            isDisabled={disabled}
            variant="text"
            {..._button}
          >
            {labelValue}
          </Button>
        }
        {...props}
      />
    )
  }
)

DateTimePicker.propTypes = {
  inputHidden: PropTypes.bool,
  value: PropTypes.shape({}),
  labelValue: PropTypes.string.isRequired,
  inputStyle: PropTypes.shape({}),
  onValueChange: PropTypes.func,
  defaultInput: PropTypes.bool,
  disabled: PropTypes.bool
}

DateTimePicker.defaultProps = {
  inputStyle: undefined,
  inputHidden: false,
  value: {},
  defaultInput: false,
  disabled: false
}

export default DateTimePicker
