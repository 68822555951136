import React, { useState } from 'react'
import { Box, HStack, Text, IBoxProps, Spacer, Pressable, Skeleton, Flex, Button } from 'native-base'
import { CharlyIcon } from '../icons'
import { useTranslation } from 'react-i18next'

interface Props extends IBoxProps {
  amount: string | undefined
  onComplete: () => void
  isLoading: boolean
  enabled: boolean
  disabled?: boolean
}

const Cash = ({ amount, onComplete, enabled, isLoading, disabled, ...boxProps }: Props) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  return (
    <Pressable
      onPress={() => setOpen(!open)}
      height="170px"
      isDisabled={disabled}
      _disabled={{
        opacity: 0.9
      }}
    >
      {enabled && (
        <Box rounded="md" bgColor="white" width="100%" px="15px" py="10px" {...boxProps} shadow="3">
          <HStack>
            <CharlyIcon name="wallet" size="32px" color="secondary.400" />
            <Text lineHeight="30px" ml="8px" fontSize="16px" color="text.dark.400" fontWeight="600">
              {t('cash.title')}
            </Text>
            <Spacer />
            <CharlyIcon name={open ? 'icn-caret-up' : 'icn-caret-down'} mt="5px" size="24px" color="gray.600" />
          </HStack>
          {open && (
            <>
              <Flex
                height="45px"
                pt="5px"
                mt="5px"
                bgColor="alert.warning.alpha.50"
                justifyContent="center"
                rounded="xl"
              >
                <Text fontSize="16px" textAlign="center">
                  {t('cash.iPay')}{' '}
                  <Text fontSize="22px" fontWeight="600">
                    {amount ?? <Skeleton rounded="full" w="80px" h="33px" startColor="alert.warning.400" />}
                  </Text>{' '}
                  {t('cash.toAngel')}
                </Text>
              </Flex>
              <Button
                mt="10px"
                height={45}
                endIcon={<CharlyIcon name="icn-caret-right" size="25px" />}
                onPress={() => onComplete()}
                isLoading={isLoading}
                isDisabled={disabled}
              >
                {t('cash.pay')}
              </Button>
            </>
          )}
        </Box>
      )}
      {!enabled && (
        <Box position="relative" mt="15px">
          <Box rounded="md" bgColor="white" width="100%" pl="15px" pr="50px" py="10px" shadow="3">
            <HStack>
              <CharlyIcon name="wallet" size="32px" color="secondary.400" />
              <Text fontStyle="italic" ml="8px" color="text.dark.400">
                {t('cash.notAvailable')}
              </Text>
              <Spacer />
            </HStack>
          </Box>
          <Box rounded="md" bgColor="gray.alpha900.50" height="63px" width="100%" top="0" position="absolute"></Box>
        </Box>
      )}
    </Pressable>
  )
}

export default Cash
