import React, { useState, lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import { ActivityIndicator } from 'react-native'
import { browserName } from 'react-device-detect'
import styled from 'styled-components/native'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { TextTextView, InputView, TextButton, IconButton, Header } from '../../components'
import colors from '../../styles/colors'
import ImageUploader from 'react-images-upload'

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  align-items: center;
  background-color: ${colors.backgroundColor};
`
const View = styled.View`
  position: absolute;
  bottom: 30;
  width: 100%;
`
const Image = styled.Image`
  width: ${browserName === 'Safari' ? '300px' : '100%'};
  height: ${browserName === 'Safari' ? '300px' : 'auto'};
  aspect-ratio: 16/9;
`

const SendImage = ({ location, history, route, navigation }) => {
  const { role, name, image, message, conversation, isActive, isTyping } =
    location.state || route.params || {}
  const [newMessage, setNewMessage] = useState(message || '')
  const [newImage, setNewImage] = useState(image)
  const [t] = useTranslation()

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <TextTextView
          flexDirection="column"
          firstText={t('chatScreensSendImage')}
          firstTextFontFamily="Martel"
          firstTextFontSize="18px"
          firstTextFontWeight="600"
          secondText={`${
            role === 'angel' ? t('chatScreensSendToAngel') : t('chatScreensSendToFamily')
          } ${name}`}
        />
      ),
      headerLeft: (
        <IconButton
          iconName="close"
          iconSize="32px"
          iconColor={colors.secondary}
          onPress={() => navigation.goBack()}
        />
      )
    })
  }

  const goBack = () => {
    if (newImage) {
      const getBase64 = (file, cb) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
          cb(reader.result)
        }
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }
      }
      getBase64(newImage.file, result => {
        history.push({
          pathname: '/chat',
          state: {
            showRoom: true,
            conversation,
            isActive,
            isTyping,
            image: result,
            text: newMessage
          }
        })
      })
    } else {
      history.push({
        pathname: '/chat',
        state: {
          showRoom: true,
          conversation,
          isActive,
          isTyping
        }
      })
    }
  }

  const onImage = files => {
    setNewImage({ file: files[0], uri: URL.createObjectURL(files[0]) })
  }

  return (
    <SafeAreaView emulateUnlessSupported>
      {!navigation && (
        <Header
          left={
            <IconButton
              iconName="close"
              iconSize="32px"
              iconColor={colors.secondary}
              onPress={history.goBack}
            />
          }
          title={t('chatScreensSendImage')}
          subtitle={`${
            role === 'angel' ? t('chatScreensSendToAngel') : t('chatScreensSendToFamily')
          } ${name}`}
        />
      )}

      <Image aspectRatio={16 / 9} source={{ uri: newImage && newImage.uri }} />

      <Suspense fallback={<ActivityIndicator />}>
        <ImageUploader
          singleImage
          withIcon={false}
          withPreview={false}
          fileContainerStyle={{
            margin: '0px',
            padding: '0px',
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }}
          buttonText={t('chatScreensChooseImage')}
          onChange={onImage}
          imgExtension={['.jpg', '.gif', '.png', '.svg']}
          maxFileSize={5242880}
        />
      </Suspense>

      <View>
        <InputView
          position="relative"
          flexDirection="row-reverse"
          paddings="0px 15px 0px 15px"
          width="100%"
          borderTopWidth="1px"
          borderBottomWidth="1px"
          backgroundColor={colors.white}
          inputFlex={1}
          placeholder={t('message')}
          showUnderline={false}
          value={newMessage}
          onChangeText={setNewMessage}
          onSubmitEditing={() => {
            if (newImage) {
              goBack()
            }
          }}
        >
          <TextButton
            backgroundColor={colors.primary}
            height="32px"
            borderRadius="6px"
            text={t('send')}
            onPress={goBack}
          />
        </InputView>
      </View>
    </SafeAreaView>
  )
}

SendImage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      role: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.node,
      message: PropTypes.string
    })
  }),
  navigation: PropTypes.shape({ setOptions: PropTypes.func, goBack: PropTypes.func }),
  history: PropTypes.shape({ push: PropTypes.func, goBack: PropTypes.func }),
  route: PropTypes.shape({
    params: PropTypes.shape({
      role: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.node,
      message: PropTypes.string
    })
  })
}

SendImage.defaultProps = {
  location: {},
  navigation: null,
  history: null,
  route: {}
}

export default SendImage
