import React, { useEffect, useMemo } from 'react'
import { Card, List, ScreenLayout } from '@charlycares/ui'
import { HStack, Heading, Text, Image } from 'native-base'
import { useTranslation } from 'react-i18next'
import { SUBSCRIPTION_STATUS, useRouter } from '@charlycares/shared'
import { useGetSubscriptionQuery, useGetUserQuery } from '@charlycares/data-access'

import { LanguageSwitch } from '../../common'
import { useGetAppVersion, useHandleLogout } from '../../../hooks'

export default function FamilyProfileScreen() {
  const { t } = useTranslation()
  const { navigate } = useRouter()

  const { onLogout } = useHandleLogout()
  const { data: user, refetch: refetchUser, isLoading: isUserLoading } = useGetUserQuery()
  const { data: subscription } = useGetSubscriptionQuery()

  const appVersion = useGetAppVersion()

  useEffect(() => {
    if (!isUserLoading) {
      refetchUser()
    }
  }, [])

  const menuItems = useMemo(() => {
    let currentMembership = null

    if (subscription) {
      currentMembership =
        subscription.status === SUBSCRIPTION_STATUS.ACTIVE ? subscription.membership_type : 'cancelled'
    }

    return [
      {
        label: t('profileScreensProfileAccount'),
        onPress: () => navigate('ProfileAccount', '/profile-account')
        // suffix: renderIncomplete(getMissingFieldsIndicator('profile', user))
      },
      {
        label: t('profileScreensFamilySettings'),
        onPress: () => navigate('ProfileFamilySettings', '/profile-settings')
        // suffix: renderIncomplete(getMissingFieldsIndicator('settings', user))
      },
      {
        label: t('profileScreensRatingAndReviews'),
        onPress: () => navigate('FamilyReviews', '/profile-reviews')
      },
      {
        label: t('profileScreensPromotions'),
        onPress: () => navigate('Promotions', '/profile-promotions')
      },
      {
        label: t('profileScreensInviteYourOwnAngel'),
        onPress: () => navigate('InviteOwnAngelScreen', '/profile-invite-your-own-angel')
      },
      { label: t('paymentsScreenPayments'), onPress: () => navigate('PaymentListScreen', '/profile-payments') },
      {
        label:
          user?.family?.membership || user?.family?.has_canceled_membership
            ? t('profileScreensMembership')
            : t('profileScreensStartTrialMonth'),
        suffix: currentMembership ? (
          <Text fontSize={17} textTransform="capitalize" color="gray.700">
            {t('membership.' + currentMembership)}
          </Text>
        ) : null,
        onPress: () =>
          user?.family?.membership || user?.family?.has_canceled_membership
            ? navigate('MembershipInfoScreen', '/profile-membership')
            : navigate('ActivateTrialMembershipInfoScreen', '/activate-trial-membership-info')
      },
      {
        label: user?.family?.has_company_wallet
          ? t('profileBusinessAccountActive')
          : t('profileScreensBusinessAccount'),
        onPress: () =>
          user?.family?.has_company_wallet
            ? navigate('BusinessAccountConnected', '/profile-business-account')
            : navigate('ProfileBusinessAccount', '/profile-business-account')
      },
      {
        label: t('profileScreensTermsAndConditions'),
        onPress: () => navigate('GeneralConditions', '/profile-general-conditions')
      },
      {
        label: t('Logout'),
        onPress: () => onLogout()
      }
    ]
  }, [t, navigate, user?.family, subscription, onLogout])

  if (!user?.family) {
    return <ScreenLayout isLoading title={t('profile')} />
  }

  return (
    <ScreenLayout hideBackButton title={t('profile')} supportButton edges={['left', 'right']}>
      <Card>
        <HStack w="100%" mb="10px" alignItems="center" space={'10px'}>
          <Image
            borderRadius="5px"
            borderWidth="1px"
            borderColor="gray.200"
            source={{ uri: user?.profile?.image }}
            w="120px"
            h="80px"
          />

          <Heading
            flex={1}
            mt="5px"
            numberOfLines={2}
            fontSize="lg"
            fontWeight="600"
          >{`${user.profile.first_name} ${user.profile.last_name}`}</Heading>

          <LanguageSwitch position="absolute" right={0} top={-10} />
        </HStack>

        <List items={menuItems} />

        {appVersion && (
          <Text w="100%" textAlign="center" mt="20px" fontSize="10px">
            App version: {appVersion}
          </Text>
        )}
      </Card>
    </ScreenLayout>
  )
}

FamilyProfileScreen.navigationOptions = () => ({
  headerShown: false
})
