import { Money, TransactionType } from '@charlycares/shared'

export enum WalletTransactionStatus {
  CANCELLED = 'cancelled',
  CHARGEBACK = 'chargeback',
  FAILED = 'failed',
  PAID = 'paid',
  UNPAID = 'unpaid',
  EXPIRED = 'expired',
  PENDING = 'pending',
  FULL_CORRECTION = 'full_correction',
  PARTIAL_CORRECTION = 'partial_correction',
  REFUNDED = 'refunded'
}

export interface ProfileImage {
  image: string
}

export enum MixedTransactionType {
  TOP_UP = 'top_up',
  BOOKING = 'booking',
  BUSINESS_BOOKING = 'business_booking',
  LEGACY_PAYMENT = 'legacy_payment',
  MEMBERSHIP = 'membership',
  BALANCE_CHANGE = 'balance_change',
  CHANGE_BANK_ACCOUNT = 'change_bank_account',
  CANCELLED_BOOKING = 'cancelled_booking'
}

export interface MixedTransaction {
  subject_id: string
  id: string
  reference?: string
  description: string
  wallet: string
  status: string
  created_at: number
  amount: string
  execute_at?: number
  image?: string
  type: MixedTransactionType
}

export interface WalletTransaction {
  uuid: string
  description: string
  redirect_url?: string
  amount?: Money
  interval?: string
  type: TransactionType
  outstanding_balance?: Money
  outstanding_interval: string
  status: WalletTransactionStatus
  created_at: string
  image: string
  reason: string
  payment_method: string
}
