import {
  USER_FETCHING,
  SET_USER,
  SIGNUP_ERROR,
  LOGIN_ERROR,
  LOGOUT,
  PASSWORD_RESET_COMPLETED,
  PASSWORD_RESET_REQUESTED,
  REGISTRATION_VALIDATED,
  SET_VALIDATION_ERRORS,
  UPDATING_PROFILE,
  UPDATE_PROFILE_ERROR,
  UPLOADING_PROFILE_IMAGE,
  UPDATE_PROFILE_IMAGE,
  SET_MEMBERSHIP,
  APPLY_COUPON,
  UPDATING_MEMBERSHIP,
  UPDATE_MEMBERSHIP,
  CANCEL_MEMBERSHIP,
  GET_MEMBERSHIP_REASONS,
  SET_APPOINTMENT,
  SET_ONBOARDING_PROGRESS,
  UPDATING_REFERENCE,
  SAVE_REFERENCE,
  VIDEO_UPLOADED,
  UPDATE_PROFILE_VIDEO,
  SET_LOCALE,
  SET_INDICATORS,
  CLEAR_VALIDATION_ERRORS,
  SET_VALIDATION_ERROR,
  DELETE_APPLY_COUPON_RESPONSE,
  GET_ANGEL_DETAILS,
  GET_ANGEL_RATINGS,
  TOGGLE_LIKE_ANGEL,
  GET_AVAILABILITY,
  SET_ANNOUNCEMENTS_READ,
  GET_ANGEL_PUBLIC,
  ADD_ANGEL_REFERENCE_SUCCESS,
  SET_HAVE_A_LOOK_AROUND
} from '../types'

const initialState = {
  fetching: false,
  user: null,
  loginError: null,
  signupError: null,
  registrationValidated: false,
  validationErrors: null,
  updatingProfile: false,
  profileUpdateError: null,
  uploadingProfileImage: false,
  videoUploaded: false,
  applyCodeResponse: {},
  updatingMembership: false,
  updatingReference: false
}

export default function (state = initialState, { type, payload }) {
  let references = null
  let validationErrorsCopy = null
  switch (type) {
    case USER_FETCHING:
      return {
        ...state,
        fetching: true
      }
    case SET_USER:
      return {
        fetching: false,
        loginError: null,
        updatingProfile: false,
        profileUpdated: true,
        user: state.user ? { ...state?.user, ...payload } : payload
      }
    case SIGNUP_ERROR:
      return {
        ...initialState,
        fetching: !payload,
        signupError: payload
      }
    case LOGIN_ERROR:
      return {
        ...initialState,
        fetching: !payload,
        loginError: payload
      }
    case LOGOUT:
      return initialState
    case PASSWORD_RESET_REQUESTED:
      return {
        passwordReset: payload
      }
    case PASSWORD_RESET_COMPLETED:
      return {
        ...state,
        passwordReset: payload
      }
    case REGISTRATION_VALIDATED:
      return {
        ...state,
        fetching: false,
        registrationValidated: true
      }
    case SET_VALIDATION_ERRORS:
      return {
        ...state,
        registrationValidated: false,
        validationErrors: payload
      }
    case UPDATING_PROFILE:
      return {
        ...state,
        profileUpdateError: null,
        updatingProfile: (payload && payload.updatingProfile) || true,
        profileUpdated: payload && payload.profileUpdated
      }
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        profileUpdateError: payload,
        uploadingProfileImage: false,
        videoUploaded: false,
        updatingProfile: false,
        profileUpdated: false
      }
    case UPLOADING_PROFILE_IMAGE:
      return {
        ...state,
        uploadingProfileImage: true
      }
    case UPDATE_PROFILE_IMAGE:
      return {
        ...state,
        user: { ...state.user, image: payload },
        uploadingProfileImage: false
      }
    case SET_MEMBERSHIP:
      return {
        ...state,
        user: { ...state.user, membershp: payload },
        updatingProfile: false
      }
    case APPLY_COUPON:
      return {
        ...state,
        user: { ...state.user, credit: payload.credit },
        applyCodeResponse: payload
      }
    case DELETE_APPLY_COUPON_RESPONSE:
      return {
        ...state,
        applyCodeResponse: null
      }
    case UPDATING_MEMBERSHIP:
      return {
        ...state,
        updatingMembership: payload
      }
    case UPDATE_MEMBERSHIP:
      return {
        ...state,
        user: {
          ...state.user,
          has_membership: true,
          membership: payload
        },
        updatingMembership: false
      }
    case CANCEL_MEMBERSHIP:
      return {
        ...state,
        user: {
          ...state.user,
          has_membership: false,
          membership: payload
        },
        updatingMembership: false
      }
    case GET_MEMBERSHIP_REASONS:
      return {
        ...state,
        user: {
          ...state.user,
          cancelationReasons: payload
        }
      }
    case SET_APPOINTMENT:
      return {
        ...state,
        user: { ...state.user, angel: { ...state.user.angel, angel_appointment: payload } }
      }
    case SET_ONBOARDING_PROGRESS:
      return {
        ...state,
        user: { ...state.user, angel: { ...state.user.angel, onboardingProgress: payload } }
      }
    case UPDATING_REFERENCE:
      return {
        ...state,
        updatingReference: true
      }
    case SAVE_REFERENCE:
      references = state.user.angel.angel_references || []
      if (references.length === 2) {
        references = references.map(reference =>
          reference.id === payload.id ? payload : reference
        )
      } else {
        references = [...references, payload]
      }
      return {
        ...state,
        updatingReference: false,
        user: { ...state.user, angel: { ...state.user.angel, angel_references: references } }
      }
    case VIDEO_UPLOADED:
      return {
        ...state,
        videoUploaded: true,
        serverError: false
      }
    case UPDATE_PROFILE_VIDEO:
      return {
        ...state,
        user: { ...state.user, video: payload },
        videoUploaded: true,
        serverError: false
      }
    case SET_LOCALE:
      return {
        ...state,
        user: { ...state.user, locale: payload }
      }
    // TODO maybe add in different store
    case SET_INDICATORS:
      return {
        ...state,
        indicators: payload
      }
    case CLEAR_VALIDATION_ERRORS:
      if (payload && Object.keys(state.validationErrors).length > 1) {
        validationErrorsCopy = { ...state.validationErrors }
        delete validationErrorsCopy[payload]
      } else {
        validationErrorsCopy = null
      }
      return {
        ...state,
        validationErrors: validationErrorsCopy
      }
    case SET_VALIDATION_ERROR:
      return {
        ...state,
        validationErrors: { ...state.validationErrors, ...payload }
      }
    case GET_ANGEL_DETAILS:
      return {
        ...state,
        angelDetails: payload
      }
    case GET_ANGEL_RATINGS:
      return {
        ...state,
        angelDetails: { ...state.angelDetails, ratings: payload }
      }
    case TOGGLE_LIKE_ANGEL:
      return {
        ...state,
        angelDetails: { ...state.angelDetails, is_liked: payload }
      }
    case GET_AVAILABILITY:
      return {
        ...state,
        availability: payload
      }
    case SET_ANNOUNCEMENTS_READ:
      return {
        ...state,
        user: { ...state.user, has_unread_announcements: false, announcement: null }
      }
    case GET_ANGEL_PUBLIC:
      return {
        ...state,
        angelData: payload
      }
    case ADD_ANGEL_REFERENCE_SUCCESS:
      return {
        ...state,
        referenceAdded: true
      }
    case SET_HAVE_A_LOOK_AROUND:
      return {
        ...state,
        haveALookAround: payload
      }
    default:
      return state
  }
}
