import React from 'react'
import styled, { useTheme } from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator } from 'react-native'
import { Edge } from 'react-native-safe-area-context'
import { Text, Center, IBoxProps, Button, IButtonProps, Box } from 'native-base'

import { Container } from '../../elements'
import Header from './Header'
import { PlatformScrollView } from '../platform'

type LayoutProps = {
  children?: React.ReactNode
  title?: string
  subtitle?: string
  onCancel?: () => void
  onConfirm?: () => void
  disabled?: boolean
  buttonConfirmLabel?: string
  buttonCancelLabel?: string
  loading?: boolean
  scrollDisabled?: boolean
  headerRight?: () => React.ReactNode
  headerLeft?: () => React.ReactNode
  hideBack?: boolean
  buttonLoading?: boolean
  hideHeader?: boolean
  progressBar?: string
  buttonConfirmProps?: IButtonProps
  buttonCancelProps?: IButtonProps
  scrollRef?: React.LegacyRef<PlatformScrollView>
  edges?: Edge[]
  buttonConfirmDisclaimer?: string
  _bottomContainerProps?: IBoxProps
}

/**
 * @deprecated Use ScreenLayout instead, is improved and done with native base.
 */
export default function Layout({
  children,
  onCancel,
  onConfirm,
  disabled,
  buttonConfirmLabel,
  buttonCancelLabel,
  title,
  subtitle,
  loading,
  scrollDisabled,
  headerRight,
  headerLeft,
  hideBack,
  buttonLoading,
  hideHeader,
  buttonConfirmProps,
  buttonCancelProps,
  buttonConfirmDisclaimer,
  scrollRef,
  _bottomContainerProps,
  edges
}: LayoutProps) {
  const { colors } = useTheme()
  const { t } = useTranslation()

  const safeViewEdges = edges || (['right', 'bottom', 'left'] as Edge[])

  if (hideHeader) {
    safeViewEdges.push('top')
  }

  return (
    <Box maxW="100%" flex={1}>
      {!hideHeader && (
        <Header
          title={title}
          subtitle={subtitle}
          headerRight={headerRight}
          headerLeft={headerLeft}
          hideBack={hideBack}
        />
      )}

      <Container edges={safeViewEdges}>
        {scrollDisabled ? <>{children}</> : <PlatformScrollView ref={scrollRef}>{children}</PlatformScrollView>}

        {(onCancel || onConfirm) && ( // @ts-ignore
          <Box flexDir="row" p="5px" width="100%" borderTopWidth={1} borderColor="gray.400" {..._bottomContainerProps}>
            {onCancel && (
              <Center flex={1} p="5px">
                <Button onPress={onCancel} variant="text" color={colors.secondary} {...buttonCancelProps}>
                  {buttonCancelLabel || t('cancel')}
                </Button>
              </Center>
            )}
            <Center flex={1} p="5px">
              {buttonConfirmDisclaimer && (
                <Text marginBottom="10px" fontSize="12px" color="gray.800">
                  {buttonConfirmDisclaimer}
                </Text>
              )}
              <Button
                w="100%"
                isDisabled={disabled}
                onPress={onConfirm}
                isLoading={buttonLoading}
                {...buttonConfirmProps}
              >
                {buttonConfirmLabel || t('confirm')}
              </Button>
            </Center>
          </Box>
        )}
      </Container>

      {loading && (
        <Overlay>
          <ActivityIndicator size="large" color={colors.white} />
        </Overlay>
      )}
    </Box>
  )
}

const Overlay = styled.View`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: ${p => p.theme.colors.transparentModal};
  z-index: 100;
`
