import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { TextButton } from '../Buttons';
import colors from '../../styles/colors';

const Container = styled.View`
  flex-direction: row;
  padding: 0px 20px 0px 20px;
  position: absolute;
  right: 5px;
  top: ${({ top }) => top};
`;
const Divider = styled.View`
  margin: auto;
  height: 40%;
  border-width: 1px;
  border-color: ${colors.grey07};
`;

const LanguageSwitch = ({ top, selectedLanguage, onPress }) => (
  <Container top={top}>
    <TextButton
      paddings="0px 10px 0px 10px"
      text="NL"
      textColor={selectedLanguage === 'nl_NL' ? colors.secondary : colors.grey07}
      onPress={() => onPress('nl_NL')}
    />

    <Divider />

    <TextButton
      paddings="0px 10px 0px 10px"
      text="EN"
      textColor={selectedLanguage === 'en_GB' ? colors.secondary : colors.grey07}
      onPress={() => onPress('en_GB')}
    />
  </Container>
);

LanguageSwitch.propTypes = {
  top: PropTypes.string,
  selectedLanguage: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired
};

LanguageSwitch.defaultProps = {
  top: '10px'
};

export default LanguageSwitch;
