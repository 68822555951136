import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ContainerView, Heading, Text } from '../../elements';
import { TextView } from '../Texts';
import { TextButton } from '../Buttons';
import colors from '../../styles/colors';

const Stories = ({ isAngel, onReadMorePress }) => {
  const [t] = useTranslation();

  return (
    <ContainerView
      flexDirection="column"
      alignItems="flex-start"
      paddings="32px 0"
      margins="0 20px"
      borderBottomWidth="1px">
      <Heading
        xxl
        ariaLevel={3}
        text={isAngel ? t('dashboardScreensAngelStoriesTitle') : t('dashboardScreensStoriesTitle')}
      />
      <Text margins="0 0 8px 0" lg lighterColor>
        {t('dashboardScreensStoriesSubtitle')}
      </Text>

      <TextView
        flexDirection="column"
        alignItems="flex-start"
        paddings="8px 0px 8px 16px"
        width="100%"
        borderLeftWidth="2px"
        borderColor={colors.primary}
        backgroundColor={colors.darkBackgroundColor}
        text={
          isAngel ? t('dashboardScreensAngelStoryTitle') : t('dashboardScreensFamilyStoryTitle')
        }
        fontSize="16px">
        <Text margins="0 0 8px 0" numberOfLines={2}>
          {isAngel ? t('dashboardScreensAngelStoryBody') : t('dashboardScreensFamilyStoryBody')}
        </Text>

        <TextButton
          margins="0 0 0 auto"
          text={t('readMore')}
          textColor={colors.notify}
          onPress={onReadMorePress}
        />
      </TextView>
    </ContainerView>
  );
};

Stories.propTypes = {
  onReadMorePress: PropTypes.func.isRequired,
  isAngel: PropTypes.bool
};

Stories.defaultProps = {
  isAngel: false
};
export default Stories;
