import React from 'react'
import { Button, Card, Center, Heading, HStack, Text } from 'native-base'
import { CharlyIcon, LandingLayout } from '@charlycares/ui'
import { useReferralPromotionQuery } from '@charlycares/data-access'
import { formatMoney, isWeb, useRouter, UserRoles } from '@charlycares/shared'
import { useTranslation } from 'react-i18next'

interface ScreenProps {
  setLoading?: (loading: boolean) => void
}
export default function InvitedScreen({ setLoading }: ScreenProps) {
  const { t } = useTranslation()
  const { navigate, getParam } = useRouter()
  const role: UserRoles = getParam('role', UserRoles.FAMILY)
  const code = getParam('code')
  const { data } = useReferralPromotionQuery({ code: code })
  // @ts-ignore
  const amount = data?.['refers_' + role] ?? { amount: 500, currency: 'EUR' }

  if (setLoading) {
    setLoading(true)
  }

  return (
    <LandingLayout>
      <Center>
        <CharlyIcon name="logo" size="78px" color="primary.400" mt={isWeb ? '100px' : ''} />
        <Heading color="white" fontSize="40px" pt="10px">
          {t('inviteScreen.heading')}
        </Heading>
        <Text color="white" fontSize="22px" pt="10px" fontWeight={600}>
          {t('inviteScreen.subHeading')}
        </Text>
      </Center>
      <Card alignSelf="center" bgColor="white" width="100%" maxWidth="400px" mt={isWeb ? '100px' : ''}>
        <Center>
          <HStack>
            <CharlyIcon name="icn-gift" size={36} color="primary.500" />
            <Heading color="secondary.400" fontSize="24px" lineHeight="40px" ml="10px">
              {t('inviteScreen.earn', { amount: formatMoney(amount) })}
            </Heading>
          </HStack>
        </Center>
        <Text pt="16px" fontSize="15px">
          <Text fontWeight={600}>{data?.first_name}</Text>{' '}
          {t(`inviteScreen.${role}.message`, { amount: formatMoney(amount), code: data?.code })}
        </Text>
        <Button
          mt="16px"
          onPress={() => {
            setLoading?.(false)
            navigate('BoardingScreenFinal', `/?referralCode=${code}`, { referralCode: code })
          }}
        >
          {t('inviteScreen.button')}
        </Button>
      </Card>
    </LandingLayout>
  )
}

InvitedScreen.navigationOptions = () => ({
  headerShown: false
})
