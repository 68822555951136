import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import TextButtonText from './TextButtonText';
import { Switch } from '../../elements';

const View = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: ${({ margins }) => margins};
`;

const TextButtonTextSwitch = ({
  margins,
  firstText,
  secondText,
  textButtonOnPress,
  switchValue,
  switchOnValueChange
}) => (
  <View margins={margins}>
    <TextButtonText firstText={firstText} secondText={secondText} onPress={textButtonOnPress} />
    <Switch value={switchValue} onValueChange={switchOnValueChange} />
  </View>
);

TextButtonTextSwitch.propTypes = {
  margins: PropTypes.string,
  firstText: PropTypes.string.isRequired,
  secondText: PropTypes.string.isRequired,
  textButtonOnPress: PropTypes.func.isRequired,
  switchValue: PropTypes.bool.isRequired,
  switchOnValueChange: PropTypes.func.isRequired
};

TextButtonTextSwitch.defaultProps = {
  margins: '0px'
};

export default TextButtonTextSwitch;
