import React, { useState, useEffect, useRef, createRef } from 'react';
import { Keyboard } from 'react-native';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { saveReference, setOnboardingProgress } from '../../../stores/actions/userAction';
import { Text, Switch } from '../../../elements';
import {
  TextView,
  TextTextView,
  TextIconView,
  TextButton,
  LabeledInput,
  Progress,
  PhoneNumberEdit,
  Picker,
  Header,
  PickerWeb
} from '../../../components';
import { isWeb, getAllCountryCodes, getCountryCode } from '../../../utils/functions';
import colors from '../../../styles/colors';

import arrowRightIcon from '../../../../assets/icons/icn-nav-right.png';

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  justify-content: space-between;
  overflow: hidden;
`;
const ScrollView = styled.ScrollView`
  flex: 1;
`;
const KeyboardAvoidingView = styled.KeyboardAvoidingView`
  flex: 1;
`;
const View = styled.View``;
const ButtonView = styled.View`
  flex-direction: row-reverse;
  padding: 10px 20px 10px 20px;
  width: 100%;
  border-top-width: 1px;
  border-color: ${colors.grey08};
`;

const getInitialReferences = (angelReferences, t) => {
  let references =
    angelReferences.map((reference) => ({
      ...reference,
      countryCode: getCountryCode(`+${reference.phone.slice(0, 2)}`),
      phone: reference.phone.slice(2, reference.phone.length),
      editable: false
    })) || [];
  const initialReference = {
    first_name: '',
    countryCode: '',
    phone: '',
    notes: t('signUpAngelScreensConnections0'),
    editable: true
  };
  while (references.length < 2) {
    references = [...references, initialReference];
  }
  return references;
};

const References = ({
  location,
  history,
  route,
  navigation,
  updatingReference,
  angel,
  setProgress,
  saveReferenceData
}) => {
  const { progress } = route.params || location.state || {};
  const [t] = useTranslation();
  const [requestReference, setRequestReference] = useState(false);
  const angelReferences = getInitialReferences(angel.angel_references || [], t);
  const [references, setReferences] = useState(angelReferences);
  const [inputFocused, setInputFocused] = useState(null);
  const [validationErrors, setValidationErrors] = useState([null, null]);
  const [currentReference, setCurrentReference] = useState(0);
  const [savingReferenceIndex, setSavingReferenceIndex] = useState(null);
  const phoneNumberInputRefs = useRef([...Array(2).map(() => createRef())]);
  const scrollViewRef = useRef(null);
  const fullNameRef = useRef(null);
  const pickerRef = useRef(null);
  const countryCodePickerRef = useRef(null);

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize={18} fontWeight="600">
          {`${progress}% ${t(`complete`)}`}
        </Text>
      )
    });
  }

  useEffect(() => {
    if (progress < 100) {
      setProgress(progress);
    }
  }, []);

  useEffect(() => {
    console.log({ savingReferenceIndex, updatingReference });
    if (angel && angel.angel_references && savingReferenceIndex && !updatingReference) {
      console.log('reference updated');
      setReferences(() => {
        const referencesCopy = references.map((reference) => ({ ...reference }));
        referencesCopy[savingReferenceIndex].editable = false;

        return referencesCopy;
      });
      setSavingReferenceIndex(null);
    }
  }, [angel, updatingReference]);

  const validateReference = (reference) => {
    const { first_name, countryCode, phone, notes } = reference;
    const valid =
      !!first_name && !!countryCode && !!phone && notes !== t('signUpAngelScreensConnections0');
    // TODO set validationE
    console.log({ reference, valid });

    return valid;
  };

  const onSaveReference = (reference, index) => {
    const { first_name, countryCode, phone, notes } = reference;
    const data = {
      first_name,
      phone: `${countryCode.slice(4, countryCode.length - 1)} ${phone}`,
      notes
    };
    setSavingReferenceIndex(index);

    saveReferenceData(data);
    if (index === 0) {
      scrollViewRef.current.scrollToEnd();
    }
  };

  console.log(
    references.map((reference) => validateReference(reference)),
    references.map((reference) => validateReference(reference)).includes(true)
  );
  const nextButtonDisabled =
    !requestReference ||
    !references.map((reference) => validateReference(reference)).includes(true);
  console.log(
    { nextButtonDisabled },
    !requestReference,
    !references.map((reference) => validateReference(reference)).includes(true)
  );

  return (
    <SafeAreaView>
      {!navigation && <Header title={`${progress}% ${t(`complete`)}`} />}

      <ScrollView ref={scrollViewRef}>
        <KeyboardAvoidingView
          enabled={inputFocused && currentReference}
          behavior="position"
          keyboardVerticalOffset={-70}>
          <Progress
            progress={progress}
            color={progress === 100 ? colors.primary : colors.secondary}
          />

          <TextTextView
            flexDirection="column"
            alignItems="flex-start"
            margins="20px"
            paddings="0px"
            firstText={t(`signUpAngelScreensBackgroundCheck`)}
            firstTextFontFamily="Martel"
            firstTextFontSize="18px"
            firstTextFontWeight="600"
            secondText={t(`signUpAngelScreensBackgroundCheckDescription`)}
            secondTextFontSize="15px"
            secondTextFontWeight="300"
            secondTextAlign="left"
          />

          <TextView
            justifyContent="space-between"
            margins="0px 20px 20px 20px"
            paddings={requestReference ? '0px' : '0px 0px 0px 10px'}
            borderLeftWidth={requestReference ? 0 : '5px'}
            borderColor={colors.alert}
            textFlex={1}
            textMargins="0px 10px 0px 0px"
            text={t(`signUpAngelScreensRequestReference`)}
            fontWeight="300">
            <Switch value={requestReference} onValueChange={setRequestReference} />
          </TextView>

          {references.map((reference, index) => {
            const { first_name, countryCode, phone, notes, editable } = reference;
            const validated = validateReference(reference);

            return (
              <View key={String(index)}>
                <TextView
                  flexDirection="column"
                  alignItems="flex-start"
                  paddings="20px"
                  text={`${t(`signUpAngelScreensReference`)} ${index + 1}`}
                  fontFamily="Martel"
                  fontSize="16px"
                  fontWeight="600">
                  <LabeledInput
                    editable={editable}
                    inputRef={fullNameRef}
                    paddings={
                      validationErrors[index] && validationErrors[index].fullName
                        ? '0px 0px 0px 5px'
                        : '0px'
                    }
                    margins="20px 0px 20px 0px"
                    width="100%"
                    borderLeftWidth={
                      validationErrors[index] && validationErrors[index].fullName ? 4 : 0
                    }
                    borderColor={colors.secondary}
                    backgroundColor={colors.white}
                    inputBorderColor={
                      currentReference === index && inputFocused && inputFocused.fullName
                        ? colors.secondary
                        : colors.defaultBtnBackgroundColor
                    }
                    labelText={t('signUpAngelScreensFirstAndLastName')}
                    inputMargins="5px 0px 0px 0px"
                    inputPaddings="0px 0px 0px 5px"
                    value={first_name}
                    returnKeyType="done"
                    onSubmitEditing={() => Keyboard.dismiss()}
                    onChangeText={(value) => {
                      setReferences(() => {
                        const referencesCopy = references.map((referenceCopy) => ({
                          ...referenceCopy
                        }));
                        referencesCopy[index].first_name = value;

                        return referencesCopy;
                      });
                    }}
                    onFocus={() => {
                      setInputFocused({ fullName: fullNameRef.current.isFocused() });
                      setCurrentReference(index);
                    }}
                  />

                  <PhoneNumberEdit
                    margins="0px"
                    width="100%"
                    editable={editable}
                    validationErrors={validationErrors[index]}
                    returnKeyType="done"
                    inputFocused={currentReference === index ? inputFocused : null}
                    countryCodeOptions={getAllCountryCodes()}
                    countryCode={countryCode}
                    onCountryCodePress={() => {
                      setInputFocused({ countryCode: true });
                      setCurrentReference(index);
                      countryCodePickerRef.current.setIsVisible(true);
                    }}
                    onCountryCodeChange={(code) => {
                      setReferences(() => {
                        const referencesCopy = references.map((referenceCopy) => ({
                          ...referenceCopy
                        }));
                        referencesCopy[currentReference].countryCode = code;

                        return referencesCopy;
                      });
                    }}
                    phoneNumberRef={(ref) => {
                      phoneNumberInputRefs.current[index] = ref;
                    }}
                    phoneNumber={phone}
                    onPhoneNumberChangeText={(value) => {
                      setReferences(() => {
                        const referencesCopy = references.map((referenceCopy) => ({
                          ...referenceCopy
                        }));
                        referencesCopy[index].phone = value;

                        return referencesCopy;
                      });
                    }}
                    onPhoneNumberFocus={() => {
                      setInputFocused({
                        phoneNumber: phoneNumberInputRefs.current[index].isFocused()
                      });
                      setCurrentReference(index);
                    }}
                    onPhoneNumberSubmitEditing={() => Keyboard.dismiss()}
                  />

                  {isWeb ? (
                    <PickerWeb
                      paddings="10px 0px 10px 0px"
                      margins="20px 0px 20px 0px"
                      disabled={!editable}
                      options={[...new Array(7).keys()].map((key) =>
                        t(`signUpAngelScreensConnections${key}`)
                      )}
                      value={notes}
                      onValueChange={(value) =>
                        setReferences(() => {
                          const referencesCopy = references.map((refer) => ({ ...refer }));
                          referencesCopy[currentReference].notes = value;

                          return referencesCopy;
                        })
                      }
                    />
                  ) : (
                    <TextButton
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      paddings="10px 0px 10px 0px"
                      margins="20px 0px 20px 0px"
                      width="100%"
                      height="auto"
                      disabled={!editable}
                      text={t('signUpAngelScreensYourConnection')}
                      fontWeight="normal"
                      textColor={colors.primaryText}
                      onPress={() => {
                        setCurrentReference(index);
                        pickerRef.current.setIsVisible(true);
                      }}>
                      <TextIconView
                        backgroundColor="transparent"
                        text={notes}
                        fontSize="16px"
                        textColor={colors.secondary}
                        icon={arrowRightIcon}
                        iconColor={colors.grey08}
                      />
                    </TextButton>
                  )}

                  {editable && (
                    <TextButton
                      disabled={!validated}
                      alignSelf="flex-end"
                      backgroundColor={
                        validated ? colors.primary : colors.defaultBtnBackgroundColor
                      }
                      text={t('save')}
                      onPress={() => onSaveReference(reference, index)}
                    />
                  )}
                </TextView>
              </View>
            );
          })}
        </KeyboardAvoidingView>
      </ScrollView>

      <ButtonView>
        <TextButton
          disabled={nextButtonDisabled}
          width="50%"
          backgroundColor={nextButtonDisabled ? colors.defaultBtnBackgroundColor : colors.primary}
          text={t('next')}
          onPress={() => {
            const newProgress = progress + 10 >= 100 ? 100 : progress + 10;
            if (newProgress === 100 && progress < 100) {
              setProgress(100);
              if (navigation) {
                navigation.popToTop();
              } else {
                history.goBack();
              }
            } else if (navigation) {
              navigation.navigate('SignUpAngelStep7', { progress: newProgress });
            } else {
              history.push({
                pathname: '/signup-angel-short-bio',
                state: { progress: newProgress }
              });
            }
          }}
        />
      </ButtonView>

      {!isWeb && (
        <>
          <Picker
            ref={pickerRef}
            value={references[currentReference].notes}
            values={[...new Array(7).keys()].map((key) => t(`signUpAngelScreensConnections${key}`))}
            onValueChange={(value) =>
              setReferences(() => {
                const referencesCopy = references.map((reference) => ({ ...reference }));
                referencesCopy[currentReference].notes = value;

                return referencesCopy;
              })
            }
          />

          <Picker
            ref={countryCodePickerRef}
            value={references[currentReference].countryCode}
            values={getAllCountryCodes()}
            onValueChange={(value) => {
              setReferences(() => {
                const referencesCopy = references.map((referenceCopy) => ({
                  ...referenceCopy
                }));
                referencesCopy[currentReference].countryCode = value;

                return referencesCopy;
              });
            }}
            onPickerClosed={() => {
              if (currentReference < phoneNumberInputRefs.current.length) {
                phoneNumberInputRefs.current[currentReference].focus();
              }
            }}
          />
        </>
      )}
    </SafeAreaView>
  );
};

References.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      progress: PropTypes.number
    })
  }),
  history: PropTypes.shape({ push: PropTypes.func, goBack: PropTypes.func }),
  route: PropTypes.shape({
    params: PropTypes.shape({
      progress: PropTypes.number
    })
  }),
  navigation: PropTypes.shape({
    setOptions: PropTypes.func,
    navigate: PropTypes.func,
    popToTop: PropTypes.func
  }),
  updatingReference: PropTypes.bool,
  angel: PropTypes.shape({ angel_references: PropTypes.arrayOf(PropTypes.shape({})) }).isRequired,
  saveReferenceData: PropTypes.func.isRequired,
  setProgress: PropTypes.func.isRequired
};

References.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null,
  updatingReference: false
};

const mapStateToProps = ({ User }) => ({
  updatingReference: User.updatingReference,
  angel: User.user.angel
});

const mapDispatchToProps = {
  saveReferenceData: saveReference,
  setProgress: setOnboardingProgress
};

export default connect(mapStateToProps, mapDispatchToProps)(References);
