import {
  FETCHING_MESSAGES,
  GET_MESSAGES,
  GET_NEXT_MESSAGES,
  NEW_MESSAGE,
  ADDING_MESSAGE,
  UPDATE_MESSAGE,
  CHAT_TYPING_CHANGE,
  GET_CONVERSATIONS,
  GET_ACTIVE_TIMINGS,
  SET_MESSAGES_READ,
  UPDATE_CONVERSATION_LAST_MESSAGE,
  GET_NOTIFICATIONS,
  SET_NOTIFICATIONS_READ
} from '../types';

const initialState = {
  fetching: true,
  activeChat: {},
  typing: false,
  conversations: []
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCHING_MESSAGES:
      return {
        ...state,
        fetching: true
      };
    case GET_MESSAGES:
      return {
        ...state,
        activeChat: payload,
        fetching: false
      };
    case GET_NEXT_MESSAGES:
      return {
        ...state,
        activeChat: {
          ...payload,
          data: [...state.activeChat.data, ...payload.data]
        },
        fetching: false
      };
    case NEW_MESSAGE:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          messageAdded: true,
          messageUpdated: false,
          data: [payload.receivedMessage || payload, ...state.activeChat.data]
        },
        fetching: false
      };
    case ADDING_MESSAGE:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          messageAdded: false
        }
      };
    case UPDATE_MESSAGE:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          messageUpdated: true,
          data: state.activeChat.data.map((message) => {
            if (message.id === payload.id) {
              return payload;
            }
            return message;
          })
        }
      };
    case SET_MESSAGES_READ:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          data: state.activeChat.data.map((message) => ({
            ...message,
            received: true,
            viewed_at: payload
          }))
        }
      };
    case UPDATE_CONVERSATION_LAST_MESSAGE:
      return {
        ...state,
        conversations: state.conversations.map((conversation) => {
          if (conversation.conversation_id === payload.conversationId) {
            return {
              ...conversation,
              last_message: {
                ...conversation.last_message,
                ...payload.message,
                viewedAt: payload.message.viewed_at || payload.message.viewedAt
              },
              unread_message_count:
                payload.message.viewed_at || payload.message.viewedAt
                  ? 0
                  : conversation.last_message.id !== payload.message.id
                  ? conversation.unread_message_count + 1
                  : conversation.unread_message_count
            };
          }
          return conversation;
        })
      };
    case CHAT_TYPING_CHANGE:
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          typing: payload
        },
        fetching: false
      };
    case GET_CONVERSATIONS:
      return {
        ...state,
        conversations: payload,
        fetching: false
      };
    case GET_ACTIVE_TIMINGS:
      return {
        ...state,
        activeBooking: payload,
        fetching: false
      };
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload
      };
    case SET_NOTIFICATIONS_READ:
      return {
        ...state,
        notifications:
          state.notifications &&
          state.notifications.map((notification) => ({ ...notification, read: true }))
      };
    default:
      return state;
  }
}
