import React, { useEffect, useState } from 'react'
import { Button, Heading, HStack, Skeleton, Spacer, Text, VStack } from 'native-base'
import { CharlyIcon } from '@charlycares/ui'
import { formatMoney, isDev, isWeb, UserRoles } from '@charlycares/shared'
import { Share } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useGetUserQuery, usePromotionsQuery } from '@charlycares/data-access'

export default function InviteReferrals() {
  const { t } = useTranslation()
  const { data: promotions, isSuccess: promotionsLoaded } = usePromotionsQuery()
  const [isCopied, setIsCopied] = useState<UserRoles | undefined>(undefined)
  const { data: user } = useGetUserQuery()
  const role = user?.role ?? UserRoles.FAMILY

  const onShareCode = (role: UserRoles) => {
    if (isWeb) {
      setIsCopied(role)

      navigator.clipboard.writeText(
        t(`promotionsScreen.credit.share.${role}.inviteMessage`, {
          link: isDev
            ? `https://app.charlycares.dev/invited?code=${promotions?.code}&role=${role}`
            : `https://app.charlycares.com/invited?code=${promotions?.code}&role=${role}`,
          amount:
            role === UserRoles.FAMILY ? formatMoney(promotions?.refers_family) : formatMoney(promotions?.refers_angel),
          code: promotions?.code
        })
      )

      return
    }

    Share.share(
      {
        title: t(`promotionsScreen.credit.share.${role}.inviteTitle`),
        message: t(`promotionsScreen.credit.share.${role}.inviteMessage`, {
          link: isDev
            ? `https://app.charlycares.dev/invited?code=${promotions?.code}&role=${role}`
            : `https://app.charlycares.com/invited?code=${promotions?.code}&role=${role}`,
          amount:
            role === UserRoles.FAMILY ? formatMoney(promotions?.refers_family) : formatMoney(promotions?.refers_angel),
          code: promotions?.code
        })
      },
      { subject: t(`promotionsScreen.credit.share.${role}.inviteMessage`) }
    )
  }

  useEffect(() => {
    let timeout: any

    if (isCopied) {
      timeout = setTimeout(() => {
        setIsCopied(undefined)
      }, 1000)
    }

    return () => timeout && clearTimeout(timeout)
  }, [isCopied])

  return (
    <>
      <HStack>
        <CharlyIcon name="icn-gift" size="30" color="primary.400" />
        <Heading fontSize={18} mt={2} ml={18}>
          {t(`promotionsScreen.credit.${role}.heading`)}
        </Heading>
      </HStack>
      <Text mt="11px" fontSize={15}>
        {t('promotionsScreen.credit.subHeading')}
      </Text>

      <VStack mt="30px">
        <HStack borderBottomColor="gray.200" borderBottomWidth="1px" pb="16px">
          <Text fontSize={16} mt={1}>
            {t('promotionsScreen.credit.inviteFamily')}
          </Text>
          <Spacer />
          {promotionsLoaded && promotions && (
            <Button
              maxHeight="32px"
              _text={{ fontSize: '13px', lineHeight: '17px' }}
              onPress={() => onShareCode(UserRoles.FAMILY)}
            >
              {isCopied === UserRoles.FAMILY
                ? t('promotionsScreen.credit.earnButtonCopied')
                : t('promotionsScreen.credit.earnButton', { amount: formatMoney(promotions?.refers_family) })}
            </Button>
          )}
          {!promotionsLoaded && <Skeleton width="100px" rounded="full" height="32px" />}
        </HStack>
        <HStack py="16px">
          <Text fontSize={16} mt={1}>
            {t('promotionsScreen.credit.inviteAngel')}
          </Text>
          <Spacer />
          {promotionsLoaded && promotions && (
            <Button
              maxHeight="32px"
              _text={{ fontSize: '13px', lineHeight: '17px' }}
              onPress={() => onShareCode(UserRoles.ANGEL)}
            >
              {isCopied === UserRoles.ANGEL
                ? t('promotionsScreen.credit.earnButtonCopied')
                : t('promotionsScreen.credit.earnButton', { amount: formatMoney(promotions?.refers_angel) })}
            </Button>
          )}
          {!promotionsLoaded && <Skeleton width="100px" rounded="full" height="32px" />}
        </HStack>
      </VStack>
    </>
  )
}
