import { SERVER_ERROR, CLEAR_SERVER_ERROR } from '../types';

const initialState = {
  serverError: false,
  errorMessage: ''
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SERVER_ERROR:
      return {
        serverError: true,
        errorMessage: payload || ''
      };
    case CLEAR_SERVER_ERROR:
      return {
        serverError: false,
        errorMessage: ''
      };
    default:
      return state;
  }
}
