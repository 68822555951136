import {
  AngelBabysittingExperience,
  Days,
  JobboardFilters,
  JobboardSortTypes,
  languageCountryCodes,
  JobboardSorts,
  User,
  getUserLanguages,
  trimJobboardFilters
} from '@charlycares/shared'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

export interface JobboardSlice {
  filters: JobboardFilters
  sorts: JobboardSorts
  filtersCount: number
}

const initialState: JobboardSlice = {
  filtersCount: 0,
  filters: {
    single: true,
    multi: true,
    postal_code: '',
    distance_in_meters: 25000,
    min_age_children_in_months: AngelBabysittingExperience.NewBorn,
    days_of_week: [Days.Monday, Days.Tuesday, Days.Wednesday, Days.Thursday, Days.Friday, Days.Saturday, Days.Sunday],
    languages: languageCountryCodes.map(lng => lng.country),
    start_time_range: {
      from: '00:00',
      to: '23:59'
    }
  },
  sorts: [
    {
      column: JobboardSortTypes.BOOKING_DATE,
      direction: 'asc'
    },
    {
      column: JobboardSortTypes.DISTANCE,
      direction: 'asc'
    },
    {
      column: JobboardSortTypes.HOURLY_RATE,
      direction: 'asc'
    }
  ]
}

export const jobboardSlice = createSlice({
  name: 'jobboard',
  initialState,
  reducers: {
    updateJobboardFilters: (state, action: PayloadAction<Partial<JobboardFilters>>) => {
      const filters = trimJobboardFilters({ ...action.payload })
      state.filters = Object.assign(state.filters, filters)
      return state
    },
    setDefaultJobboardFilters: (state, action: PayloadAction<User>) => {
      const user = action.payload

      state.filters = initialState.filters

      if (user.angel) {
        const angelAvailabilityDays = user?.angel?.availabilities
          .filter(availability => availability.evening || availability.morning || availability.evening)
          .map(availability => availability.day_of_week)

        const userFilters: Partial<JobboardFilters> = {
          postal_code: user.profile.postal_code,
          min_age_children_in_months: user.angel.min_age_children,
          languages: getUserLanguages(user),
          days_of_week: angelAvailabilityDays
        }

        state.filters = {
          ...state.filters,
          ...trimJobboardFilters(userFilters)
        }
      }

      return state
    },
    setJobboardSort: (state, action: PayloadAction<JobboardSortTypes>) => {
      const firstIndex = state.sorts.findIndex(sort => sort.column === action.payload)
      const temp = state.sorts[0]
      state.sorts[0] = state.sorts[firstIndex]
      state.sorts[firstIndex] = temp
      return state
    },
    countJobboardFilters: (state, action: PayloadAction<User>) => {
      const user = action.payload

      state.filtersCount = 0
      const angelAvailabilityDays = user?.angel?.availabilities
        .filter(availability => availability.evening || availability.morning || availability.evening)
        .map(availability => availability.day_of_week)

      if (state.filters.multi !== initialState.filters.multi || state.filters.single !== initialState.filters.single) {
        state.filtersCount++
      }
      if (state.filters.distance_in_meters !== initialState.filters.distance_in_meters) {
        state.filtersCount++
      }
      if (state.filters.postal_code !== user?.profile.postal_code) {
        state.filtersCount++
      }
      if (!_.isEqual(state.filters.start_time_range, initialState.filters.start_time_range)) {
        state.filtersCount++
      }
      if (!_.isEqual(state.filters.days_of_week, angelAvailabilityDays)) {
        state.filtersCount++
      }
      if (!_.isEqual(state.filters.languages, getUserLanguages(user))) {
        state.filtersCount++
      }
      if (state.filters.min_age_children_in_months !== user.angel?.min_age_children) {
        state.filtersCount++
      }

      return state
    }
  }
})

export const { updateJobboardFilters, setJobboardSort, countJobboardFilters, setDefaultJobboardFilters } =
  jobboardSlice.actions

export const jobboardReducer = jobboardSlice.reducer
