import User from './userReducer'
import FamilyBookings from './familyBookingsReducer'
import AngelBookings from './angelBookingsReducer'
import AngelAppointment from './angelAppointmentReducer'
import AngelPreferences from './angelPreferencesAvailabilityReducer'
import Ratings from './ratingsReducer'
import Referral from './referralReducer'
import Payments from './paymentsReducer'
import Favorites from './favoritesReducer'
import Dashboard from './dashboardReducer'
import Book from './bookReducer'
import Support from './supportReducer'
import Chat from './chatReducer'
import ServerError from './errorReducer'
import JobFinder from './jobFinderReducer'

export default {
  User,
  FamilyBookings,
  AngelBookings,
  AngelAppointment,
  AngelPreferences,
  Ratings,
  Referral,
  Payments,
  Favorites,
  Dashboard,
  Book,
  Support,
  Chat,
  ServerError,
  JobFinder
}
