const Input = {
  baseStyle: {
    color: 'text.dark.400'
  },
  sizes: {
    md: {
      fontSize: '16px',
      minH: '48px',
      px: '10px'
    }
  },
  variants: {
    underline: {
      borderBottomWidth: '1px',
      borderRadius: '0px',
      _focus: { borderBottomColor: 'secondary.400', borderBottomWidth: '1.5px' },
      _hover: {
        borderBottomColor: 'secondary.400'
      }
    },
    filled: {
      bg: 'gray.100',
      borderWidth: 0,
      borderBottomWidth: '1px',
      borderRadius: 'none',
      borderBottomColor: 'gray.500',
      _focus: {
        _important: { borderWidth: 0 },
        borderWidth: 0,
        bg: 'gray.100',
        borderBottomWidth: '2px',
        borderBottomColor: 'secondary.400'
      },
      _hover: {
        _important: { borderWidth: 0 },
        borderBottomColor: 'secondary.400'
      }
    }
  },
  defaultProps: {
    variant: 'underline',
    size: 'md'
  }
}

export default Input
