import React, { useState } from 'react'
import { ThemeProvider } from 'styled-components/native'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { NativeBaseProvider } from 'native-base'
import { Provider } from 'react-redux'
import { styledTheme, nativeBaseTheme } from '@charlycares/shared'
import { extendStoreReducer } from '@charlycares/data-access'
import { StreamingUpdateProvider } from './providers'
import { LoadingProvider } from './providers/Loading'

type Props = {
  children: React.ReactNode
  reducers: any
}

export default function SharedProviders({ children, reducers }: Props) {
  return (
    <Provider store={extendStoreReducer(reducers)}>
      <NativeBaseProvider theme={nativeBaseTheme}>
        <ThemeProvider theme={styledTheme}>
          <StreamingUpdateProvider>
            <LoadingProvider>
              <SafeAreaProvider>{children}</SafeAreaProvider>
            </LoadingProvider>
          </StreamingUpdateProvider>
        </ThemeProvider>
      </NativeBaseProvider>
    </Provider>
  )
}
