import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import {
  Login,
  OutsideService,
  WaitingList,
  SignupAngelStep2,
  SignupAngelMakeAppointment,
  SignupAngelScheduleAppointment,
  SignupAngelCancelAppointment,
  SignupAngelStep5,
  SignupAngelReferences,
  SignupAngelShortBio,
  SignupAngelPreferences,
  SignupAngelAvailability,
  SignupAngelVideo,
  SignupFamilyStep2,
  SignupFamilyStep3,
  ReferralLandingPage,
  DirectPaymentsLanding,
  DirectPaymentsThanks,
  AngelLeaveReference
} from '@charlycares/legacy/web'
import { ProtectedRoute } from '../components'
import { Onboarding, AngelSignupFinal } from '../screens'
import {
  BecomeMember,
  FamilyInfoScreen,
  FamilyIntroductionScreen,
  NewCityStep1Screen,
  NewCityStep2Screen,
  NewCityStep3Screen,
  NewCityStep4Screen,
  SetupBankAccount,
  SignUpScreen,
  ServiceGroupTypesScreen,
  PreBoardingFamilyStep1Screen,
  PreBoardingFamilyStep2Screen,
  BookingIsAlmostSentScreen,
  WhatWeOfferMembershipScreen,
  ActivateTrialMembershipInfoScreen,
  LinkBankAccountScreen,
  MembershipActivatedScreen,
  ForgotPassword,
  CheckResetPasswordCode,
  ResetPassword,
  ReviewByReference
} from '@charlycares/features'

const BoardingRoutes = (user, setLoading) => [
  <Route key="login" path="/login" component={props => <Login {...props} setLoading={setLoading} />} />,
  <Route key="forgot-password" path="/forgot-password" component={ForgotPassword} />,
  <Route key="check-reset-password-code" path="/check-reset-password-code" component={CheckResetPasswordCode} />,
  <Route key="reset-password" path="/reset-password" component={ResetPassword} />,
  <Route key="onboarding" path="/onboarding" component={Onboarding} />,
  <Route key="signup" path="/signup/:role" component={props => <SignUpScreen {...props} setLoading={setLoading} />} />,
  <Route key="signup-angel" path="/signup-angel" component={SignupAngelStep2} />,
  <Route key="signup-angel" path="/signup-angel-final" component={AngelSignupFinal} />,
  <Route key="invite/:reference/:role" path="/invite/:reference/:role" component={ReferralLandingPage} />,
  <Route key="db-payments/:reference" path="/db-payments/:reference" component={DirectPaymentsLanding} />,
  <Route key="db-payment/result/success" path="/db-payment/result/success" component={DirectPaymentsThanks} />,
  <Route key="reference/:angelId/:referenceId" path="/reference/:angelId/:referenceId" component={ReviewByReference} />,
  <ProtectedRoute
    key="signup-angel-appointment"
    path="/signup-angel-appointment"
    user={user}
    component={SignupAngelMakeAppointment}
  />,
  <ProtectedRoute
    key="signup-angel-appointment-schedule"
    path="/signup-angel-appointment-schedule"
    user={user}
    component={SignupAngelScheduleAppointment}
  />,
  <ProtectedRoute
    key="signup-angel-appointment-cancel"
    path="/signup-angel-appointment-cancel"
    user={user}
    component={SignupAngelCancelAppointment}
  />,
  <ProtectedRoute key="signup-angel-step5" path="/signup-angel-step5" user={user} component={SignupAngelStep5} />,
  <ProtectedRoute
    key="signup-angel-references"
    path="/signup-angel-references"
    user={user}
    component={SignupAngelReferences}
  />,
  <ProtectedRoute
    key="signup-angel-short-bio"
    path="/signup-angel-short-bio"
    user={user}
    component={SignupAngelShortBio}
  />,
  <ProtectedRoute
    key="signup-angel-preferences"
    path="/signup-angel-preferences"
    user={user}
    component={SignupAngelPreferences}
  />,
  <ProtectedRoute
    key="signup-angel-availability"
    path="/signup-angel-availability"
    user={user}
    component={SignupAngelAvailability}
  />,
  <ProtectedRoute key="signup-angel-video" path="/signup-angel-video" user={user} component={SignupAngelVideo} />,

  <Route key="signup-family" path="/signup-family" component={SignupFamilyStep2} />,
  <ProtectedRoute key="signup-family-bio" path="/signup-family-bio" user={user} component={SignupFamilyStep3} />,
  <ProtectedRoute key="become-member" path="/become-member" user={user} component={BecomeMember} />,
  <ProtectedRoute
    key="booking-is-almost-sent"
    path="/booking-is-almost-sent"
    user={user}
    component={BookingIsAlmostSentScreen}
  />,
  <ProtectedRoute
    key="what-we-offer-with-membership"
    path="/what-we-offer-with-membership"
    user={user}
    component={WhatWeOfferMembershipScreen}
  />,
  <ProtectedRoute
    key="activate-trial-membership-info"
    path="/activate-trial-membership-info"
    user={user}
    component={ActivateTrialMembershipInfoScreen}
  />,
  <ProtectedRoute key="link-bank-account" path="/link-bank-account" user={user} component={LinkBankAccountScreen} />,
  <ProtectedRoute
    key="membership-activated"
    path="/membership-activated"
    user={user}
    component={MembershipActivatedScreen}
  />,
  <ProtectedRoute key="setup-bank-account" path="/setup-bank-account" user={user} component={SetupBankAccount} />,
  <Route key="outside-service" path="/outside-service" component={OutsideService} />,
  <Route key="waiting-list" path="/waiting-list" component={WaitingList} />,
  <ProtectedRoute key="family-info" path="/family/info" user={user} component={FamilyInfoScreen} />,
  <ProtectedRoute
    key="family-introduction"
    path="/family/introduction"
    user={user}
    component={FamilyIntroductionScreen}
  />,
  <ProtectedRoute
    key="new-city-step1-screen"
    path="/family/new-city/step-1"
    user={user}
    component={NewCityStep1Screen}
  />,
  <ProtectedRoute
    key="new-city-step2-screen"
    path="/family/new-city/step-2"
    user={user}
    component={NewCityStep2Screen}
  />,
  <ProtectedRoute
    key="new-city-step3-screen"
    path="/family/new-city/step-3"
    user={user}
    component={NewCityStep3Screen}
  />,
  <ProtectedRoute
    key="new-city-step4-screen"
    path="/family/new-city/step-4"
    user={user}
    component={NewCityStep4Screen}
  />,
  <Route key="service-group-types" path="/service-group-types" component={ServiceGroupTypesScreen} />,
  <Route key="family-preboarding-step-1" path="/family-preboarding-step-1" component={PreBoardingFamilyStep1Screen} />,
  <Route key="family-preboarding-step-2" path="/family-preboarding-step-2" component={PreBoardingFamilyStep2Screen} />
]

BoardingRoutes.propTypes = {
  user: PropTypes.shape({}),
  setLoading: PropTypes.func.isRequired
}

BoardingRoutes.defaultProps = {
  user: null
}

export default BoardingRoutes
