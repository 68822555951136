import { MEMBERSHIP_TYPES, CancellationReason, Membership } from '@charlycares/shared'
import {
  ChangeBankAccountBody,
  ChangeBankAccountResponse,
  GetPaymentsMethodsBody,
  GetPaymentsMethodsResponse,
  CancelMembershipResponse,
  CancelMembershipBody,
  GetAvailableMembershipsResponse,
  CompleteAppleMembershipResponse,
  SubscribeMembershipBody,
  SubscriptionResponse,
  SubscribeMembershipResponse
} from './dto'
import { api } from '../api'
import { ResponseData, ApiTags } from '../types'
import { userApi } from '../user'

export const membershipApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: ({ mutation, query }) => ({
    changeBankAccount: mutation<ChangeBankAccountResponse, ChangeBankAccountBody>({
      query: body => ({
        url: `v2/memberships/change-bank-account`,
        method: 'POST',
        body
      })
    }),
    getPaymentsMethods: query<GetPaymentsMethodsResponse, GetPaymentsMethodsBody>({
      query: body => ({
        url: `v2/memberships/subscription/payment_methods`,
        method: 'POST',
        body
      })
    }),
    getCancellationReasons: query<CancellationReason[], void>({
      query: () => ({
        url: `v1/membership/reasons`,
        method: 'GET'
      })
    }),
    getSubscription: query<SubscriptionResponse, void>({
      query: () => ({
        url: `v2/memberships/subscription/`,
        method: 'GET'
      }),
      transformResponse: ({ data }): SubscriptionResponse => data,
      providesTags: [ApiTags.SUBSCRIPTION]
    }),
    getMemberships: query<Membership[], void>({
      query: () => ({
        url: `v2/memberships`,
        method: 'GET'
      }),
      transformResponse: ({ data }): Membership[] => data
    }),
    cancelMembership: mutation<CancelMembershipResponse, CancelMembershipBody>({
      query: body => ({
        url: `v2/memberships/cancel`,
        method: 'POST',
        body
      }),
      onQueryStarted(arg, { queryFulfilled, dispatch }) {
        queryFulfilled
          .then(() => dispatch(userApi.endpoints.getUserProfile.initiate()))
          .catch(err => console.error(err))
      },
      invalidatesTags: [ApiTags.SUBSCRIPTION]
    }),
    changeMembership: mutation<SubscriptionResponse, { membership_type: MEMBERSHIP_TYPES }>({
      query: body => ({
        url: 'v2/memberships/subscription/change',
        method: 'POST',
        body
      }),
      onQueryStarted(arg, { queryFulfilled, dispatch }) {
        queryFulfilled
          .then(() => dispatch(userApi.endpoints.getUserProfile.initiate()))
          .catch(err => console.error(err))
      },
      transformResponse: ({ data }): SubscriptionResponse => data,
      invalidatesTags: [ApiTags.SUBSCRIPTION]
    }),
    getAvailableMemberships: query<GetAvailableMembershipsResponse, void>({
      query: () => ({
        url: `v2/memberships/available`,
        method: 'GET'
      }),
      transformResponse: ({ data }): GetAvailableMembershipsResponse => data
    }),
    completeAppleMembership: mutation<CompleteAppleMembershipResponse, { transactionReceipt: string }>({
      query: body => ({
        url: `v2/memberships/subscription/apple`,
        method: 'POST',
        body
      }),
      onQueryStarted(arg, { queryFulfilled, dispatch }) {
        queryFulfilled.then(() => dispatch(userApi.endpoints.getUserProfile.initiate())).catch(err => console.log(err))
      }
    }),
    subscribeMembership: mutation<ResponseData<SubscribeMembershipResponse>, SubscribeMembershipBody>({
      query: body => ({
        url: `v2/memberships/subscription`,
        method: 'POST',
        body
      }),
      onQueryStarted(arg, { queryFulfilled, dispatch }) {
        queryFulfilled
          .then(() => dispatch(userApi.endpoints.getUserProfile.initiate()))
          .catch(err => console.error(err))
      }
    })
  })
})

export const {
  useChangeBankAccountMutation,
  useGetPaymentsMethodsQuery,
  useGetCancellationReasonsQuery,
  useGetSubscriptionQuery,
  useCancelMembershipMutation,
  useGetMembershipsQuery,
  useGetAvailableMembershipsQuery,
  useChangeMembershipMutation,
  useCompleteAppleMembershipMutation,
  useSubscribeMembershipMutation
} = membershipApi
