import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import colors from '../../styles/colors';

import defaultFamilyImage from '../../../assets/images/defaultFamilyImage.png';

const View = styled.View`
  overflow: hidden;
`;
const Image = styled.Image`
  margin: ${({ margins }) => margins};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-color: ${colors.grey07};
  border-width: ${({ borderWidth }) => borderWidth};
  border-radius: ${({ borderRadius }) => borderRadius};
`;

const FamilyAvatar = ({ margins, width, height, borderWidth, borderRadius, source }) => (
  <View>
    <Image
      margins={margins}
      width={width}
      height={height}
      borderWidth={borderWidth}
      borderRadius={borderRadius}
      source={source ? { uri: source } : defaultFamilyImage}
      defaultSource={defaultFamilyImage}
    />
  </View>
);

FamilyAvatar.propTypes = {
  margins: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderWidth: PropTypes.number,
  borderRadius: PropTypes.string,
  source: PropTypes.node
};

FamilyAvatar.defaultProps = {
  margins: '0px',
  width: '61px',
  height: '41px',
  borderWidth: '1px',
  borderRadius: '4px',
  source: defaultFamilyImage
};

export default FamilyAvatar;
