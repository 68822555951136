import React from 'react'
import { Card, CharlyIcon, ScreenLayout } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import {
  formatDate,
  IBookingDetails,
  useRouter,
  makeCall,
  saveInCalendar,
  BookingStates,
  isWeb,
  formatMoney
} from '@charlycares/shared'
import { Avatar, Box, Button, Divider, Factory, Heading, HStack, Pressable, Spacer, Text, VStack } from 'native-base'
import { useGetBookingCancellationReasonsQuery, useGetBookingDetailsQuery } from '@charlycares/data-access'
import { TouchableOpacity } from 'react-native'
import BookingCancellationPolicySummary from './common/BookingCancellationPolicySummary'

const Touchable = Factory(TouchableOpacity)

const FamilyBookingDetailsScreen = () => {
  const { getParam, navigate } = useRouter()
  const { t } = useTranslation()

  const obscured_id = getParam('bookingId') as string
  const { data: booking } = useGetBookingDetailsQuery(
    { obscured_id },
    {
      skip: !obscured_id
    }
  )
  const { data: cancellationReasons } = useGetBookingCancellationReasonsQuery(
    {
      booking_id: (booking as IBookingDetails)?.id.toString()
    },
    { skip: !booking }
  )

  if (!booking) {
    return <ScreenLayout isLoading title={t('booking')} supportButton />
  }

  return (
    <ScreenLayout
      _buttonDisclaimer={{
        children: t('addToCalendarDisclaimer')
      }}
      _buttonProps={
        !isWeb && booking.current_state === BookingStates.ACCEPTED
          ? {
              onPress: () =>
                saveInCalendar(
                  booking.start_date,
                  booking.end_date,
                  t('bookingCalendarTitle'),
                  t('bookingCalendarDesc')
                ),
              children: t('addToCalendar')
            }
          : undefined
      }
      supportButton
      title={t('booking')}
      subtitle={booking.obscured_id}
    >
      <Card>
        <HStack alignItems="center" space="10px">
          {!!booking.angel?.image && (
            <Avatar borderWidth={1} borderColor="gray.200" size={'80px'} source={{ uri: booking.angel?.image }} />
          )}
          <VStack flex={1} space="5px">
            <Heading fontSize="18px">{`${booking.angel?.first_name} (${booking.angel?.age})`}</Heading>

            <HStack
              space={isWeb ? '40px' : '10px'}
              alignItems="center"
              justifyContent={isWeb ? 'flex-start' : 'space-around'}
              mt="5px"
            >
              <Touchable
                onPress={() => navigate('ChatScreen', '/chat', { conversationId: booking.angel?.conversation_id })}
                disabled={!booking.angel?.conversation_id}
                justifyContent="center"
                alignItems="center"
              >
                <CharlyIcon
                  name="icn-chat"
                  size={30}
                  color={booking.angel?.conversation_id ? 'primary.400' : 'gray.400'}
                />
                <Text color={booking.angel?.conversation_id ? 'primary.400' : 'gray.400'} fontSize="12px" mt="5px">
                  {t('message')}
                </Text>
              </Touchable>

              <Touchable
                disabled={!booking.angel?.phone}
                onPress={() => booking.angel?.phone && makeCall(booking.angel.phone)}
                justifyContent="center"
                alignItems="center"
              >
                <CharlyIcon
                  name="icn-telephone-fill"
                  size={30}
                  color={booking.angel?.phone ? 'primary.400' : 'gray.400'}
                />
                <Text color={booking.angel?.phone ? 'primary.400' : 'gray.400'} fontSize="12px" mt="5px">
                  {t('call')}
                </Text>
              </Touchable>

              <Touchable
                onPress={() => navigate('BookDirect', '/book-direct', { angelId: booking.angel?.obscured_id })}
                justifyContent="center"
                alignItems="center"
              >
                <CharlyIcon name="icn-calendar" size={30} color="primary.400" />
                <Text color="primary.400" fontSize="12px" mt="5px">
                  {t('booking')}
                </Text>
              </Touchable>
            </HStack>
          </VStack>
          <CharlyIcon
            onPress={() => navigate('AngelProfile', `/booking-angel-profile`, { angelId: booking.angel?.obscured_id })}
            alignSelf="flex-start"
            name="icn-caret-right"
            size={35}
          />
        </HStack>

        <Box mt="15px">
          <Divider />
          <Box py="10px" flexDir="row" justifyContent="space-between" alignItems="center">
            <Text fontSize="16px">{t('start')}</Text>
            <Text color="gray.800" fontSize="16px">
              {formatDate(booking.start_date, { nl: 'ddd D MMMM HH:mm', en: 'ddd, MMMM D HH:mm' })}
            </Text>
          </Box>
          <Divider />
          <Box py="10px" flexDir="row" justifyContent="space-between" alignItems="center">
            <Text fontSize="16px">{t('end')}</Text>
            <Text color="gray.800" fontSize="16px">
              {formatDate(booking.end_date, { nl: 'ddd D MMMM HH:mm', en: 'ddd, MMMM D HH:mm' })}
            </Text>
          </Box>

          <Divider />

          {booking.rate && booking.current_state === BookingStates.ACCEPTED && (
            <Pressable>
              <HStack alignItems="center" py="10px" w="100%" borderColor="gray.200" borderBottomWidth="1">
                <Text fontSize="16px">{t('estimatedCosts')}</Text>
                <Spacer />
                <HStack alignItems="center">
                  <Text color="gray.900" fontSize="16px">
                    {formatMoney(booking.rate?.total_costs)}
                  </Text>
                </HStack>
              </HStack>
            </Pressable>
          )}
        </Box>

        {!!booking.personal_message && (
          <Box mt="15px" mb="0px">
            <Text fontSize="16px">{t('personalMessage')}</Text>
            <Text fontSize="15px" mt="5px">
              {booking.personal_message}
            </Text>
          </Box>
        )}
      </Card>

      {booking.current_state === BookingStates.ACCEPTED && (
        <>
          <Card>
            <Box>
              <Heading fontSize="16px" fontWeight="600">
                {t('bookingsScreenCancelBooking')}
              </Heading>
              {booking.cancellation_policies && (
                <BookingCancellationPolicySummary bookingCancellationPolicies={booking.cancellation_policies} />
              )}
              <HStack mt="25px" mb="5px">
                <Button
                  width="100%"
                  colorScheme="primary"
                  variant="outline"
                  onPress={() => {
                    if (cancellationReasons) {
                      navigate('BookingCancellationReasons', '/booking-cancellation-reasons', {
                        bookingId: booking.obscured_id,
                        cancellationReasons: cancellationReasons
                      })
                    }
                  }}
                >
                  {t('bookingsScreenCancelBooking')}
                </Button>
              </HStack>
            </Box>
          </Card>
          <Box px="20px" py="10px" mb="70px" w="100%">
            <Text color="gray.700" fontSize="14px">
              {'*'}
              {t('bookingsScreenEditBookingDescription')}
            </Text>
          </Box>
        </>
      )}
    </ScreenLayout>
  )
}

export default FamilyBookingDetailsScreen

FamilyBookingDetailsScreen.navigationOptions = {
  headerShown: false
}
