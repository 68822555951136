import React, { forwardRef, RefObject, useRef, useState } from 'react'
import { Moment } from "moment/moment";
import DateTimePicker from './DateTimePicker'
import moment from "moment";
import { Box, FormControl, IBoxProps, Input, ITextProps, Text, WarningOutlineIcon } from "native-base";
import { TextInput } from "../inputs";
import { isWeb } from "@charlycares/shared";


type Props = {
  date?: Moment | undefined
  minDate?: Moment
  maxDate?: Moment
  withTime?: boolean
  dateFormat?: string
  onChange: (date: Moment) => void
  _wrapper?: IBoxProps
  _label?: ITextProps
  _helper?: ITextProps
  label?: string
  error?: string
  hideErrorMessage?: boolean
  nextField?: RefObject<any>
  prevField?: RefObject<any>
  hidden?: boolean
  open?: boolean
}

const DatePicker = React.forwardRef(
  (
    {date, minDate, maxDate, withTime, dateFormat, onChange, label, error, _wrapper, _label, _helper, hideErrorMessage, nextField, prevField, hidden, open, ...inputProps}: Props,
    ref: any
  ) => {
    const datePickerRef = useRef<any>(null)
    const [selectedDate, setSelectedDate] = useState(date || undefined)

    // @ts-ignore
    const CustomWebInput = forwardRef(({value, onClick, labelValue}, ref) => {
      return ! hidden && (
        <TextInput value={ selectedDate?.format(dateFormat ?? 'DD-MM-YYYY') } onFocus={ onClick } ref={ ref } />
      );
    });

    if (open) {
      console.log(open)

      if (!isWeb) datePickerRef.current?.open(maxDate ?? date)
    }

    return (
      <FormControl isInvalid={ !!error }>
        { !!label && (
          <FormControl.Label>
            <Text fontSize={ '14px' } color={ error ? 'alert.danger.400' : 'gray.800' } { ..._label }>
              { label }
            </Text>
          </FormControl.Label>
        ) }
        { isWeb && (
          <DateTimePicker
            inline={open}
            showTimeSelect={ withTime ?? false }
            selected={ selectedDate?.toDate() }
            onChange={ (date: Date) => {
              setSelectedDate(moment(date));
              onChange(moment(date));
              nextField?.current?.focus()
            } }
            customInput={ <CustomWebInput/> }
            maxDate={ maxDate?.toDate() }
            minDate={ minDate?.toDate() }
            showYearDropdown
            dropdownMode="select"
          />
        ) }
        { ! isWeb && (
          <Input
            ref={ ref }
            borderBottomColor={ error ? 'alert.danger.400' : undefined }
            _focus={ {
              borderBottomColor: error ? 'alert.danger.400' : undefined
            } }
            value={ selectedDate?.format(dateFormat ?? 'DD-MM-YYYY') ?? '' }
            onFocus={ () => datePickerRef.current?.open(maxDate ?? date) }
            { ...inputProps }
          />
        ) }
        { !hideErrorMessage && (
          <FormControl.ErrorMessage leftIcon={ <WarningOutlineIcon size="xs"/> }>
            <Text fontSize={ '14px' } color={ 'alert.danger.400' } { ..._helper }>
              { error }
            </Text>
          </FormControl.ErrorMessage>
        ) }

        { !isWeb && (
          <DateTimePicker
            ref={ datePickerRef }
            type="date"
            dateFormat="YYYY"
            separator=""
            minDate={ minDate }
            maxDate={ maxDate }
            onConfirmPress={ (date: string) => {
              setSelectedDate(moment(date))
              onChange(moment(date))
              setTimeout(() => nextField?.current?.focus(), 500)
            }}
            onCancelPress={ () => setTimeout(() => prevField?.current?.focus(), 300) }
            onClose={ () => {
              return setTimeout(() => prevField?.current?.focus(), 300);
            } }
          />
        )}
      </FormControl>
    );
  }
)

export default DatePicker
