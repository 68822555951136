import React, { useEffect, useState } from 'react'
import { ServiceGroupType, useRouter } from '@charlycares/shared'
import { useTranslation } from 'react-i18next'
import { useGetUserQuery, useUpdateProfileMutation } from '@charlycares/data-access'
import { ScreenLayout, Card, TextInput } from '@charlycares/ui'
import { Box, Heading, Text } from 'native-base'
import { Keyboard } from 'react-native'
import { useAlert } from '../../../hooks'
import { getAngelOnboardingProgress } from '../../../../../legacy/mobileapp/src/utils'
import AsyncStorage from '@react-native-async-storage/async-storage'

export default function AngelDescriptionScreen() {
  const { navigate } = useRouter()
  const { t } = useTranslation()
  const alert = useAlert()

  const { data: user } = useGetUserQuery()
  const [updateProfile, updateProfileResponse] = useUpdateProfileMutation()

  const [angelDescription, setAngelDescription] = useState(user?.angel?.short_bio)

  const complete = async () => {
    if (!angelDescription || angelDescription?.length < 200) {
      alert.show(t('error'), t('angelDescription.error'))
      return
    }

    try {
      await updateProfile({ short_bio: angelDescription.trim() }).unwrap()
      navigate('SignUpAngelMakePicture')
      return
    } catch (error) {
      alert.show(t('error'), (error as any)?.data?.message)
    }
  }

  useEffect(() => {
    if (user) {
      const step = getAngelOnboardingProgress('AngelDescriptionScreen')
      AsyncStorage.setItem(`onboarding_${user.id}`, JSON.stringify(step))
    }
  }, [user])

  const serviceType = ServiceGroupType.CHILD_CARE

  return (
    <ScreenLayout
      title={t(`angelDescription.title.${serviceType}`)}
      progress={`${45}%`}
      hideBackButton
      _buttonProps={{
        onPress: () => complete(),
        isDisabled: !angelDescription || angelDescription?.length < 200,
        isLoading: updateProfileResponse.isLoading,
        children: t('next')
      }}
    >
      <Card>
        <Heading mb="20px" fontSize="18px" fontWeight={600}>
          {t(`angelDescription.title.${serviceType}`)}
        </Heading>
        <Text fontSize="15px">{t(`angelDescription.subtitle.${serviceType}`)}</Text>

        <Box w="100%" flexDir="row" justifyContent="space-between" alignItems="center" mt="32px" mb="16px">
          <Heading fontSize="14px" fontWeight={400}>
            {t('description')}
          </Heading>
          <Text fontSize="12px" color="primary.400">
            {t('required')}
          </Text>
        </Box>

        <TextInput
          minHeight="90px"
          variant="filled"
          value={angelDescription}
          onChangeText={text => setAngelDescription(text)}
          placeholder={t(`angelDescription.placeholder.${serviceType}`)}
          multiline
          numberOfLines={5}
          maxLength={500}
          returnKeyType="default"
          onSubmitEditing={() => Keyboard.dismiss()}
        />
      </Card>
    </ScreenLayout>
  )
}

AngelDescriptionScreen.navigationOptions = {
  headerShown: false,
  tabBarVisible: false
}
