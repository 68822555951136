import React from 'react'
import { Box, Heading, HStack, IHeadingProps, Text, VStack } from 'native-base'
import { CharlyIcon } from '@charlycares/ui'
import { CategoryRating } from '@charlycares/shared'
import { useTranslation } from 'react-i18next'

type Props = {
  averageRating: number
  title: string
  reviewsCount: number
  ratings: CategoryRating[]
  _title?: IHeadingProps
}

const RatingsOverview = ({ averageRating, title, reviewsCount, ratings, _title }: Props) => {
  const { t } = useTranslation()
  return (
    <Box>
      <Box flexDir={'row'} w="100%" justifyContent="space-between" alignItems="center">
        <Heading flex={1} fontSize={18} {..._title}>
          {title}
        </Heading>
        <HStack alignItems="center" space="5px">
          <Text fontSize={'15px'} fontWeight={600}>
            {averageRating?.toFixed(2)}
          </Text>
          <CharlyIcon name="star" size={15} />
          <Text ml="5px" fontSize={'12px'}>
            ({reviewsCount})
          </Text>
        </HStack>
      </Box>

      <VStack mt={ratings.length ? '20px' : 0} space="15px">
        {ratings.map(rating => (
          <Box flexDir="row" w="100%" justifyContent="space-between" alignItems="center" key={rating.category}>
            <Text fontSize={15}>{t(`review_${rating.category}`)}</Text>
            <HStack alignItems="center" space="5px">
              {Array.from({ length: 5 }).map((_, i) => (
                <CharlyIcon key={i} name="star" size={12} color={rating.rating >= i + 1 ? 'gray.800' : 'gray.300'} />
              ))}
            </HStack>
          </Box>
        ))}
      </VStack>
    </Box>
  )
}

export default RatingsOverview
