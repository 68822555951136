import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components/native';

const Container = styled.View`
  padding: 10px;
  flex: 1;
`;

const Loader = () => (
  <Container>
    <ContentLoader height={100} width="100%" speed={2} color="#f3f3f3">
      <rect x="70%" y="20" rx="0" ry="0" width="25%" height="20" />

      <rect x="20" y="20" rx="0" ry="0" width="25%" height="30" />
    </ContentLoader>

    {[...Array(6)].map((_, i) => (
      <ContentLoader
        height={100}
        width="100%"
        speed={2}
        color="#f3f3f3"
        key={i.toString()}
      >
        <rect x="20" y="29.05" rx="0" ry="0" width="30%" height="15" />

        <rect x="20" y="56.22" rx="0" ry="0" width="20%" height="9" />

        <rect x="20" y="76.22" rx="0" ry="0" width="25%" height="9" />

        <rect x="75%" y="60.05" rx="0" ry="0" width="20%" height="10" />

        <rect x="85%" y="75.05" rx="0" ry="0" width="10%" height="10" />
      </ContentLoader>
    ))}
  </Container>
);

export default Loader;
