import { InputToolbar } from 'react-native-gifted-chat';
import styled from 'styled-components/native';

const StyledInputToolbar = styled(InputToolbar).attrs({
  primaryStyle: {
    flexDirection: 'column',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '10px',
    minHeight: '98px'
  }
})``;

export default StyledInputToolbar;
