import React from 'react'
import { Avatar, Box, Column, Factory, Heading, HStack, IBoxProps, Text } from 'native-base'
import { TouchableOpacity } from 'react-native'

import { useTranslation } from 'react-i18next'
import { MixedTransaction } from '../../../../../shared/src/types/Transaction'
import moment from 'moment'
import { SvgUri } from '@charlycares/ui'

const Touchable = Factory(TouchableOpacity) as any

type Props = IBoxProps & {
  transaction: MixedTransaction
  onPress?: (transaction: MixedTransaction) => void
}

const PaymentCard = ({ transaction, onPress, ...boxProps }: Props) => {
  const { t } = useTranslation()

  return (
    <Touchable
      onPress={() => {
        onPress && onPress(transaction)
      }}
      flexDir="row"
      w="100%"
      p="20px"
      bg="white"
      borderTopWidth={0}
      borderBottomWidth={1}
      borderColor="gray.200"
      {...boxProps}
    >
      <Box flex={1} mr="10px">
        <HStack alignItems="center" space="10px">
          {transaction?.image && transaction.image.includes('.svg') ? (
            <SvgUri uri={transaction.image} width={80} height={36} />
          ) : (
            <Avatar borderWidth={1} borderColor="gray.400" size="40px" source={{ uri: transaction.image }} />
          )}
          <Column space="0.5">
            <Heading fontSize="16px" fontWeight="600" numberOfLines={1} ellipsizeMode="tail">
              {transaction.description}
            </Heading>
            <Text fontSize="15px" color="gray.800">
              {moment.unix(transaction.created_at).format('ll HH:mm')}
            </Text>
          </Column>
        </HStack>
      </Box>

      <Box justifyContent="center" alignItems="flex-end">
        <HStack space="4px" alignItems="center">
          <Text fontSize="16px">{transaction.amount}</Text>
        </HStack>

        <Text fontSize="12px">{t(`walletTransactionStatus.${transaction.status}`)}</Text>
      </Box>
    </Touchable>
  )
}

export default PaymentCard
