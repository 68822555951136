import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { updateMembership } from '../../../../stores/actions/userAction';
import {
  TextTextView,
  IconButton,
  TextButton,
  MembershipEdit,
  Header
} from '../../../../components';
import { isWeb, getFormatedDate } from '../../../../utils/functions';
import colors from '../../../../styles/colors';

import leftArrowIcon from '../../../../../assets/icons/icn-nav-left.png';

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  justify-content: space-between;
`;
const ScrollView = styled.ScrollView``;
const ButtonView = styled.View`
  padding: 15px 20px 15px 20px;
  border-top-width: 1px;
  border-color: ${colors.grey08};
`;

const Details = ({ location, history, route, navigation, membership, saveMembership }) => {
  const { mode } = route.params || location.state || {};
  const { current_state = 'flexible', previous_state, valid_until, payment_term = 'monthly' } =
    membership || {};
  const isCanceled = current_state === 'canceled';
  const [editable, setEditable] = useState(mode === 'edit');
  const [selectedPlan, setSelectedPlan] = useState(isCanceled ? previous_state : current_state);
  const [selectedTerm, setSelectedTerm] = useState(payment_term);
  const [t] = useTranslation();
  const isFlexible =
    selectedPlan === 'flexible' || selectedPlan === 'canceled' || selectedPlan === 'trial';
  const extendsOnDate = getFormatedDate(valid_until, 'DD-MM-YYYY');

  const saveEditMembership = () => {
    setEditable(false);
    saveMembership(selectedPlan, selectedTerm);
  };

  //   console.log(current_state);
  const cancelEditMembership = () => {
    setEditable(false);
    setSelectedPlan(current_state);
    setSelectedTerm(payment_term);
  };

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <TextTextView
          flexDirection="column"
          justifyContent="flex-start"
          firstText={t('profileScreensMembership')}
          firstTextFontFamily="Martel"
          firstTextFontWeight="600"
          firstTextFontSize="16px"
          secondText={`${t('profileScreensExtendsOn')} ${getFormatedDate(
            valid_until,
            'DD-MM-YYYY'
          )}`}
        />
      ),
      headerRight: !isCanceled && (
        <TextButton
          textColor={colors.secondary}
          text={editable ? t('save') : t('edit')}
          fontWeight="normal"
          onPress={() => {
            if (editable) {
              saveEditMembership();
            } else {
              setEditable(true);
            }
          }}
        />
      ),
      headerLeft: !editable ? (
        <IconButton
          iconSource={leftArrowIcon}
          fontWeight="normal"
          iconSize={32}
          iconColor={colors.secondary}
          onPress={() => navigation.goBack()}
        />
      ) : (
        <TextButton
          text={t('cancel')}
          fontWeight="normal"
          textColor={colors.secondary}
          onPress={cancelEditMembership}
        />
      )
    });
  }

  // componentDidUpdate(prevProps) {
  //   const { updatingMembership } = this.props;
  //   if (prevProps.updatingMembership && !updatingMembership) {
  //     Alert.alert(
  //       strings.profileScreensMembershipUpdated,
  //       '',
  //       [{ text: strings.ok, onPress: () => this.setState({ editable: false }) }],
  //       { cancelable: false }
  //     );
  //   }
  // }

  return (
    <SafeAreaView>
      {isWeb && (
        <Header
          left={
            !editable ? (
              <IconButton
                iconMargins="0px 20px 0px 0px"
                iconSource={leftArrowIcon}
                iconSize="32px"
                iconColor={colors.secondary}
                onPress={() => {
                  if (navigation) {
                    navigation.goBack();
                  } else {
                    history.goBack();
                  }
                }}
              />
            ) : (
              <TextButton
                margins="0px 20px 0px 0px"
                text={t('cancel')}
                fontWeight="normal"
                textColor={colors.secondary}
                onPress={cancelEditMembership}
              />
            )
          }
          title={t('profileScreensMembership')}
          subtitle={`${t('profileScreensExtendsOn')} ${getFormatedDate(valid_until, 'DD-MM-YYYY')}`}
          right={
            !isCanceled && (
              <TextButton
                textColor={colors.secondary}
                text={editable ? t('save') : t('edit')}
                fontWeight="normal"
                onPress={() => {
                  if (editable) {
                    saveEditMembership();
                  } else {
                    setEditable(true);
                  }
                }}
              />
            )
          }
        />
      )}

      <ScrollView>
        {selectedPlan === 'trial' && !isCanceled && (
          <TextTextView
            flexDirection="column"
            justifyContent="center"
            paddings="20px"
            backgroundColor={colors.primaryAlpha80}
            firstText={t('profileScreensFreeTrial')}
            firstTextFontFamily="Martel"
            firstTextFontSize="18px"
            firstTextFontWeight="600"
            firstTextAlign="center"
            secondText={t('profileScreensFreeTrialDescription', { 0: extendsOnDate })}
            secondTextFontSize="12px"
            secondTextAlign="center"
          />
        )}

        <MembershipEdit
          editable={editable || isCanceled}
          selectedPlan={selectedPlan}
          onSelectPlanPress={setSelectedPlan}
          isFlexible={isFlexible}
          selectedTerm={selectedTerm}
          onSelectTermPress={setSelectedTerm}
        />
      </ScrollView>

      {isCanceled && (
        <ButtonView>
          <TextButton
            backgroundColor={colors.primary}
            text={t('profileScreensActivateMembership')}
            fontSize="16px"
            fontWeight="600"
            onPress={() => {
              saveEditMembership(
                selectedPlan === 'canceled' ? 'flexible' : selectedPlan,
                selectedTerm
              );
              history.push('/profile-membership-success');
            }}
          />
        </ButtonView>
      )}
    </SafeAreaView>
  );
};

Details.propTypes = {
  location: PropTypes.shape({ state: PropTypes.shape({ mode: PropTypes.string }) }),
  history: PropTypes.shape({ goBack: PropTypes.func, push: PropTypes.func }),
  route: PropTypes.shape({ params: PropTypes.shape({ mode: PropTypes.string }) }),
  navigation: PropTypes.shape({
    goBack: PropTypes.func,
    setOptions: PropTypes.func,
    navigate: PropTypes.func
  }),
  membership: PropTypes.shape({}),
  saveMembership: PropTypes.func.isRequired
};

Details.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null,
  membership: {}
};

const mapStateToProps = ({ User }) => ({
  membership: User.user.membership,
  updatingMembership: User.updatingMembership
});

const mapDispatchToProps = {
  saveMembership: updateMembership
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
