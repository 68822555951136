import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { TextIconButton, colors } from '@charlycares/legacy/web'

import discoverIcon from '../../../assets/icons/icn-search.png'
import chatIcon from '../../../assets/icons/tabbar-icn-conversation.png'
import profileIcon from '../../../assets/icons/tabbar-icn-profile.png'

const Nav = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 5px 10px;
  width: 100%;
  background-color: white;
  border-top-width: 1px;
  border-color: ${colors.grey08};
`
const StyledTextIconButton = styled(TextIconButton)`
  flex-direction: column-reverse;
  padding: 0px;
  min-width: 50px;
  border-radius: 0;
`

const Tabs = ({ role }) => {
  const [t] = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const isAngel = role === 'angel'
  const bookingColor = location.pathname.includes('/booking') ? colors.secondary : colors.defaultBtnTextColor
  const chatColor = location.pathname.includes('/chat') ? colors.secondary : colors.defaultBtnTextColor
  const discoverColor =
    location.pathname.includes('/discover') || location.pathname.includes('/book-')
      ? colors.secondary
      : colors.defaultBtnTextColor
  const favoritesColor =
    location.pathname.includes('/favorites') || location.pathname.includes('/job-finder')
      ? colors.secondary
      : colors.defaultBtnTextColor
  const profileColor = location.pathname.includes('/profile') ? colors.secondary : colors.defaultBtnTextColor
  const hideNavBar =
    location.pathname === '/book-screening-info' ||
    location.pathname === '/book-explanation-features' ||
    location.pathname === '/support'

  return (
    !hideNavBar && (
      <Nav>
        <StyledTextIconButton
          text={t('bookingsTab')}
          textColor={bookingColor}
          fontSize="11px"
          iconName="icn-bookings"
          iconSize="32px"
          iconColor={bookingColor}
          onPress={() => history.replace('/bookings')}
        />

        <StyledTextIconButton
          text={t('chatTab')}
          textColor={chatColor}
          fontSize="11px"
          icon={chatIcon}
          iconSize="32px"
          iconColor={chatColor}
          onPress={() => history.replace('/chat')}
        />

        <StyledTextIconButton
          text={isAngel ? t('dashboardTab') : t('discoverTab')}
          textColor={discoverColor}
          fontSize="11px"
          icon={discoverIcon}
          iconSize="32px"
          iconColor={discoverColor}
          onPress={() => history.replace('/discover')}
        />
        <StyledTextIconButton
          text={isAngel ? t('jobFinderTab') : t('favoritesTab')}
          textColor={favoritesColor}
          fontSize="11px"
          iconName="favorites"
          iconSize="32px"
          iconColor={favoritesColor}
          onPress={() => history.replace(isAngel ? '/job-finder' : '/favorites')}
        />
        <StyledTextIconButton
          text={t('profileTab')}
          textColor={profileColor}
          fontSize="11px"
          icon={profileIcon}
          iconSize="32px"
          iconColor={profileColor}
          onPress={() => history.replace('/profile')}
        />
      </Nav>
    )
  )
}

Tabs.propTypes = {
  role: PropTypes.string.isRequired
}

export default Tabs
