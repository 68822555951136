import React from 'react'
import { api, useAppSelector, useRemovePushTokenMutation } from '@charlycares/data-access'
import { deleteDevicePushToken, getDevicePushToken, isDev, isWeb, StorageKeys, useRouter } from '@charlycares/shared'
import { useDispatch } from 'react-redux'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { QueryCacheKey } from '@reduxjs/toolkit/dist/query/core/apiState'

const useHandleLogout = () => {
  const dispatch = useDispatch()
  const { navigate } = useRouter()

  const subscriptions = useAppSelector(state => state.api.subscriptions)

  const [removePushToken] = useRemovePushTokenMutation()

  const onLogout = async () => {
    if (!isWeb) {
      // invalidate user push token
      try {
        const token = await getDevicePushToken()
        if (token) {
          await removePushToken({ token })
        }
      } catch (error) {
        isDev && console.warn(error)
      } finally {
        deleteDevicePushToken()
      }
    }

    await AsyncStorage.removeItem(StorageKeys.AUTH_TOKEN)
    navigate('BoardingScreenFinal', '/login')

    Object.keys(subscriptions).forEach((key: string) =>
      dispatch(api.internalActions.removeQueryResult({ queryCacheKey: key as QueryCacheKey }))
    )
    /**
     * @deprecated should be remove after we migrate to new state
     * @desc resets local user legacy state to initial value
     */
    dispatch({ type: 'logout' })
  }
  return { onLogout }
}

export default useHandleLogout
