import { Card } from '@charlycares/ui'
import { Avatar, HStack, Spacer, Text, VStack } from 'native-base'
import BookingCostSummary from '../../bookings/family/common/BookingCostSummary'
import { formatInterval, formatMoney, UserRoles } from '@charlycares/shared'
import { BookingWalletTransaction, useGetUserQuery } from '@charlycares/data-access'
import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

interface Props {
  bookingWalletTransaction: BookingWalletTransaction
}

export default function BookingPaymentScreen({ bookingWalletTransaction }: Props) {
  const { t } = useTranslation()
  const { data: user } = useGetUserQuery()
  const role = user?.role || UserRoles.FAMILY

  const startsAt = moment(bookingWalletTransaction.starts_at)
  const endsAt = moment(bookingWalletTransaction.ends_at)

  return (
    <Card>
      <HStack alignItems="center" w="100%" pb="10px" borderColor="gray.200" borderBottomWidth="1">
        <Avatar
          source={{
            uri:
              role === UserRoles.FAMILY ? bookingWalletTransaction.angel.image : bookingWalletTransaction.family.image
          }}
          borderWidth="1px"
          borderColor="gray.200"
        />
        <Text fontSize="16px" ml="10px">
          {role === UserRoles.FAMILY && bookingWalletTransaction.angel.first_name}
          {role === UserRoles.ANGEL && bookingWalletTransaction.family.last_name}
        </Text>
      </HStack>
      <HStack alignItems="center" py="10px" w="100%" borderColor="gray.200" borderBottomWidth="1">
        <Text fontWeight="600" fontSize="16px">
          {t('endBooking.start')}
        </Text>
        <Spacer />
        <HStack fontSize="16px" alignItems="center">
          <Text color="primary.400">{startsAt.format('ll HH:mm')}</Text>
        </HStack>
      </HStack>
      <HStack alignItems="center" py="10px" w="100%" borderColor="gray.200" borderBottomWidth="1">
        <Text fontWeight="600" fontSize="16px">
          {t('endBooking.end')}
        </Text>
        <Spacer />
        <HStack fontSize="16px" alignItems="center">
          <Text color="primary.400">{endsAt.format('ll HH:mm')}</Text>
        </HStack>
      </HStack>
      <BookingCostSummary
        dayRatePerHour={bookingWalletTransaction.day_rate_per_hour}
        nightRatePerHour={bookingWalletTransaction.night_rate_per_hour}
        insuranceFeePerHour={bookingWalletTransaction.insurance_fee_per_hour}
        bookingRate={bookingWalletTransaction.booking_rate}
        role={role}
        isLoading={false}
      />

      <HStack alignItems="center" py="10px" w="100%" borderColor="gray.200" borderTopWidth="1">
        <Text fontWeight="600" fontSize="16px">
          {t('endBooking.tip')}
        </Text>
        <Spacer />
        <Text color="primary.400">
          {bookingWalletTransaction.booking_rate?.tip.amount !== 0
            ? formatMoney(bookingWalletTransaction.booking_rate?.tip)
            : t('bookingPaymentSummary.noTip')}
        </Text>
      </HStack>

      {role === UserRoles.FAMILY && bookingWalletTransaction.company_costs && (
        <HStack alignItems="center" py="10px" w="100%" borderColor="gray.200" borderTopWidth="1">
          <Text fontSize="12px">{t('endBooking.companyPays')}</Text>
          <Spacer />
          <Text>{formatMoney(bookingWalletTransaction.company_costs)}</Text>
        </HStack>
      )}
      {role === UserRoles.FAMILY && bookingWalletTransaction.paid_from_wallet && (
        <HStack alignItems="center" py="10px" w="100%" borderColor="gray.200" borderTopWidth="1">
          <Text fontSize="12px">Betaald uit wallet</Text>
          <Spacer />
          <Text>{formatMoney(bookingWalletTransaction.paid_from_wallet)}</Text>
        </HStack>
      )}

      <HStack alignItems="center" py="10px" w="100%" borderColor="gray.200" borderTopWidth="1">
        <VStack>
          <Text fontWeight="600" fontSize="16px">
            {t('endBooking.total')}
          </Text>
          <Text>{formatInterval(bookingWalletTransaction.booking_rate.duration.total)}</Text>
        </VStack>
        <Spacer />
        <Text fontWeight="600" fontSize="22px">
          {role === UserRoles.FAMILY && formatMoney(bookingWalletTransaction.total_costs)}
          {role === UserRoles.ANGEL && formatMoney(bookingWalletTransaction.booking_rate.angel_payout)}
        </Text>
      </HStack>

      {role === UserRoles.FAMILY && (
        <>
          <HStack alignItems="center" w="100%">
            <VStack>
              <Text color="gray.800" fontSize="12px">
                {t('endBooking.angelPayout')}
              </Text>
            </VStack>
            <Spacer />
            <Text color="gray.800" fontSize="12px">
              {formatMoney(bookingWalletTransaction.booking_rate.angel_payout)}
            </Text>
          </HStack>

          {bookingWalletTransaction?.transactions &&
            bookingWalletTransaction.transactions.map(function (transaction) {
              return (
                <HStack alignItems="center" w="100%">
                  <VStack>
                    <Text color="gray.800" fontSize="12px">
                      {t('endBooking.method.' + transaction.payment_method)}
                    </Text>
                  </VStack>
                  <Spacer />
                  <Text color="gray.800" fontSize="12px">
                    {formatMoney(transaction.amount, true)}
                  </Text>
                </HStack>
              )
            })}
          {bookingWalletTransaction.vat && (
            <HStack alignItems="center" w="100%">
              <VStack>
                <Text color="gray.800" fontSize="12px">
                  {t('endBooking.vat')}
                </Text>
              </VStack>
              <Spacer />
              <Text color="gray.800" fontSize="12px">
                {formatMoney(bookingWalletTransaction.vat, true)}
              </Text>
            </HStack>
          )}
        </>
      )}
    </Card>
  )
}
