import { Platform } from 'react-native'

export const openSans = {
  normal: Platform.select({
    native: 'OpenSans-Regular',
    web: 'Open Sans'
  }) as string,
  bold: Platform.select({
    native: 'OpenSans-Bold',
    web: 'Open Sans'
  }) as string,
  light: Platform.select({
    native: 'OpenSans-Light',
    web: 'Open Sans'
  }) as string,
  semiBold: Platform.select({
    native: 'OpenSans-SemiBold',
    web: 'Open Sans'
  }) as string,
  extraBold: Platform.select({
    native: 'OpenSans-ExtraBold',
    web: 'Open Sans'
  }) as string,
  italic: Platform.select({
    native: 'OpenSans-Italic',
    web: 'Open Sans'
  }) as string,
  italicLight: Platform.select({
    native: 'OpenSans-LightItalic',
    web: 'Open Sans'
  }) as string
}

const martel = {
  normal: Platform.select({
    native: 'Martel',
    web: 'Martel'
  }) as string,
  bold: Platform.select({
    native: 'Martel-Bold',
    web: 'Martel'
  }) as string,
  light: Platform.select({
    native: 'Martel-Light',
    web: 'Martel'
  }) as string,
  semiBold: Platform.select({
    native: 'Martel-DemiBold',
    web: 'Martel'
  }) as string,
  extraBold: Platform.select({
    native: 'Martel-ExtraBold',
    web: 'Martel'
  }) as string
}

export const fontWeights = {
  300: 'light',
  400: 'normal',
  600: 'semiBold',
  700: 'bold',
  800: 'extraBold',
  italic: 'italic'
}

export const fonts = {
  openSans,
  martel
}

export type Fonts = typeof fonts
