import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import FamilyDetails from './FamilyDetails';
import { TextTextView, TextIconView } from '../Texts';
import { TextButton } from '../Buttons';
import { roundMonetaryValue } from '../../utils/functions';
import colors from '../../styles/colors';

import dayIcon from '../../../assets/icons/icn-day.png';
import nightIcon from '../../../assets/icons/icn-night.png';
import fixedIcon from '../../../assets/icons/icn-repeat.png';

const View = styled.View`
  margin: 15px 20px 15px 20px;
`;
const AngelDetails = styled.View`
  flex-direction: row;
  padding: 15px;
  margin-bottom: 0px;
  border-top-width: 1px;
  border-bottom-width: ${({ borderBottomWidth }) => borderBottomWidth};
  border-color: ${colors.grey08};
`;
const Rates = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 20px 0px 0px;
  height: auto;
  width: 60%;
  border-right-width: 1px;
  border-color: ${colors.grey08};
`;

const BookingInvitationDetails = ({
  angelNormalRate,
  angelExtraRate,
  angelFixedRate,
  expectedErnings,
  onReadPersonalMessagePress,
  isFixedBooking,
  hasMessage,
  ...familyDetails
}) => {
  const [t] = useTranslation();
  const normalRate = roundMonetaryValue(angelNormalRate);
  const extraRate = roundMonetaryValue(angelExtraRate);
  const fixedRate = roundMonetaryValue(angelFixedRate);
  const earnings = roundMonetaryValue(expectedErnings);

  return (
    <View>
      <FamilyDetails {...familyDetails} />

      <AngelDetails borderBottomWidth={hasMessage ? '1px' : 0}>
        <Rates>
          <TextIconView
            flexDirection="column-reverse"
            backgroundColor="transparent"
            text={`€ ${normalRate}`}
            fontSize="14px"
            icon={dayIcon}
            iconSize="32px"
            iconColor={isFixedBooking ? colors.grey07 : colors.grey01}
            textColor={isFixedBooking ? colors.grey07 : colors.primaryText}
          />

          <TextIconView
            margins="0px 20px 0px 20px"
            flexDirection="column-reverse"
            backgroundColor="transparent"
            text={`€ ${extraRate}`}
            fontSize="14px"
            icon={nightIcon}
            iconSize="32px"
            iconColor={isFixedBooking ? colors.grey07 : colors.grey01}
            textColor={isFixedBooking ? colors.grey07 : colors.primaryText}
          />

          <TextIconView
            flexDirection="column-reverse"
            backgroundColor="transparent"
            text={`€ ${fixedRate}`}
            fontSize="14px"
            icon={fixedIcon}
            iconSize="32px"
            iconColor={isFixedBooking ? colors.grey01 : colors.grey07}
            textColor={isFixedBooking ? colors.grey01 : colors.grey07}
          />
        </Rates>

        <TextTextView
          paddings="0px 0px 0px 10px"
          flexDirection="column"
          algnItems="center"
          backgroundColor="transparent"
          firstText={t('bookingsScreenExpectedEarnings')}
          firstTextFontSize="13px"
          secondText={`€ ${earnings}`}
          secondTextFontSize="24px"
        />
      </AngelDetails>

      {hasMessage && (
        <TextButton
          text={t('bookingsScreenReadPersonalMessage')}
          fontSize="14px"
          margins="5px 0px 0px 0px"
          textColor={colors.secondary}
          onPress={onReadPersonalMessagePress}
        />
      )}
    </View>
  );
};

BookingInvitationDetails.propTypes = {
  angelNormalRate: PropTypes.string,
  angelExtraRate: PropTypes.string,
  angelFixedRate: PropTypes.string,
  distance: PropTypes.number.isRequired,
  expectedErnings: PropTypes.number.isRequired,
  onReadPersonalMessagePress: PropTypes.func.isRequired,
  hasMessage: PropTypes.bool.isRequired,
  isFixedBooking: PropTypes.bool.isRequired
};

BookingInvitationDetails.defaultProps = {
  angelExtraRate: '0',
  angelNormalRate: '0',
  angelFixedRate: '0'
};

export default BookingInvitationDetails;
