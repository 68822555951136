import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DatePicker, { registerLocale } from 'react-datepicker';
import { createPortal } from 'react-dom';
import nl from 'date-fns/locale/nl';
import en from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';

import colors from '../../styles/colors';

registerLocale('nl_NL', nl);
registerLocale('en_GB', en);

const StyledInput = styled.div`
  color: ${(p) => p.color || colors.secondary};
`;
const Button = styled.div`
  cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')};
`;

const Input = React.forwardRef(
  ({ hidden, value, onClick, labelValue, style, color, disabled }, ref) => {
    return (
      <Button
        hidden={hidden}
        ref={ref}
        value={value}
        onClick={onClick}
        aria-hidden="true"
        disabled={disabled}
      >
        <StyledInput color={color} style={style}>
          {labelValue}
        </StyledInput>
      </Button>
    );
  }
);

Input.propTypes = {
  hidden: PropTypes.bool,
  value: PropTypes.string,
  onClick: PropTypes.func,
  labelValue: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  hidden: false,
  value: '',
  onClick: () => {},
  labelValue: '',
  style: undefined,
  color: colors.secondary,
  disabled: false,
};

const DatePickerWeb = React.forwardRef(
  (
    {
      value,
      onValueChange,
      labelValue,
      inputHidden,
      inputStyle,
      defaultInput,
      disabled,
      ...props
    },
    ref
  ) => {
    const { i18n } = useTranslation();

    return (
      <DatePicker
        locale={i18n.language}
        ref={ref}
        showTimeSelect
        selected={value}
        onChange={onValueChange}
        disabled={disabled}
        {...props}
        popperContainer={({ children }) =>
          createPortal(children, document.body)
        }
        customInput={
          defaultInput ? undefined : (
            <Input
              labelValue={labelValue}
              hidden={inputHidden}
              color={disabled ? colors.grey03 : colors.secondary}
              style={inputStyle}
              disabled={disabled}
            />
          )
        }
      />
    );
  }
);

DatePickerWeb.propTypes = {
  inputHidden: PropTypes.bool,
  value: PropTypes.shape({}),
  labelValue: PropTypes.string.isRequired,
  inputStyle: PropTypes.shape({}),
  onValueChange: PropTypes.func,
  defaultInput: PropTypes.bool,
  disabled: PropTypes.bool,
};

DatePickerWeb.defaultProps = {
  inputStyle: undefined,
  inputHidden: false,
  value: {},
  onValueChange: () => {},
  defaultInput: false,
  disabled: false,
};

export default DatePickerWeb;
