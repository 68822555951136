import axiosInstance from '../../utils/axios';
import {
  FETCHING_FAVORITES,
  GET_FAVORITES,
  GET_NEXT_FAVORITES,
  GET_SHARE_ANGEL_DETAILS,
  GET_SHARE_ANGEL_RATINGS,
  GET_FAMILY_DETAILS,
  FAVORITES_TOGGLE_LIKE,
  SERVER_ERROR,
} from '../types';

const logError = (error, dispatch) => {
  if (error.response && error.response.status === 500) {
    dispatch({ type: SERVER_ERROR });
  }
};

export const getFavorites = (sort) => (dispatch) => {
  dispatch({ type: FETCHING_FAVORITES });
  axiosInstance
    .get(`favorites/10?sort_by=${sort}`)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_FAVORITES,
          payload: data.data,
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};

export const getNextFavorites = (nextUrlPage) => (dispatch) => {
  axiosInstance
    .get(nextUrlPage)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_NEXT_FAVORITES,
          payload: data.data,
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};

export const getFamilyDetails = (id) => (dispatch) => {
  axiosInstance
    .get(`family/${id}`)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_FAMILY_DETAILS,
          payload: data.data,
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};

export const getAngelPublicDetails = (angelId) => (dispatch) => {
  axiosInstance
    .post(`angel/profile/public/${angelId}`)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_SHARE_ANGEL_DETAILS,
          payload: data.data,
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};

export const getAngelRatingsFavorite = (angelId) => (dispatch) => {
  axiosInstance
    .get(`angel/ratings/${angelId}`)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_SHARE_ANGEL_RATINGS,
          payload: data.data,
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};

export const toggleLikeAngelFavorite = (angelId, like) => (dispatch) => {
  axiosInstance
    .post('angel/like', { angel_id: angelId, like })
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: FAVORITES_TOGGLE_LIKE,
          payload: { angelId, like },
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};
