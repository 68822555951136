import React, { useEffect, useState } from 'react'
import { Card, NumberInput, ScreenLayout, TextInput } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import {
  useCalculatePotentialEarningsMutation,
  useGetBookingDetailsQuery,
  useGetUserQuery,
  useSendOfferMutation
} from '@charlycares/data-access'
import {
  BookingDate,
  BookingServiceType,
  flattenBookingDates,
  formatMoney,
  ServiceGroupType,
  useRouter
} from '@charlycares/shared'
import { Box, Flex, Heading, Text } from 'native-base'
import { BookingFamilyDetails } from '../family'
import { AddressDetails } from '../../profile'
import { BookingDates } from './common'
import { isSingleBooking } from '../common'
import { useAlert } from '../../../hooks'
import BookingMessage from '../family/common/BookingMessage'
import BookingRatesDetails from '../family/common/BookingRatesDetails'
import BookingServiceTypeInfo from '../common/BookingServiceTypeInfo'
import { SingleBookingDates } from '../common'

const CreateOfferScreen = () => {
  const { t } = useTranslation()
  const { getParam, navigate } = useRouter()
  const alert = useAlert()

  const [dayRateAmount, setDayRateAmount] = useState(0)
  const [nightRateAmount, setNightRateAmount] = useState(0)
  const [travelCost, setTravelCost] = useState(0)
  const [expectedEarningsAmount, setExpectedEarningsAmount] = useState(0)
  const [angelMessage, setAngelMessage] = useState('')
  const [selectedDates, setSelectedDates] = useState<Omit<BookingDate, 'repeat_days'>[]>([])

  const obscured_id = getParam('obscuredId') as string

  const { data: user } = useGetUserQuery()
  const { data: booking } = useGetBookingDetailsQuery(
    { obscured_id, isOffer: true },
    {
      skip: !obscured_id
    }
  )

  const [sendOffer, sendOfferState] = useSendOfferMutation()
  const [calculatePotentialEarnings, { data: potentialEarnings }] = useCalculatePotentialEarningsMutation()

  const sendOfferDisabled = (travelCost !== booking?.travel_allowance.amount && !angelMessage) || !selectedDates.length

  useEffect(() => {
    if (booking) {
      setTravelCost(booking.travel_allowance.amount)
      setDayRateAmount(booking.day_rate_per_hour.amount)
      setNightRateAmount(booking.night_rate_per_hour.amount)
      setExpectedEarningsAmount(booking.expected_earnings.amount)
      setSelectedDates(flattenBookingDates(booking.booking_dates))
    }
  }, [booking])

  useEffect(() => {
    if (sendOfferState.isError) {
      const error = sendOfferState.error as { data: { error: string } }

      if (sendOfferState.error && error.data && error.data.error) {
        alert.show(t(`offerErrorTitle.${error.data.error}`), t(`offerErrorMessage.${error.data.error}`))
        return
      }

      alert.show(t('error'), t('createOfferError'))
    }
  }, [sendOfferState.isError, t])

  useEffect(() => {
    if (sendOfferState.isSuccess) {
      navigate('OfferCreated', '/offer-created')
    }
  }, [sendOfferState.isSuccess, navigate])

  useEffect(() => {
    if (potentialEarnings) {
      setExpectedEarningsAmount(potentialEarnings.amount)
    }
  }, [potentialEarnings])

  useEffect(() => {
    if (!booking) return

    const getData = setTimeout(() => {
      calculatePotentialEarnings({
        booking_obscured_id: booking.obscured_id,
        travel_allowance: {
          amount: travelCost,
          currency: 'EUR'
        },
        day_rate: {
          amount: dayRateAmount,
          currency: 'EUR'
        },
        night_rate: {
          amount: nightRateAmount,
          currency: 'EUR'
        }
      })
    }, 2000)

    return () => clearTimeout(getData)
  }, [booking, travelCost, dayRateAmount, nightRateAmount, calculatePotentialEarnings])

  const confirmSendingOffer = () => {
    if (booking) {
      alert.show(t('confirmSendingOfferTitle'), t('confirmSendingOfferMessage', { familyName: booking.family.name }), [
        { text: t('cancel') },
        { text: t('confirmSendingOfferBtn'), isPreferred: true, onPress: onSendOffer }
      ])
    }
  }

  const onSendOffer = () => {
    if (booking) {
      sendOffer({
        booking_id: booking.obscured_id,
        selected_bookings: selectedDates.map(date => date.obscured_id),
        travel_allowance: {
          amount: travelCost,
          currency: 'EUR'
        },
        day_rate: {
          amount: dayRateAmount,
          currency: 'EUR'
        },
        night_rate: {
          amount: nightRateAmount,
          currency: 'EUR'
        },
        angel_message: angelMessage
      })
    }
  }

  if (!booking || !user?.angel) {
    return <ScreenLayout isLoading title={t('booking')} />
  }

  return (
    <ScreenLayout
      _buttonProps={{
        isDisabled: sendOfferDisabled,
        onPress: confirmSendingOffer,
        children: isSingleBooking(booking)
          ? t('sendOffer')
          : `${selectedDates.length} ${t('of')} ${flattenBookingDates(booking.booking_dates).length} | ${t(
              'sendOffer'
            )}`,
        isLoading: sendOfferState.isLoading
      }}
      title={t('booking')}
    >
      {booking.service_type &&
        [BookingServiceType.MEET_AND_GREET].includes(
          booking.service_type
        ) && <BookingServiceTypeInfo bookingServiceType={booking.service_type} />}

      {booking.service_group_type &&
        [ServiceGroupType.ELDERLY_CARE, ServiceGroupType.PET_CARE].includes(
          booking.service_group_type
        ) && <BookingServiceTypeInfo serviceGroupType={booking.service_group_type} />}

      <BookingFamilyDetails mt="10px" borderBottomWidth={0} family={{ ...booking, ...booking.family }} />

      <BookingRatesDetails
        mt="0"
        mb="0"
        borderWidth={0}
        dayRate={{ amount: dayRateAmount, currency: 'EUR' }}
        nightRate={{ amount: nightRateAmount, currency: 'EUR' }}
        maxRate={booking.max_hourly_rate}
        expectedEarnings={{ amount: expectedEarningsAmount, currency: 'EUR' }}
        travelAllowance={{ amount: travelCost, currency: 'EUR' }}
        showAdjustedRate={true}
        showRateWarning={
          user.angel.day_rate_per_hour.amount > booking.max_hourly_rate.amount ||
          user.angel.night_rate_per_hour.amount > booking.max_hourly_rate.amount
        }
        showPetCareRateWarning={false}
        onRateAmountChange={(value: number) => {
          setDayRateAmount(booking.day_rate_per_hour.amount + value)
          setNightRateAmount(booking.night_rate_per_hour.amount + value)
        }}
      />

      {booking.personal_message !== '' && <BookingMessage mt="0" mb="0" booking={booking} />}

      {isSingleBooking(booking) ? (
        <SingleBookingDates startsAt={booking.start_date} endsAt={booking.end_date} />
      ) : (
        booking?.booking_dates && (
          <BookingDates
            mt="10px"
            bookings={booking.booking_dates}
            selectedDates={selectedDates}
            onChange={setSelectedDates}
          />
        )
      )}

      <Card mt="10px">
        <Flex direction="row" align="center">
          <Heading mr="10px" fontSize="22px" fontWeight={600} flex={1}>
            {t('travelCost')}
          </Heading>
          <NumberInput
            _text={{
              fontSize: '18px',
              fontWeight: 600,
              color: travelCost === booking?.travel_allowance.amount ? 'gray.500' : 'text.dark.400'
            }}
            min={0}
            value={travelCost}
            changeRate={25}
            onChange={(value: number) => setTravelCost(value)}
            formatValue={val =>
              formatMoney({
                amount: val,
                currency: 'EUR'
              })
            }
          />
        </Flex>

        <Text mt="20px">{t('travelCostDescription')}</Text>

        <Box mt="30px">
          <Flex direction="row">
            <Text textTransform="capitalize">
              {travelCost !== booking?.travel_allowance.amount ? t('description') : t('message')}
            </Text>
            {travelCost !== booking?.travel_allowance.amount && (
              <Text ml="15px" color="primary.400">
                {t('required')}
              </Text>
            )}
          </Flex>

          <TextInput
            value={angelMessage}
            onChangeText={setAngelMessage}
            placeholder={
              travelCost !== booking?.travel_allowance.amount
                ? t('travelCostMessagePlaceholder')
                : t('angelMessagePlaceholder')
            }
            mt="10px"
            h="108px"
            variant="filled"
            multiline
            numberOfLines={5}
          />
        </Box>
      </Card>

      <AddressDetails
        mt="10px"
        distance={booking.distance}
        lat={booking.family.lat}
        lon={booking.family.lon}
        street_name={booking.family.street_name}
        city={booking.family.city}
      />
    </ScreenLayout>
  )
}

CreateOfferScreen.navigationOptions = {
  headerShown: false
}

export default CreateOfferScreen
