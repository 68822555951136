import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { Switch, Route, useLocation } from 'react-router-dom'
import { isBrowser } from 'react-device-detect'

import {
  FavoritesOverview,
  AngelDashboard,
  FamilyDashboard,
  SupportOverview,
  ChatInbox,
  ChatNotifications,
  SendImage,
  JobFinderOverview,
  JobFinderDetails,
  theme,
  showNavbar,
  SignupFamilyBankAccountSuccess,
  SignupFamilyBankAccountFailed
} from '@charlycares/legacy/web'
import { Loading } from '../screens'
import { ProtectedRoute, LeftBanner, RightBanner, Tabs } from '../components'
import OpenUrl from './OpenUrl'

import renderBoardingRoutes from './Boarding'
import renderBookingsRoutes from './Bookings'
import renderProfileRoutes from './Profile'
import renderBookRoutes from './Book'
import { AngelDetailsScreen, FavoriteAngelsScreen, InvitedScreen, ReviewsOverviewScreen } from '@charlycares/features'
import { getUserToken, useFetchTranslation } from '@charlycares/shared'
import { clearServerError } from '@charlycares/legacy/web'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { userApi } from '@charlycares/data-access'

const View = styled.View`
  display: flex;
  flex: 1 1 auto;
  align-self: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100dvh;
  background-color: ${theme.colors.white};
`
const BoardingView = styled.View`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: ${({ width }) => width};
  height: 100%;
  min-height: 100dvh;
  flex: 1 1 auto;
  max-width: 100%;
`
const App = styled.View`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-self: center;
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  height: 100%;
`
const ErrorView = styled.View`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.transparentWhite};
`
const ErrorMessage = styled.View`
  padding: 20px;
  width: 200px;
  border: 1px solid red;
  word-wrap: break-word;
  background-color: ${theme.colors.white};
`

const Main = ({ user, serverError, clearServerErrorData, getUserLegacy, getUser }) => {
  useFetchTranslation()
  const { role } = user || {}
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const navBarVisible = showNavbar(location)

  useEffect(() => {
    const autoLogin = async () => {
      try {
        const token = await getUserToken()

        if (token) {
          await getUserLegacy().unwrap()
          await getUser().unwrap()
        } else {
          throw new Error('no token')
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }

    autoLogin()
  }, [getUserLegacy, getUser])

  return (
    <App width="100%" maxWidth={isBrowser && navBarVisible ? '1400px' : '100%'}>
      <LeftBanner loading={loading} role={role} />

      <View accessibilityRole="main">
        <BoardingView width={'100%'}>
          <Switch>
            <Route
              exact
              path="/"
              component={props => <Loading {...props} loading={loading} setLoading={setLoading} />}
            />
            <Route
              key="invited"
              path="/invited"
              component={props => <InvitedScreen setLoading={setLoading} {...props} />}
            />
            <Route path="/open-url/:url/:fallback" render={props => <OpenUrl {...props} role={role} />} />
            {renderBoardingRoutes(user, setLoading).map(boardingRoute => boardingRoute)}
            {renderBookingsRoutes(user).map(bookingsRoute => bookingsRoute)}
            {renderProfileRoutes(user).map(profileRoute => profileRoute)}
            {renderBookRoutes(user).map(bookRoute => bookRoute)}
            <ProtectedRoute path="/favorites" user={user} component={FavoriteAngelsScreen} />
            <ProtectedRoute
              path="/discover"
              user={user}
              component={props => (role === 'angel' ? <AngelDashboard {...props} /> : <FamilyDashboard {...props} />)}
            />
            <ProtectedRoute
              key="verification/success"
              path="/verification/success"
              user={user}
              component={SignupFamilyBankAccountSuccess}
            />
            <ProtectedRoute
              key="verification/failed"
              path="/verification/failed"
              user={user}
              component={SignupFamilyBankAccountFailed}
            />
            <ProtectedRoute path="/chat" user={user} component={ChatInbox} />
            <ProtectedRoute path="/chat-notifications" user={user} component={ChatNotifications} />
            <ProtectedRoute path="/chat-send-image" user={user} component={SendImage} />
            <ProtectedRoute path="/job-finder" user={user} component={JobFinderOverview} />
            <ProtectedRoute path="/job-finder-details" user={user} component={JobFinderDetails} />
            <ProtectedRoute path="/booking-angel-profile" user={user} component={AngelDetailsScreen} />
            <ProtectedRoute
              key="view-reviews"
              path="/booking-view-reviews"
              user={user}
              component={props => <ReviewsOverviewScreen {...props} />}
            />
            <ProtectedRoute path="/support" user={user} component={SupportOverview} />
          </Switch>

          {!isBrowser && navBarVisible && <Tabs role={role} />}
        </BoardingView>
      </View>

      <RightBanner />

      {serverError && serverError.serverError && (
        <ErrorView onClick={() => clearServerErrorData()}>
          <ErrorMessage>{serverError && serverError.errorMessage}</ErrorMessage>
        </ErrorView>
      )}
    </App>
  )
}

Main.propTypes = {
  user: PropTypes.shape({ role: PropTypes.string }),
  serverError: PropTypes.shape({
    serverError: PropTypes.string,
    errorMessage: PropTypes.string
  }),
  clearServerErrorData: PropTypes.func.isRequired
}

Main.defaultProps = {
  user: null,
  serverError: null
}

const mapStateToProps = ({ User, ServerError }) => ({
  user: User.user,
  serverError: ServerError
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserLegacy: userApi.endpoints.getUserProfile.initiate,
      getUser: userApi.endpoints.getUser.initiate,
      clearServerErrorData: clearServerError
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
