import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import Slider, { Range } from 'rc-slider';
import { TextTextView } from '../Texts';

const View = styled.View`
  padding: ${({ paddings }) => paddings};
`;

const RangeSlider = ({
  paddings,
  range,
  leftText,
  rightText,
  firstTextFontFamily,
  firstTextFontSize,
  secondTextFontFamily,
  secondTextFontSize,
  secondTextFontWeight,
  minValue,
  maxValue,
  initialMin,
  initialMax,
  step,
  onValueChanged,
  ...sliderProps
}) => (
  <View paddings={paddings}>
    <TextTextView
      flexDirection="row"
      justifyContent="space-between"
      alignItems="stretch"
      paddings="10px 0px 10px 0px"
      width="100%"
      firstText={leftText}
      firstTextFontFamily={firstTextFontFamily}
      firstTextFontSize={firstTextFontSize}
      secondText={rightText}
      secondTextFontFamily={secondTextFontFamily}
      secondTextFontSize={secondTextFontSize}
      secondTextFontWeight={secondTextFontWeight}
    />

    {range ? (
      <Range
        step={step}
        min={minValue}
        max={maxValue}
        defaultValue={[initialMin, initialMax]}
        onValueChanged={onValueChanged}
        {...sliderProps}
      />
    ) : (
      <Slider
        step={step}
        min={minValue}
        max={maxValue}
        defaultValue={initialMin}
        onValueChanged={onValueChanged}
        {...sliderProps}
      />
    )}
  </View>
);

RangeSlider.propTypes = {
  paddings: PropTypes.string,
  range: PropTypes.bool,
  leftText: PropTypes.string.isRequired,
  rightText: PropTypes.string.isRequired,
  firstTextFontFamily: PropTypes.string,
  secondTextFontFamily: PropTypes.string,
  firstTextFontSize: PropTypes.number,
  secondTextFontSize: PropTypes.number,
  secondTextFontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  step: PropTypes.number,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  initialMin: PropTypes.number.isRequired,
  initialMax: PropTypes.number.isRequired,
  onValueChanged: PropTypes.func.isRequired
};

RangeSlider.defaultProps = {
  paddings: '20px 0px 0px 0px',
  range: false,
  step: 1,
  firstTextFontFamily: 'Open Sans',
  firstTextFontSize: '16px',
  secondTextFontFamily: 'Open Sans',
  secondTextFontSize: '16px',
  secondTextFontWeight: 'normal'
};

export default RangeSlider;
