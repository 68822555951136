import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
// import MapView, { Marker } from 'react-native-web-maps';
import Text from '../../elements/Text';
import Icon from '../../elements/Icon';

import houseIcon from '../../../assets/icons/icn-house.png';

const Container = styled.View`
  flex-direction: column;
`;
const AddressInfo = styled.View``;
const AddressInfoContainer = styled.View`
  flex-direction: row;
  padding-top: 10px;
`;
// const MapContainer = styled.View`
//   width: 100%;
//   height: 165px;
//   margin-top: 10px;
//   margin-bottom: 10px;
// `;
// const StyledMapView = styled(MapView)`
//   width: 100%;
//   height: 100%;
// `;

// TODO ADD MAPVIEW
const Address = ({ streetName, streetNumber, city, lon, lat }) => (
  <Container>
    <AddressInfoContainer>
      <Icon margins="0px 5px 0px 0px" size="32px" source={houseIcon} />

      <AddressInfo>
        <Text>{`${streetName} ${streetNumber}`}</Text>

        <Text fontSize="14px">{city}</Text>
      </AddressInfo>
    </AddressInfoContainer>

    {/* <MapContainer>
       <StyledMapView
        region={{
          latitude: lat,
          longitude: lon,
          latitudeDelta: 0.005,
          longitudeDelta: 0.0021
        }}>
        <Marker coordinate={{ latitude: lat, longitude: lon }} />
      </StyledMapView>
    </MapContainer> */}
  </Container>
);

Address.propTypes = {
  streetName: PropTypes.string.isRequired,
  streetNumber: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  lon: PropTypes.number.isRequired,
  lat: PropTypes.number.isRequired
};

export default Address;
