import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
// import Swipeable from 'react-native-swipeable';
import { useTranslation } from 'react-i18next';
import { IconButton, TextIconButton } from '../Buttons';
import Contact from './Contact';
import { makeAPhoneCall } from '../../utils/functions';
import colors from '../../styles/colors';

const ButtonsView = styled.View`
  flex-direction: row;
  align-items: center;
  background-color: ${colors.grey08};
`;

const ContactCard = ({
  isActive,
  isTyping,
  borderBottomWidth,
  onCardPress,
  contactInfo,
  onBookPress,
  onProfilePress,
  onCalendarPress
}) => {
  const [t] = useTranslation();
  const {
    first_name,
    last_name,
    image,
    last_message,
    phone,
    created_at,
    user,
    unread_message_count,
    user_id
  } = contactInfo || {};
  const { role } = user || {};
  const name =
    role === 'angel' ? first_name : `${t('profileScreensFam')} ${first_name} ${last_name}`;
  const lastMessage = last_message
    ? {
        ...last_message,
        isMine: last_message && last_message.user_id !== user_id
      }
    : null;
  const swipable = useRef(null);

  const renderRightActions = (phoneNumber, showCalendar) => (
    <ButtonsView>
      {showCalendar && (
        <TextIconButton
          flexDirection="column-reverse"
          borderTopWidth={0}
          borderBottomWidth={0}
          iconName="icn-calendar"
          paddings="0px 10px 0px 10px"
          iconSize="32px"
          iconColor={colors.grey04}
          text={t('calendar')}
          fontSize="12px"
          textColor={colors.grey04}
          onPress={onCalendarPress}
        />
      )}

      <TextIconButton
        disabled={!phoneNumber}
        flexDirection="column-reverse"
        borderTopWidth={0}
        borderBottomWidth={0}
        iconName="icn-telephone"
        paddings="0px 10px 0px 10px"
        iconSize="32px"
        iconColor={!phoneNumber ? colors.grey07 : colors.grey04}
        text={t('call')}
        fontSize="12px"
        textColor={!phoneNumber ? colors.grey07 : colors.grey04}
        onPress={() => makeAPhoneCall(phoneNumber)}
      />

      <TextIconButton
        flexDirection="column-reverse"
        borderTopWidth={0}
        borderBottomWidth={0}
        iconName="icn-calendar-add"
        paddings="0px 10px 0px 10px"
        iconSize="32px"
        iconColor={colors.grey04}
        text={t('book')}
        fontSize="12px"
        textColor={colors.grey04}
        onPress={onBookPress}
      />

      <TextIconButton
        flexDirection="column-reverse"
        borderTopWidth={0}
        borderBottomWidth={0}
        iconName="icn-profile"
        paddings="0px 20px 0px 10px"
        iconSize="32px"
        iconColor={colors.grey04}
        text={t('profile')}
        fontSize="12px"
        textColor={colors.grey04}
        onPress={onProfilePress}
      />

      <IconButton
        height="100%"
        paddings="0px 10px 0px 20px"
        backgroundColor={colors.alert}
        iconName="icn-cancel"
        iconColor={colors.white}
        onPress={() => swipable.current.close()}
      />
    </ButtonsView>
  );

  return (
    // <Swipeable ref={swipable} rightButtons={() => renderRightActions(phone, role === 'angel')}>
    <Contact
      borderBottomWidth={borderBottomWidth}
      isActive={isActive}
      isTyping={isTyping}
      name={name}
      image={image}
      lastMessage={lastMessage}
      unreadMessages={unread_message_count}
      createdAt={created_at}
      onPress={() => onCardPress(isActive, isTyping)}
    />
    // </Swipeable>
  );
};

ContactCard.propTypes = {
  borderBottomWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isActive: PropTypes.bool.isRequired,
  isTyping: PropTypes.bool.isRequired,
  contactInfo: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    image: PropTypes.string,
    last_message: PropTypes.shape({ user_id: PropTypes.number }),
    phone: PropTypes.string,
    created_at: PropTypes.string,
    user: PropTypes.shape({ role: PropTypes.string }),
    unread_message_count: PropTypes.number,
    user_id: PropTypes.number
  }).isRequired,
  onCardPress: PropTypes.func.isRequired,
  onBookPress: PropTypes.func.isRequired,
  onProfilePress: PropTypes.func.isRequired,
  onCalendarPress: PropTypes.func.isRequired
};

ContactCard.defaultProps = {
  borderBottomWidth: '1px'
};

export default ContactCard;
