import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { Text, Icon } from '../../elements'
import { TextView } from '../Texts'
import { TextButton } from '../Buttons'
import colors from '../../styles/colors'

import checkOnIcon from '../../../assets/icons/icn-check.png'

const View = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
  margin: ${({ margins }) => margins || '0px'};
  ${({ width }) => width};
  height: ${({ height }) => height || 'auto'};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  border-radius: 7px;
`
const IconView = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 38px;
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  border-left-width: ${({ borderLeftWidth }) => borderLeftWidth || 0};
  border-right-width: ${({ borderRightWidth }) => borderRightWidth || 0};
  border-color: ${colors.grey08};
  width: ${({ width }) => width || 'auto'};
`

const PLAN_COLUMN_WIDTH = 100

const MembershipEdit = ({
  buttonsDisabled,
  title,
  titleFontSize,
  subtitle,
  informationFontSize,
  informationTextAlign,
  editable,
  selectedPlan,
  onSelectPlanPress,
  isFlexible,
  selectedTerm,
  onSelectTermPress,
  showChooseMembership,
  onChooseMembershipPress
}) => {
  const [t] = useTranslation()

  return (
    <TextView
      flexDirection="column"
      paddings="20px 20px 15px 20px"
      width="100%"
      text={title || t('profileScreensChooseSubscription')}
      fontFamily="Martel"
      fontSize={titleFontSize}
      fontWeight="600"
    >
      <Text margins="10px 0px 15px 0px" fontSize="12px" color={colors.grey04}>
        {subtitle || t('profileScreensDowngradeUpgrade')}
      </Text>

      <View alignItems="center" height="30px" backgroundColor={colors.backgroundColor}>
        <TextButton
          width={PLAN_COLUMN_WIDTH}
          disabled={!editable}
          flex={1}
          justifyContent="center"
          height="30px"
          borderRadius="7px"
          borderWidth={0}
          borderTopWidth={0}
          borderBottomWidth={0}
          backgroundColor={selectedPlan === 'basic' ? colors.grey02 : 'transparent'}
          text={t('profileScreensBaseMembership')}
          fontSize="17px"
          fontWeight="600"
          textColor={selectedPlan === 'basic' ? colors.white : colors.grey04}
          onPress={() => onSelectPlanPress('basic')}
        />

        <TextButton
          width={PLAN_COLUMN_WIDTH}
          disabled={!editable}
          flex={1}
          justifyContent="center"
          height={isFlexible ? '30px' : '23px'}
          borderRadius={isFlexible ? '7px' : '1px'}
          borderWidth="1px"
          borderTopWidth={0}
          borderBottomWidth={0}
          borderColor={colors.grey08}
          backgroundColor={isFlexible ? colors.grey02 : 'transparent'}
          text={t('profileScreensFlexibleMembership')}
          fontSize="17px"
          fontWeight="600"
          textColor={isFlexible ? colors.white : colors.grey04}
          onPress={() => onSelectPlanPress('flexible')}
        />

        <TextButton
          width={PLAN_COLUMN_WIDTH}
          disabled={!editable}
          flex={1}
          height="30px"
          justifyContent="center"
          borderRadius="7px"
          borderWidth={0}
          borderTopWidth={0}
          borderBottomWidth={0}
          backgroundColor={selectedPlan === 'premium' ? colors.grey02 : 'transparent'}
          text={t('profileScreensPremiumMembership')}
          fontSize="17px"
          fontWeight="600"
          textColor={selectedPlan === 'premium' ? colors.white : colors.grey04}
          onPress={() => onSelectPlanPress('premium')}
        />
      </View>

      <TextView
        flexDirection="column"
        justifyContent="center"
        text={
          selectedTerm === 'monthly'
            ? t('profileScreensMembershipPerMonth')
            : t('profileScreensMembershipPerYear')
        }
        textMargins="20px 0px 10px 0px"
        fontWeight="300"
      >
        <View height="38px">
          <TextView
            width={PLAN_COLUMN_WIDTH}
            justifyContent="center"
            paddings="0px 5px 0px 5px"
            backgroundColor={selectedPlan === 'basic' ? colors.backgroundColor : 'transparent'}
            text={selectedTerm === 'monthly' ? '€ 2.99' : '€ 32,29'}
            fontSize="17px"
            fontWeight="600"
            textAlign="center"
            textColor={selectedPlan === 'basic' ? colors.black : colors.grey04}
          />

          <TextView
            width={PLAN_COLUMN_WIDTH}
            justifyContent="center"
            paddings="0px 5px 0px 5px"
            backgroundColor={isFlexible ? colors.backgroundColor : 'transparent'}
            borderLeftWidth="1px"
            borderRightWidth="1px"
            text={selectedTerm === 'monthly' ? '€ 6.99' : '€ 75,49'}
            fontSize="17px"
            fontWeight="600"
            textAlign="center"
            textColor={isFlexible ? colors.black : colors.grey04}
          />

          <TextView
            width={PLAN_COLUMN_WIDTH}
            justifyContent="center"
            paddings="0px 5px 0px 5px"
            backgroundColor={selectedPlan === 'premium' ? colors.backgroundColor : 'transparent'}
            text={selectedTerm === 'monthly' ? '€ 25' : '€ 270'}
            fontSize="17px"
            fontWeight="600"
            textColor={selectedPlan === 'premium' ? colors.black : colors.grey04}
          />
        </View>
      </TextView>

      <TextView
        flexDirection="column"
        justifyContent="center"
        text={t('profileScreensServiceCosts')}
        textMargins="20px 0px 10px 0px"
        fontWeight="300"
      >
        <View height="38px">
          <TextView
            width={PLAN_COLUMN_WIDTH}
            justifyContent="center"
            paddings="0px 5px 0px 5px"
            backgroundColor={selectedPlan === 'basic' ? colors.backgroundColor : 'transparent'}
            text="€ 7,50"
            fontSize="17px"
            fontWeight="600"
            textColor={selectedPlan === 'basic' ? colors.black : colors.grey04}
          />

          <TextView
            justifyContent="center"
            paddings="0px 5px 0px 5px"
            backgroundColor={isFlexible ? colors.backgroundColor : 'transparent'}
            borderLeftWidth="1px"
            borderRightWidth="1px"
            text="€ 3"
            fontSize="17px"
            fontWeight="600"
            textAlign="center"
            width={PLAN_COLUMN_WIDTH}
            textColor={isFlexible ? colors.black : colors.grey04}
          />

          <TextView
            width={PLAN_COLUMN_WIDTH}
            justifyContent="center"
            paddings="0px 5px 0px 5px"
            backgroundColor={selectedPlan === 'premium' ? colors.backgroundColor : 'transparent'}
            text={t('profileScreensNone')}
            fontSize="17px"
            fontWeight="600"
            textColor={selectedPlan === 'premium' ? colors.black : colors.grey04}
          />
        </View>
      </TextView>

      <TextView
        flexDirection="column"
        justifyContent="center"
        text={t('profileScreensMonthlyTerminable')}
        textMargins="20px 0px 10px 0px"
        fontWeight="300"
      >
        <View height="38px">
          <IconView
            width={PLAN_COLUMN_WIDTH}
            backgroundColor={selectedPlan === 'basic' ? colors.backgroundColor : 'transparent'}
          >
            <Icon
              justifyContent="center"
              margins="0px 5px 0px 5px"
              backgroundColor={colors.backgroundColor}
              size="32px"
              color={selectedPlan === 'basic' ? colors.primary : colors.grey04}
              source={checkOnIcon}
            />
          </IconView>

          <IconView
            width={PLAN_COLUMN_WIDTH}
            backgroundColor={isFlexible ? colors.backgroundColor : 'transparent'}
            borderLeftWidth="1px"
            borderRightWidth="1px"
          >
            <Icon
              justifyContent="center"
              margins="0px 5px 0px 5px"
              backgroundColor={colors.backgroundColor}
              size="32px"
              color={isFlexible ? colors.primary : colors.grey04}
              source={checkOnIcon}
            />
          </IconView>

          <IconView
            width={PLAN_COLUMN_WIDTH}
            backgroundColor={selectedPlan === 'premium' ? colors.backgroundColor : 'transparent'}
          >
            <Icon
              justifyContent="center"
              margins="0px 5px 0px 5px"
              backgroundColor={colors.backgroundColor}
              size="32px"
              color={selectedPlan === 'premium' ? colors.primary : colors.grey04}
              source={checkOnIcon}
            />
          </IconView>
        </View>
      </TextView>

      <TextView
        flexDirection="column"
        justifyContent="center"
        text={t('profileScreensPersonalSupport')}
        textMargins="20px 0px 10px 0px"
        fontWeight="300"
      >
        <View height="38px" width={PLAN_COLUMN_WIDTH}>
          <TextView
            justifyContent="center"
            paddings="0px 5px 0px 5px"
            backgroundColor={selectedPlan === 'basic' ? colors.backgroundColor : 'transparent'}
            text="-"
            fontSize="32px"
            fontWeight="600"
            width={PLAN_COLUMN_WIDTH}
            height="32px"
            textAlign="center"
            textColor={selectedPlan === 'basic' ? colors.primary : colors.grey04}
          />

          <IconView
            width={PLAN_COLUMN_WIDTH}
            backgroundColor={isFlexible ? colors.backgroundColor : 'transparent'}
            borderLeftWidth="1px"
            borderRightWidth="1px"
          >
            <Icon
              justifyContent="center"
              margins="0px 5px 0px 5px"
              backgroundColor={colors.backgroundColor}
              size="32px"
              color={isFlexible ? colors.primary : colors.grey04}
              source={checkOnIcon}
            />
          </IconView>

          <IconView
            width={PLAN_COLUMN_WIDTH}
            backgroundColor={selectedPlan === 'premium' ? colors.backgroundColor : 'transparent'}
          >
            <Icon
              justifyContent="center"
              margins="0px 5px 0px 5px"
              backgroundColor={colors.backgroundColor}
              size="32px"
              color={selectedPlan === 'premium' ? colors.primary : colors.grey04}
              source={checkOnIcon}
            />
          </IconView>
        </View>
      </TextView>

      <TextView
        flexDirection="column"
        justifyContent="center"
        text={t('profileScreensDoctorHelpline')}
        textMargins="20px 0px 10px 0px"
        fontWeight="300"
      >
        <View height="38px">
          <IconView
            width={PLAN_COLUMN_WIDTH}
            backgroundColor={selectedPlan === 'basic' ? colors.backgroundColor : 'transparent'}
          >
            <Icon
              justifyContent="center"
              margins="0px 5px 0px 5px"
              backgroundColor={colors.backgroundColor}
              size="32px"
              color={selectedPlan === 'basic' ? colors.primary : colors.grey04}
              source={checkOnIcon}
            />
          </IconView>

          <IconView
            width={PLAN_COLUMN_WIDTH}
            backgroundColor={isFlexible ? colors.backgroundColor : 'transparent'}
            borderLeftWidth="1px"
            borderRightWidth="1px"
          >
            <Icon
              justifyContent="center"
              margins="0px 5px 0px 5px"
              backgroundColor={colors.backgroundColor}
              size="32px"
              color={isFlexible ? colors.primary : colors.grey04}
              source={checkOnIcon}
            />
          </IconView>

          <IconView
            width={PLAN_COLUMN_WIDTH}
            backgroundColor={selectedPlan === 'premium' ? colors.backgroundColor : 'transparent'}
          >
            <Icon
              justifyContent="center"
              margins="0px 5px 0px 5px"
              backgroundColor={colors.backgroundColor}
              size="32px"
              color={selectedPlan === 'premium' ? colors.primary : colors.grey04}
              source={checkOnIcon}
            />
          </IconView>
        </View>
      </TextView>

      <TextView
        flexDirection="column"
        justifyContent="center"
        text={t('profileScreensAngelLiabilityInsurance')}
        textMargins="20px 0px 10px 0px"
        fontWeight="300"
      >
        <View height="38px">
          <IconView
            width={PLAN_COLUMN_WIDTH}
            backgroundColor={selectedPlan === 'basic' ? colors.backgroundColor : 'transparent'}
          >
            <Icon
              justifyContent="center"
              margins="0px 5px 0px 5px"
              backgroundColor={colors.backgroundColor}
              size="30px"
              color={selectedPlan === 'basic' ? colors.primary : colors.grey04}
              source={checkOnIcon}
            />
          </IconView>

          <IconView
            width={PLAN_COLUMN_WIDTH}
            backgroundColor={isFlexible ? colors.backgroundColor : 'transparent'}
            borderLeftWidth="1px"
            borderRightWidth="1px"
          >
            <Icon
              justifyContent="center"
              margins="0px 5px 0px 5px"
              backgroundColor={colors.backgroundColor}
              size="32px"
              color={isFlexible ? colors.primary : colors.grey04}
              source={checkOnIcon}
            />
          </IconView>

          <IconView
            width={PLAN_COLUMN_WIDTH}
            backgroundColor={selectedPlan === 'premium' ? colors.backgroundColor : 'transparent'}
          >
            <Icon
              justifyContent="center"
              margins="0px 5px 0px 5px"
              backgroundColor={colors.backgroundColor}
              size="32px"
              color={selectedPlan === 'premium' ? colors.primary : colors.grey04}
              source={checkOnIcon}
            />
          </IconView>
        </View>
      </TextView>

      <View margins="40px 20px 10px 20px">
        <TextButton
          disabled={!editable}
          width={(PLAN_COLUMN_WIDTH * 3) / 2}
          height="30px"
          justifyContent="center"
          borderRadius="7px"
          backgroundColor={selectedTerm === 'monthly' ? colors.grey02 : colors.backgroundColor}
          text={t('profileScreensMonthly')}
          fontSize="17px"
          fontWeight="600"
          textColor={selectedTerm === 'monthly' ? colors.white : colors.grey04}
          onPress={() => onSelectTermPress('monthly')}
        />

        <TextView
          width={(PLAN_COLUMN_WIDTH * 3) / 2}
          flexDirection="column-reverse"
          justifyContent="center"
          text={t('profileScreensAnnualDiscount')}
          fontSize="12px"
          textColor={colors.secondary}
        >
          <TextButton
            disabled={!editable}
            width="100%"
            height="30px"
            justifyContent="center"
            borderRadius="7px"
            backgroundColor={selectedTerm !== 'monthly' ? colors.grey02 : colors.backgroundColor}
            text={t('profileScreensAnnual')}
            fontSize="17px"
            fontWeight="600"
            textColor={selectedTerm !== 'monthly' ? colors.white : colors.grey04}
            onPress={() => onSelectTermPress('yearly')}
          />
        </TextView>
      </View>

      {showChooseMembership && (
        <TextButton
          disabled={!editable || buttonsDisabled}
          margins="20px 0px 20px 0px"
          width="100%"
          backgroundColor={colors.primary}
          text={t('signUpParentScreensChooseMembership')}
          onPress={onChooseMembershipPress}
        />
      )}

      <Text
        margins="0px 0px 20px 0px"
        fontSize={informationFontSize}
        fontWeight="300"
        textAlign={informationTextAlign}
      >
        {t('profileScreensServiceFeeDescription')}
      </Text>
    </TextView>
  )
}

MembershipEdit.propTypes = {
  buttonsDisabled: PropTypes.bool,
  title: PropTypes.string,
  titleFontSize: PropTypes.number,
  subtitle: PropTypes.string,
  informationFontSize: PropTypes.number,
  informationTextAlign: PropTypes.string,
  editable: PropTypes.bool,
  selectedPlan: PropTypes.string.isRequired,
  onSelectPlanPress: PropTypes.func.isRequired,
  isFlexible: PropTypes.bool.isRequired,
  selectedTerm: PropTypes.string.isRequired,
  onSelectTermPress: PropTypes.func.isRequired,
  showChooseMembership: PropTypes.bool,
  onChooseMembershipPress: PropTypes.func
}

MembershipEdit.defaultProps = {
  buttonsDisabled: false,
  title: null,
  titleFontSize: '18px',
  subtitle: null,
  informationFontSize: '15px',
  informationTextAlign: 'center',
  editable: true,
  showChooseMembership: false,
  onChooseMembershipPress: () => {}
}

export default MembershipEdit
