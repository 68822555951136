import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../elements';
import { TextView } from '../Texts';
import { IconButton, TextIconButton } from '../Buttons';
import RequestBookingDetails from './RequestBookingDetails';
import { getFormatedDate } from '../../utils/functions';
import colors from '../../styles/colors';

import checkedIcon from '../../../assets/icons/icn-check.png';
import arrowDown from '../../../assets/icons/icn-nav-down.png';

const View = styled.View``;

const RequestActiveDetails = ({
  index,
  startDate,
  endDate,
  bookingDates,
  expand,
  selectedDays,
  onToggleSelectDatePress,
  onToggleSelectAllPress,
  onShowAllPress,
  showToggeSelectButton,
  invitationState
}) => {
  const [t] = useTranslation();

  return (
    <View>
      <TextView
        paddings="15px 15px 5px 15px"
        borderTopWidth="1px"
        borderBottomWidth="1px"
        backgroundColor={colors.backgroundColor}
        text={`${t('request')} ${index + 1}`}
        fontSize="14px"
        textColor={colors.grey02}
      />

      <RequestBookingDetails
        startDate={startDate}
        endDate={endDate}
        bookingDates={bookingDates}
        selectedDays={selectedDays}
        onToggleSelectAllPress={onToggleSelectAllPress}
        showToggeSelectButton={showToggeSelectButton}
      />

      {bookingDates.map((bookingDate, dateIndex) => {
        if (!expand && dateIndex !== 0) {
          return null;
        }
        return (
          <TextView
            key={bookingDate.id}
            justifyContent="space-between"
            paddings="10px 20px 10px 20px"
            text={getFormatedDate(bookingDate.start_date, 'D MMMM')}>
            {showToggeSelectButton && (
              <IconButton
                iconName={selectedDays[dateIndex] ? 'btn-check' : 'btn-plus'}
                iconSize={30}
                iconColor={selectedDays[dateIndex] ? colors.primary : colors.grey07}
                onPress={() => onToggleSelectDatePress(dateIndex)}
              />
            )}
            {invitationState === 'pending_approval' && (
              <Icon source={checkedIcon} size="30px" color={colors.secondary} />
            )}
          </TextView>
        );
      })}

      {!expand && bookingDates.length > 1 && (
        <TextIconButton
          flexDirection="row"
          alignSelf="center"
          text={t('bookingsScreenShowAll')}
          textMargins="0px 0px 10px 0px"
          textColor={colors.primaryText}
          icon={arrowDown}
          iconMargins="0px 0px 10px 5px"
          iconColor={colors.secondary}
          onPress={onShowAllPress}
        />
      )}
    </View>
  );
};

RequestActiveDetails.propTypes = {
  index: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  bookingDates: PropTypes.arrayOf(PropTypes.string).isRequired,
  expand: PropTypes.bool.isRequired,
  selectedDays: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleSelectDatePress: PropTypes.func,
  onToggleSelectAllPress: PropTypes.func,
  onShowAllPress: PropTypes.func.isRequired,
  invitationState: PropTypes.string,
  showToggeSelectButton: PropTypes.bool
};

RequestActiveDetails.defaultProps = {
  onToggleSelectDatePress: () => {},
  onToggleSelectAllPress: () => {},
  invitationState: null,
  showToggeSelectButton: false
};

export default RequestActiveDetails;
