import { ReactComponent as Add } from '../../../assets/icons/add.svg';
import { ReactComponent as BtnCheck } from '../../../assets/icons/btn-check.svg';
import { ReactComponent as BtnMinus } from '../../../assets/icons/btn-minus.svg';
import { ReactComponent as BtnPlay } from '../../../assets/icons/btn-play.svg';
import { ReactComponent as BtnPlus } from '../../../assets/icons/btn-plus.svg';
import { ReactComponent as Cat } from '../../../assets/icons/cat.svg';
import { ReactComponent as Close } from '../../../assets/icons/close.svg';
import { ReactComponent as Dog } from '../../../assets/icons/dog.svg';
import { ReactComponent as Favorites } from '../../../assets/icons/favorites.svg';
import { ReactComponent as IcnAlertOff } from '../../../assets/icons/icn-alert-off.svg';
import { ReactComponent as IcnAlert } from '../../../assets/icons/icn-alert.svg';
import { ReactComponent as IcnBabyBottle } from '../../../assets/icons/icn-baby-bottle.svg';
import { ReactComponent as IcnBackpack } from '../../../assets/icons/icn-backpack.svg';
import { ReactComponent as IcnBlock } from '../../../assets/icons/icn-block.svg';
import { ReactComponent as IcnBookings } from '../../../assets/icons/icn-bookings.svg';
import { ReactComponent as IcnCalculate } from '../../../assets/icons/icn-calculate.svg';
import { ReactComponent as IcnCalendarAccepted } from '../../../assets/icons/icn-calendar-accepted.svg';
import { ReactComponent as IcnCalendarAdd } from '../../../assets/icons/icn-calendar-add.svg';
import { ReactComponent as IcnCalendarCanceled } from '../../../assets/icons/icn-calendar-canceled.svg';
import { ReactComponent as IcnCalendarSync } from '../../../assets/icons/icn-calendar-sync.svg';
import { ReactComponent as IcnCalendar } from '../../../assets/icons/icn-calendar.svg';
import { ReactComponent as IcnCancel } from '../../../assets/icons/icn-cancel.svg';
import { ReactComponent as IcnCar } from '../../../assets/icons/icn-car.svg';
import { ReactComponent as IcnCaretDown } from '../../../assets/icons/icn-caret-down.svg';
import { ReactComponent as IcnCaretLeft } from '../../../assets/icons/icn-caret-left.svg';
import { ReactComponent as IcnCaretRight } from '../../../assets/icons/icn-caret-right.svg';
import { ReactComponent as IcnCaretUp } from '../../../assets/icons/icn-caret-up.svg';
import { ReactComponent as IcnCat } from '../../../assets/icons/icn-cat.svg';
import { ReactComponent as IcnChat } from '../../../assets/icons/icn-chat.svg';
import { ReactComponent as IcnCheck } from '../../../assets/icons/icn-check.svg';
import { ReactComponent as IcnConversation } from '../../../assets/icons/icn-conversation.svg';
import { ReactComponent as IcnCooking } from '../../../assets/icons/icn-cooking.svg';
import { ReactComponent as IcnCreditcard } from '../../../assets/icons/icn-creditcard.svg';
import { ReactComponent as IcnCycling } from '../../../assets/icons/icn-cycling.svg';
import { ReactComponent as IcnDay } from '../../../assets/icons/icn-day.svg';
import { ReactComponent as IcnDining } from '../../../assets/icons/icn-dining.svg';
import { ReactComponent as IcnDishes } from '../../../assets/icons/icn-dishes.svg';
import { ReactComponent as IcnDog } from '../../../assets/icons/icn-dog.svg';
import { ReactComponent as IcnDrums } from '../../../assets/icons/icn-drums.svg';
import { ReactComponent as IcnEdit } from '../../../assets/icons/icn-edit.svg';
import { ReactComponent as IcnEyeOpen } from '../../../assets/icons/icn-eye-open.svg';
import { ReactComponent as IcnFacebook } from '../../../assets/icons/icn-facebook.svg';
import { ReactComponent as IcnFamily } from '../../../assets/icons/icn-family.svg';
import { ReactComponent as IcnFavoriteFill } from '../../../assets/icons/icn-favorite-fill.svg';
import { ReactComponent as IcnFavoriteInactive } from '../../../assets/icons/icn-favorite-inactive.svg';
import { ReactComponent as IcnFavorites } from '../../../assets/icons/icn-favorites.svg';
import { ReactComponent as IcnFilter } from '../../../assets/icons/icn-filter.svg';
import { ReactComponent as IcnFirstAid } from '../../../assets/icons/icn-first-aid.svg';
import { ReactComponent as IcnFlute } from '../../../assets/icons/icn-flute.svg';
import { ReactComponent as IcnGift } from '../../../assets/icons/icn-gift.svg';
import { ReactComponent as IcnGuitar } from '../../../assets/icons/icn-guitar.svg';
import { ReactComponent as IcnHouse } from '../../../assets/icons/icn-house.svg';
import { ReactComponent as IcnImage } from '../../../assets/icons/icn-image.svg';
import { ReactComponent as IcnInsurance } from '../../../assets/icons/icn-insurance.svg';
import { ReactComponent as IcnIroning } from '../../../assets/icons/icn-ironing.svg';
import { ReactComponent as IcnKids } from '../../../assets/icons/icn-kids.svg';
import { ReactComponent as IcnLanguageDe } from '../../../assets/icons/icn-language-de.svg';
import { ReactComponent as IcnLanguageEn } from '../../../assets/icons/icn-language-en.svg';
import { ReactComponent as IcnLanguageFr } from '../../../assets/icons/icn-language-fr.svg';
import { ReactComponent as IcnLanguageIt } from '../../../assets/icons/icn-language-it.svg';
import { ReactComponent as IcnLanguageNl } from '../../../assets/icons/icn-language-nl.svg';
import { ReactComponent as IcnLanguageSp } from '../../../assets/icons/icn-language-sp.svg';
import { ReactComponent as IcnLastMinute } from '../../../assets/icons/icn-last-minute.svg';
import { ReactComponent as IcnLaundry } from '../../../assets/icons/icn-laundry.svg';
import { ReactComponent as IcnLocation } from '../../../assets/icons/icn-location.svg';
import { ReactComponent as IcnMath } from '../../../assets/icons/icn-math.svg';
import { ReactComponent as IcnMicrophone } from '../../../assets/icons/icn-microphone.svg';
import { ReactComponent as IcnMobilePhone } from '../../../assets/icons/icn-mobile-phone.svg';
import { ReactComponent as IcnMorning } from '../../../assets/icons/icn-morning.svg';
import { ReactComponent as IcnNavAdd } from '../../../assets/icons/icn-nav-add.svg';
import { ReactComponent as IcnNavDown } from '../../../assets/icons/icn-nav-down.svg';
import { ReactComponent as IcnNavEnd } from '../../../assets/icons/icn-nav-end.svg';
import { ReactComponent as IcnNavLeft } from '../../../assets/icons/icn-nav-left.svg';
import { ReactComponent as IcnNavRight } from '../../../assets/icons/icn-nav-right.svg';
import { ReactComponent as IcnNavStart } from '../../../assets/icons/icn-nav-start.svg';
import { ReactComponent as IcnNavUp } from '../../../assets/icons/icn-nav-up.svg';
import { ReactComponent as IcnNight } from '../../../assets/icons/icn-night.svg';
import { ReactComponent as IcnPayments } from '../../../assets/icons/icn-payments.svg';
import { ReactComponent as IcnPeople } from '../../../assets/icons/icn-people.svg';
import { ReactComponent as IcnPhoto } from '../../../assets/icons/icn-photo.svg';
import { ReactComponent as IcnPiano } from '../../../assets/icons/icn-piano.svg';
import { ReactComponent as IcnPost } from '../../../assets/icons/icn-post.svg';
import { ReactComponent as IcnProfile } from '../../../assets/icons/icn-profile.svg';
import { ReactComponent as IcnReading } from '../../../assets/icons/icn-reading.svg';
import { ReactComponent as IcnRepeat } from '../../../assets/icons/icn-repeat.svg';
import { ReactComponent as IcnSaxophone } from '../../../assets/icons/icn-saxophone.svg';
import { ReactComponent as IcnSearch } from '../../../assets/icons/icn-search.svg';
import { ReactComponent as IcnSend } from '../../../assets/icons/icn-send.svg';
import { ReactComponent as IcnSettings } from '../../../assets/icons/icn-settings.svg';
import { ReactComponent as IcnShare } from '../../../assets/icons/icn-share.svg';
import { ReactComponent as IcnSinging } from '../../../assets/icons/icn-singing.svg';
import { ReactComponent as IcnTelephoneFill } from '../../../assets/icons/icn-telephone-fill.svg';
import { ReactComponent as IcnTelephone } from '../../../assets/icons/icn-telephone.svg';
import { ReactComponent as IcnTime } from '../../../assets/icons/icn-time.svg';
import { ReactComponent as IcnTrash } from '../../../assets/icons/icn-trash.svg';
import { ReactComponent as IcnVacuum } from '../../../assets/icons/icn-vacuum.svg';
import { ReactComponent as IcnValidated } from '../../../assets/icons/icn-validated.svg';
import { ReactComponent as IcnViolin } from '../../../assets/icons/icn-violin.svg';
import { ReactComponent as IcnWalkingTheDog } from '../../../assets/icons/icn-walking-the-dog.svg';
import { ReactComponent as NavClose } from '../../../assets/icons/nav-close.svg';
import { ReactComponent as Standby } from '../../../assets/icons/standby.svg';
import { ReactComponent as Star } from '../../../assets/icons/star.svg';
import { ReactComponent as Time } from '../../../assets/icons/time.svg';

export default {
"add": Add, 
"btn-check": BtnCheck, 
"btn-minus": BtnMinus, 
"btn-play": BtnPlay, 
"btn-plus": BtnPlus, 
"cat": Cat, 
"close": Close, 
"dog": Dog, 
"favorites": Favorites, 
"icn-alert-off": IcnAlertOff, 
"icn-alert": IcnAlert, 
"icn-baby-bottle": IcnBabyBottle, 
"icn-backpack": IcnBackpack, 
"icn-block": IcnBlock, 
"icn-bookings": IcnBookings, 
"icn-calculate": IcnCalculate, 
"icn-calendar-accepted": IcnCalendarAccepted, 
"icn-calendar-add": IcnCalendarAdd, 
"icn-calendar-canceled": IcnCalendarCanceled, 
"icn-calendar-sync": IcnCalendarSync, 
"icn-calendar": IcnCalendar, 
"icn-cancel": IcnCancel, 
"icn-car": IcnCar, 
"icn-caret-down": IcnCaretDown, 
"icn-caret-left": IcnCaretLeft, 
"icn-caret-right": IcnCaretRight, 
"icn-caret-up": IcnCaretUp, 
"icn-cat": IcnCat, 
"icn-chat": IcnChat, 
"icn-check": IcnCheck, 
"icn-conversation": IcnConversation, 
"icn-cooking": IcnCooking, 
"icn-creditcard": IcnCreditcard, 
"icn-cycling": IcnCycling, 
"icn-day": IcnDay, 
"icn-dining": IcnDining, 
"icn-dishes": IcnDishes, 
"icn-dog": IcnDog, 
"icn-drums": IcnDrums, 
"icn-edit": IcnEdit, 
"icn-eye-open": IcnEyeOpen, 
"icn-facebook": IcnFacebook, 
"icn-family": IcnFamily, 
"icn-favorite-fill": IcnFavoriteFill, 
"icn-favorite-inactive": IcnFavoriteInactive, 
"icn-favorites": IcnFavorites, 
"icn-filter": IcnFilter, 
"icn-first-aid": IcnFirstAid, 
"icn-flute": IcnFlute, 
"icn-gift": IcnGift, 
"icn-guitar": IcnGuitar, 
"icn-house": IcnHouse, 
"icn-image": IcnImage, 
"icn-insurance": IcnInsurance, 
"icn-ironing": IcnIroning, 
"icn-kids": IcnKids, 
"icn-language-de": IcnLanguageDe, 
"icn-language-en": IcnLanguageEn, 
"icn-language-fr": IcnLanguageFr, 
"icn-language-it": IcnLanguageIt, 
"icn-language-nl": IcnLanguageNl, 
"icn-language-sp": IcnLanguageSp, 
"icn-last-minute": IcnLastMinute, 
"icn-laundry": IcnLaundry, 
"icn-location": IcnLocation, 
"icn-math": IcnMath, 
"icn-microphone": IcnMicrophone, 
"icn-mobile-phone": IcnMobilePhone, 
"icn-morning": IcnMorning, 
"icn-nav-add": IcnNavAdd, 
"icn-nav-down": IcnNavDown, 
"icn-nav-end": IcnNavEnd, 
"icn-nav-left": IcnNavLeft, 
"icn-nav-right": IcnNavRight, 
"icn-nav-start": IcnNavStart, 
"icn-nav-up": IcnNavUp, 
"icn-night": IcnNight, 
"icn-payments": IcnPayments, 
"icn-people": IcnPeople, 
"icn-photo": IcnPhoto, 
"icn-piano": IcnPiano, 
"icn-post": IcnPost, 
"icn-profile": IcnProfile, 
"icn-reading": IcnReading, 
"icn-repeat": IcnRepeat, 
"icn-saxophone": IcnSaxophone, 
"icn-search": IcnSearch, 
"icn-send": IcnSend, 
"icn-settings": IcnSettings, 
"icn-share": IcnShare, 
"icn-singing": IcnSinging, 
"icn-telephone-fill": IcnTelephoneFill, 
"icn-telephone": IcnTelephone, 
"icn-time": IcnTime, 
"icn-trash": IcnTrash, 
"icn-vacuum": IcnVacuum, 
"icn-validated": IcnValidated, 
"icn-violin": IcnViolin, 
"icn-walking-the-dog": IcnWalkingTheDog, 
"nav-close": NavClose, 
"standby": Standby, 
"star": Star, 
"time": Time, 
};