import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatMoney, MEMBERSHIP_TYPES, useRouter } from '@charlycares/shared'
import { Card, CharlyIcon, ScreenLayout } from '@charlycares/ui'
import { Box, Button, CheckIcon, Flex, Heading, HStack, Text } from 'native-base'
import { useGetAvailableMembershipsQuery } from '@charlycares/data-access'
import moment from 'moment'

export default function NewCityStep4Screen() {
  const { t } = useTranslation()
  const { navigate } = useRouter()
  const { data: availableMemberships } = useGetAvailableMembershipsQuery()
  const priceMonthlyFlex = formatMoney(availableMemberships?.flexible.monthly.costs)
  const feePrice = formatMoney(availableMemberships?.flexible.monthly.fee_costs)
  const insurancePrice = formatMoney(availableMemberships?.flexible.monthly.insurance_costs)

  const membershipUsps = [t('newCityStep4Screen.usp1'), t('newCityStep4Screen.usp2')]

  const startTrial = () => {
    navigate('SetupBankAccount', '/setup-bank-account', {
      membershipType: MEMBERSHIP_TYPES.FLEXIBLE_TRIAL_3_MONTHS
    })
  }

  return (
    <ScreenLayout
      progress={`${90}%`}
      subtitle={''}
      headerLeft={<CharlyIcon name="logo" color="primary.400" size={30} />}
      bgColor="white"
      hideBackButton
    >
      <Card borderWidth="0" minHeight="530px">
        <Heading fontSize="20" fontWeight="600" lineHeight="26px" mt="30px">
          {t('newCityStep4Screen.header')}
        </Heading>

        <Flex direction="row" justifyContent="space-between" mt="5px">
          <Text fontSize="16px" color="gray.800">
            {t('newCityStep4Screen.monthly')}
          </Text>
          <Text mt="5px" fontSize="18px" color="gray.800">
            {priceMonthlyFlex}
          </Text>
        </Flex>

        <Box mt="25px" ml="10px">
          {membershipUsps.map(text => (
            <HStack space={2} key={text} marginBottom="10px">
              <CheckIcon size="5" mt="0.5" color="gray.600" />
              <Text fontSize="15px" lineHeight="22px" color="gray.900" paddingRight="40px">
                {text}
              </Text>
            </HStack>
          ))}
        </Box>

        <Box bgColor="gray.100" rounded="md" padding="14px" mt="35px">
          <Text fontSize="14px">
            {t('newCityStep4Screen.renewalInformation', {
              renewDate: moment().add(91, 'days').format('DD MMMM YYYY')
            })}
          </Text>
        </Box>
      </Card>

      <Button mx="20px" onPress={() => startTrial()}>
        {t('newCityStep4Screen.nextStep')}
      </Button>

      <Text mx="20px" fontSize="xs" color="gray.800" mt="24px" fontWeight={300}>
        {t('newCityStep4Screen.furtherInformation', {
          feePrice,
          insurancePrice
        })}
      </Text>
    </ScreenLayout>
  )
}
