import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Actionsheet,
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Pressable,
  ScrollView,
  Skeleton,
  Spacer,
  Text,
  VStack
} from 'native-base'
import { useGetActiveTimerQuery, useGetPaymentSummaryMutation, useStopTimerMutation } from '@charlycares/data-access'
import { BookingCancellationReasonType, getDurationString, makeCall, useRouter } from '@charlycares/shared'
import { useTranslation } from 'react-i18next'
import { CharlyIcon, DateTimePicker } from '@charlycares/ui'
import moment, { Moment } from 'moment'
import { BookingCosts } from './index'
import { ScrollViewProps } from 'react-native'
import { Tip } from './BookingCosts'
import WalletTransaction from '../common/WalletTransaction'

interface Props {
  onClose?: () => void
}

export default function RunningBookingOverlay({ onClose }: Props) {
  const { t } = useTranslation()
  const scrollView = useRef<ScrollViewProps | null>(null)
  const [show, setShow] = useState(false)
  const [showBusinessChoice, setShowBusinessChoice] = useState<boolean | undefined>(undefined)
  const [showCosts, setShowCosts] = useState(false)
  const { navigate } = useRouter()
  const { data: activeTimer } = useGetActiveTimerQuery()
  const [postStopTimer, { isSuccess: postTimerFinished, data: stopTimerData, isLoading: stopTimerIsLoading }] =
    useStopTimerMutation()
  const [getPaymentSummaryData, { isLoading, data: bookingPaymentSummary, isUninitialized, isSuccess }] =
    useGetPaymentSummaryMutation()
  const [showStartPicker, setShowStartPicker] = useState(false)
  const [showEndPicker, setShowEndPicker] = useState(false)
  const [useBusiness, setUseBusiness] = useState<null | boolean>(null)
  const startDatePicker = useRef<DateTimePicker | null>(null)
  const endDatePicker = useRef<DateTimePicker | null>(null)
  const avatar = activeTimer?.booking?.angel?.image
  const startsAt = moment(activeTimer?.booking.starts_at)
  const endsAt = moment(activeTimer?.booking.ends_at)
  const minStartDate = startsAt.clone().subtract(12, 'hours').format('YYYY-MM-DD HH:mm:ss')
  const maxStartDate = startsAt.clone().add(1, 'day').format('YYYY-MM-DD HH:mm:ss')
  const [startDate, setStartDate] = useState<Moment>(startsAt)
  const minEndDate = startDate.clone().add(2, 'hours')
  const maxEndDate = endsAt.clone().add(72, 'hours')
  const [endDate, setEndDate] = useState<Moment>(moment())
  const isBusiness = useMemo(() => {
    return (activeTimer?.has_business_membership && activeTimer?.has_business_credits) ?? false
  }, [activeTimer])
  const [tip, setTip] = useState<Tip>({ amount: 0, currency: 'EUR', label: '', value: 0 })

  const endBooking = (useBusiness: boolean | undefined | null, choosenTip?: Tip | undefined) => {
    if (isBusiness && useBusiness === undefined) {
      setShowBusinessChoice(true)

      return
    }

    if (!isBusiness && useBusiness === undefined) {
      setShowBusinessChoice(false)
    }

    if (useBusiness !== undefined) {
      setUseBusiness(useBusiness)
    }

    if (choosenTip) {
      setTip(choosenTip)
    }

    if (activeTimer) {
      getPaymentSummaryData({
        starts_at: startDate,
        ends_at: endDate,
        booking_id: activeTimer.booking.id.toString(),
        use_business: useBusiness ?? false,
        tip: choosenTip ? { amount: choosenTip.amount, currency: choosenTip.currency } : undefined
      })

      setShowCosts(true)
    }
  }

  const stopTimer = () => {
    if (!activeTimer) {
      return
    }

    postStopTimer({
      booking_id: activeTimer.booking.id.toString(),
      use_business: useBusiness ?? false,
      starts_at: startDate,
      ends_at: endDate,
      tip: { amount: tip.amount, currency: tip.currency }
    })
  }

  useEffect(() => {
    if (postTimerFinished && activeTimer) {
      setShow(false)

      if (useBusiness === true) {
        navigate('BookingFeedback', '/booking/feedback', {
          bookingId: activeTimer.booking.id.toString(),
          isBookedBefore: stopTimerData?.is_booked_before,
          paymentMethod: 'business'
        })

        return
      }

      navigate('PayBookingScreen', '/booking/pay', { bookingId: activeTimer.booking.id.toString() })
    }
  }, [postTimerFinished])

  useEffect(() => {
    if (activeTimer && isUninitialized) {
      getPaymentSummaryData({
        booking_id: activeTimer?.booking.id.toString(),
        use_business: useBusiness ?? false
      })
    }
  }, [getPaymentSummaryData, isUninitialized, activeTimer])

  useEffect(() => {
    if (isSuccess && bookingPaymentSummary) {
      setStartDate(moment(bookingPaymentSummary?.starts_at))
      setEndDate(moment(bookingPaymentSummary?.ends_at))
      setShow(true)
    }
  }, [bookingPaymentSummary, isSuccess])

  useEffect(() => {
    if (isSuccess) {
      const timeout = setTimeout(() => {
        // @ts-ignore
        scrollView.current?.scrollToEnd()
      }, 200)

      return () => clearTimeout(timeout)
    }

    return () => {}
  }, [isSuccess])

  useEffect(() => {
    endDate.diff(startDate, 'hours') < 2 && setEndDate(startDate.clone().add(2, 'hours'))
  }, [endDate, startDate])

  const doneEditing = showBusinessChoice !== undefined

  return (
    <Actionsheet
      isOpen={show}
      onClose={() => {
        setShow(false)

        onClose?.()
      }}
      _backdrop={{ bgColor: 'gray.900', opacity: 0.7 }}
    >
      <Actionsheet.Content justifyContent="center" bgColor="white" px="0px">
        {(showBusinessChoice || showCosts) && (
          <Box w="100%" bg="white" pb="20px">
            <Flex minH="30px" direction="row" alignItems="center" justify="space-between" px="15px">
              <CharlyIcon
                name="icn-nav-left"
                color="gray.600"
                size={30}
                onPress={() => {
                  setShowBusinessChoice(undefined)
                  setShowCosts(false)
                  setUseBusiness(null)
                }}
              />

              <Center zIndex={-1} position="absolute" top="0" bottom="0" left="0" right="0">
                <Text fontSize="18px" fontWeight="600">
                  {showBusinessChoice && !showCosts && t('runningBookingOverlay.businessChoice.overlayTitle')}
                  {showCosts && t('runningBookingOverlay.costs.title')}
                </Text>
              </Center>
            </Flex>
          </Box>
        )}
        <Flex direction="row" w="100%" alignItems="center" px="20px">
          {avatar ? (
            <Avatar borderWidth={1} borderColor="gray.400" size="40px" source={{ uri: avatar }} />
          ) : (
            <CharlyIcon name="avatar" size={40} />
          )}
          <Text fontSize="16px" fontWeight="600" pl="15px">
            {activeTimer?.booking?.angel?.first_name}
          </Text>
          <Spacer />
          {!doneEditing && activeTimer && (
            <HStack>
              <CharlyIcon
                name="icn-telephone-fill"
                color="secondary.400"
                size="32"
                mr="30px"
                onPress={() => {
                  activeTimer?.booking.angel?.phone && makeCall(activeTimer?.angel.phone)
                }}
              />
              <CharlyIcon
                name="icn-chat"
                color="secondary.400"
                size="32"
                onPress={() => {
                  setShow(false)
                  navigate('ChatScreen', '/chat', {
                    conversationId: activeTimer?.conversation_id
                  })
                }}
              />
            </HStack>
          )}
        </Flex>
        <VStack w="100%" px="20px">
          <HStack pt="22px" alignItems="center">
            <Text fontSize="16px" fontWeight="400" textAlign="center" ml="20px">
              {t('runningBookingOverlay.start')}
            </Text>
            <Spacer />
            <Pressable
              disabled={doneEditing}
              onPress={() => {
                startDatePicker.current?.open(startDate.format('YYYY-MM-DD HH:mm:ss'))

                setShowEndPicker(false)
                setShowStartPicker(true)
              }}
            >
              <HStack
                fontSize="16px"
                alignItems="center"
                bgColor={showStartPicker || doneEditing ? 'white' : 'secondary.alpha.10'}
                borderWidth={showStartPicker ? '2px' : '0px'}
                borderColor="secondary.400"
                rounded="xl"
                py="8px"
                pl={showStartPicker ? '20px' : '21px'}
                pr={showStartPicker ? '10px' : '11px'}
              >
                <Text
                  fontSize="16px"
                  color={doneEditing ? 'gray.800' : 'secondary.400'}
                  fontWeight={doneEditing ? '400' : '600'}
                  minWidth="130px"
                >
                  {startDate.format('dd. D MMMM')}
                </Text>
                <Text
                  fontSize="16px"
                  pl="5px"
                  color={doneEditing ? 'gray.800' : 'secondary.400'}
                  fontWeight={doneEditing ? '400' : '600'}
                >
                  {startDate.format('HH:mm')}
                </Text>
                {!doneEditing && <CharlyIcon name="icn-edit" size={20} ml="10px" color="secondary.400" />}
              </HStack>
            </Pressable>
          </HStack>
          <HStack pt="10px" alignItems="center">
            <Text fontSize="16px" fontWeight="400" textAlign="center" ml="20px">
              {t('runningBookingOverlay.end')}
            </Text>
            <Spacer />
            <Pressable
              disabled={doneEditing}
              onPress={() => {
                endDatePicker.current?.open(endDate.format('YYYY-MM-DD HH:mm:ss'))

                setShowStartPicker(false)
                setShowEndPicker(true)
              }}
            >
              <HStack
                fontSize="16px"
                alignItems="center"
                bgColor={showEndPicker || doneEditing ? 'white' : 'secondary.alpha.10'}
                borderWidth={showEndPicker ? '2px' : '0px'}
                borderColor="secondary.400"
                rounded="xl"
                py="8px"
                pl={showEndPicker ? '20px' : '21px'}
                pr={showEndPicker ? '10px' : '11px'}
              >
                <Text
                  color={doneEditing ? 'gray.800' : 'secondary.400'}
                  fontWeight={doneEditing ? '400' : '600'}
                  fontSize="16px"
                  minWidth="130px"
                >
                  {endDate.format('dd. D MMMM')}
                </Text>
                <Text
                  fontSize="16px"
                  color={doneEditing ? 'gray.800' : 'secondary.400'}
                  fontWeight={doneEditing ? '400' : '600'}
                  pl="5px"
                >
                  {endDate.format('HH:mm')}
                </Text>
                {!doneEditing && <CharlyIcon name="icn-edit" size={20} ml="10px" color="secondary.400" />}
              </HStack>
            </Pressable>
          </HStack>
        </VStack>

        {!doneEditing && (
          <Box>
            <HStack bgColor="gray.100" width="100%" h="80px" alignItems="center" mt="20px">
              <CharlyIcon name="icn-time" color="secondary.400" size="32" ml="30px" />
              <Text fontSize="22px" fontWeight="600" color="secondary.400" ml="8px">
                {getDurationString(startDate, endDate, true)}:00
              </Text>
              <Spacer />
              {!showEndPicker && !showStartPicker && (
                <Button bgColor="red.600" mr="30px" px="30px" onPress={() => endBooking(undefined, undefined)}>
                  {t('runningBookingOverlay.stopTimer')}
                </Button>
              )}
            </HStack>

            {!showEndPicker && !showStartPicker && (
              <Box py="20px">
                <Button
                  variant="text"
                  _text={{ color: 'gray.700' }}
                  onPress={() => {
                    setShow(false)
                    navigate('CancelBooking', '/cancel-booking', {
                      bookingId: activeTimer?.booking.id,
                      cancellationReason: BookingCancellationReasonType.ANGEL_NO_SHOW
                    })
                  }}
                >
                  {t('runningBookingOverlay.cancelService')}
                </Button>
              </Box>
            )}
          </Box>
        )}

        <Box width="100%" bgColor="white">
          <Box>
            {showStartPicker && (
              <HStack bgColor="gray.50" height="40px" alignItems="center" borderWidth="1px" borderColor="gray.200">
                <Spacer />
                <Button
                  variant="text"
                  color="secondary.400"
                  mr="20px"
                  fontSize="16px"
                  onPress={() => setShowStartPicker(false)}
                >
                  {t('done')}
                </Button>
              </HStack>
            )}
            <DateTimePicker
              width="100%"
              ref={startDatePicker}
              height="100%"
              type="datetime"
              minDate={minStartDate}
              maxDate={maxStartDate}
              textConfirm={t('done')}
              minuteInterval={5}
              onValueChanged={(date: string) => {
                setStartDate(moment(date))
              }}
              inline={showStartPicker}
            />
          </Box>
          <Box>
            {showEndPicker && (
              <HStack bgColor="gray.50" height="40px" alignItems="center">
                <Spacer />
                <Button
                  variant="text"
                  color="secondary.400"
                  mr="20px"
                  fontSize="16px"
                  onPress={() => setShowEndPicker(false)}
                >
                  {t('done')}
                </Button>
              </HStack>
            )}
            <DateTimePicker
              width="100%"
              ref={endDatePicker}
              height="100%"
              type="datetime"
              minDate={minEndDate}
              maxDate={maxEndDate}
              textConfirm={t('done')}
              minuteInterval={5}
              onValueChanged={(date: string) => {
                setEndDate(moment(date))
              }}
              inline={showEndPicker}
            />
          </Box>
        </Box>

        <ScrollView w="100%" ref={scrollView}>
          {showBusinessChoice && (
            <Box width="full" px="20px" my="20px">
              <Box borderColor="gray.200" borderTopWidth="1px" width="full" mb="20px"></Box>
              <Text fontSize="16px" fontWeight="600">
                {t('runningBookingOverlay.businessChoice.title')}
              </Text>
              <HStack width="100%" pt="15px" space="sm">
                <Button
                  variant={useBusiness === false ? 'solid' : 'outline'}
                  width="49%"
                  onPress={() => endBooking(false)}
                >
                  {t('runningBookingOverlay.businessChoice.ownPayment')}
                </Button>
                <Button
                  variant={useBusiness === true ? 'solid' : 'outline'}
                  width="49%"
                  onPress={() => endBooking(true)}
                >
                  {t('runningBookingOverlay.businessChoice.employerPayment')}
                </Button>
              </HStack>
            </Box>
          )}

          {showCosts && (
            <Box width="full" mb="80px">
              <Box width="full" px="20px" mb="20px">
                {!isLoading &&
                  bookingPaymentSummary?.wallet_transactions.map((walletTransaction, index) => (
                    <WalletTransaction key={`transaction-${index}`} walletTransaction={walletTransaction} />
                  ))}
                {isLoading && useBusiness && <Skeleton h="100px" flex={2} startColor="gray.400" />}
              </Box>
              <BookingCosts
                isLoading={isLoading}
                bookingPaymentSummary={bookingPaymentSummary}
                tipChanged={tip => endBooking(useBusiness, tip)}
              />
            </Box>
          )}
        </ScrollView>
        {showCosts && (
          <Box
            bgColor="gray.50"
            width="full"
            px="20px"
            position="absolute"
            bottom="0px"
            pb="50px"
            pt="10px"
            borderTopWidth="1px"
            borderTopColor="gray.200"
          >
            <Button isLoading={isLoading || stopTimerIsLoading} onPress={() => stopTimer()}>
              {t('runningBookingOverlay.complete')}
            </Button>
          </Box>
        )}
      </Actionsheet.Content>
    </Actionsheet>
  )
}
