import React from 'react';
import { Share } from 'react-native';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { logout } from '../../stores/actions/userAction';
import {
  Video,
  TextView,
  TextTextView,
  TextButton,
  CircularProgress,
  Header
} from '../../components';
import colors from '../../styles/colors';

import video from '../../../assets/videos/waitinglist-vid.mp4';

const ScrollView = styled.ScrollView`
  width: 100%;
`;
const VideoHeader = styled(Video)`
  width: 100%;
  height: 66.6%;
  background-color: ${colors.primaryAlpha80};
`;
const Container = styled.View`
  flex-direction: row;
  justify-content: center;
  margin: 0px 0px 20px 0px;
`;
const LineDivider = styled.View`
  margin: auto;
  width: 80%;
  border-width: 0.5px;
  border-color: ${colors.grey07};
`;

const onShare = async (message) => {
  try {
    Share.share({ message });
  } catch (error) {
    console.log({ error });
  }
};

const WaitingList = ({ location, history, route, navigation, logOut }) => {
  const { area, referral } = route.params || location.state || {};
  const { active_angel_campaign = {}, active_family_campaign = {} } = referral || {};
  const { disclaimer } = active_family_campaign || {};
  const { registered_families, minimum_families, registered_angels, minimum_angels, display_name } =
    area || {};
  const family_percentage = (registered_families / minimum_families) * 100;
  const angel_percentage = (registered_angels / minimum_angels) * 100;

  const [t] = useTranslation();

  if (navigation) {
    navigation.setOptions({
      headerLeft: null,
      headerRight: (
        <TextButton
          text={t('logOut')}
          textColor={colors.secondary}
          onPress={() => {
            if (navigation) {
              navigation.navigate('BoardingScreenFinal');
            } else {
              history.replace('/');
            }
            logOut();
          }}
        />
      )
    });
  }

  return (
    <ScrollView>
      {!navigation && (
        <Header
          right={
            <TextButton
              text={t('logOut')}
              textColor={colors.secondary}
              onPress={() => {
                history.replace('/');
                logOut();
              }}
            />
          }
        />
      )}

      <VideoHeader src={video} autoPlay />

      <TextView
        position="absolute"
        paddings="50px 25px 25px 50px"
        width="55%"
        text={t('signUpWaitingListAlmostLiveHeader', { 0: display_name })}
        fontFamily="Martel-DemiBold"
        fontSize="18px"
        textAlign="center"
      />

      <TextTextView
        flexDirection="column"
        justifyContent="flex-start"
        margins="15px 20px 20px 20px"
        firstText={t('signUpWaitingListGoLiveAtTitle')}
        firstTextFontFamily="Martel-DemiBold"
        firstTextFontSize="18px"
        firstTextFontWeight="normal"
        firstTextAlign="center"
        secondTextMargins="10px 0px 0px 0px"
        secondText={t('signUpWaitingListGoLiveAtDesc', {
          families: minimum_families,
          angels: minimum_angels
        })}
        secondTextFontFamily="OpenSans-Light"
        secondTextFontSize="15px"
        secondTextFontWeight="100"
        secondTextAlign="center"
      />

      <Container>
        <CircularProgress
          percent={family_percentage}
          radius={35}
          bgRingWidth={4}
          progressRingWidth={9}
          ringColor={colors.grey07}
          ringBgColor={colors.grey03}
          bgColor="white"
          startDegrees={0}
          text="Families"
          count={registered_families}
        />

        <CircularProgress
          percent={angel_percentage}
          radius={35}
          bgRingWidth={4}
          progressRingWidth={9}
          ringColor={colors.grey07}
          ringBgColor={colors.grey03}
          bgColor="white"
          startDegrees={0}
          text="Angels"
          count={registered_angels}
        />
      </Container>

      <LineDivider />

      <TextView
        justifyContent="center"
        margins="30px 0px 15px 0px"
        text={t('signUpWaitingListShare')}
        fontFamily="Martel-DemiBold"
        fontSize="16px"
        textAlign="center"
        textWidth="70%"
      />

      <TextButton
        margins="auto auto 10px auto"
        width="70%"
        backgroundColor={colors.primary}
        text={active_family_campaign.button_text}
        onPress={() => onShare(active_family_campaign.message)}
      />

      <TextButton
        margins="auto auto 0px auto"
        width="70%"
        textColor={colors.primary}
        borderColor={colors.primary}
        text={active_angel_campaign.button_text}
        onPress={() => onShare(active_angel_campaign.message)}
        borderRightWidth="1px"
        borderLeftWidth="1px"
      />

      <TextView
        justifyContent="center"
        margins="20px 0px 15px 0px"
        text={disclaimer}
        fontFamily="Open Sans"
        fontSize="13px"
        textAlign="center"
        textWidth="70%"
        textColor={colors.defaultBtnTextColor}
      />
    </ScrollView>
  );
};

WaitingList.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      area: PropTypes.shape({}),
      referral: PropTypes.shape({})
    })
  }),
  history: PropTypes.shape({
    replace: PropTypes.func
  }),
  route: PropTypes.shape({
    params: PropTypes.shape({ area: PropTypes.shape({}), referral: PropTypes.shape({}) })
  }),
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    setOptions: PropTypes.func
  }),
  logOut: PropTypes.func.isRequired
};

WaitingList.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null
};

const mapDispatchToProps = { logOut: logout };

export default connect(null, mapDispatchToProps)(WaitingList);
