import React from 'react'
import { Icon, IPressableProps, Pressable } from 'native-base'
import { iconsMap } from './generated'

type Props = IPressableProps & {
  name: keyof typeof iconsMap
  size: number | string
  height?: number | string
  width?: number | string
}

const CharlyIcon = ({ name, size, height, width, color, onPress, ...buttonProps }: Props) => {
  const IconComponent = iconsMap[name]

  return (
    <Pressable
      color={color}
      pointerEvents={onPress ? 'auto' : 'none'}
      _pressed={{
        opacity: onPress ? 0.3 : 1
      }}
      onPress={onPress}
      {...buttonProps}
    >
      <Icon
        as={<IconComponent width={width && height ? width : size} height={width && height ? height : size} />}
        color={color}
      />
    </Pressable>
  )
}

CharlyIcon.defaultProps = {
  size: 20
}

export default CharlyIcon
