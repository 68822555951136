import { Platform, Linking } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import moment from 'moment'
import 'moment/min/locales'

export const isWeb = Platform.OS === 'web'

export const changeMomentLocale = locale => moment.locale(locale)

export const getFormatedDate = (date, formatType) => moment(date).format(formatType)

export const getToken = () => AsyncStorage.getItem('token')

export const getAllCountryCodes = () => {
  const codes = [
    'NL (+31)',
    'BE (+32)',
    'GB (+44)',
    'BD (+880)',
    'BF (+226)',
    'BG (+359)',
    'BA (+387)',
    'BB (+1-246)',
    'WF (+681)',
    'BL (+590)',
    'BM (+1-441)',
    'BN (+673)',
    'BO (+591)',
    'BH (+973)',
    'BI (+257)',
    'BJ (+229)',
    'BT (+975)',
    'JM (+1-876)',
    'BW (+267)',
    'WS (+685)',
    'BQ (+599)',
    'BR (+55)',
    'BS (+1-242)',
    'JE (+44-1534)',
    'BY (+375)',
    'BZ (+501)',
    'RU (+7)',
    'RW (+250)',
    'RS (+381)',
    'TL (+670)',
    'RE (+262)',
    'TM (+993)',
    'TJ (+992)',
    'RO (+40)',
    'TK (+690)',
    'GW (+245)',
    'GU (+1-671)',
    'GT (+502)',
    'GR (+30)',
    'GQ (+240)',
    'GP (+590)',
    'JP (+81)',
    'GY (+592)',
    'GG (+44-1481)',
    'GF (+594)',
    'GE (+995)',
    'GD (+1-473)',
    'GA (+241)',
    'SV (+503)',
    'GN (+224)',
    'GM (+220)',
    'GL (+299)',
    'GI (+350)',
    'GH (+233)',
    'OM (+968)',
    'TN (+216)',
    'JO (+962)',
    'HR (+385)',
    'HT (+509)',
    'HU (+36)',
    'HK (+852)',
    'HN (+504)',
    'VE (+58)',
    'PR: (+1-787)',
    'PS (+970)',
    'PW (+680)',
    'PT (+351)',
    'SJ (+47)',
    'PY (+595)',
    'IQ (+964)',
    'PA (+507)',
    'PF (+689)',
    'PG (+675)',
    'PE (+51)',
    'PK (+92)',
    'PH (+63)',
    'PN (+870)',
    'PL (+48)',
    'PM (+508)',
    'ZM (+260)',
    'EH (+212)',
    'EE (+372)',
    'EG (+20)',
    'ZA (+27)',
    'EC (+593)',
    'IT (+39)',
    'VN (+84)',
    'SB (+677)',
    'ET (+251)',
    'SO (+252)',
    'ZW (+263)',
    'SA (+966)',
    'ES (+34)',
    'ER (+291)',
    'ME (+382)',
    'MD (+373)',
    'MG (+261)',
    'MF (+590)',
    'MA (+212)',
    'MC (+377)',
    'UZ (+998)',
    'MM (+95)',
    'ML (+223)',
    'MO (+853)',
    'MN (+976)',
    'MH (+692)',
    'MK (+389)',
    'MU (+230)',
    'MT (+356)',
    'MW (+265)',
    'MV (+960)',
    'MQ (+596)',
    'MP (+1-670)',
    'MS (+1-664)',
    'MR (+222)',
    'IM (+44-1624)',
    'UG (+256)',
    'TZ (+255)',
    'MY (+60)',
    'MX (+52)',
    'IL (+972)',
    'FR (+33)',
    'IO (+246)',
    'SH (+290)',
    'FI (+358)',
    'FJ (+679)',
    'FK (+500)',
    'FM (+691)',
    'FO (+298)',
    'NI (+505)',
    'NO (+47)',
    'NA (+264)',
    'VU (+678)',
    'NC (+687)',
    'NE (+227)',
    'NF (+672)',
    'NG (+234)',
    'NZ (+64)',
    'NP (+977)',
    'NR (+674)',
    'NU (+683)',
    'CK (+682)',
    'CI (+225)',
    'CH (+41)',
    'CO (+57)',
    'CN (+86)',
    'CM (+237)',
    'CL (+56)',
    'CC (+61)',
    'CA (+1)',
    'CG (+242)',
    'CF (+236)',
    'CD (+243)',
    'CZ (+420)',
    'CY (+357)',
    'CX (+61)',
    'CR (+506)',
    'CW (+599)',
    'CV (+238)',
    'CU (+53)',
    'SZ (+268)',
    'SY (+963)',
    'SX (+599)',
    'KG (+996)',
    'KE (+254)',
    'SS (+211)',
    'SR (+597)',
    'KI (+686)',
    'KH (+855)',
    'KN (+1-869)',
    'KM (+269)',
    'ST (+239)',
    'SK (+421)',
    'KR (+82)',
    'SI (+386)',
    'KP (+850)',
    'KW (+965)',
    'SN (+221)',
    'SM (+378)',
    'SL (+232)',
    'SC (+248)',
    'KZ (+7)',
    'KY (+1-345)',
    'SG (+65)',
    'SE (+46)',
    'SD (+249)',
    'DO: (+1-809)',
    'DM (+1-767)',
    'DJ (+253)',
    'DK (+45)',
    'VG (+1-284)',
    'DE (+49)',
    'YE (+967)',
    'DZ (+213)',
    'US (+1)',
    'UY (+598)',
    'YT (+262)',
    'UM (+1)',
    'LB (+961)',
    'LC (+1-758)',
    'LA (+856)',
    'TV (+688)',
    'TW (+886)',
    'TT (+1-868)',
    'TR (+90)',
    'LK (+94)',
    'LI (+423)',
    'LV (+371)',
    'TO (+676)',
    'LT (+370)',
    'LU (+352)',
    'LR (+231)',
    'LS (+266)',
    'TH (+66)',
    'TG (+228)',
    'TD (+235)',
    'TC (+1-649)',
    'LY (+218)',
    'VA (+379)',
    'VC (+1-784)',
    'AE (+971)',
    'AD (+376)',
    'AG (+1-268)',
    'AF (+93)',
    'AI (+1-264)',
    'VI (+1-340)',
    'IS (+354)',
    'IR (+98)',
    'AM (+374)',
    'AL (+355)',
    'AO (+244)',
    'AS (+1-684)',
    'AR (+54)',
    'AU (+61)',
    'AT (+43)',
    'AW (+297)',
    'IN (+91)',
    'AX (+358-18)',
    'AZ (+994)',
    'IE (+353)',
    'ID (+62)',
    'UA (+380)',
    'QA (+974)',
    'MZ (+258)'
  ]
  return codes
}

export const getCountryCode = code => {
  const suggestedCode = getAllCountryCodes().find(countryCode => countryCode.includes(`(${code})`))
  return suggestedCode
}

export const getAge = birthdate => Math.abs(moment(birthdate).diff(moment(), 'years'))

export const makeAPhoneCall = phone => Linking.openURL(`tel${Platform.OS === 'android' ? '' : 'prompt'}:${phone}`)

export const calculateBikeTime = distance => {
  if (distance === undefined || distance === null) return '0 min'
  const time = distance * 4
  return `${time.toFixed(0)} min`
}

export const roundMonetaryValue = amount => {
  let parsedAmount = parseFloat(amount)
  if (parsedAmount % 1 === 0) {
    parsedAmount = `${Math.round(parsedAmount)},-`
  } else {
    parsedAmount = parsedAmount.toFixed(2)
    parsedAmount = parsedAmount.replace('.', ',')
  }
  return parsedAmount
}

export const formatPhoneNumber = phone => {
  if (phone && phone.length) {
    if (phone.charAt(0) === '0') {
      return `+${phone.substring(0, 2)} ${phone.substring(2, phone.length)}`
    }
    return `+${phone.substring(0, 2)} (0)${phone.substring(2, phone.length)}`
  }
  return ''
}

export const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1)

export const isCountryCodeValid = code => getAllCountryCodes().includes(code)

export const getDuration = (t, startDate, endDate) => {
  const differenceHours = moment(endDate).diff(startDate, 'hours', false)
  const differenceMinutes = moment(endDate).diff(startDate, 'minutes', false)
  const minutes = differenceMinutes - differenceHours * 60
  return `${differenceHours} ${t('hours')} ${minutes ? `${minutes} ${t('minutes')}` : ''}`
}

export const getRateDescription = (t, rate, competitiveRate) => {
  const difference = rate - competitiveRate
  if (difference <= 0.25 && difference >= -0.25) {
    return t('competitive')
  }
  if (difference >= 0.5 && difference <= 1) {
    return t('uncompetitive')
  }
  if (difference > 1) {
    return t('veryUncompetitive')
  }
  if (difference <= -0.5 && difference >= -1) {
    return t('aboveCompetitive')
  }

  return t('veryCompetitive')
}

export const isValidIBAN = accountNumber => {
  // This function check if the checksum is correct
  let accountNum = accountNumber.toUpperCase()

  accountNum = accountNum.replace(/^(.{4})(.*)$/, '$2$1') // Move the first 4 chars from left to the right
  accountNum = accountNum.replace(/[A-Z]/g, $e => $e.charCodeAt(0) - 'A'.charCodeAt(0) + 10) // Convert A-Z to 10-25
  let $sum = 0
  let $ei = 1 // First exponent
  for (let $i = accountNum.length - 1; $i >= 0; $i -= 1) {
    $sum += $ei * parseInt(accountNum.charAt($i), 10) // multiply the digit by it's exponent
    $ei = ($ei * 10) % 97 // compute next base 10 exponent  in modulus 97
  }
  return $sum % 97 === 1
}

export const getOnboardingStep = onboardingStep => {
  switch (onboardingStep) {
    case 'references':
      return {
        screen: 'SignUpAngelStep6',
        pathname: '/signup-angel-references'
      }
    case 'short_bio':
      return {
        screen: 'SignUpAngelStep7',
        pathname: '/signup-angel-bio'
      }
    case 'preferences':
      return {
        screen: 'SignUpAngelStep8',
        pathname: '/signup-angel-preferences'
      }
    case 'availability':
      return {
        screen: 'SignUpAngelStep5',
        pathname: '/signup-angel-step5',
        progress: 100
      }
    case 'calendar':
      return {
        screen: 'SignUpAngelStep5',
        pathname: '/signup-angel-step5',
        progress: 100
      }
    case 'video':
      return {
        screen: 'SignUpAngelStep5',
        pathname: '/signup-angel-step5',
        progress: 100
      }
    default:
      return {
        screen: 'SignUpAngelStep5',
        pathname: '/signup-angel-step5'
      }
  }
}

export const getMembershipPlanName = (t, membershipPlan) => {
  switch (membershipPlan) {
    case 'basic':
      return t('profileScreensBaseMembership')
    case 'premium':
      return t('profileScreensPremiumMembership')
    case 'canceled':
      return t('profileScreensCanceledMembership')
    case 'trial':
      return t('profileScreensTrialMembership')
    default:
      return t('profileScreensFlexibleMembership')
  }
}

export const getNavigationRouteAndParamsOnLogin = (user = {}, location = '/') => {
  const {
    role,
    angel,
    is_accepted,
    has_active_timer,
    area_id,
    waitinglist,
    referral,
    has_pending_payment_booking,
    has_outstanding_payments
  } = user
  let routeAndParams = {}

  if (waitinglist) {
    const { area } = user
    routeAndParams = {
      screen: 'WaitingList',
      pathname: '/waiting-list',
      params: { area, referral, role }
    }
    return routeAndParams
  }

  if (role === 'angel') {
    const { angel_appointment, current_state } = angel

    if (area_id === 0) {
      routeAndParams = { screen: 'SignUpOutsideServce', pathname: '/outside-service' }
      return routeAndParams
    }

    if (Platform.OS === 'web') {
      routeAndParams = {
        screen: 'AngelSignupFinal',
        pathname: '/signup-angel-final',
        params: {
          ...user,
          preventLogin: true
        }
      }
    } else if (!is_accepted) {
      if (current_state === 'retired') {
        routeAndParams = {
          screen: 'ProfileAngelUnsubscribe',
          pathname: '/angel-unsubscribe',
          params: { wasRetired: true }
        }
      } else if (!angel_appointment) {
        routeAndParams = {
          screen: 'AngelSignupFinal',
          pathname: '/signup-angel-final'
        }
      } else {
        routeAndParams = {
          screen: 'SignUpAngelStep5',
          pathname: '/signup-angel-step5'
        }
      }
    } else if (current_state === 'suspended') {
      routeAndParams = { screen: 'Suspended', pathname: '/suspended' }
    } else if (current_state === 'paused') {
      routeAndParams = {
        screen: 'ProfileAngelUnsubscribe',
        pathname: '/angel-unsubscribe',
        params: { wasPaused: true }
      }
    } else {
      routeAndParams = { screen: 'AngelTabNavigator', pathname: '/bookings' }
    }
  } else if (has_pending_payment_booking) {
    routeAndParams = {
      screen: 'BecomeMember',
      pathname: '/become-member'
    }
  } else if (has_outstanding_payments) {
    routeAndParams = {
      screen: 'PaymentListScreen',
      pathname: '/profile-payments'
    }
  } else if (has_active_timer) {
    routeAndParams = { screen: 'ChatInbox', pathname: '/chat' }
  } else {
    routeAndParams = {
      screen: 'ParentTabNavigator',
      pathname: location === '/' ? '/discover' : location
    }
  }

  return routeAndParams
}

export const renderDistanceInKilometers = distance => {
  if (distance === undefined || distance === null) return '0 m'
  if (distance < 1) {
    return `${distance.toFixed(2) * 1000} m`
  }
  return `${distance.toFixed(2)} km`
}

export const getNumberOfProfileMissingFields = user => {
  const { image, first_name, last_name, street_name, phone, family, angel, role } = user
  const { short_bio } = role === 'family' ? family : angel
  const fields = [image, first_name, last_name, short_bio, street_name, phone]
  return fields.filter(field => !field).length
}

export const formatRating = rating => rating.toString().replace(/\./g, ',')

export const getFormattedChildrenAge = (dob, t) => {
  const dobMoment = moment(dob)

  const years = moment().diff(dobMoment, 'years')
  dobMoment.add(years, 'years')
  const months = moment().diff(dobMoment, 'months')
  if (years > 4) {
    return `${years} ${t('years')}`
  }
  if (years > 0 && years <= 4 && months > 0) {
    return `${years} ${t('years')} ${t('and')} ${months} ${t('months')}`
  }

  if (years > 0 && months === 0) {
    return `${years} ${t('years')}`
  }

  return `${months} ${t('months')}`
}

export const showNavbar = location => {
  const isDiscover = location.pathname.includes('discover') || location.pathname.includes('book-')
  const isBooking = location.pathname.includes('booking')
  const isChat = location.pathname.includes('chat')
  const isFavOrFinder = location.pathname.includes('favorites') || location.pathname.includes('job-finder')
  const isProfile = location.pathname.includes('profile')
  const isSupport = location.pathname.includes('support')
  const excludeOpenUrl = !location.pathname.includes('open-url')

  return (isDiscover || isBooking || isChat || isFavOrFinder || isProfile || isSupport) && excludeOpenUrl
}
