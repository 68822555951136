import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';

const Image = styled.ImageBackground`
  flex-direction: ${({ flexDirection }) => flexDirection};
  width: 100%;
  height: 100%;
`;

const ImageBackground = ({ flexDirection, source, children }) => (
  <Image flexDirection={flexDirection} source={source}>
    {children}
  </Image>
);

ImageBackground.propTypes = {
  flexDirection: PropTypes.string,
  source: PropTypes.node.isRequired,
  children: PropTypes.node
};

ImageBackground.defaultProps = {
  flexDirection: 'column',
  children: null
};

export default ImageBackground;
