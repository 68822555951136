import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useLocation } from 'react-router-dom'
import { isBrowser } from 'react-device-detect'
import { View } from 'react-native'

import { FullLogo, theme, showNavbar } from '@charlycares/legacy/web'
import { Navbar, Subnavbar } from '../Navigation'
import BoardingBanner from '../BoardingBanner'

const ColumnLeft = styled.View`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding: ${theme.spacer.lg};
  ${({ alignItems }) => alignItems && { 'align-items': alignItems }};
  width: 250px;
  max-width: 33%;
  height: ${({ height }) => height || 'auto'};
`

const LeftBanner = ({ loading, role }) => {
  const location = useLocation()
  const navBarVisible = showNavbar(location)
  const shouldShowNavbar = isBrowser && !loading

  if (!shouldShowNavbar) {
    return <View />
  }

  return navBarVisible ? (
    <ColumnLeft alignItems="flex-end" height={!isBrowser ? '60px' : 'auto'}>
      <FullLogo margins="16px auto" />
      <Navbar role={role} />
      <Subnavbar />
    </ColumnLeft>
  ) : (
    <BoardingBanner />
  )
}

LeftBanner.propTypes = {
  loading: PropTypes.bool.isRequired,
  role: PropTypes.string
}

LeftBanner.defaultProps = {
  role: null
}

export default LeftBanner
