import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from '@charlycares/shared'
import { ScreenLayout, CharlyIcon } from '@charlycares/ui'
import { Box, Button, Text, HStack } from 'native-base'
import { Share } from 'react-native'

export default function AngelShareBookingScreen() {
  const { getParam, navigate } = useRouter()
  const { t } = useTranslation()

  const bookingId = getParam('bookingId') as string
  const url = `https://charlycares.page.link/?link=https://app.charlycares.com/JobFinderDetails/${bookingId}&apn=nl.digitalchefs.charlycares&isi=1140986378&ibi=com.charlycares.charlycares`

  useEffect(() => {
    if (bookingId) {
      onShareBooking()
    }
  }, [])

  const onShareBooking = () => {
    Share.share(
      {
        title: t('takeOverBookingTitle'),
        message: `${t('shareBookingMessage')}: ${url}`
      },
      { subject: t('takeOverBookingTitle') }
    )
  }

  return (
    <ScreenLayout
      progress={`${100}%`}
      hideBackButton
      title={'Title'}
      subtitle={bookingId}
      edges={['right', 'left']}
      headerRight={
        <Button onPress={() => navigate('BookingsAngelScreen')} variant="text">
          {t('done')}
        </Button>
      }
      _bottomContainer={{
        backgroundColor: 'white',
        paddingBottom: '30px'
      }}
      _buttonProps={{
        onPress: onShareBooking,
        children: t('shareBookingBtnText')
      }}
    >
      <Box w="100%" bg="white" p="20px" borderColor="gray.200" borderWidth="1px">
        <HStack alignItems="center" justifyContent="space-between">
          <Text color="gray.900" fontSize="18px" fontWeight="600">
            {t('takeOverBookingTitle')}
          </Text>
          <CharlyIcon name="icn-gift" color="primary.400" size={34} />
        </HStack>

        <Text mt="35px" color="gray.800" fontSize="15px">
          {t('shareBookingMessage')}
        </Text>
      </Box>
    </ScreenLayout>
  )
}

AngelShareBookingScreen.navigationOptions = () => ({
  headerShown: false
})
