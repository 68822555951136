import { extendTheme } from 'native-base'

import components from './components'
import colors from './colors'
import fonts from './fonts'

export const nativeBaseTheme = extendTheme({
  components,
  colors,
  ...fonts
})

export type NativeBaseTheme = typeof nativeBaseTheme
