import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components/native'
import { useAppSelector } from '@charlycares/data-access'
import { useRouter } from '@charlycares/shared'
import { Card, Icon, IconDescription, Layout, Text } from '@charlycares/ui'
import { Button } from 'native-base'

export default function FamilyAccountCreated() {
  const { t } = useTranslation()
  const { fonts, colors } = useTheme()
  const { first_name, last_name } = useAppSelector(state => state.User.user)
  const { navigate } = useRouter()

  return (
    <Layout hideBack title={t('accountCreatedTitle')} subtitle={`100% ${t('complete')}`} progressBar="100%">
      <Card justifyContent="center" alignItems="center">
        <Text align="center" size={22} font={fonts.openSans.semiBold} weight="600">
          {`${t('welcome', { first_name: first_name, last_name: last_name })}`}
        </Text>

        <Text m="20px 0" align="center">
          {t('familyAccountCreatedDescription')}
        </Text>

        <Button w="100%" onPress={() => navigate('FamilyDashboardScreen', '/dashboard')}>
          {t('getStartedButton')}
        </Button>
      </Card>

      <Card bg={colors.primaryAlpha(0.22)}>
        <IconDescription
          titleProps={{ color: colors.primaryDark, font: fonts.openSans.semiBold, size: 22 }}
          title={t('businessProfileTitle')}
          text={t('Charly Cares Business')}
          icon={() => <Icon name="flat" size={50} color={colors.primaryDark} />}
          color={colors.primary}
          size={16}
        />

        <Text m="20px 0">{t('companyAccountDescription')}</Text>

        <Button
          w="100%"
          bg={colors.white}
          color={colors.primary}
          onPress={() => navigate('ProfileBusinessAccount', '/profile')}
        >
          {t('registerNow')}
        </Button>
      </Card>
    </Layout>
  )
}

FamilyAccountCreated.navigationOptions = () => ({
  headerShown: false
})
