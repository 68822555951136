import { useEffect, useState } from 'react'
import { Keyboard, KeyboardEventListener, Platform } from 'react-native'

export default function useKeyboard() {
  const [shown, setShown] = useState(false)
  const [keyboardHeight, setKeyboardHeight] = useState<number>(0)

  const handleKeyboardShow: KeyboardEventListener = e => {
    setShown(true)
    setKeyboardHeight(e.endCoordinates.height)
  }
  const handleKeyboardHide: KeyboardEventListener = e => {
    setShown(false)
    setKeyboardHeight(0)
  }

  useEffect(() => {
    const subscriptions =
      Platform.OS === 'ios'
        ? [
            Keyboard.addListener('keyboardWillShow', handleKeyboardShow),
            Keyboard.addListener('keyboardWillHide', handleKeyboardHide)
          ]
        : [
            Keyboard.addListener('keyboardDidShow', handleKeyboardShow),
            Keyboard.addListener('keyboardDidHide', handleKeyboardHide)
          ]

    return () => {
      subscriptions.forEach(subscription => subscription.remove())
    }
  }, [])

  return {
    isKeyboardVisible: shown,
    keyboardHeight
  }
}
