import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { TextView } from '../Texts';
import { TextButton } from '../Buttons';
import { LabeledInput } from '../Inputs';
import colors from '../../styles/colors';
import { isWeb } from '../../utils/functions';
import { PickerWeb } from '../Pickers';
import theme from '../../styles/theme';

const View = styled.View`
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  margin: ${({ margins }) => margins};
  ${({ width }) => ({ width })};
`;

const PhoneNumberEdit = ({
  margins,
  width,
  editable,
  countryCode,
  countryCodeOptions,
  onCountryCodePress,
  onCountryCodeChange,
  phoneNumberRef,
  phoneNumber,
  onPhoneNumberChangeText,
  onPhoneNumberFocus,
  inputFocused,
  onPhoneNumberSubmitEditing,
  validationErrors
}) => {
  const [t] = useTranslation();

  return (
    <View margins={margins} width={width}>
      <TextView
        flexDirection="column"
        alignItems="flex-start"
        width="30%"
        paddings={validationErrors && validationErrors.countryCode ? '0px 0px 0px 5px' : '0px'}
        borderColor={colors.secondary}
        backgroundColor={colors.white}
        text={t('country')}
        fontSize="12px"
        fontWeight={theme.inputLabelFontWeight}
        textColor={colors.grey02}>
        {!isWeb ? (
          <TextButton
            alignItems="flex-start"
            margins="5px 0px 0px 0px"
            paddings="0px 0px 0px 5px"
            width="100%"
            height="44px"
            borderTopWidth={0}
            borderRadius={0}
            borderColor={
              inputFocused && inputFocused.countryCode
                ? colors.secondary
                : colors.defaultBtnBackgroundColor
            }
            text={countryCode}
            fontSize="16px"
            fontWeight="normal"
            textColor={colors.primaryText}
            onPress={onCountryCodePress}
          />
        ) : (
          <PickerWeb
            options={countryCodeOptions.map((code) => ({ value: code, label: code }))}
            value={{ value: countryCode, label: countryCode }}
            onValueChange={onCountryCodeChange}
            width="130px"
            height="44px"
            margins="5px 0px 0px 0px"
          />
        )}
      </TextView>

      <LabeledInput
        inputRef={phoneNumberRef}
        flex={1}
        margins="0px 0px 0px 40px"
        paddings={validationErrors && validationErrors.phone ? '0px 0px 0px 5px' : '0px'}
        borderLeftWidth={validationErrors && validationErrors.phone ? '4px' : 0}
        borderColor={colors.secondary}
        backgroundColor={colors.white}
        inputBorderColor={
          inputFocused && inputFocused.phoneNumber
            ? colors.secondary
            : colors.defaultBtnBackgroundColor
        }
        labelText={t('phoneNumber')}
        inputMargins="5px 0px 0px 0px"
        inputPaddings="0px 0px 0px 5px"
        keyboardType="numeric"
        returnKeyType="done"
        validationMessage={validationErrors && validationErrors.phone}
        editable={editable}
        value={phoneNumber}
        onChangeText={onPhoneNumberChangeText}
        onFocus={onPhoneNumberFocus}
        onSubmitEditing={onPhoneNumberSubmitEditing}
      />
    </View>
  );
};

PhoneNumberEdit.propTypes = {
  margins: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editable: PropTypes.bool,
  countryCodeOptions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
  ),
  countryCode: PropTypes.string.isRequired,
  onCountryCodePress: PropTypes.func,
  onCountryCodeChange: PropTypes.func,
  phoneNumberRef: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired,
  phoneNumber: PropTypes.string.isRequired,
  onPhoneNumberChangeText: PropTypes.func.isRequired,
  onPhoneNumberFocus: PropTypes.func.isRequired,
  onPhoneNumberSubmitEditing: PropTypes.func,
  inputFocused: PropTypes.shape({ countryCode: PropTypes.bool, phoneNumber: PropTypes.bool }),
  validationErrors: PropTypes.shape({ countryCode: PropTypes.bool, phone: PropTypes.bool })
};

PhoneNumberEdit.defaultProps = {
  margins: '0px 20px 0px 20px',
  width: 'auto',
  editable: true,
  countryCodeOptions: [],
  onCountryCodePress: () => {},
  onCountryCodeChange: () => {},
  onPhoneNumberSubmitEditing: () => {},
  inputFocused: null,
  validationErrors: null
};

export default PhoneNumberEdit;
