import React, { useEffect } from 'react'
import { ActivityIndicator } from 'react-native'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { getFamilyDetails } from '../../stores/actions/favoritesAction'
import { Text, Divider } from '../../elements'
import { IconButton, Address, Languages, Kids, InfoIcons, Header } from '../../components'
import { makeAPhoneCall } from '../../utils/functions'
import colors from '../../styles/colors'

const SafeAreaView = styled.SafeAreaView`
  justify-content: space-between;
`
const ScrollContainer = styled.ScrollView``
const Container = styled.View`
  flex-direction: column;
  padding: 10px 20px 10px 20px;
`

const HeaderRightView = styled.View`
  flex-direction: row;
`

const FamilyProfile = ({ location, history, navigation, familyDetails, user, getFamilyDetailsData }) => {
  const { phone, conversationId, familyId } = location.state || {}
  const [t] = useTranslation()

  const { angel } = user

  const {
    id,
    image,
    last_name,
    short_bio,
    street_name,
    street_number,
    city,
    lon,
    lat,
    distance,
    dog,
    cat,
    child_count,
    languages,
    kids,
    allowed_chat,
    last_booked,
    conversation_id,
    obscured_id
  } = familyDetails || {}

  useEffect(() => {
    if (id !== familyId) {
      getFamilyDetailsData(familyId)
    }
  }, [])

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          left={
            <IconButton iconSize={32} iconName="icn-nav-left" iconColor={colors.secondary} onPress={history.goBack} />
          }
          right={
            <HeaderRightView>
              <IconButton
                disabled={!allowed_chat || !conversation_id}
                margins="0px 10px 0px 0px"
                iconName="icn-chat"
                iconSize={32}
                iconColor={allowed_chat ? colors.secondary : colors.grey03}
                onPress={() =>
                  history.push({
                    pathname: '/chat',
                    state: { conversationId: conversation_id }
                  })
                }
              />

              <IconButton
                disabled={!last_booked || !phone}
                margins="0px 10px 0px 0px"
                iconName="icn-telephone"
                iconSize={32}
                iconColor={last_booked && phone ? colors.secondary : colors.grey03}
                onPress={() => makeAPhoneCall(phone)}
              />

              <IconButton
                margins="0px 10px 0px 0px"
                iconName="icn-calendar-add"
                iconSize={32}
                iconColor={colors.secondary}
                onPress={() => {
                  history.push({
                    pathname: '/book-direct',
                    state: {
                      familyId: obscured_id
                    }
                  })
                }}
              />
            </HeaderRightView>
          }
        />
      )}
      {!familyDetails ? (
        <ActivityIndicator />
      ) : (
        <ScrollContainer>
          <Divider />

          <Text margins="20px 0px 10px 0px" fontFamily="Martel" fontSize="20px">
            {t('familyName', { 0: last_name })}
          </Text>

          {short_bio !== '' && (
            <Text margins="0px 0px 15px 0px" fontSize={15}>
              {short_bio}
            </Text>
          )}

          <InfoIcons hasCat={Boolean(cat)} hasDog={Boolean(dog)} distance={distance} childCount={child_count} />

          <Divider />

          <Container>
            <Address streetName={street_name} streetNumber={street_number} city={city} lon={lon} lat={lat} />
          </Container>

          <Divider />

          <Container>
            <Languages languages={languages} />
          </Container>

          <Divider />

          <Container>
            <Kids kids={kids} />
          </Container>

          <Divider />
        </ScrollContainer>
      )}
    </SafeAreaView>
  )
}

FamilyProfile.propTypes = {
  navigation: PropTypes.shape({ navigate: PropTypes.func, setOptions: PropTypes.func }),
  location: PropTypes.shape({ state: PropTypes.shape({}) }),
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func
  }),
  familyDetails: PropTypes.shape({}),
  getFamilyDetailsData: PropTypes.func.isRequired
}

FamilyProfile.defaultProps = {
  navigation: null,
  history: null,
  location: {},
  familyDetails: null
}

const mapStateToProps = ({ Favorites, User }) => ({
  familyDetails: Favorites.familyDetails,
  user: User.user
})

const mapDispatchToProps = {
  getFamilyDetailsData: getFamilyDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(FamilyProfile)
