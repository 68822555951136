import React from 'react'
import { Box, HStack, Text, IBoxProps, Spacer, Pressable, Skeleton } from 'native-base'
import { CharlyIcon } from '../icons'
import { useTranslation } from 'react-i18next'

interface Props extends IBoxProps {
  isLoading?: boolean
  onPress: () => void
}

const Scheme = ({ isLoading, onPress, ...boxProps }: Props) => {
  const { t } = useTranslation()

  return (
    <Pressable onPress={onPress}>
      <Box rounded="md" bgColor="white" width="100%" px="15px" py="12px" {...boxProps} shadow="3">
        {!isLoading && (
          <HStack alignItems="center">
            <CharlyIcon name="payment-card" size="32px" />
            <Text lineHeight="30px" ml="8px" fontSize="16px" color="text.dark.400" fontWeight="600">
              {t('scheme.title')}
            </Text>
            <Spacer />
            <CharlyIcon name="icn-caret-right" size="24px" color="gray.700" />
          </HStack>
        )}
        {isLoading && (
          <Skeleton height="30px" startColor="gray.alpha900.10" endColor="white" width="100%" rounded="md" mt="10px" />
        )}
      </Box>
    </Pressable>
  )
}

export default Scheme
