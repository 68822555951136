import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ActivityIndicator } from 'react-native'

import { Text } from '../../elements'
import { FamilyAvatar, TextButton, TextIconView } from '../../components'
import colors from '../../styles/colors'
import { getReferralLanding } from '../../stores/actions/referralAction'

import giftIcon from '../../../assets/icons/icn-gift.png'
import { useFetchTranslation } from '@charlycares/shared'

const Landing = ({ history, getReferralData, referralData }) => {
  const params = useParams()
  useFetchTranslation()

  useEffect(() => {
    getReferralData(params.reference, params.role, 'nl_NL')
  }, [])

  if (!referralData) {
    return (
      <Center>
        <ActivityIndicator size="large" />
      </Center>
    )
  }

  const { referrer_image, button_text, title, landing_subtitle, landing_message, id, referrer_id } = referralData

  const onSignup = () => {
    const referral = {
      referrer_id,
      campaign_id: id
    }

    history.push(`/signup/${params.role}`, { referral, role: params.role })
  }

  return (
    <Center>
      <TextIconView
        icon={giftIcon}
        iconSize="38px"
        text={title}
        fontSize="22px"
        flexDirection="row-reverse"
        backgroundColor="transparent"
        iconColor={colors.secondary}
        margins="0 0 30px 0"
        iconMargins="0 10px 0 0"
      />

      <Container>
        <FamilyAvatar source={referrer_image} width="80px" height="80px" borderRadius="80px" />
        <Text fontSize="16px" margins="auto auto auto 30px">
          {landing_subtitle}
        </Text>
      </Container>

      <Text margins="20px" fontSize="16px">
        <div dangerouslySetInnerHTML={{ __html: landing_message }} />
      </Text>

      <TextButton
        width="100%"
        margins="20px 0 0 0"
        text={button_text}
        backgroundColor={colors.primary}
        textColor={colors.white}
        onPress={onSignup}
      />
    </Center>
  )
}

Landing.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func, replace: PropTypes.func }),
  navigation: PropTypes.shape({ navigate: PropTypes.func }),
  referralData: PropTypes.shape({
    referrer_image: PropTypes.string,
    button_text: PropTypes.string,
    title: PropTypes.string,
    landing_subtitle: PropTypes.string,
    landing_message: PropTypes.string,
    id: PropTypes.number.isRequired,
    referrer_id: PropTypes.number.isRequired
  }),
  getReferralData: PropTypes.func.isRequired
}

Landing.defaultProps = {
  referralData: null,
  history: null,
  navigation: null
}

const Container = styled.View`
  flex-direction: row;
  background-color: ${colors.greenLight};
  padding: 15px 40px 15px 40px;
  width: 100%;
  border-radius: 5px;
`

const Center = styled.View`
  flex: 1;
  margin: 20px;
`

const mapStateToProps = ({ Referral }) => ({ referralData: Referral.referralLanding })

const mapDispatchToProps = {
  getReferralData: getReferralLanding
}

export default connect(mapStateToProps, mapDispatchToProps)(Landing)
