import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ActivityIndicator, Linking } from 'react-native';
import { FullLogo, Text } from '../../../elements';
import { TextButton, PaymentCard, TextTextView, TextIconView, TextView } from '../../../components';
import colors from '../../../styles/colors';
import { getOutstandingPayments } from '../../../stores/actions/paymentsAction';
import { roundMonetaryValue } from '../../../utils/functions';

import checkIcon from '../../../../assets/icons/icn-check.png';

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  justify-content: center;
  margin: 20px;
`;

const Thanks = ({ history, navigation }) => {
  const [t] = useTranslation();

  return (
    <SafeAreaView>
      <TextView
        text={t('outstandingPaymentsThanksTitle')}
        fontSize="18px"
        textMargins="auto auto 15px auto"
        width="100%"
        borderBottomWidth="1px"
        borderColor={colors.grey07}
      />

      <TextIconView
        text={t('outstandingPaymentsThanksTitle')}
        fontSize="15px"
        textAlign="center"
        margins="15px 0px 15px 0px"
        icon={checkIcon}
        flexDirection="row-reverse"
        backgroundColor="transparent"
        iconColor={colors.primary}
        iconSize="24px"
        iconMargins="0px 10px 0px 0px"
      />

      <TextButton
        padding="5px 5px 5px 5px"
        margins="15px 0px 0px 0px"
        backgroundColor={colors.primary}
        text={t('paymentsScreenOutstandingBookNow')}
        fontSize="16px"
        fontWeight="600"
        onPress={() => {
          if (navigation) {
            navigation.navigate('LoginScreen');
          } else {
            history.push('/login');
          }
        }}
      />
    </SafeAreaView>
  );
};

Thanks.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func, replace: PropTypes.func }),
  navigation: PropTypes.shape({ navigate: PropTypes.func })
};

Thanks.defaultProps = {
  history: null,
  navigation: null
};

export default Thanks;
