import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'

import { TextView, TextTextView, TextIconView } from '../Texts'
import { Rating, AngelAvatar } from '../Profile'
import { IconButton, TextIconButton } from '../Buttons'
import { renderDistanceInKilometers, roundMonetaryValue, getAge } from '../../utils/functions'
import theme from '../../styles/theme'
import { IcnNavRight } from '../Icons'

import dayIcon from '../../../assets/icons/icn-day.png'
import educationIcon from '../../../assets/icons/icn-reading.png'
import playIcon from '../../../assets/icons/label-play.png'
import infoIcon from '../../../assets/icons/icn-small-info.png'

const AngelCard = styled.TouchableOpacity`
  padding: 20px;
  border-bottom-width: 1px;
  border-color: ${theme.colors.grey08};
`
const AngelDetailsButtonView = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
const AngelAvatarDetails = styled.View`
  flex-direction: row;
`
const AngelDetails = styled.View`
  flex-direction: row;
`
const AngelAdditionalDetails = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0px 0px 0px;
`
const AngelAvatarView = styled.View``
const AngelNotAvailableButtons = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
`
const FavoritesButtons = styled.View`
  position: absolute;
  top: -10;
  right: 0;
  flex-direction: row;
  align-items: center;
`

const AvailableAngelCard = ({
  cardDisabled,
  disabled,
  favorites,
  share,
  angel,
  selected,
  chatEnabled,
  onVideoPress,
  onToggleSelectPress,
  onToggleFavoritePress,
  onCalendarPress,
  onMessagePress,
  onSharePress,
  onBookPress,
  onCardPress
}) => {
  const [t] = useTranslation()
  const {
    id,
    first_name,
    birthdate,
    image = null,
    is_liked,
    average_rating,
    distance,
    normal_rate,
    extra_rate,
    response_time = 0,
    first_aid,
    driving_license,
    babysit_expertise,
    liability_insurance,
    tutor,
    min_age_children,
    fixed = false,
    standBy = false,
    do_not_disturb = false,
    available = 1,
    video = null
  } = angel
  let otherSkills = [first_aid, driving_license, babysit_expertise, liability_insurance].filter(
    skill => skill > 0
  ).length
  const showBabySkill = min_age_children < 6
  otherSkills += showBabySkill && !!tutor ? 1 : 0

  return (
    <AngelCard disabled={cardDisabled} onPress={onCardPress}>
      <AngelDetailsButtonView>
        <AngelAvatarDetails>
          <AngelAvatarView>
            <AngelAvatar size="80px" source={image}>
              {video && (
                <IconButton
                  position="absolute"
                  bottom="0px"
                  height="auto"
                  borderWidth={0}
                  borderTopWidth={0}
                  borderBottomWidth={0}
                  iconSource={playIcon}
                  iconWidth="25px"
                  iconHeight="20px"
                  useIconTintColor={false}
                  onPress={onVideoPress}
                />
              )}
            </AngelAvatar>
          </AngelAvatarView>

          <TextView
            flexDirection="column"
            alignItems="flex-start"
            margins="-10px 0px 0px 10px"
            text={`${first_name} (${getAge(birthdate)})`}
            textWidth={favorites ? '60%' : '75%'}
            fontFamily="Martel"
            fontSize="16px"
            fontWeight="600"
            numberOfLines={1}
          >
            <Rating
              margins="5px 0px 10px 0px"
              rating={average_rating}
              iconSize="12px"
              activeTintColor={theme.ratingStarActiveMuted}
              height="12px"
            />

            {available === 1 ? (
              <AngelDetails>
                <TextIconView
                  minWidth="35px"
                  margins="0px 10px 0px 0px"
                  flexDirection="column-reverse"
                  backgroundColor="transparent"
                  text={renderDistanceInKilometers(distance)}
                  fontSize="13px"
                  name="icn-location"
                  iconColor="black"
                />

                {!!tutor && (
                  <TextIconView
                    minWidth="35px"
                    margins="0px 10px 0px 0px"
                    flexDirection="column-reverse"
                    backgroundColor="transparent"
                    text={t('tutorKids')}
                    fontSize="13px"
                    iconSize={'25px'}
                    icon={educationIcon}
                    iconColor="black"
                  />
                )}

                <TextIconView
                  minWidth="35px"
                  margins="0px 10px 0px 0px"
                  flexDirection="column-reverse"
                  backgroundColor="transparent"
                  text={`€ ${roundMonetaryValue(normal_rate)}`}
                  fontSize="15px"
                  name="icn-day"
                  iconColor="black"
                />

                <TextIconView
                  minWidth="35px"
                  margins="0px 10px 0px 0px"
                  flexDirection="column-reverse"
                  backgroundColor="transparent"
                  text={`€ ${roundMonetaryValue(extra_rate)}`}
                  fontSize="13px"
                  name="icn-night"
                  iconColor="black"
                />

                {showBabySkill && !tutor && (
                  <TextIconView
                    minWidth="35px"
                    margins="0px 10px 0px 0px"
                    flexDirection="column-reverse"
                    backgroundColor="transparent"
                    text={t('baby')}
                    fontSize="13px"
                    name="icn-baby-bottle"
                    iconColor="black"
                  />
                )}

                {!!otherSkills && (
                  <TextTextView
                    minWidth="35px"
                    paddings="0px"
                    flexDirection="column"
                    justifyContent="flex-start"
                    firstText={`+${otherSkills}`}
                    firstTextFontSize="24px"
                    secondText={otherSkills === 1 ? t('skill') : t('skills')}
                    secondTextFontSize="12px"
                    secondTextColor={theme.colors.primaryText}
                  />
                )}
              </AngelDetails>
            ) : (
              <TextTextView
                flexDirection="column"
                alignItems="flex-start"
                firstText={t('bookScreensAngelNotAvailable')}
                secondText={t('bookScreensCalendarClosed')}
              />
            )}
          </TextView>

          <IconButton
            height="auto"
            position="absolute"
            margins="-15px 0px 0px -20px"
            paddings="5px"
            headerRight="auto"
            borderWidth={0}
            borderTopWidth={0}
            borderBottomWidth={0}
            iconName={is_liked ? 'icn-favorite-fill' : 'icn-favorite-inactive'}
            iconColor={is_liked ? theme.colors.secondary : theme.colors.grey07}
            iconWidth="30px"
            iconHeight="30px"
            onPress={() => onToggleFavoritePress(id, is_liked > 0)}
          />
        </AngelAvatarDetails>

        {share ? (
          <IcnNavRight size="30px" color={theme.colors.grey07} />
        ) : (
          <>
            {!favorites ? (
              available === 1 &&
              !disabled && (
                <IconButton
                  paddings="0px"
                  height="30px"
                  iconName={selected ? 'btn-check' : 'btn-plus'}
                  iconSize={30}
                  iconColor={selected ? theme.colors.primary : theme.colors.grey07}
                  onPress={onToggleSelectPress}
                />
              )
            ) : (
              <FavoritesButtons>
                <IconButton
                  height="30px"
                  disabled
                  iconName="icn-share"
                  iconSize="30px"
                  onPress={onSharePress}
                />
                <IconButton
                  margins="0px 0px 0px 20px"
                  height="30px"
                  iconSource="icn-calendar-add"
                  iconSize="30px"
                  onPress={onBookPress}
                />
              </FavoritesButtons>
            )}
          </>
        )}
      </AngelDetailsButtonView>

      {available === 1 ? (
        <AngelAdditionalDetails>
          {!do_not_disturb && (standBy || response_time > 0) && (
            <TextIconView
              flexDirection="row-reverse"
              backgroundColor={theme.colors.white}
              text={
                standBy
                  ? t('bookScreensStandBy')
                  : `${t('bookScreensResponseTime')} ± ${response_time} min.`
              }
              textColor={theme.colors.grey02}
              fontSize="13px"
              textMargins="0px 0px 0px 5px"
              name={standBy ? 'standby' : 'time'}
              iconColor={standBy ? theme.colors.secondary : theme.colors.grey04}
            />
          )}

          {do_not_disturb === 1 && (
            <TextIconView
              flexDirection="row-reverse"
              backgroundColor={theme.colors.white}
              text={t('bookScreensDoNotDisturb')}
              textColor={theme.colors.grey02}
              fontSize="13px"
              textMargins="0px 0px 0px 5px"
              icon={infoIcon}
              iconColor={theme.colors.secondary}
            />
          )}

          {fixed && (
            <TextIconView
              paddings="0px 5px 0px 5px"
              flexDirection="row-reverse"
              backgroundColor={theme.colors.grey08}
              text={t('bookScreensPermanentBabysitter')}
              fontSize="13px"
              textMargins="0px 0px 0px 5px"
              name="icn-repeat"
              iconColor={theme.colors.primaryText}
            />
          )}
        </AngelAdditionalDetails>
      ) : (
        <AngelNotAvailableButtons>
          <TextIconButton
            paddings="0px 10px 0px 10px"
            flexDirection="row-reverse"
            text={t('calendar')}
            fontSize="14px"
            textColor={theme.colors.secondary}
            iconColor={theme.colors.secondary}
            iconName="icn-calendar"
            iconSize="30px"
            onPress={onCalendarPress}
          />

          <TextIconButton
            disabled={!chatEnabled}
            paddings="0px 10px 0px 10px"
            flexDirection="row-reverse"
            text={t('message')}
            fontSize="14px"
            textColor={chatEnabled ? theme.colors.secondary : theme.colors.grey08}
            iconColor={chatEnabled ? theme.colors.secondary : theme.colors.grey08}
            iconName="icn-chat"
            iconSize="30px"
            onPress={onMessagePress}
          />
        </AngelNotAvailableButtons>
      )}
    </AngelCard>
  )
}

AvailableAngelCard.propTypes = {
  cardDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
  favorites: PropTypes.bool,
  share: PropTypes.bool,
  angel: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    birthdate: PropTypes.string,
    image: PropTypes.string,
    is_liked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    average_rating: PropTypes.number,
    distance: PropTypes.number,
    normal_rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    extra_rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    response_time: PropTypes.number,
    first_aid: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    driving_license: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    babysit_expertise: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    liability_insurance: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    tutor: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    min_age_children: PropTypes.number,
    fixed: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    standBy: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    do_not_disturb: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    available: PropTypes.number,
    video: PropTypes.string
  }).isRequired,
  selected: PropTypes.bool,
  chatEnabled: PropTypes.bool,
  onVideoPress: PropTypes.func.isRequired,
  onToggleSelectPress: PropTypes.func,
  onToggleFavoritePress: PropTypes.func,
  onCalendarPress: PropTypes.func,
  onMessagePress: PropTypes.func,
  onSharePress: PropTypes.func,
  onBookPress: PropTypes.func,
  onCardPress: PropTypes.func.isRequired
}

AvailableAngelCard.defaultProps = {
  cardDisabled: false,
  disabled: false,
  favorites: false,
  share: false,
  selected: false,
  chatEnabled: true,
  onToggleSelectPress: () => {},
  onToggleFavoritePress: () => {},
  onCalendarPress: () => {},
  onMessagePress: () => {},
  onSharePress: () => {},
  onBookPress: () => {}
}

export default AvailableAngelCard
