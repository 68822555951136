import React, { useCallback, useMemo } from 'react'
import { Card, CharlyIcon, List, ScreenLayout } from '@charlycares/ui'
import { Avatar, Box, Button, Center, Factory, HStack, Heading, Spinner, Text, useTheme } from 'native-base'
import { useTranslation } from 'react-i18next'
import { Alert, Linking, TouchableOpacity } from 'react-native'
import { getMissingFieldsIndicator, useRouter, links } from '@charlycares/shared'
import { ToDoType, useGenerateAngelCVMutation, useGetAngelTodosQuery, useGetUserQuery } from '@charlycares/data-access'

import { LanguageSwitch } from '../../common'
import { useGetAppVersion, useHandleLogout } from '../../../hooks'
import { ConnectAngelToFamilyScreen } from '../../onboarding'

const Touchable = Factory(TouchableOpacity)

export default function AngelProfileMenu() {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { navigate } = useRouter()
  const { onLogout } = useHandleLogout()
  const appVersion = useGetAppVersion()

  const { data: user, isLoading: isUserLoading } = useGetUserQuery()
  const [generateAngelCV] = useGenerateAngelCVMutation()
  const { data: todos } = useGetAngelTodosQuery()

  const isVerified = todos?.find(todo => todo.item === ToDoType.PROFILE_VERIFIED && todo.completed)

  const renderIncomplete = useCallback(
    (missingFields: number) => {
      return missingFields ? (
        <HStack alignItems="center" space="5px">
          <Text fontSize="14px" color="primary.400">
            {t('profileScreensIncomplete')}
          </Text>
          <Center borderRadius="50px" w="18px" h="18px" bg="alert.info.400">
            <Text color="white" fontSize="11px" fontWeight={600}>
              {missingFields}
            </Text>
          </Center>
        </HStack>
      ) : null
    },
    [t]
  )

  const menuItems = useMemo(() => {
    return [
      {
        label: t('profileScreensProfileAccount'),
        onPress: () => navigate('AngelAccount'),
        suffix: user?.angel && renderIncomplete(getMissingFieldsIndicator('profile', user))
      },
      {
        label: t('profileScreensAngelPersonalSettings'),
        onPress: () => navigate('ProfilePersonalSettings'),
        suffix: user && renderIncomplete(getMissingFieldsIndicator('settings', user))
      },
      {
        label: t('profileScreensSittingPreferences'),
        onPress: () => navigate('ProfileAngelPreferences')
      },
      {
        label: t('payments'),
        onPress: () => navigate('PaymentListScreen')
      },
      {
        label: t('angelRatingsAndReviews'),
        onPress: () => navigate('AngelReviews')
      },
      {
        label: t('profileScreensAngelConnectWithFamily'),
        onPress: () => navigate('ConnectAngelToFamilyScreen', '/connect-angel-to-family', { fromProfileMenu: true })
      },
      {
        label: t('profileScreensAngelPromotions'),
        onPress: () => navigate('Promotions')
      },
      {
        label: t('profileScreensDownloadCV'),
        onPress: () => {
          Alert.alert(t('downloadCVTitle'), t('downloadCVDescription'), [
            { text: t('cancel') },
            { text: t('Download'), onPress: () => generateAngelCV() }
          ])
        }
      },
      { label: t('profileScreensGeneralContitions'), onPress: () => navigate('GeneralConditions') },
      {
        label: t('Logout'),
        onPress: () => onLogout()
      }
    ]
  }, [t, navigate, renderIncomplete, user, generateAngelCV, onLogout])

  return (
    <ScreenLayout
      hideBackButton
      title={t('profile')}
      edges={['right', 'left']}
      headerRight={() => (
        <Button variant="text" onPress={() => Linking.openURL(links.support)}>
          {t('profileScreensNeedHelp')}
        </Button>
      )}
    >
      <Touchable
        p="20px"
        w="100%"
        bg="primary.300"
        flexDir="row"
        justifyContent="space-between"
        onPress={() => navigate('ShopOverview')}
      >
        <Text color="text.dark.400" fontWeight={300} fontSize="17px">
          {t('profileScreensCharlysShop')}
        </Text>
        <HStack space={'5px'} alignItems="center">
          {isUserLoading ? (
            <Spinner color="secondary.400" />
          ) : (
            <Text color="gray.700" fontSize="16px" fontWeight={300}>
              {t('angelPoints', { points: user?.angel?.available_points })}
            </Text>
          )}

          <CharlyIcon size={30} color={colors.primary[400]} name="icn-nav-right" />
        </HStack>
      </Touchable>

      <Card>
        <HStack w="100%" mb="10px" alignItems="center" space={'10px'}>
          <Box>
            <Avatar borderWidth="1px" borderColor="gray.200" source={{ uri: user?.profile?.image }} w="80px" h="80px" />
            {!!user?.angel?.is_verified && (
              <CharlyIcon name={'verified'} size="24px" position="absolute" right={-5} top={0} />
            )}
          </Box>

          <Heading
            flex={1}
            mt="5px"
            numberOfLines={2}
            fontSize="lg"
            fontWeight="600"
          >{`${user?.profile?.first_name} ${user?.profile?.last_name}`}</Heading>

          <LanguageSwitch position="absolute" right={0} top={-10} />
        </HStack>

        <List items={menuItems} />

        {appVersion && (
          <Text w="100%" textAlign="center" mt="20px" fontSize="10px">
            App version: {appVersion}
          </Text>
        )}
      </Card>
    </ScreenLayout>
  )
}

AngelProfileMenu.navigationOptions = () => ({
  headerShown: false
})
