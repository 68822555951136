export const fonts = {
  regular: 'OpenSans-Regular',
  bold: 'OpenSans-Bold',
  light: 'OpenSans-Light',
  semiBold: 'OpenSans-SemiBold',
  extraBold: 'OpenSans-ExtraBold',
  italic: 'OpenSans-Italic',
  italicLight: 'OpenSans-LightItalic',

  martel: {
    regular: 'Martel',
    bold: 'Martel-Bold',
    extraBold: 'Martel-ExtraBold',
    light: 'Martel-Light',
    semiBold: 'Martel-DemiBold'
  },

  size: {
    h1: 25,
    h2: 22,
    h3: 18,
    body: 15
  },

  family: {
    h1: 'OpenSans-Bold',
    h2: 'OpenSans-SemiBold',
    h3: 'OpenSans-SemiBold',
    body: 'OpenSans-Regular'
  }
};
