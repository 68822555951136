import { setStyle } from '@charlycares/shared'
import { ThemedStyledProps } from 'styled-components'
import { DefaultTheme } from 'styled-components/native'

export type BaseProps = {
  m?: string | number
  mb?: string | number
  mt?: string | number
  mr?: string | number
  ml?: string | number
  mx?: string | number
  my?: string | number
  margin?: string | number
  padding?: string | number

  p?: string | number
  pb?: string | number
  pt?: string | number
  pr?: string | number
  pl?: string | number
  px?: string | number
  py?: string | number

  maxW?: string | number
  maxH?: string | number
  w?: string | number
  h?: string | number
  width?: string | number
  height?: string | number
  flex?: number

  bg?: string
  background?: string

  position?: 'absolute' | 'relative'
  left?: number
  right?: number
  top?: number
  bottom?: number
}

export const baseProps = (p: ThemedStyledProps<BaseProps, DefaultTheme>) => `
${setStyle('margin', p.margin || p.m, 0)};
${setStyle('margin-top', p.mt)};
${setStyle('margin-bottom', p.mb)};
${setStyle('margin-left', p.ml)};
${setStyle('margin-right', p.mr)};
${setStyle('margin-horizontal', p.my)};
${setStyle('margin-vertical', p.mx)};
${setStyle('padding', p.padding || p.p, 0)};
${setStyle('padding-top', p.pt)};
${setStyle('padding-bottom', p.pb)};
${setStyle('padding-left', p.pl)};
${setStyle('padding-right', p.pr)};
${setStyle('padding-horizontal', p.py)};
${setStyle('padding-vertical', p.px)};

${setStyle('flex', p.flex)};
${setStyle('width', p.width || p.w)};
${setStyle('height', p.height || p.h)};
${setStyle('max-width', p.maxW)};
${setStyle('max-height', p.maxH)};

${setStyle('position', p.position)};
${setStyle('top', p.top)};
${setStyle('bottom', p.bottom)};
${setStyle('right', p.right)};
${setStyle('left', p.left)};

${setStyle('background', p.background || p.bg)};
`
