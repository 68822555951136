import React, { useState, useEffect } from 'react';
import { ActivityIndicator } from 'react-native';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { updateProfile } from '../../../stores/actions/userAction';
import {
  getPreferences,
  getAvailability,
  setPreference,
  savePreferencesAvailability,
  setAvailability,
  setWasEdited
} from '../../../stores/actions/angelPreferencesAvailabilityAction';
import { Text, Switch, Divider } from '../../../elements';
import {
  TextView,
  TextTextView,
  TextIconView,
  TextButton,
  IconButton,
  ButtonSwitch,
  MultiRangeSlider,
  Header,
  Icon
} from '../../../components';
import colors from '../../../styles/colors';

import morningIcon from '../../../../assets/icons/icn-morning.png';
import afternoonIcon from '../../../../assets/icons/icn-day.png';
import eveningIcon from '../../../../assets/icons/icn-night.png';
import leftIcon from '../../../../assets/icons/icn-nav-left.png';

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  overflow: hidden;
`;
const ScrollView = styled.ScrollView`
  flex: 1;
  background-color: ${colors.white};
`;
const View = styled.View`
  flex-direction: row;
  align-items: center;
  align-self: ${({ alignSelf }) => alignSelf || 'auto'};
  opacity: ${({ editMode }) => (editMode ? '1' : '0.5')};
`;
const Container = styled.View``;
const Cell = styled.View`
  justify-content: center;
  align-items: center;
  min-width: 80px;
`;
const PetContainer = styled.View`
  justify-content: space-between;
  flex-direction: row;
  padding: 10px 0px 5px 0px;
  width: 100%;
`;
const IconView = styled.View`
  flex-direction: row;
`;
const SliderContainer = styled.View`
  flex-direction: column;
  width: 100%;
  opacity: ${({ editMode }) => (editMode ? '1' : '0.5')};
`;
const WeekContainer = styled.View`
  padding: 20px;
`;
const DayContainer = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 0px 5px 0px;
  border-bottom-width: 1px;
  border-color: ${colors.grey07};
  opacity: ${({ editMode }) => (editMode ? '1' : '0.5')};
`;
const MomentsContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
`;

const renderDayIcons = (t) => (
  <DayContainer editMode>
    <MomentsContainer>
      <TextIconView
        flexDirection="column-reverse"
        width="30%"
        backgroundColor={colors.white}
        icon={morningIcon}
        iconSize="26px"
        iconColor={colors.grey01}
        text={t('morning')}
        fontSize="12px"
        useIconTintColor
      />

      <TextIconView
        flexDirection="column-reverse"
        width="30%"
        backgroundColor={colors.white}
        icon={afternoonIcon}
        iconSize="26px"
        iconColor={colors.grey01}
        text={t('afternoon')}
        fontSize="12px"
        useIconTintColor
      />

      <TextIconView
        flexDirection="column-reverse"
        width="30%"
        backgroundColor={colors.white}
        icon={eveningIcon}
        iconSize="26px"
        iconColor={colors.grey01}
        text={t('evening')}
        fontSize="12px"
        useIconTintColor
      />
    </MomentsContainer>
  </DayContainer>
);

const Preferences = ({
  history,
  navigation,
  wasEdited,
  user,
  preferences,
  availability,
  updateProfileData,
  savePreferencesAvailabilityData,
  setPreferenceData,
  setAvailabilityData,
  getPreferencesData,
  getAvailabilityData,
  onSetWasEdited
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [fixedType, setFixedType] = useState(user && user.angel && user.angel.type === 'fixed');
  const [t] = useTranslation();
  const { angel } = user;

  const goBack = () => {
    if (!wasEdited) {
      if (navigation) {
        navigation.goBack();
      } else {
        history.goBack();
      }
    } else {
      // Alert.alert(
      //   strings.angelSettingsScreenLeaveTitle,
      //   strings.angelSettingsScreenLeaveDescription,
      //   [
      //     { text: strings.cancel },
      //     {
      //       text: strings.leave,
      //       onPress: navigation.goBack
      //     }
      //   ],
      //   { cancelable: false }
      // );
    }
  };

  const onUpdateSettingsPress = () => {
    if (editMode) {
      updateProfileData({ type: fixedType ? 'fixed' : 'occasional' });
      savePreferencesAvailabilityData(preferences, availability);
    }

    let hasMomentAvailable = false;
    if (currentTab === 1) {
      availability.forEach((dayAvailability) => {
        if (dayAvailability.morning || dayAvailability.afternoon || dayAvailability.evening) {
          hasMomentAvailable = true;
        }
      });
    }

    if (!hasMomentAvailable && currentTab === 1) {
      // Alert.alert(
      //   strings.profileScreensMustIncludeOneMomentTitle,
      //   strings.profileScreensMustIncludeOneMomentDescription,
      //   [{ text: strings.ok }],
      //   { cancelable: false }
      // );
      return;
    }

    setEditMode(!editMode);
  };

  if (navigation) {
    navigation.setOptipons({
      headerTitle: (
        <Text fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('profileScreensAngelPersonalSettings')}
        </Text>
      ),
      headerRight: (
        <TextButton
          text={editMode ? t('save') : t('edit')}
          textColor={colors.secondary}
          onPress={onUpdateSettingsPress}
        />
      ),
      headerLeft: (
        <IconButton
          margins="0px 0px 0px 10px"
          iconSize="32px"
          iconSource={leftIcon}
          iconColor={colors.secondary}
          onPress={goBack}
          useIconTintColor
        />
      )
    });
  }

  useEffect(() => {
    getPreferencesData();
    getAvailabilityData(angel.id);
  }, []);

  const switchToFixed = () => {
    setCurrentTab(1);
  };

  const setPreferenceDataKids = (low, high) => {
    setPreferenceData('min_kids_age', low);
    setPreferenceData('max_kids_age', high);
  };

  const renderIcon = (key) => (
    <Cell>
      <IconButton
        disabled={!editMode}
        iconName={preferences[key] ? 'btn-check' : 'btn-plus'}
        iconColor={preferences[key] ? colors.primary : colors.defaultBtnBackgroundColor}
        iconSize="32px"
        onPress={() => setPreferenceData(key, !preferences[key])}
      />
    </Cell>
  );

  const renderDay = (day) => (
    <DayContainer key={day.day_of_week} editMode={editMode}>
      <Text width="120px">
        {moment()
          .day(day.day_of_week + 1)
          .format('dddd')}
      </Text>

      <MomentsContainer>
        <IconButton
          disabled={!editMode}
          width="30%"
          iconName={day.morning ? 'btn-check' : 'btn-plus'}
          iconColor={day.morning ? colors.primary : colors.defaultBtnBackgroundColor}
          iconSize="32px"
          onPress={() => setAvailabilityData(day, 'morning')}
        />

        <IconButton
          disabled={!editMode}
          width="30%"
          iconName={day.afternoon ? 'btn-check' : 'btn-plus'}
          iconColor={day.afternoon ? colors.primary : colors.defaultBtnBackgroundColor}
          iconSize="32px"
          onPress={() => setAvailabilityData(day, 'afternoon')}
        />

        <IconButton
          disabled={!editMode}
          width="30%"
          iconName={day.evening ? 'btn-check' : 'btn-plus'}
          iconColor={day.evening ? colors.primary : colors.defaultBtnBackgroundColor}
          iconSize="32px"
          onPress={() => setAvailabilityData(day, 'evening')}
        />
      </MomentsContainer>
    </DayContainer>
  );

  if (!Object.keys(preferences).length || !availability.length) {
    return <ActivityIndicator />;
  }

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          left={
            <IconButton
              margins="0px 0px 0px 10px"
              iconSize="32px"
              iconSource={leftIcon}
              iconColor={colors.secondary}
              onPress={goBack}
              useIconTintColor
            />
          }
          title={t('profileScreensAngelPersonalSettings')}
          right={
            <TextButton
              text={editMode ? t('save') : t('edit')}
              textColor={colors.secondary}
              onPress={onUpdateSettingsPress}
            />
          }
        />
      )}

      <ScrollView>
        <ButtonSwitch>
          <TextButton
            height="auto"
            width="50%"
            borderRadius="5px"
            backgroundColor={currentTab ? colors.white : colors.primary}
            text={t('profileScreensPreferences')}
            fontSize="16px"
            fontWeight="600"
            textColor={currentTab ? colors.primary : colors.white}
            onPress={() => setCurrentTab(0)}
          />

          <TextButton
            height="auto"
            width="50%"
            borderRadius="5px"
            backgroundColor={currentTab ? colors.primary : colors.white}
            text={t('profileScreensFixedSitting')}
            fontSize="16px"
            fontWeight="600"
            textColor={currentTab ? colors.white : colors.primary}
            onPress={switchToFixed}
          />
        </ButtonSwitch>

        {currentTab === 0 ? (
          <Container editMode={editMode}>
            <TextTextView
              flexDirection="column"
              paddings="20px 20px 20px 20px"
              borderTopWidth="1px"
              backgroundColor={colors.white}
              firstText={t('profileScreensYourPreferences')}
              firstTextFontFamily="Martel"
              firstTextFontSize="16px"
              firstTextFontWeight="600"
              firstTextWidth="100%"
              secondText={t('profileScreensYourPreferencesDescription')}
              secondTextMargins="10px 0px 0px 0px"
              secondTextFontSize="15px"
              secondTextFontWeight="300"
              secondTextAlign="left"
            />

            <Divider />

            <TextView
              flexDirection="column"
              margins="10px 0px 0px 0px"
              paddings="20px"
              width="100%"
              borderBottomWidth="1px"
              text={t('profileScreensBabysitDuration')}
              textMargins="0px 0px 5px 0px"
              textWidth="100%"
              fontSize="16px">
              <View editMode={editMode} alignSelf="flex-end">
                <Cell>
                  <Text fontSize="12px">{t('workweek')}</Text>
                </Cell>

                <Cell>
                  <Text fontSize="12px">{t('weekend')}</Text>
                </Cell>
              </View>

              <TextView
                justifyContent="space-between"
                paddings="10px 0px 5px 0px"
                width="100%"
                text={t('profileScreensToHours', { 0: 2, 1: 4 })}>
                <View editMode={editMode}>
                  {renderIcon('week_duration_request4')}
                  {renderIcon('weekend_duration_request4')}
                </View>
              </TextView>

              <TextView
                justifyContent="space-between"
                paddings="5px 0px 5px 0px"
                width="100%"
                text={t('profileScreensToHours', { 0: 4, 1: 6 })}>
                <View editMode={editMode}>
                  {renderIcon('week_duration_request6')}
                  {renderIcon('weekend_duration_request6')}
                </View>
              </TextView>

              <TextView
                justifyContent="space-between"
                paddings="5px 0px 5px 0px"
                width="100%"
                text={t('profileScreensToHours', { 0: 7, 1: 8 })}>
                <View editMode={editMode}>
                  {renderIcon('week_duration_request8')}
                  {renderIcon('weekend_duration_request8')}
                </View>
              </TextView>

              <TextView
                justifyContent="space-between"
                width="100%"
                paddings="5px 0px 10px 0px"
                text={`> 8 ${t('hours')}`}>
                <View editMode={editMode}>
                  {renderIcon('week_duration_request_over_8')}
                  {renderIcon('weekend_duration_request_over_8')}
                </View>
              </TextView>
            </TextView>

            <TextView
              flexDirection="column"
              margins="10px 0px 0px 0px"
              paddings="20px"
              width="100%"
              borderBottomWidth="1px"
              text={t('profileScreensWorkhours')}
              textMargins="0px 0px 10px 0px"
              textWidth="100%"
              fontSize="16px">
              <View editMode={editMode} alignSelf="flex-end">
                <Cell>
                  <Text fontSize="12px">{t('workweek')}</Text>
                </Cell>

                <Cell>
                  <Text fontSize="12px">{t('weekend')}</Text>
                </Cell>
              </View>

              <TextView
                justifyContent="space-between"
                width="100%"
                textWidth="40%"
                numberOfLines={2}
                text={t('profileScreensEarlyMornings')}>
                <View editMode={editMode}>
                  {renderIcon('week_early_morning')}
                  {renderIcon('weekend_early_morning')}
                </View>
              </TextView>

              <TextView
                justifyContent="space-between"
                paddings="5px 0px 5px 0px"
                width="100%"
                text={t('profileScreensMornings')}>
                <View editMode={editMode}>
                  {renderIcon('week_morning')}
                  {renderIcon('weekend_morning')}
                </View>
              </TextView>

              <TextView
                justifyContent="space-between"
                paddings="5px 0px 5px 0px"
                width="100%"
                text={t('profileScreensAfterschool')}>
                <View editMode={editMode}>
                  {renderIcon('after_school_requests')}
                  <Cell />
                </View>
              </TextView>

              <TextView
                justifyContent="space-between"
                paddings="5px 0px 5px 0px"
                width="100%"
                text={t('profileScreensAfternoons')}>
                <View editMode={editMode}>
                  {renderIcon('week_afternoon')}
                  {renderIcon('weekend_afternoon')}
                </View>
              </TextView>

              <TextView
                justifyContent="space-between"
                width="100%"
                paddings="5px 0px 10px 0px"
                text={t('profileScreensEvening')}>
                <View editMode={editMode}>
                  {renderIcon('week_evening')}
                  {renderIcon('weekend_evening')}
                </View>
              </TextView>

              <TextView
                justifyContent="space-between"
                width="100%"
                textWidth="70%"
                numberOfLines={2}
                paddings="5px 0px 10px 0px"
                text={t('profileScreensLateNight')}>
                <View editMode={editMode}>{renderIcon('late_night_bookings')}</View>
              </TextView>
            </TextView>

            <TextView
              flexDirection="column"
              margins="10px 0px 0px 0px"
              paddings="20px"
              width="100%"
              text={t('profileScreensRequestPreferences')}
              textMargins="0px 0px 10px 0px"
              textWidth="100%"
              fontSize="16px">
              <View editMode={editMode} alignSelf="flex-end">
                <Cell>
                  <Text fontSize="12px">{t('workweek')}</Text>
                </Cell>

                <Cell>
                  <Text fontSize="12px">{t('weekend')}</Text>
                </Cell>
              </View>

              <TextView
                justifyContent="space-between"
                width="100%"
                textWidth="40%"
                numberOfLines={2}
                text={t('signUpAngelScreensRequestLastMinute')}>
                <View editMode={editMode}>
                  {renderIcon('week_last_minute_requests')}
                  {renderIcon('weekend_last_minute_requests')}
                </View>
              </TextView>

              <TextView
                justifyContent="space-between"
                width="100%"
                textWidth="40%"
                numberOfLines={2}
                text={t('signUpAngelScreensRequestToWeeks', { 0: 1, 1: 2 })}>
                <View editMode={editMode}>
                  {renderIcon('week_advanced_notice2')}
                  {renderIcon('weekend_advanced_notice2')}
                </View>
              </TextView>

              <TextView
                justifyContent="space-between"
                paddings="5px 0px 5px 0px"
                width="100%"
                text={t('signUpAngelScreensRequestToWeeks', { 0: 3, 1: 4 })}>
                <View editMode={editMode}>
                  {renderIcon('week_advanced_notice3')}
                  {renderIcon('weekend_advanced_notice3')}
                </View>
              </TextView>

              <TextView
                justifyContent="space-between"
                paddings="5px 0px 5px 0px"
                width="100%"
                text={`> 4 ${t('weeks')}`}>
                <View editMode={editMode}>
                  {renderIcon('week_advanced_notice4')}
                  {renderIcon('weekend_advanced_notice4')}
                </View>
              </TextView>
            </TextView>

            <Divider>
              <Text margins="10px 10px 5px 20px" fontSize="14px" color={colors.grey03}>
                {t('profileScreensGeneralQuestions')}
              </Text>
            </Divider>

            <TextView
              flexDirection="column"
              margins="10px 0px 0px 0px"
              paddings="20px"
              width="100%"
              borderBottomWidth="1px"
              text={t('profileScreensPetsInHouse')}
              textMargins="0px 0px 5px 0px"
              textWidth="100%"
              fontSize="16px">
              <PetContainer>
                <IconView>
                  <Icon name="dog" size="32px" />

                  <Text margins="5px 0px 0px 5px">{t('dog')}</Text>
                </IconView>

                <View editMode={editMode}>{renderIcon('likes_dog')}</View>
              </PetContainer>

              <PetContainer>
                <IconView>
                  <Icon name="cat" size="32px" />

                  <Text margins="5px 0px 0px 5px">{t('cat')}</Text>
                </IconView>

                <View editMode={editMode}>{renderIcon('likes_cat')}</View>
              </PetContainer>
            </TextView>

            <TextView
              flexDirection="column"
              margings="10px 0px 0px 0px"
              paddings="20px"
              width="100%"
              text={t('profileScreensAboutTheFamily')}
              textWidth="100%"
              fontSize="16px">
              <SliderContainer editMode={editMode}>
                <MultiRangeSlider
                  leftText={t('profileScreensMaxNumberKidsDescription')}
                  firstTextFontFamily="Open Sans"
                  firstTextFontSize="14px"
                  secondTextFontSize="15px"
                  rightText={t('profileScreensMaxNumberKids', {
                    maxNumberKids: preferences.max_number_kids
                  })}
                  minValue={2}
                  maxValue={5}
                  initialMin={Number(preferences.max_number_kids)}
                  initialMax={Number(preferences.max_number_kids)}
                  initialLowValue={1}
                  onValueChanged={(value) => setPreferenceData('max_number_kids', value)}
                  rangeEnabled={false}
                />

                <MultiRangeSlider
                  leftText={t('profileScreensKidsAgeDescription')}
                  firstTextFontFamily="Open Sans"
                  firstTextFontSize="14px"
                  secondTextFontSize="15px"
                  rightText={t('profileScreensKidsAge', {
                    minKidsAge: preferences.min_kids_age,
                    maxKidsAge: preferences.max_kids_age
                  })}
                  initialMin={Number(preferences.min_kids_age)}
                  initialMax={Number(preferences.max_kids_age)}
                  minValue={0}
                  maxValue={15}
                  step={1}
                  onValueChanged={setPreferenceDataKids}
                  rangeEnabled
                />

                <MultiRangeSlider
                  leftText={t('profileScreensJourneyTimeDescription')}
                  firstTextFontFamily="Open Sans"
                  firstTextFontSize="14px"
                  secondTextFontSize="15px"
                  rightText={t('profileScreensJourneyTime', {
                    jurneyTime: preferences.journey_time
                  })}
                  minValue={5}
                  maxValue={45}
                  step={5}
                  initialMin={Number(preferences.journey_time)}
                  initialMax={Number(preferences.journey_time)}
                  initialLowValue={5}
                  onValueChanged={(value) => setPreferenceData('journey_time', value)}
                  rangeEnabled={false}
                />
              </SliderContainer>
            </TextView>
          </Container>
        ) : (
          <Container>
            <TextTextView
              flexDirection="column"
              paddings="20px 20px 20px 20px"
              borderTopWidth="1px"
              backgroundColor={colors.white}
              firstText={
                fixedType
                  ? t('profileScreensYourPreferencesFixed')
                  : t('profileScreensYourPreferencesNonFixed')
              }
              firstTextWidth="100%"
              firstTextFontFamily="Martel"
              firstTextFontSize="16px"
              firstTextFontWeight="600"
              secondText={
                fixedType
                  ? t('profileScreensYourPreferencesFixedDescription')
                  : t('profileScreensYourPreferencesNonFixedDescription')
              }
              secondTextMargins="10px 0px 0px 0px"
              secondTextWidth="100%"
              secondTextFontSize="15px"
              secondTextFontWeight="300"
              secondTextAlign="left"
            />

            <TextView
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              margins="10px 20px 10px 20px"
              text={t('profileScreensFixedPreference')}
              textMargins="0px 20px 0px 0px"
              fontWeight="300"
              textWidth="80%">
              <Switch
                disabled={!editMode}
                value={fixedType}
                onValueChange={(value) => {
                  setFixedType(value);
                  onSetWasEdited(true);
                }}
              />
            </TextView>

            {fixedType && (
              <WeekContainer>
                {renderDayIcons(t)}

                {availability.map(renderDay)}
              </WeekContainer>
            )}
          </Container>
        )}
      </ScrollView>
    </SafeAreaView>
  );
};

Preferences.propTypes = {
  history: PropTypes.shape({ goBack: PropTypes.func }),
  navigation: PropTypes.shape({ setOptipons: PropTypes.func, goBack: PropTypes.bool }),
  user: PropTypes.shape({
    angel: PropTypes.shape({ type: PropTypes.string, id: PropTypes.number })
  }).isRequired,
  angel: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  preferences: PropTypes.shape({
    max_number_kids: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    journey_time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    min_kids_age: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max_kids_age: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  wasEdited: PropTypes.bool,
  availability: PropTypes.arrayOf(PropTypes.shape({})),
  getPreferencesData: PropTypes.func.isRequired,
  getAvailabilityData: PropTypes.func.isRequired,
  setAvailabilityData: PropTypes.func.isRequired,
  setPreferenceData: PropTypes.func.isRequired,
  onSetWasEdited: PropTypes.func.isRequired,
  savePreferencesAvailabilityData: PropTypes.func.isRequired,
  updateProfileData: PropTypes.func.isRequired
};

Preferences.defaultProps = {
  history: null,
  navigation: null,
  preferences: {},
  availability: [],
  wasEdited: false
};

const mapStateToProps = ({ User, AngelPreferences }) => ({
  user: User.user,
  preferences: AngelPreferences.preferences,
  availability: AngelPreferences.availability,
  wasEdited: AngelPreferences.wasEdited
});

const mapDispatchToProps = {
  getPreferencesData: getPreferences,
  getAvailabilityData: getAvailability,
  setPreferenceData: setPreference,
  savePreferencesAvailabilityData: savePreferencesAvailability,
  setAvailabilityData: setAvailability,
  updateProfileData: updateProfile,
  onSetWasEdited: setWasEdited
};

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
