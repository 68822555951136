import { getAppVersion } from '@charlycares/shared'
import React, { useEffect, useState } from 'react'

export default function useGetAppVersion() {
  const [appVersion, setAppVersion] = useState<string | null>(null)

  useEffect(() => {
    getAppVersion().then(version => setAppVersion(version))
  }, [])

  return appVersion
}
