import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, HStack, Select } from 'native-base'
import { CharlyIcon } from '@charlycares/ui'
import { useGetTransactionsOverviewLinkMutation } from '@charlycares/data-access'
import { useAlert } from '@charlycares/features'

const TransactionsOverviewDownload = () => {
  const { t } = useTranslation()
  const alert = useAlert()

  const [year, setYear] = useState('')
  const [month, setMonth] = useState('')

  const [getTransactionOverviewLink, { isLoading: isFetchingLink, error: downloadError }] =
    useGetTransactionsOverviewLinkMutation()

  const currentYear = new Date().getFullYear()
  const months = [
    { value: '1', label: t('january') },
    { value: '2', label: t('february') },
    { value: '3', label: t('march') },
    { value: '4', label: t('april') },
    { value: '5', label: t('may') },
    { value: '6', label: t('june') },
    { value: '7', label: t('july') },
    { value: '8', label: t('august') },
    { value: '9', label: t('september') },
    { value: '10', label: t('october') },
    { value: '11', label: t('november') },
    { value: '12', label: t('december') }
  ]

  const getLink = () => {
    if (year) {
      getTransactionOverviewLink({ year: year, month: month })
    }
    setYear('')
    setMonth('')
  }

  useEffect(() => {
    if (downloadError) {
      alert.show(t('downloadFailed'), (downloadError as any)?.data?.error || t('error'))
    }
  }, [downloadError])

  return (
    <HStack alignItems="center" justifyContent="space-between" flexDirection="row" minH="50px">
      <Select
        selectedValue={year}
        onValueChange={value => setYear(value)}
        isDisabled={isFetchingLink}
        placeholder={t('selectYear')}
        bgColor="gray.50"
        rounded="md"
        borderWidth="1px"
        placeholderTextColor="gray.900"
        _actionSheetContent={{
          bgColor: 'white'
        }}
        _actionSheetBody={{
          bgColor: 'white'
        }}
        _item={{
          bgColor: 'white'
        }}
        minH="40px"
        maxH="40px"
        flex={1}
        mr="4px"
        dropdownIcon={<CharlyIcon name="icn-caret-down" size="24px" color="gray.700" />}
      >
        {Array.from({ length: currentYear - 2022 }, (_, i) => (
          <Select.Item key={i} label={`${currentYear - i}`} value={`${currentYear - i}`} />
        ))}
      </Select>
      {/*<Select*/}
      {/*  selectedValue={month}*/}
      {/*  onValueChange={value => setMonth(value)}*/}
      {/*  isDisabled={isFetchingLink}*/}
      {/*  placeholder={t('month')}*/}
      {/*  bgColor="gray.50"*/}
      {/*  rounded="md"*/}
      {/*  borderWidth="1px"*/}
      {/*  placeholderTextColor="gray.900"*/}
      {/*  _actionSheetContent={{*/}
      {/*    bgColor: 'white'*/}
      {/*  }}*/}
      {/*  _actionSheetBody={{*/}
      {/*    bgColor: 'white'*/}
      {/*  }}*/}
      {/*  _item={{*/}
      {/*    bgColor: 'white'*/}
      {/*  }}*/}
      {/*  minH="40px"*/}
      {/*  maxH="40px"*/}
      {/*  flex={1}*/}
      {/*  dropdownIcon={<CharlyIcon name="icn-caret-down" size="24px" color="gray.700" />}*/}
      {/*>*/}
      {/*  <Select.Item key="0" label={t('All months')} value="" />*/}
      {/*  {months.map(month => (*/}
      {/*    <Select.Item key={month.value} label={month.label} value={month.value} py="8px" />*/}
      {/*  ))}*/}
      {/*</Select>*/}
      <Button
        variant="unstyled"
        disabled={!year || isFetchingLink}
        onPress={() => getLink()}
        isLoading={isFetchingLink}
        minW="130px"
        w="130px"
        _text={{ color: year ? 'secondary.400' : 'gray.700', fontSize: '16px', fontWeight: '600' }}
      >
        {t('download')}
      </Button>
    </HStack>
  )
}

export default TransactionsOverviewDownload
