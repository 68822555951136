import React from 'react'
import { Slider as SliderRange } from '@miblanchard/react-native-slider'
import { useTheme } from 'native-base'

type Props = {
  value: number | number[]
  max?: number
  min?: number
  step?: number
  onValueChange: (value: number | number[]) => void
}

const Slider = ({ value, max, min, step, onValueChange, ...sliderProps }: Props) => {
  const { colors } = useTheme()
  return (
    <SliderRange
      step={step}
      value={value}
      minimumValue={min}
      maximumValue={max}
      onValueChange={onValueChange}
      thumbStyle={{
        borderWidth: 1,
        borderColor: colors.gray[200],
        width: 31,
        height: 31,
        borderRadius: 30,
        backgroundColor: colors.white
      }}
      trackStyle={{
        height: 8,
        borderRadius: 3
      }}
      minimumTrackTintColor={colors.secondary[500]}
      maximumTrackTintColor={colors.gray[200]}
      {...sliderProps}
    />
  )
}

export default Slider
