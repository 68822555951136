import React from 'react'
import { Card, CharlyIcon } from '@charlycares/ui'
import { useRouter } from '@charlycares/shared'
import { useTranslation } from 'react-i18next'
import { Button, Heading, HStack, Text } from 'native-base'

const InsuranceCard = () => {
  const { navigate } = useRouter()
  const { t } = useTranslation()

  return (
    <Card mt="10px">
      <HStack space="10px" alignItems="center">
        <CharlyIcon color="primary.400" size={30} name="icn-insurance" />
        <Heading fontSize="18px" fontWeight={600}>
          {t('bookRequestSentUspTitle')}
        </Heading>
      </HStack>

      <Text mt="15px">{t('bookRequestSentUspDescription')}</Text>

      <Button my="30px" w="100%" onPress={() => navigate('WebView', undefined, { uri: t('bookRequestSentUspUrl') })}>
        {t('bookRequestSentUspBtn')}
      </Button>
    </Card>
  )
}

export default InsuranceCard
