import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';

import defaultAngelImage from '../../../assets/images/defaultAngelImage.png';

const View = styled.View`
  ${({ alignItems }) => alignItems && { 'align-items': alignItems }};
  overflow: hidden;
  margin: ${({ margins }) => margins};
`;
const Image = styled.Image`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
`;

const AngelAvatar = ({ alignItems, margins, source, size, imageBlur, defaultImage, children }) => (
  <View alignItems={alignItems} margins={margins}>
    <Image
      size={size}
      source={source ? { uri: source } : defaultImage || defaultAngelImage}
      blurRadius={imageBlur}
    />

    {children}
  </View>
);

AngelAvatar.propTypes = {
  alignItems: PropTypes.string,
  margins: PropTypes.string,
  source: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imageBlur: PropTypes.number,
  defaultImage: PropTypes.node,
  children: PropTypes.node
};

AngelAvatar.defaultProps = {
  alignItems: null,
  margins: '0px',
  size: '41px',
  source: null,
  imageBlur: 0,
  defaultImage: defaultAngelImage,
  children: null
};

export default AngelAvatar;
