import React from 'react'
import { Box, Button } from 'native-base'

type Props = {
  activeTab: string
  tabs: { label: string; key: string }[]
  onChange?: (tab: string) => void
}

const ButtonsTabs = ({ activeTab, tabs, onChange }: Props) => {
  return (
    <Box w="100%" h="32px" flexDir="row" p="2px" bg="rgba(48, 48, 54, 0.15)" borderRadius="9px">
      {tabs.map(tab => (
        <Button
          key={tab.key}
          onPress={() => onChange?.(tab.key)}
          bg={activeTab === tab.key ? 'white' : 'transparent'}
          borderRadius="7px"
          variant="text"
          flex={1}
          _text={{
            color: 'text.dark.400',
            fontWeight: 600,
            fontSize: '13px'
          }}
        >
          {tab.label}
        </Button>
      ))}
    </Box>
  )
}

export default ButtonsTabs
