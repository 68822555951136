import axiosInstance from '../../utils/axios';
import {
  SET_PREFERENCES,
  SAVE_REFERENCE,
  SET_AVAILABILITY,
  SET_PREFERENCE,
  SET_WAS_EDITED,
  GET_AVAILABILITY,
  SET_AVAILABILITIES
} from '../types';

export const getPreferences = () => (dispatch) => {
  axiosInstance
    .get('angel/preferences')
    .then((response) => {
      if (response.data) {
        const { data } = response.data;
        const preferences = {};
        Object.keys(data).forEach((preference) => {
          preferences[data[preference].key] = data[preference].pivot.value;
        });
        dispatch({
          type: SET_PREFERENCES,
          payload: preferences
        });
      }
    })
    .catch((error) => console.log({ error }));
};

export const savePreferences = (preferences) => (dispatch) => {
  const dataToSend = Object.keys(preferences).map((key) => ({ key, value: preferences[key] }));
  axiosInstance
    .put('angel/preferences', dataToSend)
    .then((response) => {
      if (response.data) {
        const { data } = response.data;
        const newPreferences = {};
        Object.keys(data).forEach((preference) => {
          newPreferences[data[preference].key] = data[preference].pivot.value;
        });
        dispatch({
          type: SAVE_REFERENCE,
          payload: newPreferences
        });
      }
    })
    .catch((error) => console.log({ error }));
};

export const getAvailability = (angelId) => (dispatch) => {
  axiosInstance
    .get(`angel/calendar/availability${angelId ? `/${angelId}` : ''}`)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: SET_AVAILABILITIES,
          payload: data.data
        });
      }
    })
    .catch((error) => console.log({ error }));
};

export const saveAvailability = (availabilities) => (dispatch) => {
  axiosInstance
    .put('angel/calendar/availability', { availabilities })
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: SET_AVAILABILITIES,
          payload: data.data
        });
      }
    })
    .catch((error) => console.log({ error }));
};

export const setPreference = (key, value = false) => (dispatch) => {
  dispatch({
    type: SET_PREFERENCE,
    payload: { key, value }
  });
};

export const setAvailability = (day, partOfDay) => (dispatch) => {
  dispatch({
    type: SET_AVAILABILITY,
    payload: { day, partOfDay }
  });
};

export const setWasEdited = (bool) => (dispatch) => {
  dispatch({
    type: SET_WAS_EDITED,
    payload: bool
  });
};

export const savePreferencesAvailability = (preferences, availability) => (dispatch) => {
  const data = [];
  Object.keys(preferences).forEach((key) => {
    data.push({ key, value: preferences[key] });
  });
  axiosInstance
    .put('angel/preferences', data)
    .then((response) => {
      if (response.data) {
        const preferencesData = {};
        Object.keys(response.data.data).forEach((key) => {
          preferencesData[response.data.data[key].key] = response.data.data[key].pivot.value;
        });
        console.log('save preferences', preferencesData);
        dispatch({
          type: SET_PREFERENCES,
          payload: preferencesData
        });
      }
    })
    .catch((error) => console.log({ error }));

  console.log({ availability });
  axiosInstance
    .put('angel/calendar/availability', { availabilities: availability })
    .then((response) => {
      if (response.data) {
        console.log('save availability', response.data);
        dispatch({
          type: GET_AVAILABILITY,
          payload: response.data.data
        });
      }
    })
    .catch((error) => console.log({ error }));
};
