import React from 'react'
import { Badge, Box, HStack, IBoxProps, Text } from 'native-base'
import { BookingDate, formatDate } from '@charlycares/shared'
import moment from 'moment'
import useBookingDates from '../bookings/common/useBookingDates'

type Props = IBoxProps & {
  startsAt?: string
  endsAt?: string
  bookingDates: BookingDate[]
}

const BookingCardDates = ({ startsAt, endsAt, bookingDates }: Props) => {
  const { startDate, firstBookingEndDate, lastBookingStartDate, bookingsCount, days } = useBookingDates(bookingDates)

  const isSameDay = moment(startsAt).isSame(moment(endsAt), 'day')

  return (
    <Box flex={1}>
      {bookingsCount > 1 ? (
        <HStack space="4px">
          <Box mt="5px">
            <HStack space="4px">
              <Text fontSize="12px" fontWeight="400">
                {days.length > 1 ? days.join(', ') : formatDate(startDate, 'dddd')}
              </Text>

              <Badge px="2px" py="0.5px" bg="gray.alpha900.15" borderRadius="10px">
                <Text fontSize="10px" fontWeight="400">
                  {bookingsCount}X
                </Text>
              </Badge>

              <Text fontSize="12px">{`${formatDate(startDate, {
                nl: 'D MMM',
                en: 'MMM. D'
              })} - ${formatDate(lastBookingStartDate, {
                nl: 'D MMM',
                en: 'MMM. D'
              })}`}</Text>
            </HStack>
            <Text fontSize="16px" fontWeight="600">
              {`${formatDate(startDate, 'HH:mm')} - ${formatDate(firstBookingEndDate, 'HH:mm')}`}
            </Text>
          </Box>
        </HStack>
      ) : (
        Boolean(startsAt && endsAt) && (
          <HStack mt="5px" space="10px">
            <Box>
              <Text fontSize="12px" fontWeight="400">{`${formatDate(startsAt, {
                nl: 'dddd D MMM',
                en: 'dddd MMM. D'
              })}`}</Text>

              <Text fontSize="16px" fontWeight="600">{`${formatDate(startsAt, 'HH:mm')} ${
                isSameDay ? `- ${formatDate(endsAt, 'HH:mm')}` : ''
              }`}</Text>
            </Box>
            {!isSameDay && (
              <Box>
                <Text fontSize="12px" fontWeight="400">{`${formatDate(endsAt, {
                  nl: 'dddd D MMM',
                  en: 'dddd MMM. D'
                })}`}</Text>

                <Text fontSize="16px" fontWeight="600">
                  {formatDate(endsAt, 'HH:mm')}
                </Text>
              </Box>
            )}
          </HStack>
        )
      )}
    </Box>
  )
}

export default BookingCardDates
