import React from 'react'
import { Card, CharlyIcon, Checkbox } from '@charlycares/ui'
import { Box, Button, Divider, Flex, Heading, IBoxProps, Stack, Text } from 'native-base'
import { BookingDate, formatDate, getSelectedDatesCount } from '@charlycares/shared'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

type Props = IBoxProps & {
  bookings: BookingDate[]
  selectedDates?: Omit<BookingDate, 'repeat_days'>[]
  onChange?: (dates: Omit<BookingDate, 'repeat_days'>[]) => void
  readonly?: boolean
}

const BookingDates = ({ bookings, selectedDates, onChange, readonly, ...boxProps }: Props) => {
  const { t } = useTranslation()

  const onSelect = (date: BookingDate | Omit<BookingDate, 'repeat_days'>) => {
    if (selectedDates && onChange) {
      const index = selectedDates.findIndex(item => item.obscured_id === date.obscured_id)
      if (index !== -1) return

      onChange([...selectedDates, date])
    }
  }

  const onRemove = (date: BookingDate | Omit<BookingDate, 'repeat_days'>) => {
    if (selectedDates && onChange) {
      onChange(selectedDates.filter(item => item.obscured_id !== date.obscured_id))
    }
  }

  const selectAll = (booking: BookingDate) => {
    if (selectedDates && onChange) {
      const dates = [...selectedDates, booking, ...booking.repeat_days]
      const uniqDates = _.uniqBy(dates, item => item.obscured_id)
      onChange(uniqDates)
    }
  }

  const deselectAll = (booking: BookingDate) => {
    if (selectedDates && onChange) {
      const dates = [booking, ...booking.repeat_days]
      const filtered = selectedDates.filter(item => !dates.find(date => date.obscured_id === item.obscured_id))
      onChange(filtered)
    }
  }

  return (
    <Card {...boxProps}>
      <Stack space="40px">
        {bookings.map(booking => {
          const childrenSelected = getSelectedDatesCount(booking, selectedDates)
          const isSelected = !!selectedDates?.find(item => item.obscured_id === booking.obscured_id)

          return (
            <Box w="100%" key={booking.obscured_id}>
              <Flex direction="row" justify="space-between" align="center">
                <Heading>{formatDate(booking.start_date, 'dddd')}</Heading>
                <Text fontWeight="600" fontSize="16px">{`${formatDate(booking.start_date, 'HH:mm')} - ${formatDate(
                  booking.end_date,
                  'HH:mm'
                )}`}</Text>
              </Flex>

              {!readonly && (
                <Flex mt="10px" direction="row" justify="space-between" align="center">
                  <Text fontSize="15px">{`${childrenSelected} / ${booking.repeat_days.length + 1}`}</Text>
                  <Button
                    onPress={() =>
                      childrenSelected === booking.repeat_days.length + 1 ? deselectAll(booking) : selectAll(booking)
                    }
                    _text={{ color: 'secondary.400', fontSize: '15px' }}
                    variant="text"
                  >
                    {childrenSelected === booking.repeat_days.length + 1 ? t('deselectAll') : t('selectAll')}
                  </Button>
                </Flex>
              )}

              <Divider my="15px" />

              <Stack space="20px">
                <Flex direction="row" justify="space-between" align="center" key={booking.obscured_id}>
                  <Text fontSize="16px">
                    {formatDate(booking.start_date, {
                      nl: 'D MMMM',
                      en: 'MMMM D'
                    })}
                  </Text>
                  {readonly ? (
                    isSelected ? (
                      <CharlyIcon name="icn-check" size={30} color="secondary.400" />
                    ) : null
                  ) : (
                    <Checkbox
                      onChange={isChecked => (isChecked ? onSelect(booking) : onRemove(booking))}
                      isChecked={isSelected}
                    />
                  )}
                </Flex>

                {booking.repeat_days.map(repeatBooking => {
                  const repeatedSelected = !!selectedDates?.find(item => item.obscured_id === repeatBooking.obscured_id)

                  return (
                    <Flex direction="row" justify="space-between" align="center" key={repeatBooking.obscured_id}>
                      <Text fontSize="16px">
                        {formatDate(repeatBooking.start_date, {
                          nl: 'D MMMM',
                          en: 'MMMM D'
                        })}
                      </Text>
                      {readonly ? (
                        repeatedSelected ? (
                          <CharlyIcon name="icn-check" size={30} color="secondary.400" />
                        ) : null
                      ) : (
                        <Checkbox
                          isChecked={repeatedSelected}
                          onChange={isChecked => (isChecked ? onSelect(repeatBooking) : onRemove(repeatBooking))}
                        />
                      )}
                    </Flex>
                  )
                })}
              </Stack>
            </Box>
          )
        })}
      </Stack>
    </Card>
  )
}

export default BookingDates
