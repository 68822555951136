export const colors = {
  primaryDark: '#062D51',
  primary: '#0B5394',
  primaryAlpha: (alpha: number) => `rgba(11, 83, 148, ${alpha})`,
  primaryLight: '#DEEDF1',

  secondary: '#F56B87',
  secondaryLight: '#F1D4DC',

  primaryText: '#303036',
  secondaryText: '#fff',
  buttonText: '#97979a',

  background: '#f9f8f9',
  cardBackground: '#fff',
  transparentModal: '#30303680',
  header: '#fff',

  warning: '#ffba59',
  success: '#33D69D',
  success02: '#C1F2E1',
  danger: '#DD0000',
  notify: '#4A8FE2',
  chat: '#DFF1D4',
  warningAlpha: (alpha: number) => `rgba(255, 186, 89, ${alpha})`,
  successAlpha: (alpha: number) => `rgba(51, 214, 157, ${alpha})`,
  grayAlpha: (alpha: number) => `rgba(48, 48, 54, ${alpha})`,

  black: '#000',
  grey01: '#303036',
  grey02: '#68686e',
  grey03: '#97979a',
  grey04: '#a9a9ac',
  grey05: '#c7c7c9',
  grey06: '#d9d9d9',
  grey07: '#dedede',
  grey08: '#e6e6e6',
  grey09: '#f2f2f2',
  grey10: '#fafafa',
  white: '#fff'
}

export type Colors = typeof colors
