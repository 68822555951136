import React, { useState, useEffect, useRef } from 'react'
import { FlatList } from 'react-native'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getConversations, getActiveTimings, updateConversationLastMessage } from '../../stores/actions/chatAction'
import { getIndicators } from '../../stores/actions/userAction'
import { Text, Icon, Divider } from '../../elements'
import { InputView, ContactCard, ActiveBookingTimer, Header, Alert } from '../../components'
import { getDuration } from '../../utils/functions'
import colors from '../../styles/colors'

import searchIcon from '../../../assets/icons/icn-search.png'

const SafeAreaView = styled.SafeAreaView`
  flex: 1 1 auto;
`
const StyledFlatList = styled(FlatList).attrs({
  contentContainerStyle: {
    backgroundColor: colors.grey08
  }
})``

const View = styled.View`
  flex-direction: column-reverse;
  padding: 0px 15px 15px 15px;
  width: 100%;
  height: 70px;
  background-color: ${colors.backgroundColor};
  border-bottom-width: 1px;
  border-color: ${colors.grey08};
`
const ContactsBackground = styled.View`
  background-color: ${colors.grey08};
`
const ActiveBookingView = styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.grey08};
`

const Inbox = ({
  location,
  history,
  navigation,
  socket,
  user,
  conversations,
  activeBooking,
  openRoom,
  updateLastMessage,
  getIndicatorsData,
  getAllActiveTimings,
  getAllConversations
}) => {
  const [search, setSearch] = useState('')
  const [conversationsActive, setConversationActive] = useState([])
  const [conversationsTyping, setConversationTyping] = useState([])
  const [showRoom, setShowRoom] = useState(
    location.state && (!!location.state.conversationId || !!location.state.showRoom)
  )
  const [alertData, setAlertData] = useState(null)
  const alertRef = useRef(null)
  const { role } = user
  const [t] = useTranslation()

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('chatScreensChat')}
        </Text>
      ),
      tabBarVisible: true
    })
  }

  useEffect(() => {
    if (alertData) {
      if (alertRef.current) {
        alertRef.current.setIsVisible(true)
      }
    }
  }, [alertData])

  useEffect(() => {
    getIndicatorsData()
    getAllActiveTimings()
    getAllConversations()

    return () => {
      if (socket.connected) {
        socket.removeAllListeners()
      }
    }
  }, [])

  const setIsOnline = (conversationId, isOnline) => {
    if (isOnline) {
      if (!conversationsActive.includes(conversationId)) {
        setConversationActive([...conversationsActive, conversationId])
      }
    } else {
      setConversationActive(conversationsActive.filter(id => id !== conversationId))
    }
  }

  const setIsTyping = (conversationId, isTyping) => {
    if (isTyping) {
      if (!conversationsTyping.includes(conversationId)) {
        setConversationTyping([...conversationsTyping, conversationId])
      }
    } else {
      setConversationTyping(conversationsTyping.filter(id => id !== conversationId))
    }
  }

  const shouldUpdateLastMessage = (conversationId, userId) => {
    console.log('shouldUpdateLastMessage')
    const currentConversation = conversations.find(({ conversation_id }) => conversation_id === conversationId)
    const { last_message } = currentConversation
    if (last_message && userId === last_message.user_id) {
      const viewed_at = moment().format('YYYY-MM-DD HH:mm:ss')
      updateLastMessage(conversationId, { viewed_at })
    }
  }

  const initInboxSocket = () => {
    console.log('initInboxSocket', conversations)
    socket.removeAllListeners()
    socket.on('connect', () => {
      console.log('socket connected Inbox')
    })
    socket.on('disconnect', () => {
      console.log('disconnect Inbox')
    })
    conversations.forEach(conversation => {
      const { conversation_id, user_id } = conversation
      socket.on(`INIT_CHAT_CONVERSATION_${conversation_id}`, userId => {
        console.log('INIT_CHAT_CONVERSATION INBOX')
        console.log({ socket })
        if (userId !== user_id) {
          setIsOnline(conversation_id, true)
        }
        shouldUpdateLastMessage(conversation_id, userId)
      })
      socket.on(`CLOSE_CHAT_CONVERSATION_${conversation_id}`, userId => {
        console.log('CLOSE_CHAT_CONVERSATION_ INBOX')
        if (userId !== user_id) {
          setIsOnline(conversation_id, false)
        }
      })
      socket.on(`CHAT_SEND_${conversation_id}`, message => {
        console.log('CHAT_SEND_ INBOX')
        if (message.user_id === user_id) {
          updateLastMessage(conversation_id, message)
        }
      })
      socket.on(`CHAT_TYPING_${conversation_id}`, userId => {
        console.log('CHAT_TYPING_ INBOX')
        if (userId !== user_id) {
          setIsTyping(conversation_id, true)
        }
      })
      socket.on(`CHAT_TYPING_CANCEL_${conversation_id}`, userId => {
        console.log('CHAT_TYPING_CANCEL_ INBOX')
        if (userId !== user_id) {
          setIsTyping(conversation_id, false)
        }
      })
    })
    console.log('connect inbox')
    socket.connect()
  }

  useEffect(() => {
    if (conversations.length > 0) {
      const { conversationId, conversation, ...restProps } = location.state || {}
      if (showRoom) {
        let currentConversation = conversation
        if (conversationId) {
          const [curentConv] = conversations.filter(conv => conv.conversation_id === conversationId)
          currentConversation = curentConv
        }
        setShowRoom(false)
        openRoom({ conversation: currentConversation, ...restProps })
        return
      }
      if (!socket.connected) {
        initInboxSocket()
      }
    }
  }, [conversations])

  const onConversationProfilePress = conversation => {
    if (user.role === 'angel') {
      history.push({
        pathname: '/profile-family',
        state: { familyId: conversation.user.family.obscured_id || conversation.user.family.id }
      })
    } else {
      history.push({
        pathname: '/profile-angel',
        state: { angelId: conversation.obscured_id || role_id }
      })
    }
  }

  const getActiveBookingConversations = () => {
    let activeBookingConversation = null
    if (activeBooking) {
      const { family, angel } = activeBooking
      const { user_id } = role === 'angel' ? family : angel
      const [activeConversation] = conversations.filter(conversation => conversation.user_id === user_id)
      activeBookingConversation = activeConversation
    }
    return activeBookingConversation
  }

  const getBookingDuration = () => {
    let duration = null
    if (activeBooking) {
      const { start_time } = activeBooking
      duration = getDuration(t, start_time, moment().format('YYYY-MM-DD HH:mm:ss'))
    }
    return duration
  }

  const getConversationsToShow = activeBookingConversation => {
    let conversationsToShow = conversations
    if (activeBookingConversation) {
      conversationsToShow = conversationsToShow.filter(conversation => conversation !== activeBookingConversation)
    }
    if (search !== '') {
      conversationsToShow = conversationsToShow.filter(({ first_name, last_name }) =>
        `${first_name} ${last_name}`.toLowerCase().includes(search.toLowerCase())
      )
    }
    return conversationsToShow
  }

  const activeBookingConversation = getActiveBookingConversations()
  const conversationsToShow = getConversationsToShow(activeBookingConversation)
  const duration = getBookingDuration()

  return (
    <SafeAreaView>
      <Header title={t('chatScreensChat')} />

      {activeBookingConversation && (
        <>
          <Divider />

          <ActiveBookingView>
            <ContactsBackground>
              <ContactCard
                isActive={conversationsActive.includes(activeBookingConversation.conversation_id)}
                isTyping={conversationsTyping.includes(activeBookingConversation.conversation_id)}
                borderBottomWidth={0}
                contactInfo={activeBookingConversation}
                onCardPress={(isActive, isTyping) => {
                  if (navigation) {
                    navigation.navigate('ChatScreen', {
                      conversation: activeBookingConversation,
                      isActive,
                      isTyping,
                      setIsOnline
                    })
                  } else {
                    openRoom({
                      conversation: activeBookingConversation,
                      isActive,
                      isTyping
                    })
                  }
                }}
                onBookPress={() => {
                  history.push({
                    pathname: '/book-direct',
                    state: {
                      angelId: role === 'angel' ? activeBookingConversation.obscured_id : undefined,
                      familyId: role === 'family' ? activeBookingConversation.obscured_id : undefined
                    }
                  })
                }}
                onProfilePress={() => onConversationProfilePress(activeBookingConversation)}
                onCalendarPress={() => {
                  if (navigation) {
                    navigation.navigate('FamilyCalendar', {
                      angelId: activeBookingConversation.user_id,
                      angelName: activeBookingConversation.first_name
                    })
                  } else {
                    history.push({
                      pathname: '/calendar-family',
                      state: {
                        angelId: activeBookingConversation.user_id,
                        angelName: activeBookingConversation.first_name
                      }
                    })
                  }
                }}
              />
            </ContactsBackground>
          </ActiveBookingView>
        </>
      )}

      <FlatList
        extraData={search}
        keyboardShouldPersistTaps="always"
        data={conversationsToShow}
        ListHeaderComponent={
          <>
            <View>
              <InputView
                accessibilityRole="search"
                flexDirection="row"
                paddings="5px 10px 5px 10px"
                borderRadius="10px"
                backgroundColor={colors.grey08}
                blurOnSubmit
                inputFlex={1}
                returnKeyType="done"
                minInputHeight="auto"
                placeholder={user.role === 'family' ? t('chatScreensFindAngel') : t('chatScreensFindFamily')}
                value={search}
                onChangeText={setSearch}
              >
                <Icon margins="0px 10px 0px 0px" size="25px" source={searchIcon} />
              </InputView>
            </View>

            <Divider />
          </>
        }
        renderItem={({ item }) => {
          const { conversation_id, user_id, first_name, role_id, obscured_id } = item

          return (
            <ContactCard
              isActive={conversationsActive.includes(conversation_id)}
              isTyping={conversationsTyping.includes(conversation_id)}
              contactInfo={item}
              onCardPress={(isActive, isTyping) => {
                if (navigation) {
                  navigation.navigate('ChatScreen', {
                    conversation: item,
                    isActive,
                    isTyping,
                    setIsOnline
                  })
                } else {
                  openRoom({ conversation: item, isActive, isTyping })
                }
              }}
              onBookPress={() => {
                history.push({
                  pathname: '/book-direct',
                  state: {
                    angelId: role === 'angel' ? obscured_id : undefined,
                    familyId: role === 'family' ? obscured_id : undefined
                  }
                })
              }}
              onProfilePress={() => onConversationProfilePress(item)}
              onCalendarPress={() => {
                history.push({
                  pathname: '/calendar-family',
                  state: { angelId: role_id, angelName: first_name }
                })
              }}
            />
          )
        }}
        keyExtractor={item => item.id.toString()}
      />

      {alertData && !navigation && <Alert ref={alertRef} maxWidth={400} showSecondConfirmButton {...alertData} />}
    </SafeAreaView>
  )
}

Inbox.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      conversationId: PropTypes.string,
      showRoom: PropTypes.bool
    })
  }),
  history: PropTypes.shape({ push: PropTypes.func }),
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    setOptions: PropTypes.func
  }),
  socket: PropTypes.shape({
    on: PropTypes.func,
    removeAllListeners: PropTypes.func,
    connect: PropTypes.func,
    connected: PropTypes.bool
  }).isRequired,
  openRoom: PropTypes.func.isRequired,
  user: PropTypes.shape({
    role: PropTypes.string,
    has_membership: PropTypes.bool
  }),
  conversations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeBooking: PropTypes.shape({
    family: PropTypes.shape({}),
    angel: PropTypes.shape({}),
    start_time: PropTypes.string,
    booking_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    cancellation_reasons: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  getAllConversations: PropTypes.func.isRequired,
  getAllActiveTimings: PropTypes.func.isRequired,
  updateLastMessage: PropTypes.func.isRequired,
  getIndicatorsData: PropTypes.func.isRequired
}

Inbox.defaultProps = {
  location: {},
  history: null,
  navigation: null,
  activeBooking: null,
  user: {}
}

const mapStateToProps = ({ User, Chat }) => ({
  lookAround: User.lookAround,
  user: User.user,
  conversations: Chat.conversations,
  activeBooking: Chat.activeBooking
})

const mapDispatchToProps = {
  getAllConversations: getConversations,
  getAllActiveTimings: getActiveTimings,
  updateLastMessage: updateConversationLastMessage,
  getIndicatorsData: getIndicators
}

export default connect(mapStateToProps, mapDispatchToProps)(Inbox)
