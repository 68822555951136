import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getIndicators, setLocale, resetUpdateProfile } from '../../../stores/actions/userAction'
import { getRatings } from '../../../stores/actions/ratingsAction'
import { Text, Icon, Divider } from '../../../elements'
import { TextView, TextButton, ProfileHeader, Header } from '../../../components'
import {
  roundMonetaryValue,
  getNumberOfProfileMissingFields,
  formatRating,
  changeMomentLocale
} from '../../../utils/functions'
import colors from '../../../styles/colors'

import rightArrowIcon from '../../../../assets/icons/icn-nav-right.png'
import { Linking } from 'react-native'

const ScrollView = styled.ScrollView`
  flex: 1;
`

const Profile = ({ history, navigation, user, getIndicatorsData, getRatingsData, resetUpdateProfileData }) => {
  const [t, i18n] = useTranslation()
  const { image, first_name, last_name, angel, role, locale, account_number, languages, payments_total, credit } = user
  const { short_bio, video, average_rating } = angel

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('profile')}
        </Text>
      ),
      headerRight: (
        <TextButton
          text={t('profileScreensNeedHelp')}
          textColor={colors.secondary}
          onPress={() => Linking.openURL('https://support.charlycares.com')}
        />
      )
    })
  }

  useEffect(() => {
    getRatingsData()
    resetUpdateProfileData()
    let focusListener = () => {}
    if (navigation) {
      focusListener = navigation.addListener('didFocus', () => {
        getIndicatorsData()
      })
    }

    return focusListener
  }, [])

  const getNumberOfMissingPersonalSettingsFields = () => {
    const { dutch, english, french, german, italian, spanish } = languages
    const allLanguages = [dutch, english, french, german, italian, spanish]
    const fields = [account_number, video, allLanguages.filter(language => language).length]

    return fields.filter(field => !field).length
  }

  const getItemsToRender = () => {
    const items = [
      {
        title: t('profileScreensProfileAccount'),
        description: t('profileScreensIncompleteProfile'),
        numberOfMissingFields: getNumberOfProfileMissingFields(user),
        navigateTo: navigation ? 'ProfileAccount' : '/profile-account',
        missingFieldsNavigateTo: navigation ? 'ProfileEdit' : '/profile-edit',
        navigateParams: { shouldGoBack: false }
      },
      {
        title: t('profileScreensAngelPersonalSettings'),
        description: t('profileScreensIncomplete'),
        numberOfMissingFields: getNumberOfMissingPersonalSettingsFields(),
        navigateTo: navigation ? 'ProfilePersonalSettings' : '/profile-settings',
        missingFieldsNavigateTo: navigation ? 'ProfilePersonalSettings' : '/profile-settings-edit'
      },
      {
        title: t('profileScreensSittingPreferences'),
        navigateTo: navigation ? 'ProfileAngelPreferences' : '/profile-preferences'
      },
      {
        title: t('angelRatingsAndReviews'),
        navigateTo: '/profile-reviews',
        description: `rating ${formatRating(average_rating)}`,
        fontSizeDescription: '17px'
      },
      {
        title: t('profileScreensAngelPromotions'),
        description: `€ ${roundMonetaryValue(credit)}`,
        navigateTo: navigation ? 'Promotions' : '/profile-promotions',
        fontSizeDescription: '17px'
      },
      {
        title: t('profileScreensGeneralContitions'),
        navigateTo: navigation ? 'GeneralConditions' : '/profile-general-conditions'
      }
    ]

    return items
  }

  const items = getItemsToRender()

  return (
    <ScrollView>
      {!navigation && (
        <Header
          title={t('profile')}
          right={
            <TextButton
              text={t('profileScreensNeedHelp')}
              textColor={colors.secondary}
              onPress={() => Linking.openURL('https://support.charlycares.com')}
            />
          }
        />
      )}
      <Divider />

      <ProfileHeader
        image={image}
        bio={short_bio}
        firstName={first_name}
        lastName={last_name}
        isAngel={role === 'angel'}
        showPlaceholder={role !== 'angel'}
        onLanguagePress={language => {
          i18n.changeLanguage(language)
          changeMomentLocale(language === 'nl_NL' ? 'nl' : 'en')
        }}
        selectedLanguage={locale}
      />

      {items.map(
        ({
          title,
          description,
          numberOfMissingFields,
          navigateTo,
          missingFieldsNavigateTo,
          navigateParams,
          fontSizeDescription
        }) => (
          <TextButton
            key={title}
            flexDirection="row"
            justifyContent="space-between"
            paddings="15px 0px 15px 0px"
            margins="0px 0px 0px 20px"
            height="auto"
            borderWidth={0}
            borderBottomWidth="1px"
            borderTopWidth={0}
            borderRadius="0px"
            borderColor={colors.grey08}
            text={title}
            fontWeight={100}
            textColor={colors.primaryText}
            onPress={() => {
              if (navigation) {
                navigation.navigate(
                  numberOfMissingFields > 0 ? missingFieldsNavigateTo : navigateTo,
                  navigateParams || {}
                )
              } else {
                history.push(numberOfMissingFields > 0 ? missingFieldsNavigateTo : navigateTo)
              }
            }}
          >
            <TextView
              text={numberOfMissingFields === 0 || !description ? '' : description}
              fontSize={fontSizeDescription || '14px'}
              textColor={numberOfMissingFields > 0 ? colors.primary : colors.grey03}
            >
              {numberOfMissingFields > 0 && (
                <TextView
                  justifyContent="center"
                  margins="0px 0px 0px 5px"
                  width="17px"
                  height="17px"
                  borderRadius="17px"
                  backgroundColor={colors.primary}
                  text={numberOfMissingFields.toString()}
                  fontSize="11px"
                  fontWeight="600"
                  textColor={colors.white}
                />
              )}

              <Icon size="32px" color={colors.defaultBtnBackgroundColor} source={rightArrowIcon} />
            </TextView>
          </TextButton>
        )
      )}
    </ScrollView>
  )
}

Profile.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  navigation: PropTypes.shape({
    setOptions: PropTypes.func,
    navigate: PropTypes.func,
    addListener: PropTypes.func
  }),
  user: PropTypes.shape({
    image: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    angel: PropTypes.shape({
      short_bio: PropTypes.string,
      video: PropTypes.string,
      average_rating: PropTypes.number
    }),
    role: PropTypes.string,
    available_points: PropTypes.number,
    locale: PropTypes.string,
    account_number: PropTypes.string,
    languages: PropTypes.shape({
      dutch: PropTypes.bool,
      english: PropTypes.bool,
      french: PropTypes.bool,
      german: PropTypes.bool,
      italian: PropTypes.bool,
      spanish: PropTypes.bool
    }),
    payments_total: PropTypes.number,
    credit: PropTypes.string
  }).isRequired,
  getIndicatorsData: PropTypes.func.isRequired,
  getRatingsData: PropTypes.func.isRequired,
  resetUpdateProfileData: PropTypes.func.isRequired
}

Profile.defaultProps = {
  history: null,
  navigation: null
}

const mapStateToProps = ({ User }) => ({
  user: User.user
})

const mapDispatchToProps = {
  getIndicatorsData: getIndicators,
  getRatingsData: getRatings,
  setLocaleData: setLocale,
  resetUpdateProfileData: resetUpdateProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
