import React from 'react'
import { CharlyIcon } from '@charlycares/ui'
import { Box, Input } from 'native-base'

interface Props {
  searchValue: string
  onChange: (value: string) => void
  onCancel: () => void
}
export default function HeaderSearch({ searchValue, onChange, onCancel }: Props) {
  return (
    <Box
      zIndex={10}
      style={{ elevation: 1 }}
      left="0"
      right="0"
      top="-3px"
      shadow={2}
      position="absolute"
      bg="white"
      mx="20px"
      px="10px"
      py="10px"
      borderRadius="4px"
      borderColor="secondary.400"
      borderWidth="5px"
      bottom="-15px"
      flexDir="row"
      alignItems="center"
      h="55px"
    >
      <CharlyIcon size="32px" name="icn-search" color="gray.800" />

      <Box w="1px" h="70%" bg="gray.800" mx="10px" />

      <Input
        autoFocus
        value={searchValue}
        onChange={e => onChange(e.nativeEvent.text)}
        borderBottomWidth={0}
        _focus={{
          borderBottomWidth: 0
        }}
        minH="100%"
        flex={1}
        fontSize={15}
        returnKeyType="search"
      />

      <CharlyIcon size="32px" name="btn-close" color="gray.800" onPress={onCancel} />
    </Box>
  )
}
