import React from 'react'
import { showAlert } from '@charlycares/data-access'
import { isWeb } from '@charlycares/shared'
import { useDispatch } from 'react-redux'
import { Alert, AlertButton } from 'react-native'

export default function useAlert() {
  const dispatch = useDispatch()

  const show = (title: string, message?: string, buttons?: AlertButton[]) => {
    if (isWeb) {
      dispatch(
        showAlert({
          title,
          message,
          buttons
        })
      )
    } else {
      Alert.alert(title, message, buttons)
    }
  }

  return { show }
}
