import React, { memo, useEffect, useState } from 'react'
import { useTheme, Text, HStack, IStackProps, ITextProps } from 'native-base'

import { CharlyIcon } from '../icons'

type Props = IStackProps & {
  disabled?: boolean
  initialValue: number
  changeRate?: number
  onChange?: (value: number) => void
  formatValue?: (value: number) => string | React.ReactNode
  buttonColor: string
  _text?: ITextProps
}

const DebouncedNumberInput = ({
  initialValue,
  formatValue,
  changeRate = 1,
  onChange,
  _text,
  buttonColor,
  ...boxProps
}: Props) => {
  const { colors } = useTheme()
  const [value, setValue] = useState(initialValue)

  const onIncrease = () => {
    const newValue = value + changeRate

    setValue(newValue)
  }

  const onDecrease = () => {
    const newValue = value - changeRate

    setValue(newValue)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (value !== initialValue) {
        onChange?.(value)
      }
    }, 800)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <HStack space="5px" justifyContent="space-between" alignItems="center" {...boxProps}>
      <CharlyIcon onPress={onDecrease} name="btn-minus" size={32} color={buttonColor} />

      <Text minW="60px" textAlign="center" fontSize="16px" {..._text}>
        {formatValue?.(value) || value}
      </Text>

      <CharlyIcon onPress={onIncrease} name="btn-plus" size={32} color={buttonColor} />
    </HStack>
  )
}

export default memo(DebouncedNumberInput)
