import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '../Buttons';
import { TextView } from '../Texts';
import InputView from './InputView';
import colors from '../../styles/colors';

const LabelIconButtonInput = ({
  label,
  labelColor,
  labelMargins,
  labelFamily,
  labelSize,
  labelWeight,
  labelAlign,
  labelWidth,
  iconSource,
  iconColor,
  iconSize,
  onButtonPress,
  inputValue,
  onChangeText,
  maxInputHeight,
  ...inputProps
}) => (
  <InputView
    alignItems="flex-start"
    inputWidth="100%"
    inputMinHeight="44px"
    inputMaxHeight={maxInputHeight}
    multiline
    returnKeyType="default"
    blurOnSubmit
    textAlignVertical="top"
    value={inputValue}
    onChangeText={onChangeText}
    {...inputProps}>
    <TextView
      width={labelWidth}
      justifyContent="space-between"
      text={label}
      textColor={labelColor}
      textMargins={labelMargins}
      fontFamily={labelFamily}
      fontSize={labelSize}
      fontWeight={labelWeight}
      textAlign={labelAlign}>
      <IconButton
        iconSource={iconSource}
        iconColor={iconColor}
        iconWidth={iconSize}
        iconHeight={iconSize}
        onPress={onButtonPress}
      />
    </TextView>
  </InputView>
);

LabelIconButtonInput.propTypes = {
  label: PropTypes.string.isRequired,
  labelColor: PropTypes.string,
  labelMargins: PropTypes.string,
  labelFamily: PropTypes.string,
  labelSize: PropTypes.number,
  labelWeight: PropTypes.string,
  labelAlign: PropTypes.string,
  labelWidth: PropTypes.string,
  iconSource: PropTypes.node.isRequired,
  iconColor: PropTypes.string,
  iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onButtonPress: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  onChangeText: PropTypes.func.isRequired,
  maxInputHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

LabelIconButtonInput.defaultProps = {
  labelColor: colors.primaryText,
  labelMargins: '0px',
  labelFamily: 'Open Sans',
  labelSize: '15px',
  labelWeight: 'normal',
  labelAlign: 'auto',
  labelWidth: 'auto',
  iconColor: colors.grey08,
  iconSize: '20px',
  maxInputHeight: '150px'
};

export default LabelIconButtonInput;
