import React from 'react'
import { ScreenLayout } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import Children from './common/Children'
import Languages from '../common/Languages'
import { useGetChildrenQuery, useGetLandingScreensQuery, useGetLanguagesQuery } from '@charlycares/data-access'
import { useRouter } from '@charlycares/shared'

export default function FamilyInfoScreen() {
  const { t } = useTranslation()
  const { navigate } = useRouter()
  const { data: screen } = useGetLandingScreensQuery()
  const { data: children, isLoading: getChildrenIsLoading } = useGetChildrenQuery()
  const { data: languages, isLoading: getLanguagesIsLoading } = useGetLanguagesQuery()
  const hasChildren = children && children.length > 0
  const hasLanguages =
    languages && (languages.dutch || languages.english || languages.french || languages.german || languages.spanish)
  const progress = screen?.current_screen?.progress || 10
  const required = screen?.current_screen?.required || true

  return (
    <ScreenLayout
      title={t('familyInfoScreen.header')}
      progress={`${progress}%`}
      hideBackButton={required}
      _buttonProps={{
        onPress: () => screen && navigate(screen.current_screen.screen_name, screen.current_screen.web_path),
        isDisabled: !hasChildren || !hasLanguages,
        isLoading: getChildrenIsLoading || getLanguagesIsLoading,
        children: t('familyInfoScreen.nextStep')
      }}
    >
      <Children />
      <Languages />
    </ScreenLayout>
  )
}

FamilyInfoScreen.navigationOptions = {
  headerShown: false
}
