export enum ApiTags {
  SUBSCRIPTION = 'SUBSCRIPTION',
  CHILDREN = 'CHILDREN',
  AVAILABILITIES = 'AVAILABILITIES',
  USER = 'USER',
  ANGEL_TODOS = 'ANGEL_TODOS',
  MARKETING = 'MARKETING',
  BOOKINGS = 'BOOKINGS',
  LANGUAGES = 'LANGUAGES',
  ONBOARDING_SCREENS = 'ONBOARDING_SCREENS',
  BOOKING = 'BOOKING',
  JOBS = 'JOBS',
  ANGEL_DETAILS = 'ANGEL_DETAILS',
  REVIEWS = 'REVIEWS',
  REVIEW = 'REVIEW',
  BOOKING_TIMER = 'BOOKING_TIMER',
  CHAT = 'CHAT',
  MERCURE_TOKEN = 'MERCURE_TOKEN'
}

export type ResponseData<T> = {
  success: boolean
  data: T
}

export type ResponseError<T = void> = {
  success: boolean
  error: T
  status: number
  message: string
  data?: {
    message: string
    error?: string
  }
}
