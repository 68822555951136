import moment from 'moment'
import { Linking, Platform } from 'react-native'
import _ from 'lodash'

import { BookingDate, SearchBookingsDates, JobboardFilters, PaginatedResults, User, UserLanguages } from '../types'
import { i18n } from '../locale'
import { isWeb } from '../const'

export const platformSelect = <T>(native: T, web: T): T => {
  return Platform.select({
    native,
    web
  }) as T
}

export const setStyle = (
  propName: string,
  value?: number | string,
  defaultValue?: number | string,
  disable = false
): string => {
  if (disable || (value === undefined && defaultValue === undefined)) return ''
  value = value || defaultValue
  const valueString = typeof value === 'number' ? `${value}px` : value
  return `${propName}: ${valueString}`
}

export const changeLocale = (locale: string) => {
  moment.locale(locale === 'nl_NL' ? 'nl' : 'en')
  i18n.changeLanguage(locale)
}

export const validateIBAN = (accountNumber: string) => {
  // This function check if the checksum is correct
  let accountNum = accountNumber.toUpperCase()

  accountNum = accountNum.replace(/^(.{4})(.*)$/, '$2$1') // Move the first 4 chars from left to the right
  accountNum = accountNum.replace(/[A-Z]/g, $e => ($e.charCodeAt(0) - 'A'.charCodeAt(0) + 10).toString()) // Convert A-Z to 10-25
  let $sum = 0
  let $ei = 1 // First exponent
  for (let $i = accountNum.length - 1; $i >= 0; $i -= 1) {
    $sum += $ei * parseInt(accountNum.charAt($i), 10) // multiply the digit by it's exponent
    $ei = ($ei * 10) % 97 // compute next base 10 exponent  in modulus 97
  }
  return $sum % 97 === 1
}

export const isValidBSN = (bsn: string): boolean => {
  if (bsn.length < 8 || bsn.length > 9) {
    return false
  }

  const numbers = Array.from(String(bsn), Number)
  const lastNumber = numbers[numbers.length - 1]

  if (typeof lastNumber === 'number' && !numbers.includes(NaN)) {
    return (numbers.slice(0, -1).reduce((a, b, i, arr) => b * (arr.length + 1 - i) + a, 0) - lastNumber) % 11 === 0
  }

  return false
}

/**
 * @deprecated
 */
export const getUserLanguages = (user?: User) => {
  return Object.keys(user?.languages[0] || {}).filter(
    key => (user as User).languages[0][key as keyof UserLanguages] === true
  )
}

export const filterUserLanguages = (languages: UserLanguages) => {
  return Object.keys(languages).filter(key => languages[key as keyof UserLanguages])
}

/**
 * @description this is temporary quick fix to make it work and should be remove
 */
export const transformBookingDates = (
  dates: SearchBookingsDates[]
): {
  start_date: string | moment.Moment | Date
  end_date: string | moment.Moment | Date
}[][] => {
  return dates.map(({ startDate, endDate, repeatDates }) => {
    const firstDate = {
      start_date: startDate,
      end_date: endDate
    }

    const allRepeatDates =
      repeatDates?.map(({ startDate, endDate }) => ({
        start_date: startDate,
        end_date: endDate
      })) || []

    return [firstDate, ...allRepeatDates]
  })
}

/**
 * @description this is temporary quick fix to make it work and should be remove
 */
export const reverseBookingDates = (
  dates:
    | {
        start_date: string | moment.Moment | Date
        end_date: string | moment.Moment | Date
      }[][]
    | undefined
): SearchBookingsDates[] => {
  return (
    dates?.map(date => {
      return {
        startDate: date[0].start_date,
        endDate: date[0].end_date,
        repeatDates: date
          .slice(1, date.length)
          .map(repDate => ({ startDate: repDate.start_date, endDate: repDate.end_date }))
      }
    }) || []
  )
}

export const flattenSearchBookingDates = (
  dates: SearchBookingsDates[]
): {
  starts_at: string
  ends_at: string
}[] => {
  return _.flatten(transformBookingDates(dates)).map(date => ({
    starts_at: moment(date.start_date).format('YYYY-MM-DD HH:mm:ss'),
    ends_at: moment(date.end_date).format('YYYY-MM-DD HH:mm:ss')
  }))
}

export const getGoogleClientId = () => {
  if (!isWeb) return null

  // @ts-ignore
  const googleAnalytics = window.ga

  if (googleAnalytics && typeof googleAnalytics.getAll === 'function') {
    const trackers = googleAnalytics.getAll()

    if (trackers.length > 0) {
      return trackers[0].get('clientId')
    }
  }
}

export const getCityLaunch = () => {
  if (!isWeb) return null

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => (typeof prop === 'string' ? searchParams.get(prop) : '')
  })

  // @ts-ignore
  return params.city_launch ?? ''
}

export const trimJobboardFilters = (filters: Partial<JobboardFilters>) => {
  for (const key in filters) {
    const value = filters[key as keyof typeof filters]
    if (value === undefined || value === '') {
      delete filters[key as keyof typeof filters]
    } else if (Array.isArray(value) && value.length === 0) {
      delete filters[key as keyof typeof filters]
    }
  }

  return filters
}

export const flattenBookingDates = (dates: BookingDate[]): Omit<BookingDate, 'repeat_days'>[] => {
  const flatten: Omit<BookingDate, 'repeat_days'>[] = []

  dates.forEach(item => {
    flatten.push(item, ...item.repeat_days)
  })

  return flatten
}

export const getSelectedDatesCount = (
  date: BookingDate,
  selected?: BookingDate[] | Omit<BookingDate, 'repeat_days'>[]
): number => {
  let count = date.repeat_days.filter(
    repDay => !!selected?.find(item => item.obscured_id === repDay.obscured_id)
  ).length

  if (selected?.find(item => item.obscured_id === date.obscured_id)) {
    count++
  }

  return count
}

export const makeCall = (phone: string) => {
  if (isWeb) {
    window.open(`tel:${phone}`)
  } else {
    Linking.openURL(`tel${Platform.OS === 'android' ? '' : 'prompt'}:${phone}`)
  }
}

export const generatePathFromScreenName = (screenName: string) => {
  const pathname = screenName.replace(/([A-Z])/g, '-$1').toLowerCase()

  return pathname.slice(1, pathname.length)
}

export const contactPaginatedData = <T>(data: T[], results: PaginatedResults<T>) => {
  const newData = [...data]

  const deleted = newData.splice(results.meta.from - 1, results.meta.per_page, ...results.data)

  // on refetch should remove all the items in the list and add first page
  if (results.meta.current_page === 1 && deleted?.length) {
    return [...results.data]
  }

  return newData
}
