import React from 'react'
import { Card, CharlyIcon } from '@charlycares/ui'
import { Text, Box, Center, Heading, HStack, IBoxProps, Image, Pressable } from 'native-base'
import { useTranslation } from 'react-i18next'
import { BookingServiceType, calculateBikeTime, getDistance, Money, useRouter } from '@charlycares/shared'

type Props = IBoxProps & {
  family: {
    obscured_id: string
    distance: number
    child_count: number
    has_cat: boolean
    has_dog: boolean
    image: string
    name: string
    personal_message: string
    day_rate_per_hour: Money
    night_rate_per_hour: Money
    expected_earnings: Money
    travel_allowance: Money
    max_hourly_rate: Money
    average_rating?: number
    rating_count?: number
    service_type?: BookingServiceType
  }
}

const BookingFamilyDetails = ({ family, ...boxProps }: Props) => {
  const { t } = useTranslation()
  const { navigate } = useRouter()

  const isPetCare = family.service_type === BookingServiceType.PET_CARE
  const isElderlyCare = family.service_type === BookingServiceType.ELDERLY_CARE

  return (
    <Card p="0" {...boxProps}>
      <Pressable
        onPress={() =>
          navigate('FamilyProfile', `family/${family.obscured_id}`, {
            familyId: family.obscured_id,
            bookingServiceType: family.service_type
          })
        }
      >
        <HStack p="20px" alignItems="center" space="10px">
          {family.image && <Image w="90px" h="60px" borderRadius="4px" source={{ uri: family.image }} />}
          <Box flex={1}>
            {!!family.average_rating && (
              <HStack space="5px" mt="10px" alignItems="center">
                <Heading fontSize="15px">{family.average_rating.toFixed(2)}</Heading>
                <CharlyIcon name="star" size={15} color={'gray.800'} />
                <Text ml="5px" fontSize="12px" color="gray.900">{`(${family.rating_count || 0})`}</Text>
              </HStack>
            )}
            <Heading mt="5px" fontSize="16px" fontWeight="600">
              {family.name}
            </Heading>
          </Box>
          <CharlyIcon name="icn-caret-right" size={35} color="gray.700" />
        </HStack>
      </Pressable>

      <HStack
        px="20px"
        mb="20px"
        justifyContent={isPetCare || isElderlyCare ? 'flex-start' : 'space-evenly'}
        alignItems="center"
        space={isPetCare || isElderlyCare ? '40px' : '0'}
        w="100%"
      >
        <Center ml={isPetCare || isElderlyCare ? '10px' : '0'}>
          <CharlyIcon name="icn-location" size={30} color="gray.900" />
          <Text fontSize="14px" mt="5px">
            {getDistance(family.distance)}
          </Text>
        </Center>
        <Center>
          <CharlyIcon name="icn-cycling" size={30} color="gray.900" />
          <Text fontSize="14px" mt="5px">
            {calculateBikeTime(family.distance)}
          </Text>
        </Center>
        {!isPetCare && !isElderlyCare && (
          <Center>
            <CharlyIcon name="icn-kids" size={30} color="gray.900" />
            <Text fontSize="14px" mt="5px">
              {family.child_count}
            </Text>
          </Center>
        )}
        {!isPetCare && (
          <>
            <Center opacity={family.has_dog ? 1 : 0.5}>
              <CharlyIcon name="icn-dog" size={30} color="gray.900" />
              <Text fontSize="14px" mt="5px">
                {family.has_dog ? t('yes') : t('no')}
              </Text>
            </Center>
            <Center opacity={family.has_cat ? 1 : 0.5}>
              <CharlyIcon name="icn-cat" size={30} color="gray.900" />
              <Text fontSize="14px" mt="5px">
                {family.has_cat ? t('yes') : t('no')}
              </Text>
            </Center>
          </>
        )}
      </HStack>
    </Card>
  )
}

export default BookingFamilyDetails
