import React from 'react'
import { Box, Center, HStack, IBoxProps, Skeleton, VStack } from 'native-base'

type Props = IBoxProps & {
  index?: number
}
export default function SkeletonAngelCard({ index = 1, ...boxProps }: Props) {
  return (
    <HStack
      key={index}
      h="175px"
      mb="5px"
      w="100%"
      borderTopWidth={1}
      borderBottomWidth={1}
      borderColor="gray.200"
      p="20px"
    >
      <Box flex={2}>
        <HStack space="10px" alignItems="center">
          <Skeleton size="80px" rounded="full" startColor="gray.400" />
          <VStack flex={1}>
            <Skeleton h="3" w="150px" rounded="full" startColor="gray.400" />
            <Skeleton h="3" w="80px" mt="10px" rounded="full" startColor="gray.400" />
            <HStack mt="20px">
              <Skeleton h="4" w="30px" rounded="full" startColor="gray.400" />
              <Skeleton h="4" w="30px" ml="15px" rounded="full" startColor="gray.400" />
              <Skeleton h="4" w="30px" ml="15px" rounded="full" startColor="gray.400" />
              <Skeleton h="4" w="30px" ml="15px" rounded="full" startColor="gray.400" />
            </HStack>
          </VStack>
        </HStack>
        <Skeleton h="3" w="130px" mt="20px" rounded="full" startColor="gray.400" />
      </Box>

      <Center flex={1} justifyContent="flex-start">
        <Skeleton size="30px" rounded="full" startColor="gray.400" mr="5px" alignSelf="flex-end" />
      </Center>
    </HStack>
  )
}
