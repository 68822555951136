import React from 'react'
import { useTranslation } from 'react-i18next'
import { BookingServiceType, useRouter } from '@charlycares/shared'
import { Card, CharlyIcon, ScreenLayout } from '@charlycares/ui'
import { Heading, Button, Text, VStack } from 'native-base'
import { getInfoFromBookingServiceType } from '../common'
import { Linking } from 'react-native'

export default function BookingServiceTypeComingSoonScreen() {
  const { getParam } = useRouter()
  const { t, i18n } = useTranslation()

  const bookingServiceType = getParam('bookingServiceType') as BookingServiceType
  const researchParticipationLink = getParam('researchParticipationLink') as string[]

  const researchLink = researchParticipationLink[
    i18n.language.split('_')[0] as keyof typeof researchParticipationLink
  ] as string

  return (
    <ScreenLayout title={t(bookingServiceType)} edges={['right', 'left']}>
      <Card w="100%" bg="white" px="20px" borderColor="gray.200" borderWidth="1px">
        <VStack alignItems="center">
          <CharlyIcon
            mt="80px"
            name={getInfoFromBookingServiceType(bookingServiceType).icon}
            size={78}
            color="secondary.400"
          />
          <Heading color="gray.900" fontWeight="600" fontSize="22px" mt="50px" mb="25px">
            {t('comingSoonTitle')}
          </Heading>

          <Text mb="30px" color="gray.900" fontSize="16px" fontWeight="600">
            {t('comingSoonSubtitle')}
          </Text>
          <Text color="gray.900" fontSize="16px" fontWeight="600" px="20px">
            {t('comingSoonDescription')}
          </Text>

          <Button onPress={() => Linking.openURL(researchLink)} w="100%" mt="30px" mb="50px">
            {t('participateInResearch')}
          </Button>
        </VStack>
      </Card>
    </ScreenLayout>
  )
}

BookingServiceTypeComingSoonScreen.navigationOptions = () => ({
  headerShown: false
})
