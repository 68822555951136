import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { logout } from '../../stores/actions/userAction'
import { Text, Icon, Divider } from '../../elements'
import {
  TextView,
  TextButton,
  IconButton,
  TextTextView,
  TextIconView,
  ProfileAvatar,
  NotificationSettings,
  Header
} from '../../components'
import { isWeb, formatPhoneNumber } from '../../utils/functions'
import colors from '../../styles/colors'

import houseIcon from '../../../assets/icons/icn-house.png'
import phoneIcon from '../../../assets/icons/icn-telephone.png'
import arrowRightIcn from '../../../assets/icons/icn-nav-right.png'
import leftIcon from '../../../assets/icons/icn-nav-left.png'

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
`
const ScrollView = styled.ScrollView``
const AddressView = styled.View`
  flex-direction: row;
  align-items: center;
  margin: 10px 0px 0px 0px;
`

const Account = ({ history, navigation, user, logOut }) => {
  const [t] = useTranslation()
  const {
    image,
    first_name,
    last_name,
    street_name,
    street_number,
    city,
    phone,
    second_phone,
    family,
    angel,
    email,
    settings,
    role
  } = user
  const { promo_mail, system_mail, promo_push, system_push } = settings
  const isAngel = role === 'angel'
  const { short_bio } = isAngel ? angel : family

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('profileScreensProfileAccount')}
        </Text>
      ),
      headerRight: (
        <TextButton
          text={t('edit')}
          textColor={colors.secondary}
          onPress={() => navigation.navigate('ProfileEdit', { shouldGoBack: true })}
        />
      )
    })
  }

  return (
    <SafeAreaView>
      {isWeb && (
        <Header
          left={
            <IconButton iconSource={leftIcon} iconSize="32px" iconColor={colors.secondary} onPress={history.goBack} />
          }
          title={t('profileScreensProfileAccount')}
          right={
            <TextButton
              text={t('edit')}
              textColor={colors.secondary}
              onPress={() => history.push({ pathname: '/profile-edit', state: { shouldGoBack: true } })}
            />
          }
        />
      )}
      <ScrollView>
        <Divider />

        <ProfileAvatar
          alignSelf="center"
          margins="20px 0px 20px 0px"
          imageWidth="300px"
          imageHeight="200px"
          imageSize="15px"
          source={image}
          isAngel={isAngel}
        />

        <TextTextView
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          paddings="0px 20px 20px 20px"
          firstText={t('firstName')}
          firstTextFontSize="12px"
          firstTextFontWeight="300"
          firstTextColor={colors.grey02}
          secondText={first_name}
          secondTextMargins="10px 0px 0px 5px"
          secondTextFontSize="16px"
          secondTextColor={colors.primaryText}
        />

        <TextTextView
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          paddings="0px 20px 20px 20px"
          borderBottomWidth="1px"
          firstText={t('surname')}
          firstTextFontSize="12px"
          firstTextFontWeight="300"
          firstTextColor={colors.grey02}
          secondText={last_name}
          secondTextMargins="10px 0px 0px 5px"
          secondTextFontSize="16px"
          secondTextColor={colors.primaryText}
        />

        <TextTextView
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          paddings="20px"
          borderBottomWidth="1px"
          firstText={t('description')}
          firstTextFontSize="12px"
          firstTextFontWeight="300"
          firstTextColor={colors.grey02}
          secondText={short_bio}
          secondTextMargins="10px 0px 0px 5px"
          secondTextFontSize="16px"
          secondTextWidth="100%"
          secondTextAlign="left"
          secondTextColor={colors.primaryText}
        />

        <TextView
          flexDirection="column"
          alignItems="flex-start"
          paddings="20px"
          borderBottomWidth="1px"
          text={t('address')}
          fontSize="12px"
          fontWeight="300"
          textColor={colors.grey02}
        >
          <AddressView>
            <Icon size="32px" source={houseIcon} />

            <TextTextView
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              paddings="0px"
              margins="0px 0px 0px 10px"
              firstText={`${street_name} ${street_number}`}
              secondText={city}
              secondTextFontSize="12px"
              secondTextFontWeight="300"
            />
          </AddressView>
        </TextView>

        <TextView
          flexDirection="column"
          alignItems="flex-start"
          paddings="20px"
          text={t('phoneNumber')}
          fontSize="12px"
          fontWeight="300"
          textColor={colors.grey02}
        >
          <TextIconView
            flexDirection="row-reverse"
            backgroundColor="transparent"
            icon={phoneIcon}
            iconMargins="0px 10px 0px 0px"
            iconSize="32px"
            iconColor="black"
            text={formatPhoneNumber(phone)}
            fontSize="16px"
          />

          {second_phone && (
            <TextIconView
              flexDirection="row-reverse"
              backgroundColor="transparent"
              icon={phoneIcon}
              iconMargins="0px 10px 0px 0px"
              iconSize="32px"
              iconColor="black"
              text={formatPhoneNumber(second_phone)}
              fontSize="16px"
            />
          )}
        </TextView>

        <NotificationSettings
          disabled
          promoMail={!!promo_mail}
          systemMail={!!system_mail}
          promoPush={!!promo_push}
          systemPush={!!system_push}
        />

        <TextView
          paddings="15px 15px 5px 15px"
          borderBottomWidth="1px"
          backgroundColor={colors.backgroundColor}
          text={t('account')}
          fontSize="14px"
          textColor={colors.grey02}
        />

        {isAngel && (
          <TextButton
            flexDirection="row"
            justifyContent="space-between"
            margins="10px 0px 0px 0px"
            borderBottomWidth="1px"
            borderTopWidth={0}
            borderColor={colors.grey07}
            backgroundColor={colors.white}
            text={t('account')}
            fontFamily="Martel"
            fontSize="16px"
            textColor={colors.primaryText}
            onPress={() => navigation.navigate('ProfileAngelUnsubscribe')}
          >
            <TextIconView
              backgroundColor={colors.white}
              icon={arrowRightIcn}
              iconColor={colors.grey04}
              text={t('deactiveOrUnsubscribe')}
              fontSize="16px"
              fontWeight={100}
              textColor={colors.alert}
            />
          </TextButton>
        )}

        <TextTextView
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          paddings="20px 20px 20px 20px"
          firstText={t('emailAddress')}
          firstTextFontSize="12px"
          firstTextFontWeight="300"
          firstTextColor={colors.grey02}
          secondText={email}
          secondTextMargins="10px 0px 0px 5px"
          secondTextFontSize="16px"
          secondTextColor={colors.primaryText}
        />

        <TextTextView
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          paddings="0px 20px 0px 20px"
          firstText={t('password')}
          firstTextFontSize="12px"
          firstTextFontWeight="300"
          firstTextColor={colors.grey02}
          secondText="*******"
          secondTextMargins="10px 0px 0px 5px"
          secondTextFontSize="16px"
          secondTextColor={colors.primaryText}
        />
      </ScrollView>
    </SafeAreaView>
  )
}

Account.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func, goBack: PropTypes.func }),
  navigation: PropTypes.shape({ navigate: PropTypes.func, setOptions: PropTypes.func }),
  user: PropTypes.shape({
    image: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    street_name: PropTypes.string,
    street_number: PropTypes.string,
    city: PropTypes.string,
    phone: PropTypes.string,
    second_phone: PropTypes.string,
    family: PropTypes.shape({}),
    angel: PropTypes.shape({}),
    email: PropTypes.string,
    settings: PropTypes.shape({
      promo_mail: PropTypes.number,
      system_mail: PropTypes.number,
      promo_push: PropTypes.number,
      system_push: PropTypes.number
    }),
    role: PropTypes.string
  }).isRequired,
  logOut: PropTypes.func.isRequired
}

Account.defaultProps = {
  history: null,
  navigation: null
}

const mapStateToProps = ({ User }) => ({
  user: User.user
})

const mapDispatchToProps = {
  logOut: logout
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)
