import * as React from 'react'
import Svg, { SvgProps, Rect, Path } from 'react-native-svg'

const FixedCalendarIcon = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 52 55" fill="none" {...props}>
    <Rect x={0.797} y={5.58} width={49.42} height={48.623} rx={5.58} fill="#fff" stroke="#0B5394" strokeWidth={1.594} />
    <Path
      d="M6.377 5.58h38.26a5.58 5.58 0 015.58 5.58v2.39H.797v-2.39a5.58 5.58 0 015.58-5.58z"
      fill="#0B5394"
      stroke="#0B5394"
      strokeWidth={1.594}
    />
    <Rect
      x={10.362}
      y={0.797}
      width={5.58}
      height={10.362}
      rx={1.594}
      fill="#fff"
      stroke="#0B5394"
      strokeWidth={1.594}
    />
    <Rect
      x={35.072}
      y={0.797}
      width={5.58}
      height={10.362}
      rx={1.594}
      fill="#fff"
      stroke="#0B5394"
      strokeWidth={1.594}
    />
    <Rect
      x={6.776}
      y={18.732}
      width={5.58}
      height={5.58}
      rx={0.399}
      fill="#062D51"
      stroke="#F2F2F2"
      strokeWidth={0.797}
    />
    <Rect
      x={6.776}
      y={26.703}
      width={5.58}
      height={5.58}
      rx={0.399}
      fill="#062D51"
      stroke="#F2F2F2"
      strokeWidth={0.797}
    />
    <Rect
      x={6.776}
      y={34.674}
      width={5.58}
      height={5.58}
      rx={0.399}
      fill="#062D51"
      stroke="#F2F2F2"
      strokeWidth={0.797}
    />
    <Rect
      x={6.776}
      y={42.645}
      width={5.58}
      height={5.58}
      rx={0.399}
      fill="#062D51"
      stroke="#F2F2F2"
      strokeWidth={0.797}
    />
    <Rect x={14.348} y={18.333} width={6.377} height={6.377} rx={0.797} fill="#F2F2F2" />
    <Rect x={14.348} y={26.304} width={6.377} height={6.377} rx={0.797} fill="#F2F2F2" />
    <Rect x={14.348} y={34.275} width={6.377} height={6.377} rx={0.797} fill="#F2F2F2" />
    <Rect x={14.348} y={42.246} width={6.377} height={6.377} rx={0.797} fill="#F2F2F2" />
    <Rect
      x={22.718}
      y={18.732}
      width={5.58}
      height={5.58}
      rx={0.399}
      fill="#062D51"
      stroke="#F2F2F2"
      strokeWidth={0.797}
    />
    <Rect
      x={22.718}
      y={26.703}
      width={5.58}
      height={5.58}
      rx={0.399}
      fill="#062D51"
      stroke="#F2F2F2"
      strokeWidth={0.797}
    />
    <Rect
      x={22.718}
      y={34.674}
      width={5.58}
      height={5.58}
      rx={0.399}
      fill="#062D51"
      stroke="#F2F2F2"
      strokeWidth={0.797}
    />
    <Rect
      x={22.718}
      y={42.645}
      width={5.58}
      height={5.58}
      rx={0.399}
      fill="#062D51"
      stroke="#F2F2F2"
      strokeWidth={0.797}
    />
    <Rect x={30.29} y={18.333} width={6.377} height={6.377} rx={0.797} fill="#F2F2F2" />
    <Rect x={30.29} y={26.304} width={6.377} height={6.377} rx={0.797} fill="#F2F2F2" />
    <Rect x={30.29} y={34.275} width={6.377} height={6.377} rx={0.797} fill="#F2F2F2" />
    <Rect x={30.29} y={42.246} width={6.377} height={6.377} rx={0.797} fill="#F2F2F2" />
    <Rect x={38.261} y={18.333} width={6.377} height={6.377} rx={0.797} fill="#F2F2F2" />
    <Rect x={38.261} y={26.304} width={6.377} height={6.377} rx={0.797} fill="#F2F2F2" />
    <Rect x={38.261} y={34.275} width={6.377} height={6.377} rx={0.797} fill="#F2F2F2" />
    <Rect x={38.261} y={42.246} width={6.377} height={6.377} rx={0.797} fill="#F2F2F2" />
  </Svg>
)

export default FixedCalendarIcon
