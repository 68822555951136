import { configureStore, Reducer } from '@reduxjs/toolkit'
import { OldUser } from '@charlycares/shared'

import { api } from '../api'
import { alertReducer, b2bReducer, bookingReducer, jobboardReducer } from './slices'

const reducer = {
  b2b: b2bReducer,
  alert: alertReducer,
  booking: bookingReducer,
  jobboard: jobboardReducer,
  [api.reducerPath]: api.reducer
}

const storeConfig = {
  reducer,
  middleware: (getDefaultMiddleware: any) => getDefaultMiddleware().concat(api.middleware)
}

export const store = configureStore(storeConfig)

/**
  @description Combine new reducer with existing legacy reducers
**/
export const extendStoreReducer = (extendReducer: Reducer) =>
  configureStore({
    ...storeConfig,
    reducer: {
      ...extendReducer,
      ...reducer
    }
  })

/**
  @description This is used as type for legacy store
  @todo Add more reducers types
  @deprecated Should be removed when all reducers are migrated to redux-toolkit
**/
export type LegacyState = {
  User: {
    user: OldUser
    indicators: {
      has_pending_bookings: number
      new_messages: number
      profile_todo: number
      active_timer: boolean
      bookings_pending_review: string[]
    }
  }
}
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState> & LegacyState
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
