import React, { Suspense } from 'react'
import { SharedProviders } from '@charlycares/features'
import { webReducers } from '@charlycares/legacy/web'
import { Alert } from '@charlycares/ui'

import LegacyRouter from '../legacy/routes'

import 'rc-slider/assets/index.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'video-react-css'
import './index.css'

function App() {
  return (
    <SharedProviders reducers={webReducers}>
      <Suspense fallback={null}>
        <LegacyRouter />
        <Alert />
      </Suspense>
    </SharedProviders>
  )
}

export default App
