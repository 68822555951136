import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { Heading } from '../../elements'
import { IconButton } from '../Buttons'
import { TextView, TextTextView, TextIconView } from '../Texts'
import { AngelAvatar, Rating } from '../Profile'
import { renderDistanceInKilometers, roundMonetaryValue, getAge } from '../../utils/functions'
import theme from '../../styles/theme'

import locationIcon from '../../../assets/icons/icn-location.png'
import dayIcon from '../../../assets/icons/icn-day.png'
import nightIcon from '../../../assets/icons/icn-night.png'
import fixedIcon from '../../../assets/icons/icn-repeat.png'
import playIcon from '../../../assets/icons/label-play.png'
import repeatIcon from '../../../assets/icons/icn-repeat.png'
import standbyIcon from '../../../assets/icons/icn-standby.png'
import favoriteIcon from '../../../assets/icons/icn-favorite.png'
import favoriteInactiveIcon from '../../../assets/icons/icn-favorite-inactive.png'
import colors from '../../../../mobileapp/src/utils/colors'

const Angel = styled.TouchableOpacity`
  align-items: center;
  margin: 5px 20px 5px 5px;
  padding: 10px 10px 0px 10px;
  width: 200px;
  height: 250px;
  border-radius: ${theme.borderRadius.md};
  box-shadow: ${theme.boxShadow};
  elevation: 5;
  background-color: ${theme.colors.white};
`
const Skills = styled.View`
  flex-direction: row;
  margin: 10px 0px;
`
const Availabilities = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  margin-top: auto;
  width: 100%;
  border-top-width: 1px;
  border-color: ${theme.dividerLineColor};
  height: 50px;
`

const BabysitterCard = ({
  babysitter: {
    id,
    obscured_id,
    image,
    video,
    first_name,
    birthdate,
    average_rating,
    distance,
    normal_rate,
    extra_rate,
    fixed_rate,
    min_age_children,
    tutor,
    first_aid,
    driving_license,
    babysit_expertise,
    liability_insurance,
    response_time,
    availabilities,
    fixed,
    standBy,
    is_liked
  },
  lastMinute,
  onAngelPress,
  onAngelVideoPress,
  onToggleLikePress
}) => {
  const [t] = useTranslation()
  const otherSkills = [
    min_age_children,
    tutor,
    first_aid,
    driving_license,
    babysit_expertise,
    liability_insurance
  ].filter(skill => skill > 0).length

  return (
    <Angel onPress={() => onAngelPress(id, obscured_id)}>
      <AngelAvatar size="80px" source={image} overflow="visible" margins="0 0 8px">
        {video && (
          <IconButton
            position="absolute"
            bottom="0px"
            height="auto"
            borderWidth={0}
            borderTopWidth={0}
            borderBottomWidth={0}
            iconSource={playIcon}
            iconWidth="25px"
            iconHeight="20px"
            useIconTintColor={false}
            onPress={() => onAngelVideoPress(video)}
          />
        )}
        {standBy && !fixed && (
          <TextIconView
            position="absolute"
            left={40}
            bottom={0}
            flexDirection="row-reverse"
            alignItems="flex-start"
            justifyContent="flex-start"
            paddings="3px 0px 3px 0px"
            opacity={0.8}
            backgroundColor={theme.colors.secondary}
            text={t('standBy')}
            textMargins="0px 5px 0px 0px"
            textColor={theme.colors.white}
            fontSize="10px"
            icon={standbyIcon}
            iconSize="15px"
            iconMargins="0px 5px 0px 5px"
            iconColor={theme.colors.white}
          />
        )}
        {fixed && !standBy && (
          <TextIconView
            position="absolute"
            left={40}
            bottom={0}
            flexDirection="row-reverse"
            alignItems="flex-start"
            justifyContent="flex-start"
            paddings="3px 0px 3px 0px"
            opacity={0.7}
            backgroundColor={theme.colors.grey08}
            text={t('bookScreensPermanentBabysitter')}
            textMargins="0px 5px 0px 0px"
            fontSize="10px"
            icon={repeatIcon}
            iconMargins="0px 5px 0px 5px"
            iconSize="15px"
            iconColor={theme.colors.primaryText}
          />
        )}
      </AngelAvatar>
      <IconButton
        paddings="5px"
        height="auto"
        position="absolute"
        top={5}
        left={5}
        borderWidth={0}
        borderTopWidth={0}
        borderBottomWidth={0}
        iconSource={is_liked ? favoriteIcon : favoriteInactiveIcon}
        iconColor={is_liked ? theme.colors.secondary : theme.colors.grey07}
        iconSize="32px"
        onPress={() => onToggleLikePress({ id, liked: !is_liked })}
      />
      <Heading
        md
        ariaLevel={4}
        fontFamily={theme.fontFamilyAlt}
        margins="0 0 4px 0"
        numberOfLines={1}
        text={`${first_name} (${getAge(birthdate)})`}
      />

      <Rating iconSize="12px" rating={average_rating} />
      <Skills>
        <TextIconView
          minWidth="35px"
          margins="0px 2px"
          flexDirection="column-reverse"
          backgroundColor="transparent"
          text={renderDistanceInKilometers(distance)}
          fontSize="12px"
          icon={locationIcon}
          iconSize="24px"
          iconColor={theme.textColorBase}
        />
        {fixed ? (
          <TextIconView
            minWidth="35px"
            margins="0px 2px"
            flexDirection="column-reverse"
            backgroundColor="transparent"
            text={`€ ${roundMonetaryValue(fixed_rate)}`}
            fontSize="12px"
            icon={fixedIcon}
            iconSize="24px"
            iconColor={theme.textColorBase}
          />
        ) : (
          <>
            <TextIconView
              minWidth="35px"
              margins="0 4px"
              flexDirection="column-reverse"
              backgroundColor="transparent"
              text={`€ ${roundMonetaryValue(normal_rate)}`}
              fontSize="12px"
              icon={dayIcon}
              iconSize="24px"
              iconColor={theme.textColorBase}
            />
            {extra_rate && (
              <TextIconView
                minWidth="35px"
                margins="0 2px"
                flexDirection="column-reverse"
                backgroundColor="transparent"
                text={`€ ${roundMonetaryValue(extra_rate)}`}
                fontSize="12px"
                icon={nightIcon}
                iconSize="24px"
                iconColor={theme.textColorBase}
              />
            )}
          </>
        )}
        {!!otherSkills && (
          <TextTextView
            minWidth="35px"
            paddings="0px"
            margins="0 2px"
            flexDirection="column"
            justifyContent="flex-start"
            firstText={`+${otherSkills}`}
            firstTextHeight="20px"
            firstTextFontSize="17px"
            secondText={otherSkills === 1 ? t('skill') : t('skills')}
            secondTextFontSize="12px"
            secondTextColor={theme.textColorBase}
          />
        )}
      </Skills>

      <Availabilities>
        {lastMinute ? (
          <TextIconView
            flexDirection="row-reverse"
            alignItems="center"
            justifyContent="center"
            opacity={0.5}
            backgroundColor={theme.colors.white}
            name="time"
            iconMargins="0px 5px 0px 0px"
            iconSize="16px"
            iconColor={theme.colors.primaryText}
            text={`${t('bookScreensResponseTime')} ± ${response_time || 0} ${t('minutes')}`}
            fontSize="12px"
            numberOfLines={1}
          />
        ) : (
          <>
            {Object.keys(availabilities).map((key, index) => {
              const { morning, afternoon, evening } = availabilities[key]

              return (
                <TextView
                  key={availabilities[key].id}
                  alignItems="center"
                  justifyContent="center"
                  paddings="2px 3px"
                  margins="0px"
                  width="20px"
                  height="20px"
                  borderRadius={theme.borderRadius.xs}
                  backgroundColor={morning || afternoon || evening ? colors.defaultGrey : colors.backgroundColor}
                  text={t('calendarScreensMonthsArray', { returnObjects: true })[index]}
                  fontSize="12px"
                />
              )
            })}
          </>
        )}
      </Availabilities>
    </Angel>
  )
}

BabysitterCard.propTypes = {
  babysitter: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    image: PropTypes.string,
    video: PropTypes.string,
    first_name: PropTypes.string,
    birthdate: PropTypes.string,
    average_rating: PropTypes.number,
    distance: PropTypes.number,
    normal_rate: PropTypes.string,
    extra_rate: PropTypes.string,
    fixed_rate: PropTypes.string,
    min_age_children: PropTypes.number,
    tutor: PropTypes.number,
    first_aid: PropTypes.number,
    driving_license: PropTypes.number,
    babysit_expertise: PropTypes.number,
    liability_insurance: PropTypes.number,
    response_time: PropTypes.number,
    availabilities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        morning: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        afternoon: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        evening: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
      })
    ),
    fixed: PropTypes.number,
    standBy: PropTypes.number,
    is_liked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
  }).isRequired,
  lastMinute: PropTypes.bool,
  onAngelPress: PropTypes.func.isRequired,
  onAngelVideoPress: PropTypes.func.isRequired,
  onToggleLikePress: PropTypes.func.isRequired
}

BabysitterCard.defaultProps = {
  lastMinute: false
}

export default BabysitterCard
