import React from 'react'
import { Box, Center, Divider, Flex, Heading, HStack, Skeleton, Text, VStack } from 'native-base'
import { useTranslation } from 'react-i18next'
import { useGetUserBookingsMetricQuery } from '@charlycares/data-access'

import { getDuration, IBookingDetails, links, useRouter } from '@charlycares/shared'
import { CharlyIcon } from '@charlycares/ui'
import moment from 'moment'

type Props = {
  booking: IBookingDetails
}

export default function BookingsMetric({ booking }: Props) {
  const { t, i18n } = useTranslation()
  const { navigate } = useRouter()

  const { isLoading, data: userBookingsMetric } = useGetUserBookingsMetricQuery()

  const isLateCancellation = getDuration(moment(), moment(booking.start_date)).asHours() < 48
  const policyLink = links.cancellation_policy.angel[
    i18n.language.split('_')[0] as keyof typeof links.cancellation_policy.angel
  ] as string

  return (
    <Box mb="10px" w="100%" bg="white" p="20px" borderColor="gray.200" borderWidth="1px">
      <Heading color="gray.900" fontSize="18px" mt="10px" mb="15px">
        {t('cancellationConsequencesTitle')}
      </Heading>

      <HStack alignItems="center" mb="10px">
        <Text fontSize="14px" color="gray.700" mb="20px">
          {t('cancellationConsequencesMessageAngel')}
          <Text
            onPress={() => navigate('WebView', undefined, { uri: policyLink })}
            color="blue.400"
            textTransform="lowercase"
          >
            {' '}
            {t('readMore')}.
          </Text>
        </Text>
      </HStack>

      {isLoading &&
        Array.from({ length: 3 }).map((_, index) => (
          <HStack alignItems="center" mt="10px" py="10px" key={index}>
            <Skeleton h="4" w="100%" rounded="full" startColor="gray.400" />
          </HStack>
        ))}
      {userBookingsMetric && (
        <Box backgroundColor="gray.100" borderRadius="10px">
          <VStack
            px="20px"
            py="8px"
            w="100%"
            flexDir="row"
            borderColor="gray.300"
            borderWidth="1px"
            borderRadius="10px"
            bgColor="white"
            alignItems="center"
            justifyContent="space-between"
            zIndex={2}
          >
            <Flex direction="row" alignItems="center" w="100%">
              <Center alignItems="flex-start" w="50%">
                <Text fontSize="18px" fontWeight="600" color="gray.900">
                  {t('cancelled')}
                </Text>
                {isLateCancellation && (
                  <Text fontSize="15px" fontWeight="600" color="red.500">
                    {t('within48Hours')}
                  </Text>
                )}
              </Center>

              <HStack justifyContent="flex-end" w="50%">
                <Text mr="20px" fontSize="22px" fontWeight="600" color={isLateCancellation ? 'red.500' : 'gray.700'}>
                  {isLateCancellation ? '+1' : '+0'}
                </Text>
                <CharlyIcon
                  color={isLateCancellation ? 'red.500' : 'gray.700'}
                  name={isLateCancellation ? 'face-frown' : 'face-neutral'}
                  size={34}
                />
              </HStack>

              <Box
                position="absolute"
                bottom="-17px"
                w="16px"
                h="16px"
                borderBottomWidth="1px"
                borderColor="gray.300"
                borderRightWidth="1px"
                bgColor="white"
                right={isLateCancellation ? '22%' : '48%'}
                style={{ transform: [{ rotate: '45deg' }] }}
              />
            </Flex>
          </VStack>
          <Box
            px="10px"
            py="10px"
            w="100%"
            flexDir="row"
            borderColor="gray.200"
            borderBottomRadius="10px"
            backgroundColor="gray.100"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Center alignItems="center">
              <Text fontSize="21px" fontWeight="600" color="gray.800">
                {userBookingsMetric.completed_bookings}
              </Text>
              <Text fontSize="14px" color="gray.700" textTransform="lowercase">
                {t('complete')}
              </Text>
            </Center>
            <Divider thickness="1" mx="0" h="65%" orientation="vertical" />

            <Center>
              <Text fontSize="21px" fontWeight="600" color="gray.800" textTransform="lowercase">
                {userBookingsMetric.cancelled_bookings}
              </Text>
              <Text fontSize="14px" color="gray.700">
                {t('cancelled')}
              </Text>
            </Center>
          </Box>
        </Box>
      )}
    </Box>
  )
}
