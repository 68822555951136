import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import Text from '../../elements/Text';
import colors from '../../styles/colors';

import defaultFamilyImage from '../../../assets/images/defaultFamilyImage.png';
import defaultAngelImage from '../../../assets/images/defaultAngelImage.png';

const View = styled.View`
  margin: ${({ margins }) => margins};
  padding: ${({ paddings }) => paddings};
  align-items: center;
  ${({ justifyContent }) => justifyContent && { 'justify-content': justifyContent }};
  align-self: ${({ alignSelf }) => alignSelf};
  ${({ width }) => width && { width }};
  ${({ height }) => height && { height }};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-left-width: ${({ borderLeftWidth }) => borderLeftWidth};
  border-color: ${colors.secondary};
  border-radius: ${({ borderRadius }) => borderRadius};
`;
const Image = styled.Image`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-color: ${colors.grey07};
  border-width: ${({ borderWidth }) => borderWidth};
  border-radius: ${({ borderRadius }) => borderRadius};
`;

const ProfileAvatar = ({
  margins,
  paddings,
  alignSelf,
  backgroundColor,
  width,
  height,
  borderRadius,
  borderLeftWidth,
  source,
  imageSize,
  imageWidth,
  imageHeight,
  imageBorderWidth,
  imageBorderRadius,
  isAngel,
  showPlaceholder,
  children
}) => {
  const [t] = useTranslation();
  const defaultImage = isAngel ? defaultAngelImage : defaultFamilyImage;

  return (
    <View
      margins={margins}
      paddings={paddings}
      alignSelf={alignSelf}
      justifyContent={showPlaceholder ? 'center' : 'flex-start'}
      width={width}
      height={height}
      backgroundColor={isAngel ? 'transparent' : backgroundColor}
      borderRadius={isAngel ? 0 : borderRadius}
      borderLeftWidth={borderLeftWidth}>
      {!isAngel && showPlaceholder ? (
        <Text paddings="10px" fontSize="12px" color={colors.grey04} textAlign="center">
          {t('profileScreensAddFamilyPhoto')}
        </Text>
      ) : (
        <Image
          width={isAngel ? imageSize : imageWidth}
          height={isAngel ? imageSize : imageHeight}
          borderWidth={imageBorderWidth}
          borderRadius={isAngel ? imageSize : imageBorderRadius}
          source={source ? { uri: source } : defaultImage}
        />
      )}
      {children}
    </View>
  );
};

ProfileAvatar.propTypes = {
  margins: PropTypes.string,
  paddings: PropTypes.string,
  alignSelf: PropTypes.string,
  backgroundColor: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderLeftWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  source: PropTypes.node,
  imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imageSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imageBorderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imageBorderWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isAngel: PropTypes.bool.isRequired,
  showPlaceholder: PropTypes.bool,
  children: PropTypes.node
};

ProfileAvatar.defaultProps = {
  margins: '0px',
  paddings: '0px',
  alignSelf: 'auto',
  width: 'auto',
  height: 'auto',
  backgroundColor: colors.grey09,
  imageWidth: '61px',
  imageHeight: '41px',
  imageSize: '80px',
  imageBorderWidth: '1px',
  borderRadius: '4px',
  imageBorderRadius: '4px',
  borderLeftWidth: '0px',
  source: null,
  showPlaceholder: false,
  children: null
};

export default ProfileAvatar;
