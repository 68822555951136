import React, { useState } from 'react'
import { Card, CharlyIcon, NumberInput, ScreenLayout } from '@charlycares/ui'
import { Box, Button, Heading, HStack, Icon, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import { Alert, Keyboard, Linking } from 'react-native'
import { useGetAngelsAvgRatesQuery, useGetUserQuery, useUpdateProfileMutation } from '@charlycares/data-access'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  ANGEL_RATE_MAX_AMOUNT,
  ANGEL_RATE_MIN_AMOUNT,
  formatMonetaryValue,
  formatMoney,
  links
} from '@charlycares/shared'

import NotificationsSettings from './NotificationsSettings'
import AngelAccount from './AngelAccount'

const ValidationSchema = Yup.object().shape({
  normal_rate: Yup.number().required(),
  extra_rate: Yup.number().required(),
  promo_mail: Yup.boolean(),
  system_mail: Yup.boolean(),
  promo_push: Yup.boolean(),
  system_push: Yup.boolean()
})

export default function AngelSettings() {
  const { t, i18n } = useTranslation()
  const locale = i18n.language.split('_')[0]

  const [editable, setEditable] = useState(false)

  const { data: user } = useGetUserQuery()
  const { data: avgRates } = useGetAngelsAvgRatesQuery()
  const [updateProfile, { isLoading }] = useUpdateProfileMutation()

  const { handleSubmit, resetForm, values, setFieldValue } = useFormik({
    initialValues: {
      normal_rate: user?.angel?.normal_rate || ANGEL_RATE_MIN_AMOUNT,
      extra_rate: user?.angel?.extra_rate || ANGEL_RATE_MIN_AMOUNT,
      promo_mail: Boolean(user?.notification_settings.promotional_email),
      system_mail: Boolean(user?.notification_settings.system_email),
      promo_push: Boolean(user?.notification_settings.promotional_push),
      system_push: Boolean(user?.notification_settings.system_push)
    },
    onSubmit: async values => {
      Keyboard.dismiss()
      await updateProfile(values)
      setEditable(false)
    },
    validationSchema: ValidationSchema,
    validateOnChange: false
  })

  const onCancel = () => {
    Alert.alert(t('profileScreensLeaveThisScreen'), t('profileScreensChangesNotSaved'), [
      { text: t('cancel') },
      {
        text: t('leave'),
        onPress: () => {
          Keyboard.dismiss()
          resetForm()
          setEditable(false)
        }
      }
    ])
  }

  return (
    <ScreenLayout
      title={t('Settings')}
      headerLeft={
        editable ? (
          <Button
            h="38px"
            p="5px"
            onPress={onCancel}
            variant="ghost"
            _text={{ color: 'primary.400', fontSize: '16px' }}
            _pressed={{
              bg: 'primary.alpha.20'
            }}
            _hover={{
              bg: 'primary.alpha.20'
            }}
          >
            {t('cancel')}
          </Button>
        ) : undefined
      }
      headerRight={
        <Button
          h="38px"
          p="5px"
          onPress={() => (editable ? handleSubmit() : setEditable(true))}
          variant="ghost"
          _text={{ color: 'primary.400', fontSize: '16px' }}
          _pressed={{
            bg: 'primary.alpha.20'
          }}
          _hover={{
            bg: 'primary.alpha.20'
          }}
          isLoading={isLoading}
          isLoadingText={t('Updating')}
          spinnerPlacement="end"
          _spinner={{
            color: 'primary.400'
          }}
        >
          {t(editable ? 'save' : 'edit')}
        </Button>
      }
    >
      {/* Angel Rates */}
      <Card>
        <Box w="100%">
          <Heading fontSize={'18px'} fontWeight="600">
            {t('angelHourlyRatesTitle')}
          </Heading>
          <Text mt="10px" fontSize={'15px'}>
            {t('angelHourlyRatesDescription')}
          </Text>

          <Button
            mt="30px"
            variant="outline"
            onPress={() => Linking.openURL(links.earnings[locale as keyof typeof links.earnings])}
          >
            {t('angelHourlyRatesBtn')}
          </Button>

          <Box mt="40px" flexDir="row">
            <Box flex={1}>
              <HStack alignItems="center" space="5px">
                <CharlyIcon name="icn-day" size={32} color="black" />
                <Text fontSize="15px">{t('day')}</Text>
              </HStack>

              <Text fontSize={'12px'} color="gray.700">
                {`${t('profileScreensCompetitiveRate')}  ${formatMoney(
                  avgRates?.['normal_rate' as keyof typeof avgRates]
                )}`}
              </Text>
            </Box>

            <NumberInput
              disabled={!editable}
              changeRate={0.25}
              value={values.normal_rate}
              formatValue={val => formatMonetaryValue(val, locale)}
              min={ANGEL_RATE_MIN_AMOUNT}
              max={ANGEL_RATE_MAX_AMOUNT}
              onChange={val => setFieldValue('normal_rate', val)}
            />
          </Box>

          <Box mt="20px" flexDir="row">
            <Box flex={1}>
              <HStack alignItems="center" space="5px">
                <CharlyIcon name="icn-night" size={'32px'} color="black" />
                <Text fontSize="15px">{t('night')}</Text>
              </HStack>

              <Text fontSize={'12px'} color="gray.700">
                {`${t('profileScreensCompetitiveRate')}  ${formatMoney(
                  avgRates?.['extra_rate' as keyof typeof avgRates]
                )}`}
              </Text>
            </Box>

            <NumberInput
              changeRate={0.25}
              disabled={!editable}
              value={values.extra_rate}
              formatValue={val => formatMonetaryValue(val, locale)}
              min={ANGEL_RATE_MIN_AMOUNT}
              max={ANGEL_RATE_MAX_AMOUNT}
              onChange={val => setFieldValue('extra_rate', val)}
            />
          </Box>
        </Box>
      </Card>

      {/* Notifications */}
      <NotificationsSettings values={values} setFieldValue={setFieldValue} editable={editable} />

      {/* Account */}
      <AngelAccount />
    </ScreenLayout>
  )
}

AngelSettings.navigationOptions = () => ({
  headerShown: false
})
