import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../elements';
import { TextView, TextTextView } from '../../Texts';
import colors from '../../../styles/colors';

import creditCardIcon from '../../../../assets/icons/icn-creditcard.png';

const View = styled.View`
  flex-direction: row;
  align-items: center;
`;

const BankAccount = ({ paddings, isAngel, accountLastDigits }) => {
  const [t] = useTranslation();

  return (
    <TextView
      flexDirection="column"
      alignItems="flex-start"
      paddings={paddings}
      text={isAngel ? t('profileScreensBankAccount').toUpperCase() : t('profileScreensBankAccount')}
      fontSize={isAngel ? '12px' : '17px'}
      fontWeight="300"
      textColor={isAngel ? colors.grey02 : colors.primaryText}>
      <View>
        <Icon size="32px" source={creditCardIcon} />

        <TextTextView
          margins="0px 0px 0px 10px"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          firstText={`**** **** **** ${accountLastDigits}`}
          firstTextFontFamily="Martel"
          firstTextFontSize="16px"
          firstTextFontWeight="600"
          secondText={t('profileScreensBankCredit')}
          secondTextFontSize="12px"
          secondTextColor={colors.defaultBtnBackgroundColor}
        />
      </View>
    </TextView>
  );
};

BankAccount.propTypes = {
  paddings: PropTypes.string,
  isAngel: PropTypes.bool,
  accountLastDigits: PropTypes.string.isRequired
};

BankAccount.defaultProps = {
  paddings: '0px',
  isAngel: false
};

export default BankAccount;
