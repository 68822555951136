import { UserLanguages } from 'libs/shared/src/types/User'
import { Moment } from 'moment'
import { Money } from './Money'
import { BookingRate, BookingCancellationPolicy } from '@charlycares/data-access'

export interface IBookingDetails {
  angel?: {
    obscured_id: number
    angel_user_id: string
    age: number
    first_name: string
    image: string
    phone: string
    conversation_id: string
  }
  family: {
    obscured_id: string
    id: number
    child_count: number
    city: string
    family_id: number
    has_cat: boolean
    has_dog: boolean
    image: string
    lat: number
    lon: number
    name: string
    short_bio: string
    street_name: string
    average_rating?: number
    rating_count: number
  }
  angel_status: AngelBookingStatus
  booking_dates: BookingDate[]
  current_state: BookingStates
  day_rate_per_hour: Money
  expected_earnings: Money
  extra_hourly_rate: Money
  distance: number
  end_date: string
  has_offer: boolean
  id: number
  invitations: IInvitation[]
  max_hourly_rate: Money
  night_rate_per_hour: Money
  normal_hourly_rate: Money
  obscured_id: string
  offers: IOffer[]
  on_job_board: boolean
  personal_message: string
  start_date: string
  travel_allowance: Money
  rate?: BookingRate
  insurance_fee_per_hour?: Money
  cancellation_policies?: BookingCancellationPolicy[]
  service_type: BookingServiceType
  service_group_type?: ServiceGroupType
  maximum_amount_of_angels?: number
  invitations_allowed?: boolean
}

export interface IBookingFamily {
  family_user_id: string
  image: string
  name: string
  short_bio: string
  child_count: number
  phone: number
  city: string
  street_name: string
  street_number: number
  dog: number
  cat: number
  conversation_id: number
  languages: UserLanguages
  kids: number[]
}

export interface IBookingAngel {
  id: number
  obscured_id: string
  angel_user_id: string
  age: number
  babysit_expertise: boolean
  day_rate_per_hour: Money
  distance: number
  driving_license: boolean
  first_aid: boolean
  first_name: string
  image: string
  night_rate_per_hour: Money
  tutor: boolean
  video: string
  is_liked?: boolean
  average_rating?: number
  toddler_expertise?: boolean
  written_review_count?: number
}

export interface IInvitation {
  booking_obscured_id: string
  obscured_id: string
  parent_booking_obscured_id: string
  current_state: InvitationsStates
  day_rate_per_hour: Money
  expires_at: string
  first_reaction_at?: string
  night_rate_per_hour: Money
  angel: IBookingAngel
}

export interface IOffer {
  angel: IBookingAngel
  booking_obscured_id: string
  from_invite: boolean
  day_rate_per_hour: Money
  ends_at: string
  expires_at: string
  night_rate_per_hour: Money
  obscured_id: string
  offers: {
    obscured_id: string
    booking_obscured_id: string
    status: OfferStates
    starts_at: string
    ends_at: string
  }[]
  parent_booking_obscured_id: string
  parent_offer_obscured_id: string
  starts_at: string
  status: OfferStates
  travel_allowance: Money
  amount: 0
  currency: string
  angel_message: null
  remaining_booking_dates?: BookingDate[]
}

/**
 * @desc This is use for creating a booking dates
 */
export type SearchBookingsDates = {
  startDate: string | Moment | Date
  endDate: string | Moment | Date
  repeatDates?: SearchBookingsDates[]
}

export type BookingDate = {
  current_state: BookingStates
  end_date: string
  obscured_id: string
  parent_obscured_id: string
  start_date: string
  repeat_days: Omit<BookingDate, 'repeat_days'>[]
}

export enum BookingTypes {
  SINGLE = 'single',
  MULTI = 'multi'
}

export enum BookingServiceType {
  CHILD_CARE_SHORT_TERM = 'child_care_short_term',
  CHILD_CARE_LONG_TERM = 'child_care_long_term',
  PET_CARE = 'pet_care',
  ELDERLY_CARE = 'elderly_care',
  MEET_AND_GREET = 'meet_and_greet',
  TUTORING = 'tutoring',
  SINGLE = 'single',
  MULTI = 'multi',
  HOLIDAY = 'holiday',
  WALKING_SERVICE = 'walking_service',
  DAY_CARE = 'day_care',
}

export enum ServiceGroupType {
  CHILD_CARE = 'child_care',
  PET_CARE = 'pet_care',
  ELDERLY_CARE = 'elderly_care'
}

export enum BookingStates {
  PENDING = 'pending',
  PENDING_EDIT = 'pending_edit',
  PENDING_PAYMENT = 'pending_payment',
  ACCEPTED = 'accepted',
  CANCELED = 'canceled',
  CANCELLED = 'cancelled',
  ENDED = 'ended',
  DECLINED = 'declined'
}

export enum OfferStates {
  PENDING = 'pending',
  PENDING_EDIT = 'pending_edit',
  ACCEPTED = 'accepted',
  CANCELLED = 'cancelled',
  DECLINED = 'declined',
  GIVEN = 'given',
  EXPIRED = 'expired'
}

export enum InvitationsStates {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  PENDING_EDIT = 'pending_edit',
  PENDING_PAYMENT = 'pending_payment',
  PENDING_APPROVAL = 'pending_approval',
  CANCELED = 'canceled',
  CANCELLED = 'cancelled',
  ENDED = 'ended',
  DECLINED = 'declined',
  DECLINED_BY_FAMILY = 'declined_by_family',
  EXPIRED = 'expired',
  GIVEN = 'given'
}

export enum AngelBookingStatus {
  PENDING = 'pending',
  PENDING_PAYMENT = 'pending_payment',
  PENDING_OFFER = 'pending_offer',
  PENDING_INVITATION = 'pending_invitation',
  PENDING_EDIT = 'pending_edit',
  ACCEPTED = 'accepted',
  CANCELLED = 'cancelled',
  ENDED = 'ended',
  DECLINED_BY_YOU = 'declined_by_you',
  DECLINED_BY_FAMILY = 'declined_by_family',
  DECLINED = 'declined',
  GIVEN_TO_ANOTHER_ANGEL = 'given_to_another_angel',
  EXPIRED = 'expired'
}

export enum BookingCancellationPolicyType {
  MORE_THAN_48_H_BEFORE_START_TIME = 'more_than_48_h_before_start_time',
  LESS_THAN_48_H_BEFORE_START_TIME = 'less_than_48_h_before_start_time',
  LESS_THAN_24_H_BEFORE_START_TIME = 'less_than_24_h_before_start_time',
  LESS_THAN_3_H_BEFORE_START_TIME = 'less_than_3_h_before_start_time',
  START_TIME_HAS_PASSED = 'start_time_has_passed',
  ANGEL_NO_SHOW = 'angel_no_show',
  ANGEL_CANCELLED = 'angel_cancelled'
}

export enum BookingCancellationReasonType {
  SOMETHING_CAME_UP = 'something_came_up',
  ANGEL_IS_SICK = 'angel_is_sick',
  MISREAD_CALENDAR = 'misread_calendar',
  PLANS_HAVE_CHANGED = 'plans_have_changed',
  CHILD_IS_SICK = 'child_is_sick',
  FOUND_OWN_SITTER = 'found_own_sitter',
  ANGEL_NO_SHOW = 'angel_no_show',
  WRONG_DATES = 'wrong_dates',
  NO_SUITABLE_ANGEL = 'no_suitable_angel',
  OTHER = 'other',
  NO_FEE = 'no_fee',
  ANGEL_CANCELLED = 'angel_cancelled'
}
