import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { TypingAnimation } from 'react-native-typing-animation'
import { connect } from 'react-redux'
import { ActivityIndicator } from 'react-native'
import {
  getConversations,
  getMessages,
  addMessage,
  messagesRead,
  updateMessage,
  addRecivedMessage,
  updateConversationLastMessage,
  clearMessageAdded
} from '../../stores/actions/chatAction'
import { Text, Circle } from '../../elements'
import {
  TextView,
  TextButton,
  IconButton,
  AngelAvatar,
  CustomGiftedChat,
  CustomSend,
  CustomInputToolbar,
  CustomLoadEarlier,
  CustomBubble,
  BookingBubble,
  CustomDay,
  Header
} from '../../components'
import { getFormatedDate } from '../../utils/functions'
import theme from '../../styles/theme'
import { IcnBlock } from '../../components/Icons'

const SafeAreaView = styled.SafeAreaView`
  flex: 1 1 auto;
  min-height: 100%;
  background-color: ${theme.colors.backgroundColor};
`
const View = styled.View`
  flex-direction: row;
  padding: 10px 20px 10px 20px;
  width: 100%;
  background-color: ${theme.colors.yellow};
`

const Room = ({
  location,
  history,
  route,
  navigation,
  conversation,
  isActive,
  socket,
  setScreen,
  activeChat,
  user,
  fetching,
  conversations = [],
  addNewMessage,
  addNewRecivedMessage,
  setMessagesRead,
  getAllMessages,
  updateLastMessage,
  updateMessageData,
  getAllConversations,
  clearMessageAddedData
}) => {
  const { conversationId } = location.state || route.params || {}
  const [page, setPage] = useState(1)
  const [isTyping, setIsTyping] = useState(false)
  const [isPersonActive, setIsActive] = useState(isActive)
  const [messageUpdating, setMessageUpdating] = useState(null)
  const [socketDisconnected, setSocketDisconnected] = useState(false)
  const [t] = useTranslation()
  let typingTimer

  if (navigation) {
    navigation.setOptions(() => {
      const { first_name = '', image, last_seen } = conversation

      return {
        headerTitle: (
          <TextView flexDirection="column" text={first_name} fontFamily="Martel" textAlign="center" fontWeight="600">
            {isTyping ? (
              <TypingAnimation
                style={{ flex: 1, width: 20, height: 'auto' }}
                dotColor={theme.colors.primaryText}
                dotMargin={5}
                dotSpeed={0.1}
                dotRadius={2.5}
                dotX={7.5}
                dotY={0}
              />
            ) : (
              last_seen && (
                <Text fontSize="13px" color={theme.colors.grey02}>
                  {t('chatScreensLastSeen')}
                  {getFormatedDate(last_seen, 'DD MMM, HH:mm')}
                </Text>
              )
            )}
          </TextView>
        ),
        headerRight: (
          <AngelAvatar margins="15px 10px 15px 15px" source={image}>
            <Circle
              position="absolute"
              bottom={0}
              right={0}
              size={16}
              borderColor={theme.colors.white}
              backgroundColor={isPersonActive ? theme.colors.green : theme.colors.grey05}
            />
          </AngelAvatar>
        )
      }
    })
  }

  const setAllMessagesRead = () => {
    const { user_id, last_message } = conversation
    if (last_message) {
      setMessagesRead(last_message.id, moment().format('YYYY-MM-DD HH:mm:ss'), last_message.user_id === user_id)
    }
  }

  const showMessage = message => {
    const { conversation_id } = conversation
    addNewRecivedMessage(message, conversation_id)
    updateLastMessage(conversation_id, message)
  }

  const initChatConversation = (conversation_id, user_id, userId) => {
    if (userId !== user_id) {
      setIsActive(true)
      setAllMessagesRead()
    }
  }

  const closeChatConversation = () => {
    setIsActive(false)
  }

  const showNewMessage = (user_id, message) => {
    if (message.receiver_id !== user_id) {
      showMessage(message)
    }
  }

  const chatTyping = (user_id, userId) => {
    if (userId !== user_id) {
      setIsTyping(true)
      setIsActive(true)
    }
  }

  const chatTypingCancel = (user_id, userId) => {
    if (userId !== user_id) {
      setIsTyping(false)
    }
  }

  const messageChaged = (user_id, userId, messageId) => {
    if (userId !== user_id) {
      setMessageUpdating(messageId)
      updateMessageData(messageId)
    }
  }

  const onGetMessages = userId => {
    getAllMessages(userId, page)
  }

  const initRoomSocket = (conversation_id, user_id) => {
    socket.on('disconnect', () => setSocketDisconnected(true))
    console.log('initRoomSocket')
    socket.removeAllListeners()
    socket.on(`INIT_CHAT_CONVERSATION_${conversation_id}`, userId => {
      console.log('initChatConversation')
      initChatConversation(conversation_id, user_id, userId)
    })
    socket.on(`CLOSE_CHAT_CONVERSATION_${conversation_id}`, () => {
      console.log('CLOSE_CHAT_CONVERSATION_ Room')
      console.log('closeChatConversation')
      closeChatConversation()
    })
    socket.on(`CHAT_SEND_${conversation_id}`, message => {
      console.log('chat send Room', conversation_id, message)
      console.log('showNewMessage')
      showNewMessage(user_id, message)
    })
    socket.on(`CHAT_TYPING_${conversation_id}`, userId => {
      console.log('CHAT_TYPING Room', conversation_id, userId, user_id)
      console.log('chatTyping')
      chatTyping(user_id, userId)
    })
    socket.on(`CHAT_TYPING_CANCEL_${conversation_id}`, userId => {
      console.log('CHAT_TYPING_CANCEL Room', conversation_id, userId, user_id)

      console.log('chatTypingCancel')
      chatTypingCancel(user_id, userId)
    })
    socket.on(`MESSAGE_CHANGED_${conversation_id}`, (userId, messageId) => {
      console.log('MESSAGE_CHANGED_ Room')

      console.log('messageChaged')
      messageChaged(user_id, userId, messageId)
    })
  }

  const initConversation = (convId, userId) => {
    console.log('initConversation')
    initRoomSocket(convId, userId)
    socket.emit('INIT_CHAT_CONVERSATION', convId, userId)
    onGetMessages(userId)
  }

  const onMessageSend = message => {
    const { user_id, conversation_id } = conversation
    const viewedAt = isActive ? moment().format('YYYY-MM-DD HH:mm:ss') : null
    const newMessage = { ...message, received: !!viewedAt, viewed_at: viewedAt }
    addNewMessage(newMessage, user_id)
    console.log('onMessageSend', message)
    updateLastMessage(conversation_id, newMessage)
  }

  const sendMessage = (message, convId) => {
    console.log('sendMEssage')
    socket.emit('CHAT_SEND', message, convId)
    clearMessageAddedData()
  }

  const handleMessageUpdate = (convId, userId, messageId) => {
    setMessageUpdating(null)
    socket.emit('MESSAGE_CHANGED', convId, userId, messageId)
  }

  useEffect(() => {
    console.log('room did mount')
    if (!conversations.length) {
      getAllConversations()
    } else if (conversationId) {
      const [currentConversation] = conversations.filter(({ conversation_id }) => conversation_id === conversationId)
      if (currentConversation) {
        const { conversation_id, user_id } = currentConversation
        console.log({ currentConversation })
        initConversation(conversation_id, user_id)
      } else {
        getAllConversations()
      }
    } else if (conversation) {
      const { conversation_id, user_id } = conversation || {}
      console.log({ conversation })
      initConversation(conversation_id, user_id)
    }

    const { image, text } = location.state || route.params || {}
    if (image) {
      onMessageSend({ image, text })
    }

    return () => {
      const { conversation_id, user_id } = conversation || {}
      socket.emit('CLOSE_CHAT_CONVERSATION', conversation_id, user_id)
      socket.removeAllListeners()
      console.log('disconnect room', { socket })
    }
  }, [])

  useEffect(() => {
    // check if conversations are fetched and initialize conversation
    if (conversations.length) {
      if (conversationId && !conversation) {
        const [currentConversation] = conversations.filter(({ conversation_id }) => conversation_id === conversationId)
        if (currentConversation) {
          if (navigation) {
            navigation.setParams({ conversation: currentConversation })
          }
          const { conversation_id, user_id } = currentConversation
          initConversation(conversation_id, user_id)
        }
      } else if (Object.keys(activeChat).length) {
        setAllMessagesRead()
      }
    }
  }, [conversations])

  useEffect(() => {
    const { messageAdded, messageUpdated } = activeChat
    const { conversation_id, user_id } = conversation || {}

    if (messageAdded) {
      const [newMessage] = activeChat.data

      if (newMessage.receiver_id === user_id) {
        sendMessage(newMessage, conversation_id)
      }
    }
    if (messageUpdated) {
      handleMessageUpdate(conversation_id, user_id, messageUpdating)
    }
  }, [activeChat])

  const onTyping = () => {
    const { conversation_id } = conversation
    console.log('emit chat_typing', conversation_id)
    socket.emit('CHAT_TYPING', conversation_id)
  }

  const onTypingStoped = () => {
    const { conversation_id } = conversation
    socket.emit('CHAT_TYPING_CANCEL', conversation_id)
  }

  const onInputChange = () => {
    if (typingTimer) {
      clearTimeout(typingTimer)
    }
    onTyping()
    typingTimer = setTimeout(onTypingStoped, 2000)
  }

  const getNextPage = userId => {
    setPage(page + 1)
    onGetMessages(userId)
  }

  const onImagePress = () => {
    const { first_name, last_name } = conversation || {}
    history.push({
      pathname: '/chat-send-image',
      state: {
        conversation,
        isActive,
        isTyping,
        role: conversation.user.role,
        name: conversation.user.role === 'angel' ? first_name : last_name
      }
    })
  }

  const onCameraPress = () => {}

  const onCancelPress = messageId => {
    setMessageUpdating(messageId)
    updateMessageData(messageId, { show_date: false })
  }

  const onGoToRequestPress = (messageId, bookingId, booking, proposedDate, invitationId) => {
    const { first_name, image } = conversation
    const conversationUser = conversation.user
    if (bookingId) {
      const bookingDetailsId = conversationUser.role !== 'angel' ? invitationId : bookingId
      if (navigation) {
        navigation.navigate('BookingsActiveInvitationDetails', { bookingId: bookingDetailsId })
      } else {
        history.push({ pathname: '/booking', state: { bookingId: bookingDetailsId } })
      }
    } else {
      const id = conversationUser.role === 'angel' ? conversationUser.angel.id : conversationUser.family.id
      setMessageUpdating(messageId)
      history.push({
        pathname: '/book-direct',
        state: {
          angelId: conversationUser.role === 'angel' ? conversationUser.obscured_id : undefined,
          familyId: conversationUser.role === 'family' ? conversationUser.obscured_id : undefined
        }
      })
    }
  }

  const renderSend = ({ text, onSend }) => {
    const { phone, first_name, image } = conversation
    const conversationUser = conversation.user
    const { role, angel, family } = conversationUser
    const id = role === 'angel' ? angel.id : family.id
    const disabled = text === ''

    return (
      <CustomSend
        sendDisabled={disabled}
        sendText={text}
        phone={phone}
        onImagePress={onImagePress}
        onCameraPress={onCameraPress}
        onAddBookingPress={() => {
          history.push({
            pathname: '/book-direct',
            state: {
              angelId: role === 'angel' ? angel.obscured_id : undefined,
              familyId: role === 'family' ? family.obscured_id : undefined
            }
          })
        }}
        onSend={onSend}
      />
    )
  }

  const goBack = () => {
    if (navigation) {
      navigation.goBack()
    } else {
      setScreen(0)
    }
  }

  const { user_id, first_name, last_name, last_seen, image } = conversation || {}
  let messagesToShow = []

  if (activeChat.data) {
    console.log(activeChat.data)
    messagesToShow = activeChat.data.map(message => ({
      ...message,
      user: { _id: message.sender.id }
    }))
  }

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          title={first_name}
          subtitle={
            isTyping ? (
              <TypingAnimation
                style={{ flex: 1, width: 20, height: 'auto' }}
                dotColor={theme.colors.notify}
                dotMargin={5}
                dotSpeed={0.1}
                dotRadius={2.5}
                dotX={7.5}
                dotY={0}
              />
            ) : (
              last_seen && (
                <>
                  {t('chatScreensLastSeen')}
                  {getFormatedDate(last_seen, 'DD MMM, HH:mm')}
                </>
              )
            )
          }
          right={
            <AngelAvatar margins="0 8px" source={image}>
              <Circle
                position="absolute"
                bottom={0}
                right={0}
                borderColor={theme.colors.white}
                backgroundColor={isPersonActive ? theme.colors.green : theme.colors.grey05}
              />
            </AngelAvatar>
          }
          left={
            <IconButton iconSize="32px" iconColor={theme.colors.secondary} iconName="icn-nav-left" onPress={goBack} />
          }
        />
      )}

      {socketDisconnected && (
        <View>
          <IcnBlock size="32px" color={theme.colors.alert} />

          <TextView
            flexDirection="column"
            alignItems="flex-start"
            margins="5px 0px 0px 5px"
            text={t('chatScreensNoConnection')}
            fontFamily="Martel"
            fontSize="16px"
            fontWeight="600"
          >
            <TextButton
              paddings="0px"
              height="auto"
              borderBottomWidth={0}
              borderTopWidth={0}
              text={t('chatScreensReconnect')}
              fontSize="13px"
              textColor={theme.colors.primaryText}
              fontWeight="normal"
              onPress={() => socket.connect()}
            />
          </TextView>
        </View>
      )}

      {conversation ? (
        <CustomGiftedChat
          inverted={false}
          loadEarlier
          isLoadingEarlier={fetching}
          user={{ _id: user.id }}
          minInputToolbarHeight={98}
          isCustomViewBottom
          messages={messagesToShow.reverse()}
          onLoadEarlier={() => getNextPage(user_id)}
          renderBubble={chatProps => <CustomBubble {...chatProps} />}
          renderDay={chatProps => <CustomDay {...chatProps} />}
          // renderCustomView={chatProps => (
          //   <BookingBubble
          //     {...chatProps}
          //     myMessage={chatProps.currentMessage.receiver_id === user_id}
          //     name={(user.role !== 'angel' ? first_name : last_name) || ''}
          //     onCancelPress={onCancelPress}
          //     onGoToRequestPress={onGoToRequestPress}
          //   />
          // )}
          renderLoadEarlier={chatProps => (
            <CustomLoadEarlier {...chatProps} label={t('chatScreensLoadEarlierMessages')} />
          )}
          renderInputToolbar={chatProps => (
            <CustomInputToolbar
              {...chatProps}
              multiline={false}
              placeholder={t('message')}
              textInputProps={{
                onChange: onInputChange,
                onSubmitEditing: () => chatProps.onSend({ text: chatProps.text.trim() }, true)
              }}
            />
          )}
          renderSend={renderSend}
          onSend={([message]) => onMessageSend(message)}
        />
      ) : (
        <ActivityIndicator />
      )}
    </SafeAreaView>
  )
}

Room.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      conversation: PropTypes.shape({ user: PropTypes.shape({ role: PropTypes.string }) }),
      conversationId: PropTypes.string,
      isActive: PropTypes.bool
    })
  }),
  history: PropTypes.shape({ push: PropTypes.func }),
  route: PropTypes.shape({
    params: PropTypes.shape({
      conversation: PropTypes.shape({ user: PropTypes.shape({ role: PropTypes.string }) }),
      conversationId: PropTypes.string,
      isActive: PropTypes.bool
    })
  }),
  navigation: PropTypes.shape({
    setOptions: PropTypes.func,
    navigate: PropTypes.func,
    setParams: PropTypes.func,
    goBack: PropTypes.func
  }),
  socket: PropTypes.shape({
    on: PropTypes.func,
    emit: PropTypes.func,
    removeAllListeners: PropTypes.func,
    connect: PropTypes.func
  }).isRequired,
  conversation: PropTypes.shape({
    user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    last_seen: PropTypes.string,
    image: PropTypes.string,
    conversation_id: PropTypes.string,
    phone: PropTypes.string,
    user: PropTypes.shape({
      role: PropTypes.string,
      angel: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
      family: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
    }),
    last_message: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }).isRequired,
  isActive: PropTypes.bool,
  setScreen: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    role: PropTypes.string,
    angel: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
    family: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired,
  text: PropTypes.string,
  onSend: PropTypes.func,
  fetching: PropTypes.bool.isRequired,
  activeChat: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    messageAdded: PropTypes.bool,
    messageUpdated: PropTypes.bool
  }),
  conversations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getAllMessages: PropTypes.func.isRequired,
  addNewMessage: PropTypes.func.isRequired,
  setMessagesRead: PropTypes.func.isRequired,
  updateMessageData: PropTypes.func.isRequired,
  addNewRecivedMessage: PropTypes.func.isRequired,
  updateLastMessage: PropTypes.func.isRequired,
  getAllConversations: PropTypes.func.isRequired,
  clearMessageAddedData: PropTypes.func.isRequired
}

Room.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null,
  isActive: false,
  text: '',
  onSend: () => {},
  activeChat: {}
}

const mapStateToProps = ({ Chat, User }) => ({
  activeChat: Chat.activeChat,
  fetching: Chat.fetching,
  conversations: Chat.conversations,
  user: User.user
})

const mapDispatchToProps = {
  getAllConversations: getConversations,
  getAllMessages: getMessages,
  addNewMessage: addMessage,
  setMessagesRead: messagesRead,
  updateMessageData: updateMessage,
  addNewRecivedMessage: addRecivedMessage,
  updateLastMessage: updateConversationLastMessage,
  clearMessageAddedData: clearMessageAdded
}

export default connect(mapStateToProps, mapDispatchToProps)(Room)
