import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getUser } from '../../../stores/actions/userAction';
import { Text } from '../../../elements';
import { TextIconView, TextButton, Header } from '../../../components';
import colors from '../../../styles/colors';

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  padding: 20px;
`;

const BankAccountFailed = ({ history, location, route, navigation, paymentLink, getUserData }) => {
  const { hasBookingPending } = route.params || location.state || {};
  const [t] = useTranslation();

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize={18} fontWeight="600">
          {t('onnectBankAccountFailedHeader')}
        </Text>
      )
    });
  }

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <SafeAreaView>
      {!navigation && <Header title={t('connectBankAccountFailedHeader')} />}
      <TextIconView
        flexDirection="row-reverse"
        justifyContent="flex-end"
        backgroundColor={colors.white}
        iconMargins="0px 10px 0px 0px"
        name="close"
        iconSize="25px"
        iconColor={colors.alert}
        text={t('connectBankAccountFailed')}
        textWidth="80%"
        textAlign="left"
      />

      {hasBookingPending && (
        <TextIconView
          flexDirection="row-reverse"
          justifyContent="flex-end"
          paddings="20px 0px 20px 0px"
          borderBottomWidth="1px"
          backgroundColor={colors.white}
          iconMargins="0px 10px 0px 0px"
          name="close"
          iconSize="25px"
          iconColor={colors.alert}
          text={t('signUpParentScreensRequestNotSent')}
          textWidth="80%"
          textAlign="left"
        />
      )}

      <Text margins="20px 20px 0px 20px">{t('connectBankAccountFailedExplanation')}</Text>

      <Text margins="20px 20px 0px 20px" fontSize="16px" fontWeight={600}>
        {t('connectBankAccountFailedCompanyName')}
      </Text>

      <Text margins="20px 20px 0px 20px">{t('connectBankAccountFailedPhoneMain')}</Text>

      <Text margins="20px 20px 0px 20px">{t('connectBankAccountFailedPhoneSecondary')}</Text>

      <Text margins="20px 20px 0px 20px">{t('connectBankAccountFailedExplanationSecond')}</Text>

      <TextButton
        margins="20px 20px 0px 20px"
        backgroundColor={colors.primary}
        text={t('tryAgain')}
        onPress={() => {
          history.push('/signup-family-bank');
        }}
      />
    </SafeAreaView>
  );
};

BankAccountFailed.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      hasBookingPending: PropTypes.bool
    })
  }),
  route: PropTypes.shape({
    params: PropTypes.shape({
      hasBookingPending: PropTypes.bool
    })
  }),
  navigation: PropTypes.shape({ navigate: PropTypes.func, setOptions: PropTypes.func }),
  paymentLink: PropTypes.string,
  getUserData: PropTypes.func.isRequired
};

BankAccountFailed.defaultProps = {
  location: {},
  route: {},
  navigation: null,
  paymentLink: ''
};

const mapStateToProp = ({ User }) => ({
  paymentLink: User.user && User.user.payment_link
});

const mapDispatchToProps = {
  getUserData: getUser
};

export default connect(mapStateToProp, mapDispatchToProps)(BankAccountFailed);
