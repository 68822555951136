import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface B2bSlice {
  code: string
}

const initialState: B2bSlice = {
  code: ''
}

export const b2bSlice = createSlice({
  name: 'b2b',
  initialState,
  reducers: {
    setB2bCode: (state, action: PayloadAction<{ code: string }>) => {
      state.code = action.payload.code
    }
  }
})

// Action creators are generated for each case reducer function
export const { setB2bCode } = b2bSlice.actions

export const b2bReducer = b2bSlice.reducer
