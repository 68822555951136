import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { ContainerView, Text, Circle, Heading } from '../../elements';
import { TextTextView } from '../Texts';
import theme from '../../styles/theme';

const ScrollView = styled.ScrollView`
  width: 280px;
`;
const View = styled.View`
  flex-direction: row;
  align-items: center;
  margin: 10px 0px 10px 0px;
`;

const ParentReviews = ({ isAngel, reviews }) => {
  const [page, setPage] = useState(0);
  const scrollView = useRef(null);
  const [t] = useTranslation();

  const onMomentumScrollEnd = ({ nativeEvent: { contentOffset } }) => {
    const index = Math.round(contentOffset.x / 280);
    setPage(index);
  };

  useEffect(() => {
    const cycleInterval = setTimeout(() => {
      let newPage = 0;
      if (page < reviews.length - 1) {
        newPage = page + 1;
      }
      if (scrollView.current) {
        setPage(newPage);
        scrollView.current.scrollTo({ x: 280 * newPage, y: 0, animated: true });
      }
    }, 4000);

    return () => clearTimeout(cycleInterval);
  }, [page, reviews, scrollView.current]);

  return (
    <ContainerView paddings="32px 0" alignItems="center" backgroundColor={theme.colors.grey09}>
      <Heading
        xxl
        ariaLevel={3}
        text={
          isAngel ? t('dashboardScreensAngelReviewsTitle') : t('dashboardScreensParentReviewsTitle')
        }
      />
      {reviews.length ? (
        <>
          <ScrollView
            scrollEventThrottle={16}
            ref={scrollView}
            horizontal
            pagingEnabled
            showsHorizontalScrollIndicator={false}
            onMomentumScrollEnd={onMomentumScrollEnd}>
            {reviews.map(({ id, first_name }, index) => (
              <TextTextView
                key={id}
                flexDirection="column"
                justifyContent="flex-start"
                width="280px"
                maxWidth="100%"
                firstText={`"${t(
                  `${isAngel ? 'dashboardScreenAngelReview' : 'discoverParentReview'}${index + 1}`
                )}"`}
                firstTextMargins="0 0 8px 0"
                firstTextFontSize="15px"
                firstTextAlign="center"
                secondText={`- ${first_name} -`}
                secondTextFontSize="15px"
                secondTextAlign="center"
              />
            ))}
          </ScrollView>

          <View>
            {reviews.map(({ id }, index) => (
              <Circle
                key={id}
                margins="0px 5px 0px 5px"
                size="10px"
                borderWidth="5px"
                borderColor={page === index ? theme.colors.grey05 : theme.colors.grey07}
              />
            ))}
          </View>
        </>
      ) : (
        <Text>{t('dashboardScreensParentReviewsNoReviews')}</Text>
      )}
    </ContainerView>
  );
};

ParentReviews.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.object),
  isAngel: PropTypes.bool
};

ParentReviews.defaultProps = {
  reviews: [],
  isAngel: false
};

export default ParentReviews;
