import React from 'react'
import { Box, HStack, Text, IBoxProps, Pressable } from 'native-base'
import { CharlyIcon } from '../icons'
import { useTranslation } from 'react-i18next'

interface Props extends IBoxProps {
  onPress: () => void
  isLoading: boolean
  disabled?: boolean
}

const Ideal = ({ onPress, isLoading, disabled, ...boxProps }: Props) => {
  const { t } = useTranslation()

  return (
    <Box rounded="md" bgColor="white" width="100%" px="15px" py="10px" {...boxProps} shadow="3">
      <Pressable onPress={() => onPress()} isDisabled={disabled}>
        <HStack>
          <CharlyIcon name="ideal" size="32px" />
          <Text lineHeight="30px" ml="8px" fontSize="16px" color="text.dark.400" fontWeight="600">
            iDeal
          </Text>
          <Text lineHeight="30px" ml="4px" fontSize="14px" color="text.dark.400">
            {t('ideal.mostUsed')}
          </Text>
        </HStack>
      </Pressable>
    </Box>
  )
}

export default Ideal
