import { useCallback, useEffect } from 'react'
import { useCancelBookingMutation, useGetUserQuery } from '@charlycares/data-access'
import { BookingCancellationReasonType, formatMoney, IBookingDetails, useRouter, UserRoles } from '@charlycares/shared'
import { useTranslation } from 'react-i18next'
import { useAlert } from '../../../hooks'

type Params = {
  booking?: IBookingDetails
  navigation?: any
}

export default function useHandleCancelBooking({ booking, navigation }: Params) {
  const { t } = useTranslation()
  const { navigate } = useRouter(navigation)
  const alert = useAlert()

  const { data: user } = useGetUserQuery()
  const [cancelBooking, cancelBookingResponse] = useCancelBookingMutation()
  const cancelBookingError = cancelBookingResponse.error as any

  const onCancelBooking = useCallback(
    (bookingObscuredId: string, cancellationReason: BookingCancellationReasonType, message?: string) => {
      const shouldBeReopened = [
        BookingCancellationReasonType.ANGEL_CANCELLED,
        BookingCancellationReasonType.ANGEL_NO_SHOW
      ].includes(cancellationReason)

      if (cancellationReason && booking && user) {
        alert.show(
          shouldBeReopened ? t('cancelAndRecreateBookingAlertTitle') : t('cancelBookingAlertTitle'),
          user.role === UserRoles.FAMILY
            ? shouldBeReopened
              ? t('cancelAndRecreateBookingAlertDescription')
              : t('cancelBookingAlertDescription')
            : t('angelCancelBookingAlertDescription', { amount: formatMoney(booking.expected_earnings) }),
          [
            {
              text: shouldBeReopened ? t('cancelAndRecreateBookingAlertExit') : t('cancelBookingAlertExit'),
              onPress: () =>
                user.role === UserRoles.FAMILY
                  ? booking.angel
                    ? navigate('FamilyBookingDetails', `/booking`, { bookingId: booking.obscured_id })
                    : navigate('BookingsScreen', `/bookings`)
                  : navigate('AngelBookingDetails', `angel/booking/${booking.obscured_id}`, {
                      bookingId: booking.obscured_id
                    }),
              style: 'cancel'
            },
            {
              text: shouldBeReopened ? t('cancelAndRecreateBookingAlertConfirm') : t('cancelBookingAlertConfirm'),
              onPress: () =>
                cancelBooking({
                  booking_obscured_id: bookingObscuredId,
                  cancellation_reason: cancellationReason,
                  message: message
                })
            }
          ]
        )
      }
    },
    [alert, booking?.angel, navigate, t]
  )

  useEffect(() => {
    if (cancelBookingResponse.isError) {
      alert.show(
        t('error'),
        cancelBookingError?.status === 422
          ? cancelBookingError?.data?.message || cancelBookingError?.data?.error
          : t('requestError')
      )
    }
  }, [cancelBookingResponse.isError, cancelBookingError, t])

  useEffect(() => {
    if (cancelBookingResponse.isSuccess) {
      if (user?.role === UserRoles.FAMILY) {
        navigate('BookingCancelled', '/booking-cancelled', {
          bookingId: cancelBookingResponse.data?.obscured_id,
          bookingStatus: cancelBookingResponse.data?.current_state
        })
      } else {
        navigate('AngelBookingCancelled', '/angel-booking-cancelled', {
          bookingId: cancelBookingResponse.data?.obscured_id,
          bookingStatus: cancelBookingResponse.data?.current_state
        })
      }
    }
  }, [cancelBookingResponse.isSuccess, t])

  return { onCancelBooking, cancelBookingResponse }
}
