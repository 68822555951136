import {
  AngelBookingStatus,
  BookingServiceType,
  BookingStates,
  IBookingDetails,
  InvitationsStates,
  OfferStates,
  ServiceGroupType
} from '@charlycares/shared'
import { iconsMap } from '@charlycares/ui'

export const isSingleBooking = (booking: IBookingDetails) =>
  booking?.booking_dates.length === 1 && booking.booking_dates[0].repeat_days.length === 0

export const getAngelInvite = (booking: IBookingDetails) =>
  booking.invitations.find(invite =>
    [InvitationsStates.PENDING, InvitationsStates.PENDING_APPROVAL, InvitationsStates.PENDING_EDIT].includes(
      invite.current_state
    )
  )

export const getAngelOffer = (booking: IBookingDetails) =>
  booking.offers.find(offer => [OfferStates.PENDING, OfferStates.PENDING_EDIT].includes(offer.status))

export const getPendingOffers = (booking: IBookingDetails) =>
  booking.offers.filter(offer => [OfferStates.PENDING, OfferStates.PENDING_EDIT].includes(offer.status))

export const getArchivedInvitations = (bookings: IBookingDetails) =>
  bookings.invitations.filter(invite =>
    [
      InvitationsStates.DECLINED,
      InvitationsStates.DECLINED_BY_FAMILY,
      InvitationsStates.CANCELED,
      InvitationsStates.CANCELLED,
      InvitationsStates.ENDED,
      InvitationsStates.EXPIRED,
      InvitationsStates.GIVEN
    ].includes(invite.current_state)
  )

export const getArchivedOffers = (booking: IBookingDetails) =>
  booking.offers.filter(offer =>
    [OfferStates.DECLINED, OfferStates.CANCELLED, OfferStates.EXPIRED, OfferStates.GIVEN].includes(offer.status)
  )

export const isBookingArchived = (booking: IBookingDetails) =>
  [BookingStates.CANCELED, BookingStates.CANCELLED, BookingStates.DECLINED, BookingStates.ENDED].includes(
    booking.current_state
  )

export const isAngelBookingPending = (booking: IBookingDetails) => {
  return [
    AngelBookingStatus.PENDING,
    AngelBookingStatus.PENDING_EDIT,
    AngelBookingStatus.PENDING_INVITATION,
    AngelBookingStatus.PENDING_OFFER
  ].includes(booking.angel_status)
}

export const isAngelBookingArchived = (booking: IBookingDetails) =>
  [
    AngelBookingStatus.CANCELLED,
    AngelBookingStatus.DECLINED_BY_YOU,
    AngelBookingStatus.DECLINED,
    AngelBookingStatus.DECLINED_BY_FAMILY,
    AngelBookingStatus.GIVEN_TO_ANOTHER_ANGEL,
    AngelBookingStatus.EXPIRED,
    AngelBookingStatus.ENDED
  ].includes(booking.angel_status)

export const isBookingPending = (booking: IBookingDetails) =>
  [BookingStates.PENDING, BookingStates.PENDING_EDIT].includes(booking.current_state)

export const getInfoFromBookingServiceType = (
  serviceType?: BookingServiceType|ServiceGroupType
): { icon: keyof typeof iconsMap; icon_color: string; banner_color: string } => {
  switch (serviceType) {

    case ServiceGroupType.PET_CARE:
      return {
        icon: 'icn-walking-the-dog',
        icon_color: 'green',
        banner_color: 'green'
      }
    case ServiceGroupType.ELDERLY_CARE:
      return {
        icon: 'elderly-care',
        icon_color: 'warning',
        banner_color: 'warning'
      }
    case BookingServiceType.MEET_AND_GREET:
      return {
        icon: 'meet-and-greet',
        icon_color: 'secondary',
        banner_color: 'info'
      }
    case BookingServiceType.CHILD_CARE_SHORT_TERM:
      return {
        icon: 'icn-dining',
        icon_color: 'secondary',
        banner_color: 'secondary'
      }
    case BookingServiceType.SINGLE:
      return {
        icon: 'icn-dining',
        icon_color: 'secondary',
        banner_color: 'secondary'
      }
    case BookingServiceType.CHILD_CARE_LONG_TERM:
      return {
        icon: 'icn-calendar-sync',
        icon_color: 'secondary',
        banner_color: 'secondary'
      }
    case BookingServiceType.MULTI:
      return {
        icon: 'icn-calendar-sync',
        icon_color: 'secondary',
        banner_color: 'secondary'
      }
    case BookingServiceType.PET_CARE:
      return {
        icon: 'icn-walking-the-dog',
        icon_color: 'green',
        banner_color: 'green'
      }
    case BookingServiceType.ELDERLY_CARE:
      return {
        icon: 'elderly-care',
        icon_color: 'warning',
        banner_color: 'warning'
      }
    case BookingServiceType.TUTORING:
      return {
        icon: 'tutoring',
        icon_color: 'secondary',
        banner_color: 'white'
      }
    default:
      return {
        icon: 'icn-dining',
        icon_color: 'secondary',
        banner_color: 'white'
      }
  }
}
