import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const IdentityVerifiedIcon = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 28 28" fill="none" {...props}>
    <Path
      d="M14 0c1.969 0 3.719 1.148 4.594 2.844a5.2 5.2 0 015.304 1.258 5.2 5.2 0 011.258 5.304C26.852 10.281 28 12.031 28 14a5.222 5.222 0 01-2.844 4.648 5.108 5.108 0 01-1.258 5.25c-1.421 1.422-3.5 1.86-5.304 1.313A5.152 5.152 0 0114 28c-2.023 0-3.773-1.094-4.648-2.79a5.249 5.249 0 01-5.25-1.312 5.249 5.249 0 01-1.313-5.25C1.094 17.773 0 16.023 0 14a5.151 5.151 0 012.79-4.594c-.548-1.804-.11-3.883 1.312-5.304a5.108 5.108 0 015.25-1.258A5.222 5.222 0 0114 0z"
      fill="currentColor"
    />
    <Path
      d="M20.18 11.43a1.287 1.287 0 000-1.805c-.547-.547-1.367-.547-1.86 0l-6.07 6.07-2.57-2.57c-.547-.547-1.367-.547-1.86 0a1.198 1.198 0 000 1.805l3.5 3.5c.492.547 1.313.547 1.86 0l7-7z"
      fill="#0B5394"
    />
  </Svg>
)

export default IdentityVerifiedIcon
