import React, { useEffect, useState } from 'react'
import { Card, CharlyIcon } from '@charlycares/ui'
import { BookingDate, flattenBookingDates, isWeb, links, useRouter } from '@charlycares/shared'
import { useTranslation } from 'react-i18next'
import { Button, Heading, HStack, Pressable, Spinner, Text } from 'native-base'
import { BookingDates, useAlert } from '@charlycares/features'
import { useRecreateBookingMutation } from '@charlycares/data-access'
import { Linking } from 'react-native'

type Props = {
  navigation: any
  remainingBookingDates: BookingDate[]
}
export default function RecreateBookingFromRemainingDates({ navigation, remainingBookingDates }: Props) {
  const { navigate } = useRouter(navigation)
  const { t, i18n } = useTranslation()
  const alert = useAlert()

  const [selectedDates, setSelectedDates] = useState(flattenBookingDates(remainingBookingDates))

  const [recreateBooking, recreateState] = useRecreateBookingMutation()

  const recreateBookingError = recreateState.error as any
  const jobBoardLink = links.job_board[i18n.language.split('_')[0] as keyof typeof links.job_board] as string

  useEffect(() => {
    if (recreateState.isSuccess) {
      navigate('BookingsScreen', '/bookings')
    }
  }, [recreateState.isSuccess, navigate])

  useEffect(() => {
    if (recreateState.isError) {
      alert.show(
        t('error'),
        recreateBookingError?.status === 422
          ? recreateBookingError?.data?.message || recreateBookingError?.data?.error
          : t('requestError')
      )
    }
  }, [recreateState.isError, recreateBookingError, t])

  const recreateBookings = () => {
    if (selectedDates.length > 0) {
      recreateBooking({
        dates: selectedDates.map(date => ({
          obscured_id: date.obscured_id,
          starts_at: date.start_date,
          ends_at: date.end_date
        }))
      })
    }
  }

  if (!remainingBookingDates) return <Spinner />

  return (
    <Card mt="10px">
      <HStack space="10px" alignItems="center">
        <Heading color="primary.400" fontSize="18px" fontWeight={600}>
          {t('remainingBookingsTitle')}
        </Heading>
      </HStack>
      <Text mt="20px">{t('remainingBookingsDescription')}</Text>
      <BookingDates
        mt="0px"
        borderWidth="0px"
        px="0px"
        bookings={remainingBookingDates}
        selectedDates={selectedDates}
        onChange={setSelectedDates}
      />
      <Button
        h="40px"
        my="20px"
        flex={1}
        leftIcon={<CharlyIcon name="icn-post" color="white" size={24} />}
        isDisabled={selectedDates.length === 0}
        isLoading={recreateState.isLoading}
        isLoadingText={t('postNewJob')}
        onPress={recreateBookings}
      >
        {t('postNewJob')}
      </Button>
      <Pressable
        mb="20px"
        onPress={() => (isWeb ? Linking.openURL(jobBoardLink) : navigate('WebView', undefined, { uri: jobBoardLink }))}
      >
        <HStack alignItems="center">
          <Text color="blue.400" fontSize="14px" ml="auto" mr="4px">
            {t('readMoreAboutJobBoard')}
          </Text>
          <CharlyIcon name="icn-small-info" size={15} color="primary.400" />
        </HStack>
      </Pressable>
    </Card>
  )
}
