import {
  SearchBookingsDates,
  BookingTypes,
  getBookingPickerDatesRange,
  AngelSummery,
  languageCountryCodes
} from '@charlycares/shared'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import moment from 'moment'

import { SearchAngelFilters } from '../../api'

export interface BookingSlice {
  search: {
    bookingType: BookingTypes
    selectedDates: SearchBookingsDates[]
    selectedAngels: AngelSummery[]
    filters: SearchAngelFilters
    filtersCount: number
    sorts: { column: string; direction: 'asc' | 'desc' }[]
  }
}

const bookingRange = getBookingPickerDatesRange()

export const initialBookingState: BookingSlice = {
  search: {
    filtersCount: 0,
    bookingType: BookingTypes.SINGLE,
    selectedDates: [
      {
        startDate: bookingRange.minStartDate.format('YYYY-MM-DD HH:mm:ss'),
        endDate: bookingRange.minEndDate.format('YYYY-MM-DD HH:mm:ss')
      }
    ],
    selectedAngels: [],
    filters: {
      max_rate_per_hour: { amount: 0, currency: 'EUR' },
      max_distance: 20,
      age_range: { min: 15, max: 99 },
      languages: languageCountryCodes.map(lng => lng.country),
      skills: [],
      first_name: ''
    },
    sorts: []
  }
}

export const bookingSlice = createSlice({
  name: 'booking',
  initialState: initialBookingState,
  reducers: {
    addBookingDay: state => {
      const start = moment(state.search.selectedDates[state.search.selectedDates.length - 1].startDate).add(1, 'day')
      const range = getBookingPickerDatesRange(start)
      state.search.selectedDates.push({
        startDate: start.format('YYYY-MM-DD HH:mm:ss'),
        endDate: range.minEndDate.format('YYYY-MM-DD HH:mm:ss')
      })
    },
    removeBookingDay: (state, action: PayloadAction<number>) => {
      state.search.selectedDates.splice(action.payload, 1)
    },
    updateBookingDates: (state, action: PayloadAction<{ index: number; dates: SearchBookingsDates }>) => {
      state.search.selectedDates[action.payload.index] = action.payload.dates
    },
    changeBookingType: (state, action: PayloadAction<BookingTypes>) => {
      state.search.bookingType = action.payload
      if (action.payload === BookingTypes.SINGLE) {
        state.search.selectedDates = [{ ...state.search.selectedDates[0], repeatDates: undefined }]
      }
    },
    resetBookingDates: state => {
      const { minStartDate, minEndDate } = getBookingPickerDatesRange()

      state.search.selectedDates = [
        {
          startDate: minStartDate.format('YYYY-MM-DD HH:mm:ss'),
          endDate: minEndDate.format('YYYY-MM-DD HH:mm:ss')
        }
      ]
    },
    setSelectedAngels: (state, action: PayloadAction<AngelSummery[]>) => {
      state.search.selectedAngels = action.payload
    },
    toggleSelectedAngels: (state, action: PayloadAction<AngelSummery>) => {
      const angel = action.payload
      const index = state.search.selectedAngels.findIndex(a => a.id === angel.id)
      if (index === -1) {
        state.search.selectedAngels.push(angel)
      } else {
        state.search.selectedAngels.splice(index, 1)
      }
    },
    setBookingSearchFilters: (state, action: PayloadAction<SearchAngelFilters>) => {
      state.search.filters = action.payload
    },
    setBookingSearchName: (state, action: PayloadAction<string>) => {
      state.search.filters.first_name = action.payload
    },
    setBookingSearchSkills: (state, action: PayloadAction<string[]>) => {
      state.search.filters.skills = action.payload
    },
    setBookingSearchSort: (state, action: PayloadAction<string>) => {
      state.search.sorts[0] = {
        column: action.payload,
        direction: 'asc'
      }

      return state
    },
    countBookingFilters: state => {
      state.search.filtersCount = 0
      const currentFilters = state.search.filters

      if (!_.isEqual(currentFilters.age_range, initialBookingState.search.filters.age_range)) {
        state.search.filtersCount++
      }

      if (!_.isEqual(currentFilters.max_rate_per_hour, initialBookingState.search.filters.max_rate_per_hour)) {
        state.search.filtersCount++
      }

      if (!_.isEqual(currentFilters.languages, initialBookingState.search.filters.languages)) {
        state.search.filtersCount++
      }

      if (!_.isEqual(currentFilters.skills, initialBookingState.search.filters.skills)) {
        state.search.filtersCount++
      }

      if (currentFilters.first_name !== initialBookingState.search.filters.first_name) {
        state.search.filtersCount++
      }

      if (currentFilters.max_distance !== initialBookingState.search.filters.max_distance) {
        state.search.filtersCount++
      }

      return state
    }
  }
})

export const {
  addBookingDay,
  updateBookingDates,
  changeBookingType,
  removeBookingDay,
  resetBookingDates,
  toggleSelectedAngels,
  setSelectedAngels,
  setBookingSearchFilters,
  setBookingSearchName,
  setBookingSearchSort,
  setBookingSearchSkills,
  countBookingFilters
} = bookingSlice.actions

export const bookingReducer = bookingSlice.reducer
