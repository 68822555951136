import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components/native';

const Container = styled.View`
  padding: 10px;
`;

const Loader = () => (
  <Container>
    <ContentLoader height={130} width={400} speed={2} color="#f3f3f3">
      <rect x="10" y="25.05" rx="0" ry="0" width="50" height="20" />

      <rect x="80" y="25.05" rx="0" ry="0" width="50" height="20" />

      <rect x="150" y="25.05" rx="0" ry="0" width="50" height="20" />

      <rect x="220" y="25.05" rx="0" ry="0" width="50" height="20" />

      <rect x="290" y="25.05" rx="0" ry="0" width="50" height="20" />

      <rect x="10" y="85.05" rx="0" ry="0" width="260" height="15" />
    </ContentLoader>

    {[...Array(8)].map((_, i) => (
      <ContentLoader
        height={60}
        width={400}
        speed={2}
        color="#f3f3f3"
        key={i.toString()}
      >
        <rect x="30" y="5.05" rx="0" ry="0" width="40" height="15" />

        <rect x="50" y="5.05" rx="0" ry="0" width="260" height="15" />
      </ContentLoader>
    ))}
  </Container>
);
export default Loader;
