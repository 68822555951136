import React from 'react'
import { Platform } from 'react-native'
import { SvgUri, CharlyIcon, Ideal, Scheme } from '@charlycares/ui'
import { useGetPaymentsMethodsQuery } from '@charlycares/data-access'
import { MEMBERSHIP_TYPES, PaymentMethod } from '@charlycares/shared'
import { Box, HStack, Pressable, Skeleton, Spacer, Text } from 'native-base'

type MembershipPaymentMethodsProps = {
  onPress: (methodId: string) => void
  membershipType: MEMBERSHIP_TYPES
  isLoading: boolean
}

export default function MembershipPaymentMethods({
  onPress,
  membershipType,
  isLoading
}: MembershipPaymentMethodsProps) {
  const { data: paymentMethods, isLoading: paymentMethodsLoading } = useGetPaymentsMethodsQuery({
    channel: Platform.OS,
    membership_type: membershipType
  })

  const getPaymentMethod = (paymentMethod: PaymentMethod) => {
    switch (paymentMethod.id) {
      case 'ideal':
        return <Ideal mt="15px" isLoading={isLoading} onPress={() => onPress(paymentMethod.id)} />
      case 'scheme':
        return <Scheme mt="15px" isLoading={isLoading} onPress={() => onPress(paymentMethod.id)} />
      default:
        return (
          <Pressable mt="15px" onPress={() => onPress(paymentMethod.id)}>
            <Box rounded="md" bgColor="white" width="100%" px="15px" py="12px" shadow="3">
              <HStack alignItems="center">
                <SvgUri uri={paymentMethod.image} alt={paymentMethod.name} size={35} />
                <Text lineHeight="30px" ml="8px" fontSize="16px" color="text.dark.400" fontWeight="600">
                  {paymentMethod.name}
                </Text>
                <Spacer />
                <CharlyIcon name="icn-caret-right" size="24px" color="gray.700" />
              </HStack>
            </Box>
          </Pressable>
        )
    }
  }

  return (
    <Box px="20px">
      {!paymentMethodsLoading &&
        paymentMethods?.data.map(paymentMethod => {
          return getPaymentMethod(paymentMethod)
        })}

      {paymentMethodsLoading &&
        Array.from({ length: 2 }).map(index => <Skeleton mt="15px" h="50" flex="2" rounded="md" />)}
    </Box>
  )
}
