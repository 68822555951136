import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { resetUpdateProfile } from '../../../stores/actions/userAction';
import { Text, Divider } from '../../../elements';
import {
  TextView,
  TextButton,
  IconButton,
  TextTextView,
  TextIconView,
  Header
} from '../../../components';
import { isWeb, getFormatedDate } from '../../../utils/functions';
import colors from '../../../styles/colors';

import kidsIcon from '../../../../assets/icons/icn-kids.png';
import leftIcon from '../../../../assets/icons/icn-nav-left.png';

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  overflow: hidden;
`;
const ScrollView = styled.ScrollView``;
const View = styled.View`
  margin-top: 15px;
  flex-direction: row;
  align-items: center;
`;

const ProfileParentFamilySettings = ({ history, navigation, user, resetUpdateProfileData }) => {
  const { family, languages } = user;
  const { cat, dog, kids } = family;
  const { dutch, english, french, german, italian, spanish } = languages;
  const [t] = useTranslation();
  const languagesArray = [
    dutch ? t('dutchLanguage') : null,
    english ? t('englishLanguage') : null,
    german ? t('germanLanguage') : null,
    french ? t('frenchLanguage') : null,
    spanish ? t('spanishLanguage') : null,
    italian ? t('italianLanguage') : null
  ];
  const familyLanguages = languagesArray.filter((lan) => lan);

  if (navigation) {
    navigation.setOption({
      headerTitle: (
        <Text fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('profileScreensFamilySettings')}
        </Text>
      ),
      headerRight: (
        <TextButton
          text={t('edit')}
          textColor={colors.secondary}
          onPress={() => navigation.navigate('ProfileEditFamilySettings')}
        />
      )
    });
  }

  useEffect(() => {
    if (user) {
      resetUpdateProfileData();
    }
  }, [user]);

  return (
    <SafeAreaView>
      {isWeb && (
        <Header
          left={
            <IconButton
              iconSource={leftIcon}
              iconSize="32px"
              iconColor={colors.secondary}
              onPress={history.goBack}
            />
          }
          title={t('profileScreensFamilySettings')}
          right={
            <TextButton
              text={t('edit')}
              textColor={colors.secondary}
              onPress={() => history.push('/profile-settings-edit')}
            />
          }
        />
      )}

      <ScrollView>
        <Divider />

        <TextView
          flexDirection="column"
          alignItems="flex-start"
          paddings="20px"
          borderBottomWidth="1px"
          text={t('children').toUpperCase()}
          fontSize="12px"
          fontWeight="300"
          textColor={colors.grey02}>
          {kids.map(({ id, birthdate }) => (
            <TextIconView
              key={id.toString()}
              flexDirection="row-reverse"
              justifyContent="flex-start"
              margins="15px 0px 0px 0px"
              backgroundColor="transparent"
              icon={kidsIcon}
              iconMargins="0px 10px 0px 0px"
              iconSize="32px"
              iconColor={colors.primaryText}
              text={getFormatedDate(birthdate, 'LL')}
              textAlign="auto"
            />
          ))}
        </TextView>

        <TextTextView
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          paddings="20px"
          borderBottomWidth="1px"
          firstText={t('languages').toUpperCase()}
          firstTextFontSize="12px"
          firstTextFontWeight="300"
          firstTextColor={colors.grey02}
          secondText={familyLanguages.join(', ')}
          secondTextMargins="15px 0px 0px 0px"
          secondTextFontSize="16px"
          secondTextColor={colors.primaryText}
          secondTextAlign="left"
        />

        <TextView
          flexDirection="column"
          alignItems="flex-start"
          paddings="20px"
          borderBottomWidth="1px"
          text={t('pets').toUpperCase()}
          fontSize="12px"
          fontWeight="300"
          textColor={colors.grey02}>
          <View>
            <TextIconView
              flexDirection="row-reverse"
              justifyContent="flex-start"
              margins="0px 50px 0px 0px"
              backgroundColor="transparent"
              name="dog"
              iconMargins="0px 10px 0px 0px"
              iconSize="32px"
              iconColor={colors.primaryText}
              text={dog ? t('yes') : t('No')}
              textAlign="auto"
            />

            <TextIconView
              flexDirection="row-reverse"
              justifyContent="flex-start"
              margins="0px 0px 0px 0px"
              backgroundColor="transparent"
              name="cat"
              iconMargins="0px 10px 0px 0px"
              iconSize="32px"
              iconColor={colors.primaryText}
              text={cat ? t('yes') : t('No')}
              textAlign="auto"
            />
          </View>
        </TextView>
      </ScrollView>
    </SafeAreaView>
  );
};

ProfileParentFamilySettings.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func, goBack: PropTypes.func }),
  navigation: PropTypes.shape({ setOption: PropTypes.func, navigate: PropTypes.func }),
  user: PropTypes.shape({
    family: PropTypes.shape({
      cat: PropTypes.number,
      dog: PropTypes.number,
      kids: PropTypes.arrayOf(PropTypes.string)
    }),
    languages: PropTypes.shape({
      dutch: PropTypes.number,
      english: PropTypes.number,
      french: PropTypes.number,
      german: PropTypes.number,
      italian: PropTypes.number,
      spanish: PropTypes.number
    })
  }).isRequired,
  resetUpdateProfileData: PropTypes.func.isRequired
};

ProfileParentFamilySettings.defaultProps = {
  history: null,
  navigation: null
};

const mapStateToProps = ({ User }) => ({
  user: User.user
});

const mapDispatchToProps = {
  resetUpdateProfileData: resetUpdateProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileParentFamilySettings);
