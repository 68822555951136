import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import Autolink from 'react-native-autolink';
import { Text } from '../../elements';
import colors from '../../styles/colors';
import { IcnNavAdd } from '../Icons';

const Container = styled.TouchableOpacity`
  flex-direction: row;
  padding: 10px;
`;
const TextContainer = styled.View`
  flex-direction: column;
  margin-left: 10px;
`;

const Item = ({ item }) => {
  const [expand, setExpand] = useState(false);

  return (
    <Container onPress={() => setExpand(!expand)}>
      <IcnNavAdd color={colors.secondary} />

      <TextContainer>
        <Text>{item.title}</Text>

        {expand && (
          <Text margins="5px 0px 0px 0px" color={colors.grey02}>
            <Autolink text={item.body} />
          </Text>
        )}
      </TextContainer>
    </Container>
  );
};

Item.propTypes = {
  item: PropTypes.shape({ title: PropTypes.string, body: PropTypes.string }).isRequired
};

export default Item;
