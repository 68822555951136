import React from 'react'
import { Card, ScreenLayout } from '@charlycares/ui'
import { IOffer, useRouter } from '@charlycares/shared'
import { useTranslation } from 'react-i18next'
import { Button, Heading, Text } from 'native-base'
import { InsuranceCard } from '../common'
import RecreateBookingFromRemainingDates from './common/RecreateBookingFromRemainingDates'

const OfferAcceptedScreen = ({ navigation }: any) => {
  const { getParam, popToTop } = useRouter(navigation)
  const { t } = useTranslation()

  const offer = getParam('offer') as IOffer | undefined

  return (
    <ScreenLayout
      hideBackButton
      title={t('success')}
      headerRight={
        <Button
          onPress={() => {
            popToTop()
          }}
          variant="text"
        >
          {t('done')}
        </Button>
      }
    >
      <Card>
        <Heading fontSize="18px">{t('angelComingToBabysitTitle', { angel: offer?.angel.first_name })}</Heading>
        <Text my="15px">{t('angelComingToBabysitDesc', { angel: offer?.angel.first_name })}</Text>
      </Card>

      {offer?.remaining_booking_dates && offer?.remaining_booking_dates.length > 0 ? (
        <RecreateBookingFromRemainingDates
          navigation={navigation}
          remainingBookingDates={offer.remaining_booking_dates}
        />
      ) : (
        <InsuranceCard />
      )}
    </ScreenLayout>
  )
}

export default OfferAcceptedScreen

OfferAcceptedScreen.navigationOptions = {
  headerShown: false
}
