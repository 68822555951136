import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components/native';

const Container = styled.View`
  padding: 10px;
`;

const Loader = () => (
  <Container>
    <ContentLoader height={100} width={400} speed={2} color="#f3f3f3">
      <rect x="303" y="45.05" rx="0" ry="0" width="14" height="1" />

      <rect x="10" y="9.05" rx="0" ry="0" width="115.2" height="18.96" />

      <rect x="10" y="50.22" rx="0" ry="0" width="40" height="40" />

      <rect
        x="60"
        y="50.22"
        rx="0"
        ry="0"
        width="150.66"
        height="8.879999999999999"
      />

      <rect
        x="60"
        y="63.22"
        rx="0"
        ry="0"
        width="150.66"
        height="8.879999999999999"
      />

      <rect x="242" y="12.05" rx="0" ry="0" width="93" height="16.96" />

      <rect x="272" y="55.05" rx="0" ry="0" width="61" height="10" />
    </ContentLoader>

    {[...Array(5)].map((_, i) => (
      <ContentLoader
        height={100}
        width={400}
        speed={2}
        color="#f3f3f3"
        key={i.toString()}
      >
        <rect x="303" y="45.05" rx="0" ry="0" width="14" height="1" />

        <rect x="0" y="137.01" rx="0" ry="0" width="401.88" height="2.04" />

        <circle cx="40" cy="60" r="35" />

        <rect x="90" y="29.05" rx="0" ry="0" width="115.2" height="18.96" />

        <rect
          x="90"
          y="76.22"
          rx="0"
          ry="0"
          width="150.66"
          height="8.879999999999999"
        />

        <rect x="252" y="32.05" rx="0" ry="0" width="93" height="16.96" />

        <rect x="282" y="75.05" rx="0" ry="0" width="61" height="10" />
      </ContentLoader>
    ))}
  </Container>
);
export default Loader;
