import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { connect } from 'react-redux';

import { TextView } from './Texts';
import Icon from './Icons/Icon';
import colors from '../styles/colors';
import theme from '../styles/theme';

const View = styled.View``;

const AbsoluteWrapper = styled.View`
  position: ${({ position }) => position || 'absolute'};
  top: ${({ top }) => top || 0};
  ${({ left }) => (left ? { left } : { left: -5 })};
  align-items: center;
  justify-content: space-between;
  ${({ flexDirection }) =>
    flexDirection && { 'flex-direction': flexDirection }};
  width: 50;
  ${({ size }) => size && { width: size, height: size, borderRadius: size }};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'transparent'};
  z-index: 99;
`;

const TabIndicator = ({ indicators, tab, children }) => {
  let activeTimer;
  let indicatorNumber;

  if (indicators) {
    activeTimer = indicators.active_timer;
    if (tab === 'Chat') {
      indicatorNumber = indicators.new_messages;
    } else if (tab === 'Bookings') {
      indicatorNumber = indicators.has_pending_bookings;
    } else if (tab === 'JobFinder') {
      indicatorNumber = indicators.has_pending_jobs;
    } else if (tab === 'Profile') {
      indicatorNumber = indicators.profile_todo;
    }
  }

  return (
    <View>
      <AbsoluteWrapper flexDirection={!indicatorNumber ? 'row' : 'row-reverse'}>
        {!!indicatorNumber && (
          <TextView
            justifyContent="center"
            width={16}
            height={16}
            borderRadius={15}
            backgroundColor={theme.colors.notify}
            text={indicatorNumber.toString()}
            fontSize={11}
            fontWeight="600"
            textColor={colors.white}
            margins="3px 0px 0px 0px"
          />
        )}

        {tab === 'Chat' && activeTimer && (
          <View
            position="relative"
            top={0}
            size={15}
            backgroundColor={colors.white}
          >
            <Icon size={15} name="icn-time" color={colors.blue} />
          </View>
        )}
      </AbsoluteWrapper>

      {children}
    </View>
  );
};

TabIndicator.propTypes = {
  tab: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  indicators: PropTypes.object,
  children: PropTypes.element.isRequired,
};

TabIndicator.defaultProps = {
  indicators: null,
};

function mapStateToProps(state) {
  return {
    indicators: state.User.indicators,
  };
}

export default connect(mapStateToProps, null)(TabIndicator);
