/* eslint-disable import/prefer-default-export */
import axiosInstance from '../../utils/axios';
import { GET_SUPPORT, FETCHING_SUPPORT, SERVER_ERROR } from '../types';

const logError = (error, dispatch) => {
  if (error.response && error.response.status === 500) {
    dispatch({ type: SERVER_ERROR });
  } else {
    console.log({ error });
  }
};

export const getSupport = () => (dispatch) => {
  dispatch({ type: FETCHING_SUPPORT });
  axiosInstance
    .get('faq')
    .then((response) => {
      if (response.data) {
        dispatch({
          type: GET_SUPPORT,
          payload: response.data.data
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};
