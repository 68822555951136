import React from 'react'
import { Box, IBoxProps } from 'native-base'

type Props = IBoxProps & {
  lat: number
  lon: number
}

const Map = ({ lon, lat, ...boxProps }: Props) => {
  return <Box mt="10px" w="100%" h="165px" {...boxProps}></Box>
}

export default Map
