import React, { useRef, useState } from 'react'
import { Flex, Heading, IconButton, Pressable, Spacer, Text } from 'native-base'
import { Card, CharlyIcon, DatePicker, DateTimePicker } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { useCreateChildMutation, useDeleteChildMutation, useGetChildrenQuery } from '@charlycares/data-access'
import moment from 'moment'
import { Moment } from 'moment/moment'

const Children = () => {
  const { t } = useTranslation()
  const { data: children } = useGetChildrenQuery()
  const [createChild] = useCreateChildMutation()
  const [deleteChild] = useDeleteChildMutation()
  const datePicker = useRef<DateTimePicker | null>(null)
  const [openDatePicker, setOpenDatePicker] = useState(false)

  return (
    <Card mt="10px">
      <Heading mb="20px" fontSize={'18px'} fontWeight={600}>
        {t('children.header')}
      </Heading>
      <Text fontSize="15px">{t('children.description')}</Text>
      {children?.map(({ date_of_birth, id }) => (
        <Flex direction="row" mt="24px" key={id}>
          <IconButton
            rounded="full"
            size={28}
            bgColor="white"
            icon={<CharlyIcon color="primary.400" size={28} name="icn-cancel" />}
            onPress={() => deleteChild({ id })}
          />
          <CharlyIcon name="icn-kids" size={28} px="5px" />
          <Text pl="8px" fontSize="md" fontWeight="400" paddingRight="40px">
            {moment(date_of_birth).format('LL')}
          </Text>
        </Flex>
      ))}
      <Pressable onPress={() => setOpenDatePicker(true)}>
        <Flex direction="row" mt="30px">
          <IconButton rounded="full" size={28} icon={<CharlyIcon color="gray.400" size={28} name="btn-plus" />} />
          <Text pl="8px" fontSize="md" color="primary.400" fontWeight="400" paddingRight="40px">
            {t('children.add')}

            <DatePicker
              ref={datePicker}
              dateFormat="YYYY"
              minDate={moment().subtract(18, 'years')}
              maxDate={moment().add(1, 'years')}
              onChange={(value: Moment) => {
                setTimeout(() => setOpenDatePicker(false), 500)
                createChild({ date_of_birth: value })
              }}
              hidden
              open={openDatePicker}
            />
          </Text>
        </Flex>
      </Pressable>
      <Flex direction="row" mt="30px">
        <Text fontSize="16px">{t('children.pregnant')}</Text>
        <Spacer />
        <Pressable onPress={() => setOpenDatePicker(true)}>
          <Flex direction="row">
            <Text pl="8px" fontSize="md" color="secondary.500" fontWeight="400">
              {t('children.pregnant.date')}
            </Text>
            <IconButton
              rounded="full"
              size={28}
              icon={<CharlyIcon color="gray.400" size={28} name="icn-caret-right" />}
            />
          </Flex>
        </Pressable>
      </Flex>
    </Card>
  )
}

export default Children
