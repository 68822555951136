import { GetJobsArgs, useGetJobsQuery } from '@charlycares/data-access'
import { useEffect, useState } from 'react'
import { contactPaginatedData, IJobPost } from '@charlycares/shared'

export default function useJobboardData(body: GetJobsArgs, skip?: boolean) {
  const [data, setData] = useState<IJobPost[]>([])
  const result = useGetJobsQuery(body, { skip })

  useEffect(() => {
    if (result.isSuccess && !result.isFetching) {
      setData(state => contactPaginatedData(state, result.data))
    }
  }, [result])

  return { ...result, meta: result.data?.meta, data }
}
