import React from 'react'
import { Card, CharlyIcon, ScreenLayout } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { Box, Button, Heading, HStack, Text, VStack } from 'native-base'
import { useRouter } from '@charlycares/shared'
import { Linking } from 'react-native'

const VerificationErrorScreen = () => {
  const { t } = useTranslation()
  const { navigate } = useRouter()

  return (
    <ScreenLayout hideBackButton title={t('profileVerifiedError')}>
      <Card>
        <HStack space="20px" alignItems="center">
          <CharlyIcon size={20} color="alert.danger.400" name="close" />
          <Text flex={1} fontSize={15}>
            {t('profileVerifiedErrorDesc')}
          </Text>
        </HStack>
      </Card>

      <Card>
        <Heading fontSize={18}>{t('whatNowErrorTitle')}</Heading>
        <Text mt="10px" fontSize={15}>
          {t('whatNowErrorDesc')}
        </Text>

        <Box mt="50px">
          <Heading mt="10px" fontSize={15}>
            {t('reachOut')}
          </Heading>
          <VStack space="5px" mt="5px">
            <HStack space="40px">
              <Text>{t('phone')}</Text>
              <Text
                fontWeight={600}
                onPress={() => Linking.openURL('tel:+31202102323')}
                color="alert.info.400"
                underline
              >
                +31202102323
              </Text>
            </HStack>
            <HStack space="40px">
              <Text>{t('whatsapp')}</Text>
              <Text
                onPress={() => Linking.openURL('whatsapp://send?phone=+31202102323')}
                fontWeight={600}
                color="alert.info.400"
                underline
              >
                +31202102323
              </Text>
            </HStack>
          </VStack>
        </Box>

        <Button onPress={() => navigate('Dashboard', '/dashboard')} variant="outline" mt="35px">
          {t('continueToTheApp')}
        </Button>
      </Card>
    </ScreenLayout>
  )
}

export default VerificationErrorScreen

VerificationErrorScreen.navigationOptions = {
  headerShown: false
}
