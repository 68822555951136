import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Text } from '../../elements';
import { TextView } from '../Texts';
import { TextIconButton } from '../Buttons';
import colors from '../../styles/colors';

const ProfileLanguagesEdit = ({ languages, valid, onToggleLanguage }) => {
  const [t] = useTranslation();

  return (
    <TextView
      flexDirection="column"
      alignItems="flex-start"
      paddings={`0px 10px 0px ${valid ? 10 : 5}px`}
      margins="20px 10px 20px 10px"
      borderLeftWidth={valid ? 0 : '5px'}
      borderColor={colors.secondary}
      text={t('languages').toUpperCase()}
      fontSize="12px"
      fontWeight="300"
      textColor={colors.grey02}>
      <Text margins="10px 0px 10px 0px" fontWeight="300">
        {t('profileScreensIndicateSpokenLanguages')}
      </Text>

      {Object.keys(languages).map((language) => {
        const isSelected = languages[`${language}`];

        return (
          <TextIconButton
            key={language}
            flexDirection="row"
            justifyContent="space-between"
            paddings="0px"
            margins="10px 0px 10px 0px"
            width="100%"
            text={t(`${language}Language`)}
            textColor={isSelected ? colors.primaryText : colors.grey02}
            iconName={isSelected ? 'btn-check' : 'btn-plus'}
            iconSize="32px"
            iconColor={isSelected ? colors.primary : colors.defaultBtnBackgroundColor}
            onPress={() => onToggleLanguage(language)}
          />
        );
      })}
    </TextView>
  );
};

ProfileLanguagesEdit.propTypes = {
  languages: PropTypes.shape({}).isRequired,
  valid: PropTypes.bool.isRequired,
  onToggleLanguage: PropTypes.func.isRequired
};

export default ProfileLanguagesEdit;
