import React, { useState, useEffect, lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import {
  updateProfile,
  updateProfileImage,
  setHaveALookAround
} from '../../../stores/actions/userAction'
import { Text } from '../../../elements'
import {
  TextView,
  TextTextView,
  TextButton,
  LabeledInput,
  PetsEdit,
  Header,
  ProfileAvatar
} from '../../../components'
import colors from '../../../styles/colors'
import { ActivityIndicator } from 'react-native'
import ImageUploader from 'react-images-upload'

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  justify-content: space-between;
`
const ScrollView = styled.ScrollView`
  flex: 1;
`

const SignUpParentStep3 = ({
  history,
  navigation,
  user,
  uploadingProfileImage,
  updateProfileImageData,
  updateProfileData,
  setHaveALookAroundData
}) => {
  const [shortBio, setShortBio] = useState('')
  const [dog, setDog] = useState(false)
  const [cat, setCat] = useState(false)
  const [image, setImage] = useState(null)
  const [t] = useTranslation()
  const buttonDisabled = !shortBio || !image || uploadingProfileImage

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize={18} fontWeight="600">
          {t('signUpParentScreensIntroduceYourself')}
        </Text>
      )
    })
  }

  const uploadImage = () => {
    const formData = new FormData()
    formData.append('file', image.file)

    updateProfileImageData(formData)
  }

  useEffect(() => {
    if (user.image) {
      if (!user.family.short_bio) {
        updateProfileData({ short_bio: shortBio, dog, cat })
      } else {
        setHaveALookAroundData(true)
        if (navigation) {
          navigation.navigate('DiscoverScreen')
        } else {
          history.push('/discover')
        }
      }
    }
  }, [user])

  return (
    <SafeAreaView>
      {!navigation && <Header title={t('signUpParentScreensIntroduceYourself')} />}

      <ScrollView>
        <TextTextView
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          margins="20px"
          paddings="0px 0px 20px 0px"
          borderBottomWidth="1px"
          firstText={t('signUpParentScreensMoreAboutYourFamily')}
          firstTextFontFamily="Martel"
          firstTextFontSize="18px"
          fontWeight="600"
          secondText={t('signUpParentScreensMoreAboutYourFamilyDescription')}
          secondTextMargins="5px 0px 0px 0px"
          secondTextFontSize="15px"
          secondTextFontWeight="300"
          secondTextAlign="left"
          autoCapitalize="sentences"
        />

        <TextView
          flexDirection="column"
          alignItems="center"
          margins="0px 20px 0px 20px"
          borderBottomWidth="1px"
          text={t('signUpParentScreensAddFamilyPhoto')}
          fontFamily="Martel"
          fontSize="16px"
          fontWeight="600"
        >
          <ProfileAvatar
            alignSelf="center"
            margins="10px 0px 10px 0px"
            backgroundColor="transparent"
            borderRadius={0}
            imageWidth="300px"
            imageHeight="200px"
            source={(image && image.uri) || null}
            isAngel={false}
          >
            <Suspense fallback={<ActivityIndicator />}>
              <ImageUploader
                singleImages
                withIcon={false}
                withLabel={false}
                fileContainerStyle={{
                  margin: '0px',
                  padding: '0px',
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  top: '-15px'
                }}
                buttonStyles={{
                  margin: '0px'
                }}
                buttonText={t('chatScreensChooseImage')}
                onChange={files => setImage({ file: files[0], uri: URL.createObjectURL(files[0]) })}
                imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                maxFileSize={5242880}
              />
            </Suspense>
          </ProfileAvatar>
        </TextView>

        <LabeledInput
          margins="20px 20px 0px 20px"
          borderBottomWidth="1px"
          labelText={t('signUpParentScreensDescribeFamily')}
          labelColor={colors.primaryText}
          fontFamily="Martel"
          fontSize="16px"
          fontWeight="600"
          inputMargins="5px 0px 20px 0px"
          inputPaddings="0px 0px 40px 5px"
          multiline
          showUnderline={false}
          returnKeyType="default"
          placeholder={t('signUpParentScreensDescribeFamilyDescription')}
          value={shortBio}
          onChangeText={setShortBio}
          textAlignVertical="top"
          autoCapitalize="sentences"
        />

        <PetsEdit
          borderBottomWidth={0}
          borderTopWidth={0}
          title={t('signUpParentScreensPets')}
          titleFontFamily="Martel"
          titleFontSize="16px"
          titleFontWeight="600"
          titleColor={colors.primaryText}
          secondIconButtonMargins="0px"
          dog={dog}
          cat={cat}
          toggleDogPress={() => setDog(!dog)}
          toggleCatPress={() => setCat(!cat)}
        />
      </ScrollView>

      <TextButton
        disabled={buttonDisabled}
        margins="20px 20px 75px 20px"
        backgroundColor={buttonDisabled ? colors.defaultBtnBackgroundColor : colors.primary}
        text={t('signUpParentScreensCompleteProfile')}
        onPress={uploadImage}
      />
    </SafeAreaView>
  )
}

SignUpParentStep3.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }),
  navigation: PropTypes.shape({ navigate: PropTypes.func, setOptions: PropTypes.func }),
  updateProfileData: PropTypes.func.isRequired,
  updateProfileImageData: PropTypes.func.isRequired,
  user: PropTypes.shape({
    family: PropTypes.shape({ short_bio: PropTypes.string }),
    image: PropTypes.string
  }).isRequired,
  uploadingProfileImage: PropTypes.bool,
  setHaveALookAroundData: PropTypes.func.isRequired
}

SignUpParentStep3.defaultProps = {
  history: null,
  navigation: null,
  uploadingProfileImage: false
}

const mapStateToProps = ({ User }) => ({
  user: User.user
})

const mapDispatchToProps = {
  updateProfileData: updateProfile,
  updateProfileImageData: updateProfileImage,
  setHaveALookAroundData: setHaveALookAround
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpParentStep3)
