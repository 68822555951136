import React from 'react';
import { useTranslation } from "react-i18next";
import { Flex, Heading, Text } from "native-base";
import { Card, MultiSelectList } from "@charlycares/ui";
import {
  useGetLanguagesQuery,
  useUpdateLanguagesMutation
} from "@charlycares/data-access";
import { UserLanguages } from "@charlycares/shared";

enum Language {
  Dutch = 'dutch',
  English = 'english',
  French = 'french',
  German = 'german',
  Italian = 'italian',
  Spanish = 'spanish'
}

const Languages = () => {
  const {t} = useTranslation()
  const {data: selectedLanguages, isSuccess} = useGetLanguagesQuery()
  const [updateLanguage] = useUpdateLanguagesMutation()
  const availableLanguages = [
    {value: Language.Dutch, label: t('dutchLanguage')},
    {value: Language.English, label: t('englishLanguage')},
    {value: Language.French, label: t('frenchLanguage')},
    {value: Language.German, label: t('germanLanguage')},
    {value: Language.Italian, label: t('italianLanguage')},
    {value: Language.Spanish, label: t('spanishLanguage')}
  ];

  return (
    <Card mt="10px">
      <Heading mb="20px" fontSize={ '18px' } fontWeight={ 600 }>
        { t('languages.header') }
      </Heading>
      <Text fontSize="15px">
        { t('languages.description') }
      </Text>
      <Flex direction="row" mt="30px">
        <Text fontSize="16px">
          { t('languages.language') }
        </Text>
      </Flex>

      {isSuccess && (
        <MultiSelectList
          values={availableLanguages}
          selected={selectedLanguages}
          onChange={(languages) => updateLanguage((languages as unknown) as UserLanguages)}
        />
      )}
    </Card>
  );
};

export default Languages;
