import React from 'react'
import { Box, Flex } from 'native-base'

type Props = {
  progress?: string
  barColor?: string
  barHeight?: string
}

const ProgressBar = ({ progress, barColor , barHeight }: Props) => {
  return (
    <Flex px="2px" h={barHeight || 10} w="100%" justifyContent="center">
      <Box w={progress} h={ barHeight || "70%"} bg={barColor || 'primary.400'} borderRadius={'10px'} />
    </Flex>
  )
}

ProgressBar.defaultProps = {
  progress: 0
}

export default ProgressBar
