import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getAgendas } from '../../../stores/actions/angelAppointmentAction'
import { Text, Divider } from '../../../elements'
import {
  TextView,
  TextIconView,
  TextTextView,
  IconButton,
  TextIconButton,
  Alert,
  Header
} from '../../../components'
import { renderDistanceInKilometers } from '../../../utils/functions'
import colors from '../../../styles/colors'

import arrowDownIcon from '../../../../assets/icons/icn-nav-down.png'
import arrowUpIcon from '../../../../assets/icons/icn-nav-up.png'
import arrowRightIcon from '../../../../assets/icons/icn-nav-right.png'
import giftIcon from '../../../../assets/icons/icn-gift.png'
import locationIcon from '../../../../assets/icons/icn-location.png'

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  overflow: hidden;
`
const ScrollView = styled.ScrollView`
  flex: 1;
`
const View = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 0px;
  border-bottom-width: 1px;
  border-color: ${colors.grey08};
`

const MakeAppointment = ({
  location,
  history,
  route,
  navigation,
  agendas,
  user,
  getAgendasData
}) => {
  const { edit } = route.props || location.state || {}
  const { onboardingProgress } = user.angel
  const [showMeetingSteps, setShowMeetingSteps] = useState(false)
  const [appointmentLocation, setAppointmentLocation] = useState({})
  const [t] = useTranslation()
  const alertRef = useRef(null)

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize={16} fontWeight="600">
          {t('signUpAngelScreensMakeAnApointment')}
        </Text>
      ),
      headerLeft: null
    })
  }

  const onLocationPress = agenda => {
    setAppointmentLocation(agenda)
    alertRef.current.setIsVisible(true)
  }

  const goNextStep = () => {
    if (navigation) {
      navigation.navigate('SignUpAngelStep4', {
        appointmentLocation,
        progress: onboardingProgress || 55
      })
    } else {
      history.push({
        pathname: '/signup-angel-appointment-schedule',
        state: {
          appointmentLocation,
          progress: onboardingProgress || 55
        }
      })
    }
  }

  useEffect(() => {
    // TODO change to appointment updated check
    if (user && user.angel.angel_appointment && !edit) {
      if (navigation) {
        navigation.navigate('SignUpAngelStep5')
      } else {
        history.push('/signup-angel-step5')
      }
    } else {
      console.log('fixit')
    }
  }, [user])

  useEffect(() => {
    getAgendasData()
  }, [])

  return (
    <SafeAreaView>
      {!navigation && <Header title={t('signUpAngelScreensMakeAnApointment')} />}
      <ScrollView>
        <Divider />

        <Text margins="20px" fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('signUpAngelScreensMeetUp')}
        </Text>

        <Text margins="0px 20px 20px 20px" fontWeight="300">
          {t('signUpAngelScreensMeetUpDescription')}
        </Text>

        <TextIconButton
          flexDirection="row"
          justifyContent="space-between"
          text={t('signUpAngelScreensMeetUpExpectation')}
          fontSize="14px"
          fontWeight="normal"
          textColor={colors.primaryText}
          icon={showMeetingSteps ? arrowUpIcon : arrowDownIcon}
          iconColor={colors.secondary}
          onPress={() => setShowMeetingSteps(!showMeetingSteps)}
        />

        {showMeetingSteps && (
          <Text margins="10px 20px 0px 20px" fontWeight="300">
            {t('signUpAngelScreensMeetUpSteps')}
          </Text>
        )}

        <TextIconView
          flexDirection="row-reverse"
          alignItems="flex-start"
          paddings="15px 10px 15px 10px"
          margins="20px"
          backgroundColor={colors.grey09}
          icon={giftIcon}
          iconSize="40px"
          iconColor={colors.secondary}
          textFlex={1}
          textMargins="0px 0px 0px 10px"
          text={t('signUpAngelScreensGiftDescription')}
          fontWeight="300"
          textAlign="left"
        />

        <TextView
          flexDirection="column"
          margins="20px 0px 20px 20px"
          text={t('signUpAngelScreensSelectNearestLocation')}
          fontFamily="Martel"
          fontSize="18px"
          fontWeight="600">
          {agendas.map(agenda => {
            const { city, address, distance } = agenda

            return (
              <IconButton
                key={`${city}-${address}`}
                flexDirection="row-reverse"
                justifyContent="flex-start"
                height="auto"
                width="100%"
                iconSource={arrowRightIcon}
                iconColor={colors.defaultBtnBackgroundColor}
                onPress={() => onLocationPress(agenda)}>
                <View>
                  <TextTextView
                    flexDirection="column"
                    alignItems="flex-start"
                    paddings="0px"
                    firstText={city}
                    firstTextFontSize="14px"
                    firstTextAlign="left"
                    secondText={address}
                    secondTextFontSize="12px"
                    secondTextFontWeight="300"
                    secondTextAlign="left"
                  />

                  <TextIconView
                    flexDirection="column-reverse"
                    backgroundColor="transparent"
                    icon={locationIcon}
                    iconSize="32px"
                    iconColor={colors.primaryText}
                    text={renderDistanceInKilometers(distance)}
                    fontSize="12px"
                  />
                </View>
              </IconButton>
            )
          })}
        </TextView>
      </ScrollView>
      <Alert
        ref={alertRef}
        title={t('signUpAngelScreensSelectDateAndTime')}
        subtitle={`${appointmentLocation.city}\n${appointmentLocation.address}\n\n${t(
          'signUpAngelScreensNextStepSelectDateAndTime'
        )}`}
        onCancelPress={() => alertRef.current.setIsVisible(false)}
        onConfirmPress={goNextStep}
      />
    </SafeAreaView>
  )
}

MakeAppointment.propTypes = {
  location: PropTypes.shape({ state: PropTypes.shape({ edit: PropTypes.bool }) }),
  history: PropTypes.shape({ push: PropTypes.func }),
  route: PropTypes.shape({ props: PropTypes.shape({ edit: PropTypes.bool }) }),
  navigation: PropTypes.shape({ navigate: PropTypes.func, setOptions: PropTypes.func }),
  agendas: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string,
      address: PropTypes.string,
      distance: PropTypes.number
    })
  ),
  user: PropTypes.shape({
    angel: PropTypes.shape({
      onboardingProgress: PropTypes.string,
      angel_appointment: PropTypes.shape({})
    })
  }),
  getAgendasData: PropTypes.func.isRequired
}

MakeAppointment.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null,
  agendas: [],
  user: null
}

const mapStateToProps = ({ User, AngelAppointment }) => ({
  agendas: AngelAppointment.agendas,
  user: User.user
})

const mapDispatchToProps = {
  getAgendasData: getAgendas
}

export default connect(mapStateToProps, mapDispatchToProps)(MakeAppointment)
