import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import { ActivityIndicator } from 'react-native'
import styled from 'styled-components/native'
import { isMobile } from 'react-device-detect'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { IcnNavRight } from '../Icons'
import { TextView, TextIconView } from '../Texts'
import { TextButton, IconButton } from '../Buttons'
import colors from '../../styles/colors'

import DatePicker from '../Pickers/DatePickerWeb'

const View = styled.View`
  border-top-width: ${({ multiBooking }) => (multiBooking ? '1px' : '0px')};
  border-color: ${colors.grey07};
`
const DateTimeView = styled.View`
  flex-direction: row;
  align-items: center;
`

const getDuration = (startDate, endDate) => {
  const diff = moment(endDate).diff(moment(startDate))
  const diffDuration = moment.duration(diff)
  const hours = Math.round(diffDuration.asHours())
  const minutes = diffDuration.minutes()

  return { hours, minutes }
}

const BookingDay = ({
  disabled,
  index,
  startDate,
  endDate,
  minStartDate,
  maxStartDate,
  maxEndDate,
  repeat,
  multiBooking,
  setStartDate,
  setEndDate,
  onRepeatPress,
  onRemoveDayPress
}) => {
  const [t] = useTranslation()
  const duration = getDuration(startDate, endDate)

  return (
    <View multiBooking={multiBooking}>
      {multiBooking && (
        <TextView
          justifyContent="space-between"
          paddings="15px 15px 5px 15px"
          backgroundColor={colors.backgroundColor}
          text={`${disabled ? t('request') : t('day')} ${index + 1}`}
          fontFamily="Martel"
          fontWeight="300"
          fontSize="22px"
        >
          {index > 0 && !disabled && (
            <IconButton
              disabled={disabled}
              paddings="20px"
              width="auto"
              height="auto"
              borderRadius="30px"
              borderWith={0}
              backgroundColor={colors.backgroundColor}
              iconName="icn-cancel"
              iconSize="17px"
              iconColor={colors.grey03}
              onPress={onRemoveDayPress}
            />
          )}
        </TextView>
      )}

      <TextView
        justifyContent="space-between"
        paddings="10px 15px 10px 0px"
        margins="0px 0px 0px 20px"
        borderBottomWidth="1px"
        text={t('start')}
        fontSize="16px"
      >
        <DateTimeView>
          <DatePicker
            disabled={disabled}
            timeIntervals={15}
            minDate={moment(minStartDate).toDate()}
            maxDate={moment(maxStartDate).toDate()}
            selected={moment(startDate).toDate()}
            labelValue={moment(startDate).format('dddd D MMM HH:mm')}
            onChange={date => {
              const roundedMinutes = Math.round(moment(date).minute() / 15) * 15
              setStartDate(moment(date))
            }}
            popperPlacement="bottom-end"
            popperModifiers={{
              flip: {
                behavior: ['bottom'] // don't allow it to flip to be above
              },
              preventOverflow: {
                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
              },
              hide: {
                enabled: false // turn off since needs preventOverflow to be enabled
              }
            }}
          />

          {!disabled && <IcnNavRight color={colors.grey08} />}
        </DateTimeView>
      </TextView>
      <TextView
        justifyContent="space-between"
        alignItems="flex-start"
        paddings="10px 15px 10px 0px"
        margins="0px 0px 0px 20px"
        borderBottomWidth="1px"
        text={t('end')}
        fontSize="16px"
      >
        <DateTimeView>
          <Suspense fallback={<ActivityIndicator />}>
            <DatePicker
              disabled={disabled}
              timeIntervals={15}
              min={moment(startDate).add(2, 'hours').toDate()}
              max={moment(maxEndDate).toDate()}
              value={moment(endDate).toDate()}
              labelValue={moment(endDate).format('dddd D MMM HH:mm')}
              onChange={date => {
                setEndDate(moment(date))
              }}
              popperPlacement="bottom-end"
              popperModifiers={{
                flip: {
                  behavior: ['bottom'] // don't allow it to flip to be above
                },
                preventOverflow: {
                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                  enabled: false // turn off since needs preventOverflow to be enabled
                }
              }}
            />
          </Suspense>

          {!disabled && <IcnNavRight color={colors.grey08} />}
        </DateTimeView>
      </TextView>

      <TextView
        justifyContent="space-between"
        alignItems="flex-start"
        paddings="10px 20px 10px 0px"
        margins="0px 0px 0px 20px"
        text={t('duration')}
        fontSize="16px"
      >
        <DateTimeView>
          <TextIconView
            flexDirection="row-reverse"
            alignItems="flex-end"
            paddings="0px"
            margins="0px 5px 0px 20px"
            height="auto"
            backgroundColor={colors.white}
            text={`${duration.hours} ${t('hourShort')} ${duration.minutes} ${t('minuteShort')}`}
            fontSize="16px"
            textColor={colors.grey03}
            name="time"
            iconMargins="0px 5px 0px 0px"
            iconColor={colors.grey03}
          />
        </DateTimeView>
      </TextView>

      {multiBooking && (
        <TextView
          justifyContent="space-between"
          paddings="10px 15px 10px 0px"
          margins="0px 0px 0px 20px"
          borderTopWidth="1px"
          text={t('repeat')}
          fontSize="16px"
        >
          <DateTimeView>
            <TextButton
              disabled={disabled}
              paddings="0px"
              margins="0px 5px 0px 0px"
              height="auto"
              text={repeat === 0 ? t('no') : `${repeat}x`}
              fontSize="16px"
              textColor={disabled ? colors.grey02 : colors.secondary}
              onPress={onRepeatPress}
            />

            {!disabled && <IcnNavRight color={colors.grey08} />}
          </DateTimeView>
        </TextView>
      )}
    </View>
  )
}

BookingDay.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  minStartDate: PropTypes.string,
  maxStartDate: PropTypes.string,
  maxEndDate: PropTypes.string,
  repeat: PropTypes.number.isRequired,
  multiBooking: PropTypes.bool.isRequired,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  onRepeatPress: PropTypes.func,
  onRemoveDayPress: PropTypes.func
}

BookingDay.defaultProps = {
  disabled: false,
  minStartDate: null,
  maxStartDate: null,
  maxEndDate: null,
  setStartDate: () => {},
  setEndDate: () => {},
  onRepeatPress: () => {},
  onRemoveDayPress: () => {}
}

export default BookingDay
