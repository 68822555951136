import { LoadEarlier } from 'react-native-gifted-chat';
import styled from 'styled-components/native';
import colors from '../../styles/colors';

const StyledLoadEarlier = styled(LoadEarlier).attrs({
  textStyle: {
    fontFamily: 'Open Sans'
  },
  wrapperStyle: {
    backgroundColor: colors.grey05
  }
})``;

export default StyledLoadEarlier;
