import React, { useState, useEffect } from 'react';
import { FlatList, ActivityIndicator } from 'react-native';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getRatings, getNextRatings } from '../../../stores/actions/ratingsAction';
import { Text, Divider } from '../../../elements';
import { IconButton, Header, AngelReviewCard } from '../../../components';
import colors from '../../../styles/colors';

import leftIcon from '../../../../assets/icons/icn-nav-left.png';

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
`;

const Reviews = ({
  history,
  navigation,
  fetching,
  ratings,
  getRatingsData,
  getNextRatingsData
}) => {
  const [page, setPage] = useState(1);
  const [t] = useTranslation();
  const { data, current_page, next_page_url } = ratings || {};
  console.log({ data });

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('profileScreensRatingAndReviews')}
        </Text>
      )
    });
  }

  useEffect(() => {
    getRatingsData();
  }, []);

  const refreshRatings = () => {
    setPage(1);
    getRatingsData();
  };

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          left={
            <IconButton
              iconSize="32px"
              iconColor={colors.secondary}
              iconSource={leftIcon}
              onPress={history.goBack}
            />
          }
          title={t('profileScreensRatingAndReviews')}
        />
      )}
      {fetching && ratings && !data.length ? (
        <ActivityIndicator />
      ) : (
        <>
          <Divider />

          {ratings && data && data.length ? (
            <FlatList
              initialNumToRender={10}
              data={data}
              renderItem={({ item }) => {
                const { angel, start_date, rating, comments } = item;

                return (
                  <AngelReviewCard
                    angelImage={angel.image}
                    startDate={start_date}
                    rating={rating}
                    comments={comments}
                    onCardPress={() => {
                      if (navigation) {
                        navigation.navigate('ProfileReviewDetails', { review: item });
                      } else {
                        history.push({
                          pathname: '/profile-reviews-details',
                          state: { review: item }
                        });
                      }
                    }}
                  />
                );
              }}
              onEndReachedThreshold={0.9}
              onEndReached={() => {
                if (current_page === page && next_page_url) {
                  setPage(page + 1);
                  getNextRatingsData(next_page_url);
                }
              }}
              refreshing={fetching}
              onRefresh={refreshRatings}
              keyExtractor={({ id }) => id.toString()}
            />
          ) : (
            <Text paddings="20px" textAlign="center">
              {t('profileScreensRatingAndReviewsNoReviews')}
            </Text>
          )}
        </>
      )}
    </SafeAreaView>
  );
};

Reviews.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func, goBack: PropTypes.func }),
  navigation: PropTypes.shape({ navigate: PropTypes.func, setOptions: PropTypes.func }),
  ratings: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    current_page: PropTypes.number,
    next_page_url: PropTypes.string
  }),
  fetching: PropTypes.bool,
  getRatingsData: PropTypes.func.isRequired,
  getNextRatingsData: PropTypes.func.isRequired
};

Reviews.defaultProps = {
  history: null,
  navigation: null,
  ratings: null,
  fetching: true
};

const mapStateToProps = ({ Ratings }) => ({
  ratings: Ratings.ratings,
  fetching: Ratings.fetching
});

const mapDispatchToProps = {
  getRatingsData: getRatings,
  getNextRatingsData: getNextRatings
};

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
