import React, { useState } from 'react'
import { ScreenLayout, TextInput, Card } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { flattenSearchBookingDates, useRouter, UserRoles } from '@charlycares/shared'
import {
  ResponseError,
  useAngelCreateBookingMutation,
  useAppSelector,
  useCreateBookingMutation,
  useGetAngelDetailsQuery,
  useGetFamilyDetailsQuery,
  useGetUserQuery
} from '@charlycares/data-access'
import { Avatar, Heading, Pressable, Text } from 'native-base'

import { BookingDatesSelector } from './common'
import { JobBoardOptions, useHandleCreateBooking } from './family'
import { useAlert } from '../../hooks'

const DirectBookingScreen = () => {
  const { t } = useTranslation()
  const { navigate, getParam } = useRouter()
  const alert = useAlert()

  const familyId = getParam('familyId')
  const angelId = getParam('angelId')

  const [personalMessage, setPersonalMessage] = useState('')
  const [jobBoard, setJobBoard] = useState(true)
  const [maxHourlyRate, setMaxHourlyRate] = useState<number>(1000)

  const selectedDates = useAppSelector(state => state.booking.search.selectedDates)

  const { data: user } = useGetUserQuery()
  const { data: family } = useGetFamilyDetailsQuery({ familyObscuredId: familyId }, { skip: !familyId })
  const { data: angel } = useGetAngelDetailsQuery({ angelObscuredId: angelId }, { skip: !angelId })
  const [familyCreateBooking, { isLoading: familyBookingLoading }] = useCreateBookingMutation()
  const [angelCreateBooking, { isLoading: angelBookingLoading }] = useAngelCreateBookingMutation()
  const { getErrorMessage } = useHandleCreateBooking({})

  if (!angel && !family) {
    return <ScreenLayout isLoading title={t('sendRequest')} />
  }

  const onSubmit = async () => {
    try {
      if (user?.role === UserRoles.FAMILY) {
        await familyCreateBooking({
          selected_angels: [
            {
              angel_user_id: angel?.angel_user_id || '',
              rank: 0
            }
          ],
          dates: flattenSearchBookingDates(selectedDates),
          job_board: jobBoard,
          personal_message: '',
          max_hourly_rate: {
            amount: maxHourlyRate,
            currency: 'EUR'
          },
          travel_allowance: {
            amount: 0,
            currency: 'EUR'
          }
        }).unwrap()
        navigate('BookRequestSent', '/book-request-sent')
      } else {
        await angelCreateBooking({
          dates: flattenSearchBookingDates(selectedDates),
          message: personalMessage,
          family_user_id: family?.family_user_id || ''
        }).unwrap()
        navigate('AngelBookingRequestSent', '/book-angel-request-sent')
      }
    } catch (error) {
      const err = error as ResponseError
      alert.show(t('errorAlert'), getErrorMessage(err), [{ text: t('ok'), style: 'cancel' }])
    }
  }

  return (
    <ScreenLayout
      title={t('sendRequest')}
      _buttonProps={{
        isLoading: familyBookingLoading || angelBookingLoading,
        onPress: onSubmit,
        children: t('bookingsScreenSendRequest')
      }}
    >
      <Card>
        <Pressable
          onPress={() => navigate('AngelProfile', `/booking-angel-profile`, { angelId: angel?.obscured_id })}
          flexDir="row"
          alignItems="center"
        >
          <Avatar shadow="5" borderWidth="1px" borderColor="white" source={{ uri: angel?.image || family?.image }} />
          <Text ml="15px" fontSize="14px">
            {angel?.first_name || family?.name}
          </Text>
        </Pressable>
      </Card>

      <BookingDatesSelector />

      <Card>
        <Heading fontSize="15px" fontWeight={600}>
          {t('bookingPersonalMessageLabel')}
        </Heading>

        <TextInput
          placeholder={t('bookingPersonalMessagePlaceholder')}
          onChangeText={setPersonalMessage}
          value={personalMessage}
          maxLength={250}
          mt="15px"
          h="110px"
          variant="filled"
          multiline
          numberOfLines={5}
        />
      </Card>

      {!!user?.family && (
        <JobBoardOptions
          maxHourlyRate={maxHourlyRate}
          onMaxHourlyRateChange={setMaxHourlyRate}
          jobBoard={jobBoard}
          onToggle={value => setJobBoard(value)}
          mb="47px"
        />
      )}
    </ScreenLayout>
  )
}

export default DirectBookingScreen

DirectBookingScreen.navigationOptions = {
  headerShown: false
}
