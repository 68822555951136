import React from 'react'
import { Box, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import { SearchBookingsDates } from '@charlycares/shared'

import BookingDatePicker from '../../common/BookingDatePicker'

type Props = {
  bookingDates: SearchBookingsDates[]
}

const BookingDatesList = ({ bookingDates }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      {bookingDates.map(({ startDate, endDate, repeatDates }, index) => {
        return (
          <Box key={startDate.toString() + index}>
            {bookingDates.length > 1 && (
              <Text mx="20px" mt="15px" fontSize="18px" fontWeight={600}>{`${t('day')} ${index + 1}`}</Text>
            )}
            <BookingDatePicker repeatDates={repeatDates?.length} startDate={startDate} endDate={endDate} readonly />
          </Box>
        )
      })}
    </>
  )
}

export default BookingDatesList
