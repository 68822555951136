import React from 'react'
import { Card, Icon as CharlyIcon, MissingIndicator, SittingExperiencePicker } from '@charlycares/ui'
import { Box, Divider, HStack, Icon, Switch, Text, useTheme } from 'native-base'
import { useTranslation } from 'react-i18next'
import { FormikErrors } from 'formik'
import { TouchableOpacity } from 'react-native'
import { babysittingExperience, isIos } from '@charlycares/shared'

type Props = {
  editable?: boolean
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<any>>
  values: {
    min_age_children?: number
    first_aid: boolean
    driving_license: boolean
    tutor: boolean
    languages: {
      dutch: boolean
      english: boolean
      french: boolean
      german: boolean
      spanish: boolean
      italian: boolean
    }
  }
}

const AngelSkills = ({ editable, setFieldValue, values }: Props) => {
  const { first_aid, tutor, driving_license, languages, min_age_children } = values
  const { t } = useTranslation()
  const { colors } = useTheme()

  const skills = [
    {
      label: t('bookScreensFirstAid'),
      field: 'first_aid',
      value: first_aid,
      icon: <CharlyIcon name="icn-first-aid" size={32} color={colors.black} />
    },
    {
      label: t('bookScreensDrivingLicence'),
      field: 'driving_license',
      value: driving_license,
      icon: <CharlyIcon name="icn-car" size={32} color={colors.black} />
    },
    {
      label: t('bookScreensEducation'),
      field: 'tutor',
      value: tutor,
      icon: <CharlyIcon name="school" size={32} color={colors.black} />
    }
  ]

  return (
    <Card>
      {editable ? (
        <Box w="100%">
          {/* experience */}
          <Box>
            <Text mb="20px" fontSize="14px" color="gray.800">
              {t('babysitExperiences')}
            </Text>

            <SittingExperiencePicker
              onChange={exp => setFieldValue('min_age_children', exp)}
              value={min_age_children}
              label={t('babysitExperiencesDescription')}
            />
          </Box>

          <Divider bg="gray.200" my="20px" />

          {/* skills */}
          <Box>
            {skills.map(({ label, value, icon, field }, index) => (
              <Box key={field}>
                {!first_aid && !tutor && !driving_license && <MissingIndicator />}

                {index === 0 && (
                  <Text textTransform="capitalize" fontSize="14px" color="gray.800">
                    {t('skills')}
                  </Text>
                )}

                <Box mt="20px" flexDir="row" alignItems="center">
                  <Icon mx="15px" as={icon} />
                  <Text ml="10px" fontSize="15px" flex={1}>
                    {label}
                  </Text>

                  <Switch
                    disabled={!editable}
                    size={isIos ? 'md' : 'lg'}
                    value={value}
                    onValueChange={val => {
                      setFieldValue(field, val)
                    }}
                  />
                </Box>
              </Box>
            ))}
          </Box>

          <Divider bg="gray.200" my="20px" />

          {/* languages */}
          <Box>
            <Box>
              {!Object.values(languages).find(val => val) && <MissingIndicator />}

              <Text fontSize="14px" color="gray.800">
                {t('languages')}
              </Text>

              <Text mt="10px" fontSize="14px">
                {t('profileScreensIndicateSpokenLanguages')}
              </Text>
            </Box>

            {Object.keys(languages).map(lang => {
              const value = languages[lang as keyof typeof languages]
              return (
                <Box key={lang} mt="20px" flexDir="row" alignItems="center">
                  <Text textTransform="capitalize" fontSize="15px" flex={1}>
                    {t(`${lang}Language`)}
                  </Text>

                  <TouchableOpacity disabled={!editable} onPress={() => setFieldValue(`languages.${lang}`, !value)}>
                    {value ? (
                      <CharlyIcon name="btn-check" color={colors.secondary[400]} size={32} />
                    ) : (
                      <CharlyIcon name="btn-plus" color={colors.gray[400]} size={32} />
                    )}
                  </TouchableOpacity>
                </Box>
              )
            })}
          </Box>
        </Box>
      ) : (
        <Box w="100%">
          <Box>
            {min_age_children === undefined && <MissingIndicator />}

            <Text fontSize="14px" color="gray.800">
              {t('babysitExperiences')}
            </Text>

            <Text px="10px" py="15px" textTransform="lowercase" fontSize="16px">
              {min_age_children !== undefined &&
                babysittingExperience
                  .filter(obj => obj.value >= (min_age_children as number))
                  .map(obj => t(obj.key))
                  .join(', ')}
            </Text>
          </Box>

          <Box>
            {!skills.filter(s => s.value).length && <MissingIndicator />}

            <Text textTransform="capitalize" fontSize="14px" color="gray.800">
              {t('skills')}
            </Text>

            <HStack px="10px" py="15px" space="40px">
              {skills
                .filter(s => s.value)
                .map(({ icon, field }) => (
                  <Icon key={field} as={icon} />
                ))}
            </HStack>
          </Box>

          <Box>
            {!Object.keys(languages).filter(lng => languages[lng as keyof typeof languages]).length && (
              <MissingIndicator />
            )}
            <Text fontSize="14px" color="gray.800">
              {t('languages')}
            </Text>

            <Text px="10px" py="15px" textTransform="capitalize" fontSize="16px">
              {Object.keys(languages)
                .filter(lng => languages[lng as keyof typeof languages])
                .map(lang => t(`${lang}Language`))
                .join(',  ')}
            </Text>
          </Box>
        </Box>
      )}
    </Card>
  )
}

export default AngelSkills
