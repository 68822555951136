import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Text } from '../../elements';

const CountdownTimer = ({ expiresAt }) => {
  const [countdown, setCountdown] = useState(null);
  const [t] = useTranslation();

  const setTime = (time) => {
    if (time > 0) {
      if (time < 10) {
        return `0${time}`;
      }
      return time;
    }
    return null;
  };

  useEffect(() => {
    let interval;
    if (expiresAt) {
      interval = setInterval(() => {
        const now = moment();
        const endTime = moment(expiresAt, 'YYYY-MM-DD HH:mm:ss');
        const diff = endTime.diff(now);

        setCountdown(moment.duration(diff));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, []);

  return (
    <Text fontFamily="Martel-DemiBold" fontSize="12px" fontWeight="bold">
      {countdown &&
        `${t('bookingsScreenExpiresIn')} ${setTime(countdown.hours()) || '00'}h ${
          setTime(countdown.minutes()) || '00'
        }m ${setTime(countdown.seconds()) || '00'}s`}
    </Text>
  );
};

CountdownTimer.propTypes = {
  expiresAt: PropTypes.string.isRequired
};

export default CountdownTimer;
