import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Text, Icon, Heading, ContainerView } from '../../elements';
import { TextButton } from '../Buttons';
import theme from '../../styles/theme';

import shopIcon from '../../../assets/icons/icn-gift.png';

const CharlysShop = ({ onGoToShopPress, isAngel }) => {
  const [t] = useTranslation();

  return (
    <ContainerView
      flexDirection="column"
      alignItems="flex-start"
      paddings="32px 0"
      margins="0 20px"
      borderBottomWidth="1px">
      <Heading
        margins="0 44px 4px 0"
        xxl
        ariaLevel={3}
        text={
          isAngel
            ? t('dashboardScreensCharlysShopTitle')
            : t('dashboardScreensCharlysShopFamilyTitle')
        }
      />
      <Text margins="0 0 4px 0" lg lighterColor>
        {t('dashboardScreensCharlysShopSubtitle')}
      </Text>

      <Text margins="0 0 16px 0">
        {isAngel
          ? t('dashboardScreensCharlysShopDescription')
          : t('dashboardScreensCharlysShopFamilyDescription')}
      </Text>

      <TextButton
        paddings="0px 30px 0px 30px"
        height="30px"
        borderRightWidth="1px"
        borderLeftWidth="1px"
        borderColor={theme.colors.primary}
        text={t('dashboardScreensGoToShop')}
        fontSize="13px"
        fontWeight={theme.buttonsFontWeight}
        textColor={theme.colors.primary}
        onPress={onGoToShopPress}
      />
      <Icon
        position="absolute"
        top={0}
        right="0px"
        size="40px"
        color={theme.colors.secondary}
        source={shopIcon}
      />
    </ContainerView>
  );
};

CharlysShop.propTypes = {
  onGoToShopPress: PropTypes.func.isRequired,
  isAngel: PropTypes.bool.isRequired
};

export default CharlysShop;
