import React from 'react'
import { AlertDialog, Button, HStack } from 'native-base'
import { closeAlert, useAppDispatch, useAppSelector } from '@charlycares/data-access'

const Alert = () => {
  const { show, message, title, buttons } = useAppSelector(state => state.alert)
  const dispatch = useAppDispatch()

  const cancelRef = React.useRef(null)

  const onClose = () => dispatch(closeAlert())

  return (
    <AlertDialog leastDestructiveRef={cancelRef} isOpen={show} onClose={onClose}>
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        {title && <AlertDialog.Header>{title}</AlertDialog.Header>}
        {message && <AlertDialog.Body>{message} </AlertDialog.Body>}
        <AlertDialog.Footer>
          <HStack space={2}>
            {buttons?.map(({ text, onPress, style }) => (
              <Button
                key={text}
                variant={'unstyled'}
                colorScheme={style === 'cancel' ? 'text.dark.400' : 'primary'}
                onPress={() => {
                  onPress?.()
                  onClose()
                }}
                _hover={{
                  _important: {
                    bg: `primary.400`
                  }
                }}
                _text={{
                  fontWeight: style === 'cancel' ? 400 : 600
                }}
                ref={cancelRef}
              >
                {text}
              </Button>
            ))}
          </HStack>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  )
}

export default Alert
