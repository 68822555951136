import AsyncStorage from '@react-native-async-storage/async-storage'
import moment from 'moment'
import { isWeb } from '@charlycares/shared'

const useHandleDownloadAppOverlay = () => {
  const shouldShowDownloadAppOverlay = async () => {
    const lastShown = await AsyncStorage.getItem('downloadAppTimestamp')
    const now = moment()
    const diff = now.diff(moment(lastShown), 'hours')

    if (isWeb && (!lastShown || diff > 24)) {
      await AsyncStorage.setItem('downloadAppTimestamp', now.format())

      return true
    }

    return false
  }

  return { shouldShowDownloadAppOverlay }
}

export default useHandleDownloadAppOverlay
