import { DefaultTheme } from 'styled-components/native'

import { colors } from './colors'
import { fonts } from './fonts'
import { space, fontSize } from './sizes'

export const styledTheme: DefaultTheme = {
  mode: 'light',
  colors,
  fonts,
  space,
  fontSize
}
