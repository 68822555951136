import React from 'react'
import { useTranslation } from 'react-i18next'
import { isWeb, NewCityBannerEN, NewCityBannerNL, useRouter } from '@charlycares/shared'
import { Card, CharlyIcon, ScreenLayout } from '@charlycares/ui'
import { Button, Heading, Image, Text } from 'native-base'

export default function NewCityStep1Screen() {
  const { t, i18n } = useTranslation()
  const { navigate } = useRouter()
  const banner = i18n.language === 'nl_NL' ? NewCityBannerNL : NewCityBannerEN

  return (
    <ScreenLayout
      progress={`${25}%`}
      subtitle={''}
      headerLeft={<CharlyIcon name="logo" color="primary.400" size={30} />}
      bgColor="white"
      hideBackButton
    >
      <Image
        // @ts-ignore
        source={banner}
        alt="New City Banner"
        resizeMode="contain"
        maxHeight="200px"
        height={isWeb ? '200px' : undefined}
        width="100%"
      />
      <Card borderWidth="0" minHeight="330px">
        <Heading mb="20px" fontSize="20px" fontWeight="600" mt="-20px">
          {t('newCityStep1Screen.header')}
        </Heading>
        <Text fontSize="18px">{t('newCityStep1Screen.description')}</Text>
      </Card>
      <Button mx="20px" onPress={() => navigate('NewCityStep2Screen', '/family/new-city/step-2')}>
        {t('newCityStep1Screen.nextStep')}
      </Button>
    </ScreenLayout>
  )
}
