import Input from './input.style'
import Button from './button.style'
import Badge from './badge.style'
import Heading from './heading.style'
import Divider from './divider.style'

export default {
  Input,
  Button,
  Badge,
  Heading,
  Divider
}
