export enum StorageKeys {
  LANG_CODE = '@langCode',
  AUTH_TOKEN = 'token',
  JOBBOARD_FILTERS = '@jobBoardFilters',
  BASE_URL = '@baseUrl'
}

export enum FeatureTypes {
  FAMILY_HIDE_STANDBY_ANGELS = 'family_hide_standby_angels',
  ANGELS_DISABLE_DO_NOT_DISTURB_STATUS = 'angels_disable_do_not_disturb_status',
  FAMILY_WELCOME_SCREEN = 'family_welcome_modal',
  IOS_IAP_FLOW = 'ios_iap_flow'
}
