import axiosInstance from '../../utils/axios';
import {
  GET_AGENDAS,
  GET_APPOINTMENT_TIMESLOTS,
  GET_APPOINTMENT_TIMESLOTS_ERROR,
  SET_APPOINTMENT
} from '../types';

export const getAgendas = () => (dispatch) => {
  axiosInstance
    .get('agendas/show')
    .then(({ data }) => {
      console.log({ data }, 'getAgendas');
      if (data) {
        dispatch({
          type: GET_AGENDAS,
          payload: data.data.agendas
        });
      }
    })
    .catch((error) => console.log({ error }));
};

export const getAppointmentTimeslots = (id, weeekNumber, year) => (dispatch) => {
  dispatch({ type: GET_APPOINTMENT_TIMESLOTS_ERROR, payload: { loading: true } });
  axiosInstance
    .get(`agenda/${id}/${weeekNumber}/${year}`)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_APPOINTMENT_TIMESLOTS,
          payload: data.data
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        console.log({ error });
        if (error.response.status === 400) {
          dispatch({
            type: GET_APPOINTMENT_TIMESLOTS_ERROR,
            payload: { error: error.response.data.message, loading: false }
          });
        }
      }
    });
};

export const makeAppointment = (id) => (dispatch) => {
  axiosInstance
    .post('agenda/makeAppointment', { id })
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: SET_APPOINTMENT,
          payload: data.data
        });
      }
    })
    .catch((error) => console.log({ error }));
};

export const cancelAppointment = (reason) => (dispatch) => {
  axiosInstance
    .post('agenda/cancelAppointment', { reason })
    .then(({ data }) => {
      if (data) {
        dispatch({ type: SET_APPOINTMENT, payload: null });
      }
    })
    .catch((error) => console.log({ error }));
};
