import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { TextIconButton, theme, TabIndicator } from '@charlycares/legacy/web'

const View = styled.View`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledTextIconButton = styled(TextIconButton)`
  border-radius: 0;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: ${({ showBorder }) => (showBorder ? theme.colors.grey08 : 'transparent')};
  font-weight: ${theme.textFontWeight};
`

const Navbar = ({ role }) => {
  const [t] = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const isAngel = role === 'angel'
  const isDiscover = location.pathname.includes('/discover') || location.pathname.includes('/book-')
  const isBooking = location.pathname.includes('/booking')
  const isChat = location.pathname.includes('/chat')
  const isFavOrFinder =
    location.pathname.includes('/favorites') || location.pathname.includes('/job-finder')
  const isProfile = location.pathname === '/profile'

  return (
    <View accessibilityRole="navigation">
      <StyledTextIconButton
        flexDirection="row-reverse"
        justifyContent="flex-end"
        paddings="0px 12px"
        height="40px"
        width="100%"
        showBorder={isDiscover}
        backgroundColor={isDiscover ? theme.colors.white : 'transparent'}
        text={isAngel ? t('dashboardTab') : t('discoverTab')}
        textColor={isDiscover ? theme.colors.secondary : theme.colors.defaultBtnTextColor}
        fontWeight={theme.textFontWeight}
        iconName="icn-search"
        iconMargins="0px 8px 0px 0px"
        iconSize="32px"
        iconColor={isDiscover ? theme.colors.secondary : theme.colors.defaultBtnTextColor}
        onPress={() => history.replace('/discover')}
      />

      <TabIndicator tab="Bookings">
        <StyledTextIconButton
          flexDirection="row-reverse"
          justifyContent="flex-end"
          paddings="0px 12px"
          height="40px"
          width="100%"
          showBorder={isBooking}
          backgroundColor={isBooking ? theme.colors.white : 'transparent'}
          text={t('bookingsTab')}
          textColor={isBooking ? theme.colors.secondary : theme.colors.defaultBtnTextColor}
          fontWeight={theme.textFontWeight}
          iconName="icn-bookings"
          iconMargins="0px 10px 0px 0px"
          iconSize="32px"
          iconColor={isBooking ? theme.colors.secondary : theme.colors.defaultBtnTextColor}
          onPress={() => history.replace('/bookings')}
        />
      </TabIndicator>

      <TabIndicator tab="Chat">
        <StyledTextIconButton
          flexDirection="row-reverse"
          justifyContent="flex-end"
          paddings="0px 12px"
          height="40px"
          width="100%"
          showBorder={isChat}
          backgroundColor={isChat ? theme.colors.white : 'transparent'}
          text={t('chatTab')}
          textColor={isChat ? theme.colors.secondary : theme.colors.defaultBtnTextColor}
          fontWeight={theme.textFontWeight}
          iconName="icn-chat"
          iconMargins="0px 8px 0px 0px"
          iconSize="32px"
          iconColor={isChat ? theme.colors.secondary : theme.colors.defaultBtnTextColor}
          onPress={() => history.replace('/chat')}
        />
      </TabIndicator>

      <TabIndicator tab={isAngel ? 'JobFinder' : ''}>
        <StyledTextIconButton
          flexDirection="row-reverse"
          justifyContent="flex-end"
          paddings="0 12px"
          height="40px"
          width="100%"
          showBorder={isFavOrFinder}
          backgroundColor={isFavOrFinder ? theme.colors.white : 'transparent'}
          text={isAngel ? t('jobFinderTab') : t('favoritesTab')}
          textColor={isFavOrFinder ? theme.colors.secondary : theme.colors.defaultBtnTextColor}
          fontWeight={theme.textFontWeight}
          iconName="favorites"
          iconMargins="0px 8px 0px 0px"
          iconSize="32px"
          iconColor={isFavOrFinder ? theme.colors.secondary : theme.colors.defaultBtnTextColor}
          onPress={() => history.replace(isAngel ? '/job-finder' : '/favorites')}
        />
      </TabIndicator>

      <TabIndicator tab="Profile">
        <StyledTextIconButton
          flexDirection="row-reverse"
          justifyContent="flex-end"
          paddings="0 12px"
          height="40px"
          width="100%"
          showBorder={isProfile}
          backgroundColor={isProfile ? theme.colors.white : 'transparent'}
          text={t('profileTab')}
          textColor={isProfile ? theme.colors.secondary : theme.colors.defaultBtnTextColor}
          fontWeight={theme.textFontWeight}
          iconName="icn-profile"
          iconMargins="0px 8px 0px 0px"
          iconSize="32px"
          iconColor={isProfile ? theme.colors.secondary : theme.colors.defaultBtnTextColor}
          onPress={() => history.replace('/profile')}
        />
      </TabIndicator>
    </View>
  )
}

Navbar.propTypes = {
  role: PropTypes.string.isRequired
}

export default Navbar
