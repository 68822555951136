import React from 'react'
import { minutesToCredit } from '@charlycares/shared'
import { useTranslation } from 'react-i18next'
import { CheckIcon, HStack, Text, VStack } from 'native-base'

interface BusinessAccountUspsProps {
  minutes: number
}

export default function BusinessAccountUsps({ minutes }: BusinessAccountUspsProps) {
  const { t } = useTranslation()

  const START_BUSINESS_ACCOUNT_CHECKLIST = [
    t('startBusinessUsp1'),
    t('startBusinessUsp2', {
      hours: `${minutesToCredit(minutes || 0)}`
    }),
    t('startBusinessUsp3')
  ]

  return (
    <VStack space="20px" margin="10px">
      {START_BUSINESS_ACCOUNT_CHECKLIST.map(text => (
        <HStack space="10px" key={text}>
          <CheckIcon size="30px" color="secondary.400" />
          <Text fontSize="15px" color="gray.900" paddingRight="40px">
            {text}
          </Text>
        </HStack>
      ))}
    </VStack>
  )
}
