import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { Text, Icon, Divider } from '../../../../elements';
import { TextTextView, TextButton, Header, IconButton } from '../../../../components';
import colors from '../../../../styles/colors';

import creditCardIcon from '../../../../../assets/icons/icn-creditcard.png';
import leftIcon from '../../../../../assets/icons/icn-nav-left.png';

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
`;
const View = styled.View`
  flex-direction: row;
  align-items: center;
  margin: 0px 0px 0px 20px;
`;

const BankAccount = ({ location, history, route, navigation }) => {
  const { accountNumber, paymentLink } = route.params || location.state;
  const accountLastDigits = accountNumber.slice(accountNumber.length - 4, accountNumber.length);
  const [t] = useTranslation();

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('profileScreensBankAccount')}
        </Text>
      )
    });
  }

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          title={t('profileScreensBankAccount')}
          left={
            <IconButton
              iconSize="32px"
              iconColor={colors.secondary}
              iconSource={leftIcon}
              onPress={history.goBack}
            />
          }
        />
      )}
      <Divider />

      <TextTextView
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        paddings="20px"
        firstText={t('profileScreensChangePaymentMethod')}
        firstTextFontFamily="Martel"
        firstTextFontSize="18px"
        firstTextFontWeight="600"
        secondText={t('profileScreensDoYouWantToChangePayment')}
        secondTextMargins="15px 0px 0px 0px"
        secondTextFontSize="15px"
        secondTextFontWeight="300"
        secondTextAlign="left"
      />

      <View>
        <Icon size="32px" source={creditCardIcon} />

        <TextTextView
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          margins="0px 0px 0px 10px"
          firstText={`**** **** **** ${accountLastDigits}`}
          firstTextFontFamily="Martel"
          firstTextFontSize="16px"
          firstTextFontWeight="600"
          secondText={t('profileScreensBankCredit')}
          secondTextFontSize="12px"
          secondTextColor={colors.defaultBtnBackgroundColor}
        />
      </View>

      <TextButton
        margins="20px"
        backgroundColor={colors.primary}
        text={t('profileScreensEditAccount')}
        fontSize="16px"
        fontWeight="600"
        onPress={() => {
          if (navigation) {
            navigation.navigate('WebView', { uri: paymentLink });
          } else {
            window.open(paymentLink, '_self');
          }
        }}
      />
    </SafeAreaView>
  );
};

BankAccount.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({ accountNumber: PropTypes.string, paymentLink: PropTypes.string })
  }),
  history: PropTypes.shape({ goBack: PropTypes.func }),
  route: PropTypes.shape({
    params: PropTypes.shape({ accountNumber: PropTypes.string, paymentLink: PropTypes.string })
  }),
  navigation: PropTypes.shape({ setOptions: PropTypes.func, navigate: PropTypes.func })
};

BankAccount.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null
};

export default BankAccount;
