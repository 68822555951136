import React, { useState } from 'react';
import { Flex, IconButton, Spacer, Text } from "native-base";
import { CharlyIcon } from "@charlycares/ui";

interface Props {
  values: {
    value: string;
    label: string;
    leftIcon?: JSX.Element
  }[];
  selected: Record<string, boolean> | undefined;
  onChange: (selected: Record<string, boolean>) => void;
}

const MultiSelectList = ({values, selected, onChange}: Props): JSX.Element => {
  const [realSelected, setRealSelected] = useState(selected ?? {});

  const change = (value: string) => {
    const newValues = {...realSelected, [value]: realSelected ? !realSelected[value] : false};

    setRealSelected(newValues)
    onChange(newValues)
  }

  return (
    <>
      { values.map(({value, label, leftIcon}) => (
        <Flex direction="row" mt="14px" key={ `multi-select-${ value }` }>
          { leftIcon }
          <Text fontSize="md" color="gray.900" pl="16px">
            { label }
          </Text>
          <Spacer/>
          <IconButton
            rounded="full"
            size={ 28 }
            icon={
              !realSelected[value] ? (
                <CharlyIcon color="gray.500" size={ 28 } name="btn-plus"/>
              ) : (
                <CharlyIcon color="secondary.400" size={ 28 } name="btn-check"/>
              )
            }
            onPress={ () => change(value) }
          />
        </Flex>
      )) }
    </>
  )

}

export default MultiSelectList;
