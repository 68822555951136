import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, TextInput, ScreenLayout } from '@charlycares/ui'
import { Text, Button, Heading } from 'native-base'

import { useRouter } from '@charlycares/shared'
import { useForgotPasswordMutation } from '@charlycares/data-access'
import { useAlert } from '../../hooks'

export default function ForgotPassword() {
  const { t } = useTranslation()
  const { navigate } = useRouter()
  const alert = useAlert()

  const [email, setEmail] = useState('')

  const [forgotPassword, forgotPasswordState] = useForgotPasswordMutation()

  const onSubmit = () => {
    if (email) {
      forgotPassword({ email })
    }
  }

  useEffect(() => {
    if (forgotPasswordState.isSuccess) {
      alert.show(t('forgotPasswordScreen.alertSuccessTitle'), t('forgotPasswordScreen.alertSuccessMessage'), [
        {
          text: t('ok'),
          onPress: () => navigate('CheckResetPasswordCode', '/check-reset-password-code', { email })
        }
      ])
    }
  }, [forgotPasswordState.isSuccess])

  useEffect(() => {
    if (forgotPasswordState.isError) {
      alert.show(t('forgotPasswordScreen.alertErrorTitle'), (forgotPasswordState.error as any)?.data?.message)
    }
  }, [forgotPasswordState.isError])

  return (
    <ScreenLayout title={t('forgotPasswordScreen.header')} disableScroll>
      <Card h="100%">
        <Heading mb="20px" fontSize="18px" fontWeight={600}>
          {t('forgotPasswordScreen.title')}
        </Heading>
        <Text fontSize="15px" mb="20px">
          {t('forgotPasswordScreen.subtitle')}
        </Text>

        <TextInput
          mt="10px"
          autoCapitalize="none"
          label={t('forgotPasswordScreen.emailLabel')}
          value={email}
          error={(forgotPasswordState.error as any)?.data?.message}
          onChangeText={value => setEmail(value)}
          placeholder={t('forgotPasswordScreen.emailPlaceholder')}
          onSubmitEditing={() => onSubmit()}
          returnKeyType="done"
        />
        <Button
          onPress={() => onSubmit()}
          isLoading={forgotPasswordState.isLoading}
          isDisabled={!email}
          mt="20px"
          height="50px"
        >
          {t('forgotPasswordScreen.submitButton')}
        </Button>
      </Card>
    </ScreenLayout>
  )
}

ForgotPassword.navigationOptions = () => ({
  headerShown: false
})
