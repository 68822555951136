import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Modal from './Modal';
import { Text, Icon } from '../../elements';
import { TextButton, IconButton } from '../Buttons';
import colors from '../../styles/colors';

import bellIcon from '../../../assets/icons/tabbar-icn-available.png';

const AnnouncementCard = styled.View`
  width: 400px;
  min-height: 80%;
  background-color: ${colors.white};
  border-radius: 12px;
`;

const Container = styled.View`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Divider = styled.View`
  width: 1px;
  height: 30px;
  background-color: ${colors.grey08};
  margin: 0 10px;
`;

const Header = styled.View`
  padding: 10px 20px 10px 20px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: auto;
  border-bottom-width: 1px;
  border-color: ${colors.grey08};
`;
const Content = styled.View`
  margin: 20px;
  background-color: ${colors.white};
  border-bottom-width: 1px;
  border-color: ${colors.grey08};
  align-items: center;
`;

const Row = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const FlatList = styled.FlatList`
  flex: 1;
`;

const Announcement = forwardRef(({ announcements, onClose }, ref) => {
  const [t] = useTranslation();
  const modalRef = useRef(null);

  const setIsVisible = (isVisible) => {
    modalRef.current.setIsVisible(isVisible);
  };

  useImperativeHandle(ref, () => ({
    setIsVisible
  }));

  return (
    <Modal ref={modalRef}>
      <Container>
        <AnnouncementCard>
          <Header>
            <Row>
              <Icon size="32px" source={bellIcon} color={colors.grey01} />

              <Text fontFamily="Martel" fontSize="18px" fontWeight="600" margins="0 10px">
                {t('announcement')}
              </Text>
            </Row>

            <Row>
              <Divider />
              <IconButton
                iconName="close"
                iconSize="32px"
                iconColor={colors.grey01}
                onPress={() => setIsVisible(false)}
              />
            </Row>
          </Header>

          <FlatList
            data={announcements}
            renderItem={({ item: { template } }) => (
              <Content>
                {/* TODO: use webview to render html in rn */}
                {!isMobile && (
                  <div
                    style={{ alignItems: 'center' }}
                    dangerouslySetInnerHTML={{ __html: template.app_content }}
                  />
                )}

                {template.redirect !== '' && (
                  <TextButton
                    margins="25px 0px 25px 0px"
                    width="50%"
                    borderRadius="20px"
                    backgroundColor={colors.primary}
                    text={t('continue')}
                    fontSize="18px"
                    onPress={() => onClose(template.redirect)}
                  />
                )}
              </Content>
            )}
            keyExtractor={({ id }) => String(id)}
          />
        </AnnouncementCard>
      </Container>
    </Modal>
  );
});

Announcement.propTypes = {
  navigation: PropTypes.shape({ navigate: PropTypes.func }),
  history: PropTypes.shape({ goBack: PropTypes.func }),
  announcements: PropTypes.arrayOf(
    PropTypes.shape({ app_content: PropTypes.string, redirect: PropTypes.string })
  ),
  onClose: PropTypes.func.isRequired
};

Announcement.defaultProps = {
  navigation: null,
  history: null,
  announcements: []
};

export default Announcement;
