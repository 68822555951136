import React, { useCallback, useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import { ITextProps, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import { getDuration } from '@charlycares/shared'

type Props = ITextProps & {
  date: string | Moment | Date
}

export default function CountDownTimer({ date, ...textProps }: Props) {
  const { t } = useTranslation()

  const getExpiration = useCallback(() => {
    const expires = getDuration(moment(), date)
    if (expires.asSeconds() < 1) return
    return `${t('expiresIn')} ${formatTime(expires.get('h'))}h ${formatTime(expires.get('m'))}m ${formatTime(
      expires.get('s')
    )}s`
  }, [date, t])

  const formatTime = (time: number) => {
    if (time > 9) {
      return time
    } else {
      return `0${time}`
    }
  }

  const [time, setTime] = useState(getExpiration())

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(getExpiration())
    }, 1000)

    return () => clearInterval(interval)
  }, [getExpiration])

  return (
    <Text fontSize="12px" {...textProps}>
      {time}
    </Text>
  )
}
