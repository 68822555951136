import React from 'react'
import ContentLoader, { Rect } from 'react-content-loader/native'

import { Box } from '@charlycares/ui'

type MembershipLoaderProps = {
  loading?: boolean
  children?: React.ReactElement | React.ReactElement[] | null
}

/**
 * @deprecated Use native-base skeleton component also this one should be moved to features.
 */
const MembershipLoader: React.FC<MembershipLoaderProps> = ({ children, loading }) => {
  return loading ? (
    <Box>
      <ContentLoader height={60} width={'100%'} speed={2}>
        <Rect x="0" y="0" rx="0" ry="0" width="100%" height="35" />
      </ContentLoader>

      <Box p="0 20px">
        {[...Array(5)].map((_, i) => (
          <ContentLoader height={70} width={'100%'} speed={2} key={i.toString()}>
            <Rect x="0" y="0" rx="0" ry="0" width="30%" height="40" />
            <Rect x="33%" y="0" rx="0" ry="0" width="30%" height="40" />
            <Rect x="66%" y="0" rx="0" ry="0" width="30%" height="40" />
          </ContentLoader>
        ))}
      </Box>

      <ContentLoader height={50} width={'100%'} speed={2}>
        <Rect x="0" y="0" rx="0" ry="0" width="100%" height="35" />
      </ContentLoader>
    </Box>
  ) : (
    <>{children}</>
  )
}

export default MembershipLoader
