import React from 'react'
import { iconsMap } from './generated'
import { BoxProps } from '@charlycares/ui'
import { IPressableProps, Pressable } from 'native-base'

type IconProps = BoxProps &
  IPressableProps & {
    name: keyof typeof iconsMap
    size: number
    color?: string
    onPress?: () => void | undefined
  }

/**
 * @deprecated Use CharlyIcon instead
 */
const Icon = ({ name, size, color, onPress, ...buttonProps }: IconProps) => {
  const IconComponent = iconsMap[name]

  return (
    <Pressable
      disabled={!onPress}
      onPress={onPress}
      hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
      {...buttonProps}
    >
      <IconComponent color={color || 'black'} width={size} height={size} />
    </Pressable>
  )
}

Icon.defaultProps = {
  size: 20
}

export default Icon
