import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { connect, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { applyCoupon } from '../../../stores/actions/userAction'
import { Text } from '../../../elements'
import { TextView, TextIconView, InputView, TextButton, Header } from '../../../components'
import colors from '../../../styles/colors'

import creditCardIcon from '../../../../assets/icons/icn-creditcard.png'
import sendMessageIcon from '../../../../assets/icons/icn-send.png'
import adyenImage from '../../../../assets/images/adyen.png'

import { PaymentsMethods } from '@charlycares/features'
import { Box } from '@charlycares/ui'
import { useStartBusinessMembershipMutation } from '@charlycares/data-access'
import { Platform } from 'react-native'

const SaveAreaView = styled.SafeAreaView`
  flex: 1;
  justify-content: space-between;
`

const BusinessBankAccount = ({ location, route, applyDiscountCoupon, applyCodeResponse }) => {
  const { isPrivate } = route?.params || location?.state || {}
  const [discountCode, setDiscountCode] = useState('')
  const [t] = useTranslation()
  const membership_type = useSelector(state => state.User.user?.membership_type)
  const personal_code = useSelector(state => state.b2b.code)
  const [connect, { isLoading, data, error }] = useStartBusinessMembershipMutation()

  // useEffect(() => {
  //   if (data) {
  //     window.open(data.data?.redirect_url, '_self')
  //   }
  // }, [data])

  const onPaymentMethod = payment_method => {
    if (!isLoading) {
      connect({
        payment_method,
        code: personal_code || '8DBBOYTW',
        share_permission: true,
        channel: Platform.OS,
        membership_type: isPrivate ? 'business_flexible_monthly' : undefined
      })
    }
  }

  return (
    <SaveAreaView>
      <Box>
        <Header title={t('signUpParentScreensPaymentofAngel')} />

        <InputView
          flexDirection="row-reverse"
          borderRadius="19px"
          backgroundColor={colors.grey08}
          margins={`20px 20px ${applyCodeResponse.description ? '0' : '20'}px 20px`}
          inputFlex={1}
          inputPaddings="0px 0px 0px 20px"
          minInputHeight="38px"
          showUnderline={false}
          placeholder={t('signUpParentScreensDiscountCode')}
          value={discountCode}
          onChangeText={setDiscountCode}
        >
          <TextButton
            backgroundColor="transparent"
            borderTopWidth={0}
            borderBottomWidth={0}
            text={t('apply')}
            textColor={discountCode ? colors.alert : colors.grey05}
            onPress={() => applyDiscountCoupon(discountCode)}
          />
        </InputView>

        {applyCodeResponse.description && (
          <Text margins="5px 20px 15px 30px" fontSize="12px" color={colors.alert}>
            {applyCodeResponse.description}
          </Text>
        )}

        <TextView
          flexDirection="column"
          alignItems="flex-start"
          margins="20px"
          paddings="0px 0px 20px 0px"
          borderBottomWidth="1px"
          text={t('signUpParentScreensEasyPayment')}
          fontFamily="Martel"
          fontSize="16px"
          fontWeight="600"
        >
          <TextIconView
            flexDirection="row-reverse"
            justifyContent="flex-start"
            alignItems="flex-start"
            margins="10px 0px 0px 5px"
            backgroundColor="transparent"
            icon={creditCardIcon}
            iconSize="32px"
            iconColor={colors.primaryText}
            textFlex={1}
            textMargins="0px 0px 0px 15px"
            text={t('signUpParentScreensConnectBankAccount')}
            fontWeight="300"
            textAlign="left"
          />
        </TextView>

        <TextIconView
          flexDirection="row-reverse"
          justifyContent="flex-start"
          alignItems="flex-start"
          margins="0px 20px 30px 20px"
          backgroundColor="transparent"
          icon={adyenImage}
          iconSize={0}
          iconWidth="70px"
          iconHeight="35px"
          iconColor={colors.primaryText}
          useTintColor={false}
          textFlex={1}
          textMargins="0px 0px 0px 10px"
          text={t('signUpParentScreensAdyenDescription')}
          fontSize="12px"
          fontWeight="300"
          textAlign="left"
        />

        <Box p="0 20px">
          <PaymentsMethods membershipType={membership_type} onPress={onPaymentMethod} />
        </Box>

        <Text color="red" margins="20px">
          {error?.data?.message}
        </Text>
      </Box>
    </SaveAreaView>
  )
}

BusinessBankAccount.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      hasBookingPending: PropTypes.bool
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  route: PropTypes.shape({ params: PropTypes.shape({ hasBookingPending: PropTypes.bool }) }),
  applyDiscountCoupon: PropTypes.func.isRequired,
  applyCodeResponse: PropTypes.shape({ description: PropTypes.string })
}

BusinessBankAccount.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null,
  applyCodeResponse: {}
}

const mapStateToProps = ({ User }) => ({
  applyCodeResponse: User.applyCodeResponse
})

const mapDispatchToProps = {
  applyDiscountCoupon: applyCoupon
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessBankAccount)
