import axiosInstance from '../../utils/axios';
import {
  GET_PAYMENTS,
  GET_NEXT_PAYMENTS,
  CREATE_PDF,
  FETCHING_PAYMENTS,
  SERVER_ERROR,
  SET_DETAILS,
  SET_IBAN,
  PAYMENT_PAID,
  PAYMENT_METHODS,
  GET_OUTSTANDING_PAYMENTS
} from '../types';

const logError = (error, dispatch) => {
  console.log(error);
  if (error.response && error.response.status === 500) {
    dispatch({ type: SERVER_ERROR });
  }
};

export const getPayments = (amountPerPage = 10, year) => (dispatch) => {
  dispatch({ type: FETCHING_PAYMENTS });
  axiosInstance
    .get(`payments/${amountPerPage}${year ? `/${year}` : ''}`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: GET_PAYMENTS,
          payload: response.data.data
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};

export const getNextPayments = (nextUrlPage) => (dispatch) => {
  axiosInstance
    .get(nextUrlPage)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: GET_NEXT_PAYMENTS,
          payload: response.data.data
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};

export const getCreatePdfs = (ids) => (dispatch) => {
  axiosInstance
    .post('payments/pdf', { payment_ids: ids })
    .then((response) => {
      if (response.data) {
        dispatch({
          type: CREATE_PDF,
          payload: response.data
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};

export const getOutstandingPayments = (reference) => (dispatch) => {
  axiosInstance
    .get(`payments/outstanding/${reference}`)
    .then((response) => {
      if (response.data) {
        dispatch({
          type: GET_OUTSTANDING_PAYMENTS,
          payload: response.data.data
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};

export const setPaymentDetails = (details) => (dispatch) => {
  axiosInstance
    .post('payments/details', { ...details })
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: SET_DETAILS,
          payload: data
        });
      }
    })
    .catch((error) => logError(error, dispatch));
};

export const setPaymentIBAN = (iban) => (dispatch) => {
  axiosInstance
    .post('payments/iban', { iban })
    .then(({ data }) => {
      if (data) {
        dispatch({ type: SET_IBAN });
      }
    })
    .catch((error) => logError(error, dispatch));
};

export const payoutDirectly = (payment_id) => (dispatch) => {
  axiosInstance
    .post('payments/instacash', { payment_id })
    .then(({ data }) => {
      if (data) {
        dispatch({ type: PAYMENT_PAID, payload: data.data });
      }
    })
    .catch((error) => logError(error, dispatch));
};

export const getAvailablePaymentMethods = (membership_type, channel) => (dispatch) => {
  axiosInstance
    .post('v2/memberships/subscription/payment_methods', { membership_type, channel })
    .then(({ data }) => {
      if (data) {
        dispatch({ type: PAYMENT_METHODS, payload: data.data });
      }
    })
    .catch((error) => logError(error, dispatch));
};
