export const USER_FETCHING = 'user_fetching';
export const SET_USER = 'set_user';
export const LOGOUT = 'logout';
export const LOGIN_ERROR = 'login_error';
export const SIGNUP_ERROR = 'signup_error';
export const PASSWORD_RESET_COMPLETED = 'password_reset_completed';
export const PASSWORD_RESET_REQUESTED = 'passwrod_reset_reqeusted';
export const REGISTRATION_VALIDATED = 'registration_validated';
export const SET_VALIDATION_ERRORS = 'set_validation_errors';
export const SET_LOCALE = 'set_locale';
export const SET_INDICATORS = 'set_indicators';
export const CLEAR_VALIDATION_ERRORS = 'clear_validation_errors';
export const SET_VALIDATION_ERROR = 'set_validation_error';
export const SET_HAVE_A_LOOK_AROUND = 'set_have_a_look_around';

export const UPDATING_PROFILE = 'updating_profile';
export const UPDATE_PROFILE_ERROR = 'update_profile_error';

export const UPLOADING_PROFILE_IMAGE = 'uploading_profile_image';
export const UPDATE_PROFILE_IMAGE = 'update_profile_image';
export const VIDEO_UPLOADED = 'video_uploaded';
export const UPDATE_PROFILE_VIDEO = 'uploading_profile_video';

export const TOGGLE_LIKE_ANGEL = 'toggle_like_angel';

export const SET_ONBOARDING_PROGRESS = 'set_onboarding_progress';
export const UPDATING_REFERENCE = 'updating_reference';
export const SAVE_REFERENCE = 'save_reference';
export const SET_PREFERENCES = 'set_preferences';
export const SET_PREFERENCE = 'set_preference';
export const SET_AVAILABILITIES = 'set_availabilities';
export const SET_AVAILABILITY = 'set_availability';
export const SET_WAS_EDITED = 'set_was_edited';

export const SET_MEMBERSHIP = 'set_membership';
export const APPLY_COUPON = 'apply_coupon';
export const DELETE_APPLY_COUPON_RESPONSE = 'delete_apply_coupon_response';
export const UPDATING_MEMBERSHIP = 'updating_membership';
export const UPDATE_MEMBERSHIP = 'update_membership';
export const CANCEL_MEMBERSHIP = 'cancel_membership';
export const GET_MEMBERSHIP_REASONS = 'get_membership_reasons';

export const GET_AGENDAS = 'get_agendas';
export const GET_APPOINTMENT_TIMESLOTS = 'get_appointment_timeslots';
export const GET_APPOINTMENT_TIMESLOTS_ERROR = 'get_appointment_timeslots_error';
export const SET_APPOINTMENT = 'set_appointment';

export const FETCHING_BOOKINGS = 'fetching_bookings';
export const GET_BOOKINGS = 'get_bookings';
export const GET_NEXT_BOOKINGS = 'get_next_bookings';
export const SET_BOOKING_DETAILS = 'set_booking_details';
export const ACCEPT_BOOKING = 'accept_booking';
export const BOOKING_ACCEPTED_ERROR = 'booking_accepted_error';
export const UPDATING_BOOKING_DETAILS = 'updating_booking_details';
export const CHECK_AVAILABILITY = 'check_availability';
export const CHECK_AVAILABILITY_ERROR = 'check_availability_error';

export const FETCHING_INVITATIONS = 'fetching_invitations';
export const GET_ANGEL_INVITATIONS = 'get_angel_invitations';
export const GET_NEXT_ANGEL_INVITATIONS = 'get_next_angel_invitations';
export const SET_INVITATION_DETAILS = 'set_invitation_details';
export const UPDATING_INVITATION_DETAILS = 'updating_invitation_details';
export const ACCEPT_INVITATION = 'accept_invitation';
export const INVITATION_ACCEPTED_ERROR = 'invitation_accepted_error';

export const GET_REFERRAL_SETTINGS = 'get_referral_settings';
export const GET_REFERRALS = 'get_referrals';
export const GET_REFERRAL_ALL = 'get_referral_all';
export const GET_REFERRAL_LANDING = 'get_referral_landing';

export const SERVER_ERROR = 'server_error';
export const CLEAR_SERVER_ERROR = 'clear_server_error';
export const FETCHING_RATINGS = 'fetching_ratings';
export const GET_RATINGS = 'get_ratings';
export const GET_NEXT_RATINGS = 'get_next_ratings';
export const UPDATING_RATING = 'updating_rating';
export const UPDATE_RATING = 'update_rating';

export const GET_PAYMENTS = 'get_payments';
export const GET_NEXT_PAYMENTS = 'get_next_payments';
export const CREATE_PDF = 'create_pdf';
export const GET_OUTSTANDING_PAYMENTS = 'get_outstanding_payments';
export const FETCHING_PAYMENTS = 'fetching_payments';

export const SET_DETAILS = 'set_details';
export const SET_IBAN = 'set_iban';
export const PAYMENT_PAID = 'payment_paid';
export const PAYMENT_METHODS = 'payment_methods';

export const FETCHING_FAVORITES = 'fetching_favorites';
export const GET_FAVORITES = 'get_favorites';
export const GET_NEXT_FAVORITES = 'get_next_favorites';
export const GET_SHARE_ANGEL_DETAILS = 'get_share_angel_details';
export const GET_SHARE_ANGEL_RATINGS = 'get_share_angel_ratings';
export const GET_FAMILY_DETAILS = 'get_family_details';
export const FAVORITES_TOGGLE_LIKE = 'favorites_toggle_like';

export const GET_ANGEL_DETAILS = 'get_angel_details';
export const GET_ANGEL_RATINGS = 'get_angel_ratings';
export const GET_AVAILABILITY = 'get_availability';

export const GET_ANGEL_DASHBOARD = 'get_angel_dashboard';
export const GET_FAMILY_DASHBOARD = 'get_family_dashboard';
export const GET_ANGEL_LATEST_REVIEWS = 'get_angel_latest_reviews';
export const GET_AVAILABLE_ANGELS = 'get_available_angels';
export const FETCHING_AVAILABLE_ANGElS = 'fetching_available_angels';
export const GET_STANDBY_ANGELS = 'get_standby_angels';
export const FETCHING_STANDBY_ANGElS = 'fetching_standby_angels';
export const TOGGLE_LIKE_DASHBOARD_ANGELS = 'toggle_like_dashboard_angels';
export const SET_ANNOUNCEMENTS_READ = 'set_announcements_read';

export const FETCHING_BOOK = 'fetching_book';
export const SEARCH_AVAILABLE_ANGELS = 'search_available_angels';
export const SEARCH_AVAILABLE_ANGELS_ERROR = 'search_available_angels_error';
export const GET_BOOK_ANGEL_RATINGS = 'get_book_angel_ratings';
export const TOGGLE_LIKE_BOOK_ANGEL = 'toggle_like_book_angel';
export const SEND_BOOK_REQUEST = 'send_book_request';
export const SEND_BOOK_REQUEST_ERROR = 'set_book_request_error';
export const ADD_ANGELS = 'add_angels';

export const GET_SUPPORT = 'get_support';
export const FETCHING_SUPPORT = 'fetching_support';

export const FETCHING_MESSAGES = 'fetching_messages';
export const GET_MESSAGES = 'get_messages';
export const GET_NEXT_MESSAGES = 'get_next_messages';
export const NEW_MESSAGE = 'new_message';
export const ADDING_MESSAGE = 'adding_message';
export const UPDATE_MESSAGE = 'update_message';
export const CHAT_TYPING_CHANGE = 'chat_typing_change';
export const SET_MESSAGES_READ = 'set_messages_read';
export const UPDATE_CONVERSATION_LAST_MESSAGE = 'update_conversation_last_message';
export const GET_CONVERSATIONS = 'get_conversations';
export const GET_ACTIVE_TIMINGS = 'get_active_timings';
export const GET_NOTIFICATIONS = 'get_notifications';
export const SET_NOTIFICATIONS_READ = 'set_notifications_read';

export const GET_JOBS = 'get_jobs';
export const FETCHING_JOBS = 'fetching_jobs';
export const ADDING_TO_JOBLIST = 'adding_to_joblist';
export const ADD_TO_JOBLIST = 'add_to_joblist';
export const ADD_TO_JOBLIST_ERROR = 'add_to_joblist_error';

export const GET_UPDATED_TIMING = 'get_updated_timing';
export const FETCHING_TIMING = 'fetching_timing';
export const FINISH_BOOKING = 'finish_booking';

export const GET_ANGEL_PUBLIC = 'get_angel_public';
export const ADD_ANGEL_REFERENCE_SUCCESS = 'add_angel_reference_success';
