import { dinero, toFormat } from 'dinero.js'
import * as currencies from '@dinero.js/currencies'
import { i18n } from '../locale'
import moment from 'moment'
import { Money } from '@charlycares/data-access'

export function hideAccountNumber(account_number?: string, char = '\u25CF'): string {
  const accountLastDigits =
    account_number?.slice(account_number.length - 4, account_number.length) || `${char}${char}${char}${char}`

  return `${char}${char}${char}${char} ${char}${char}${char}${char} ${char}${char}${char}${char} ${accountLastDigits}`
}

export const formatMoney = (value: Money | undefined, invertNegative = false) => {
  let currency = currencies['EUR']

  if (value === undefined) {
    return '€-'
  }

  if (value.currency in currencies) {
    currency = currencies[value.currency]
  }

  if (value.amount < 0 && invertNegative) {
    value.amount = -value.amount
  }

  return toFormat(dinero({ amount: value.amount, currency: currency }), ({ amount, currency }) => {
    return amount.toLocaleString(i18n.language.replace('_', '-'), {
      style: 'currency',
      currency: currency.code
    })
  })
}

export const formatInterval = (interval: string | undefined, showDays = true, shortNotation = false): string => {
  if (interval === undefined) {
    return ''
  }

  let formattedInterval = ''
  let seconds = moment.duration(interval).asSeconds()

  if (showDays) {
    const days = Math.floor(seconds / 3600 / 24)
    if (days >= 1) {
      seconds -= days * 24 * 3600

      const daysKey = shortNotation ? 'interval.d' : days > 1 ? 'interval.days' : 'interval.day'
      formattedInterval += i18n.t(daysKey, { day: days }) + ' '
    }
  }

  const hours = Math.floor(seconds / 3600)
  const hoursKey = shortNotation ? 'interval.h' : hours > 1 ? 'interval.hours' : 'interval.hour'
  formattedInterval += i18n.t(hoursKey, { hour: hours }) + ' '
  seconds -= hours * 3600

  const minutes = Math.floor(seconds / 60)
  const minutesKey = shortNotation ? 'interval.m' : minutes > 1 ? 'interval.minutes' : 'interval.minutes'

  return formattedInterval + i18n.t(minutesKey, { minute: minutes })
}

export const formatPhoneNumber = (phone?: string) => {
  if (phone && phone.length) {
    if (phone.charAt(0) === '0') {
      return `+${phone.substring(0, 2)} ${phone.substring(2, phone.length)}`
    }
    return `+${phone.substring(0, 2)} (0)${phone.substring(2, phone.length)}`
  }
  return ''
}

export const concatPhoneCountryPrefix = (phone: string, countryCode: string) => {
  const countryPrefix = countryCode.split(' ')[1].replace(/[()]/g, '')
  return `${countryPrefix}${phone}`
}

export const formatMonetaryValue = (amount: number | string, locale = 'nl') => {
  if (typeof amount === 'string') {
    amount = parseFloat(amount)
  }

  return amount.toLocaleString(locale, {
    style: 'currency',
    currency: 'eur'
  })
}

export const formatRating = (rating: number) => rating.toString().replace(/\./g, ',')

export const getDistance = (distance_in_meters: number) => {
  if (distance_in_meters >= 1000) {
    const km = distance_in_meters / 1000
    const firstDecimal = parseInt(km.toFixed(1).split('.')[1])
    return firstDecimal === 0 ? `${km.toFixed(0)}km` : `${km.toFixed(1)}km`
  }

  return `${Math.round(distance_in_meters)}m`
}

export const calculateBikeTime = (distance: number) => {
  if (!distance) return '0 min'

  const time = (distance / 1000) * 4
  return `${time.toFixed(0)} min`
}

export const capitalize: (string: string) => string = string => string.charAt(0).toUpperCase() + string.slice(1)
