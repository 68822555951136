import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { Text } from '../../elements';
import colors from '../../styles/colors';

const Container = styled.TouchableOpacity`
  padding: 10px;
  ${({ selected }) =>
    selected &&
    `border: ${colors.secondary} solid 0px;
    border-bottom-width: 3px;`};
`;

const Topic = ({ title, onPress, selected }) => (
  <Container onPress={onPress} selected={selected}>
    <Text>{title}</Text>
  </Container>
);

Topic.propTypes = {
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired
};

export default Topic;
