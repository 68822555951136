import React, { useState, useEffect, useRef } from 'react'
import { Keyboard } from 'react-native'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import { validateRegistration, setValidationErrors } from '../../stores/actions/userAction'
import { Text } from '../../elements'
import {
  TextButton,
  TextButtonTextSwitch,
  LabeledInput,
  EmailPasswordEdit,
  PhoneNumberEdit,
  Header
} from '../../components'
import { getAllCountryCodes } from '../../utils/functions'
import colors from '../../styles/colors'
import { getCityLaunch, getGoogleClientId } from '@charlycares/shared'

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  justify-content: space-between;
  overflow: hidden;
`
const ScrollView = styled.ScrollView`
  flex: 1;
  padding-top: 20px;
  padding-bottom: 20px;
`
const View = styled.View`
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  margin: ${({ margins }) => margins || '0px 20px 20px 20px'};
  ${({ width }) => width && { width }};
`

const Step1 = ({
  location,
  history,
  navigation,
  registrationValidated,
  validationErrors,
  validateRegistrationData,
  setValidationErrorsData,
  setLoading
}) => {
  const { role } = useParams()
  const { shareAngelId, referral } = location.state || {}
  const [registrationData, setRegistrationData] = useState({
    firstName: '',
    lastName: '',
    postalCode: '',
    streetNumber: '',
    countryCode: 'NL (+31)',
    phoneNumber: '',
    email: '',
    password: ''
  })
  const [hidePassword, setHidePassword] = useState(true)
  const [inputFocused, setInputFocused] = useState(null)
  const [termsAndCondition, setTermsAndCondition] = useState(false)
  const [privacyPolicy, setPrivacyPolicy] = useState(false)
  const scrollViewRef = useRef(null)
  const firstNameInputRef = useRef(null)
  const surnameInputRef = useRef(null)
  const postalCodeInputRef = useRef(null)
  const streetNumberInputRef = useRef(null)
  const phoneNumberInputRef = useRef(null)
  const emailInputRef = useRef(null)
  const passwordInputRef = useRef(null)
  const pickerRef = useRef(null)
  const [t, i18n] = useTranslation()

  const { firstName, lastName, postalCode, streetNumber, countryCode, phoneNumber, email, password } = registrationData
  const loginDisabled =
    postalCode === '' ||
    streetNumber === '' ||
    countryCode === '' ||
    phoneNumber === '' ||
    email === '' ||
    password === '' ||
    !termsAndCondition ||
    !privacyPolicy
  const locale = i18n.language

  const goToStep2 = () => {
    const user = {
      first_name: firstName,
      last_name: lastName,
      postalcode: postalCode,
      street_number: streetNumber,
      phone: `${countryCode.slice(5, countryCode.length - 1)}${
        phoneNumber.charAt(0) === '0' ? phoneNumber.substr(1) : phoneNumber
      }`,
      email,
      password,
      referral,
      google_client_id: getGoogleClientId(),
      campaign: getCityLaunch()
    }

    if (navigation) {
      navigation.navigate(role === 'angel' ? 'SignUpAngelStep2' : 'SignUpParentStep2', {
        step1Data: user,
        shareAngelId
      })
    } else {
      history.push({ pathname: `/signup-${role}`, state: { step1Data: user } })
    }
  }

  const validate = () => {
    const dataToValidate = {
      role,
      postalcode: postalCode,
      email,
      password,
      phone: `${countryCode.slice(5, countryCode.length - 1)}${phoneNumber}`
    }
    validateRegistrationData(dataToValidate)
  }

  useEffect(() => {
    if (validationErrors) {
      if (validationErrors.outside_area) {
        if (navigation) {
          navigation.navigate('SignUpOutsideServce')
        } else {
          history.push('/outside-service')
        }
      }
    }
  }, [validationErrors])

  useEffect(() => {
    if (registrationValidated) {
      goToStep2()
    }
  }, [registrationValidated])

  useEffect(() => {
    setLoading(false)
    setValidationErrorsData()
  }, [])

  return (
    <SafeAreaView>
      {!navigation && (
        <Header
          title={role === 'angel' ? t('signUpScreensAngelSignup') : t('signupScreensFamilySignup')}
          right={<TextButton text={t('login')} textColor={colors.secondary} onPress={() => history.push('/login')} />}
        />
      )}

      <ScrollView ref={scrollViewRef} contentContainerStyle={{ flexGrow: 1 }}>
        <Text margins="20px" fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('signUpScreensCreateAccount')}
        </Text>

        <LabeledInput
          inputRef={firstNameInputRef}
          margins="15px 20px 20px 20px"
          paddings={validationErrors && validationErrors.first_name ? '0px 0px 0px 5px' : '0px'}
          borderLeftWidth={validationErrors && validationErrors.first_name ? '4px' : 0}
          borderColor={colors.secondary}
          backgroundColor={colors.white}
          inputBorderColor={
            inputFocused && inputFocused.firstName ? colors.secondary : colors.defaultBtnBackgroundColor
          }
          labelText={t('firstName')}
          inputMargins="5px 0px 0px 0px"
          inputPaddings="0px 0px 0px 5px"
          validationMessage={validationErrors && validationErrors.first_name}
          value={firstName}
          onChangeText={value => setRegistrationData({ ...registrationData, firstName: value })}
          onFocus={() => setInputFocused({ firstName: firstNameInputRef.current.isFocused() })}
          onSubmitEditing={() => surnameInputRef.current.focus()}
          autoCapitalize="words"
        />

        <LabeledInput
          inputRef={surnameInputRef}
          margins="0px 20px 20px 20px"
          paddings={validationErrors && validationErrors.last_name ? '0px 0px 0px 5px' : '0px'}
          borderLeftWidth={validationErrors && validationErrors.last_name ? '4px' : 0}
          borderColor={colors.secondary}
          backgroundColor={colors.white}
          inputBorderColor={inputFocused && inputFocused.lastName ? colors.secondary : colors.defaultBtnBackgroundColor}
          labelText={t('surname')}
          inputMargins="5px 0px 0px 0px"
          inputPaddings="0px 0px 0px 5px"
          validationMessage={validationErrors && validationErrors.last_name}
          value={lastName}
          onChangeText={value => setRegistrationData({ ...registrationData, lastName: value })}
          onFocus={() => setInputFocused({ lastName: surnameInputRef.current.isFocused() })}
          onSubmitEditing={() => postalCodeInputRef.current.focus()}
          autoCapitalize="words"
        />

        <View>
          <LabeledInput
            inputRef={postalCodeInputRef}
            paddings={validationErrors && validationErrors.postalcode ? '0px 0px 0px 5px' : '0px'}
            width="55%"
            borderLeftWidth={validationErrors && validationErrors.postalcode ? '4px' : 0}
            borderColor={colors.secondary}
            backgroundColor={colors.white}
            inputBorderColor={
              inputFocused && inputFocused.postalCode ? colors.secondary : colors.defaultBtnBackgroundColor
            }
            labelText={t('postalCode')}
            inputMargins="5px 0px 0px 0px"
            inputPaddings="0px 0px 0px 5px"
            validationMessage={validationErrors && validationErrors.postalcode}
            value={postalCode}
            onChangeText={value => setRegistrationData({ ...registrationData, postalCode: value.trim() })}
            onFocus={() => setInputFocused({ postalCode: postalCodeInputRef.current.isFocused() })}
            onSubmitEditing={() => streetNumberInputRef.current.focus()}
            autoCapitalize="characters"
          />

          <LabeledInput
            inputRef={streetNumberInputRef}
            flex={1}
            margins="0px 0px 0px 20px"
            paddings={validationErrors && validationErrors.street_number ? '0px 0px 0px 5px' : '0px'}
            borderLeftWidth={validationErrors && validationErrors.street_number ? '4px' : 0}
            borderColor={colors.secondary}
            backgroundColor={colors.white}
            inputBorderColor={
              inputFocused && inputFocused.streetNumber ? colors.secondary : colors.defaultBtnBackgroundColor
            }
            labelText={t('streetNumber')}
            inputMargins="5px 0px 0px 0px"
            inputPaddings="0px 0px 0px 5px"
            validationMessage={validationErrors && validationErrors.street_number}
            value={streetNumber}
            onChangeText={value => setRegistrationData({ ...registrationData, streetNumber: value })}
            onFocus={() => setInputFocused({ streetNumber: streetNumberInputRef.current.isFocused() })}
            onSubmitEditing={() => {}}
          />
        </View>

        <PhoneNumberEdit
          validationErrors={validationErrors}
          inputFocused={inputFocused}
          countryCode={countryCode}
          countryCodeOptions={getAllCountryCodes()}
          onCountryCodePress={() => {
            setInputFocused({ countryCode: true })
            pickerRef.current.setIsVisible(true)
          }}
          onCountryCodeChange={code => {
            setRegistrationData({ ...registrationData, countryCode: code.value })
          }}
          phoneNumberRef={phoneNumberInputRef}
          phoneNumber={phoneNumber}
          onPhoneNumberChangeText={value => setRegistrationData({ ...registrationData, phoneNumber: value })}
          onPhoneNumberFocus={() => setInputFocused({ phoneNumber: phoneNumberInputRef.current.isFocused() })}
          onPhoneNumberSubmitEditing={() => emailInputRef.current.focus()}
        />

        <EmailPasswordEdit
          showTitle={false}
          highlightInputBackground={false}
          validationErrors={validationErrors}
          email={email}
          password={password}
          inputFocused={inputFocused}
          returnKeyTypeEmail="next"
          returnKeyTypePassword="done"
          emailInputRef={emailInputRef}
          onEmailChangeText={value => setRegistrationData({ ...registrationData, email: value })}
          onEmailSubmitEditing={() => {
            passwordInputRef.current.focus()
            scrollViewRef.current.scrollToEnd()
          }}
          onEmailFocus={() => setInputFocused({ email: emailInputRef.current.isFocused() })}
          passwordInputRef={passwordInputRef}
          onPasswordChangeText={value => setRegistrationData({ ...registrationData, password: value })}
          onPasswordFocus={() => setInputFocused({ password: passwordInputRef.current.isFocused() })}
          hidePassword={hidePassword}
          toggleHidePasswordPress={() => setHidePassword(!hidePassword)}
          onPasswordSubmitEditing={() => {
            Keyboard.dismiss()
            scrollViewRef.current.scrollToEnd({ animated: true })
          }}
        />

        <TextButtonTextSwitch
          margins="0px 20px 0px 20px"
          firstText={t('signUpScreensIAgreeWith')}
          secondText={t('signUpScreensTermsAndConditions')}
          textButtonOnPress={() => {
            if (navigation) {
              navigation.navigate('WebView', {
                uri: locale.includes('en')
                  ? 'https://www.charlycares.com/en/babysitter-agreement'
                  : 'https://www.charlycares.com/gebruikersovereenkomst'
              })
            } else {
              window.open(
                locale.includes('en')
                  ? 'https://www.charlycares.com/en/babysitter-agreement'
                  : 'https://www.charlycares.com/gebruikersovereenkomst'
              )
            }
          }}
          switchValue={termsAndCondition}
          switchOnValueChange={setTermsAndCondition}
        />

        <TextButtonTextSwitch
          margins="5px 20px 0px 20px"
          firstText={t('signUpScreensIAgreeWith')}
          secondText={t('signUpScreensPrivacyPolicy')}
          textButtonOnPress={() => {
            if (navigation) {
              navigation.navigate('WebView', {
                uri: locale.includes('en')
                  ? 'https://www.charlycares.com/en/privacy'
                  : 'https://www.charlycares.com/privacy'
              })
            } else {
              window.open(
                locale.includes('en') ? 'https://www.charlycares.com/en/privacy' : 'https://www.charlycares.com/privacy'
              )
            }
          }}
          switchValue={privacyPolicy}
          switchOnValueChange={setPrivacyPolicy}
        />
      </ScrollView>

      <TextButton
        disabled={loginDisabled}
        margins="20px"
        backgroundColor={loginDisabled ? colors.defaultBtnBackgroundColor : colors.primary}
        text={t('signup')}
        textColor={loginDisabled ? colors.defaultBtnTextColor : colors.white}
        onPress={validate}
      />
    </SafeAreaView>
  )
}

Step1.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      role: PropTypes.string,
      shareAngelId: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  route: PropTypes.shape({
    params: PropTypes.shape({ role: PropTypes.string, shareAngelId: PropTypes.string })
  }),
  navigation: PropTypes.shape({
    navigate: PropTypes.func,
    setOptions: PropTypes.func,
    addListener: PropTypes.func
  }),
  registrationValidated: PropTypes.bool,
  validationErrors: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    postalcode: PropTypes.string,
    street_number: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    outside_area: PropTypes.bool
  }),
  validateRegistrationData: PropTypes.func.isRequired,
  setValidationErrorsData: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired
}

Step1.defaultProps = {
  location: {},
  history: null,
  route: {},
  navigation: null,
  registrationValidated: false,
  validationErrors: null
}

const mapStateToProps = ({ User }) => ({
  validationErrors: User.validationErrors,
  registrationValidated: User.registrationValidated
})

const mapDispatchToProps = {
  validateRegistrationData: validateRegistration,
  setValidationErrorsData: setValidationErrors
}

export default connect(mapStateToProps, mapDispatchToProps)(Step1)
