import React from 'react'
import { Box, Heading, HStack, Pressable, Skeleton, Spacer, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import { useGetBookingCancellationRateQuery } from '@charlycares/data-access'
import { BookingCancellationReasonType, formatMoney, IBookingDetails, links, useRouter } from '@charlycares/shared'
import { CharlyIcon } from '@charlycares/ui'

type Props = {
  booking: IBookingDetails
  cancellationReason: BookingCancellationReasonType
}

export default function FamilyBookingCancellationRate({ booking, cancellationReason }: Props) {
  const { t, i18n } = useTranslation()
  const { navigate } = useRouter()

  const policyLink = links.cancellation_policy.family[
    i18n.language.split('_')[0] as keyof typeof links.cancellation_policy.family
  ] as string

  const { isLoading, data: bookingCancellationRate } = useGetBookingCancellationRateQuery({
    booking_obscured_id: booking.obscured_id,
    cancellation_reason: cancellationReason
  })

  return (
    <Box mb="10px" w="100%" bg="white" p="20px" borderColor="gray.200" borderWidth="1px">
      <Heading color="gray.900" fontSize="18px" mt="10px" mb="15px">
        {t('cancellationConsequencesTitle')}
      </Heading>
      {isLoading &&
        Array.from({ length: 3 }).map((_, index) => (
          <HStack alignItems="center" mt="10px" py="10px" key={index}>
            <Skeleton h="3" w="100%" rounded="full" startColor="gray.400" />
          </HStack>
        ))}

      {bookingCancellationRate && (
        <Box w="100%" mt="10px" p="16px" bgColor="gray.100" borderRadius="5px">
          <HStack alignItems="center" mb="8px">
            <Text color="gray.900" fontSize="16px" fontWeight="600" mr="8px">
              Cancellation
            </Text>
            <Text color="primary.400" fontSize="15px" fontWeight="600">
              {t(bookingCancellationRate.cancellation_policy_type)}
            </Text>
          </HStack>
          <HStack alignItems="center" mb="8px">
            <Text color="gray.800" fontSize="14px">
              {t('compensationFor', { angel: booking.angel?.first_name || 'angel' })}
            </Text>
            <Spacer />
            <Text color="gray.900" fontWeight="600">
              {formatMoney(bookingCancellationRate.penalty_amount)}
            </Text>
          </HStack>
          <HStack alignItems="center">
            <Text color="gray.800" fontSize="14px">
              {t('serviceCosts')} Charly Cares
            </Text>
            <Spacer />
            <Text color="gray.900" fontSize="14px" fontWeight="600">
              {formatMoney(bookingCancellationRate.service_fee)}
            </Text>
          </HStack>
        </Box>
      )}

      <Pressable onPress={() => navigate('WebView', undefined, { uri: policyLink })}>
        <HStack mt="15px" alignItems="center">
          <Text color="blue.400" fontSize="14px" ml="auto" mr="4px">
            {t('readCancellationConsequencesPolicy')}
          </Text>
          <CharlyIcon name="icn-small-info" size={15} color="blue.400" />
        </HStack>
      </Pressable>
    </Box>
  )
}
