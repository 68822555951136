import React, { useState } from 'react'
import { HStack, IconButton, useTheme } from 'native-base'
import { CharlyIcon } from "@charlycares/ui";

export default function Rating({
  defaultRating,
  onChange
}: {
  defaultRating: 1 | 2 | 3 | 4 | 5 | undefined
  onChange: (rating: 1 | 2 | 3 | 4 | 5) => void
}) {
  const { colors } = useTheme();
  const [rating, setRating] = useState<1 | 2 | 3 | 4 | 5 | undefined>(defaultRating)
  const ratingsArray = new Array(5).fill(false).map((value, index) => {
    if (!rating) {
      return false
    }

    return index < rating
  })

  const updateRating = (value: 1 | 2 | 3 | 4 | 5) => {
    setRating(value)
    onChange(value)
  }

  return (
    <HStack>
      {ratingsArray.map((item, index) => (
        <IconButton
          key={index.toString()}
          icon={<CharlyIcon name="star" color={item ? colors.gray[900] : colors.gray[600]} size={28} />}
          onPress={() => updateRating((index + 1) as 1 | 2 | 3 | 4 | 5)}
          height="30px"
        />
      ))}
    </HStack>
  )
}
