import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const VacationIcon = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 77 46" fill="none" {...props}>
    <Path
      d="M30.469 1.32a1.32 1.32 0 112.64 0v2.742h10.868a5.383 5.383 0 015.382 5.383v15.64h-2.64V9.446a2.742 2.742 0 00-2.742-2.742H33.109v2.742a1.32 1.32 0 11-2.64 0V6.703H18.89v2.742a1.32 1.32 0 11-2.641 0V6.703H5.383A2.742 2.742 0 002.64 9.445v30.47a2.742 2.742 0 002.742 2.741h23.36v2.64H5.382A5.383 5.383 0 010 39.915V9.445a5.383 5.383 0 015.383-5.383H16.25V1.32a1.32 1.32 0 112.64 0v2.742H30.47V1.32z"
      fill="#97979A"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.508 28.438c.965 0 1.805.538 2.234 1.332v6.07a2.539 2.539 0 01-2.234 1.332h-3.656a2.54 2.54 0 01-2.54-2.54v-3.655a2.54 2.54 0 012.54-2.54h3.656zm-.102 6.093h-3.453v-3.453h3.453v3.453zM10.664 16.25h3.656a2.54 2.54 0 012.54 2.54v3.655a2.54 2.54 0 01-2.54 2.54h-3.656a2.54 2.54 0 01-2.539-2.54V18.79a2.54 2.54 0 012.54-2.539zm3.555 6.094h-3.453V18.89h3.453v3.453zM10.664 28.438h3.656a2.54 2.54 0 012.54 2.539v3.656a2.54 2.54 0 01-2.54 2.539h-3.656a2.54 2.54 0 01-2.539-2.54v-3.655a2.54 2.54 0 012.54-2.54zm3.555 6.093h-3.453v-3.453h3.453v3.453zM22.852 16.25h3.656a2.54 2.54 0 012.539 2.54v3.655a2.54 2.54 0 01-2.54 2.54h-3.655a2.54 2.54 0 01-2.54-2.54V18.79a2.54 2.54 0 012.54-2.539zm3.554 6.094h-3.453V18.89h3.453v3.453zM35.04 16.25h3.655a2.54 2.54 0 012.54 2.54v3.655a2.54 2.54 0 01-2.54 2.54H35.04a2.54 2.54 0 01-2.539-2.54V18.79a2.54 2.54 0 012.54-2.539zm3.554 6.094H35.14V18.89h3.453v3.453z"
      fill="#97979A"
    />
    <Path
      d="M57.18 31.164c1.833-5.333 5.3-12.8 10.5-18m-33 30.5s7-12.5 18.5-12.5 14.5 12.5 14.5 12.5h-33z"
      stroke="#0B5394"
      strokeWidth={2.8}
      strokeLinejoin="round"
    />
    <Path
      d="M67.68 12.164c5.5 0 8.7 5.3 7.5 10.5M67.68 12.164c0-3.5-9-6-11.5-1.5M67.68 12.164c-3-2-7-1-9.5 3.5M67.68 12.664c3.5 2 2.5 8.5-1 11.5"
      stroke="#0B5394"
      strokeWidth={2.8}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </Svg>
)

export default VacationIcon
