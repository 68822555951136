import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'

import { Text } from '../../elements'
import { TextView } from '../Texts'
import { IconButton } from '../Buttons'
import colors from '../../styles/colors'
import { roundMonetaryValue } from '../../utils/functions'

const View = styled.View`
  flex-direction: row;
  align-items: center;
`

const PickMaxRate = ({ enabled, value, onValueChange }) => {
  const [t] = useTranslation()

  return (
    <TextView
      justifyContent="space-between"
      paddings="10px 20px 20px 20px"
      text={t('bookScreensMaximumHourlyRate')}
      fontWeight="300"
      opacity={enabled ? 1 : 0.25}
    >
      <View margins="0px">
        <IconButton
          disabled={!enabled}
          iconName="btn-minus"
          iconSize="30px"
          iconColor={colors.primary}
          onPress={() => {
            onValueChange(-0.25)
          }}
        />

        <Text margins="0px 10px 0px 10px" fontSize="18px">
          {`€ ${roundMonetaryValue(value)}`}
        </Text>

        <IconButton
          disabled={!enabled}
          iconName="btn-plus"
          iconSize="30px"
          iconColor={colors.primary}
          onPress={() => {
            onValueChange(0.25)
          }}
        />
      </View>
    </TextView>
  )
}

PickMaxRate.propTypes = {
  enabled: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
  onValueChange: PropTypes.func.isRequired
}

export default PickMaxRate
