import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { Text, Icon } from '../../elements';
import { getFormattedChildrenAge } from '../../utils/functions';
import colors from '../../styles/colors';

import childIcon from '../../../assets/icons/icn-kids.png';

const Container = styled.View`
  padding-top: 10px;
  padding-bottom: 10px;
`;
const KidsContainer = styled.View``;
const Kid = styled.View`
  flex-direction: row;
  margin-top: 15px;
`;

const Kids = ({ kids }) => {
  const [t] = useTranslation();

  return (
    <Container>
      <Text fontFamily="Martel" fontSize="16px">
        {t('children')}
      </Text>

      <KidsContainer>
        {kids.map(({ id, birthdate }) => (
          <Kid key={id}>
            <Icon margins="0px 10px 0px 0px" width="32px" height="32px" source={childIcon} />

            <Text margins="5px 0px 0px 0px" color={colors.primaryText}>
              {getFormattedChildrenAge(birthdate, t)}
            </Text>
          </Kid>
        ))}
      </KidsContainer>
    </Container>
  );
};

Kids.propTypes = {
  kids: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, birthdate: PropTypes.string }))
    .isRequired
};

export default Kids;
