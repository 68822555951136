import React, { useEffect, useRef, useState } from 'react'
import { Keyboard, Linking, TextInput as TextInputType } from 'react-native'
import { Box, Heading, HStack, Switch, Text, useTheme, useToast } from 'native-base'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Card, Icon, TextInput, ScreenLayout, DatePicker, Select, PlatformScrollView } from '@charlycares/ui'
import {
  countryCodes,
  useRouter,
  MIN_AGE_REQUIRED,
  concatPhoneCountryPrefix,
  links,
  UserRoles,
  getGoogleClientId,
  getCityLaunch
} from '@charlycares/shared'
import {
  useAngelSignUpMutation,
  useAppSelector,
  useFamilySignUpMutation,
  useGetLandingScreensQuery,
  useGetUserProfileMutation
} from '@charlycares/data-access'

export default function SignUpScreen() {
  const { t, i18n } = useTranslation()
  const { navigate, getParam, routeParams } = useRouter()
  const toast = useToast()
  const { colors } = useTheme()

  const role = getParam('role') ?? routeParams?.role ?? UserRoles.FAMILY
  const cityLaunch = getParam('cityLaunch')
  const { referrer_id, campaign_id } = getParam('referral') ?? { referrer_id: null, campaign_id: null }
  const serviceGroupTypes = getParam('serviceGroupTypes', null)

  const user = useAppSelector(state => state.User.user)

  const [terms, setTerms] = useState(false)
  const [privacy, setPrivacy] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const [angelSignUp, angelSignUpState] = useAngelSignUpMutation()
  const [familySignUp, familySignUpState] = useFamilySignUpMutation()
  const signUpState = role === UserRoles.ANGEL ? angelSignUpState : familySignUpState
  const [getUserProfile, profileState] = useGetUserProfileMutation()
  const { data: screen, isSuccess: screenLoadedSuccessfully } = useGetLandingScreensQuery()
  const signUp = role === UserRoles.ANGEL ? angelSignUp : familySignUp

  const lastNameRef = useRef<TextInputType>()
  const postalCodeRef = useRef<TextInputType>()
  const streetNumberRef = useRef<TextInputType>()
  const phoneRef = useRef<TextInputType>()
  const dateOfBirthRef = useRef<TextInputType>()
  const emailRef = useRef<TextInputType>()
  const passwordRef = useRef<TextInputType>()
  const scrollRef = useRef<PlatformScrollView>()

  const serverError =
    role === UserRoles.ANGEL
      ? (angelSignUpState.error as any)?.data.errors
      : (familySignUpState.error as any)?.data.errors
  const minBirthDate = moment().subtract(99, 'years')
  const maxBirthDate = moment().subtract(MIN_AGE_REQUIRED, 'years')
  const termsLink = links.terms[i18n.language.split('_')[0] as keyof typeof links.terms]
  const privacyLink = links.privacy[i18n.language.split('_')[0] as keyof typeof links.privacy]

  const ValidationSchema = Yup.object().shape({
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    postal_code: Yup.string().required(),
    street_number: Yup.string().required(),
    phone: Yup.string().required(),
    date_of_birth: role === UserRoles.ANGEL ? Yup.string().required() : Yup.string(),
    country: Yup.string(),
    email: Yup.string().email().required(),
    password: Yup.string().min(6).required()
  })

  const { handleSubmit, handleChange, setFieldValue, errors, values } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      postal_code: '',
      street_number: '',
      phone: '',
      date_of_birth: '',
      email: '',
      password: '',
      country: countryCodes[0]
    },
    onSubmit: values => {
      signUp({
        ...values,
        phone: concatPhoneCountryPrefix(values.phone, values.country),
        locale: i18n.language,
        google_client_id: getGoogleClientId(),
        campaign: cityLaunch ?? getCityLaunch(),
        referral: referrer_id && campaign_id ? { referrer_id, campaign_id } : undefined,
        referral_code: getParam('referralCode', null),
        service_group_types: serviceGroupTypes
      })
    },
    validationSchema: ValidationSchema,
    validateOnChange: false
  })

  useEffect(() => {
    if (angelSignUpState.isError || familySignUpState.isError) {
      toast.show({
        description: t('signUpError')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [angelSignUpState.isError, familySignUpState.isError, t])

  useEffect(() => {
    if (signUpState.isSuccess) {
      getUserProfile()
    }
  }, [signUpState.isSuccess, getUserProfile])

  useEffect(() => {
    if (user?.id && screenLoadedSuccessfully && screen && screen.current_screen.screen_name !== 'SignUpScreen') {
      navigate(screen.current_screen.screen_name, screen.current_screen.web_path)
    }
  }, [profileState.isSuccess, user, navigate, screenLoadedSuccessfully, screen])

  return (
    <ScreenLayout
      scrollRef={scrollRef as any}
      title={t(role === UserRoles.ANGEL ? 'signUpScreensAngelSignup' : 'signUpScreensFamilySignup')}
      _buttonProps={{
        onPress: () => handleSubmit(),
        isDisabled: !terms || !privacy,
        isLoading: angelSignUpState.isLoading || familySignUpState.isLoading,
        children: t('signup')
      }}
    >
      <Card mt="10px">
        <Box _web={{ maxW: '700px', margin: 'auto', w: '100%' }}>
          <Heading mb="20px" fontSize={'18px'} fontWeight={600}>
            {t('signUpScreensCreateAccount')}
          </Heading>

          <TextInput
            mb="10px"
            value={values.first_name}
            error={errors.first_name || serverError?.first_name?.[0]}
            onChangeText={handleChange('first_name')}
            label={t('firstName')}
            autoCapitalize="words"
            onSubmitEditing={() => lastNameRef.current?.focus()}
            returnKeyType="next"
          />
          <TextInput
            mb="10px"
            ref={lastNameRef}
            value={values.last_name}
            error={errors.last_name || serverError?.last_name?.[0]}
            onChangeText={handleChange('last_name')}
            label={t('surname')}
            autoCapitalize="words"
            onSubmitEditing={() => postalCodeRef.current?.focus()}
            returnKeyType="next"
          />
          <HStack mb="10px" space={'15px'} flexDir={'row'}>
            <TextInput
              ref={postalCodeRef}
              _wrapper={{ flex: 1.5 }}
              label={t('postalCode')}
              value={values.postal_code}
              error={errors.postal_code || serverError?.postal_code?.[0]}
              onChangeText={handleChange('postal_code')}
              onSubmitEditing={() => streetNumberRef.current?.focus()}
              returnKeyType="next"
            />
            <TextInput
              ref={streetNumberRef}
              _wrapper={{ flex: 1 }}
              label={t('streetNumber')}
              value={values.street_number}
              error={errors.street_number || serverError?.street_number?.[0]}
              onChangeText={handleChange('street_number')}
              onSubmitEditing={() => phoneRef.current?.focus()}
              returnKeyType="next"
            />
          </HStack>
          <HStack mb="10px" space={'15px'} flexDir={'row'}>
            <>
              <Select
                _wrapper={{ flex: 1 }}
                label={t('country')}
                selectedValue={values.country ?? countryCodes[0]}
                onValueChange={itemValue => handleChange('country')(itemValue)}
                items={countryCodes}
              />
              <TextInput
                ref={phoneRef}
                _wrapper={{ flex: 2 }}
                label={t('phoneNumber')}
                keyboardType="phone-pad"
                value={values.phone}
                error={errors.phone || serverError?.phone?.[0]}
                onChangeText={handleChange('phone')}
                onSubmitEditing={() =>
                  role === UserRoles.ANGEL ? dateOfBirthRef.current?.focus() : emailRef.current?.focus()
                }
                returnKeyType="next"
              />
            </>
          </HStack>

          {role === UserRoles.ANGEL && (
            <DatePicker
              ref={dateOfBirthRef}
              date={values.date_of_birth ? moment(values.date_of_birth) : undefined}
              minDate={minBirthDate}
              maxDate={maxBirthDate}
              error={errors.date_of_birth || serverError?.date_of_birth?.[0]}
              label={t('birthdate')}
              onChange={date => setFieldValue('date_of_birth', date.format('YYYY-MM-DD'), false)}
              nextField={emailRef}
              prevField={phoneRef}
            />
          )}

          <TextInput
            mb="10px"
            ref={emailRef}
            autoCapitalize="none"
            label={t('emailAddress')}
            value={values.email}
            error={errors.email || serverError?.email?.[0]}
            onChangeText={handleChange('email')}
            keyboardType="email-address"
            onSubmitEditing={() => passwordRef.current?.focus()}
            returnKeyType="next"
          />
          <TextInput
            mb="10px"
            ref={passwordRef}
            autoCapitalize={'none'}
            label={t('password')}
            secureTextEntry={!showPassword}
            value={values.password}
            error={errors.password || serverError?.password?.[0]}
            onChangeText={handleChange('password')}
            letterSpacing="2px"
            onSubmitEditing={() => {
              Keyboard.dismiss()
              scrollRef.current?.scrollToEnd()
            }}
            returnKeyType="done"
            InputRightElement={
              <Icon
                onPress={() => setShowPassword(!showPassword)}
                name={showPassword ? 'eye-open' : 'eye-shut'}
                size={25}
                color={colors.primary['400']}
              />
            }
          />

          <Box flexDir="row" alignItems="center" w="100%">
            <Text fontSize="14px" flex={1}>
              {t('signUpScreensIAgreeWith')}{' '}
              <Text onPress={() => Linking.openURL(termsLink)} color="primary.400">
                {t('signUpScreensTermsAndConditions')}
              </Text>
            </Text>
            <Switch
              size="md"
              value={terms}
              onToggle={() => {
                Keyboard.dismiss()
                setTerms(prev => !prev)
              }}
            />
          </Box>

          <Box mt="10px" flexDir="row" alignItems="center" w="100%">
            <Text fontSize="14px" flex={1}>
              {t('signUpScreensIAgreeWith')}{' '}
              <Text onPress={() => Linking.openURL(privacyLink)} color="primary.400">
                {t('signUpScreensPrivacyPolicy')}
              </Text>
            </Text>
            <Switch
              size="md"
              value={privacy}
              onToggle={() => {
                Keyboard.dismiss()
                setPrivacy(prev => !prev)
              }}
            />
          </Box>
        </Box>
      </Card>
    </ScreenLayout>
  )
}

SignUpScreen.navigationOptions = {
  headerShown: false
}
