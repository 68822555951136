import React from 'react'
import { Box, Button, Flex, Heading, HStack, Text, VStack } from 'native-base'
import { useTranslation } from 'react-i18next'
import { CharlyIcon, iconsMap, LandingLayout } from '@charlycares/ui'
import { formatMoney, getMembershipTrialPlan, MEMBERSHIP_TYPES, MembershipsName, useRouter } from '@charlycares/shared'
import { useGetAvailableMembershipsQuery } from '@charlycares/data-access'
import moment from 'moment'

export default function ActivateTrialMembershipInfoScreen() {
  const { navigate } = useRouter()
  const { t } = useTranslation()

  const { data: availableMemberships } = useGetAvailableMembershipsQuery()

  const membershipName = availableMemberships
    ? (Object.keys(availableMemberships)[0] as MembershipsName)
    : ('full_service' as MembershipsName)
  const membershipType = getMembershipTrialPlan(membershipName)
  const days = moment.duration(availableMemberships?.[membershipName].trial?.interval).days()
  const price = formatMoney(availableMemberships?.[membershipName].monthly.costs)
  const feePrice = formatMoney(availableMemberships?.[membershipName].monthly.fee_costs)
  const insurancePrice = formatMoney(availableMemberships?.[membershipName].monthly.insurance_costs)
  const usps = (): { text: string; icon: keyof typeof iconsMap; color: string }[] => {
    if (membershipName === 'pet_service') {
      return [
        {
          text: t('activateTrialMembershipInfoPetService.usp1'),
          icon: 'icn-check',
          color: 'primary.300'
        },
        {
          text: t('activateTrialMembershipInfoPetService.usp2'),
          icon: 'icn-check',
          color: 'primary.300'
        }
      ]
    }

    return [
      {
        text: t('activateTrialMembershipInfoFullService.usp1'),
        icon: 'icn-check',
        color: 'primary.300'
      },
      {
        text: t('activateTrialMembershipInfoFullService.usp2'),
        icon: 'icn-check',
        color: 'primary.300'
      }
    ]
  }

  return (
    <LandingLayout
      withGoBack
      withLogo
      BottomComponent={
        <Box w="100%" p="20px" mt="auto">
          <Button
            onPress={() =>
              navigate('LinkBankAccountScreen', '/link-bank-account', {
                membershipType: membershipType
              })
            }
            bgColor="white"
            _text={{ color: 'secondary.400', fontSize: '16px', fontWeight: 600 }}
          >
            {t('activateTrialMembershipInfoScreen.next')}
          </Button>
        </Box>
      }
    >
      <Box w="100%" px="20px" mt="50px">
        <Heading color="primary.300" fontSize="22px" fontWeight="600">
          {t('activateTrialMembershipInfoScreen.heading', { days })}
        </Heading>
        <Flex direction="row" mt="10px">
          <Text fontSize="16" color="gray.200">
            {t('activateTrialMembershipInfoScreen.subHeading')}
          </Text>
          <Text fontSize="16" color="gray.200" ml="auto">
            {price}
          </Text>
        </Flex>
      </Box>

      <VStack space="20px" p="20px" mt="40px">
        {usps().map(({ text, icon, color }) => (
          <HStack space="10px" key={text}>
            <CharlyIcon name={icon} size="28px" color={color} />
            <Text fontSize="18px" color="white" fontWeight="600" flex={1}>
              {text}
            </Text>
          </HStack>
        ))}
      </VStack>

      <Box w="100%" p="20px" mt="20px">
        <Box p="15px" borderRadius="8px" bgColor="secondary.alpha.30">
          <Text fontSize="14" fontWeight="400" color="gray.100">
            {membershipName === 'pet_service'
              ? t('activateTrialMembershipPetService.furtherInformation', {
                  days,
                  feePrice,
                  insurancePrice
                })
              : t('activateTrialMembershipFullService.furtherInformation', {
                  days,
                  feePrice,
                  insurancePrice
                })}
          </Text>
        </Box>
      </Box>
    </LandingLayout>
  )
}

ActivateTrialMembershipInfoScreen.navigationOptions = () => ({
  headerShown: false
})
