import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { updateProfile, resetUpdateProfile } from '../../../stores/actions/userAction';
import { Text, Divider } from '../../../elements';
import { TextButton, ProfileLanguagesEdit, KidsEdit, PetsEdit, Header } from '../../../components';
import { isWeb } from '../../../utils/functions';
import colors from '../../../styles/colors';

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  overflow: hidden;
`;
const ScrollView = styled.ScrollView``;
const Border = styled.View`
  width: 100%;
  height: 1px;
  background-color: ${colors.grey08};
`;

const SettingsEdit = ({
  history,
  navigation,
  user,
  updatingProfile,
  profileUpdated,
  updateProfileData,
  resetUpdateProfileData
}) => {
  const { family } = user;
  const { dutch, english, french, german, italian, spanish } = user.languages;
  const [cat, setCat] = useState(family.cat);
  const [dog, setDog] = useState(family.dog);
  const [kids, setKids] = useState(family.kids.map(({ birthdate }) => birthdate));
  const [languages, setLanguages] = useState({ dutch, english, french, german, italian, spanish });
  const [t] = useTranslation();

  const onUpdateProfilePress = () => {
    const dataToUpdate = {
      cat: !!cat,
      dog: !!dog,
      kids,
      languages
    };
    updateProfileData(dataToUpdate);
  };

  if (navigation) {
    navigation.setOptions({
      headerTitle: (
        <Text fontFamily="Martel" fontSize="18px" fontWeight="600">
          {t('edit')}
        </Text>
      ),
      headerRight: (
        <TextButton text={t('save')} textColor={colors.secondary} onPress={onUpdateProfilePress} />
      ),
      headerLeft: (
        <TextButton
          text={t('cancel')}
          textColor={colors.secondary}
          onPress={() => history.goBack()}
        />
      )
    });
  }

  useEffect(() => {
    if (user) {
      resetUpdateProfileData();
    }
  }, [user]);

  useEffect(() => {
    if (profileUpdated && !updatingProfile) {
      if (navigation) {
        navigation.goBack();
      } else {
        history.goBack();
      }
    }
  }, [profileUpdated, updatingProfile]);

  return (
    <SafeAreaView>
      {isWeb && (
        <Header
          left={
            <TextButton text={t('cancel')} textColor={colors.secondary} onPress={history.goBack} />
          }
          title={t('edit')}
          right={
            <TextButton
              text={t('save')}
              textColor={colors.secondary}
              onPress={onUpdateProfilePress}
            />
          }
        />
      )}
      <ScrollView>
        <Divider />

        <KidsEdit
          paddings="0px 5px 0px 5px"
          margins="20px 10px 20px 10px"
          borderLeftWidth={kids.length > 0 ? 0 : '4px'}
          borderBottomWidth={0}
          borderColor={colors.secondary}
          title={t('children').toUpperCase()}
          titleFontFamily="Open Sans"
          titleFontSize="12px"
          titleFontWeight="300"
          titleColor={colors.grey02}
          kids={kids}
          onAddPress={(birthdate) => setKids([...kids, birthdate])}
          onDeletePress={(birthdate) => setKids(kids.filter((kid) => kid !== birthdate))}
        />

        <Border />

        <ProfileLanguagesEdit
          languages={languages}
          valid={!!Object.values(languages).filter((lan) => lan).length}
          onToggleLanguage={(language) => {
            const languagesCopy = { ...languages };
            languagesCopy[`${language}`] = !languagesCopy[`${language}`];

            setLanguages(languagesCopy);
          }}
        />

        <PetsEdit
          title={t('pets').toUpperCase()}
          dog={!!dog}
          cat={!!cat}
          toggleDogPress={() => setDog(!dog)}
          toggleCatPress={() => setCat(!cat)}
        />
      </ScrollView>
    </SafeAreaView>
  );
};

SettingsEdit.propTypes = {
  history: PropTypes.shape({ goBack: PropTypes.func }),
  navigation: PropTypes.shape({ setOptions: PropTypes.func, goBack: PropTypes.func }),
  updateProfileData: PropTypes.func.isRequired,
  resetUpdateProfileData: PropTypes.func.isRequired,
  user: PropTypes.shape({
    family: PropTypes.shape({
      cat: PropTypes.number,
      dog: PropTypes.number,
      kids: PropTypes.arrayOf(PropTypes.string)
    }),
    languages: PropTypes.shape({
      dutch: PropTypes.bool,
      english: PropTypes.bool,
      french: PropTypes.bool,
      german: PropTypes.bool,
      italian: PropTypes.bool,
      spanish: PropTypes.bool
    })
  }).isRequired,
  updatingProfile: PropTypes.bool,
  profileUpdated: PropTypes.bool
};

SettingsEdit.defaultProps = {
  history: null,
  navigation: null,
  updatingProfile: false,
  profileUpdated: false
};

const mapStateToProps = ({ User }) => ({
  user: User.user,
  updatingProfile: User.updatingProfile,
  profileUpdated: User.profileUpdated,
  serverError: User.serverError
});

const mapDispatchToProps = {
  updateProfileData: updateProfile,
  resetUpdateProfileData: resetUpdateProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsEdit);
