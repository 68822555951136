import React, { useState, useEffect } from 'react'
import { Keyboard, ActivityIndicator } from 'react-native'
import PropTypes from 'prop-types'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useLoginMutation } from '@charlycares/data-access'

import { login } from '../../stores/actions/userAction'
import { Heading, Text, Button } from '../../elements'
import { IconButtonLabeledInput, LabeledInput, TextButton } from '../../components'
import theme from '../../styles/theme'
import { getNavigationRouteAndParamsOnLogin } from '../../utils/functions'

import passwordShowIcon from '../../../assets/icons/icn-small-eye-open.png'
import passwordHideIcon from '../../../assets/icons/icn-small-eye-shut.png'
import { Box } from 'native-base'

const SafeAreaView = styled.SafeAreaView`
  flex: 1 1 auto;
  justify-content: center;
  padding: 16px;
  min-height: 100%;
`

const Login = ({ history, navigation, user, setLoading, loginError }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [hidePassword, setHidePassword] = useState(true)
  const [t] = useTranslation()
  const loginDisabled = !email || !password || isLoading
  const [login, { error, isLoading }] = useLoginMutation()

  useEffect(() => {
    setLoading(false)
  }, [])

  useEffect(() => {
    if (user && history) {
      const { pathname, params } = getNavigationRouteAndParamsOnLogin(user)
      history.push({ pathname, state: params })
    }
  }, [user])

  return (
    <Box w="100%" maxW="600px" margin="auto">
      <SafeAreaView>
        <Heading xxl fontFamily={theme.fontFamilyAlt} ariaLevel={2} text={t('login')} />
        <Text lg margins="0px 0px 20px 0px">
          {t('loginScreenLoginWithYourUsername')}
        </Text>
        <LabeledInput
          margins="0px 0px 16px 0px"
          labelText={t('emailAddress')}
          keyboardType="email-address"
          value={email}
          onChangeText={setEmail}
        />
        <IconButtonLabeledInput
          width="100%"
          returnKeyType="done"
          secureTextEntry={hidePassword}
          labelText={t('password')}
          iconSource={hidePassword ? passwordHideIcon : passwordShowIcon}
          value={password}
          onChangeText={setPassword}
          onSubmitEditing={() => {
            Keyboard.dismiss()
            login({ email, password })
          }}
          onIconPress={() => setHidePassword(!hidePassword)}
        />
        {loginError && (
          <Text margins="5px 0px 0px 0px" fontSize="12px" color={theme.colors.alert}>
            {loginError}
          </Text>
        )}

        <Button
          disabled={loginDisabled}
          margins="16px 0px 16px 0px"
          backgroundColor={loginDisabled ? theme.colors.defaultBtnBackgroundColor : theme.colors.primary}
          onPress={() => login({ email, password })}
        >
          {isLoading ? (
            <ActivityIndicator color={theme.colors.white} />
          ) : (
            <Text color={loginDisabled ? theme.colors.defaultBtnTextColor : theme.colors.white}>{t('login')}</Text>
          )}
        </Button>
        <TextButton
          text={t('loginScreenForgotPassword')}
          textColor={theme.colors.secondary}
          onPress={() => {
            if (navigation) {
              navigation.navigate('ForgotPassword')
            } else {
              history.push('/forgot-password')
            }
          }}
        />
      </SafeAreaView>
    </Box>
  )
}

Login.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func, replace: PropTypes.func }),
  navigation: PropTypes.shape({ navigate: PropTypes.func }),
  fetching: PropTypes.bool,
  user: PropTypes.shape({
    role: PropTypes.string,
    has_membership: PropTypes.bool,
    has_pending_bookings: PropTypes.bool,
    membership: PropTypes.shape({
      active_payments: PropTypes.bool,
      current_state: PropTypes.string
    })
  }),
  loginError: PropTypes.string,
  logIn: PropTypes.func.isRequired,
  setLoading: PropTypes.func
}

Login.defaultProps = {
  history: null,
  navigation: null,
  fetching: false,
  user: null,
  loginError: '',
  setLoading: () => {}
}

const mapStateToProps = ({ User }) => ({
  fetching: User.fetching,
  user: User.user,
  loginError: User.loginError
})

const mapDispatchToProps = { logIn: login }

export default connect(mapStateToProps, mapDispatchToProps)(Login)
