import React from 'react';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextTextView } from '../../Texts';
import { IconButton } from '../../Buttons';
import theme from '../../../styles/theme';

import infoIcon from '../../../../assets/icons/icn-small-info.png';

const View = styled.View`
  width: 100%;
  padding: 20px 0px 20px 0px;
  flex-direction: row;
  justify-content: space-between;
`;
const Container = styled.View`
  flex: 1;
`;

const Characteristics = ({
  responseTime,
  languages,
  education,
  fieldOfStudy,
  onInfoPress,
  firstTextWidth
}) => {
  const [t] = useTranslation();

  return (
    <View>
      <Container>
        <TextTextView
          alignItems="flex-start"
          firstText={t('angelProfileScreening')}
          secondText={t('angelProfilePersonallyScreened')}
          secondTextAlign="left"
          justifyContent="flex-start"
          firstTextWidth={firstTextWidth}
          firstTextFontSize={theme.fontSize.md}
          firstTextColor={theme.textColorBase}
          secondTextWidth="50%"
          secondTextNumberOfLines={2}
          secondTextFontSize={theme.fontSize.md}
        />

        <TextTextView
          firstText={t('angelProfileResponseTime')}
          secondText={`${t('angelProfileResponseTimeDescription')} ${
            responseTime > 0 ? responseTime : 120
          } ${responseTime > 1 ? t('minutes') : t('minute')}`}
          secondTextAlign="left"
          justifyContent="flex-start"
          firstTextWidth={firstTextWidth}
          firstTextFontSize={theme.fontSize.md}
          firstTextColor={theme.textColorBase}
          secondTextFontSize={theme.fontSize.md}
        />

        <TextTextView
          alignItems="flex-start"
          firstText={t('angelProfileLanguages')}
          secondText={languages.join(', ')}
          secondTextWidth="50%"
          secondTextNumberOfLines={3}
          secondTextAlign="left"
          justifyContent="flex-start"
          firstTextWidth={firstTextWidth}
          firstTextFontSize={theme.fontSize.md}
          firstTextColor={theme.textColorBase}
          secondTextFontSize={theme.fontSize.md}
        />

        <TextTextView
          firstText={t('angelProfileAreaOfInterest')}
          secondText={fieldOfStudy}
          secondTextAlign="left"
          justifyContent="flex-start"
          firstTextWidth={firstTextWidth}
          firstTextFontSize={theme.fontSize.md}
          firstTextColor={theme.textColorBase}
          secondTextFontSize={theme.fontSize.md}
        />

        <TextTextView
          firstText={t('angelProfileEducation')}
          secondText={education}
          secondTextAlign="left"
          justifyContent="flex-start"
          firstTextWidth={firstTextWidth}
          firstTextFontSize={theme.fontSize.md}
          firstTextColor={theme.textColorBase}
          secondTextFontSize={theme.fontSize.md}
        />
      </Container>

      <IconButton
        paddings="5px"
        iconSource={infoIcon}
        iconColor={theme.colors.secondary}
        onPress={onInfoPress}
      />
    </View>
  );
};

Characteristics.propTypes = {
  responseTime: PropTypes.number,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  education: PropTypes.string.isRequired,
  fieldOfStudy: PropTypes.string.isRequired,
  onInfoPress: PropTypes.func.isRequired,
  firstTextWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Characteristics.defaultProps = {
  responseTime: 0,
  firstTextWidth: '120px'
};

export default Characteristics;
