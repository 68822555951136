import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-native';
import colors from '../styles/colors';

const DefaultSwitch = ({
  disabled,
  value,
  onValueChange,
  trackColor,
  thumbColor,
  iosBackgroundColor,
}) => {
  return (
    <Switch
      value={value}
      onValueChange={(val) => !disabled && onValueChange(val)}
      trackColor={trackColor}
      thumbColor={thumbColor}
      ios_backgroundColor={iosBackgroundColor}
    />
  );
};

DefaultSwitch.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.bool.isRequired,
  onValueChange: PropTypes.func.isRequired,
  trackColor: PropTypes.shape({
    false: PropTypes.string,
    true: PropTypes.string,
  }),
  thumbColor: PropTypes.string,
  iosBackgroundColor: PropTypes.string,
};

DefaultSwitch.defaultProps = {
  disabled: false,
  trackColor: { false: colors.defaultBtnBackgroundColor, true: colors.primary },
  thumbColor: colors.white,
  iosBackgroundColor: colors.defaultBtnBackgroundColor,
};

export default DefaultSwitch;
